import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';
import { DataEngineManager } from '../../shared/base-func/data-engine-manager';

import { DomSanitizer } from '@angular/platform-browser';

import { BaseFunc } from '../../shared/base-func/base-func';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { CustomCrudsService } from './custom-cruds.service';

import { FieldEngineComponent } from '../../shared/field-engine/field-engine.component';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { GuidedTour, Orientation } from 'ngx-guided-tour';

@Component({
  selector: 'app-custom-cruds',
  templateUrl: './custom-cruds.component.html',
  styleUrls: ['./custom-cruds.component.css']
})
export class CustomCrudsComponent extends BaseFunc implements OnInit {
  @ViewChildren('fields') fields: QueryList<FieldEngineComponent>;

  crud;
  crudId;
  crudFields: any[];
  reportFilters: any[];
  crudFieldsVisible: any[];
  auxData: any;
  func_crd: any;
  source;
  config: {[key:string]: any};
  valid;
  dataManager;

  dataTotal: any[];
  dataShow: any[];
  dataResult: any[] = [];
  dataResultOrigin: any[] = [];
  dataResultFiltered: any[] = [];
  startIndex:number;
  endIndex: number;
  numberPages: number;
  current_page: number = 0;
  linesPerPage: number = 10;
  filterTextGlobal:string = '';

  loading_data = false;

  // Tipos de edição
  head_type: string = 'DEFAULT';
  newInline: boolean = false;
  editLine: string = "";

  filterModal: any = { index: -1, title: 'Indefinido', left: 0, top: 0, display: 'none', filterText: '', filterOrder: '' };


  form: FormGroup;
  global: Global = new Global();

  userPermissions: any = {
    create: false,
    update: false,
    delete: false,
  }

  selectedColumn: any = null
  selectedFilter: any = {
    label: null,
    type: null
  };
  isOptionsOpened: boolean = false;

  offset: number = 0;
  limit: number = 5;

  tourSequence: GuidedTour;

  elementExists(classList: string) {
    const tag = document.getElementsByClassName(classList)[0] as HTMLElement;
    return tag ? true : false
  }

  constructor(
    private modalAlertService: BootstrapModalAlertService,
    private sanitizer: DomSanitizer,
    private customcruds: CustomCrudsService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    public authService: AuthService) {
      super();
      this.dataManager = new DataEngineManager(this);
    }

  ngOnInit(): void {
    this.getCRUDID();
  }

  onPrepareTour({ fields }: any) {

    this.tourSequence = {
      tourId: 'custom-cruds',
      steps: [
        {
          title: 'Nome do tabela',
          selector: '.tour-table-title',
          content: 'Aqui você encontra o nome do formulário.',
          orientation: Orientation.Bottom,
          action: () => {},
        },
        {
          title: 'Dados do tabela',
          selector: '.tour-table-content',
          content: 'Aqui você encontra a tabela que contem as informações.',
          orientation: Orientation.Bottom,
          skipStep: this.elementExists('tour-table-item')
        },
        {
          title: 'Nomes dos campos',
          selector: '.tour-table-fields',
          content: 'Existem diferentes possibilidades de tipos de campos.',
          orientation: Orientation.Bottom,
          action: () => {},
        }
      ]
    };

    const hasIncludes = [];
    fields.map((field) => {
      if(hasIncludes.includes(field.label)) {
        return
      }

      this.tourSequence.steps.push({
        title: 'Filtrar dados',
        selector: `th#${field.dom_id}`,
        content: `${field.description}` ?? `Esse é o campo de exibição do ${field.label}`,
        orientation: Orientation.Bottom,
      });

      hasIncludes.push(field.label);
    });

    const finalSequence = [
      {
        title: 'Filtrar dados',
        selector: '.tour-table-filters',
        content: 'Clicando aqui, você abre o menu de filtros que possibilita uma melhor visualização dos dados.',
        orientation: Orientation.Bottom,
        closeAction: () => {
          const tag = document.getElementsByClassName('tour-table-filters')[0] as HTMLElement;
          tag.click();
        },
      },
      {
        title: 'Tipos de filtros',
        selector: '.modal-options',
        content: 'Aqui você pode escolher entre as diversas possibilidades de visualização.',
        orientation: Orientation.Bottom,
        action: () => {
          const tag = document.getElementsByClassName('modal-options')[0] as HTMLElement;
        }
      },
      {
        title: 'Ordenação dos campos',
        selector: '.tour-table-filter-order',
        content: 'Você pode alterar a ordenação da tabela com base no campo selecionado.',
        orientation: Orientation.Bottom,
        action: () => {
        }
      },
      {
        title: 'Ordenação dos campos',
        selector: '.tour-table-filter-search',
        content: 'Faça uma busca direta com base no texto.',
        orientation: Orientation.Bottom,
        action: () => {
        }
      },
      {
        title: 'Ordenação dos campos',
        selector: '.tour-table-filter-clear',
        content: 'Clique aqui para remover os filtros selecionados.',
        orientation: Orientation.Bottom,
        closeAction: () => {
          const tag = document.getElementsByClassName('tour-table-filter-close')[0] as HTMLElement;
          tag.click();
        }
      },
      {
        title: 'Ações disponíveis',
        selector: '.tour-table-actions',
        content: 'Esses são os botões de ações, aqui é possível editar, remover, ver, dentre outras opções.',
        orientation: Orientation.Left,
      },
      {
        title: 'Paginação',
        selector: '.tour-table-paginator',
        content: 'Caso o item que você procura não esteja aparecendo, tente avançar ou retroceder a página.',
        orientation: Orientation.Left,
        skipStep: this.elementExists('tour-table-filter-close')
      },

      {
        title: 'Adicionar dados',
        selector: '.tour-table-create',
        content: 'Para adicionar novos dados basta clicar aqui e preencher o formulário.',
        orientation: Orientation.Right,
        skipStep: this.elementExists('tour-table-create')
      }
    ]

    this.tourSequence.steps = [...this.tourSequence.steps, ...finalSequence]
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  getCRUDID = () => {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.crudId = +param.get('id');
      this.read_config_data();
      this.onVerifyPermissions(this.crudId)
    });
  }

  onOrderBy(type: 'asc' | 'desc') {
    const index = this.selectedFilter.tableIndex + 1;

    this.dataResultFiltered.sort((a, b) => {
      if (type === 'asc') {
        return a[index].localeCompare(b[index]);
      } else {
        return b[index].localeCompare(a[index]);
      }
    })
  }

  onClearFilters() {
    this.dataResultFiltered = this.dataResultOrigin;
  }

  onHideFilters() {
    this.selectedFilter = {
      label: null,
      type: null
    };
    this.isOptionsOpened = false;
  }

  onShowFilters(data: any, index: number) {
    this.selectedFilter = { ...data, tableIndex: index };
    this.isOptionsOpened = true;
  }

  onVerifyPermissions(id: number) {
    this.userPermissions.create = true
    this.userPermissions.update = this.authService.checkPermission(`change_form_${id}`);
    this.userPermissions.delete = this.authService.checkPermission(`delete_form_${id}`);

    // this.userPermissions = {
    //   create: true,
    //   update: true,
    //   delete: true
    // }

  }

  read_config_data = () => {
    var self = this;
    self.loading_data = true;
    self.cancelBtn();
    this.customcruds.getCustomCrudsConf(self.crudId).pipe(take(1)).subscribe(
      data => {
        this.onPrepareTour(data);

        self.crud = data.crud;
        self.reportFilters = data.filters;
        self.crudFields = data.fields;

        self.auxData = data.auxData;
        self.func_crd = ( data.func != null ? eval('(' + data.func + ')') : null );

        self.valid = data.valid;
        self.config = data.config;
        self.head_type = self.config.head_type && ( self.config.head_type == 'MODAL' || self.config.head_type == 'INLINE' || self.config.head_type == 'DEFAULT')  ? self.config.head_type : 'DEFAULT';
        if (self.config.head_type == 'INLINE')
          delete self.config.excludeList;

        self.crudFieldsVisible = self.crudFields.filter( (x, ix) => !(self.config.hasOwnProperty('excludeList') ? self.config.excludeList : []).includes(ix) );

        self.getList(function() { self.loading_data = false; });
        self.form = self.formBuilder.group({ });
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error.message + '</ul>');
        if( error.error.ic_exception && error.error.param && error.error.param.length > 0 )
          self.special_behavior(error);
      },
      () => {

      }
    );
  }

  // #region CRUD Methods
  createBtn = ( clearPK = null, isCreate = true ) => {
    var self = this;
    if (self.head_type == 'DEFAULT') {
      self.$j('.listCrud').hide();
      self.$j('.headCrud').show();
    }
    else if (self.head_type == 'MODAL') {
      self.$j('.headCrud').modal('show');
    }
    else if(self.head_type == 'INLINE') {
      self.newInline = isCreate;
      if (isCreate)
        self.editLine = "";
    }

    if ( !clearPK )
      self.$j('#pk').val('');
  }

  cancelBtn = () => {
    var self = this;
    if (self.head_type == 'DEFAULT') {
      self.$j('.listCrud').show();
      self.$j('.headCrud').hide();
    }
    else if (self.head_type == 'MODAL') {
      self.$j('.headCrud').modal('hide');
    }
    else if(self.head_type == 'INLINE') {
      self.newInline = false;
      self.editLine = "";
    }
    self.global.clearForm('.managedform');
  }

  applyBtn = (event) => {
    event.preventDefault();
    var self = this;

    var callback = null;
    if (self.config.beforeValidate)
      eval(self.config.beforeValidate);

    if ( !self.global.validateToServer(self.valid, self.modalAlertService, callback) ) {
      return false;
    }
    else {
      self.$j('#applyFilters').attr('disabled', 'disabled');
      self.save();
    }
  }

  getList = (callback?) => {
    var self = this;

    var formatter = null;
    if( self.config.formatter )
      formatter = self.dataManager.get_formatter(self.config, self.config.formatter.split(',').length, true);

    this.customcruds.getCustomCrudsSearch(self.crudId).pipe(take(1)).subscribe(
      data => {
        this.dataResultOrigin = data.data;
        this.dataResultFiltered = data.data;

        self.dataTotal = data.data;
        self.dataShow = data.data;
        self.$j('#searchGlobalInput').val('');
        // self.calculate_page();

        if(callback)
          callback();
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error.message + '</ul>');
        if( error.error.ic_exception && error.error.param && error.error.param.length > 0 )
          self.special_behavior(error);
      },
      () => {
      }
    );
  }

  searchFor = (refBtn, id) => {
    var self = this;

    if (self.head_type == 'INLINE')
      self.editLine = id;

    var formatter = null;
    if( self.config.formatter )
      formatter = self.dataManager.get_formatter(self.config, self.config.formatter.split(',').length, true);

    if (self.config.beforeRetrieve)
      eval(self.config.beforeRetrieve);

    self.loading_data = true;
    this.customcruds.getCustomCrudsSearch(self.crudId, id).pipe(take(1)).subscribe(
      data => {
        var editSemaphore = setInterval(function() {
          if ( self.$j('#pk').length > 0 ) {
            try {
              var map = {};
              data.cols.unshift(id);
              data.cols.forEach(function(e, i){ map[e] = data.data[0][i]; });
              var fieldUploads = Object.assign({}, ...self.fields.filter(x => x.field.type == 'IMAGE' || x.field.type == 'FILE').map((x) => ({[x.field.dom_id]: x})));
              self.global.fillFormWithHistoryObject(map, null, fieldUploads);
              self.$j('#pk').val(id);
              self.createBtn(true, self.head_type == 'INLINE' ? false : true);

              if (self.config.afterRetrieve)
                eval(self.config.afterRetrieve);
            }
            catch(e) {
              console.log('Ocorreu uma falha ao buscar os dados');
              console.log(e);
            }
            finally {
              clearInterval(editSemaphore);
              self.loading_data = false;
            }

          }
        }, 200);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error.message + '</ul>');
        if( error.error.ic_exception && error.error.param && error.error.param.length > 0 )
          self.special_behavior(error);
      },
      () => {
      }
    );
  }

  save = () => {
    var self = this;
    var fieldUploads = Object.assign({}, ...self.fields.filter(x => x.field.type == 'IMAGE' || x.field.type == 'FILE').map((x) => ({[x.field.dom_id]: x})));
    var id = self.$j('#pk').val()
    var toServer = self.global.saveFormData(fieldUploads);
    toServer.append('id', self.crudId);
    toServer.append('pkey', id);

    if (self.config.beforeSave)
      eval(self.config.beforeSave);

    self.loading_data = true;
    this.customcruds.getCustomCrudsSave(toServer).pipe(take(1)).subscribe(
      data => {
        this.showSnack("Registro salvo com sucesso!");

        window.location.reload();

        if (self.config.afterSave)
          eval(self.config.afterSave);

        self.getList(function() {
          self.loading_data = false;
          self.cancelBtn();
          self.$j('#applyFilters').removeAttr('disabled');
          self.global.clearForm('.managedform');
        });

      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error.message + '</ul>');
        if( error.error.ic_exception && error.error.param && error.error.param.length > 0 )
          self.special_behavior(error);

        self.$j('#applyFilters').removeAttr('disabled');
      },
      () => {
      }
    );
  }

  delete_prepare = (code) => {
    var self = this;
    self.$j('#curr_delete_formcode').val(code);
  }

  delete = (btn) => {
    var self = this;
    var code = self.$j('#curr_delete_formcode').val();
    if (self.config.beforeDelete)
      eval(self.config.beforeDelete);

    self.loading_data = true;
    this.customcruds.getCustomCrudsDelete(self.crudId, code).pipe(take(1)).subscribe(
      data => {
        self.getList(function() {
          if (self.config.afterDelete)
            eval(self.config.afterDelete);
          self.loading_data = false;
        });
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error.message + '</ul>');
        if( error.error.ic_exception && error.error.param && error.error.param.length > 0 )
          self.special_behavior(error);
      },
      () => {
      }
    );
  }

  special_behavior = (error) => {
    var self = this;
    if (error.error.param[0] == 'unique')
      self.fields.filter(x => x.field.sql == error.error.param[1] || x.field.alias_sql == error.error.param[1] ).forEach(x => {
        self.$j('#div_' + x.field.dom_id).addClass('highlightError');
        setTimeout(function() { self.$j('#div_' + x.field.dom_id).removeClass('highlightError'); }, 5000);
      });
  }
  //#endregion

  onCloseModal() {
    this.selectedColumn = null;
  }

  onOpenModal(field: any) {
    this.selectedColumn = field;
  }

  onSearch(data: any) {
    const index = this.selectedFilter.tableIndex + 1;

    this.dataResultFiltered = this.dataResultOrigin.filter((el) => {
      return el[index].toLowerCase().includes(data)
    })
  }

  // #region Table/List Management Methods
  menuColumn = (index, label, element, status, field) => {
    this.onOpenModal(field);
    var self = this;
    var rect = element.target.getBoundingClientRect();
    const positionInScreenTop = rect.top + window.scrollY + rect.height;
    const positionInScreenLeft = rect.left + window.screenTop - rect.width/2;

    this.selectedColumn = field;

    self.filterModal = {
      index: ( index != -1 ? index : self.filterModal.index ),
      title: label,
      left: positionInScreenLeft,
      top: positionInScreenTop,
      display: status,
      filterText: index == -1 || self.filterModal.index == index ? self.filterModal.filterText : '',
      filterOrder: index == -1 || self.filterModal.index == index ? self.filterModal.filterOrder : ''
    };

    self.filterItemsFromTable(self.filterModal.filterText, 'local');
  }

  filterItemsFromTable = (value: any, type:string) => {
    var self = this;
    var text = value;

    if ( type == 'global' )
      self.filterModal.filterText = '';
    else
      self.filterTextGlobal = '';

    if (value != null && value.trim() != '') // Filtro não está vazio
      self.dataShow = self.dataTotal.filter( x =>
        ( self.config.hasOwnProperty('id') ? x.slice(1) : x ).filter( (y, ix) => !(self.config.hasOwnProperty('excludeList') ? self.config.excludeList : []).includes(ix) )
        .filter( (a, ax) => {
          if (type != 'global' && ax != self.filterModal.index)
            return false;

          if (a == null || a == undefined)
            return false;

          return a.toString().toLowerCase().indexOf(text.toLowerCase()) != -1;
        }).length > 0 );
    else
      self.dataShow = self.dataTotal.slice(0);

    if (self.filterModal.filterOrder != '')
      self.dataShow = self.dataShow.sort(function(a , b) {
        var e1 = ( ( self.config.hasOwnProperty('id') ? a.slice(1) : a ).filter( (y, ix) => !(self.config.hasOwnProperty('excludeList') ? self.config.excludeList : []).includes(ix) ) )[self.filterModal.index];
        var e2 = ( ( self.config.hasOwnProperty('id') ? b.slice(1) : b ).filter( (y, ix) => !(self.config.hasOwnProperty('excludeList') ? self.config.excludeList : []).includes(ix) ) )[self.filterModal.index];
        if( e1 != null && e2 != null && e1.toString() > e2.toString())
          return self.filterModal.filterOrder == 'asc' ? 1 : -1;
        if( e1 != null && e2 != null && e1.toString() < e2.toString())
          return self.filterModal.filterOrder == 'asc' ? -1 : 1;

        return 0;
      });
    self.calculate_page();
  }

  getCellFormatted = (ei, col_index) => {
    var self = this;

    var formatter = null;
    if( self.config.formatter )
      formatter = self.dataManager.get_formatter(self.config, self.config.formatter.split(',').length, true);

    var col_visible = self.decodeIndexColVisible(col_index);
    var cellContent;
    if ( formatter && formatter.length > col_visible) { // Possui um formatter
      if ( ei != null && ei != "" ) //Campo foi preenchido com um valor, então deve ser formatado
        cellContent = formatter[col_visible](ei)
      else                         // Campo não obrigatório que foi salvo sem um valor
        cellContent = ei
    }
    else
      cellContent = ei;

    if (typeof cellContent === 'string' && cellContent.indexOf(' **&& ') != -1) // Campos autocomplete
      cellContent = cellContent.split(' ¶¶µµ ').map(x => x.split(' **&& ')[0] ).join(', ');

    return cellContent;
  }

  sanitizeImageValue = (val) => {
    var self = this;
    return self.sanitizer.bypassSecurityTrustUrl( 'data:image/png;base64, ' + val );
  }

  isColumnVisible = (indexCol) => {
    var self = this;
    if ( !self.config || ( self.config && !self.config.hasOwnProperty('excludeList') ) )
      return true;

    return !self.config.excludeList.includes(indexCol);
  }

  decodeIndexColVisible = (col_index) => {
    var self = this;
    return col_index - ( self.config.hasOwnProperty('excludeList') ? self.config.excludeList.filter( x => x < col_index ).length: 0 )
  }
  // #endregion

  // #region Pagination Methods
  change_page = (index) => {
    var self = this;
    if (index >= 0 && index < self.numberPages) {
      self.current_page = index;
      self.calculate_page();
    }
  }

  calculate_page = () => {
    var self = this;
    self.numberPages = Math.ceil( self.dataShow.length / self.linesPerPage );
    if (self.current_page >= self.numberPages)
      self.current_page = 0;

    self.startIndex = self.current_page * self.linesPerPage;
    self.endIndex = Math.min(self.dataShow.length, ( self.current_page + 1 ) * self.linesPerPage);

    self.dataResult = self.dataShow.slice(self.startIndex, self.endIndex);
    this.dataResultFiltered = this.dataResult;
  }
  //#endregion
}


<div class="container-fluid" *ngIf="form != null">
    <div class="content-wrapper">
        <section class="content-header">
            <form #f="ngForm" name="serchForm" action="/help_index" class="input-group">
                <input #search_field type="text" id="q" name="q" value=""  class="form-control" placeholder="Buscar ..." maxlength="255">
                <span class="input-group-btn">
                    <button (click)="submitSearch(search_field)" id="search-btn" class="btn btn-flat">
                        <i class="fa fa-search"></i>
                    </button>
                </span>
            </form>
        </section>

        <section class="mt-5 mb-5">
            <ng-container *ngIf="founded; then builded_help; else not_builded_help">
            </ng-container>
            <ng-template #builded_help >

                <ng-container *ngIf="request_type == 2; then true_block; else false_block">
                </ng-container>
                <ng-template #true_block >
                    <ng-container *ngIf="results.length > 0; then has_results; else no_results">
                    </ng-container>
                    <ng-template #has_results >
                        <ng-container *ngFor="let r of results" >
                            <h4><a href="/help_index/{{r[0][0]}}" >{{r[0][1]}}</a></h4>
                            <p> {{r[1]}} resultado(s) encontrado(s) </p>
                        </ng-container>
                    </ng-template>
                    <ng-template #no_results >
                        <h4><a>Nenhum resultado encontrado</a></h4>
                    </ng-template>
                </ng-template>
                <ng-template #false_block>
                    <h1>{{ header.title }}</h1>
                    <p>{{ header.body }}</p>
    
                    <ng-container *ngIf="filters.length > 0" >
                        <h3>Filtros e Elementos de Controle</h3>
                        A seguir são apresentados filtros e elementos de controle disponíveis para a manipulação do {{type}}.
                        <ul>
                            <ng-container *ngFor="let f of filters" >
                                <li><b>{{f.title}}</b>: {{f.body}} </li> 
                                <ng-container *ngIf="f.examples && f.examples.length > 0" >
                                    <i>Ex.: {{f.examples.join(', ')}}</i>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </ng-container>
    
                    <ng-container *ngIf="body.length > 0" >
                        <h3>Componentes do {{type}}</h3>
                        <!-- A seguir será apresentado uma visão analítica do {{type}}. -->
                        A seguir será apresentado uma visão analítica do {{type}}.
                        <ul>
                            <ng-container *ngFor="let f of body" >
                                <li><b>{{f.title}}</b>: {{f.body}} </li> 
                                <div id="fieldset_{{f.id}}" name="fieldset_{{f.id}}" >
                                    <div class="card-body grid-stack-item-content" >
                                        <div class="align-items-center">
                                            <div>
                                                <h4 class="d-inline card-title">
                                                    <span></span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="customReportCell_{{f.id}}" class="customReportCell " ></div>
                                </div>
                            </ng-container>
                        </ul>
                    </ng-container>
    
                    <ng-container *ngIf="fields.length > 0" >
                        <h2>Campos de {{header.title}}</h2>
                        <!-- A seguir são apresentados um resumo dos campos do {{header.title|lower}}. -->
                        A seguir são apresentados um resumo dos campos do {{header.title.toLower()}}
                        <ul>
                            <ng-container *ngFor="let f of fields" >
                                <li><b>{{f[0]}}</b>: {{f[1]}} </li> 
                            </ng-container>
                        </ul>
                    </ng-container>
                </ng-template>

            </ng-template>
            <ng-template #not_builded_help >

                <section class="section">
                    <div class="container mt-5">
                        <div class="page-error">
                            <div class="page-inner">
                                <h1>Página em construção</h1>
                                <div class="page-description">
                                    Desculpe, mas essa sessão do sistema de ajuda ainda está em construção
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </ng-template>
        
        </section>
    </div>
</div>


import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { UsersGroupsService } from '../users-groups.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { take, map } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-users-groups-update',
  templateUrl: './users-groups-update.component.html',
  styleUrls: ['./users-groups-update.component.css'],
})
export class UsersGroupsUpdateComponent implements OnInit, AfterViewInit {
  /**
   * Formulário para a criação de perfil (Grupo de permissões).
   */
  formGroup: FormGroup;

  /**
   * Recebe as informações relativas as empresas (mapping).
   */
  mapping: any = {};

  /**
   * Recebe a empresa com suas respectivas informações .
   */
  companies: any[] = [];

  /**
   * Recebe a lista das empresas cadastradas no banco de dados (Keys).
   */
  source: any[] = [];

  /**
   * Lista para armazenar os IDs das permissões selecionados.
   */
  permissionsIds: number[];

  /**
   * Variavel de controle do Toggle Collapse das hierarquias.
   */
  isCollapsedHierarchy = false;

  /**
   * Variavel de controle do Toggle Collapse das permissões.
   */
  isCollapsedPermissions = false;

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Itens da varialve do ttree-view-checkbox.
   */
  items: TreeviewItem[];

  /**
   * Configuração do tree-view-checkbox.
   */
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });

  get isHorizontal() {
    return window['whiteLabelConf']('horizontal');
  }

  hasCompanyToHisUser(db: string) {
    return this.selectedCompanies.filter((el) => el.company === db).length > 0 || db === 'permissões gerais';
  }

  itemsByDb: any[] = [];
  selectedDb: number = null;
  treeViewByDb: { db: string; tree: TreeviewItem[]; selectedIds: number[] }[] = [];

  selectedCompanies: any[] = [];
  previusCompanies: any[] = [];
  previusPermissions: any[] = [];

  isOpened(data: any) {
    if (!this.formGroup) {
      return;
    }

    const company = this.formGroup.get('companies').value;
    return company !== data.company;
  }

  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private usersGroupsService: UsersGroupsService,
    private modalAlertService: BootstrapModalAlertService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    public layout: LayoutService,
  ) {}

  // Verificar a permissão de alterar Nível organizacional
  hasHierarchyChangePermission(): boolean {
    return this.authService.checkPermission('change_authhierarchy');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.onFindUsersPermissions();
  }

  /**
   * Inicializa o formulário reativo.
   */
  private initializeForm(): void {
    this.loadData();
    this.formGroup = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      person: [''],
      permissions: [[]],
      description: [''],
      companies: [[]],
      plants: [[]],
      auth_hierarchies: [[]],
    });
    this.setupFormChanges();
  }

  ngAfterViewInit(): void {
    // check if user is enable
    this.authService.isEnable();

    // check permission
    // tslint:disable-next-line:triple-equals
    var is_mdb =
      this.authService &&
      this.authService.getProfile().indexOf('2') != -1 &&
      this.authService &&
      ((this.authService.getPersonName() &&
        this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1));

    var is_terceirizada =
      this.authService &&
      this.authService.getProfile().indexOf('2') != -1 &&
      this.authService &&
      ((this.authService.getPersonName() &&
        this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1));

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('change_group') == false) {
      this.router.navigate(['/403']);
    }

    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = parseInt(param.get('id'), 10);
      this.read(id);
    });
  }

  onFindUsersPermissions() {
    this.usersGroupsService.getUsersPermissions().subscribe(
      (data) => {
        this.onTransformPermissionsByDb(data);
      },
      (error) => {
        this.modalAlertService.showAlertDanger('Não foi possível listar as permissões');
      },
    );
  }

  onSelectDb(index: number) {
    this.selectedDb = index;
  }

  onHidePermissions() {
    this.isCollapsedPermissions = !this.isCollapsedPermissions;
  }

  read(id: number) {
    this.usersGroupsService.detailUserGroup(id).subscribe(
      (data) => {
        // Recebebndo e preenchendo os valores do formulário

      

        const companies = data.auth_hierarchies.map((hierarchy) => hierarchy.db_name);
        const plants = data.auth_hierarchies.map((hierarchy) => hierarchy);

        this.setupFormChanges();

        this.updatePlantsOptions(companies);

        this.formGroup.patchValue({
          id: data.id,
          name: data.name,
          person: data.person,
          description: data.description,
          auth_hierarchies: data.auth_hierarchies,
        });


        this.onSetPreviusCompanies(data.auth_hierarchies);

        this.previusPermissions = data.permissions;

        this.formGroup.get('permissions').setValue(data.permissions.map((permission) => permission.id));
      },
      (error) => {
        console.error('Erro ao buscar os dados do grupo', error);
      },
    );
  }

  updateProfilePermissions() {
    // Marca o formulário como submetido.
    this.submitted = true;

    if (this.formGroup.valid) {
      if (this.authService.hasPerson() && this.authService.hasToken()) {
        this.formGroup.get('person').setValue(this.authService.getPerson());
        this.permissionsIds = [];

        this.treeViewByDb.map((tree) => {
          if (tree.selectedIds.length > 0) {
            this.permissionsIds = [...this.permissionsIds, ...tree.selectedIds];
          }
        });
        this.formGroup.get('permissions').setValue(this.permissionsIds);

        if (this.selectedCompanies.length == 0) {
          return;
        }

        let hierarchies = [];
        this.selectedCompanies.map((co) => {
          co.plants.map((plant) => {
            const payload = {
              hierarchy_id: plant.id,
              hierarchy_type: 'Company',
              db_name: this.authService.encrypt(co.company),
              db_real_name: co.company,
            };
            hierarchies.push(payload);
          });
        });

        this.formGroup.get('auth_hierarchies').setValue(hierarchies);

        const { id } = this.route.params['_value']

        this.isLoading = true;
        this.usersGroupsService.updateUsersGroups(this.formGroup.value, id).subscribe(
          (data) => {
            window.location.reload();
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso.');
          },
          (error) => {
            this.modalAlertService.showAlertDanger('Erro ao alterar o registro');
          },
        ).add(() => {
          this.isLoading = false;
        });
      } else {
        this.modalAlertService.showAlertDanger('Erro ao alterar o registro');
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.formGroup);
    }
  }

  loadData() {
    this.authService
      .getCompanyList()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.source = data.keys;
          this.companies = [];
        },
        (error) => {
          console.error('Falha ao carregar empresas.', error.msg);
        },
      );
  }

  setupFormChanges() {
    this.formGroup.get('companies').valueChanges.subscribe((selectedCompany) => {
      this.updatePlantsOptions(selectedCompany);

      const indexOfDb = this.itemsByDb.findIndex((el) => Object.keys(el)[0] === selectedCompany);
      if (indexOfDb !== -1) {
        this.isCollapsedPermissions = false;
        this.onSelectDb(indexOfDb);
      }
    });
  }

  updatePlantsOptions(selectedCompany) {
    this.authService
      .getCompanyList()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.companies = data.mapping[selectedCompany] || [];

          this.formGroup.get('plants').reset();
          this.selectedCompanies.map((co) => {
            if (co.company == selectedCompany) {
              this.formGroup.get('plants').setValue(co.plants);
            }
          });
          // this.formGroup.get('plants').setValue(this.companies);
        },
        (error) => {
          console.error('Falha ao carregar empresas.', error.msg);
        },
      );
  }

  onSetPreviusCompanies(companies: { id: number; label: string; db_name: string; hierarchy_id: number }[]) {
    const data: { company: string; plants: { id: number; report_db: string; person: any }[] }[] = [];

    companies.map((co) => {
      const temp = {
        company: co.db_name,
        plant: {
          id: co.hierarchy_id,
          report_db: this.authService.encrypt(co.label),
          person: {
            trade_name: co.label,
          },
        },
      };

      const companyIndex = data.findIndex((el) => el.company === temp.company);
      if (companyIndex !== -1) {
        data[companyIndex].plants.push(temp.plant);
      } else {
        data.push({
          ...temp,
          plants: [temp.plant],
        });
      }

      this.previusCompanies = data;
    });

    this.selectedCompanies = data;
    this.formGroup.get('companies').setValue(this.selectedCompanies[0].company);
    this.formGroup.get('plants').setValue(this.selectedCompanies[0].plants);
  }

  onSelectPlant(plants: { id: number; report_db: string; person: any }[]) {
    const company = this.formGroup.get('companies').value;
    const companyIndex = this.selectedCompanies.findIndex((co) => co.company == company);

    if (companyIndex !== -1 && plants.length == 0) {
      this.selectedCompanies.splice(companyIndex, 1);
      this.onClearTreeByCompanyIfIsEmpty(company);
      return;
    }

    if (companyIndex !== -1 && plants.length > 0) {
      this.selectedCompanies[companyIndex].plants = plants;
      return;
    }

    this.selectedCompanies.push({
      company,
      plants,
    });
  }

  onClearTreeByCompanyIfIsEmpty(company: string) {
    const treeExistsIndex = this.treeViewByDb.findIndex((tree) => tree.db == company);
    if(treeExistsIndex !== -1) {
      this.treeViewByDb[treeExistsIndex].selectedIds = [];
      const permissions = this.itemsByDb.filter((el) => Object.keys(el)[0] == company)[0][company];
      console.log(permissions);
      
      this.treeViewByDb[treeExistsIndex].tree = this.transformPermissionsToTreeViewItems({ permissions });
      this.onSelectDb(0);
    }
  }

  onRemoveSingle(data: { company: string; plantIndex: number }) {
    const companyIndex = this.selectedCompanies.findIndex((co) => co.company == data.company);
    if (companyIndex !== -1) {
      this.selectedCompanies[companyIndex].plants.splice(data.plantIndex, 1);
      this.formGroup.get('companies').setValue(data.company);
      this.formGroup.get('plants').setValue(this.selectedCompanies[companyIndex].plants);
      
      if (this.selectedCompanies[companyIndex].plants.length == 0) {
        this.selectedCompanies.splice(companyIndex, 1);
        this.onClearTreeByCompanyIfIsEmpty(data.company);
      }
    }
  }

  onRemoveAll(data: { company: string; plants: { id: number; report_db: string; person: any }[] }) {
    const company = this.formGroup.get('companies').value;
    if (company == data.company) {
      this.formGroup.get('plants').setValue([]);
    }
    this.formGroup.get('companies').setValue(data.company);
    this.selectedCompanies = this.selectedCompanies.filter((co) => co.company !== data.company);
  
    this.onClearTreeByCompanyIfIsEmpty(data.company);
    
  }

  onSelectedChange(ids: number[], target: any): void {
    target.selectedIds = ids;
  }

  onTransformPermissionsByDb(data: any[]) {
    const accumulator = data.reduce((acc: any, el: any) => {
      const { report_db } = el;

      const key = report_db === 'main' || report_db === 'None' || report_db === 'form' || report_db === null ? 'permissões gerais' : report_db;

      acc[key] = acc[key] || [];
      acc[key].push(el);

      return acc;
    }, {});

    this.itemsByDb = Object.entries(accumulator).map(([key, value]) => ({
      [key]: value,
    }));

    this.itemsByDb.map((el: any) => {
      const db = Object.keys(el)[0];

      this.treeViewByDb.push({
        db,
        tree: this.transformPermissionsToTreeViewItems({ permissions: el[db] }),
        selectedIds: [],
      });
    });

    this.previusPermissions.map((per) => {
      const treeByDbIndex = this.treeViewByDb.findIndex((el) => el.db == per.report_db);
      const generalDbIndex = this.treeViewByDb.findIndex((el) => el.db === 'permissões gerais');

      if (treeByDbIndex !== -1) {
        this.treeViewByDb[treeByDbIndex].selectedIds.push(per.id);
      } else {
        this.treeViewByDb[generalDbIndex].selectedIds.push(per.id);
      }
    });
  }

  onGetDbName(index: number) {
    let name = Object.keys(this.itemsByDb[index])[0];
    return name;
  }

  transformPermissionsToTreeViewItems(permissionsJson: any): TreeviewItem[] {
    const userPermissions = this.formGroup.get('permissions').value;
    const groupedPermissions = this.groupPermissions(permissionsJson.permissions);

    const children = [].concat(
      ...Object.keys(groupedPermissions).map((actionType) => {
        return Object.keys(groupedPermissions[actionType]).map((screenOrReport) => {
          return {
            text: `${actionType} ${screenOrReport}`, // Texto do nível superior
            value: screenOrReport,
            collapsed: true,
            children: groupedPermissions[actionType][screenOrReport].map((action) => ({
              text: this.getActionName(action.codename), // Texto do nível de ação
              value: action.id, // O ID da ação
              checked: userPermissions.includes(action.id),
            })),
          };
        });
      }),
    );

    return [
      new TreeviewItem({
        text: 'Selecionar todas as permissões',
        value: null,
        children: children,
      }),
    ];
  }

  groupPermissions(permissions: any[]): any {
    const grouped = {};

    permissions.forEach((permission) => {
      let typeKey = 'Gerenciar';
      let itemKey = permission.name;

      if (permission.codename.startsWith('report')) {
        typeKey = 'Relatorio';
        const reportNameMatch = permission.name.match(/Visualizar (.+)/i);
        itemKey = reportNameMatch && reportNameMatch[1] ? reportNameMatch[1].trim() : 'Relatório Sem Nome';
      } else {
        // Extrai o nome relevante da propriedade 'name' para ações não-relatório
        const actionRemoved = permission.name.split(' ').slice(2).join(' ');
        itemKey = actionRemoved || 'Ação Desconhecida';
      }

      if (!grouped[typeKey]) {
        grouped[typeKey] = {};
      }

      if (!grouped[typeKey][itemKey]) {
        grouped[typeKey][itemKey] = [];
      }

      // Evita adicionar a mesma ação de visualização mais de uma vez para o mesmo item.
      const existingAction = grouped[typeKey][itemKey].find((action) => action.codename === permission.codename);
      if (!existingAction) {
        grouped[typeKey][itemKey].push(permission);
      }
    });

    return grouped;
  }

  getScreenOrReportFromCodename(codename: string, name: string): string {
    if (codename.startsWith('report')) {
      const reportName = name.match(/Visualizar (.+)/i);
      return reportName && reportName[1] ? reportName[1] : 'Relatório Sem Nome';
    } else {
      // Remove a ação (primeira palavra) e retorna o restante como nome do objeto
      const actionRemoved = name.split(' ').slice(2).join(' ');
      return actionRemoved || 'Ação Desconhecida';
    }
  }

  getActionName(codename: string): string {
    if (codename.startsWith('view')) {
      return 'Visualizar';
    } else if (codename.startsWith('change')) {
      return 'Atualizar';
    } else if (codename.startsWith('add')) {
      return 'Adicionar';
    } else if (codename.startsWith('delete')) {
      return 'Excluir';
    } else if (codename.startsWith('report')) {
      return 'Visualizar';
    } else if (codename.startsWith('access')) {
      return 'Acesso total';
    } else {
      return 'Ação Desconhecida';
    }
  }

  /**
   * Cancela a operação e retorna para a tela anterior.
   */
  cancel() {
    this.location.back();
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.formGroup.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field),
    };
  }

  /**
   * Valida todos os campos de um formulário reativo.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersGroupsService {

  // Headers
  httpOptions = {
    // tslint:disable-next-line:max-line-length
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    })
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
  }

  getUsersGroups = (url: string): Observable<any> => {
    return this.http.get(url, this.httpOptions);
  }

  getUsersPermissions = (): Observable<any> => {
    return this.http.get(this.authService.endpoint + '/permissions', this.httpOptions);
  }

  createUsersGroups = (group): Observable<any> => {
    const body = {
      name: group.name,
      description: group.description,
      person: group.person,
      permissions: group.permissions,
      auth_hierarchies: group.auth_hierarchies
    };
    return this.http.post(this.authService.endpoint + '/groups/create', body, this.httpOptions);
  }

  deleteUsersGroups = (id): Observable<any> => {
    return this.http.delete(this.authService.endpoint + '/groups/delete/' + id, this.httpOptions);
  }

  detailUserGroup = (id): Observable<any> => {
    return this.http.get(this.authService.endpoint + '/groups/detail/' + id, this.httpOptions);
  }

  updateUsersGroups = (group, id: string): Observable<any> => {
    const body = {
      name: group.name,
      description: group.description,
      person: group.person,
      permissions: group.permissions,
      auth_hierarchies: group.auth_hierarchies
    };
    return this.http.patch(this.authService.endpoint + '/groups/update/' + id, body, this.httpOptions);
  }

}

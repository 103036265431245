import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from '../../../auth/auth.service';
import { UsersService } from './../users.service';
import { UsersProfilesService } from './../../users-profiles/users-profiles.service';
import { Profile } from './../../users-profiles/profile.model';
import { Group } from './../../users-groups/group.model';
import { Permission } from './../../users-groups/permission.model';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

/**
 * O componente `UsersCreateComponent` é responsável por criar novos usuários no sistema.
 * Este componente interage com serviços relacionados a perfis, grupos e permissões.
 */
@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.css']
})
export class UsersCreateComponent implements OnInit, AfterViewInit {
  /**
   * Formulário para a criação de usuários.
   */
  userForm: FormGroup;

  /**
   * Campos para a criação de usuários.
   */
  fields: any[];

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  // Listas de perfis, grupos, permissões e todos os perfis, grupos e permissões disponíveis
  permissions: Permission[];
  groups: Group[];
  profile: Profile;
  allPermissions: Permission[];
  allGroups: Group[];
  allProfiles: [];

  isLoading: boolean = false;

  // Construtor para injetar dependências necessárias
  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private modalAlertService: BootstrapModalAlertService,
    private usersProfilesService: UsersProfilesService,
    private location: Location,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    // Inicializa a leitura de dados necessários
    this.read_data();

    // Inicializa o formulário reativo.
    this.initializeForm();
  }


  blur(event) {
    console.log(event);
  }
  /**
 * Método Angular executado após a inicialização da exibição do componente.
 * Realiza verificações de permissões e obtém listas de perfis, grupos e permissões.
 */
  ngAfterViewInit(): void {
    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissões para acessar a página
    const is_mdb = this.authService && (this.authService.getProfile().indexOf('2') != -1) &&
      this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1));

    if (is_mdb == false && this.authService.checkPermission('view_user') == false) {
      this.router.navigate(['/403']);
    }

    // Obtém todos os grupos disponíveis
    this.usersService.getUsersGroups(this.authService.endpoint + '/groups/nopage', this.authService.getPerson()).pipe(take(1)).subscribe(
      data => {
        this.allGroups = data;
      },
      error => {
        console.error(error.message);
        this.modalAlertService.showAlertDanger('Não foi possível listar os grupos');
      }
    );

    // Obtém todas as permissões disponíveis
    this.usersService.getUsersPermissions().pipe(take(1)).subscribe(
      data => {
        this.allPermissions = data;
      },
      error => {
        console.error(error.message);
        this.modalAlertService.showAlertDanger('Não foi possível listar as permissões');
      }
    );
  }

  /**
   * Inicializa o formulário reativo.
   */
  private initializeForm(): void {
    this.userForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      groups: [[], Validators.required],
      phone_number: [''],
      person: [""],
      profile: [""],
      avatar: [""],
      is_active: false,
      report_db: ''
    });
  }

  /**
    * Método personalizado para recuperar os dados associados aos campos do usuário.
    * Obtém os campos associados aos usuários.
    */
  read_data() {
    this.usersService.getUsersDetails(this.authService.endpoint + '/users/details-list').pipe(take(1)).subscribe(
      data => {
        this.fields = data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  /**
   * Método responsável por salvar um novo usuário.
   * Realiza a criação de um novo usuário no sistema.
   */

  save(){
    // Marca o formulário como submetido.
    this.submitted = true;

    if (this.userForm.valid) {
      if (this.authService.hasPerson() && this.authService.hasToken()) {
        // Preenche os dados do usuário
        this.userForm.get('person').setValue(this.authService.getPerson());
        this.userForm.get('report_db').setValue(this.authService.getReportDb());


        // Envia a requisição para criar o usuário
        this.isLoading = true;
        this.usersService.createUser(this.userForm.value).pipe(take(1)).subscribe(
          data => {
            // this.user = data;
            // Retorna para a lista de usuários após a criação bem-sucedida
            this.location.back();
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
          },
          error => {

            console.log(error.message);

            // Exibe alerta em caso de erro durante a criação do usuário
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          this.isLoading = false;
        });
      } else {
        // Trata o caso em que não há relatório de banco de dados disponível
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.clearAll();
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.userForm);
    }
  }

   /**
   * Reseta o formulário, marcando-o como não submetido e limpando os campos.
   */
   reset() {
    this.submitted = false;
    this.userForm.reset();
  }

  /**
   * Cancela o cadastro e redireciona para a listagem.
   */
  cancel() {
    this.router.navigate(['/users']);
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.userForm.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
  * Valida todos os campos de um formulário reativo.
  * @param formGroup Formulário a ser validado.
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
}

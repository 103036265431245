import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { UsersProfilesService } from '../users-profiles.service';
import { AuthService } from '../../../auth/auth.service';

/**
 * O componente 'UsersProfilesCreateComponent' é responsável pela criação de novos perfis de usuário.
 *
 * Este componente gerencia a criação de novos perfis, verificando permissões,
 * identificação de usuário e ID da aplicação antes de salvar os dados do perfil.
 */
@Component({
  selector: 'app-users-profiles-create',
  templateUrl: './users-profiles-create.component.html',
  styleUrls: ['./users-profiles-create.component.css']
})
export class UsersProfilesCreateComponent implements OnInit {
  // Objeto representando os dados do novo perfil
  profile = { name: '', person: ''};


  // Construtor para injetar dependências necessárias.
  constructor(
    private authService: AuthService,
    private usersProfilesService: UsersProfilesService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissões para acessar a página
    var is_mdb = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService &&
      ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1));

    var is_terceirizada = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService &&
      ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1));

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('add_profile') == false) {
      this.router.navigate(['/403']);
    }
  }

  /**
   * Método para salvar o novo perfil.
   * Verifica a identificação de usuário e ID de aplicação antes de criar o perfil.
   */
  save = () => {
    // Verifica se existe usuario autenticado
    if (this.authService.hasPerson() && this.authService.hasToken()) {
      this.profile.person = this.authService.getPerson();

      // Envia a requisição para criar o perfil
      this.usersProfilesService.createUsersProfiles(this.profile).subscribe(
        data => {
          // Retorna para a lista de perfis após a criação bem-sucedida
          this.location.back();
          this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
        },
        error => {
          // Exibe alerta em caso de erro durante a criação do perfil
          this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        }
      );
    } else {
      // Trata o caso em que não há identificação de pessoa ou ID de aplicação
      this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
      this.authService.doLogout();
    }
  }
}

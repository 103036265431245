import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from './../../../auth/auth.service';
import { UsersService } from './../users.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Profile } from './../../users-profiles/profile.model';
import { Group } from './../../users-groups/group.model';
import { Permission } from './../../users-groups/permission.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-users-update',
  templateUrl: './users-update.component.html',
  styleUrls: ['./users-update.component.css']
})
export class UsersUpdateComponent implements OnInit, AfterViewInit {

  /**
   * Formulário para a criação de usuários.
   */
  userForm: FormGroup;

  /**
   * Campos para a criação de usuários.
   */
  fields: any[];

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  userId: number;

  permissions: Permission[];
  groups: Group[];
  profile: Profile;
  filesrc = '../assets/img/avatar/avatar-1.png';

  allPermissions: Permission[];
  allGroups: Group[] = [];
  allProfiles: any;

  isHorizontal = window['whiteLabelConf']('horizontal');

  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private modalAlertService: BootstrapModalAlertService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {

    // check if user is enable
    this.authService.isEnable();

    // check permission
    var is_mdb = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1))

    var is_terceirizada = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1))

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('change_user') == false) {
      this.router.navigate(['/403']);
    };

    // All permissions
    this.usersService.getUsersPermissions().pipe(take(1)).subscribe(
      data => {
        console.log(data);
        this.allPermissions = data;
      },
      error => {
        console.error(error.message);
        this.modalAlertService.showAlertDanger('Não foi possível listar as permissões');
      }
    );

    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = parseInt(param.get('id'), 10);
      setTimeout(() => {
        this.read(id);
        this.getUserDetails(id);
        this.getAllProfiles();
      }, 100);
    });


  }

  /**
  * Consulta todos os grupos do usuário.
  */
  getUserDetails(Id: number){
    this.usersService.detailUser(Id).pipe(take(1)).subscribe(
      data => {
        data.groups.map(el => {
          const elExists = this.allGroups.some((ag) => ag.id == el.id);
          if(!elExists) {
            this.allGroups.push(el);
          }
        })
        this.orderByAsc()
      },
      error => {
        console.error(error.error);
        this.modalAlertService.showAlertDanger('Não foi possível recuperar os perfis usuário.');
      }
    )
  }

  /**
  * Consulta todos os grupos da base de dados do usuário.
  */
  getAllProfiles() {
    this.usersService.getUsersGroups(this.authService.endpoint + '/groups/nopage', this.authService.getPerson())
    .subscribe((data: any[]) => {
      data.map(el => {
        const elExists = this.allGroups.some((ag) => ag.id == el.id);
        if(!elExists) {
          this.allGroups.push(el);
        }
      })
      this.orderByAsc()
    })
  }

  orderByAsc(){
    this.allGroups.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }

  /**
     * Inicializa o formulário reativo.
     */
  private initializeForm(): void {
    this.userForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      groups: [[], Validators.required],
      phone_number: [''],
      app_id: [""],
      person: [""],
      profile: [""],
      avatar: [""],
      is_active: false,
      id: []
    });
  }

  read(Id: number) {
    this.usersService.detailUser(Id).pipe(take(1)).subscribe(
      data => {

        this.userForm.get('id').setValue(data.id);
        this.userForm.get('app_id').setValue(data.app_id);
        this.userForm.get('email').setValue(data.email);
        this.userForm.get('person').setValue(data.person);
        this.userForm.get('first_name').setValue(data.first_name);
        this.userForm.get('last_name').setValue(data.last_name);
        this.userForm.get('is_active').setValue(data.is_active);
        this.userForm.get('phone_number').setValue(data.phone_number);
        this.userForm.get('groups').setValue(data.groups.map(g => g.id));
      },
      error => {
        console.error(error.error);
        this.modalAlertService.showAlertDanger('Não foi possível recuperar usuário.');
      }
    )
  }


  updateUser() {
    // Marca o formulário como submetido.
    this.submitted = true;

    if (this.userForm.valid) {

      if (this.authService.hasPerson() && this.authService.hasToken()) {

        this.userForm.get('person').setValue(this.authService.getPerson());

        this.isLoading = true;
        this.usersService.updateUser(this.userForm.value).pipe(take(1)).subscribe(
          data => {
            // return to list
            this.router.navigate(['/users']);
            this.modalAlertService.showAlertSuccess('Registro atualizado com sucesso.');
          },
          error => {
            console.log(error.message);
            this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
          }
        ).add(() => {
          this.isLoading = false;
        })
      } else {
        this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
        this.authService.clearAll();
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.userForm);
    }
  }

  /**
   * Cancela o cadastro e redireciona para a listagem.
   */
  cancel() {
    this.router.navigate(['/users']);
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.userForm.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
  * Valida todos os campos de um formulário reativo.
  * @param formGroup Formulário a ser validado.
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { GuidedTour } from 'ngx-guided-tour';
import { HowToGuideService } from 'src/app/core/services/how-to-guide/how-to-guide.service';

@Component({
  selector: 'app-button-how-to',
  templateUrl: './button-how-to.component.html',
  styleUrls: ['./button-how-to.component.css'],
})
export class ButtonHowToComponent implements OnInit {

  @Input() sequence: GuidedTour;
  constructor(public tour: HowToGuideService) {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

  export class PubsubService {
    botServiceUrl = environment.botUrl;
    httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`
    });

    constructor(private http: HttpClient,
      private authService: AuthService) { }


    botPublisher(forceSync): Observable<any> {
      return this.http.post(this.botServiceUrl + 'bot/publisher', forceSync, {headers: this.httpHeaders});
    }

    botPublisherStatus(): Observable<any> {
      return this.http.post(this.botServiceUrl + 'bot/publisher_status', {headers: this.httpHeaders});
    }
  }

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente responsável pela criação de imagens de IOT.
 */
@Component({
  selector: 'app-equipment-image-create',
  templateUrl: './equipment-image-create.component.html',
  styleUrls: ['./equipment-image-create.component.css']
})
export class EquipmentImageCreateComponent implements OnInit {

  /**
   * Formulário reativo para a criação de imagens de IOT.
   */
  form: FormGroup;

  /**
   * Lista de IOT disponíveis.
   */
  equipments = [];

  /**
   * Objeto FormData para envio de arquivos.
   */
  formData = new FormData();

  /**
   * Flag que indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Extensões permitidas para arquivos de imagem.
   */
  allowedExtensions: string[] = ['.png', '.jpeg', '.jpg', '.jfif', '.gif'];

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Inicializa o componente. Verifica a habilitação do usuário e as permissões.
   * Inicializa o formulário e obtém a lista de IOT disponíveis.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica as permissões de acesso.
    if (!this.authService.checkPermission('add_equipmentimage')) {
      this.router.navigate(['/403']);
    };

    // Obtém a lista de IOT disponíveis.
    // let company = this.authService.getPerson();
    // let url = this.authService.endpoint_main + 'plant/equipment/list/nopage';
    // this.plantService.getEquipmentAllList(url, company).pipe(take(1)).subscribe(
    //   data => {
    //     this.equipments = data;
    //   }, error => { }
    // );

    // Inicializa o formulário com as validações necessárias.
    this.form = this.formBuilder.group({
      company: '',
      name: '',
      description: '',
      qr_code: '',
      bundle_equipment_id: ['',],
      image: ''
    });

    this.onFindAllBundlers();
  }

  onFindAllBundlers() {
    const url = this.authService.endpoint_main + 'plant/equipmentbundle/list'
    const company = this.authService.getPerson();

    this.plantService.getEquipmentBundleAllList(url, company).subscribe((data) => {
      this.equipments = data;
    });
  }

  /**
   * Verifica se um campo do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo for inválido e foi submetido, caso contrário, `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.form.get(field).valid && this.submitted;
  }

  /**
   * Retorna as classes de estilo para exibir erros de validação no campo.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto de classes CSS para estilizar o campo com erro, se aplicável.
   */
  displayFieldCss(field: string): { 'is-invalid': boolean } {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   * @param formGroup Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Manipula a mudança de imagem quando um arquivo é selecionado no campo de upload.
   * @param event O evento de mudança de imagem.
   */
  onImageChange(event: Event): void {
    // Obtém o elemento de entrada de arquivo do evento.
    const inputElement = event.target as HTMLInputElement;
    // Obtém os arquivos selecionados.
    const files = inputElement.files as FileList;

    // Limpa o formData antes de adicionar novos arquivos.
    this.formData = new FormData();

    // Itera sobre os arquivos selecionados.
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        // Verifica se o tipo do arquivo é uma imagem.
        if (files[i].type.startsWith('image/')) {
          // Se for uma imagem, adiciona ao formData.
          this.formData.append(files[i].name, files[i]);

          // Remove qualquer erro no campo 'imagem'.
          this.form.get('image').setErrors(null);
        } else {
          // Se não for uma imagem, define um erro no campo 'image' e limpa o valor do input.
          this.form.get('image').setErrors({ 'invalidFileType': true });
          inputElement.value = '';
        }
      }
    }
  }

  /**
 * Salva as informações do formulário.
 */
  save(): void {
    // Define que o formulário foi enviado.
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe usuario autenticado
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Define os valores de company no formulário.
        this.form.get('company').setValue(this.authService.getPerson());

        // Chama o serviço para criar a imagem do IOT.
        this.isLoading = true;
        this.plantService.createEquipmentImage(this.form.value).pipe(take(1)).subscribe(
          data => {
            // Chama o serviço para enviar o arquivo da imagem do IOT, se disponível.
            this.plantService.createEquipmentImageUpload(this.formData, data.id).pipe(take(1)).subscribe(
              data => { /* Lida com o sucesso do envio da imagem do IOT */ },
              error => { /* Lida com erros no envio da imagem do IOT */ }
            );

            // Exibe um alerta de sucesso.
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');

            // Navega de volta para a página anterior.
            this.location.back();
          },
          error => {
            // Exibe um alerta de erro ao incluir o registro.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de erro caso não haja um ID de aplicativo ou pessoa disponíveis.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário não seja válido, valida todos os campos.
      this.validateAllFormFields(this.form);
    }
  }


  /**
   * Reseta o formulário e a flag de submissão.
   */
  reset(): void {
    this.submitted = false;
    this.form.reset();
  }

  /**
   * Obtém a mensagem de erro para o tipo de arquivo inválido.
   * @returns A mensagem de erro ou `null` se não houver erro.
   */
  get invalidFileTypeError(): string {
    const logoControl = this.form.get('image');
    return logoControl.hasError('invalidFileType') ? 'Tipo de arquivo inválido. Por favor, selecione uma imagem.' : null;
  }
}

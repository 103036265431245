<section class="section">
  <div class="section-header person-profile-header_read">
    <h1>{{ layout.aliasTitle('Perfil de pessoas') }}</h1>
    <!-- <div class="section-header-breadcrumb person-profile-header-breadcrumb_read">
          <div class="breadcrumb-item active"><a href="">Dashboard</a></div>
          <div class="breadcrumb-item">Perfil de Pessoa Física/Jurídica</div>
      </div> -->
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" [ngClass]="{ 'bg-white' : layout.layoutType === 'MYECOS' }">
            <h4><a class="btn btn-primary" routerLink="/personsperfil/create">Novo</a></h4>
            <div class="card-header-action"></div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-md">
                <thead>
                  <tr>
                    <th>
                      <div class="d-flex justify-content-between">
                        <span>Nome</span>
                        <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Nome')"></i>
                      </div>
                      <div [class.d-none]="selectedFilter !== 'Nome'">
                        <ng-container *ngTemplateOutlet="options"></ng-container>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-between">
                        <span>Ativo</span>
                        <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Ativo')"></i>
                      </div>
                      <div [class.d-none]="selectedFilter !== 'Ativo'">
                        <ng-container *ngTemplateOutlet="options"></ng-container>
                      </div>
                    </th>
                    <th class="w-25">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of perfilsFiltered | slice:offset:limit">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      <ng-template [ngIf]="item.active == true" [ngIfElse]="elseBlock">
                        <i class="fa fa-check-circle text-success"></i>
                      </ng-template>
                      <ng-template #elseBlock>
                        <i class="fa fa-times-circle text-danger"></i>
                      </ng-template>
                    </td>
                    <td>
                      <app-default-icon-button [tooltiptext]="'Editar'" [type]="'warning'" (pressed)="readId(item.id)">
                        <i class="fa fa-edit fa-lg mx-0"></i>
                      </app-default-icon-button>

                     <app-default-icon-button [tooltiptext]="'Excluir'" [type]="'danger'" (pressed)="onDelete(item)">
                      <i class="fa fa-trash fa-lg mx-0"></i>
                     </app-default-icon-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-paginator-control [content]="perfilsFiltered" (change)="onPaginate($event)"></app-paginator-control>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #options>
  <app-table-filters-popup 
  [visible]="isOptionsOpened" 
  [field]="selectedFilter" 
  [hasStatus]="selectedFilter == 'Ativo'" 
  [hasSearch]="selectedFilter != 'Ativo'"
  [hasOrdernator]="selectedFilter != 'Ativo'"
  (orderBy)="onOrderBy($event)" 
  (close)="onHideFilters()" 
  (search)="onSearch($event)"
  (clear)="onClearFilters()"
  (status)="onFilterBoolean($event)">
  </app-table-filters-popup>
</ng-template>

<section class="section">
    <div class="section-header">
        <h1>{{ layout.aliasTitle('Imagem') }}</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipmentimages">Imagem de equipamento</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header">
                    <h4>Upload de {{ layout.aliasTitle('Imagem') }}</h4>
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="plant_equipment">{{ layout.aliasTitle('Agrupador') }}</label>
                                <select class="form-control" formControlName="plant_equipment" [ngClass]="displayFieldCss('plant_equipment')">
                                    <option *ngFor="let item of equipments" [selected]="selectedEquipment == item.id" value="{{ item.id }}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione um {{ layout.aliasTitle('Agrupador') }}
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="qr_code">QR code</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="qr_code">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="description">Descrição</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="description">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="image">Imagem</label>
                                <br>
                               <a href="{{image}}" target="blank"><img src="{{image}}" alt="image" width="200px"></a>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-right">
                    <app-default-button [isLoading]="isLoading" (pressed)="update()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

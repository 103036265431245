import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
@Injectable()
export class DataService {

  private paramSource1 = new BehaviorSubject(null);
  private paramSource2 = new BehaviorSubject(null);
  private paramSource3 = new BehaviorSubject(null);

  tabs = this.paramSource1.asObservable();
  filters = this.paramSource2.asObservable();
  isDashboard = this.paramSource3.asObservable();

  folders_crud: any[] = []
  items_crud: any[] = []

  folders: any[] = []
  items: any[] = []

  constructor() { }

  changeParam(param: any[]) {
    this.paramSource1.next(param)
  }

  changeFilters(param: any[]) {
    this.paramSource2.next(param)
  }

  changeIsDashboard(param: boolean) {
    this.paramSource3.next(param)
  }

  getIsDashboard(): Observable<any> {
    return this.paramSource3.asObservable();
  }
}
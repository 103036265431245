<ul class="nav nav-pills m-t-30 m-b-30">
  <li class=" nav-item" (click)="setTabIndex(0)">
    <a href="#navpills-0" class="nav-link active" data-toggle="tab" aria-expanded="false">{{ layout.aliasTitle('Pessoas') }}</a>
  </li>
  <li class="nav-item" (click)="setTabIndex(1)">
    <a href="#navpills-1" class="nav-link" data-toggle="tab" aria-expanded="false">{{ layout.aliasTitle('Perfil de pessoas') }}</a>
  </li>
</ul>
<div class="tab-content br-n pn" [ngClass]="{ 'mt-3 bg-white': layout.layoutType == 'MYECOS' }">
  <div id="navpills-0" class="tab-pane active" *ngIf="selectedIndex == 0">
    <app-person-read>
    </app-person-read>
  </div>
  <div id="navpills-1" class="tab-pane" *ngIf="selectedIndex == 1">
    <app-persons-perfil-read>
    </app-persons-perfil-read>
  </div>
</div>

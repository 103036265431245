<div class="d-flex flex-column py-3 border rounded shadow-hover my-3 px-3" [class.shadow]="!isCollapsed">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <button class="btn btn-sm mx-0 my-0 pointer" (click)="onHandleCollapse()">
        <i class="fa-solid fa-folder" *ngIf="isCollapsed"></i>
        <i class="fa-solid fa-folder-open" *ngIf="!isCollapsed"></i>
      </button>
      <span class="">{{ data.company }}</span>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <span class="">{{ data.plants.length }}</span>
        <i class="fa-solid fa-layer-group"></i>
      </div>
      <i class="fa-solid fa-trash mx-2 text-error pointer" (click)="onRemoveAll()"></i>
    </div>
  </div>

  <div class="d-flex align-items-center mx-3 mt-2 badges-content" *ngIf="!isCollapsed" [@fade]>
    <span class="d-flex align-items-center badge badge-primary mr-1 my-1" *ngFor="let item of data.plants;index as i" style="width: fit-content" (click)="onRemoveItem(i)">
      <i class="fa-solid fa-xmark mr-1"></i>
      {{ item.person.trade_name }}
    </span>
  </div>
</div>

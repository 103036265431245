import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonRoutingModule } from './person-routing.module';
import { PersonUpdateComponent } from './person-update/person-update.component';
import { PersonCreateComponent } from './person-create/person-create.component';
import { PersonReadComponent } from './person-read/person-read.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [PersonReadComponent, PersonCreateComponent, PersonUpdateComponent],
  imports: [CommonModule, PersonRoutingModule, SharedModule, ReactiveFormsModule, NgxMaskModule],
  exports: [PersonReadComponent, PersonCreateComponent, PersonUpdateComponent],
})
export class PersonModule {}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { EnergyImpact } from './entities/energy-impact';
import { Scenery } from './entities/scenery';

@Injectable({
  providedIn: 'root'
})
export class SceneryService {
  repoUrl = environment.simulationUrl;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

   // Requisições de Cenário
   saveScenery(scenery: Scenery): Observable<any> {
      var exportType = {
         id: scenery.id,
         name: scenery.name,
         creationDate: new Date(),
         baseYear: scenery.baseYear, comparisonYear: scenery.comparisonYear,
         industrialUnity: scenery.industrialUnity.id,
         projects: scenery.projects.map(x => {
         return {
            name: x.name, startPeriod: x.startPeriod, endPeriod: x.endPeriod, valueImpact: x.valueImpact,
            location: x.location.id, projectType: x.projectType.id,
            energyImpact: x.energyImpact.id,
            impactType: x.impactType.id, impactValueType: x.impactValueType.id}
         })
      }
      const body = exportType;
      return this.http.post(this.repoUrl + 'save_scenery', body, { headers: this.httpHeaders });
  }

  getScenaries(): Observable<any> {
    return this.http.get(this.repoUrl + 'search_scenery', { headers: this.httpHeaders });
  }

  getScenary(idScenery: number): Observable<any> {
    return this.http.get(this.repoUrl + 'get_scenery/'+idScenery, { headers: this.httpHeaders });
  }

  deleteScenery(idScenery: number): Observable<any> {
    const body = { 'id': idScenery };
    return this.http.post(this.repoUrl + 'delete_scenery', body, { headers: this.httpHeaders });
  }

  simulate(idScenery: number): Observable<any> {
    const body = { 'id': idScenery };
    return this.http.post(this.repoUrl + 'simulate', body, { headers: this.httpHeaders });
  }

  // Requisições de Listas
  getUnities(): Observable<any> {
    return this.http.get(this.repoUrl + 'unity', { headers: this.httpHeaders });
  }

  getLocal(): Observable<any> {
   return this.http.get(this.repoUrl + 'local', { headers: this.httpHeaders });
 }

  getEnergyImpacts(): Observable<any> {
    return this.http.get(this.repoUrl + 'energy-impact', { headers: this.httpHeaders });
  }

  getProjectTypes(): Observable<any> {
    return this.http.get(this.repoUrl + 'project-type', { headers: this.httpHeaders });
  }

  getImpactTypes(): Observable<any> {
    return this.http.get(this.repoUrl + 'impact-type', { headers: this.httpHeaders });
  }

  getImpactValueTypes(): Observable<any> {
    return this.http.get(this.repoUrl + 'impact-value-type', { headers: this.httpHeaders });
  }
  simulationPubisher(forceSync): Observable<any> {
   return this.http.post(this.repoUrl + 'simulation/publisher', forceSync, { headers: this.httpHeaders });
  }

  simulationPublisherStatus(): Observable<any> {
    return this.http.post(this.repoUrl + 'simulation/publisher_status', { headers: this.httpHeaders });
  }
}
// Indicador de comparação: combo vai ser 'energia' ou 'emissão'
/*
{
   "status":"success",
   "data":{
      "projections":[
         {
            "name":"Projeção de Emissões",
            "unity":"tCO2eq",
            "data":[
               [
                  2016, // X (ano base)
                  956302, // Y (valor original)
                  956302 // Z (valor projeção)
               ],
               [
                  2017,
                  1077610,
                  1077610
               ],
               [
                  2018,
                  1073910,
                  1073910
               ],
               [
                  2019,
                  1067112,
                  1067112
               ],
               [
                  2020,
                  1096398,
                  1096398
               ],
               [
                  2021,
                  1094306,
                  1094306
               ],
               [
                  2022,
                  1060760,
                  1060760
               ],
               [
                  2023,
                  1035364,
                  1035364
               ],
               [
                  2024,
                  1022590,
                  1022590
               ],
               [
                  2025,
                  1031112,
                  1031112
               ],
               [
                  2026,
                  1043852,
                  1043852
               ],
               [
                  2027,
                  1035364,
                  1035364
               ],
               [
                  2028,
                  1014046,
                  1014046
               ],
               [
                  2029,
                  1009765,
                  1009765
               ],
               [
                  2030,
                  1001187,
                  1001187
               ]
            ]
         },
         {
            "name":"Projeção de Energia",
            "unity":"GJ",
            "data":[
               [
                  2016,
                  14420034,
                  14420034
               ],
               [
                  2017,
                  15475483,
                  15475483
               ],
               [
                  2018,
                  15424030,
                  15424030
               ],
               [
                  2019,
                  15256297,
                  15256297
               ],
               [
                  2020,
                  16292327,
                  16292327
               ],
               [
                  2021,
                  11721236,
                  11721236
               ],
               [
                  2022,
                  11458188,
                  11456313
               ],
               [
                  2023,
                  11177924,
                  11175424
               ],
               [
                  2024,
                  11011120,
                  11008620
               ],
               [
                  2025,
                  11124298,
                  11123048
               ],
               [
                  2026,
                  11279248,
                  11279248
               ],
               [
                  2027,
                  11177924,
                  11177924
               ],
               [
                  2028,
                  10890039,
                  10890039
               ],
               [
                  2029,
                  10826535,
                  10826535
               ],
               [
                  2030,
                  10693600,
                  10693600
               ]
            ]
         },
         {
            "name":"Projeção intensidade de emissão",
            "unity":"tCO2eq/tCueq",
            "data":[
               [
                  2016,
                  956302,
                  11.289127611852201
               ],
               [
                  2017,
                  1077610,
                  12.934941783699436
               ],
               [
                  2018,
                  1073910,
                  13.422197225346832
               ],
               [
                  2019,
                  1067112,
                  13.192137470639139
               ],
               [
                  2020,
                  1096398,
                  13.158881421027365
               ],
               [
                  2021,
                  1094306,
                  13.599438279046069
               ],
               [
                  2022,
                  1060760,
                  12.856917762559846
               ],
               [
                  2023,
                  1035364,
                  12.549106114780923
               ],
               [
                  2024,
                  1022590,
                  12.394279134597904
               ],
               [
                  2025,
                  1031112,
                  12.497569844251863
               ],
               [
                  2026,
                  1043852,
                  12.651984728198292
               ],
               [
                  2027,
                  1035364,
                  12.549106114780923
               ],
               [
                  2028,
                  1014046,
                  12.290721774437912
               ],
               [
                  2029,
                  1009765,
                  12.238834010059996
               ],
               [
                  2030,
                  1001187,
                  12.134864553663414
               ]
            ]
         },
         {
            "name":"Projeção intensidade de energia",
            "unity":"GJ/tCueq",
            "data":[
               [
                  2016,
                  14420034,
                  170.22823751623184
               ],
               [
                  2017,
                  15475483,
                  185.75780818629215
               ],
               [
                  2018,
                  15424030,
                  192.77627796525434
               ],
               [
                  2019,
                  15256297,
                  188.605476573124
               ],
               [
                  2020,
                  16292327,
                  195.5392102736438
               ],
               [
                  2021,
                  11721236,
                  145.6651298047647
               ],
               [
                  2022,
                  11458188,
                  138.87871038118902
               ],
               [
                  2023,
                  11177924,
                  135.48177686200836
               ],
               [
                  2024,
                  11011120,
                  133.46003272528938
               ],
               [
                  2025,
                  11124298,
                  134.83180413308284
               ],
               [
                  2026,
                  11279248,
                  136.70987212896188
               ],
               [
                  2027,
                  11177924,
                  135.48177686200836
               ],
               [
                  2028,
                  10890039,
                  131.99247318344342
               ],
               [
                  2029,
                  10826535,
                  131.22277437731046
               ],
               [
                  2030,
                  10693600,
                  129.61153869462456
               ]
            ]
         }
      ],
      "projectionDetails_energy":[
         {
            "baseYear":2016,
            "valueifNothingChange":14420034,
            "valueScenery":14420034,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2017,
            "valueifNothingChange":15475483,
            "valueScenery":15475483,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2018,
            "valueifNothingChange":15424030,
            "valueScenery":15424030,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2019,
            "valueifNothingChange":15256297,
            "valueScenery":15256297,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2020,
            "valueifNothingChange":16292327,
            "valueScenery":16292327,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2021,
            "valueifNothingChange":11721236,
            "valueScenery":11721236,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2022,
            "valueifNothingChange":11458188,
            "valueScenery":11456313,
            "valueVariation":-1875,
            "valueVariationPercentage":-0.016363843916682115
         },
         {
            "baseYear":2023,
            "valueifNothingChange":11177924,
            "valueScenery":11175424,
            "valueVariation":-2500,
            "valueVariationPercentage":-0.02236551259428853
         },
         {
            "baseYear":2024,
            "valueifNothingChange":11011120,
            "valueScenery":11008620,
            "valueVariation":-2500,
            "valueVariationPercentage":-0.02270432072305088
         },
         {
            "baseYear":2025,
            "valueifNothingChange":11124298,
            "valueScenery":11123048,
            "valueVariation":-1250,
            "valueVariationPercentage":-0.011236664102310097
         },
         {
            "baseYear":2026,
            "valueifNothingChange":11279248,
            "valueScenery":11279248,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2027,
            "valueifNothingChange":11177924,
            "valueScenery":11177924,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2028,
            "valueifNothingChange":10890039,
            "valueScenery":10890039,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2029,
            "valueifNothingChange":10826535,
            "valueScenery":10826535,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2030,
            "valueifNothingChange":10693600,
            "valueScenery":10693600,
            "valueVariation":0,
            "valueVariationPercentage":0
         }
      ],
      "projectionDetails_emit":[
         {
            "baseYear":2016,
            "valueifNothingChange":956302,
            "valueScenery":956302,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2017,
            "valueifNothingChange":1077610,
            "valueScenery":1077610,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2018,
            "valueifNothingChange":1073910,
            "valueScenery":1073910,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2019,
            "valueifNothingChange":1067112,
            "valueScenery":1067112,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2020,
            "valueifNothingChange":1096398,
            "valueScenery":1096398,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2021,
            "valueifNothingChange":1094306,
            "valueScenery":1094306,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2022,
            "valueifNothingChange":1060760,
            "valueScenery":1060760,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2023,
            "valueifNothingChange":1035364,
            "valueScenery":1035364,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2024,
            "valueifNothingChange":1022590,
            "valueScenery":1022590,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2025,
            "valueifNothingChange":1031112,
            "valueScenery":1031112,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2026,
            "valueifNothingChange":1043852,
            "valueScenery":1043852,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2027,
            "valueifNothingChange":1035364,
            "valueScenery":1035364,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2028,
            "valueifNothingChange":1014046,
            "valueScenery":1014046,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2029,
            "valueifNothingChange":1009765,
            "valueScenery":1009765,
            "valueVariation":0,
            "valueVariationPercentage":0
         },
         {
            "baseYear":2030,
            "valueifNothingChange":1001187,
            "valueScenery":1001187,
            "valueVariation":0,
            "valueVariationPercentage":0
         }
      ]
   }
}
*/

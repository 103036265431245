<section class="section mt-3 px-3">
  <div class="section-header dealership-header_update">
    <h1>{{ layout.aliasTitle('Concessionárias') }}</h1>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>Editar {{ layout.aliasTitle('Concessionárias') }}</h4>
        </div>
        <div class="card-body">
          <form method="POST" [formGroup]="form">
            <div class="row mb-5">
              <div class="col-12 col-md-6">
                <label for="Cliente">Cliente</label>
                <select class="form-control" formControlName="person" [ngClass]="displayFieldCss('person')">
                  <option *ngFor="let item of persons" value="{{ item.id }}">
                    <ng-template [ngIf]="item.is_physical == true" [ngIfElse]="elseBlock">
                      {{ item.first_name }}&nbsp;{{ item.last_name }}
                    </ng-template>
                    <ng-template #elseBlock>
                      {{ item.trade_name }}
                    </ng-template>
                  </option>
                </select>
                <div class="invalid-feedback">Por favor selecione um(a) {{ layout.aliasTitle('Grupo') }}</div>
              </div>
              <div class="col-12 col-md-6">
                <label for="Cliente">{{ layout.aliasTitle('Concessionárias') }}</label>
                <select
                  class="form-control"
                  formControlName="concessionary"
                  [ngClass]="displayFieldCss('concessionary')"
                  (change)="onSelectConcessionary()"
                >
                  <option value="" disabled>Selecione</option>
                  <option *ngFor="let item of data; index as i" value="{{ i }}">{{ item.type }}</option>
                </select>
                <div class="invalid-feedback">Por favor selecione um(a) {{ layout.aliasTitle('Grupo') }}</div>
              </div>
            </div>

            <div class="row">
              <ng-container *ngFor="let item of selectedConcessionary?.items">
                <app-field-engine
                  [field]="item.field"
                  [reportFilters]="[]"
                  [hasLabel]="true"
                  [positionField]="item.field.size"
                  #fields
                  #wrapper
                >
                </app-field-engine>
                <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
              </ng-container>
            </div>
          </form>
        </div>
        <div class="card-footer text-right">
          <app-default-button [isLoading]="isLoading" (pressed)="onSubmit()"></app-default-button>
          <app-default-button [label]="'Limpar'" [type]="'secondary'" (pressed)="reset()"></app-default-button>
          <app-default-button
            [label]="'Cancelar'"
            [type]="'secondary'"
            [navigateTo]="'/dealerships'"
          ></app-default-button>
        </div>
      </div>
    </div>
  </div>
</section>

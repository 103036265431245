import { Component, HostListener } from '@angular/core';
import { BaseFunc } from './components/shared/base-func/base-func';
import { environment } from './../environments/environment';
import { AuthService } from './auth/auth.service';
import { LayoutService } from './core/services/layout/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent extends BaseFunc {
  @HostListener('window:load', ['$event'])
  onLoad(event: any) {
    const height = event.currentTarget.innerHeight;
    const width = event.currentTarget.innerWidth;
    const layout = window['whiteLabelConf']('horizontal')
    this.layout.onSetScreenDetails({ height, width, layout: layout ? 'MYECOS' : 'DEFAULT' })

    const screenWidth = window.innerWidth;
    if (screenWidth < 1170) {
      document.getElementById('main-wrapper')?.classList.add('mini-sidebar');
      this.layout.onHandleLogo(true)
    } else {
      document.getElementById('main-wrapper')?.classList.remove('mini-sidebar')
      this.layout.onHandleLogo(false)
    }
  }

  @HostListener('window:resize', ['$event'])
  showMiniSidebarInLowResolution() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    this.layout.onSetScreenDetails({ height, width, layout: this.layout.layoutType })
    
    const screenWidth = window.innerWidth;
    if (screenWidth < 1170) {
      document.getElementById('main-wrapper')?.classList.add('mini-sidebar');
      this.layout.onHandleLogo(true)
    } else {
      document.getElementById('main-wrapper')?.classList.remove('mini-sidebar')
      this.layout.onHandleLogo(false)
    }
  }

 
  hasLoaded: boolean = false;
  title = 'uicare';

  constructor(
    private authService: AuthService,
    private layout: LayoutService) {
      super()
  }

  ngOnInit() {
    this.loadEnvironmentSpecificScript();
    this.loadStyles();
    this.showMiniSidebarInLowResolution();
  }

  loadStyles() {
    var self = this;
    window['whiteLabelConf'] = function(key) {
      var base_conf = {
        logo: 'assets/img/logo-maior.png',
        navlogo: '/assets/alfa_adminbite/assets/images/logo-completa.svg',
        navlogomini: '/assets/alfa_adminbite/assets/images/logo-light-icon1.png',
        title: 'IndustryCare Analytics',
        base_style: '/assets/alfa_adminbite/dist/css/style.min.css',
        custom_style: '/assets/css/custom/custom.css',
        favicon_style: '/assets/alfa_adminbite/assets/images/favicon.png',
        bg_img: '../assets/img/bg.jpg',
        horizontal: false,
        templateScript: '../assets/alfa_adminbite/dist/js/app.init.js'
      };
      var config = environment.while_label_config[window.location.host] ? environment.while_label_config[window.location.host] : base_conf;
      return config[key] != undefined ? config[key] : '';
    };
    var after = document.querySelector('base');
    var items = [
      { name: 'favicon_style', rel: 'icon', type: 'image/png' },
      { name: 'base_style', rel: 'stylesheet', type: 'text/css' },
      { name: 'custom_style', rel: 'stylesheet', type: 'text/css' },
    ];
    const head = document.getElementsByTagName('head')[0];
    items.forEach(function(val) {
      var script = document.createElement('link');
      script.rel = val.rel;
      script.type = val.type;
      script.className = 'link_' + val.name;
      script.href = window['whiteLabelConf'](val.name);
      if (head !== null && head !== undefined && document.querySelectorAll('head .' + script.className).length == 0)
        after.parentNode.insertBefore(script, after.nextSibling);
      after = script;
    });
    document.querySelector('title').innerHTML = window['whiteLabelConf']('title');

    var gLoad = self.$j('#global_load_screen');
    if (gLoad.length > 0)
      setTimeout(function() { gLoad[0].remove() }, 200);
  }

  hideSnackbarModal() {
    let closeButton: HTMLElement = document.getElementById('#snack-modal-close') as HTMLElement;
    closeButton.click();
  }

  loadEnvironmentSpecificScript() {
    const currentHost = window.location.host;
    const config = environment.while_label_config[currentHost];

    if (config && config.templateScript) {
      this.loadScript(config.templateScript);
    }
  }

  loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    document.body.appendChild(script);
  }

  
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../auth/auth.service';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PersonService } from './../person.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PersonPerfil } from './../../persons-perfil/person-perfil.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular responsável pela atualização de informações de pessoa.
 */
@Component({
  selector: 'app-person-update',
  templateUrl: './person-update.component.html',
  styleUrls: ['./person-update.component.css'],
})
export class PersonUpdateComponent implements OnInit, OnChanges {
  @Output() clear: EventEmitter<boolean> = new EventEmitter();
  @Output() created: EventEmitter<boolean> = new EventEmitter();

  @Input() parentFormStatus: boolean = false;
  @Input() personId: any;


  /**
   * FormGroup para gerenciar o formulário de atualização da pessoa.
   */
  form: FormGroup;

  /**
   * Lista de perfis de pessoa.
   */
  personProfiles: PersonPerfil[];

  /**
   * Referência para elementos da tabela de contatos.
   */
  @ViewChildren('tbodyContact') tbodyContact: QueryList<ElementRef>;

  /**
   * Referência para elementos da tabela de arquivos.
   */
  @ViewChildren('tbodyFile') tbodyFile: QueryList<ElementRef>;

  /**
   * Classe CSS para controle de visibilidade de formulário CNPJ.
   */
  select_form_cnpj = '';

  /**
   * Classe CSS para controle de visibilidade de formulário CPF.
   */
  select_form_cpf = 'show active';

  /**
   * Identificador do contato a ser removido.
   */
  contact_remove = '';

  /**
   * Indica se há erros no formulário.
   */
  has_error = false;

  /**
   * Mensagens de validação provenientes do backend.
   */
  backend_validation_message = [];

  /**
   * Informações da pessoa.
   */
  person = '';
  ufs = '';
  selectedUf = '';
  cities = '';
  selectedCity = '';
  selectedProfile = '';

  /**
   * Item selecionado (contato, arquivo).
   */
  selectedItem = { id: '', name: '', active: true };

  /**
   * Indica se o formulário foi submetido.
   */
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.read(this.personId);

    console.log(this.personId);
  }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (this.authService.checkPermission('change_person') == false) {
      this.router.navigate(['/403']);
    }

    // Inicializa o formulário com os campos e validadores necessários.
    this.form = this.formBuilder.group({
      id: [''],
      trade_name: [''],
      company_name: [''],
      cnpj: [''],
      cpf: [''],
      is_physical: [true],
      first_name: [''],
      last_name: [''],
      icms_free: [false],
      person_address: this.formBuilder.group({
        id: [''],
        street: ['', Validators.required],
        number: ['', Validators.maxLength(9)],
        complement: ['', Validators.maxLength(40)],
        sector: ['', Validators.maxLength(40)],
        zipcode: ['', Validators.required],
        city: ['', Validators.required],
        uf: ['', Validators.required],
        email: ['', [Validators.email]],
        phone: ['', Validators.required],
        site: [''],
      }),
    });


  }

  /**
   * Realiza a leitura dos dados da pessoa com o ID fornecido.
   *
   * @param id - ID da pessoa a ser lida.
   */
  read = (id) => {
    // Obtém informações sobre a pessoa e realiza a inicialização do formulário.
    this.personService
      .getPerson(id)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data.is_physical) {
            let value = 'cpf';
            this.focusPerson(value);
          } else {
            let value = 'cnpj';
            this.focusPerson(value);
          }

          this.form.get('id').setValue(data.id);
          this.form.get('trade_name').setValue(data.trade_name);
          this.form.get('company_name').setValue(data.company_name);
          this.form.get('cnpj').setValue(data.cnpj);
          this.form.get('cpf').setValue(data.cpf);
          this.form.get('is_physical').setValue(data.is_physical);
          this.form.get('first_name').setValue(data.first_name);
          this.form.get('last_name').setValue(data.last_name);
          this.form.get('icms_free').setValue(data.icms_free);

          this.selectedProfile = data.profile;
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar perfil de pessoa');
        },
      );

    // Obtém informações sobre UFs para o endereço.
    this.personService
      .getUfs(this.authService.endpoint_main + 'uf')
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.ufs = data;
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de UFs');
        },
      );

    // Obtém o endereço da pessoa.
    this.personService
      .getPersonAddress(id)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.form.get('person_address.id').setValue(data.id);
          this.form.get('person_address.street').setValue(data.street);
          this.form.get('person_address.number').setValue(data.number);
          this.form.get('person_address.complement').setValue(data.complement);
          this.form.get('person_address.sector').setValue(data.sector);
          this.form.get('person_address.zipcode').setValue(data.zipcode);
          this.form.get('person_address.city').setValue(data.city.id);
          this.form.get('person_address.uf').setValue(data.uf.id);
          this.form.get('person_address.email').setValue(data.email);
          this.form.get('person_address.phone').setValue(data.phone);
          this.form.get('person_address.site').setValue(data.site);
          let tmp_city = data.city.id;
          this.selectedUf = data.uf.id;
          this.personService.getCities(this.authService.endpoint_main + 'city/' + this.selectedUf).subscribe(
            (data) => {
              this.cities = data;
              this.selectedCity = tmp_city;
            },
            (error) => {
              this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de cidades');
            },
          );
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar endereço');
        },
      );

    // Obtém contatos da pessoa.
    this.personService
      .getPersonContactByPersonId(id)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.selectedItem = data;
          for (let i = 0; i < data.length; i++) {
            this.addSelectedContactToHtml(data[i]);
          }
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar contatos');
        },
      );

    // Obtém arquivos associados à pessoa.
    this.personService.getPersonFileByPersonId(id).subscribe(
      (data) => {
        for (let i = 0; i < data.length; i++) {
          this.addSelectedFileToHtml(data[i]);
        }
      },
      (error) => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar documentos');
      },
    );
  };

  /**
   * Verifica se um campo específico do formulário é válido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns true se o campo for inválido e o formulário tiver sido submetido, false caso contrário.
   */
  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result;
  }

  /**
   * Retorna uma classe CSS 'is-invalid' se o campo fornecido for inválido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns Objeto com a classe CSS 'is-invalid' se o campo for inválido.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field),
    };
  }

  /**
   * Manipula o evento de seleção de UF (Unidade Federativa) no formulário, atualizando a lista de cidades correspondentes.
   */
  onUfSelected() {
    let value = this.form.get('person_address.uf').value;
    this.personService
      .getCities(this.authService.endpoint_main + 'city/' + value)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.cities = data;
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de cidades');
        },
      );
  }

  /**
   * Obtém um objeto contendo todos os campos do formulário para facilitar o acesso.
   *
   * @returns Objeto contendo todos os controles do formulário.
   */
  get f() {
    return this.form.controls;
  }

  /**
   * Altera o foco entre os campos de pessoa física (CPF) e pessoa jurídica (CNPJ) no formulário.
   *
   * @param value - Valor indicando o tipo de pessoa (CPF ou CNPJ).
   */
  focusPerson(value: string) {
    if (value == 'cpf') {
      this.select_form_cnpj = '';
      this.select_form_cpf = 'show active';
      this.form.get('trade_name').setValue('');
      this.form.get('company_name').setValue('');
      this.form.get('cnpj').setValue('');
      this.form.get('person_address.site').setValue('');
      this.form.get('is_physical').setValue(true);
    } else {
      this.select_form_cnpj = 'show active';
      this.select_form_cpf = '';
      this.form.get('icms_free').setValue(false);
      this.form.get('first_name').setValue('');
      this.form.get('last_name').setValue('');
      this.form.get('cpf').setValue('');
      this.form.get('is_physical').setValue(false);
    }
  }

  /**
   * Adiciona um contato selecionado ao HTML da tabela de contatos.
   *
   * @param data - Dados do contato a serem exibidos na tabela.
   */
  addSelectedContactToHtml(data: any) {
    let html =
      '<tr><td><div class="form-check form-check-inline"><input class="form-check-input"  type="checkbox" name="remove"></div></td> <td><input type="text"  class="form-control mb-2 mr-sm-2"  name="contact_name" value="' +
      data.name +
      '" > </td><td><input type="email" class="form-control mb-2 mr-sm-2" name="contact_email" value="' +
      data.email +
      '"></td><td><input mask="(00) 00000-0000" type="text" class="form-control mb-2 mr-sm-2"  name="contact_phone" value="' +
      data.phone +
      '"></td> <td><input type="text" class="form-control mb-2 mr-sm-2"  name="contact_office" value="' +
      data.office +
      '"></td></tr>';

    this.tbodyContact.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Adiciona uma nova linha vazia ao HTML da tabela de contatos.
   */
  addContactToHtml() {
    let html =
      '<tr><td><div class="form-check form-check-inline"><input class="form-check-input"  type="checkbox" name="remove"></div></td> <td><input type="text"  class="form-control mb-2 mr-sm-2"  name = "contact_name" > </td><td><input type="email" class="form-control mb-2 mr-sm-2" name="contact_email"></td><td><input mask="(00) 00000-0000" type="text" class="form-control mb-2 mr-sm-2"  name="contact_phone"></td> <td><input type="text" class="form-control mb-2 mr-sm-2"  name="contact_office"></td></tr>';
    this.tbodyContact.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Adiciona um arquivo selecionado ao HTML da tabela de arquivos.
   *
   * @param data - Dados do arquivo a serem exibidos na tabela.
   */
  addSelectedFileToHtml(data: any) {
    let html =
      '<tr><td><div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" name="remove"><span style="display:none;">' +
      data.id +
      '</span> </div></td><td><input type="text" class="form-control mb-2 mr-sm-2"  name="file_name" value="' +
      data.name +
      '"> </td><td><a  class="btn btn-primary" href="' +
      data.file +
      '" download="' +
      data.name +
      '" target="_blank"><i class="fa fa-download"></i></a></td></tr>';
    this.tbodyFile.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Adiciona uma nova linha vazia ao HTML da tabela de arquivos.
   */
  addFileToHtml() {
    let html =
      '<tr><td><div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" name="remove"></div></td><td><input type="text" class="form-control mb-2 mr-sm-2"  name="file_name"> </td><td><input type="file" class="form-control mb-2 mr-sm-2"  name="file_file"></td></tr>';
    this.tbodyFile.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Exclui as linhas selecionadas na tabela de contatos.
   */
  deleteContactTr() {
    let rows = document.getElementById('contact-tbody');
    let selected = rows.querySelectorAll('tr td .form-check-inline input:checked');
    let selected_length = rows.querySelectorAll('tr td .form-check-inline input:checked').length;

    for (let i = 0; i < selected_length; i++) {
      selected[i].parentNode.parentNode.parentNode.parentNode.removeChild(selected[i].parentNode.parentNode.parentNode);
    }
  }

  /**
   * Exclui as linhas selecionadas na tabela de arquivos, também excluindo os arquivos correspondentes no backend.
   */
  deleteFileTr() {
    let rows = document.getElementById('file-tbody');
    let selected = rows.querySelectorAll('tr td .form-check-inline input:checked');
    let selected_length = rows.querySelectorAll('tr td .form-check-inline input:checked').length;

    for (let i = 0; i < selected_length; i++) {
      if ((<Element>selected[i].parentNode).querySelector('span')) {
        let file_id = (<Element>selected[i].parentNode).querySelector('span').innerText;
        this.personService.deletePersonFile(file_id).pipe(take(1)).subscribe();
      }

      selected[i].parentNode.parentNode.parentNode.parentNode.removeChild(selected[i].parentNode.parentNode.parentNode);
    }
  }

  /**
   * Marca todos os campos de um FormGroup como "touched", indicando que foram tocados pelo usuário.
   * Isso é útil para acionar as mensagens de erro de validação de exibição em um formulário.
   *
   * @param formGroup - O FormGroup a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Reseta o estado de submissão do formulário para falso e recarrega os dados com base nos parâmetros da rota.
   * Útil para redefinir o formulário para um estado inicial antes de novas operações.
   */
  reset() {
    // Define o estado de submissão como falso para permitir uma nova submissão.
    this.submitted = false;

    // Obtém o parâmetro 'id' da rota para identificar a entidade a ser carregada.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');

      // Carrega os dados da entidade com o ID fornecido.
      this.read(id);
    });
  }

  /**
   * Atualiza os dados de uma pessoa com base no formulário preenchido.
   * Realiza validações e manipulações necessárias antes de enviar a solicitação de atualização para o serviço.
   */
  update = () => {
    // Define o estado de submissão como verdadeiro para acionar validações e feedbacks visuais.
    this.submitted = true;

    if(!this.parentFormStatus) {
      this.created.emit(true);
      return;
    }

    if (this.form.valid && this.parentFormStatus) {
      // Verifica se existe token.
      if (this.authService.hasToken()) {
        // Cria um objeto FormData para envio de arquivos.
        const formData = new FormData();

        // Variável para rastrear se há arquivos anexados.
        let has_file = false;

        // Arrays para armazenar contatos e arquivos relacionados à pessoa.
        let person_contact = [];
        let person_file = [];

        // Obtém valores específicos do formulário.
        let is_physical = this.form.get('is_physical').value;
        let cpf = this.form.get('cpf').value;

        // Obtém elementos relacionados a contatos do DOM.
        let contacts_name = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_name"]');
        let contacts_email = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_email"]');
        let contacts_phone = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_phone"]');
        let contacts_office = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_office"]');
        let contacts_length = contacts_name.length;

        // Prepara o objeto de contatos relacionados à pessoa.
        for (var i = 0; i < contacts_length; i++) {
          let rowContact = {
            name: contacts_name[i].value,
            email: contacts_email[i].value,
            phone: contacts_phone[i].value,
            office: contacts_office[i].value,
          };
          // Adiciona contatos apenas se tiverem e-mail ou telefone preenchidos.
          if (rowContact.phone != '' || rowContact.email != '') {
            person_contact.push(rowContact);
          }
        }

        // Adiciona chaves no objeto de pessoa.
        this.person = this.form.value;
        this.person['person_contact'] = person_contact;

        // Envia a solicitação de atualização para o serviço.
        this.isLoading = true;
        this.personService
          .updatePerson(this.person)
          .pipe(take(1))
          .subscribe(
            (data) => {
              this.location.back();
              this.has_error = false;
              this.created.emit(true)
            },
            (error) => {
              // Lida com erros durante a atualização da pessoa.
              this.modalAlertService.showAlertDanger('Erro ao incluir o registro');

              this.backend_validation_message = [];
              if ('person_address' in error.error) {
                if ('site' in error.error['person_address']) {
                  this.backend_validation_message.push(error.error['person_address']['site'][0]);
                }
              }
              if ('person_contact' in error.error) {
                if ('email' in error.error['person_contact']) {
                  this.backend_validation_message.push(error.error['person_contact']['email'][0]);
                }
              }
              if ('cnpj' in error.error) {
                this.backend_validation_message.push(error.error['cnpj'][0]);
              }
              if ('cpf' in error.error) {
                this.backend_validation_message.push(error.error['cpf'][0]);
              }
              // Define a flag de erro como verdadeira para sinalizar a presença de erros.
              this.has_error = true;
            },
          )
          .add(() => {
            scrollTo(0, 0);
            this.isLoading = false;
          });
      } else {
        // Caso não haja ID de aplicativo, exibe uma mensagem de erro e realiza logout.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário não seja válido, aciona a validação de todos os campos.
      this.validateAllFormFields(this.form);
    }
  };
}

<!-- BreadCrumb -->
<div class="page-breadcrumb">
  <div class="row">
    <!-- <div class="col-5 align-self-center">
      <h4 class="page-title">{{ "Report" | translate }}</h4>
    </div> -->
    <div class="col-12 align-self-center">
      <div class="d-flex no-block justify-content-end align-items-center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/">{{ 'Home' | translate }}</a>
            </li>
            <li class="breadcrumb-item">
              {{ "Cube" | translate }}
            </li>

          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" >
    <div id="parameterDiv" name="parameterDiv" style="display: none" >
        <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active waves-effect waves-light" id="pills-filters-tab" data-toggle="pill" href="#pills-filters" role="tab" aria-controls="pills-filters" aria-selected="true">
                    <i class="fas fa-filter" title="Data"></i>  Filtros
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-effect waves-light" id="pills-dimensions-tab" data-toggle="pill" href="#pills-dimensions" role="tab" aria-controls="pills-dimensions" aria-selected="false">
                    <i class="fab fa-buromobelexperte" title="Data"></i>  Dimensões
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-effect waves-light" id="pills-metrics-tab" data-toggle="pill" href="#pills-metrics" role="tab" aria-controls="pills-metrics" aria-selected="false">
                    <i class="fas fa-tachometer-alt" title="Data"></i>  Métricas
                </a>
            </li>
        </ul>
        <div class="col-12 card tab-content managedform" id="pills-tabContent" style="min-height: 400px">
            <div class="tab-pane fade show active" id="pills-filters" role="tabpanel" aria-labelledby="pills-filters-tab">
                <section class="card-body">

                    <div class="row" >
                        <div class="col-xs-12 col-sm-12 col-md-12 input-group-prepend alwaysTour">
                                <div>
                                    <span class="input-group-text">
                                        <i class='fa-calendar-alt far' title='Data'></i>
                                    </span>
                                </div>
                                <input type="text" id="date-interval" name="date-interval"
                                    class="form-control date-interval"
                                    ngxDaterangepickerMd
                                    alwaysShowCalendars="true"
                                    autocomplete="off"
                                    [closeOnAutoApply]="true"
                                    [linkedCalendars]="true"
                                    [locale]="{
                                                applyLabel: 'Aplicar',
                                                customRangeLabel: 'Personalizar',
                                                clearLabel: '',
                                                cancelLabel: 'Cancelar',
                                                format: 'DD/MM/YYYY HH:mm',
                                                firstDay: 1
                                            }"
                                    [(ngModel)]="selected"
                                    [ngModelOptions]="{standalone: true}"
                                    [opens]="'right'"
                                    [ranges]="ranges"
                                    [showCancel]="true"
                                    [showClearButton]="true"
                                    [showCustomRangeLabel]="true"
                                    [timePicker]="true"
                                    [timePickerIncrement]="15"
                                    [timePickerSeconds]="false"
                                    [timePicker24Hour]="true"
                                />
                        </div>

                        <div *ngFor="let f of filters" id="{{f}}_div" name="{{f}}_div" [attr.ac_tools]="f" class="col-xs-12 col-sm-12 col-md-6 col-xl-4 alwaysTour">
                            <app-ic-select key="{{f}}" >
                            </app-ic-select>
                        </div>
                    </div>

                </section>
            </div>
            <div class="tab-pane fade" id="pills-dimensions" role="tabpanel" aria-labelledby="pills-dimensions-tab">
                <section class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 row">
                            <div class="col-6">
                                <select id="selectDimensions" name="selectDimensions"  size="15" multiple class="w-100">
                                    <option *ngFor="let c of dimensions" value="{{c[0]}}">{{c[1]}}</option>
                                </select>
                            </div>
                            <div class="col-6 mt-5">
                                <button id="addColumn" name="addColumn" destination='col' class="btn btn-rounded btn-outline-secondary w-100">
                                    <i class="fas fa-arrow-alt-circle-right"></i>
                                    Colunas
                                </button>
                                <button id="addLine" name="addLine" destination='line' class="btn btn-rounded btn-outline-secondary w-100">
                                    <i class="fas fa-arrow-alt-circle-right"></i>
                                    Linha
                                </button>
                                <button id="addDispose" name="addDispose" destination='dis' class="btn btn-rounded btn-outline-secondary w-100">
                                    <i class="fas fa-arrow-alt-circle-right"></i>
                                    Disposição
                                </button>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <label for="dim_cols" class="alwaysTour">Colunas</label>
                            <ng-select class="dim_select" id="dim_cols" name="dim_cols"
                                (add)="selectDimensions($event, 0)"
                                (remove)="unselectDimensions($event, 0)"
                                multiple="multiple">
                                <ng-option *ngFor="let c of dimensions" value="{{c[0]}}">{{c[1]}}</ng-option>
                            </ng-select>


                            <label for="dim_lines" class="alwaysTour">Linhas</label>
                            <ng-select class="dim_select" id="dim_lines" name="dim_lines"
                                (add)="selectDimensions($event, 1)"
                                (remove)="unselectDimensions($event, 1)"
                                multiple="multiple">
                                <ng-option *ngFor="let c of dimensions" value="{{c[0]}}">{{c[1]}}</ng-option>
                            </ng-select>

                            <label for="dim_dis" class="alwaysTour">Disposição</label>
                            <ng-select class="dim_select" id="dim_dis" name="dim_dis"
                                (add)="selectDimensions($event, 2)"
                                (remove)="unselectDimensions($event, 2)"
                                multiple="multiple">
                                <ng-option *ngFor="let c of dimensions" value="{{c[0]}}">{{c[1]}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </section>
            </div>
            <div class="tab-pane fade" id="pills-metrics" role="tabpanel" aria-labelledby="pills-metrics-tab">
                <section class="card-body">
                    <div class="row" >
                      <ng-container *ngFor="let c of metricSource">
                        <div *ngIf="!c[0].startsWith('METRIC') && !c[0].startsWith('NATURE')" class="col-xs-12 col-md-6 form-check input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <div class="custom-control custom-checkbox mr-sm-2">
                                        <input class="custom-control-input checkbox-style form-check-input m_check" (change)="metric_change($event)" type="checkbox" value="{{c[0]}}" id="m_{{c[0]}}">
                                        <label class="custom-control-label" for="m_{{c[0]}}"></label>
                                    </div>
                                </div>
                            </div>
                            <label class="form-check-label form-control" for="m_{{c[0]}}">
                                {{c[1]}}
                            </label>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="hasMetricEQP">
                        <h4 class="form-text pt-1 col-11">Medições</h4>
                        <ng-container *ngFor="let c of metricSource">
                          <div *ngIf="c[0].startsWith('METRIC')" class="col-xs-12 col-md-6 form-check input-group">
                              <div class="input-group-prepend">
                                  <div class="input-group-text">
                                      <div class="custom-control custom-checkbox mr-sm-2">
                                          <input class="custom-control-input checkbox-style form-check-input m_check" (change)="metric_change($event)" type="checkbox" value="{{c[0]}}" id="m_{{c[0]}}">
                                          <label class="custom-control-label" for="m_{{c[0]}}"></label>
                                      </div>
                                  </div>
                              </div>
                              <label class="form-check-label form-control" for="m_{{c[0]}}">
                                  {{c[1]}}
                              </label>
                          </div>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="hasMetricNature">
                        <h4 class="form-text pt-1 col-11">Naturezas</h4>
                        <ng-container *ngFor="let c of metricSource">
                          <div *ngIf="c[0].startsWith('NATURE')" class="col-xs-12 col-md-6 form-check input-group">
                              <div class="input-group-prepend">
                                  <div class="input-group-text">
                                      <div class="custom-control custom-checkbox mr-sm-2">
                                          <input class="custom-control-input checkbox-style form-check-input m_check" (change)="metric_change($event)" type="checkbox" value="{{c[0]}}" id="m_{{c[0]}}">
                                          <label class="custom-control-label" for="m_{{c[0]}}"></label>
                                      </div>
                                  </div>
                              </div>
                              <label class="form-check-label form-control" for="m_{{c[0]}}">
                                  {{c[1]}}
                              </label>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                </section>
            </div>
        </div>
        <div class="row">
            <div class="mx-auto mb-3">
                <button class="dataDP onlyWithData btn btn-secondary">
                    <i class="fas fa-table"></i>
                    Voltar ao Cubo
                </button>
                <button class="favoriteDP btn btn-secondary">
                    <i class="fas fa-star"></i>
                    Favoritos
                </button>
                <button id="applyFilters" name="applyFilters" (click)="searchBtn(1)" class="btn btn-primary">
                    <i class="fas fa-search"></i>
                    Consultar
                </button>
            </div>
        </div>
    </div>

    <div id="favoriteDiv" name="favoriteDiv">
        <div class="row">
            <div class="col-sm-12 row">
                <button type="button" class="createDP btn  btn-circle btn-lg">
                    <h2><i class="fas fa-plus"></i></h2>
                </button>
                <button type="button" class="dataDP onlyWithData btn btn-circle btn-lg">
                    <h2><i class="fas fa-table"></i></h2>
                </button>

            </div>
        </div>

        <app-filter-wall key='Cube' event='#applyFilters' callback='this.getContext( document.querySelector("#parameterDiv") ).pivotFilterConf(name, values);' >
        </app-filter-wall>

    </div>

    <div id="dataDiv" name="dataDiv" style="display: none">

        <div id="pivot_div_bar" class="pivot_div_bar" style="display:none; text-align: center;">
            <div class="cubeloading">
                <span id="pivot_loading_count" >0%</span>
                <div class="cubeasset">
                    <div class="cubeassetsides">
                    <div class="cl_top"></div>
                    <div class="cl_right"></div>
                    <div class="cl_bottom"></div>
                    <div class="cl_left"></div>
                    <div class="cl_front"></div>
                    <div class="cl_back"></div>
                    </div>
                </div>
                <div id="pivot_loading_text" class="cubeloadingtext row">Carregando...</div>
            </div>
        </div>

        <fieldset id="cubeDiv">

            <div id="pivot_chart">
            </div>
            <div id="pivot_table_section">
                <div class="p_config_div row">
                    <div class="col-sm-4 row">
                        <button type="button" class="metricDP btn  btn-circle btn-lg">
                            <h2><i class="fas fa-cogs"></i></h2>
                        </button>
                        <button type="button" class="graphChartsBtn btn  btn-circle btn-lg">
                            <h2><i class="mdi mdi-chart-areaspline"></i></h2>
                        </button>
                        <button type="button" class="favoriteDP btn btn-circle btn-lg">
                            <h2><i class="fas fa-star"></i></h2>
                        </button>
                        <button type="button" class="createDP btn  btn-circle btn-lg">
                            <h2><i class="fas fa-plus"></i></h2>
                        </button>
                        <button type="button" id="saveDP" name="saveDP" class="saveDP btn  btn-circle btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <h2><i class="fas fa-save"></i></h2>
                        </button>
                        <app-filter-save key='Cube' hasID='saveDP' callback='this.getContext( document.querySelector("#parameterDiv") ).pivotFilterConf(name, values);'></app-filter-save>
                    </div>
                    <div id="pivot_page" class="dispose_area col-sm-8 d-flex"></div>
                </div>

                <div id="p_table_area" class="dc-scroll" >
                </div>
            </div>

        </fieldset>

    </div>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NewsletterLinkComponent } from './newsletter-link/newsletter-link.component';
import { NewsletterSampleComponent } from './newsletter-sample/newsletter-sample.component';


@NgModule({
  declarations: [
    NewsletterLinkComponent,
    NewsletterSampleComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    NgxSliderModule,
    NgSelectModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    NewsletterLinkComponent
  ]
})
export class NewsletterModule { }

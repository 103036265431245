import { Component, Input, OnInit } from '@angular/core';
import { Permission } from '../../users-groups/permission.model';

@Component({
  selector: 'app-multiple-select-update',
  templateUrl: './multiple-select-update.component.html',
  styleUrls: ['./multiple-select-update.component.css']
})
export class MultipleSelectUpdateComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('allItems') allItems: Permission[];
  // tslint:disable-next-line:no-input-rename
  @Input('fieldName') fieldName: string;
  // tslint:disable-next-line:no-input-rename
  @Input('title') title: string;
  // tslint:disable-next-line:no-input-rename
  @Input('initialItems') initialItems: Permission[];

  selectedItems = [];
  selectedItemsForSave = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  checkDuplicate = (value) => {
    if (this.initialItems.length > 0) {
      let stringForCheck = '';
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.initialItems.length; i++) {
        // tslint:disable-next-line:triple-equals
        if (this.initialItems[i].id == value) {
          stringForCheck += 'a';
        } else {
          stringForCheck += 'b';
        }
      }
      // tslint:disable-next-line:triple-equals
      return stringForCheck.indexOf('a') != -1;
    } else {
      return false;
    }
  }

  addItems = () => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.allItems.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.selectedItems.length; j++) {
        // tslint:disable-next-line:triple-equals radix
        if (parseInt(this.selectedItems[j]) == this.allItems[i].id) {
          // tslint:disable-next-line:triple-equals
          if (this.checkDuplicate(this.allItems[i].id) == false) {
            this.initialItems.push(this.allItems[i]);
          }
        }
      }
    }
  }

  removeItems = () => {
    if (this.selectedItemsForSave.length > 0) {
      for (let i = 0; i < this.initialItems.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.selectedItemsForSave.length; j++) {
          // tslint:disable-next-line:radix triple-equals
          if (parseInt(this.selectedItemsForSave[j]) == this.initialItems[i].id) {
            this.initialItems.splice(i, 1);
          }
        }
      }
    }
  }
}

import { HeaderComponent } from './header.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http"
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  mainUrl = environment.mainApiUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getNofificationList(): Observable<any> {
    //autocomplete?key=SET&q[term]=to&depends[EQP]=1<spt_s>100
    //console.log('valor do term ' + term)
    var params = new HttpParams();
    // params = params.set('key', key);
    var options = {  headers: this.httpHeaders };

    var url = this.mainUrl + ( this.mainUrl.endsWith('/') ? 'plant/alert/list' : '/plant/alert/list' )
    return this.http.get(url, options);
  }

  postNofificationList(data): Observable<any> {
    //autocomplete?key=SET&q[term]=to&depends[EQP]=1<spt_s>100
    //console.log('valor do term ' + term)
    var params = new HttpParams();
    // params = params.set('key', key);
    var options = {  headers: this.httpHeaders };

    return this.http.post(this.mainUrl + 'plant/alert/status_update', data, options);
  }
}

export const environment = {
  production: true,
  secret: 'G3TCBFKpG83hV3EXD9dgO7Fjo8VtMU1nUvvUQ77sGJ0=',
  mainApiUrl: 'https://main.industrycare.com.br/',
  repoUrl: 'https://repo.industrycare.com.br/',
  formUrl: 'https://form.industrycare.com.br/',
  simulationUrl: 'https://simulation.industrycare.com.br/',
  mailUrl: 'https://mail.industrycare.com.br/',
  authApiUrl: 'https://auth.industrycare.com.br/api',
  botUrl: 'https://botservice.industrycare.com.br/',

  while_label_config: {
    'analytics.industrycare.com.br': {
      url: '',
      logo: 'assets/img/logo-maior.png',
      navlogo: '/assets/alfa_adminbite/assets/images/logo-completa.svg',
      navlogomini: '/assets/alfa_adminbite/assets/images/logo-light-icon1.png',
      title: 'IndustryCare Analytics',
      base_style: '/assets/alfa_adminbite/dist/css/style.min.css',
      custom_style: '/assets/css/custom/custom.css',
      favicon_style: '/assets/alfa_adminbite/assets/images/favicon.png',
      bg_img: '../assets/img/bg.jpg',
      horizontal: false,
      templateScript: '../assets/alfa_adminbite/dist/js/app.init.js'
    },
    'myecos.industrycare.com.br': {
      url: '',
      logo: '../assets/myEcos/image/logo-my-ecos.png',
      navlogo: '../assets/myEcos/image/logo-my-ecos.png',
      navlogomini: '../assets/myEcos/image/logo-my-ecos.png',
      title: 'My Ecos',
      base_style: '/assets/alfa_adminbite/dist/css/style.min.css',
      custom_style: '/assets/myEcos/css/custom-ecos.css',
      favicon_style: '../assets/myEcos/image/icon-my-ecos.ico',
      bg_img: 'https://cdn.pixabay.com/photo/2016/11/29/05/45/astronomy-1867616_640.jpg',
      horizontal: true,
      templateScript: '../assets/alfa_adminbite/dist/js/app.init.horizontal.js'
    }
  }
};

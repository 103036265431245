import { Moment } from "moment";
import { EnergyImpact } from "./energy-impact";
import { ImpactType } from "./impact-type";
import { ImpactValueType } from "./impact-value-type";
import { IndustrialUnity } from "./industrial-unity";
import { Location } from "./location";
import { ProjectType } from "./project-type";

export class Project {
    name: string;
    location: Location;
    projectType: ProjectType;
    energyImpact: EnergyImpact;
    valueImpact: number;
    impactType: ImpactType;
    impactValueType: ImpactValueType;
    startPeriod: Moment;
    endPeriod: Moment;
    expanded: boolean;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilterSaveService {
  repoUrl = environment.repoUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}` 
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  saveFilter(formData): Observable<any> {
    const body = formData;
    return this.http.post(this.repoUrl + 'insert_filter', body,
      {headers: this.httpHeaders});
  }
}

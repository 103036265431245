import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, ReflectiveInjector } from '@angular/core';
import { BaseFunc } from '../../shared/base-func/base-func';
import { Global } from '../../shared/base-func/global';
import Speech from 'speak-tts'

import * as L from 'leaflet'

import "leaflet-groupedlayercontrol";
import 'leaflet.markercluster';
import "leaflet.featuregroup.subgroup";
import "leaflet/dist/leaflet.css"

import 'leaflet.heat/dist/leaflet-heat.js';

import * as moment from 'moment';
import { Moment } from 'moment';

import Chart from 'chart.js';
import '../../../../../node_modules/chartjs-plugin-regression/lib/regression-plugin.js';
// import ChartRegressions from 'chartjs-plugin-regression';
import '../../../../../node_modules/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js'
import annotationPlugin from "chartjs-plugin-annotation";
import '../../../../../node_modules/chartjs-gauge/dist/chartjs-gauge.min.js'
import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import { ThreeRenderComponent } from '../three-render/three-render.component';
import { GridStack } from 'gridstack';


L.Icon.Default.imagePath='./assets/';

declare const HeatmapOverlay: any;
declare var webkitSpeechRecognition: any;
Chart.pluginService.register(annotationPlugin);
Chart.plugins.register(window['ChartRegressions']);


@Injectable({
  providedIn: 'root'
})
export class DataEngineManager extends BaseFunc {
  hasTTS: boolean = false;
  hasSpechRecognizer: boolean = false;
  speech;

  recognition;
  tempWords;

  saved = {};
  curr = {};
  colors = null;
  curr_graph = null;
  markerLayers = {};
  globalMap = null;
  runTimeConf: { [key: string]: [] } = {};
  cutPoint = 1;
  regresionIndex = null;
  regressionResult = null;
  callbackAction = null;
  callerAction = null;
  numIntervals = 10;
  final_conf = null;

  caller: any = null;
  global: Global = new Global()


  constructor(caller: any) {
    super()
    this.caller = caller;

    var self = this;

    try {
      self.speech = new Speech() // will throw an exception if not browser supported
      self.hasTTS = self.speech.hasBrowserSupport();
    }
    catch (e) {
      this.hasTTS = false;
    }

    try {
      self.recognition = new webkitSpeechRecognition();
      self.recognition.lang = 'en-US';
      self.recognition.interimResults = false;
      self.recognition.addEventListener('result', (e) => {
        const transcript = Array.from(e.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join('');
        this.tempWords = transcript;
      });
      self.hasSpechRecognizer = true;
    }
    catch (e) {
      this.hasSpechRecognizer = false;
    }

  };

  // #region Gráficos
  nestedAttribute = function (obj, path, value) {
    if (path && path != "") {
      var current = obj;
      var fields = path.split('.');
      for (var i = 0; i < fields.length; ++i) {
        if (!current[fields[i]]) { current[fields[i]] = {}; }

        if (i == fields.length - 1) { current[fields[i]] = value; }
        else { current = current[fields[i]]; }
      }
    }
  };

  inferingType = function (array) {
    var self = this;
    if (array.every(x => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/g.test(x))) {
      self.runTimeConf['xAxisFormat'] = 'YYYY-MM-DD';
      return function (a) { return moment(a, self.runTimeConf['xAxisFormat']); };
    }
    else if (array.every(x => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g.test(x))) {
      self.runTimeConf['xAxisFormat'] = 'DD/MM/YYYY';
      return function (a) { return moment(a, self.runTimeConf['xAxisFormat']); };
    }
    else if (array.every(x => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/g.test(x))) {
      self.runTimeConf['xAxisFormat'] = 'DD/MM';
      return function (a) { return moment(a, self.runTimeConf['xAxisFormat']); };
    }
    else if (array.every(x => /^(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g.test(x))) {
      self.runTimeConf['xAxisFormat'] = 'MM/YYYY';
      return function (a) { return moment(a, self.runTimeConf['xAxisFormat']); };
    }
    else if (array.every(x => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g.test(x))) {
      self.runTimeConf['xAxisFormat'] = 'HH:MI';
      return function (a) { return moment(a, self.runTimeConf['xAxisFormat']); };
    }
    else if ( array.every( x => Number.isInteger(x) ) ) {
      self.runTimeConf['xAxisFormat'] = null;
      return function (a) { return self.get_formatter('default0', 1, true)[0](a); };
    }
    else if ( array.every( x => typeof x == 'number' ) ) { // Encontra o menor formatter que não cause conflitos perda de precisão
      var minFormatter = [...Array(10).keys()].map(i =>  [...new Set(array.map(x => self.get_formatter('default' + i, 1, true)[0](x) ) )].length ==  array.length ? 'default' + i : null ).find(x => x != null);
      self.runTimeConf['xAxisFormat'] = minFormatter;
      return function (a) { return self.get_formatter(minFormatter, 1, true)[0](a); };
    }
    else {
      self.runTimeConf['xAxisFormat'] = null;
      return function (a) { return a; };
    }
  };

  checkboxData = function (id, colNames, data, aggValues, confList) {
    var self = this;
    var format = self.get_formatter(confList, confList.formatter ? confList.formatter.split(',').length : colNames.length);
    var checkboxes = data.map(x => x[0]).filter((v, i, a) => a.indexOf(v) === i);
    var type = confList.checkboxDataType || 'checkbox';
    var manyOpts = checkboxes.length > 5 ? 'many' : '';
    checkboxes.sort();

    //Define o padrão do string a ser utilizado para construir o seletor
    var pattern = {
      'checkbox':`<input type="checkbox" id="idVals" name="idVals" isChecked><label for="idVals">labelVal</label>`,
      'radio': `<input type="radio" id="idVals" name="nameVals" isChecked><label for="idVals">labelVal</label>`,
      'manyradio': `<option id="idVals" name="idVals" value="idVals" isSelected>labelVal</option>`,
      'manycheckbox': `<option id="idVals" name="idVals" value="idVals" isSelected>labelVal</option>`
    }[ manyOpts + type ];
    var embraces = ( manyOpts == 'many' ?
      '<div class="form-check form-check-inline"><label for="upperID" class="mr-2">labelUpper</label><select id="upperID" name="upperID">{optionList}</select></div>' :
      '<div class="form-check form-check-inline"><label for="upperID" class="mr-2">labelUpper</label><div id="upperID" name="upperID">{optionList}</div></div>'
    ).replace(/upperID/g, 'upperSelector_' + id).replace(/labelUpper/g, colNames[0]);

    var useStorageCK = self.runTimeConf.curretCheckboxStatus && self.runTimeConf.lastCheckboxes && self.runTimeConf.lastCheckboxes.isEqualArray(checkboxes);
    var checked = useStorageCK ? self.runTimeConf.curretCheckboxStatus : [];
    checked = checked.length == 0 ? [id + '_' + checkboxes[0].replace(' ', '_')] : checked;

    var options = checkboxes.map(x => pattern
      .replace(/labelVal/g, x)
      .replace('isChecked', checked.indexOf(id + '_' + x.replace(' ', '_')) != -1 ? 'checked' : '')
      .replace('isSelected', checked.indexOf(id + '_' + x.replace(' ', '_')) != -1 ? 'selected' : '')
      .replace(/nameVals/g, id )
      .replace(/idVals/g, id + '_' + x.replace(' ', '_'))
    ).join('');

    self.$j('#customReportUpperAuxCell_' + id).html( embraces.replace('{optionList}', options) );
    self.$j('#customReportUpperAuxCell_' + id + ' input, select').change(function () {
      if (manyOpts == '')
        self.runTimeConf['curretCheckboxStatus'] = self.$j('#customReportUpperAuxCell_' + id + ' input:checked').map(x => x.id);
      else
        self.runTimeConf['curretCheckboxStatus'] = [ self.$j('#customReportUpperAuxCell_' + id + ' select').val() ];

      self.runTimeConf['lastCheckboxes'] = checkboxes;
      self.refreshGraph(self.curr.type);
    });

    var type = confList.checkboxData.split(',');
    colNames = colNames.slice(1);
    data = data.filter(x => checked.indexOf(id + '_' + x[0].replace(' ', '_')) != -1).map(x => x.slice(1));
    //group by data
    if (aggValues)
      data = data.map(x => x[0]).filter((v, i, a) => a.indexOf(v) === i).map(function (x) {
        var result = [x];
        colNames.slice(1).forEach(function (e, i) {
          var values = data.filter(xi => xi[0] == x).map(xi => xi[i + 1]).filter(xi => xi != null);
          if (values.length == 0)
            values = null;
          else if (type[i] == 'avg' && values.every(x => Array.isArray(x) ) ) // Valor que veio do servidor é um array decomposto em numerador e denominador das médias
            values = values.map(xi => xi[0]).reduce((a, b) => a + b) / values.map(xi => xi[1]).reduce((a, b) => a + b);
          else if (type[i] == 'avg' ) // Valor que veio do servidor é somente número (médias das médias)
            values = values.reduce((a, b) => a + b) / values.length;
          else if (type[i] == 'fp_agg') // ativa^2 / ( (ativa^2 + reativa^2)^(1/2) )
            values = values.map(xi => xi[0]).reduce((a, b) => Math.max(a, b)) /
              Math.pow(Math.pow(values.map(xi => xi[0]).reduce((a, b) => Math.max(a, b)), 2) + Math.pow(values.map(xi => xi[1]).reduce((a, b) => Math.max(a, b)), 2), 1 / 2);
          else if (type[i] == 'min')
            values = values.reduce((a, b) => Math.min(a, b));
          else if (type[i] == 'max')
            values = values.reduce((a, b) => Math.max(a, b));
          else if (type[i] == 'not')
            values = values;
          else //sum
            values = values.reduce((a, b) => a + b);

          if (type[i] != 'not')
            if (format[i])
              result.push( format[i](values).replace(/\./g, '').replace(/,/g, '.') );
            else
              result.push( values.toString().replace(/\./g, '').replace(/,/g, '.') )
          else
            result.push(values);
        });
        return result;
      });

    return { colNames: colNames, data: data };
  };

  createSeries = function (colNames, data, type, config) {
    var self = this;

    var refArray = data;
    if (config.orderSeries == 'lexicographic')
      refArray = data.orderedby(0, 'string', 1);
    else if (config.orderSeries == 'reverseLexicographic')
      refArray = data.orderedby(0, 'string', -1);
    else if (config.orderSeries?.startsWith('numeric_'))
      refArray = data.orderedby( parseInt(config.orderSeries.replace('numeric_', '')), 'numeric', 1);
    else if (config.orderSeries?.startsWith('reversenumeric_'))
      refArray = data.orderedby( parseInt(config.orderSeries.replace('reversenumeric_', '')), 'numeric', -1);

    var labels = refArray.map(function (e) { return e[0]; })
    var table = { data: { labels: labels, datasets: [] }, type: null };
    self.colors = ( self.colors == null ? self.get_color_graphs(type) : self.colors );

    for (var i = 1; i < colNames.length; ++i) {
      //O retorno está dentro de um array pois a função map exclui valores null
      var s = refArray.map(function (e, index) { return refArray[index][i]; });

      table.data.datasets.push({
        yAxisID: config && config.serieLinearScale && config.serieLinearScale.series ? config.serieLinearScale.series[i - 1] : null,
        borderColor: config.setColor && config.setColor.length > i ? config.setColor[(i - 1) % config.setColor.length] : self.colors[(i - 1) % self.colors.length],
        backgroundColor: config.setColor && config.setColor.length > i ? config.setColor[(i - 1) % config.setColor.length] : self.colors[(i - 1) % self.colors.length],
        label: colNames[i],
        pointRadius: 2,
        fill: false,
        data: s
      });
    }
    //Retorna o tipo de gráfico
    self.runTimeConf['xAxisStacked'] = null;
    self.runTimeConf['yAxisStacked'] = null;
    if (type == 'column')
      table.type = 'bar';
    else if (type == 'bar') {
      table.type = 'horizontalBar';
      table.data.datasets.forEach(x => { delete x.yAxisID });
    }
    else if (type == 'histogram') {
      table.type = 'bar';
      config.histogramView = true;

      var maxPrecision = config.formatter ? Math.max(...config.formatter.split(',').map(x => x.replace('default', '') )) : 2;
      var format = self.get_formatter({ 'formatter': 'default' + maxPrecision }, 1, true);

      var data_min = Math.floor( Math.min(...table.data.datasets.map(x => Math.min(...x.data) ) ) );
      var data_max = Math.ceil( Math.max(...table.data.datasets.map(x => Math.max(...x.data) ) ) );
      var step = ( data_max - data_min ) / self.numIntervals;
      var intervals: any[] = Array.from(Array(self.numIntervals), (_, i) => i ).map( x => [ format[0](data_min + step*x) + ' - ' + format[0](data_min + step*(x+1)), data_min + step*x, data_min + step*(x+1), Array(table.data.datasets.length).fill(0) ]);
      table.data.datasets.forEach( (x, i) => x.data.forEach(y => ++intervals[ intervals.indexOfNearestLessThan(1, y) ][i + 3]   ) );

      table.data.labels = intervals.map(x => x[0]);
      table.data.datasets.forEach( (x, i) => x.data = intervals.map(x => x[i+3]) );

      var upper = self.$j('#'+self.saved.div.replace('customReportCell_', 'customReportUpperAuxCell_'));
      if (table.data.labels.length > 0 && upper.html().trim() == '' ) {
        var select = '<div class="input-group-prepend col-3"><label class="input-group-text">#Intervalos</label><select class="custom-select">' + Array.from(Array(20), (_, i) => i+1 ).map(x =>'<option value="' + x +'"' + ( x == self.numIntervals ? ' selected ' : '' ) +'>' + x + '</option>').join('') + '</select></div>';
        upper.html(select);
        upper.find('select').change(function(e) {
          self.numIntervals = parseInt(e.target.value);
          self.refreshGraph(self.saved.type)
        });
      }
    }
    else if (type == 'stackedcolumn') {
      self.runTimeConf['xAxisStacked'] = true;
      self.runTimeConf['yAxisStacked'] = true;
      table.type = 'bar';
    }
    else if (type == 'stackedbar') {
      self.runTimeConf['xAxisStacked'] = true;
      self.runTimeConf['yAxisStacked'] = true;
      table.type = 'horizontalBar';
      table.data.datasets.forEach(x => { delete x.yAxisID });
    }
    else if (type == 'bubble') {
      var xType = self.inferingType(table.data.labels);
      table.data.datasets.forEach(function (element) {
        var sum = element.data.reduce((a, b) => a + b, 0)
        element.data = element.data.map(function (val, i) {
          return { 'x': xType(table.data.labels[i]), 'y': val, 'r': 100 * val / sum };
        });
      });
      table.type = type;
    }
    else if (type == 'scatter') {
      table.data.datasets.forEach(function (element) {
        element.data = element.data.map(function (val, i) {
          return { 'x': table.data.labels[i], 'y': val };
        });
      });
      table.type = type;
    }
    else if (type == 'dotted-line') {
      table.type = 'line';
      table.data.datasets.forEach(function (element, index) { element.borderDash = [10, 5]; });
    }
    else if (type == 'area') {
      table.type = 'line';
      table.data.datasets.forEach(function (element, index) { element.fill = true; });
    }
    else if (type == 'pie' || type == 'doughnut') {
      table.data.datasets.forEach(function (element, index) {
        var slices = element.data.map(function (e, i) { return self.colors[i % self.colors.length]; }); //$.makeArray( $.map(element.data, function(e,i) { return colors[ i % colors.length ];  }) );
        element.borderColor = [];
        element.backgroundColor = slices;
      });
      table.type = type;
    }
    else if (type == 'gauge') {
      table.type = 'gauge';
      table['labels'] = [];
      var gaugeColors = config.setColor ? config.setColor : self.colors;
      table.data.datasets = [table.data.datasets.reduce(function (dict, el, i) {
        if (i != table.data.datasets.length - 1) {
          dict.data.push(el.data[0]);
          dict.backgroundColor.push(gaugeColors[(i + 1) % gaugeColors.length])
        }
        else
          dict.value = el.data[0];
        return dict;
      }, { yAxisID: null, fill: false, label: '', borderWidth: 0, pointRadius: 2, value: 0, data: [data[0][0]], borderColor: [gaugeColors[0]], backgroundColor: [gaugeColors[0]] })];

      if (config.minGaugeValue && table.data.datasets.length > 0) {
        if ( config.minGaugeValue == 'min' )
          table.data.datasets[0].minValue = table.data.datasets[0].data[0];
        else
          table.data.datasets[0].minValue = config.minGaugeValue;
      }
    }
    else if (type == 'boxplot') {
      table.type = type;
      // self.colors = self.colors.filter(x => x.endsWith(',0.3)'));

      var newLabels = [...new Set(table.data.labels.map(x => x.split('-').slice(0, -1*self.cutPoint).join('-') ))];
      table.data.datasets.forEach( (x, i) => x.data = newLabels.map( l =>  x.data.map( (y, ii) =>  table.data.labels[ii].startsWith(l) ? y : null  ) )  );
      table.data.labels = newLabels;

      var upper = self.$j('#'+self.saved.div.replace('customReportCell_', 'customReportUpperAuxCell_'));
      if (table.data.labels.length > 0 && upper.html().trim() == '' ) {
        var cuts = self.saved.data[0][0].split('-').length-1;
        var select = '<div class="input-group-prepend col-3"><label class="input-group-text">#Agrupamento</label><select class="custom-select">' + Array.from(Array(cuts), (_, i) => i+1 ).map(x =>'<option value="' + x +'" ' + ( x == self.cutPoint ? ' selected ' : '' ) +'>' + x + '</option>').join('') + '</select></div>';
        upper.html(select);
        upper.find('select').change(function(e) {
          self.cutPoint = parseInt(e.target.value);
          self.refreshGraph(self.saved.type)
        });
      }

    }
    else if (type.indexOf(',') != -1) {
      table.type = 'bar';
      type.split(',').forEach(function (e, i) {
        if (e == 'dotted-line') {
          table.data.datasets[i].type = 'line';
          table.data.datasets[i].borderDash = [5, 2.5];
          table.data.datasets[i].pointRadius = 0.1;
        }
        else if (e == 'area') {
          table.data.datasets[i].type = 'line';
          table.data.datasets[i].fill = true;
        }
        else
          table.data.datasets[i].type = e;
      });
      table.data.datasets.sort(function (a, b) {
        if (a.type == b.type) { return 0; }
        else if (a.type != b.type && a.type != 'bar' && b.type == 'bar') { return -1; }
        else if (a.type != b.type && b.type != 'bar' && a.type == 'bar') { return 1; }
      });
    }
    else
      table.type = type;

    return table;
  };

  regression_panel = function (ref_id) {
    var self = this;
    var titleVis = self.$j('<h5>Regressão</h5>');
    var div_row = self.$j('<div id="regression_div_' + ref_id + '" class="regression_div"></div>');
    var buttonRegType = [['Original', 'origin', 'fa fa-registered', ''], ['Linear', 'line', 'mdi mdi-function', '<sub>1</sub>'],
      ['Polinomial Grau 2', 'polynomial', 'mdi mdi-function', '<sub>2</sub>'], ['Polinomial Grau 3', 'polynomial3', 'mdi mdi-function', '<sub>3</sub>'],
      ['Polinomial Grau 4', 'polynomial4', 'mdi mdi-function', '<sub>4</sub>'],
      ['Exponencial', 'exponential', 'mdi ', 'exp(x)'], ['Logarítimica', 'logarithmic', 'mdi ', 'ln'],
      ['Potência', 'power', 'mdi ', 'x<sup>y</sup>'],
    ];
    var regressionOpts = {
      'line': {  type: "linear"  },
      'exponential': { type: "exponential" },
      'polynomial': { type: "polynomial", calculation: { precision: 10, order: 2 } },
      'polynomial3': { type: "polynomial", calculation: { precision: 10, order: 3 } },
      'polynomial4': { type: "polynomial", calculation: { precision: 10, order: 4 } },
      'power': { type: "power" },
      'logarithmic': { type: "logarithmic", calculation: { precision: 10 } }
    };
    buttonRegType = buttonRegType.map(function (e) {
      var content = '<button type="button" class="btn btn-box-tool reg_btn_' + e[1] +'" >' +
        '<i class="' + e[2] + '" title="' + e[0] + '" ></i>' +
        (e[3] != '' ? '<sub>' + e[3] + '</sub>' : '') +
        '</button>';
      var bt = self.$j(content);
      self.$j(bt).click(function () {
        if (e[1] != 'origin') {
          self.$j('#div_comboForm_' + ref_id).hide();
          self.regresionIndex = parseInt(self.$j('#allseries_' + ref_id).val() != '' ? self.$j('#allseries_' + ref_id).val() : 1);
          self.regressionOpts = regressionOpts[e[1]];
        }
        else {
          self.regresionIndex = null;
          self.regressionOpts = null;
        }
        self.refreshGraph(self.saved.type, self.callbackAction);
      });
      return bt;
    });

    var columns = self.saved.colNames.slice(1);
    var selectorSeries = self.$j('<div class="input-group-prepend ' + (columns.length > 1 ? '' : 'd-none') + '">' +
      '<span for="input-group-text">Série: </span>\n' +
      '<select id="allseries_' + ref_id + '" name="allseries_' + ref_id + '" class="form-control">\n' +
      columns.map((x, i) => '<option value="' + (i) + '" >' + x + '</option>').join('\n') +
      '\n</select>' +
      '</div>'
    );
    var hr = self.$j('<hr/>');


    self.$j(div_row).append([titleVis, buttonRegType, selectorSeries, hr]);
    return div_row;
  };

  init_graphpanel = function (div, colNames) {
    var self = this;
    var id = div.replace('customReportCell_', '');
    var buttons = self.$j('#graphpanel_' + id + ' button[gtype]');
    buttons.forEach(function (e, i) {
      self.$j(e).unbind('click');
      var gtype = self.$j(e).attr('gtype');
      self.$j(e).click(function () {
        self.$j('#div_comboForm_' + id).hide();
        self.refreshGraph(gtype);
      });
    });
    //Combo button
    if ( self.$j('#show_comboForm_' + id).length > 0 ) {
      self.$j('#comboForm_' + id + ' div.comboFormFields').html('');
      self.$j('#show_comboForm_' + id).unbind('click').click(function () {
        self.$j('#div_comboForm_' + id).toggle();
      });
    }

    for (var x = 1; x < colNames.length; ++x) {
      var formatted = colNames[x].replace(/ /g, '_');
      var newSelector = self.$j(`
        <div class="input-group-prepend">
          <span class="input-group-text" for='serie_{{id}}_{{id_report}}'>{{x}}</span>
          <select id='serie_{{id}}_{{id_report}}' name='serie_{{id}}_{{id_report}}' class="form-control">
              <option value="bar">Barra</option>
              <option value="line">Linha</option>
              <option value="dotted-line">Linha Pontilhada</option>
              <option value="area">Área</option>
          </select>
        </div>`
        .replace('{{x}}', colNames[x]).replace(/\{\{id\}\}/g, formatted).replace(/\{\{id_report\}\}/g, id) );
      var combo = self.$j('#comboForm_' + id + ' div.comboFormFields');
      if (combo.length > 0)
        combo.append(newSelector);
    }

    var comboRef = self.$j('#comboForm_' + id + ' button.ComboApply');
    if (comboRef.length > 0)
      comboRef.unbind('click').click(function () {
        self.refreshGraph(self.$j('#comboForm_' + id));
      });

    //Download Button
    self.$j('.downloadGraphSection_' + id + ' button[dtype]').each(function (e) {
      var dType = self.$j(e).attr('dtype').toLowerCase();
      self.$j(e).unbind('click');
      self.$j(e).click(function () {
        self.$j('#div_comboForm_' + id).hide();

        if (dType != 'xls')
          self.printGraph(dType);
        else
          self.download(null);
      });
    });

    //Habilita Graphs do tipo stacked para mais de 1 série
    if (colNames.length > 2)
      self.$j('#graphpanel_' + id + ' button[gtype^="stacked"]').show();
    else
      self.$j('#graphpanel_' + id + ' button[gtype^="stacked"]').hide();
  };

  initializeGraph = function (colNames, data, opt, type, div, config, interval, isOriginal, smartUpdate) {
    var self = this; // Recebe as configurações default do objeto
    if (isOriginal)
      self.saved = { 'colNames': colNames, 'data': data, 'opt': opt, 'type': type, 'div': div, 'config': config, 'interval': interval };

    if (config.regularTranspose) {
      var result = self.transposeMatrix(colNames, data);
      colNames = result.colNames;
      data = result.data;
    }

    if (config.callerAction)
      self.callerAction = eval('(' + config.callerAction + ')');

    if (data.length > 0 || smartUpdate) {
      var chart;

      var confList = config; //confList recebe os atributos default de configuração
      if (confList.checkboxData) {
        var agg = self.checkboxData(div.replace('customReportCell_', ''), colNames, data, true, confList);
        colNames = agg.colNames;
        data = agg.data;
      }

      var series = self.createSeries(colNames, data, type, confList);
      var ctxVar = { 'series': series, 'interval': interval, 'div': div };
      var general_conf = self.custom_config({
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 500,
          plugins: { datalabels: { display: false } },
          tooltips: { mode: 'index', position: 'nearest' },
          layout: { padding: 20 }
        }
      }, confList, ctxVar, self.saved.type == type);

      var conf = self.extend(true, series, opt, general_conf);

      if (typeof conf.options?.title?.text !== 'string' && typeof conf.options?.title?.text?.text === 'string') {
        conf.options.title.text = conf.options.title.text.text;
      }

      // conf.options.title.text = conf.options.title.text.text
      self.init_graphpanel(div, colNames);
      self.final_conf = { ...conf };
      if (!smartUpdate || self.curr_graph == null) {
        self.$j('#' + div).replaceWith('<canvas id="' + div + '" class="card-report graphComp customReportCell"></canvas>');

        var ctx = (<HTMLCanvasElement>document.getElementById(div)).getContext('2d');
        chart = new Chart(ctx, conf);
        self.curr_graph = chart;
      }
      else {
        self.smartComparing(conf);
        self.curr_graph.update();
      }
    }
    else
      self.clean_canvas(div, '<center>Sem dados para exibir</center>', smartUpdate);

    self.curr = { 'colNames': colNames, 'data': data, 'opt': opt, 'type': type, 'div': div, 'config': config, 'interval': interval };
  };

  smartComparing = function (conf) {
    var self = this;
    var compare = function (a, b) {
      if (Array.isArray(a) && Array.isArray(b))
        return a.length === b.length && a.every((val, index) => val === b[index]);
      else
        return a == b;
    }
    if (!compare(conf.data.labels, self.curr_graph.data.labels)) { //Comparação de labels
      for (var i = 0; i < conf.data.labels.length; ++i) {
        if (self.curr_graph.data.labels.length > i) {
          if (self.curr_graph.data.labels[i] != conf.data.labels[i])
            self.curr_graph.data.labels[i] = conf.data.labels[i];
        }
        else
          self.curr_graph.data.labels.push(conf.data.labels[i]);
      }
      if (self.curr_graph.data.labels.length > conf.data.labels.length) //Remove séries a mais
        self.curr_graph.data.labels.length = conf.data.labels.length;
    }
    for (var i = 0; i < conf.data.datasets.length; ++i) { //Comparação de séries
      if (self.curr_graph.data.datasets.length > i) {
        if (self.curr_graph.data.datasets[i].yAxisID != conf.data.datasets[i].yAxisID || !compare(self.curr_graph.data.datasets[i].borderColor, conf.data.datasets[i].borderColor) || !compare(self.curr_graph.data.datasets[i].backgroundColor, conf.data.datasets[i].backgroundColor) || self.curr_graph.data.datasets[i].label != conf.data.datasets[i].label || self.curr_graph.data.datasets[i].pointRadius != conf.data.datasets[i].pointRadius || self.curr_graph.data.datasets[i].fill != conf.data.datasets[i].fill)
          self.curr_graph.data.datasets[i] = conf.data.datasets[i];
        else if (!compare(self.curr_graph.data.datasets[i].data, conf.data.datasets[i].data))
          self.curr_graph.data.datasets[i].data = conf.data.datasets[i].data;
        else if (self.curr_graph.data.datasets[i].value != conf.data.datasets[i].value)
          self.curr_graph.data.datasets[i].value = conf.data.datasets[i].value

      }
      else
        self.curr_graph.data.datasets.push(conf.data.datasets[i]);
    }
    if (self.curr_graph.data.datasets.length > conf.data.datasets.length) //Remove séries a mais
      self.curr_graph.data.datasets.length = conf.data.datasets.length;
    if (self.curr_graph.type != conf.type)
      self.curr_graph.type = conf.type;
  };

  custom_config = function (opts, confList, ctxVar, isOriginal) {
    var self = this;
    var axisPathStart = function (axis) {
      var axisPath = axis ?? 'yAxes';
      if ( opts.options.scales == undefined )
        opts.options.scales = ( axisPath == 'yAxes' ?
          { yAxes: [{ ticks: {min: undefined, max: undefined} }]} :
          { xAxes: [{ ticks: {min: undefined, max: undefined} }]}
        );
      if ( opts.options.scales[axisPath] == undefined )
        opts.options.scales[axisPath] = [{ ticks: {min: undefined, max: undefined} }];
      if ( opts.options.scales[axisPath][0].ticks == undefined )
        opts.options.scales[axisPath][0].ticks = {min: undefined, max: undefined};
    };
    if (confList.ignore_default)
      opts = { options: { plugins: { datalabels: { display: false } } } };

    opts.options.legend = {
      display: !confList.hideLegend,
      position: 'bottom',
      onClick: function (e, legendItem) { // Código para eixo Y inteligente
        var chart = self.curr_graph;
        if ( ['pie', 'doughnut'].includes(ctxVar.series.type) ) {
          var idx = legendItem.index;
          const meta = chart.getDatasetMeta(0).data[idx];
          meta.hidden = !chart.getDatasetMeta(0).data[idx].hidden;
        }
        else {
          var idx = legendItem.datasetIndex;
          const meta = chart.getDatasetMeta(idx);
          meta.hidden = meta.hidden === null ? !chart.data.datasets[idx].hidden : null;
        }

        if (self.curr_graph.options.scales.yAxes.length > 1) {
          for (var i = 0; i < self.curr_graph.options.scales.yAxes.length; ++i) {
            self.curr_graph.options.scales.yAxes[i].display = false;
            for (var j = 0; j < chart.data.datasets.length; ++j) {
              var curr_meta = chart.getDatasetMeta(j);
              if (chart.data.datasets[j].yAxisID == self.curr_graph.options.scales.yAxes[i].id
                && (curr_meta.hidden === null || curr_meta.hidden == false))
                self.curr_graph.options.scales.yAxes[i].display = true;
            }
          }
        }
        chart.update();
      }
    };

    if ( confList.layout )
      opts.options.layout = confList.layout;

    if ( confList.valueLabel ) {
      opts.options.valueLabel = confList.valueLabel;
      if (opts.options.valueLabel.formatter && opts.options.valueLabel.formatter.startsWith('default') )
        opts.options.valueLabel.formatter = self.get_formatter(opts.options.valueLabel.formatter, 1)[0];
      else if (opts.options.valueLabel.formatter )
        opts.options.valueLabel.formatter = eval("(" + opts.options.valueLabel.formatter + ")");
    }

    if (confList.formatter) {
      var format = self.get_formatter(confList, confList.formatter.split(',').length);
      if (!opts.options.tooltips)
        opts.options.tooltips = {};

      opts.global_formatter = confList.formatter;
      opts.regularTranspose = confList.regularTranspose;
      opts.options.tooltips.callbacks = {
        label: function (tooltipItem, data) {
          var index = ( !opts.regularTranspose ? tooltipItem.datasetIndex : tooltipItem.index );
          var formatIndex = index % format.length;
          var tick_value = null;
          if ( ctxVar.series.type != 'horizontalBar' && ctxVar.series.type != 'scatter')
            tick_value = tooltipItem.yLabel && tooltipItem.yLabel != '' ? tooltipItem.yLabel : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          else if (ctxVar.series.type == 'scatter')
            tick_value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          else
            tick_value = tooltipItem.xLabel && tooltipItem.xLabel != '' ? tooltipItem.xLabel : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];


          if (ctxVar.series.type == 'scatter')
            return data.datasets[tooltipItem.datasetIndex].label +
            ': (' + format[formatIndex](tick_value.x) + '; ' + format[formatIndex](tick_value.y) + ')';
          else if (['pie', 'doughnut', 'radar'].includes(ctxVar.series.type))
            return data.datasets[tooltipItem.datasetIndex].label + ' - ' +
              data.labels[tooltipItem.index] +
              ': ' + format[formatIndex](tick_value);
          else
            return data.datasets[tooltipItem.datasetIndex].label +
              ': ' + format[formatIndex](tick_value);
        }
      }
    }
    if (confList.gaugeDataLabels) {
      opts.options.plugins = {
        datalabels: {
          display: true,
          formatter: function (value, context) {
            if (confList.gaugeDataLabelFormatter) {

              var gff = eval("(" + confList.gaugeDataLabelFormatter.replace(/`;/g, "'") + ")");
              const indexLength = context.dataset.data.length;
              const indexGauge = context.dataIndex;

              // Retorna o primeiro sigal >, o último sinal < e o value inteiro
              if (gff.toString(1).substr(29, 7) == 'format1') {
                if (indexGauge == 0)
                  value = '< ' + Math.round(value.toFixed(2));
                else if (indexGauge == indexLength - 2)
                  value = '> ' + Math.round(value.toFixed(2));
                else
                  value = ''
              } else if (gff.toString(1).substr(29, 7) == 'format2') {
                if (indexGauge == 0)
                  value = '< ' + value.toFixed(2);
                else if (indexGauge == indexLength - 2)
                  value = '> ' + value.toFixed(2);
                else
                  value = ''
              }
              return value;
            }
            else
              return '< ' + Math.round(value);
          },
          color: function (context) {
            if (confList.gaugeDataLabelFormatter)
              return 'rgba(0, 0, 0, 1.0)'
            else
              return context.dataset.backgroundColor;
          },
          // color: 'rgba(255, 255, 255, 1.0)',
          backgroundColor: function (context) {
            if (confList.gaugeDataLabelFormatter)
              return 'rgba(0, 0, 0, 0)'
            else
              return 'rgba(0, 0, 0, 1.0)'
          },
          anchor: 'right',
          align: 'center',
          offset: 50,
          borderWidth: function (context) {
            return context.dataset.borderWidth;
          },
          borderRadius: 0,
          font: {
            weight: 'bold'
          }
        }
      };
      opts.options.cutoutPercentage = 50;
      opts.options.needle = {
        // Needle circle radius as the percentage of the chart area width
        radiusPercentage: 1.5,
        // Needle width as the percentage of the chart area width
        widthPercentage: 2,
        // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
        lengthPercentage: 80,
      }

    }
    if (confList.annotation) {
      opts.options.annotation = confList.annotation;
    }
    if ( confList.avg || confList.std || confList.var ) {
      if(opts.options.annotation == undefined )
        opts.options.annotation = { drawTime: "afterDatasetsDraw", annotations: []};

        var format = self.get_formatter(confList, confList.formatter.split(',').length);
        [ confList.avg ? 'x̅' : null, confList.std ? 'σ' : null, confList.var ? 'σ²' : null ].filter(x=> x!= null).forEach(medida => {

          var annot = ctxVar.series.data.datasets.map((x,i) => {
            var label = ctxVar.series.data.datasets.length == 1 ? '' : ' ' + x.label;
            var color = 'black';//ctxVar.series.data.datasets[i]["backgroundColor"];
            var result = x.data.reduce((a, b) => a + b, 0) / x.data.length;
            if (medida == 'σ')
              result = Math.sqrt(x.data.map(x => Math.pow(x - result, 2)).reduce((a, b) => a + b) / x.data.length);
            else if(medida == 'σ²')
              result = x.data.map(x => Math.pow(x - result, 2)).reduce((a, b) => a + b) / x.data.length;

            var value = format[i] ? format[i](result) : result;
            return { "label": { "enabled": true, "position": 'right', "content": medida + label + '=' + value }, "id": "a-line-" + medida, "type": "line", "mode": "horizontal", "scaleID": "y-axis-0", "value": result, "borderColor": color, "backgroundColor": color, "borderWidth": 2, "borderDash": [2, 2], "borderDashOffset": 5 }
          });
          opts.options.annotation.annotations = opts.options.annotation.annotations.concat(annot)
        })

    }
    if (confList.scales) {
      opts.options.scales = confList.scales;
    }

    if( confList.canvasBackgroundColor ) {
      var pluginCBC = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
          var {ctx} = chart;
          var chartArea = chart.chartArea;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = options.color || '#99ffff';
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
          ctx.restore();
        }
      };
      opts.plugins = [pluginCBC];
      opts.options.plugins.customCanvasBackgroundColor = { color: confList.canvasBackgroundColor, };
    }

    if (confList.bands == 'pf_opts' && isOriginal) {
      opts.options.scales = {
        yAxes: [{
          position: 'left',
          ticks: {
            callback: function (value, index, values) {
              return (value >= 0 ? (1 - value) : (1 - (-1) * value)).toFixed(2);
            }
          }
        },
        {
          position: 'left',
          ticks: {
            callback: function (value, index, values) {
              var zero = values.indexOf(0);
              if (Math.floor(zero / 2) == index || (zero < values.length && Math.floor((values.length - zero) / 2) + zero == index))
                return (value >= 0 ? 'Ind.' : 'Cap.');
              else
                return null;
            }
          }
        }
        ]
      };

      var format = self.get_formatter({ 'formatter': 'default3,default3,default3' }, 3);
      opts.options.tooltips = {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            var multiplier = (label == 'Capacitivo' ? -1 : 1);
            if (label) {
              label += ': ';
            }
            label += format[tooltipItem.datasetIndex](1 - multiplier * tooltipItem.yLabel);
            return label;
          }
        }
      };

      if (ctxVar.interval == 'hours')
        opts.plugins = [{
          beforeDraw: function (chart, easing, options) {
            function drawBand(start_y, end_y, start_label, end_label, color) {
              var ctx = chart.chart.ctx;
              var yRangeBegin = start_y;
              var yRangeEnd = end_y;

              var xaxis = chart.scales['x-axis-0'];
              var yaxis = chart.scales['y-axis-0'];

              var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
              var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

              var xRangeBeginPixel = xaxis.getPixelForValue(start_label);
              var xRangeEndPixel = xaxis.getPixelForValue(end_label);

              var offsetX = xaxis.getPixelForValue(xaxis._ticks[0].value) - xaxis.left;

              ctx.save();

              for (var yPixel = Math.min(yRangeBeginPixel, yRangeEndPixel); yPixel <= Math.max(yRangeBeginPixel, yRangeEndPixel); ++yPixel) {
                ctx.beginPath();
                ctx.moveTo(xRangeBeginPixel - offsetX, yPixel);
                ctx.strokeStyle = color;
                ctx.lineTo(xRangeEndPixel + offsetX, yPixel);
                ctx.stroke();
              }

              ctx.restore();
            }

            var capacitive = chart.config.data.datasets[0].data.filter(function (x, e) { return x !== 0; });
            var tip = chart.config.data.datasets[1].data.filter(function (x, e) { return x != null && x !== 0; });
            var out = chart.config.data.datasets[2].data.filter(function (x, e) { return x != null && x !== 0; });

            if (capacitive.length > 0) {
              var cstart = chart.config.data.datasets[0].data.indexOf(capacitive[0]);
              var cend = chart.config.data.datasets[0].data.lastIndexOf(capacitive[capacitive.length - 1]);
              var cstart_label = chart.config.data.labels[cstart];
              var cend_label = chart.config.data.labels[cend];
              drawBand(0, -0.1, cstart_label, cend_label, 'rgba(0, 99, 132, 0.2)');
            }
            if (out.length > 0) {
              var ostart = chart.config.data.datasets[2].data.indexOf(out[0]);
              var oend = chart.config.data.datasets[2].data.lastIndexOf(out[out.length - 1]);
              var ostart_label = chart.config.data.labels[ostart];
              var oend_label = chart.config.data.labels[oend];
              drawBand(0, 0.1, ostart_label, oend_label, 'rgba(22, 132, 0, 0.2)');
            }
            if (tip.length > 0) {
              var tstart = chart.config.data.datasets[1].data.indexOf(tip[0]);
              var tend = chart.config.data.datasets[1].data.lastIndexOf(tip[tip.length - 1]);
              var tstart_label = chart.config.data.labels[tstart];
              var tend_label = chart.config.data.labels[tend];
              drawBand(0, 0.1, tstart_label, tend_label, 'rgba(250, 132, 0, 0.6)');
            }

            //drawBand(0, -0.1, 'rgba(0, 99, 132, 0.2)');
            // draw the green band
          }
        }];
    }
    if (confList.title) {
      opts.options.title = { display: true, text: confList.title } ;
    }
    if (confList.tension) {
      ctxVar.series.data.datasets[1]['lineTension'] = 0
      ctxVar.series.data.datasets[1]['tension'] = 0
    }

    if (confList.order) {
      for (var c = 0; c < confList.order.length; ++c) {
        ctxVar.series.data.datasets[c].order = confList.order[c];
      }
    }

    if (confList.titleX != null || ['bubble'].includes(ctxVar.series.type)) {
      self.nestedAttribute(opts, "options.scales.xAxes",
        [{
          scaleLabel: {
            display: (confList.titleX && confList.titleX != ''),
            labelString: (confList.titleX != '' ? confList.titleX : '')
          },
          ticks: ['bubble'].includes(ctxVar.series.type) && self.runTimeConf['xAxisFormat'] != null ? {
            userCallback: function (label, index, labels) {
              return moment(label).format(self.runTimeConf['xAxisFormat']);
            }
          } : {}
        }]);
    }
    if (confList.titleY != null || confList.formatter || confList.serieLinearScale) {
      //Configuração de formato dos números do eixo Y
      var format = confList.formatter ? self.get_formatter(confList, confList.formatter.split(',').length, true) : null;
      //Configuração de múltiplos eixos Y
      var index = 0;
      var axisMap = confList.serieLinearScale ? self.map(confList.serieLinearScale.conf, function (e, i) {
        var aConf = { id: i, type: 'linear', position: e[0], label: (e.length > 2 ? e[3] : null) };
        if (e[1] != null && e[2] != null)
          aConf['ticks'] = { min: e[1], max: e[2] };
        else if (!confList.isAutoScale)
          aConf['ticks'] = {
            min: Math.min(0, Math.min(...ctxVar.series.data.datasets[index].data)),
            max: undefined //Math.round(Math.max(...ctxVar.series.data.datasets[index].data) * 1.1 )
          };
        if (confList.isLimitScale) {
          aConf['ticks'] = {
            min: Math.round(Math.min(Math.min(...ctxVar.series.data.datasets[index].data) * 0.9, Math.min(...ctxVar.series.data.datasets[index].data))),
            max: Math.round(Math.max(Math.max(...ctxVar.series.data.datasets[index].data) * 1.07, Math.max(...ctxVar.series.data.datasets[index].data))),
            stepSize: Math.round(Math.max(...ctxVar.series.data.datasets[0].data) * 0.005)
          }
          // if (aConf['position'] == "right" && aConf['stepSize'] == undefined) {
          //   aConf['ticks'] = {
          //     stepSize: Math.round(Math.max(...ctxVar.series.data.datasets[0].data) * 0.005)
          //   }
          // }
        }

        if (confList.serieLinearScale.conf_properties && confList.serieLinearScale.conf_properties[i])
          aConf['precision'] = confList.serieLinearScale.conf_properties[i].precision,
            aConf['stepSize'] = confList.serieLinearScale.conf_properties[i].stepSize,
            aConf['gridLines'] = confList.serieLinearScale.conf_properties[i].gridLines

        ++index;
        return aConf;
      }) : ['null'];
      self.nestedAttribute(opts, "options.scales.yAxes", axisMap.map(function (e, i) {
        var axis = {};
        if (!(i == 0 && e == 'null')) {
          axis['id'] = e.id;
          axis['type'] = e.type;
          axis['position'] = e.position;
          axis['gridLines'] = {
            display: e.gridLines
          };
          opts.options.onClick

        }
        if (confList.formatter ||
          (e['ticks'] && (e['min'] || e['max']))
        ) {
          axis['ticks'] = {
            min: e['ticks'] && e['ticks']['min'] != undefined ? e['ticks']['min'] : undefined,
            max: e['ticks'] && e['ticks']['max'] != undefined ? e['ticks']['max'] : undefined,
            precision: e['ticks'] && e['ticks']['precision'] != undefined ? e['ticks']['precision'] : undefined,
            stepSize: e['ticks'] && e['ticks']['stepSize'] != undefined ? e['ticks']['stepSize'] : undefined,
            callback: confList.formatter ? function (value, index, values) {
              return format[0](value);
            } : undefined
          };
        }
        if (e != 'null' && e.label)
          axis['scaleLabel'] = { display: true, labelString: e.label };
        else if (confList.titleY)
          axis['scaleLabel'] = { display: true, labelString: confList.titleY };

        return axis;
      }));

    }
    if (confList.drillDownFunc) {
      var drillDownLevel = confList.drillDownLevel ? confList.drillDownLevel.split(',') : ['year', 'month', 'week'];
      if (confList.drillDownFunc == 'time' && drillDownLevel.indexOf(ctxVar.interval) != -1)
        opts.options.onClick = function (e) { self.drillDown(e, ctxVar.series, ctxVar.interval); };
    }
    if (confList.labels) {
      opts.options.plugins.datalabels.display = true;
      for (var i in confList.labels) {
        if (confList.labels.hasOwnProperty(i))
          self.nestedAttribute(opts.options.plugins.datalabels, i,
            i == 'formatter' ? eval(confList.labels[i]) : confList.labels[i]
          )
      }
    }
    if (confList.isStepScale) {
      self.nestedAttribute(opts, "options.scales.xAxes",
        [{
          scaleLabel: {
            display: (confList.titleX && confList.titleX != ''),
            labelString: (confList.titleX != '' ? confList.titleX : '')
          },
          ticks: ['bubble'].includes(ctxVar.series.type) && self.runTimeConf['xAxisFormat'] != null ? {
            stepSize: 10,
            userCallback: function (label, index, labels) {
              return moment(label).format(self.runTimeConf['xAxisFormat']);
            }
          } : {
            stepSize: Math.round(ctxVar.series.data.labels.length * 0.05)
          }
        }]);

    }
    if (confList.rgPanel) {
      var id = ctxVar.div.replace('customReportCell_', '');
      self.$j('#regression_div_' + id).replaceWith(self.regression_panel(id)[0]);

      if ( ['line', 'scatter', 'bar'].includes(ctxVar.series.type) )
        self.$j('#regression_div_' + id).show();
      else
        self.$j('#regression_div_' + id).hide();

      if (self.regresionIndex != null) {
        opts.options.plugins.regressions = {
          onCompleteCalculation: function (chart2) {
            var sections = window['ChartRegressions'].getSections(chart2, self.regresionIndex);
            chart2.options.title.display = true;
            if ( sections.length > 0 ) {
              // Adiciona função matemática ao título
              var typesR = {'polynomial': 'Polinomial', 'linear': 'Linear', 'exponential': 'Exponêncial', 'power': 'Potência', 'logarithmic': 'Logaritmo' };
              var degree = sections[0].type[0] == 'polynomial' && sections[0].calculation && sections[0].calculation.order ? ' Grau ' + sections[0].calculation.order : '';
              chart2.options.title.text = [sections[0].result.string + ' ( ' + typesR[sections[0].type[0]] + degree + ' )'];

              // Adiciona legend r2 se existir
              if (sections[0].result.r2)
                chart2.options.title.text.push( 'r2 = ' + Math.round(sections[0].result.r2 * 1000) / 10 + '%');

              // Ajusta valores dos eixos x e y para exibir a área do gráfico contemplado dados e curva de regressão
              if ( chart2.config.type == 'scatter' ) {
                var xMinValue = Math.min( ...chart2.config.data.datasets[0].data.filter(x => x.x != null && x.y != null ).map(x => x.x ) );
                var yMinValue = Math.min( ...chart2.config.data.datasets[0].data.filter(x => x.x != null && x.y != null ).map(x => x.y ) );
                var predYMinValue = sections[0].result.predict(0)[1];
                if ( !isNaN(yMinValue) && !isNaN(predYMinValue) ) {
                  var minY = Math.floor( 0.9*Math.min(...[yMinValue, predYMinValue]) );
                  if ( !chart2.options.scales.yAxes[0].ticks )
                    chart2.options.scales.yAxes[0].ticks = { min: 0 };

                  chart2.options.scales.yAxes[0].ticks.min = minY;
                  chart2.options.scales.xAxes[0].ticks.min = Math.min(...[0, xMinValue]);
                }
              }
            }
            self.regressionResult = sections;
          }
        };
        ctxVar.series.data.datasets[self.regresionIndex].regressions = self.regressionOpts;
      }
    }
    if (confList.hideGridLinesFunc) {
      if (!opts.options.scales.yAxes) { opts.options.scales.yAxes = [{}]; }
      if (!opts.options.scales.xAxes) { opts.options.scales.xAxes = [{}]; }
      opts.options.scales.yAxes[0].gridLines = { display: false, drawTicks: false };
      opts.options.scales.yAxes[0].ticks = { display: false };
      opts.options.scales.xAxes[0].gridLines = { display: false, drawTicks: false };
      opts.options.scales.xAxes[0].ticks = { display: false };
    }
    if (confList.stacked || self.runTimeConf['xAxisStacked']) {
      if (!opts.options) { opts.options = {}; }
      if (!opts.options.scales) { opts.options.scales = {}; }
      if (!opts.options.scales.xAxes) { opts.options.scales.xAxes = [{}]; }
      opts.options.scales.xAxes[0].stacked = (confList.stacked == 'true' || confList.stacked == true || self.runTimeConf['xAxisStacked'] == true);
    }
    if (confList.stackedY || self.runTimeConf['yAxisStacked']) {
      if (!opts.options) { opts.options = {}; }
      if (!opts.options.scales) { opts.options.scales = {}; }
      if (!opts.options.scales.yAxes) { opts.options.scales.yAxes = [{}]; }
      opts.options.scales.yAxes[0].stacked = (confList.stackedY == 'true' || confList.stackedY == true || self.runTimeConf['yAxisStacked'] == true);
    }

    if (ctxVar.series.type == 'horizontalBar' && opts.options.scales && opts.options.scales.yAxes) {
      opts.options.scales.yAxes.forEach(x => { delete x.type; delete x.ticks; })
    }

    if (confList.histogramView) {
      axisPathStart('yAxes');
      opts.options.scales.yAxes[0].ticks.beginAtZero = true;
    }

    if (confList.beginAtZeroAxis) {
      if ( ['horizontalBar', 'stackedbar'].includes(ctxVar.series.type) ) {
        axisPathStart('xAxes');
        opts.options.scales.xAxes[0].ticks.beginAtZero = true;
      }
      else {
        axisPathStart('yAxes');
        opts.options.scales.yAxes[0].ticks.beginAtZero = true;
        if ( opts.options.scales.yAxes.length > 1 )
          opts.options.scales.yAxes[1].ticks.beginAtZero = true;
      }
    }

    if (opts.options.annotation && opts.options.annotation.annotations && opts.options.annotation.annotations.filter(x => x.mode == 'horizontal').length > 0 ) {
      if ( opts.options.scales == undefined )
        opts.options.scales = { yAxes: [{ ticks: {min: undefined, max: undefined} }]};
      if ( opts.options.scales.yAxes == undefined )
        opts.options.scales.yAxes = [{ ticks: {min: undefined, max: undefined} }];
      if ( opts.options.scales.yAxes[0].ticks == undefined )
        opts.options.scales.yAxes[0].ticks = {min: undefined, max: undefined};

      // Seta valores máximos entre os dados e a annotation
      var data_min = Math.min(...ctxVar.series.data.datasets.map(x => Math.min(...x.data) ) );
      var notation_min = Math.min(...opts.options.annotation.annotations.filter(x => x.mode == 'horizontal').map(x=>x.value));
      var min_ref = Math.floor( Math.min(...[data_min, notation_min]) );
      opts.options.scales.yAxes[0].ticks.min = Math.floor(  Math.min(...[min_ref - 1, min_ref *0.97 ]) );

      // Seta valores mínimos entre os dados e a annotation
      var data_max = Math.max(...ctxVar.series.data.datasets.map(x => Math.max(...x.data) ) );
      var notation_max = Math.max(...opts.options.annotation.annotations.filter(x => x.mode == 'horizontal').map(x=>x.value));
      var max_ref = Math.ceil( Math.max(...[data_max, notation_max]) );
      opts.options.scales.yAxes[0].ticks.max =  Math.ceil(  Math.max(...[max_ref + 1, max_ref *1.03 ]) );

    }

    return opts;
  };

  drillDown = function (e, series, interval) {
    var self = this;
    var infered_dates = function (labels, from, to, interval) {
      if (interval == 'year')
        return labels.map(x => moment(x, "YYYY").toDate());
      else if (interval == 'month')
        return labels.map(x => moment(x, "MM/YYYY").toDate());
      else if (interval == 'week')
        return labels.map((x, i) => moment(x + '/' + new Date(from).addDays(i).getFullYear(), "DD/MM/YYYY").toDate());
      else if (interval == 'hours')
        return labels.map((x, i) => moment(moment(new Date(from).addHours(i)).format("DD/MM/YYYY ") + x, "DD/MM/YYYY HH:mm").toDate());
      else
        return labels;
    };
    var activePoints = self.curr_graph.getElementsAtEvent(e);
    if (activePoints.length && self.caller) {
      var dates = infered_dates(series.data.labels, self.caller.lastsent.from, self.caller.lastsent.to, interval);
      var selectedIndex = activePoints[0]._index;
      var year = dates[selectedIndex].getFullYear();
      var month = dates[selectedIndex].getMonth();
      var day = dates[selectedIndex].getDate();
      var hour = dates[selectedIndex].getHours();
      var value = series.data.labels[selectedIndex].split('/');
      var start, end;
      if (interval == 'year') {
        start = new Date(value[0], 0, 1, 0, 0);
        end = new Date(value[0], 0, 1, 0, 0).addMonths(12);
      }
      else if (interval == 'month') {
        start = new Date(value[1], parseInt(value[0]) - 1, 1, 0, 0);
        end = new Date(value[1], parseInt(value[0]) - 1, 1, 0, 0).addMonths(1);
      }
      else if (interval == 'week') {
        start = new Date(year, parseInt(value[1]) - 1, value[0], 0, 0);
        end = new Date(year, parseInt(value[1]) - 1, value[0], 0, 0).addDays(1);
      }
      else if (interval == 'hours') {
        start = new Date(year, month, day, hour, 0);
        end = new Date(year, month, day, hour, 0).addHours(1);
      }
      else
        return;


      if ( self.caller.fields ) {
        var datebtw = self.caller.fields.find(x => x.field.type == 'DATEBTW');
        if ( datebtw ) {
          datebtw.selected.startDate = moment(start);
          datebtw.selected.endDate = moment(end);
          self.caller.getDirectives(document.querySelector('#date-interval'))[0].picker.setStartDate(moment(start))
          self.caller.getDirectives(document.querySelector('#date-interval'))[0].picker.setEndDate(moment(end))
          self.$j(".date-interval").val(moment(start).format('DD/MM/YYYY HH:mm') + ' - ' + moment(end).format('DD/MM/YYYY HH:mm'));
          self.$j('#applyFilters').trigger('click');
        }
      }
    }
  };

  dynamicReport = function (id, title, series) {
    var self = this;
    var graphPanelBtn = function (ref_id) {
      var buttonGraphs = [['Original', 'origin', 'fa fa-registered'], ['Pizza', 'pie', 'fas fa-chart-pie'],
      ['Linha', 'line', 'fas fa-chart-line'], ['Dispersão', 'scatter', 'mdi mdi-chart-scatterplot-hexbin'],
      ['Bolhas', 'bubble', 'mdi mdi-chart-bubble'], ['Área', 'area', 'fas fa-chart-area'], ['Histograma', 'histogram', 'mdi mdi-chart-histogram'],
      ['Boxplot', 'boxplot', 'mdi mdi-textbox fa fa-rotate-90'],
      ['Colunas', 'column', 'fas fa-chart-bar'], ['Colunas Empilhadas', 'stackedcolumn', 'fas fa-chart-bar'],
      ['Barras', 'bar', 'fas fa-chart-bar fa-rotate-90'], ['Barras Empilhadas', 'stackedbar', 'fas fa-chart-bar fa-rotate-90'],
      ['Radar', 'radar', 'fas fa-circle-notch'], ['Donut', 'doughnut', 'fas fa-dot-circle'],
      ['Combo', '', 'far fa-edit']
      ];
      buttonGraphs = buttonGraphs.map(function (e) {
        var content = '<button type="button" gtype="' + e[1] + '" class="btn btn-box-tool" >' +
          '<i class="' + e[2] + '" title="' + e[0] + '" ></i>' +
          (e[2].indexOf('stacked') != -1 ? '<sub>E</sub>' : '') +
          '</button>';
        var bt = self.$j(content);
        if (e[0] != 'Combo')
          self.$j(bt).click(function () {
            self.$j('#div_comboForm_' + ref_id).hide();
            self.refreshGraph(e[1]);
          });
        else
          self.$j(bt).click(function () {
            self.$j('#div_comboForm_' + ref_id).toggle();
          });
        return bt;
      });

      var configButton = self.$j('<button id=btn_graph_panel_"' + ref_id + '" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="btn btn-box-tool dropdown-toggle"> <i class="fas fa-cogs" title="Opções do Gráfico"></i></button>');
      var dropDown = self.$j('<div onclick="event.stopPropagation();" class="dropdown-menu" aria-labelledby="' + ref_id + '" ></div>');
      var titleVis = self.$j('<h5>Visualizações</h5>');
      var combo_area = self.$j(
        `<div id="div_comboForm_codiRBD" name="div_comboForm_codiRBD" style="display: none;" >
          <div id="comboForm_codiRBD" name="comboForm_codiRBD" class="inner_dropdown" style="margin: 15px;">
            <div class="comboFormFields">
            </div>
            <button class="btn btn-primary ComboApply">Aplicar</button>
          </div>
        </div>`.replace(/codiRBD/g, ref_id)
      );
      dropDown.append([titleVis, buttonGraphs, combo_area]);
      self.$j(configButton).click(function () {
        if (self.$j(dropDown).find('.comboFormFields').children().length == 0) {
          var lSeries = self.saved.colNames.slice(1).map(function (e) {
            var s_id = id + '_' + e.replace(/ /g, '_');
            var label = self.$j('<label for="' + s_id + '">' + e + '</label>');
            var select = self.$j('<select id="' + s_id + '" name="' + s_id + '" class="form-control">' +
              `<option value="bar" >Barra</option>
                <option value="line" >Linha</option>
                <option value="dotted-line" >Linha Pontilhada</option>
                <option value="area" >Área</option>
              </select>`);
            return [label, select];
          });
          var btnApply = self.$j('<button class="btn btn-primary ComboApply">Aplicar</button>');
          self.$j(btnApply).click(function () {
            self.refreshGraph(self.$j(dropDown).find('.inner_dropdown'));
          });
          self.$j(dropDown).find('.comboFormFields').append(lSeries);
          self.$j(dropDown).find('.inner_dropdown').append(btnApply);
        }
      });

      return [configButton, dropDown];
    };

    var downloadGraph = function (ref_id) {
      var close = self.$j('<button type="button" class="btn btn-box-tool btn-dyncustom" data-widget="collapse"><i class="fas fa-window-close"></i></button>');
      var print = self.$j('<button id=btn_download_"' + ref_id + '" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="btn btn-box-tool dropdown-toggle"><i class="fa fa-download"></i></button>');
      var dropDown = self.$j('<div class="dropdown-menu" aria-labelledby="' + ref_id + '"> <div class="inner_dropdown" style="margin: 15px;"></div>  </div>');
      ['png', 'jpg', 'bmp'].forEach(function (e, i) {
        var d = self.$j('<button type="button" class="btn btn-block btn-default"> Baixar em ' + e.toUpperCase() + '</button>');
        self.$j(d).click(function () { self.printGraph(e); });
        self.$j(dropDown).find('.inner_dropdown').append(d);
      });

      return [print, dropDown, close];
    };


    var div = self.$j('<div id="customReportCell_' + id + '" class="dynGraph box box-default box-solid"></div>');
    var header = self.$j('<div class="card-body"></div>');
    var row = self.$j('<div class="align-items-center"></div>');
    var subdiv = self.$j('<div></div>');
    var titleEl = self.$j('<h4 class="d-inline card-title">' + title + '</h4>');
    var rightBox = self.$j('<div class="d-inline float-right"></div>');
    var rightBox2 = self.$j('<div class="d-inline float-right"></div>');
    var graphPanel = self.$j('<div class="box-tools" style="display: inline;"></div>');
    var body = self.$j('<div style="max-height: 80%;"><canvas id="' + id + '" class="card-report graphComp customReportCell" style="height: 100px;"><center>Sem dados para exibir</center></canvas></div>');

    self.$j(rightBox).append(downloadGraph( /*"btn_download_" +*/ id));
    self.$j(graphPanel).append(graphPanelBtn( /*"btn_combo_" +*/ id));
    self.$j(rightBox2).append([graphPanel]);
    self.$j(subdiv).append([titleEl, rightBox, rightBox2]);
    self.$j(row).append([subdiv]);
    self.$j(header).append(row);
    self.$j(div).append([header, body]);

    return div;
  };

  clean_canvas = function (div, content, isSmartUpdate) {
    var self = this;
    if (!isSmartUpdate) {
      var canvas = self.$j('#' + div)[0];
      if (canvas.tagName == 'CANVAS') {
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
      }
      //var idiv = self.$j('<div id="' + div + '" class="card-report" style="height: 100px">' + content + '</div>');
      self.$j('#' + div).replaceWith('<div id="' + div + '" class="card-report graphComp customReportCell" style="height: 100px">' + content + '</div>');
    }
  };

  get_color_graphs = function (type) {
    var self = this;
    //32 cores básicas do google
    var color_base = ["rgba(51,102,204,1)", "rgba(220,57,18,1)", "rgba(255,153,0,1)", "rgba(16,150,24,1)",
      "rgba(153,0,153,1)", "rgba(0,153,198,1)", "rgba(221,68,119,1)", "rgba(102,170,0,1)",
      "rgba(184,46,46,1)", "rgba(49,99,149,1)", "rgba(51,102,204,1)", "rgba(153,68,153,1)",
      "rgba(34,170,153,1)", "rgba(170,170,17,1)", "rgba(102,51,204,1)", "rgba(230,115,0,1)",
      "rgba(139,7,7,1)", "rgba(101,16,103,1)", "rgba(50,146,98,1)", "rgba(85,116,166,1)",
      "rgba(59,62,172,1)", "rgba(183,115,34,1)", "rgba(22,214,32,1)", "rgba(185,19,131,1)",
      "rgba(244,53,158,1)", "rgba(156,89,53,1)", "rgba(169,196,19,1)", "rgba(42,119,141,1)",
      "rgba(102,141,28,1)", "rgba(190,164,19,1)", "rgba(12,89,34,1)", "rgba(116,52,17,1)"];
    var a7 = color_base.map(function (e) { return e.replace('1)', '0.7)'); });
    var a5 = color_base.map(function (e) { return e.replace('1)', '0.5)'); });
    var a3 = color_base.map(function (e) { return e.replace('1)', '0.3)'); });

    if (type == 'boxplot')
      return a3.concat(color_base).concat(a7).concat(a5);
    else
      return color_base.concat(a7).concat(a5).concat(a3);
  };

  printGraph = function (format) {
    var self = this;
    var inputElement = document.createElement("input");
    inputElement.type = "hidden";
    inputElement.name = "graphReport";
    inputElement.value = self.curr_graph.canvas.toDataURL().replace('data:image/png;base64,', '');

    var csrfElement = document.createElement("input");
    csrfElement.type = "hidden";
    csrfElement.name = "csrfmiddlewaretoken";
    csrfElement.value = self.global.getCookie('csrftoken');

    var formatEl = document.createElement("input");
    formatEl.type = "hidden";
    formatEl.name = "format";
    formatEl.value = format;

    var formElement = document.createElement("form");
    formElement.id = 'graphReportID';
    formElement.name = 'graphReportID';
    formElement.action = self.caller.baseURL + 'get_file';
    //formElement.target = "_blank";
    formElement.method = 'post';
    formElement.appendChild(formatEl);
    formElement.appendChild(inputElement);
    formElement.appendChild(csrfElement);
    self.$j(formElement).appendTo('body').submit().remove();
  };

  download = function (div) {
    var self = this;
    let isDiv = (div != null);
    var lines;

    if (isDiv)
      lines = self.$j(div).find('tr').map(function (e, i) {//$.makeArray($(div).find('tr').map(function (i, e) {
        var columns = self.$j(e).find('td, th').map(function (el, j) { //$.makeArray($(this).find('td, th').map(function (j, el) {
          return self.$j(el).text();
        });
        return columns.join(';');
      });
    else
      lines = [...[self.saved.colNames.join(';')], ...(self.saved.data.map(x => x.join(';')))];



    var inputElement = document.createElement("input");
    inputElement.type = "hidden";
    inputElement.name = "csvReport";
    inputElement.value = lines.join('\n');

    var csrfElement = document.createElement("input");
    csrfElement.type = "hidden";
    csrfElement.name = "csrfmiddlewaretoken";
    csrfElement.value = self.global.getCookie('csrftoken');

    var formElement = document.createElement("form");
    formElement.id = 'csvReportID';
    formElement.name = 'csvReportID';
    formElement.action = self.caller.baseURL + 'get_file';
    //formElement.target = "_blank";
    formElement.method = 'post';
    formElement.appendChild(inputElement);
    formElement.appendChild(csrfElement);
    self.$j(formElement).appendTo('body').submit().remove();

  };

  refreshGraph = function (format, callback) {
    var self = this;
    var newType;
    if (typeof format != 'string')
      newType = self.$j(format).find('select').map(function (e, i) {
        return self.$j(e).val();
      }).join(',');
    else if (format == 'origin')
      newType = self.saved.type;
    else
      newType = format;

    //Limpando upper e bottom cells
    var id = self.saved.div.replace('customReportCell_', '');
    self.$j('.customReportUpperAuxCell_' + id + ', .customReportAuxCell_' + id).forEach(function (e, indexManager) {
      self.$j(e).find('select, input').map(x => {
        self.$j(x).unbind()
      } );
      self.$j(e).html('');
    });

    if (newType == 'radar' || newType == 'doughnut' || newType == 'pie') {
      var config = Object.assign({}, self.saved.config); //eval("("+saved.config+")");
      if (config.titleX) delete config.titleX;
      if (config.titleY) delete config.titleY;
      if (config.stacked) delete config.stacked;
      if (config.bands) delete config.bands;
      config.ignore_default = true;

      var newOpt = {};
      self.nestedAttribute(newOpt, "options.tooltips", { mode: 'index', position: 'nearest' })
      self.nestedAttribute(newOpt, "options.tooltips.callbacks.label", function (tooltipItem, data) {
        return data.labels[tooltipItem.index] +
          (data.datasets.length > 0 ? ' ' + data.datasets[tooltipItem.datasetIndex].label : '') +
          ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      });

      var newConfig = config;
      self.initializeGraph(self.saved.colNames, self.saved.data, newOpt, newType, self.saved.div, newConfig, self.saved.interval, false);
    }
    else if (newType == 'table')
      self.to_table(self.saved.colNames, self.saved.data, self.saved.div, null, null, self.saved.config, self.saved.interval, false);
    else
      self.initializeGraph(self.saved.colNames, self.saved.data, self.saved.opt, newType, self.saved.div, self.saved.config, self.saved.interval, false);


    const navigateButtonsPosition = document.getElementById('date-interval');
    if (navigateButtonsPosition != null) {
      let navigateButtonPositionInScreen = navigateButtonsPosition.offsetLeft + navigateButtonsPosition.offsetWidth;
      let fixedButon = document.getElementById('navigateButtons')
      fixedButon.style.left = `${navigateButtonPositionInScreen}` + 'px';
    }

    if (callback)
      callback();
  };

  graph_factory = function () {
    var self = this;
    return new DataEngineManager(self.caller);
  };
  // #endregion

  // #region Árvores
  initializeTree = function (names, data, div, config, isOriginal) {
    var self = this;
    if (isOriginal)
      self.saved = { 'colNames': names, 'data': data, 'div': div, 'config': config };

    if (config.regularTranspose) {
      var result = self.transposeMatrix(names, data);
      names = result.colNames;
      data = result.data;
    }

    var rootRef = null;
    var treeMap = {};
    var format = config.formatter ? self.get_formatter(config, config.formatter.split(',').length, true) : null;
    data.forEach(x => {
      treeMap[x[0]] = {
        name: x[1],
        description: x[2],
        childrean: [],
        info: '',
        value: format && x[4] ?  format[0](x[4]) : x[4]
      };
    });
    data.forEach(x => {
      if (x[3] != null && treeMap[x[3]])
        treeMap[x[3]].childrean.push(x[0]);
      else if (x[3] == null || (x[3] != null && !treeMap[x[3]]))
        rootRef = x[0];
    });

    var createTree = function (curNode, level) {
      var hasDesc = curNode.description != null && curNode.description != '';
      var hasVal = curNode.value != null && curNode.value != '';
      var hasInfo = curNode.info != null && curNode.info != '';
      var node = self.$j(
        `<li id="{guid}" expanded="true">
          <div title="{title}">
            <span class="spanMainContent">
              <h5 class="h5-tree">
                {name}
                <span class="circle-expanded-content {visibleExpandContent}">
                  <i class="mdi mdi-plus-circle-outline"></i>
                </span>
              </h5>

            </span>
          </div>
        </li>`
          .replace('{guid}', self.global.createGuid())
          .replace('{title}', curNode.description ?? '')
          .replace('{name}', curNode.name)
          .replace('{visibleExpandContent}', hasDesc || hasVal || hasInfo ? '' : 'is-hidden')
      );

      node.find('.spanMainContent').append( self.$j('<p class="is-hidden is-show-tree-description">' + ( hasDesc ? curNode.description : '') + '</p>') );
      node.find('.spanMainContent').append( self.$j('<p class="is-hidden is-show-tree-value">' + ( hasVal ? curNode.value : '' ) + '</p>') );
      node.find('.spanMainContent').append( self.$j('<sub>' + ( hasInfo ? curNode.info : '') + '</sub>') );
      node.find('.spanMainContent').append( self.$j('<p class="is-hidden badge badge-info">' + (curNode.childrean != null && curNode.childrean.length > 0 ? curNode.childrean.length : '') + '</p>') );

      if (curNode.childrean != null && curNode.childrean.length > 0) {
        var ul = self.$j('<ul class="level_' + level + '"></ul>');
        node.append(ul);
        curNode.childrean.forEach(x => ul.append(createTree(treeMap[x], level + 1)));
      }

      self.$j(self.$j(node).find('span.circle-expanded-content')[0]).click(event => {
        if( self.$j(event['currentTarget']).find('i').attr('class') == 'mdi mdi-plus-circle-outline' )
          self.$j(event['currentTarget']).find('i').attr('class', 'mdi mdi-minus-circle-outline')
        else
          self.$j(event['currentTarget']).find('i').attr('class', 'mdi mdi-plus-circle-outline')

        let pShowContent = event['currentTarget']['parentNode']['parentNode'].querySelectorAll('p');
        pShowContent.forEach(function (el, index, array) {
          if (el.classList.contains('badge') == false) {
            if (el.classList.contains('is-hidden'))
              el.classList.remove('is-hidden');
            else if (el.classList.contains('is-hidden') == false)
              el.classList.add('is-hidden');
          }
        });
        event.stopPropagation();
      });

      self.$j(self.$j(node).find('div')[0]).click(function (innerEvent) {
        var curUl = self.$j(self.$j(this).parent().find('ul')[0]);
        var badge = self.$j( self.$j(this).find('.badge')[0] );
        if (self.$j(this).parent().attr('expanded') == 'true') {
          curUl.hide();
          badge.removeClass('is-hidden');
          self.$j(this).parent().attr('expanded', 'false');
        }
        else {
          curUl.show();
          badge.addClass('is-hidden');
          self.$j(this).parent().attr('expanded', 'true');
        }

        innerEvent.stopPropagation();
      });
      curNode.level = level;
      return node;
    };

    var tree = self.$j('<ul class="tree"></ul>');
    tree.append(createTree(treeMap[rootRef], 1));
    self.$j('#' + div).empty();
    self.$j('#' + div).html(tree);

    // Se verdadeiro expande todos os nós da árvore
    if ( config.expanded ) {
      tree.find('li[expanded="false"]').forEach(x => {
        self.$j(self.$j(x).find('div')[0]).trigger('click');
      });
    }

    // Se verdadeiro todos os nós começam com detalhes visiveis
    if ( config.expandedContent ) {
      tree.find('.mdi-plus-circle-outline').forEach(x => {
        self.$j(self.$j(x).parent()[0]).trigger('click');
      });
    }
  };
  // #endregion

  // #region Storyboard
  initializeStoryboard = function (names, data, div, config, isOriginal) {
    var self = this;
    if (isOriginal)
      self.saved = { 'colNames': names, 'data': data, 'div': div, 'config': config };

    if (config.regularTranspose) {
      var result = self.transposeMatrix(names, data);
      names = result.colNames;
      data = result.data;
    }

    var curLevel = [];
    var levels = Math.max(...data.map(x => x[1].length));
    var storyboard_change = function (content) {
      self.$j('#' + div).empty();
      self.$j('#' + div).html(content);
    };
    var showLevel = function (depth) {
      var titles = [...new Set(data.map(x => x[1][depth][0]))].sort();
      if (depth != 0) { titles.push('<<< Voltar'); }
      var bts = titles.map(x => {
        var curr_button = self.$j('<button class="btn btn-lg waves-effect waves-light btn-rounded btn-outline-primary">' + x + '</button>');
        self.$j(curr_button).click(function () {
          if (x != '<<< Voltar')
            curLevel.push(x);
          else
            curLevel.pop();

          if (curLevel.length < levels)
            storyboard_change(showLevel(curLevel.length));
          else
            showSlides(curLevel, 0);
        });
        return curr_button;
      });
      var div = self.$j('<div></div>');
      self.$j(div).append(bts);
      return div;
    };
    var actionSlide = function (content, comands) {
      var cmdList = comands.split(';');
      var divSlide = self.$j('<div style="display: inline-flex"></div>');
      var valid_regex = /[^A-Za-z0-9 ]/g;
      for (var cmd in cmdList) {
        if (cmdList.hasOwnProperty(cmd)) {
          if (cmdList[cmd] == 'listWithSounds') {
            var words = content.map(x => {
              var subtitle = '';
              if( x[0].indexOf('__') != -1) {
                var iList = x[0].split(' - ')[1].split('__')[1].split('_break_').map( (o, i) => '<ul index="' + i +'" selected="' + (i == 0 ? 'true' : 'false') + '" style= "display:' + (i == 0 ? '' : 'none') +'">' + o.split('|').map(y => '<li>' + y + '</li>').join('\n') + '</ul>' );
                var btns = iList.length > 1 ? '<button class="btn btn-default btn-circle btnpageslide" ><i class="mdi mdi-arrow-right"></i></button>' : '';
                subtitle += iList.join('\n') + btns;
              }
              var word = self.$j('<div style="padding: 10px 10px 20px 10px;text-align:center"><h5>' + x[0].split('__')[0] + '</h5>' +
                (x.length > 1 ? '<img width="250" height="250" style="" src="' + x[1] + '" />' : '') +
                subtitle +
                '</div>'
              );
              word.find('.btnpageslide').click(function() {
                var uls = self.$j(this).parent().find('ul');
                var index = parseInt(uls.filter(x => self.$j(x).attr('selected') == 'true').attr('index'));
                var next = ( index + 1) % ( uls.length );
                self.$j(uls[ index ]).attr('selected', 'false');
                self.$j(uls[ index ]).attr('style', 'display:none');

                self.$j(uls[ next ]).attr('selected', 'true');
                self.$j(uls[ next ]).attr('style', 'display:');

              });
              if (self.hasTTS)
                word.find('img').click(function () {
                  self.speech.init({ volume: 2, lang: self.recognition.lang, rate: 1, pitch: 1, splitSentences: true });
                  self.speech.speak({ text: x[0].split(' - ')[0], queue: false });
                });
              return word;
            });
            var totalDiv = self.$j('<div class="row"></div>');
            totalDiv.append(words);
            divSlide.append(totalDiv);
          }
          else if (cmdList[cmd] == 'memorize') {
            var words = content.filter(x => x.length > 1 && x[1] != '').map(x => {
              var word = self.$j('<div style="padding: 10px 10px 20px 10px;text-align:center">' +
                (x.length > 1 ? '<img width="250" height="250" style="" src="' + x[1] + '" />' : '') +
                '<input type="text" class="form-control"  />' +
                '<div class="valid-feedback">Sucesso!</div><div class="invalid-feedback">Falha!</div>' +
                '</div>'
              );
              word.keyup(function () {
                word.find('input[type="text"]').attr('class', 'form-control ' + (word.find('input').val().trim().toLowerCase().replace(valid_regex, "") == x[0].split(' - ')[0].trim().toLowerCase().replace(valid_regex, "") ? 'is-valid' : 'is-invalid'));
              });
              return word;
            });
            var totalDiv = self.$j('<div class="row"></div>');
            totalDiv.append(words);
            divSlide.append(totalDiv);
          }
          else if (cmdList[cmd] == 'listening') {
            var newContent = content.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
            var words = newContent.map(x => {
              var word = self.$j(
                '<form style="padding-right: 10px;text-align:center;display: inline-flex" class="col-12">' +
                  '<div class="input-group mb-3">' +
                    '<div class="input-group-prepend">' +
                      '<button type="button" class="btn btn-success say_now" secret="' + x[0] + '" ><i class="fa fa-play"></i></button>' +
                    '</div>' +
                    '<input type="text" class="form-control" />' +
                  '</div>' +
                  '<button type="button" class="btn btn-circle give_answer" ><i class="fa fa-question"></i></button>' +
                  '<div class="answer" style="display: none">' + x[0] + '</div>' +
                '</form>'
              );

              word.find('button.give_answer').click(function () {
                word.find('.answer').toggle();
              });

              if (self.hasTTS)
                word.find('button.say_now').click(function () {
                  self.speech.init({ volume: 2, lang: self.recognition.lang, rate: 1, pitch: 1, splitSentences: true });
                  self.speech.speak({ text: x[0], queue: false });
                });

              word.find('input[type="text"]').keyup(function () {
                word.find('input[type="text"]').attr('class', 'form-control ' + (  word.find('input[type="text"]').val().trim().toLowerCase().replace(valid_regex, "") == x[0].trim().toLowerCase().replace(valid_regex, "") ? 'is-valid' : 'is-invalid'));
              });
              return word;
            });
            var totalDiv = self.$j('<div></div>');
            totalDiv.append(words);
            divSlide.append(totalDiv);
          }
          else if (cmdList[cmd] == 'pronunciation') {
            var wordThis = null;
            var words = content.map(x => {
              var id = self.global.createGuid();
              var word = self.$j('<form id="' + id + '" style="padding-right: 10px">' +
                '<label class="form-control-label">' + x[0] + '</label>' +
                '<input type="text" class="form-control" disabled />' +
                '<div class="valid-feedback">Sucesso!</div><div class="invalid-feedback">Falha!</div>' +
                '</form>'
              );
              word.click(function () {
                wordThis = self.$j(this);
                self.recognition.start();
              });
              return word;
            });
            self.recognition.addEventListener('end', (condition) => {
              self.recognition.stop();
              wordThis.find('input[type="text"]').val(self.tempWords.trim());
              wordThis.find('input[type="text"]').attr('class', 'form-control ' + (self.tempWords.trim().toLowerCase().replace(valid_regex, "") == wordThis.find('label').text().trim().toLowerCase().replace(valid_regex, "") ? 'is-valid' : 'is-invalid'));
            });
            divSlide.css('display', '');
            divSlide.append(words);
          }
          else if (cmdList[cmd] == 'transcript') {
            var words = content.map(x => {
              var id = self.global.createGuid();
              var word = self.$j('<form id="' + id + '" style="padding-right: 10px;text-align:center">' +
                '<label class="form-control-label">' + x[0] + '</label>' +
                '<input type="text" class="form-control"  />' +
                '<div class="valid-feedback">Sucesso!</div><div class="invalid-feedback">Falha!</div>' +
                '</form>'
              );
              word.keyup(function () {
                word.find('input[type="text"]').attr('class', 'form-control ' + (word.find('input').val().trim().toLowerCase().replace(valid_regex, "") == x[1].trim().toLowerCase().replace(valid_regex, "") ? 'is-valid' : 'is-invalid'));
              });
              return word;
            });
            divSlide.css('display', '');
            divSlide.append(words);
          }
          else
            divSlide.append(self.$j('<div>' + content.map(x => x.join('')).join('') + '</div>'));
        }
      }
      return divSlide
    };
    var showSlides = function (hierarchy, index) {
      var slides = data.filter(x => x[1].every((y, i) => y == hierarchy[i])).sort((x, y) => x[3] - y[3]);
      var content = actionSlide(slides[index][0], slides[index][2]);

      var prevBtn = self.$j('<button class="btn btn-lg waves-effect waves-light btn-rounded btn-outline-primary">←</button>').click(function () { showSlides(hierarchy, --index); });
      var homeBtn = self.$j('<button class="btn btn-lg waves-effect waves-light btn-rounded btn-outline-primary">🏠</button>').click(function () { curLevel.pop(); storyboard_change(showLevel(curLevel.length)); });
      var nextBtn = self.$j('<button class="btn btn-lg waves-effect waves-light btn-rounded btn-outline-primary">→</button>').click(function () { showSlides(hierarchy, ++index); });
      var buttonDiv = self.$j('<div class="text-center"></div>');
      self.$j(buttonDiv).append(slides.length == 1 ? [homeBtn] : (index == 0 ? [homeBtn, nextBtn] : (index == slides.length - 1 ? [prevBtn, homeBtn] : [prevBtn, homeBtn, nextBtn])));

      var panels = self.$j('<div></div>');
      panels.append([buttonDiv, content])
      storyboard_change(panels);
    };
    storyboard_change(showLevel(curLevel.length));
  };
  // #endregion

  // #region Correlação
  initializeCorrelation = function (names, data, div, config, isOriginal) {
    var self = this;
    if (isOriginal)
      self.saved = { 'colNames': names, 'data': data, 'div': div, 'config': config };

    if (config.regularTranspose) {
      var result = self.transposeMatrix(names, data);
      names = result.colNames;
      data = result.data;
    }

    var id = div.replace('customReportCell_', '');
    var format = self.get_formatter(config.formatter, 1, true);

    var getRBGComponent = function(colRange, minCol, valRange, minVal, val) {
      return Math.round(((val-minVal)/valRange)*colRange+minCol)
        .toString(16)
        .toUpperCase()
        .padStart(2,'0');
    };

    var assignColor = function(minCol, maxCol, minVal, maxVal, vals, numVals) {
      var colors = [];
      var minR = parseInt(minCol.substring(1,3),16);
      var maxR = parseInt(maxCol.substring(1,3),16);
      var minG = parseInt(minCol.substring(3,5),16);
      var maxG = parseInt(maxCol.substring(3,5),16);
      var minB = parseInt(minCol.substring(5,7),16);
      var maxB = parseInt(maxCol.substring(5,7),16);
      var valsRange = maxVal - minVal;
      var rangeG = maxG - minG;
      var rangeR = maxR - minR;
      var rangeB = maxB - minB;

      for(var i = 0; i < numVals; i++) {
       colors[i] = '#'
           + getRBGComponent(rangeR,minR,valsRange,minVal,vals[i])
           + getRBGComponent(rangeG,minG,valsRange,minVal,vals[i])
           + getRBGComponent(rangeB,minB,valsRange,minVal,vals[i]);
      }
      return colors;
    };

    var valid_cols = names.slice(1).filter( x => data[x]  );
    var ths = [''].concat(valid_cols).map(function (e, i) {
      var ix = i == 0 ? ' class="sticky-col first-col" style="z-index: 49" ' : '';
      return '<th colindex="' + i + '" ' + ix +' >' + e + '<span class="sort-asc fas">' + '</span>' + '</th>';
    });
    var lines = valid_cols.map(function (l, index) {
      var values = valid_cols.map( (c,i) =>  data[ l ][ c ] );
      var colors = values.map(x => x > 0 ? assignColor('#D9D9D9','#C00000', 0 , 1, [x], 1) : assignColor('#0D0D0D','#D9D9D9', -1 , 0, [x], 1) );
      var tds = ['<td role="row" class="sticky-col first-col" >' + l + '</td>' ].concat(
          valid_cols.map(function (c, i) {
            return '<td role="row" style="background-color:' + colors[i] +'" >' + ( format[0] ? format[0]( data[ l ][ c ] ) : data[ l ][ c ] ) + '</td>';
          })
      );
      return '<tr>' + tds.join(' ') + '</tr>';
    });
    var arrayLines = lines;

    if (arrayLines.length == 0)
      arrayLines.push('<tr><td colspan="3" ><center>Sem dados para exibir</center></td></tr>');

    self.$j('#downloadTable_' + id).unbind('click');
    self.$j('#downloadTable_' + id).click(function () {
      self.download('#customReportCell_' + id);
    });

    var hasHeader = !config.hasOwnProperty('withoutHeader') || ( config.hasOwnProperty('withoutHeader') && !config.withoutHeader );
    var hasBorder = !config.hasOwnProperty('withoutBorder') || ( config.hasOwnProperty('withoutBorder') && !config.withoutBorder );
    var hasStriped = config.hasOwnProperty('withStriped') && config.withStriped;

    var table_opts = [ ( hasBorder ? "table-bordered" : "table-borderless" ), ( hasStriped ? "table-striped" : "" ) ]
    var table = "<table class='table " + table_opts.join(" ") + " table-hover dataTable' role='grid' width='100%'>" +
      ( hasHeader ? "<thead>" + ths.join('\n') + "</thead>" : "" ) +
      "<tbody>" + arrayLines.join('\n') + "</tbody></table>";
    self.$j('#' + div).html(table);

    self.curr = { 'colNames': names, 'data': data, 'opt': {}, 'type': 'table', 'div': div, 'config': config, 'interval': '' };

    if (hasHeader)
      self.fixedHeaderTable(id);
  };
  // #endregion

  // #region Text
  initializeText = function (names, data, div, config) {
    var self = this;
    var format = self.get_formatter(config, names.length, true);
    var ref_div = div.startsWith('customReportCell_') ? self.$j('#' + div) : self.$j('#fieldset_' + div);

    if (config.templateText)
      ref_div.html(config.templateText);

    names.forEach(function (e, i) {
      var classDestination = ref_div.find('.' + names[i]);
      if (data.length > 0 && classDestination.length > 0)
        classDestination.html(format[i](data[0][i]));
      else if (data.length > 0)
        ref_div.html(format[i](data[0][i]));
    });
  };
  // #endregion

  // #region Map
  initializeMap = function(names, data, div, config) {
    var self = this;
    var filtered_data = data.filter(x => x[0] != null && x[1] != null );

    //Limpa o Mapa
    //Object.keys(self.markerLayers).forEach( key => { self.markerLayers[key].clearLayers(); delete self.markerLayers[key]; });

    var lightUrl = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
    var terrainUrl = 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png';
    var streetUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFuaWxvY29nbmkiLCJhIjoiY2s0MGFuZnJ2MDFtYTNsbnNyZDUyZHg2cCJ9.h-3Etec5gSWxMCDkKWNDgw';
    var sateliteUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

    var mapType = config.mapType ? config.mapType : 2;
    var latlngCenter = filtered_data.length > 0 ? L.latLng(filtered_data[0][0], filtered_data[0][1]) : L.latLng(-16.6799, -49.255);
    var streets = L.tileLayer(streetUrl, { id: 'mapbox/streets-v11', maxZoom: 17, maxNativeZoom: 17 });
    var Esri_WorldImagery = L.tileLayer(sateliteUrl, { id: 'satelite', maxZoom: 17, maxNativeZoom: 17 });
    var light = L.tileLayer(lightUrl, {  maxZoom: 17, maxNativeZoom: 17 });
    var terrain = L.tileLayer(terrainUrl, {  maxZoom: 17, maxNativeZoom: 17 });

    var baseMaps = { "Satélite": Esri_WorldImagery, "Ruas": streets, 'Claro':light, 'Terrenos': terrain  };

    var optionOverlay = {};
    var groupedOverlays = { "Marcadores": self.markerLayers };
    var clusterGroup = L.markerClusterGroup();
    var colors = self.get_color_graphs();

    groupedOverlays['Marcadores'] = mapType == 2 ? { 'Todos': L.layerGroup() } : Object.assign({}, ...filtered_data.map((x) => ({[ x[2] ]: L.layerGroup() })));

    var markerColors = Object.assign({}, ...Object.keys( groupedOverlays['Marcadores'] ).map((y, i) => ({[y]: colors[i]})))
    for(var i = 0; i < filtered_data.length ; i++) {
      var popup = self.popupContent(names.slice(2), filtered_data[i].slice(2), config);
      L.circleMarker([ filtered_data[i][0], filtered_data[i][1] ], {
        color: mapType == 2 ? colors[0] : markerColors[filtered_data[i][2]]
      }).bindPopup( popup['popup'] ).addTo(groupedOverlays['Marcadores'][ mapType == 2 ? 'Todos' : filtered_data[i][2] ]);
    }
    if (config.isClusterMarket)
      Object.keys(groupedOverlays['Marcadores']).forEach(x => groupedOverlays['Marcadores'][x] = L.featureGroup.subGroup(clusterGroup, [groupedOverlays['Marcadores'][x]] ) );

    /*Legend specific*/
    var legend = (L as any).control({ position: "bottomleft" });
    legend.onAdd = function(map) {
      var div = L.DomUtil.create("div", "legendMap");
      var legendItems = Object.keys( markerColors ).map(x => '<i style="background: ' + markerColors[x] + '"></i><span>' + x + '</span>');
      div.innerHTML += "<h4>Legenda</h4>";

      div.innerHTML += legendItems.join('<br/>');

      return div;
    };

    if (config.heatmap) {
      groupedOverlays["Mapa de Calor"] = {};
      optionOverlay['exclusiveGroups'] = ["Mapa de Calor"];
      //groupedOverlays["Mapa de Calor"]["Nenhum"] = new HeatmapOverlay({ "radius": 1, "maxOpacity": .8, latField: 'lat', lngField: 'lng', valueField: 'count' });
      groupedOverlays["Mapa de Calor"]["Nenhum"] = L.layerGroup();

      var curr_formatter = config.formatter.split(',');
      names.slice(2).forEach( ( n, c ) => {
        if (curr_formatter.length > c && curr_formatter[c].trim() == '')
          return;

        var heatData = [];
        /*filtered_data.forEach( (x, i) => { heatData.push({lat: filtered_data[i][0], lng: filtered_data[i][1], count: filtered_data[i][ c + mapType ] }); });
        const heatLayerConfig = { radius: null, maxOpacity: .8, scaleRadius: true, useLocalExtrema: true, latField: 'lat', lngField: 'lng', valueField: 'count' };
        const heatmapLayer = new HeatmapOverlay(heatLayerConfig);
        heatmapLayer.setData({ max: Math.max( ...heatData.map(x => x.count) ), data: heatData });
        groupedOverlays["Mapa de Calor"][n] = heatmapLayer;*/

        filtered_data.forEach( (x, i) => { heatData.push([ filtered_data[i][0], filtered_data[i][1], filtered_data[i][ c + mapType ] ]); });
        var heat = (L as any).heatLayer(heatData, {radius: 25});
        groupedOverlays["Mapa de Calor"][n] = heat;

      });
    }

    if (self.globalMap) {
      self.globalMap.off();
      self.globalMap.remove();
    }

    if ( document.querySelector('#' + div).tagName == 'CANVAS' )
      self.$j('#' + div).replaceWith('<div id="' + div + '" name="' + div + '" class="map-height" ></div>');

    self.globalMap = L.map(div, {
      preferCanvas: true,
      attributionControl: false,
      center: latlngCenter,
      zoom: 10,
      layers: [ baseMaps['Satélite'], baseMaps['Ruas'] ]
    });

    if (config.isClusterMarket)
      self.globalMap.addLayer(clusterGroup);

    L.control.groupedLayers(baseMaps, groupedOverlays, optionOverlay).addTo(self.globalMap);
    for (var x in groupedOverlays['Marcadores'])
      groupedOverlays['Marcadores'][x].addTo(self.globalMap);

    if (config.heatmap)
      groupedOverlays["Mapa de Calor"]["Nenhum"].addTo(self.globalMap);

    legend.addTo(self.globalMap);
  };
  // #endregion

  // #region Supervisory
  initializeSupervisory = function(body: any, header: any, grid: GridStack, target: any, componentFactoryResolver: ComponentFactoryResolver, appRef: ApplicationRef, injector:Injector, fileAssets: any[]) {
    var self = this
    const dataParsed = JSON.parse(body)


    const { data, cols, graphConfig} = dataParsed;
    const [_, id] = target.split('_')

    if(!id) {
      return
    }

    // const mockCols = ['ignora essa parada', 'eqp 1', 'eqp2', 'eqp3']
    // const mockData = [
    //   ['0-0.3-GAQ2 - Gerador de água quente 1-1-#E83E8C', 49.588222949032435],
    //   ['0-0.2-GAQ2 - Gerador de água quente 2-1-#5925c2', null, 49.588222949032435],
    //   ['0-0.2-GAQ2 - Gerador de água quente 1-1-#E83E8C', null, null, 49.588222949032435],
    //   ['0-0.2-GAQ2 - Gerador de água quente 1-2-#E83E8C', null, null, 49.588222949032435],
    //   ['0-0.2-GAQ2 - Gerador de água quente 1-2-#E83E8C', null, 49.588222949032435],
    // ]

    const divTarget = document.getElementById(`fieldset_${id}`)

    const fileIds = dataParsed.config.file_ids
    const maps = fileAssets.filter((el) => fileIds.includes(el.graph_image_id))

    const factory = componentFactoryResolver.resolveComponentFactory(ThreeRenderComponent)
    const componentRef = factory.create(injector)
    if(id) {
      componentRef.instance.ancor = `fieldset_${id}`;
    }
    componentRef.instance.grid = grid
    componentRef.instance.title = header.nome_rbd

    maps.forEach((map) => {
      let items = []

      data.map((el) => {
        const [info, value] = el
        let splitedData = []

        if(typeof info === 'string') {
          splitedData = info.split('-')
        }

        if(splitedData[4] == map.graph_image_id) {
          const valueExists =  items.filter((el) => el.sensor == `${splitedData[2]} - ${splitedData[3]}`)

          let values = el.map((el, index) => {
            if(index != 0 && el && cols[index]) {
              return `${cols[index]} - ${Number(el).toFixed(2)}`
            }
          })

          values = values.filter(v => v)

          if(valueExists.length == 0) {
            items.push({ sensor: `${splitedData[2]} - ${splitedData[3]}`, values: values, x: Number(splitedData[0]), y: Number(splitedData[1]), z: 0.5, color: splitedData[5].replace('#', '') })
          } else {
            valueExists[0].values.push(values.filter((v) => v))
          }
        }
      })

      componentRef.instance.levels.push({
        id: map.graph_image_id,
        img: map.content,
        bg: map.content,
        items: items.filter((el) => el)
      })
    })

    appRef.attachView(componentRef.hostView)
    const nativeElement = componentRef.location.nativeElement

    const card = document.createElement('div')
    card.classList.add(`supervisory_${id}`)
    card.appendChild(nativeElement)


    const elExists = document.getElementsByClassName(`supervisory_${id}`)
    if(elExists.length == 0) {
      const elToReplace = divTarget.firstChild.childNodes[8]

      divTarget.firstChild.removeChild(elToReplace)
      divTarget.firstChild.appendChild(card)

      componentRef.instance.onInitScene();
      componentRef.instance.onMountMap(componentRef.instance.levels[0])
      componentRef.instance.renderScene();
    }
  };

  onRemoveComas(str: string) {
    if (str.startsWith(',')) {
      str = str.substring(1);
    }

    if (str.endsWith(',')) {
      str = str.slice(0, -1);
    }
   return str;
  }

  popupContent = function(names, data, config){
    var self = this;
    var format = self.get_formatter(config, names.length, true);
    var popup = '<table class="table-borderless" ><tbody>'+
      data.map(function(e,i) {
        if(!data[i] || data[i] == '')
          return null;

        return '<tr>'+
          '<td><b>' + names[i] + ': </b></td> <td>'+ format[i](data[i])  +'</td>'+
        '</tr>';
      }).join('\n')
    '</tbody></table>';

    return {
      'popup': popup,
      //'delay': isDelay == true ? true:false,
      //'noData':  value_active <= 4 ? true:false
    };
  };
  // #endregion

  // #region Tabelas
  to_table = function (names, obj, div, rowspan, colspan, config, interval, isOriginal) {
    var self = this;
    if (isOriginal)
      self.saved = { 'colNames': names, 'data': obj, 'opt': {}, 'type': 'table', 'div': div, 'config': config, 'interval': interval, 'rowspan': rowspan, 'colspan': colspan };

    if (config.regularTranspose) {
      var result = self.transposeMatrix(names, obj);
      names = result.colNames;
      obj = result.data;
    }

    var id = div.replace('customReportCell_', '');
    var format = self.get_formatter(config, names.length, true);
    self.final_conf = { ...config };

    // Configurações gerais de tabelas
    var hasHeader = !config.hasOwnProperty('withoutHeader') || ( config.hasOwnProperty('withoutHeader') && !config.withoutHeader );
    var hasBorder = !config.hasOwnProperty('withoutBorder') || ( config.hasOwnProperty('withoutBorder') && !config.withoutBorder );
    var hasStriped = config.hasOwnProperty('withStriped') && config.withStriped;

    if (config.checkboxData) {
      var agg = self.checkboxData(div.replace('customReportCell_', ''), names, obj, false, config);
      names = agg.colNames;
      obj = agg.data;

      var newFormatter = config.formatter.slice(1);
      format = self.get_formatter(newFormatter, names.length, true);
    }

    if (config.pagination) {
      var codi_rbd = div.split('_')[1]
      var TableConfig = {
        header: names,
        rows: obj,
      };
      self.caller.tables[codi_rbd] = TableConfig;
    }
    else {
      var ths = names.map(function (e, i) { return '<th colindex="' + i + '" class="">' + e + '<span class="sort-asc fas">' + '</span>' + '</th>'; });
      var lines = obj.map(function (array, index) {
        var tds = array.map(function (e, i) {
          if ((rowspan != null && rowspan[index][i] == null) || (colspan != null && colspan[index][i] == null))
            return '';

          if (rowspan || colspan) {
            var span = (rowspan != null && rowspan[index][i] != 1 ? " rowspan='" + rowspan[index][i] + "'" : "");
            span += (colspan != null && colspan[index][i] != 1 ? " colspan='" + colspan[index][i] + "'" : "");

            return '<td ' + span + ' role="row">' +
              ( config.f_null_value && e == null ? config.f_null_value : (format[i] ? format[i](e) : e) ) +
            '</td>';
          }
          else
            return '<td role="row">' +
              ( config.f_null_value && e == null ? config.f_null_value : (format[i] ? format[i](e) : e) ) +
            '</td>';
        });
        return '<tr>' + tds.join(' ') + '</tr>';
      });
      var arrayLines = lines;

      if (arrayLines.length == 0)
        arrayLines.push('<tr><td colspan="3" ><center>Sem dados para exibir</center></td></tr>');

      self.$j('#downloadTable_' + id).unbind('click');
      self.$j('#downloadTable_' + id).click(function () {
        self.download('#customReportCell_' + id);
      });

      if ( div != '' && document.querySelector('#' + div).tagName == 'CANVAS' )
        self.$j('#' + div).replaceWith('<div id="' + div + '" name="' + div + '" ></div>');

      var table_opts = [ ( hasBorder ? "table-bordered" : "table-borderless" ), ( hasStriped ? "table-striped" : "" ) ];
      var table = "<table class='table " + table_opts.join(" ") + " table-hover dataTable' role='grid' width='100%'>" +
        ( hasHeader ? "<thead>" + ths.join('\n') + "</thead>" : "" ) +
        "<tbody>" + arrayLines.join('\n') + "</tbody></table>";

      if ( div != undefined && div != null && div != '' )
        self.$j('#' + div).html(table);

      self.curr = { 'colNames': names, 'data': obj, 'opt': {}, 'type': 'table', 'div': div, 'config': config, 'interval': '' };
    }

    if (hasHeader) {
      self.fixedHeaderTable(id);
      self.sortingTable(id, names, obj, div, rowspan, colspan, config, interval);
      self.filterTable(id);
    }

  };

  //Este método é responsável por ordenas as tabelas dos relatórios
  //É adicionado um evento de click no cabeçalho das tabelas dos relatórios que altera a sua ordenção
  //É adicionado um atributo HTML chamado 'colindex' aos cabeçalhos que possuem uma numeração própria
  //Ao clicar no cabeçalho de uma tabela é gerado um novo elemento HTML no cabeçalho da tabela denominado isAsc que representa um booleano, em caso de já ter sido clicado uma vez em um cabeçalho o mesmo adiciona um valor true e ao segundo clique um valor false
  //true representa um valor ascendente e false descendente
  //Na nova tabela criada que não representa o elemento original é feita uma coomparação de valores que os ordena a partir dos parâmetros informados acima
  //Cada modificação na ordenação é relativa ao campo do cabeçalho representado por sua numeração própria
  //Há duas opções de indicação de estilização, um if que caso seja verdadeiro adiciona uma estilização que representa ordem crescente e um else if que representa ordem decrescente
  sortingTable = function (id, names, obj, div, rowspan, colspan, config, interval) {
    var self = this;
    let popUpFilters = `<div class="popup-filter">
      <div class="popup-filter-header">
        <span class="close-popup-filter"><i>×</i></span>
        <h3></h3>
      </div>
      <div class="popup-filter-body">
        <div class="horizontal-center">
          <span>
          <h4 class="horizontal-center">Ordenação de tabela</h4>
            <input class="" type="radio" name="asc" id="asc" value="asc">
            <label for="asc">Ascendente</label>
          </span>
          <span>
            <input class="" type="radio" name="desc" id="desc" value"desc">
            <label for="desc">Decrescente</label>
          </span>
        </div>
        <hr>
        <div>
          <h4 class="horizontal-center">Filtragem de informações</h4>
          <div class="input-group rounded filter-table-div">
            <input type="search" id="unique-table-report" class="form-control rounded filter-table-input"
            placeholder="Filtragem de informações">
            <span class="input-group-text border-0">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
    </div>`;

    if (self.$j("#customReportCell_" + id + " th").length != 0)
      self.$j("#customReportCell_" + id + " th").click(function () {
        let index = self.$j(this).attr('colindex');

        function sorting(sortingOrder) {


          let newObject = obj.map(x => x).sort(function (a, b) {
            const isNullOrUndefined = a[index] == undefined || a[index] == null || b[index] == undefined || b[index] == null;
            var a1 = a[index] == undefined || a[index] == null ? '00' : a[index];
            var a2 = b[index] == undefined || b[index] == null ? '00' : b[index];

            if ((!isNaN(a1) || a1 == '00') && (!isNaN(a2) || a2 == '00'))
              return sortingOrder == true ? a1 - a2 : a2 - a1;
            else
              return sortingOrder == true ? a1.localeCompare(a2) : a2.localeCompare(a1);
          });

          const count = document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").children.length - 1;

          self.to_table(names, newObject, div, rowspan, colspan, config, interval, false);
          document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").setAttribute('isAsc', `${sortingOrder}`);

          for (let j = 0; j <= count; j++) {
            let isSpan = document.querySelector(" th[colindex='" + index + "']").children[j].className.indexOf('sort-asc fas') > -1;
            if (isSpan) {
              let isAscSorting = document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").children[j].parentNode['attributes']['isasc']?.value.indexOf('true');
              document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").children[j].classList.toggle('fa-sort-amount-up', isAscSorting > -1);
              document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").children[j].classList.toggle('fa-sort-amount-down', isAscSorting);
            }
          }
        }
        //Começo do uso do sistema de popup
        let nameHeaderPopuP = document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").textContent;
        let idInputSearch = 'customReportCell_' + id + '_' + index;
        let popUpFilterHeader = `<div class="popup-filter-header">
        <span class="close-popup-filter"><i>×</i></span>
        <h3>${nameHeaderPopuP}</h3>
      </div>
      `;
        let popUpFilterBody = `
        <div class="popup-filter-body">
        <div class="horizontal-center">
          <span>
          <h4 class="horizontal-center">Ordenação de tabela</h4>
            <input class="" type="radio" name="asc-desc" id="asc">
            <label for="asc">Ascendente</label>
          </span>
          <span>
            <input class="" type="radio" name="asc-desc" id="desc">
            <label for="desc">Decrescente</label>
          </span>
        </div>
        <hr>
        <div>
        <h4 class="horizontal-center">Filtragem de informações</h4>
        <div class="input-group rounded filter-table-div">
          <input type="search unique-table-report" id="${idInputSearch}" class="form-control rounded filter-table-input"
            placeholder="Filtragem de informações">
        </div>
      </div>`;
        popUpFilters = `<div id="popup-filter">${popUpFilterHeader}${popUpFilterBody} </div>`;
        let popUp = document.getElementById('popup-filter');
        let isExistPopUp = document.body.contains(popUp);

        if (isExistPopUp) { }
        else {
          let parserStringToHtml = new DOMParser();
          let popUpFiltersHtmlDiv = parserStringToHtml.parseFromString(popUpFilters, 'text/html');
          popUp = popUpFiltersHtmlDiv.getElementById('popup-filter');
          document.querySelector('body').insertBefore(popUp, document.querySelector('main-wrapper'));
          const positionInScreenTop = document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").getBoundingClientRect().top;
          const positionInScreenLeft = document.querySelector("#customReportCell_" + id + " th[colindex='" + index + "']").getBoundingClientRect().left;
          popUp.style.left = `${positionInScreenLeft + window.screenTop}px`;
          popUp.style.top = `${positionInScreenTop + window.scrollY}px`;
        }

        document.querySelector('.close-popup-filter').addEventListener('click', function () { popUp.remove() });
        let positionIndexColumns = names.indexOf(`${nameHeaderPopuP}`);


        let isExistInput = document.body.contains(document.querySelector('#' + idInputSearch));
        function noExistInput() {
          if (isExistInput == false) {

            let createInput = `<input type="search unique-table-report" id="${idInputSearch}" class="form-control rounded filter-table-input"
            placeholder="Filtragem de informações">`
            let parserStringToHtml = new DOMParser();
            document.getElementById
            let divBodyInput = document.querySelector('.popup-filter-body').children[2];
            let createDivBody = document.createElement('div');
            createDivBody.classList.add('input-group', 'rounded', 'filter-table-div');
            divBodyInput.append(createDivBody);
            let popUpFiltersHtmlDiv = parserStringToHtml.parseFromString(createInput, 'text/html');
            divBodyInput.children[1].append(popUpFiltersHtmlDiv.querySelector(`#${idInputSearch}`));
          }
        }

        document.getElementById('asc').addEventListener('change', function () {
          let isAsc = this['checked'] == true;
          if (isAsc) {
            sorting(true);
            noExistInput()
          }
        });
        document.getElementById('desc').addEventListener('change', function () {
          let isDesc = this['checked'] == true;
          if (isDesc) {
            sorting(false);
            noExistInput();
          }
        });

        //Evento de filtragem
        self.$j('#' + idInputSearch).unbind('keyup');
        self.$j('#' + idInputSearch).keyup(function (e) {
          var results = self.saved.data;
          if (e.target['value'].length >= 3) {
            var results = self.saved.data.filter(x => x.filter(y => y.toString().toLowerCase().indexOf(e.target['value'].toLowerCase()) > -1).length >= 1);
          }
          self.to_table(self.saved.colNames, results, self.saved.div, self.saved.rowspan, self.saved.colspan, self.saved.config, self.saved.interval, false);
          let isExistInput = document.body.contains(document.querySelector('#' + idInputSearch));
          if (isExistInput == false) {

            let divBodyInput = document.querySelector('.popup-filter-body').children[2];
            let createDivBody = document.createElement('div');
            createDivBody.classList.add('input-group', 'rounded', 'filter-table-div');
            divBodyInput.append(createDivBody);
            createDivBody.append(e.target);
            let idInput = this.attributes['id'].value;
            document.getElementById(idInput).focus();


          }
        });
      });
  };

  //Este método de código tem a finalidade de fixar o cabeçalho da tabela
  //São selecionados os campos que possuem a classe html fieldset
  //Em um if é verificiado se há um fieldset, que aparece apenas em caso de possuir mais de uma tabela por relatorio, se sim é aplicado em todos uma altura de maneira dinamica a partir de seu tamanho original
  //Caso a condição acima seja falsa, a pagina possui apenas uma tabela e é capturada a altura util da tela do usuario e a tabela passara a ter como altura um valor de 70% do espaço util
  //A definição de altura é obrigatória para que o cabeçalho da tabela fique fixo
  fixedHeaderTable = function (id) {
    var self = this;
    var isFieldset = document.querySelector("#fieldset_" + id);
    var isExistFieldset = document.body.contains(isFieldset);

    if (isExistFieldset) {
      let fixedTable = document.getElementById('fieldset_' + id).offsetHeight;

      let isFilterTable = document.getElementById('filter-table_' + id);
      let isExistFilterTable = document.body.contains(isFilterTable);

      if (isExistFilterTable) {
        let searchingInput = document.getElementById('filter-table_' + id).offsetHeight;
        let insertHeight = fixedTable + searchingInput;
        self.$j("#customReportCell_" + id).css('height', insertHeight + 'px');
      }
      else {
        var title_size = document.getElementById('head_cell_row_' + id) != null ? document.getElementById('head_cell_row_' + id).offsetHeight + 20 : 0;
        var height_table = fixedTable - title_size;
        self.$j("#customReportCell_" + id).css('height', height_table + 'px');
      }
    }
    else {
      let screenHeight = window.innerHeight;
      let newHeigthTable = (screenHeight * 70) / 100;
      if( self.$j(".fixed-header-table-custom").length != 0 )
        self.$j(".fixed-header-table-custom").css('height', newHeigthTable + 'px');
    }
  }

  filterTable = function (id) {
    var self = this;
    var isFieldset = document.querySelector("#fieldset_" + id);
    var isExistFieldset = document.body.contains(isFieldset);

    if (isExistFieldset) {
      let selectDive = document.getElementById('filter-table_' + id);
      const isShowFilter = self.saved.config.hasOwnProperty('res_global_search') && self.saved.config.res_global_search == true;
      if (isShowFilter) {
        if (selectDive) {

        }
        else {
          let selectCustomReport = document.getElementById('customReportUpperAuxCell_' + id);
          let createDiv = document.createElement('div');
          createDiv.classList.add('input-group', 'rounded', 'filter-table-div');
          createDiv.setAttribute('id', 'filter-table_' + id);
          selectCustomReport.prepend(createDiv);

          let createInput = document.createElement('input');
          let selectDiv = document.getElementById('filter-table_' + id);
          createInput.setAttribute('type', 'search');
          createInput.setAttribute('id', 'unique-table-report_' + id);
          createInput.setAttribute('placeholder', 'Filtragem de informações');
          createInput.classList.add('form-control', 'rounded', 'filter-table-input');
          selectDiv.prepend(createInput);

          let createSpan = document.createElement('span');
          createSpan.classList.add('input-group-text', 'border-0');
          createSpan.setAttribute('id', 'span-filter_' + id);
          selectDiv.append(createSpan);

          let selectSpan = document.getElementById('span-filter_' + id);
          let createI = document.createElement('i');
          createI.classList.add('fas', 'fa-search');
          selectSpan.append(createI);

          self.$j('#unique-table-report_' + id).unbind('keyup');
          self.$j('#unique-table-report_' + id).keyup(function (e) {
            var results = self.saved.data;
            if (e.target['value'].length >= 3) {
              var results = self.saved.data.filter(x => x.filter(y => y.toLowerCase().indexOf(e.target['value'].toLowerCase()) > -1).length >= 1);
            }
            self.to_table(self.saved.colNames, results, self.saved.div, self.saved.rowspan, self.saved.colspan, self.saved.config, self.saved.interval, false);
          });
        }
      }

    }
    else {
      const isShowFilter = self.saved.config && self.saved.config.hasOwnProperty('res_global_search') && self.saved.config.res_global_search == true;
      if (isShowFilter) {
        self.$j('#unique-table-report').unbind('keyup');
        if (self.$j('#unique-table-report').length != 0)
          self.$j('#unique-table-report').keyup(function (e) {
            var results = self.saved.data;
            if (e.target['value'].length >= 3) {
              var results = self.saved.data.filter(x => x.filter(y => y.toLowerCase().indexOf(e.target['value'].toLowerCase()) > -1).length >= 1);
            }
            self.to_table(self.saved.colNames, results, self.saved.div, self.saved.rowspan, self.saved.colspan, self.saved.config, self.saved.interval, false);

          });
      }
      else {
        const selectSearchDiv = document.querySelector('div.input-group.rounded.filter-table-div');
        selectSearchDiv?.remove();
      }
    }
  };
  // #endregion

  get_formatter = function (config, len, isCompact) {
    var self = this;
    var list = Array(len).fill('empty');
    if (config)
      if (typeof config == 'string' && config != '' && config.split(',').length == len)
        list = config.split(',');
      else if (typeof config == 'object' && config.formatter && config.formatter.split(',').length == len)
        list = config.formatter.split(',');

    return list.map(function (e, i) {
      if ( /^default[0-9]+$/g.test(e) )
        return function (a) { return self.formata_numero(a, parseInt( e.replace('default', '') ), ',', '.', isCompact); };
      else if ( /^default[0-9]+WithNull$/g.test(e) )
        return function (a) { return a == null ? "-" : self.formata_numero(a, parseInt( e.replace('default', '').replace('WithNull', '') ), ',', '.', isCompact); };
      else if (e == 'hour')
        return function (a) {
          return a != null && a != '' ? moment.utc(a*1000).format('HH:mm:ss') : '';
        }
      else
        return function (a) { return a; };
    });
  };

  formata_numero = function (numero, casasDecimaisIN, separadorDecimalIN, separadorMilharIN, isCompact) {
    var casasDecimais = isNaN(casasDecimaisIN = Math.abs(casasDecimaisIN)) ? 2 : casasDecimaisIN;
    var separadorDecimal = separadorDecimalIN == undefined ? "," : separadorDecimalIN;
    var separadorMilhar = separadorMilharIN == undefined ? "." : separadorMilharIN;
    var signal = numero < 0 ? "-" : "";
    var i = parseInt(numero = Math.abs(+numero || 0).toFixed(casasDecimais)) + "";
    var j = (j = i.length) > 3 ? j % 3 : 0;

    var parteInteira = signal + (j ? i.substr(0, j) + separadorMilhar : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separadorMilhar);
    var parteDecimal = (casasDecimais ? separadorDecimal + Math.abs(numero - parseInt(i)).toFixed(casasDecimais).slice(2) : "");
    parteDecimal = (isCompact && parseInt(parteDecimal.replace(separadorDecimal, "")) == 0 ? '' : parteDecimal);

    return parteInteira + parteDecimal;
  };

  transposeMatrix = function (colNames, data) {
    // Validar entrada
    if (!Array.isArray(colNames) || !Array.isArray(data)) {
        throw new Error("Parâmetros inválidos: colNames e data devem ser arrays.");
    }

    // Garantir que cada linha em data tem o mesmo número de colunas que colNames
    if (data.some(row => row.length !== colNames.length)) {
        throw new Error("Número de colunas em data não corresponde ao número de colunas em colNames.");
    }

    // Concatenar colNames com data
    const combined = [colNames, ...data];

    // Transpor a matriz combinada
    const transposed = combined[0].map((_, colIndex) => combined.map(row => row[colIndex]));

    // Dividir a matriz transposta
    const newColNames = transposed[0];
    const newData = transposed.slice(1);

    return { colNames: newColNames, data: newData };
  }
}

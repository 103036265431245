import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-bootstrap-modal-confirm',
  templateUrl: './bootstrap-modal-confirm.component.html',
  styleUrls: ['./bootstrap-modal-confirm.component.css']
})
export class BootstrapModalConfirmComponent implements OnInit {

  @Input() title: string;
  @Input() msg: string;
  @Input() cancelTxt = 'Cancelar';
  @Input() confirmTxt = 'Confirmar';

  confirmResult: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onConfirm = () => {
    this.confirmAndClose(true);
  }

  onClose = () => {
    this.confirmAndClose(false);
  }

  private confirmAndClose = (value: boolean) => {
    this.confirmResult.next(value);
    this.bsModalRef.hide();
  }
}

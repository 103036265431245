import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { Global } from 'src/app/components/shared/base-func/global';

/**
 * Componente Angular responsável pela atualização de pontos (Local de medição).
 */
@Component({
  selector: 'app-point-update',
  templateUrl: './point-update.component.html',
  styleUrls: ['./point-update.component.css']
})
export class PointUpdateComponent implements OnInit {

  /**
   * Formulário para a atualização do ponto.
   */
  form: FormGroup;

  /**
   * Lista de setores.
   */
  sectors = '';

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Setor selecionado.
   */
  selectedSector = "";

  isLoading: boolean = false;
  customFields: any[] = []

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('change_point')) {
      this.router.navigate(['/403']);
    };

    // Obtém o ID do ponto da rota e executa a leitura correspondente.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Inicializa o formulário com os campos e validadores necessários.
    this.form = this.formBuilder.group({
      plant_point_id: '',
      company: '',
      sector: ['', Validators.required],
      name: ['', Validators.required],
      latitud: ['', Validators.maxLength(12)],
      longitud: ['', Validators.maxLength(12)],
    });
  }

  onFindCustomField() {
    this.plantService.onFindCustomFields('PON').subscribe((data) => {
      // this.onPrepareData(data.fields);
      this.customFields = data;
    })
  }

  /**
   * Realiza a leitura dos dados do ponto com o ID fornecido.
   *
   * @param id - ID do ponto a ser lido.
   */
  read = (id: number) => {

    // Obtém informações sobre a empresa e o aplicativo.
    let company = this.authService.getPerson();
    let sector = id;

    // Obtém a lista de setores.
    this.plantService.getSectorAll(this.authService.endpoint_main + 'plant/sector/list/nopage', company, sector).pipe(take(1)).subscribe(
      data => {
        this.sectors = data;

        // Obtém os dados do ponto.
        this.plantService.getPoint(id).pipe(take(1)).subscribe(
          data => {
            this.form.get('plant_point_id').setValue(data.plant_point_id);
            let companyValue = data.company && data.company.company_id ? data.company.company_id : null;
            this.form.get('company').setValue(companyValue);
            this.selectedSector = data.plant_sector_id.plant_sector_id;
            this.form.get('name').setValue(data.name);
            this.form.get('latitud').setValue(data.latitud);
            this.form.get('longitud').setValue(data.longitud);
            this.form.get('sector').setValue(data.plant_sector_id.plant_sector_id);
            this.customFields = data.fields ?? []
          }, error => {
            // Tratar erros de leitura.
            console.error(error);
          }
        )
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
        console.error(error);
      }
    )
  }

  /**
   * Obtém os controles do formulário para facilitar o acesso.
   */
  get f() { return this.form.controls; }

  /**
   * Verifica se um campo específico do formulário é válido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns true se o campo for inválido e o formulário tiver sido submetido, false caso contrário.
   */
  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  /**
   * Retorna as classes CSS necessárias para a exibição de erros no campo.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns Objeto contendo a classe 'is-invalid' se o campo for inválido, vazio caso contrário.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup - Grupo de formulários a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Atualiza os dados do ponto.
   */
  update = () => {
    this.submitted = true;

    const global = new Global()
    const result = global.saveData()

    if (this.form.valid) {

      const payload = {
        ...this.form.value,
        ...result
      }

      if (this.authService.hasToken() && this.authService.hasPerson()) {

        this.isLoading = true;
        this.plantService.updatePoint(payload).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            console.error(error);
          }
        ).add(() => {
          scrollTo(0,0)
          this.isLoading = false;
        })
      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário e realiza uma nova leitura dos dados com base no ID fornecido na rota.
   */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }
}

import * as moment from 'moment';
import { Moment } from 'moment';

import { BaseFunc } from '../../shared/base-func/base-func';
import { NgSelectComponent } from '@ng-select/ng-select';


export {}

declare global {
  interface Date {
    addDays(days: number, useThis?: boolean): Date;
    addMonths(days: number, useThis?: boolean): Date;
    addHours(days: number, useThis?: boolean): Date;

    addMinutes(days: number, useThis?: boolean): Date;
    addSeconds(days: number, useThis?: boolean): Date;
    addMilliSeconds(days: number, useThis?: boolean): Date;

    toMSJSON(): String;
    dtformat(format: string): string;
  }

  interface Array<T> {
    transpose(): Array<T>;
    indexOfNearestLessThan(attr, needle) : any;
    isEqualArray(array): boolean;
    orderedby(index, type, straight) : Array<T>;
  }

  interface Window {
    eventMap: any;
    pendentMap: any;
  }
}

window.eventMap = {};
window.pendentMap = [];

Date.prototype.addDays = function (days: number): Date {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

Date.prototype.addMonths = function (n: number): Date {
  this.setMonth(this.getMonth() + n);
  return this;
};

Date.prototype.addHours = function (n: number): Date {
  this.setHours(this.getHours() + n);
  return this;
};

Date.prototype.addMinutes = function (n: number): Date {
  this.setMinutes(this.getMinutes() + n);
  return this;
};

Date.prototype.addSeconds = function (n: number): Date {
  this.setSeconds(this.getSeconds() + n);
  return this;
};

Date.prototype.addMilliSeconds = function (n: number): Date {
  this.setMilliseconds(this.getMilliseconds() + n);
  return this;
};

Date.prototype.toMSJSON = function () {
  function pad(n) {
    return n < 10 ? '0' + n : n
  }
  return this.getUTCFullYear() + '-' +
    pad(this.getUTCMonth() + 1) + '-' +
    pad(this.getUTCDate()) + 'T' +
    pad(this.getUTCHours()) + ':' +
    pad(this.getUTCMinutes()) + ':' +
    pad(this.getUTCSeconds()) + 'Z';
};

Date.prototype.dtformat = function (format: string) : string {
  var o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    "S": this.getMilliseconds() //millisecond
  };

  if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
    (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(RegExp.$1,
        RegExp.$1.length == 1 ? o[k] :
        ("00" + o[k]).substr(("" + o[k]).length));
  return format;
};

Array.prototype.transpose = function () {
  // Calculate the width and height of the Array
  var a = this,
    w = a.length ? a.length : 0,
    h = a[0] instanceof Array ? a[0].length : 0;

  // In case it is a zero matrix, no transpose routine needed.
  if (h === 0 || w === 0) {
    return [];
  }

  /**
   * @var {Number} i Counter
   * @var {Number} j Counter
   * @var {Array} t Transposed data is stored in this array.
   */
  var i, j, t = [];

  // Loop through every item in the outer array (height)
  for (i = 0; i < h; i++) {

    // Insert a new row (array)
    t[i] = [];

    // Loop through every item per item in outer array (width)
    for (j = 0; j < w; j++) {

      // Save transposed data.
      t[i][j] = a[j][i];
    }
  }

  return t;
};

Array.prototype.indexOfNearestLessThan = function(attr, needle) {
	if (this.length === 0) return -1;

	var high = this.length - 1,
		low = 0,
		mid,
		item,
		target = -1;

	if (this[high][attr] < needle) {
		return high;
	}

	while (low <= high) {
		mid = (low + high) >> 1;
		item = this[mid][attr];
		if (item > needle) {
			high = mid - 1;
		} else if (item < needle) {
			target = mid;
			low = mid + 1;
		} else {
			return low;
		}
	}

	return target;
};

Array.prototype.isEqualArray = function( array ) {

	return this.length == array.length && this.every(function(element, index) {
    return element === array[index];
  })

};

Array.prototype.orderedby = function (index, type, straight) {
  var isStraight = (straight != undefined ? straight : 1 );
  return this.slice().sort(function(a,b){
    if (type == 'string')
      return isStraight * ( a[index] > b[index] ? 1 : -1 );
    else
      return isStraight * ( parseFloat(a[index]) > parseFloat(b[index]) ? 1 : -1 );
  });
}

export class Global extends BaseFunc {
  getRanges = (): { [key:string]: Array<Moment> } => {
    return {
        'Hoje': [moment().startOf('day'), moment().add(1, 'days').startOf('day')],
        'Ontem': [moment().subtract(1, 'days').startOf('day'), moment().startOf('day')],
        'Últimos 7 Dias': [moment().subtract(6, 'days').startOf('day'), moment().add(1, 'days').startOf('day')],
        'Últimos 30 Dias': [moment().subtract(29, 'days').startOf('day'), moment().add(1, 'days').startOf('day')],
        'Mês Anterior': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').add(1, 'days').startOf('day')],
        'Mês Atual': [moment().startOf('month'), moment().endOf('month').add(1, 'days').startOf('day')],
        'Últimos 6 Meses': [moment().subtract(6, 'month').startOf('day'), moment().add(1, 'days').startOf('day')],
        'Ano Passado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year').add(1, 'days').startOf('day')],
        'Ano Atual': [moment().startOf('year'), moment().endOf('year').add(1, 'days').startOf('day')],
        'Últimos 12 Meses': [moment().subtract(12, 'month').startOf('day'), moment().add(1, 'days').startOf('day')],
    };
  };

  getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  AjaxWaitRequest = (container, request) => {

  }

  GetFavoriteViews = (formCode, target) => {

  }

  /**
   * Build datatables
   * @param {*} tableId
   */
  buildDataTable = (tableId) => {

  }

  saveData = () => {
    var self = this;
    var result = new Object();

    self.$j('input, textarea, select, checkbox, radio, ng-select').forEach(function (value, index) {
      if (self.$j(value).attr("class") || self.$j(value).attr("name")) {
        var refVal = (value.tagName == 'NG-SELECT' ? 'select-multiple' : value.type);
        switch (refVal) {
          case 'password':
          case 'select-one':
          case 'number':
          case 'time':
          case 'text':
          case 'textarea':
          case undefined: // Caso do input date-interval
            var resultValue;
            if(self.$j(value).hasClass('date-interval')) { //daterangepicker
              //self.$j('.md-drppicker').hide();
              self.$j('.date-interval').trigger('click');
              resultValue = self.$j('.ranges button.active').length > 0 ? self.$j('.ranges button.active').text() : 'Personalizar';
              self.$j('.buttons_input .btn:last-child').trigger('click');
              resultValue = (resultValue == 'Personalizar' ? ( self.$j(value).val() || self.$j(value).html() ) : resultValue);
            }
            else
              resultValue = self.$j(value).val();

            if (value.name != "" && value.name != undefined)
              result[value.name] = resultValue;
            else if(value.id != "" && value.id != undefined)
              result[value.id] = resultValue;
            break;
          case 'select-multiple':
            var attr = self.$j(value).hasClass('ng-select') || self.$j(value).hasClass('ng-select-top') || self.$j(value).hasClass('ng-select-bottom');
            if(attr) {
              var selElements = self.getComponent( value ).itemsList.selectedItems;

              var data = selElements.map(function(e) { return e.label  + ' **&& ' + ( e.value.id || e.value ); });
              result[value.id] = data.join(' ¶¶µµ ');
            }
            break;
          case 'checkbox':
          case 'radio':
            if (self.$j(value).is(':checked'))
              result[value.id] = self.$j(value).val();
            break;
        }
      }
    });
    return result;
  }

  saveFormData = (fieldUploads) => {
    var self = this;
    var result = new FormData();

    self.$j('input, textarea, select, checkbox, radio, ng-select, input').forEach(function (value, index) {
      if (self.$j(value).attr("class") || self.$j(value).attr("name")) {
        var refVal = (value.tagName == 'NG-SELECT' ? 'select-multiple' : value.type);
        switch (refVal) {
          case 'password':
          case 'select-one':
          case 'number':
          case 'time':
          case 'text':
          case 'textarea':
          case undefined: // Caso do input date-interval
            var resultValue;
            if(self.$j(value).hasClass('date-interval')) { //daterangepicker
              //self.$j('.md-drppicker').hide();
              self.$j('.date-interval').trigger('click');
              resultValue = self.$j('.ranges button.active').length > 0 ? self.$j('.ranges button.active').text() : 'Personalizar';
              self.$j('.buttons_input .btn:last-child').trigger('click');
              resultValue = (resultValue == 'Personalizar' ? ( self.$j(value).val() || self.$j(value).html() ) : resultValue);
            }
            else
              resultValue = self.$j(value).val();

            if (value.name != "" && value.name != undefined)
              result.append(value.name, resultValue);
            else if(value.id != "" && value.id != undefined)
              result.append(value.id, resultValue);
            break;
          case 'select-multiple':
            var attr = self.$j(value).hasClass('ng-select') || self.$j(value).hasClass('ng-select-top') || self.$j(value).hasClass('ng-select-bottom');
            if(attr) {
              var selElements = self.getComponent( value ).itemsList.selectedItems;

              var data = selElements.map(function(e) { return e.label  + ' **&& ' + ( e.value.id || e.value ); });
              //result[value.id] = data.join(' ¶¶µµ ');
              result.append( value.id, data.join(' ¶¶µµ ') );
            }
            break;
          case 'checkbox':
          case 'radio':
            if (self.$j(value).is(':checked'))
              //result[value.id] = self.$j(value).val();
              result.append( value.id, self.$j(value).val() );
            break;
          case 'file':
            result.append(value.id, fieldUploads[value.id].files.length > 0 ? fieldUploads[value.id].files[0] : null);
            break;
        }
      }
    });
    return result;
  }

  clearForm = (form?, clearSelect?) => {
    var self = this;
    self.$j(self.$j(form)).find('input, textarea, select, checkbox, radio, ng-select').forEach(function (el) {
      switch (el.type || el.tagName.toLowerCase()) {
        case 'password':
        case 'select':
        case 'select-one':
        case 'number':
        case 'time':
        case 'text':
        case 'textarea':
          self.$j(el).val('');
          break;
        case 'select-multiple':
        case 'ng-select':
          if (self.$j(el).hasClass('ic_selectcomponent')){
            //self.$j('#' + el.id + ' .ng-value').remove();
            self.clearSelect('#' + el.id);
          }
          else {
            var selectID = self.$j(el);
            self.$j(el).find('option:checked').forEach(function() {
              var id = self.$j(el).attr('value');
              self.$j(el).removeAttr('selected');
              self.$j(el).removeAttr('data-select2-id');
              self.$j(selectID).trigger({ type:'select2:unselecting', params: { args: { data: { id: id }  } } });
            });
          }
          break;
        case 'checkbox':
        case 'radio':
          el.checked = false;
          break;
      }
    });
  }

  datePickerButton = (data, id, value) => {
    var self = this;
    var interval, ranges = self.getRanges();
    if( ranges[ data[id] ] ) {
      interval = [ranges[data[id]][0], ranges[data[id]][1]];
      self.$j(".ranges button").forEach( x => {
        self.$j( x ).removeClass('active');
        if(self.$j( x ).text() == data[id])
          self.$j( x ).addClass('active');
      });
    }
    else
      interval = data[id].split(' - ').map(function(e) { return moment(e, 'DD/MM/YYYY HH:mm'); });


    var ref = document.querySelector('#date-interval' );
    var single = self.$j(value).hasClass('single-date');
    var pickerRef = self.getDirectives( ref ).find(x => x.picker);
    var datepickerCompRef = self.getOwningComponent( ref ).fields ? self.getOwningComponent( ref ).fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA' ) : self.getOwningComponent( ref );

    datepickerCompRef.selected.startDate = interval[0];
    pickerRef.picker.setStartDate(interval[0]);
    if(!single) {
      datepickerCompRef.selected.endDate = interval[1];
      pickerRef.picker.setEndDate(interval[1]);
      self.$j(".date-interval").val(interval[0].format('DD/MM/YYYY HH:mm') + ' - ' + interval[1].format('DD/MM/YYYY HH:mm'));
      self.$j('.buttons_input .btn:last-child').trigger('click');
    }
    else
      self.$j(".date-interval").val( interval[0].format('DD/MM/YYYY HH:mm') );
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  fillFormWithHistoryObject = (data, region?, fieldUploads?) => {
    var aux = [];
    var self = this;
    var form_selector = ( region == undefined ? '.managedform' : region );

    self.$j(form_selector).find('input, textarea, select, checkbox, radio, ng-select').forEach(function (value, index) {
      switch (value.type || value.tagName.toLowerCase()) {
        case 'password':
        case 'select':
        case 'select-one':
        case 'number':
        case 'time':
        case 'text':
        case 'textarea':
        case undefined: // Caso do input date-interval
          var id = ( value.name && value.name != "" ) ? value.name : value.id;
          if ( data && id && id != "" ) {
            if(self.$j(value).hasClass('date-interval')) //daterangepicker
              self.datePickerButton(data, id, value);
            else if (self.$j(value).hasClass('single-date-without-hours'))
              self.$j(value).val(data[id].split(' ') [0]);
            else if( self.$j(value).hasClass('textArrayInput') ){
              var coord = id.split('_');
              var v_array = data[coord[0]].length > parseInt(coord[1]) ? data[coord[0]][coord[1]][coord[2]] : '';
              if(v_array != '')
                  self.$j('#'+coord[0].replace('textArrayField', 'addButton') + '_' + coord[1]).trigger('click');
              self.$j(value).val( v_array );
            }
            else
              self.$j(value).val(data[id]);
            self.$j(value).trigger('change');
          }
          break;
        case 'select-multiple':
        case 'ng-select':
          if (data[value.id] && data[value.id] != "" && value.id && value.id != "") {
            aux = data[value.id].split(' ¶¶µµ ');
            for (var i = 0; i < aux.length; ++i) {
              var auxItens = aux[i].split(' **&& ');
              self.addToSelect2(value, auxItens[1], auxItens[0] );
            }
          }
          break;
        case 'checkbox':
        case 'radio':
          if (data[value.id])
            value.checked = true;
          break;
        case 'file':
          var fileImg = null;
          var base64 = data[value.id];
          if ( value.accept == 'image/*' )
            fileImg = self.dataURLtoFile("data:image/png;base64, " + base64,'image.png');

          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(fileImg);
          value.files = dataTransfer.files
          fieldUploads[value.id].files = value.files;

          break;
      }
    });
  }

  addToSelect2 = (ref, id, label) => {
    var component;
    if(ref instanceof NgSelectComponent)
      component = ref;
    else if( typeof ref === 'string' || ref instanceof String )
      component = this.getComponent( document.querySelector( ref.toString() ) );
    else
      component = this.getComponent(ref);

    var item;
    if( id != null && id != undefined)
      item = component.itemsList.items.find(x => x.value == id || ( x.value.id != null && x.value.id != undefined && x.value.id == id ) );
    else if( label )
      item = component.itemsList.findByLabel(label);
    component.select( item );
  }

  removeToSelect2 = (ref, id, label) => {
    var component;
    if(ref instanceof NgSelectComponent)
      component = ref;
    else if( typeof ref === 'string' || ref instanceof String )
      component = this.getComponent( document.querySelector( ref.toString() ) );
    else
      component = this.getComponent(ref);

    var item;
    if( id != null && id != undefined)
      item = component.itemsList.items.find(x => x.value == id || ( x.value.id && x.value.id == id ) );
    else if( label )
      item = component.itemsList.findByLabel(label);
    component.unselect( item );
  }

  clearSelect = (ref) => {
    var component;
    if(ref instanceof NgSelectComponent)
      component = ref;
    else if( typeof ref === 'string' || ref instanceof String )
      component = this.getComponent(document.querySelector(ref.toString()));
    else
      component = this.getComponent(ref);

    component.itemsList.selectedItems.forEach(x => component.unselect( x ) );
    component.handleClearClick();
  }

  ckTrl = (original) => {
    return original;
  }

  createGuid = () => {
    return 'c_' + 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  validateToServer = (valid, modalAlertService, callback=null) => {
    var self = this;
    var isLimited = true;
    var messages = [];
    self.$j('input[maxval]').forEach(function (e, i) {
      if (self.$j(e)?.length > 0 && self.$j(e).val() != '' && parseInt(self.$j(e).val()) > parseInt(self.$j(e).attr('maxval')) ) {
        messages.push(self.$j(e).val() + " excede o tamanho máximo " + self.$j(e).attr('maxval'));
        isLimited = false;
      }
    });
    // if (!isLimited)
    //   return false;

    for (var i = 0; i < valid.length; ++i) {
      var isSelectObg = ( valid[i].id.indexOf('select') == 0 && self.$j('#'+valid[i].id).val().length == 0 );
      var generalFieldObg = ( ( valid[i].id.indexOf('plainField') == 0 || valid[i].id.indexOf('textField') == 0 || valid[i].id.indexOf('date_without_hours') == 0 || valid[i].id.indexOf('hourField') == 0 ) && self.$j('#'+valid[i].id).val() == "");
      if ( isSelectObg || generalFieldObg ) {
        messages.push("Campo '" + valid[i].label + "' é obrigatorio.");
        //return false;
      }
    }

    if (callback != null && callback != undefined)
      callback(valid, messages);

    if (messages.length > 0) {
      modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + messages.map(x => '<li>' + x + '</li>').join('') + '</ul>');
      return false;
    }

    return true;
  };

  runAfter = (callback, selector) => {
    var selfGlobal = this;
    var afterEvent = setInterval(function() {
      var element = selfGlobal.$j(selector);
      if ( element.length > 0 ) {
        callback();
        clearInterval(afterEvent);
      }
    }, 100)

  }

  cartesianProduct = (arrays) => {
    if (!arrays || arrays.length === 0) {
      return [];
    }

    // Função recursiva para calcular o produto cartesiano
    function combine(acc, array) {
      const result = [];
      acc.forEach(accItem => {
        array.forEach(item => {
          result.push([...accItem, item]);
        });
      });
      return result;
    }

    // Inicializa o acumulador com o primeiro vetor
    return arrays.reduce((acc, array) => combine(acc, array), [[]]);
  }
}




<section class="section mx-3 my-3">
  <div class="section-header">
      <h1>{{ layout.aliasTitle('Empresa') }}</h1>
      <!-- <div class="section-header-breadcrumb">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/company">Empresa</a></div>
          <div class="breadcrumb-item active">Adicionar</div>
      </div> -->
  </div>

  <div class="row mt-3">
      <div class="col-12 ">
          <div class="card">
              <div class="card-header cm">
                  <h4>Adicionar {{ layout.aliasTitle('Empresa') }} </h4>
              </div>
              <div class="card-body">
                  <form class="cm-2" method="POST" [formGroup]="companyForm">
                      <div class="form-row">
                          <div class="form-group col-md-6">
                              <label for="company_division">Unidade Industrial</label>
                              <select class="form-control" formControlName="company_division"
                                  [ngClass]="displayFieldCss('company_division')">
                                  <option *ngFor="let item of companyDivisions" value="{{item.company_division_id}}">
                                      {{item.name}}
                                  </option>
                              </select>
                              <div class="invalid-feedback">
                                  Por favor selecione uma unidade de grupo empresarial
                              </div>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="timezone">Timezones</label>
                            <select class="form-control" formControlName="timezone"
                                [ngClass]="displayFieldCss('timezone')">
                                <option *ngFor="let item of timezones" value="{{item.id}}">
                                    {{item.value}}
                                </option>
                            </select>
                            <div class="invalid-feedback">
                                Por favor selecione um timezone
                            </div>
                        </div>

                      </div>
                  </form>
              </div>

              <app-person-create (clear)="reset()" (created)="save($event)" (checkFields)="validateAllFormFields(companyForm)" [parentFormStatus]="companyForm.valid"></app-person-create>
          </div>
      </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para a atualização de Natureza da Medição.
 */
@Component({
  selector: 'app-nature-update',
  templateUrl: './nature-update.component.html',
  styleUrls: ['./nature-update.component.css']
})
export class NatureUpdateComponent implements OnInit {

  /** Formulário para a atualização de Naturezas. */
  form: FormGroup;

  /** Indica se o formulário foi submetido. */
  submitted = false;

  isLoading: boolean = false;

  /** Lista de opções de agregação. */
  aggrList = [
    { 'id': 'avg', 'value': 'Média' },
    { 'id': 'max', 'value': 'Máximo' },
    { 'id': 'min', 'value': 'Mínimo' },
    { 'id': 'sum', 'value': 'Soma' },
    { 'id': 'count', 'value': 'Contador' },
  ];

  /** Valor selecionado para a agregação. */
  aggregationSelected = '';

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  /** Inicialização do componente. */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('change_equipmentnature') == false) {
      this.router.navigate(['/403']);
    };

    // Observa as mudanças nos parâmetros da rota.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Inicializa o formulário.
    this.form = this.formBuilder.group({
      plant_equipment_nature_id: '',
      company: '',
      name: ['', Validators.required],
      aggregation: ['', Validators.required],
      un: ['', Validators.required]
    });
  }

  /**
   * Realiza a leitura dos dados da Natureza a ser atualizada.
   *
   * @param id Identificador da Natureza.
   */
  read = (id) => {
    this.plantService.getEquipmentNature(id).pipe(take(1)).subscribe(
      data => {
        this.form.get('plant_equipment_nature_id').setValue(data.plant_equipment_nature_id);
        let companyValue = data.company && data.company.company_id ? data.company.company_id : null;
        this.form.get('company').setValue(companyValue);
        this.form.get('name').setValue(data.name);
        this.aggregationSelected = data.aggregation;
        this.form.get('aggregation').setValue(data.aggregation);
        this.form.get('un').setValue(data.un);
      }, error => {
        // Tratar erro, se necessário.
      }
    );
  }

  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field Nome do campo.
   * @returns True se o campo for inválido e tiver sido tocado/submetido, False caso contrário.
   */
  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result;
  }

  /**
   * Obtém a classe CSS para destacar campos inválidos.
   *
   * @param field Nome do campo.
   * @returns Objeto com a classe CSS 'is-invalid' se o campo for inválido, vazio caso contrário.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário, marcando-os como tocados.
   *
   * @param formGroup FormGroup a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /** Atualiza os dados da Natureza. */
  update = () => {
    this.submitted = true;

    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Chama o serviço para atualizar a Natureza.
        this.isLoading = true;
        this.plantService.updateEquipmentNature(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de perigo caso não haja ID de aplicativo ou pessoa disponíveis.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de erro.
      this.validateAllFormFields(this.form);
    }
  }

  /** Reinicia o formulário e marca como não submetido. */
  reset = () => {
    this.submitted = false;
    // Obtém o ID da Natureza a partir dos parâmetros da rota e realiza a leitura dos dados novamente.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cidade } from '../models/cidade';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  constructor(private http: HttpClient) {
  }

  getEstadosBr(): Observable<any> {
    return this.http.get('assets/dados/estadosbr.json');
  }

  getCidadesHUM(idEstado: any): Observable<any> {
    return this.http.get<Cidade[]>('assets/dados/cidades.json')
      .pipe(
        // tslint:disable-next-line:triple-equals
        map((cidades: Cidade[]) => cidades.filter(c => c.estado == idEstado))
      );
  }

  getCidades(idEstado: any): Observable<any> {
    return this.http.get<Cidade[]>('assets/dados/cidades.json')
      .pipe(
        // tslint:disable-next-line:triple-equals
        map((cidades: Cidade[]) => cidades.filter(c => Array.isArray(idEstado) ? idEstado.includes(c.estado) : idEstado == c.estado))
      );
  }

  getTecnologias = () => [
    {nome: 'java', desc: 'Java'},
    {nome: 'javascript', desc: 'JavaScript'},
    {nome: 'php', desc: 'PHP'},
    {nome: 'ruby', desc: 'Ruby'}
  ]
}

import { Component, ViewChildren, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './../auth.service';
import { ResetPasswordService } from '../reset-password.service';
import { environment } from './../../../environments/environment';
import { BaseFunc } from '../../components/shared/base-func/base-func';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BootstrapModalAlertService } from '../../components/shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { MsalService } from '@azure/msal-angular';
import { BootstrapModalAlertComponent } from 'src/app/components/shared/bootstrap-modal-alert/bootstrap-modal-alert.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * O componente 'SigninComponent' é responsável pela página de login (signin).
 *
 * Este componente gerencia a lógica de autenticação do usuário, interações com Azure,
 * e funcionalidades relacionadas à senha.
 */
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent extends BaseFunc implements OnInit {

  // Objeto para armazenar informações do usuário atualmente logado
  currentUser = {};


  // Cabeçalhos HTTP para requisições
  headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

  // Referência ao componente de alerta BootstrapModalAlert
  @ViewChildren('alertDiv') alertDiv: BootstrapModalAlertComponent;

  // Modelo para informações de login do usuário
  login: any = {
    "email": "",
    "password": "",
    "username": ""
  };

  // Flag para controlar a exibição do formulário de recuperação de senha
  forgotPassword: boolean;

  // Mensagem e configurações de alerta
  message: string;
  alertType: string;
  alertMessage: string;
  showAlertErrorLogin: boolean;

  //Configurações White Label
  logo_wl = window['whiteLabelConf']('logo');
  bg_wl = window['whiteLabelConf']('bg_img');
  isHorizontal = window['whiteLabelConf']('horizontal');

  isPass: boolean = true;
  isLoading: boolean = false;

  form: FormGroup;
  get formControls() {
    return this.form.controls;
  }

  get hasInvalidEmail() {
    return this.formControls['email'].invalid && this.formControls['email'].touched;
  }

  get hasInvalidPass() {
    return this.formControls['password'].invalid && this.formControls['password'].touched;
  }

  // Construtor para injetar dependências necessáriass
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private modalAlertService: BootstrapModalAlertService,
    private resetPasswordService: ResetPasswordService,
    private msalService: MsalService,
    private fb: FormBuilder,
    public layout: LayoutService,
    private authService: AuthService
  ) { super(); }

  ngOnInit(): void {
    this.initForm();

    localStorage.removeItem('users_service');
    localStorage.removeItem('main_service');
    
    // Verifica se o usuário já está autenticado
    let isAuthenticated = this.authService.hasToken();

    this.forgotPassword = false;

    // Verifica o token de autenticação
    if (isAuthenticated === false) {
      // Trata o redirecionamento de autenticação do Azure
      this.msalService.instance.handleRedirectPromise().then(
        res => {
          if (res != null && res.account != null) {
            this.msalService.instance.setActiveAccount(res.account);
            if (!isAuthenticated) {
              this.azureSignIn(res.account);
            }
          }
        }
      )
      this.router.navigate(['/signin']);
    } else {
      // Redireciona para o painel se já estiver autenticado
      this.router.navigate(['/dashboard']);
    }
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    })
  }

  onSubmit() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this.login = {
      email: this.formControls['email'].value,
      password: this.formControls['password'].value,
      username: ''
    }

    this.signIn();
  }

  /**
 * Método para lidar com o processo de login do usuário.
 * Envia uma solicitação HTTP para autenticar o usuário com as credenciais fornecidas.
 * Se a autenticação for bem-sucedida, armazena informações relevantes no armazenamento local
 * e navega para a página principal.
 * Em caso de falha, exibe mensagens de erro apropriadas.
 */
  signIn(): void {
    // Envia uma solicitação HTTP POST para o endpoint de autenticação
    this.http.post<any>(environment.authApiUrl + '/signin', this.login, { headers: this.headers })
      .pipe(take(1)).subscribe(
        data => {
          // Armazena o valor encripitado no local storage
          localStorage.setItem(`${this.authService.prefixKeyName}w`, data);
          localStorage.setItem(`${this.authService.prefixKeyName}origin`, data);
          this.authService.onPrepareUserDetails(data);
          // Verifica o status do banco de dados e navega conforme necessário
          if (this.authService.getDbActive()) {
            window.location.reload();
            // this.router.navigate(['']);
          } else {
            alert("A instância do cliente está inativa no momento. Caso o usuário deseje um maior tempo ativo, favor entrar em contato com o suporte da IndustryCare.");
            this.router.navigate(['/signin']);
          }
        }, error => {
          // Trata erro de autenticação
          if (error.error) {
            this.showAlertErrorLogin = true;
            this.alertType = 'danger';
            this.alertMessage = 'Erro ao conectar no servidor.';
            console.error('Erro ao conectar no servidor.', error);
          }
          else {
            this.showAlertErrorLogin = true;
            this.alertType = 'danger';
            this.alertMessage = 'O usuário e senha não correspondem às informações em nossos registros. Tente novamente.';
          }
        }).add(() => {
          this.isLoading = false;
        });
  }

  /**
   * Método para lidar com a funcionalidade de esquecimento de senha.
   * Configura a flag `forgotPassword` para exibir o formulário de recuperação de senha.
   */
  onForgotPassword(): void {
    this.forgotPassword = !this.forgotPassword;
  }

  /**
   * Método para iniciar a integração com o Azure.
   * Utiliza o serviço MSAL para realizar um login via redirecionamento.
   */
  integration(): void {
    this.msalService.loginRedirect();
  }

  /**
 * Método para lidar com o login através do Azure.
 * Realiza uma solicitação HTTP de login semelhante ao método `signIn`,
 * preenchendo automaticamente o nome de usuário com as informações da conta Azure.
 * @param account - As informações da conta do Azure para preencher automaticamente o nome de usuário.
 */
  azureSignIn(account: any): void {
    // Preenche automaticamente o nome de usuário com as informações da conta Azure
    this.login.username = account.username;

    // Realiza a solicitação HTTP de login
    this.http.post<any>(environment.authApiUrl + '/signin', this.login, { headers: this.headers })
      .pipe(take(1)).subscribe(
        data => {

          // Armazena o valor encripitado no local storage
          localStorage.setItem(`${this.authService.prefixKeyName}w`, data);

          // Verifica o status do banco de dados e navega conforme necessário
          if (this.authService.getDbActive()) {
            window.location.reload();
            // this.router.navigate(['']);
          } else {
            alert("A instância do cliente está inativa no momento. Caso o usuário deseje um maior tempo ativo, favor entrar em contato com o suporte da IndustryCare.");
            this.authService.doLogout();
          }
        }, error => {
          // Trata erro de autenticação
          if (error.error) {
            alert(error.error.msg);
          } else {
            this.toastr.error('Credenciais inválidas');
          }
        });
  }

  /**
   * Método para lidar com a alteração de senha.
   * Inicia o serviço de redefinição de senha, enviando um link de redefinição para o e-mail do usuário.
   */
  onChangePassword(): void {
    if(this.formControls['email'].invalid) {
      return
    };

    this.isLoading = true;
    var userMail = this.formControls['email'].value
    this.resetPasswordService.changePassword(userMail).pipe(take(1)).subscribe(
      data => {
        this.message = data;
        this.modalAlertService.showAlertSuccess('Foi enviado para o seu email um link de redefinição de senha');
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    ).add(() => {
      this.isLoading = false;
    });
  }
}

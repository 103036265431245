import { AnimationBuilder, AnimationPlayer, query, stagger, style, animate } from '@angular/animations';
import { interval } from 'rxjs';

class ManipulateDOM {
    unwrapRNode(value) {
        while (Array.isArray(value)) {
            value = value[0];
        }
        return value;
    }

    findViaNativeElement(lView, target) {
        const tView = lView[1];
        for (let i = 20; i < tView.bindingStartIndex; i++) {
            if (this.unwrapRNode(lView[i]) === target) {
                return i;
            }
        }
        return -1;
    }

    readPatchedData(target) {
        return target["__ngContext__"] || null;
    }

    attachPatchData(target, data) {
        target["__ngContext__"] = data;
    }

    createLContext(lView, nodeIndex, native) {
        return {
            lView,
            nodeIndex,
            native,
            component: undefined,
            directives: undefined,
            localRefs: undefined,
        };
    }

    isLContainer(value) {
        return Array.isArray(value) && value[1] === true;
    }

    getLViewParent(lView) {
        const parent = lView[3];
        return this.isLContainer(parent) ? parent[3] : parent;
    }

    getComponentAtNodeIndex(nodeIndex, lView) {
        const tNode = lView[1].data[nodeIndex];
        let directiveStartIndex = tNode.directiveStart;
        return tNode.flags & 2 /* isComponentHost */ ? lView[directiveStartIndex] : null;
    }

    getDirectivesAtNodeIndex(nodeIndex, lView, includeComponents) {
        const tNode = lView[1].data[nodeIndex];
        let directiveStartIndex = tNode.directiveStart;
        if (directiveStartIndex == 0)
            return [];
        const directiveEndIndex = tNode.directiveEnd;
        if (!includeComponents && tNode.flags & 2 /* isComponentHost */)
            directiveStartIndex++;
        return lView.slice(directiveStartIndex, directiveEndIndex);
    }

    getLContext(component_ref) {
        var mpValue = component_ref["__ngContext__"];
        if ( Array.isArray(mpValue) ) {
            const lView = mpValue;
            let nodeIndex;
            let component = undefined;
            let directives = undefined;

            nodeIndex = this.findViaNativeElement(lView, component_ref);
            if (nodeIndex == -1) {
                return null;
            }

            const native = this.unwrapRNode(lView[nodeIndex]);
            const existingCtx = this.readPatchedData(native);
            const context = (existingCtx && !Array.isArray(existingCtx)) ?
                existingCtx :
                this.createLContext(lView, nodeIndex, native);

            // only when the component has been discovered then update the monkey-patch
            if (component && context.component === undefined) {
                context.component = component;
                this.attachPatchData(context.component, context);
            }
            // only when the directives have been discovered then update the monkey-patch
            if (directives && context.directives === undefined) {
                context.directives = directives;
                for (let i = 0; i < directives.length; i++) {
                    this.attachPatchData(directives[i], context);
                }
            }
            this.attachPatchData(context.native, context);
            mpValue = context;

        }
        else {
            const rElement = component_ref;
            // if the context is not found then we need to traverse upwards up the DOM
            // to find the nearest element that has already been monkey patched with data
            let parent = rElement;
            while (parent = parent.parentNode) {
                const parentContext = this.readPatchedData(parent);
                if (parentContext) {
                    let lView;
                    if (Array.isArray(parentContext)) {
                        lView = parentContext;
                    }
                    else {
                        lView = parentContext.lView;
                    }
                    // the edge of the app was also reached here through another means
                    // (maybe because the DOM was changed manually).
                    if (!lView) {
                        return null;
                    }
                    const index = this.findViaNativeElement(lView, rElement);
                    if (index >= 0) {
                        const native = this.unwrapRNode(lView[index]);
                        const context = this.createLContext(lView, index, native);
                        this.attachPatchData(native, context);
                        mpValue = context;
                        break;
                    }
                }
            }
        }

        return mpValue || null;
    }

    getContext(element) {
        const context = this.getLContext(element);
        return context === null ? null : context.lView[8];
    }

    getComponent(component_ref) {
        const context = this.getLContext(component_ref);
        if (context === null)
            return null;
        if (context.component === undefined) {
            context.component = this.getComponentAtNodeIndex(context.nodeIndex, context.lView);
        }
        return context.component;
    }

    getDirectives(component_ref) {
        const context = this.getLContext(component_ref);
        if (context.directives === undefined) {
            context.directives = this.getDirectivesAtNodeIndex(context.nodeIndex, context.lView, false);
        }

        return context.directives === null ? [] : [...context.directives];
    }

    getOwningComponent(elementOrDir) {
        const context = this.getLContext(elementOrDir);
        if (context === null)
            return null;
        let lView = context.lView;
        let parent;

        while (lView[1].type === 2 /* Embedded */ && (parent = this.getLViewParent(lView))) {
            lView = parent;
        }
        return lView[2] & 512 /* IsRoot */ ? null : lView[8];
    }
}
export class BaseFunc extends ManipulateDOM {
    //Dom Maniputation Methods
    $j = (query, element?) : IntermediateFormat => {
        var self = this;

        var isHTML = function(str) {
            var rquickExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/;
            var match;
            if(str[ 0 ] === "<" && str[ str.length - 1 ] === ">" && str.length >= 3 )
                match = [ null, str, null ];
            else
                match = rquickExpr.exec( str );

            return match;
        };
        var parseHTML = function(str, context) {
            function buildFragment( elems, context ) {
                var elem, tmp, tag, wrap, attached, j,
                    fragment = context.createDocumentFragment(),
                    nodes = [],
                    i = 0,
                    l = elems.length;
                var rtagName = ( /<([a-z][^\/\0>\x20\t\r\n\f]*)/i );
                var wrapMap = {
                    thead: [ 1, "<table>", "</table>" ],
                    col: [ 2, "<table><colgroup>", "</colgroup></table>" ],
                    tr: [ 2, "<table><tbody>", "</tbody></table>" ],
                    td: [ 3, "<table><tbody><tr>", "</tr></tbody></table>" ],
                    th: [ 3, "<table><tbody><tr>", "</tr></tbody></table>" ],
                    tbody: [ 1, "<table>", "</table>" ],
                    tfoot: [ 1, "<table>", "</table>" ],
                    colgroup: [ 1, "<table>", "</table>" ],
                    caption: [ 1, "<table>", "</table>" ],
                    _default: [ 0, "", "" ]
                };

                for ( ; i < l; i++ ) {
                    elem = elems[ i ];
                    if ( elem || elem === 0 ) {
                        tmp = tmp || fragment.appendChild( context.createElement( "div" ) );

                        // Deserialize a standard representation
                        tag = ( rtagName.exec( elem ) || [ "", "" ] )[ 1 ].toLowerCase();
                        wrap = wrapMap[ tag ] || wrapMap._default;
                        tmp.innerHTML = wrap[ 1 ] + elem + wrap[ 2 ];

                        // Descend through wrappers to the right content
                        j = wrap[ 0 ];
                        while ( j-- ) {
                            tmp = tmp.lastChild;
                        }

                        // Support: Android <=4.0 only, PhantomJS 1 only
                        // push.apply(_, arraylike) throws on ancient WebKit
                        //jQuery.merge( nodes, tmp.childNodes );
                        nodes = Array.prototype.slice.call( tmp.childNodes );

                        // Remember the top-level container
                        tmp = fragment.firstChild;

                        // Ensure the created nodes are orphaned (#12392)
                        tmp.textContent = "";
                    }
                }

                // Remove wrapper from fragment
                fragment.textContent = "";

                i = 0;
                while ( ( elem = nodes[ i++ ] ) ) {

                    fragment.appendChild( elem )
                }

                return fragment;
            }

            var rsingleTag = ( /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i );
            var parsed:any = rsingleTag.exec( str );
            if( parsed )
                return [ context.createElement( parsed[ 1 ] ) ];

            parsed = buildFragment( [ str ], context );

            return parsed.childNodes;

        };

        var elm = [];
        if(query == undefined)
            elm = [];
        else if(typeof(query) == "string") {
            var parseable = isHTML(query);
            if(!parseable) {
                if ( element ) // Usado pelo find para procurar dentro de um elemento
                    elm = Array.prototype.slice.call( element.querySelectorAll(query));
                else
                    elm = Array.prototype.slice.call( document.querySelectorAll(query));
            }
            else
                elm = Array.prototype.slice.call( parseHTML(parseable[1], element ? element : document) );
        }
        else if (query instanceof IntermediateFormat)
            elm.push(query[0]);
        else if (query instanceof Array == true)
            elm = elm.concat(query);
        else
            elm.push(query);

        return new IntermediateFormat(query, elm, self);
    };

    showSnack(message: string) {
        var snack = document.getElementById("snackbar");
        snack.innerHTML = message;
        snack.className = "show";
        interval(9000).subscribe(n => snack.className = "");
    }

    showSnackBeforeReload(msg: string) {
        localStorage.setItem('snackMessage', msg);
    }

    showSnackAfterReload() {
        var snackMessage = localStorage.getItem('snackMessage');
        if (snackMessage!=null && snackMessage!='') {
          this.showSnack(snackMessage);
          localStorage.setItem('snackMessage', '');
        }
    }

    showSnackModal(title: string, message: string) {
        var self = this;
        document.getElementById('snack-modal-title').innerHTML = title;
        document.getElementById('snack-modal-message').innerHTML = message;
        self.$j('#snack-modal').modal('show');
        self.$j('#snack-modal').css('display', 'block');
    }

    //Clone methods
    extend = (deep: boolean, target : any, object1 : any, objectN? : any) => {
        var deepExtend = function(out, object1?, objectN?) {
            out = out || {};

            for (var i = 1; i < arguments.length; i++) {
                var obj = arguments[i];

                if (!obj)
                    continue;

                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (typeof obj[key] === 'object' &&
                            obj[key] != undefined &&
                            obj[key] instanceof Date == false){
                            if(obj[key] instanceof Array == true)
                                out[key] = obj[key].slice(0);
                            else
                                out[key] = deepExtend(out[key], obj[key]);
                        }
                        else
                            out[key] = obj[key];
                    }
                }
            }

            return out;
        };
        if(deep)
            return deepExtend(target, object1, objectN);
        else
            throw new Error('Sorry! Only Deep Clone.');
    };

    unique = (array) => {
        return array;
    };

    map = (objMap, callback) => {
        var self = this;
        var values = [];
        for (var k in objMap) {
            if( objMap.hasOwnProperty(k) ) {
                values.push( callback(objMap[k], k) );
            }
        }

        return values;
    };
}

// http://youmightnotneedjquery.com/
export class IntermediateFormat extends Array {
    selector: string;
    length: number;
    caller:BaseFunc;

    constructor(selector: string, element: any, caller: any){
        //Verifica se não é uma inicialização de array do tipo new Array(5)
        super(typeof(selector) == 'number' && !element && !caller ? selector : 0)
        if( typeof(selector) == 'number' && !element && !caller )
            return;

        this.selector = selector;
        this.caller = caller;

        var self = this;
        element.forEach(function(e,i) {
            if(e !== undefined)
                self.push(e)
        });
    }

    // #region Manipulação e busca no DOM
    find = (value) : IntermediateFormat => {
        var self = this;
        if(value instanceof HTMLElement) {
            if(value.id != '')
                return self.caller.$j('#' + value.id, self[0]);
            else
                return self.caller.$j(value.tagName + Array.from(value.attributes).map(x => '['+x.nodeName + '="' + x.nodeValue + '"]' ).join(''), self[0]);
        }
        else
            return self.caller.$j(value, self[0]);

    }

    parent = () => {
        var self = this;
        return self.caller.$j(self[0].parentNode);
    }

    children = () => {
        var self = this;
        return self.caller.$j( Array.prototype.slice.call(  this[0].children ) );
    }

    each = (callback) => {
        var self = this;
        var temp = [];
        this.forEach(function(e){
            temp.push(new IntermediateFormat('', [e], self.caller));
        });
        temp.forEach(callback);
    }

    append = (content) => {
        var self = this;
        if(content instanceof Array == true)
            content.forEach(function(element) {
                if(element instanceof IntermediateFormat || element instanceof Array)
                    element.forEach(ei => {
                        if(ei instanceof IntermediateFormat)
                            ei.forEach(ii => self[0].appendChild(ii));
                        else
                            self[0].appendChild(ei);
                    });
                else
                    self[0].appendChild(element);
            });
        else
            self[0].appendChild(content);
        return self;
    };

    prepend = (content) => {
        var self = this;
        if(content instanceof Array == true)
            content.forEach(function(element) {
                if(element instanceof IntermediateFormat || element instanceof Array)
                    element.forEach(ei => {
                        if(ei instanceof IntermediateFormat)
                            ei.forEach(ii => self[0].insertBefore(ii, self[0].firstChild) );
                        else
                            self[0].insertBefore(ei, self[0].firstChild)
                    });
                else
                    self[0].insertBefore(element, self[0].firstChild);
            });
        else
            self[0].insertBefore(content, self[0].firstChild);
        return self;
    };

    prev = (element?) => {
        var self = this;
        if(!element)
            return self.caller.$j(self[0].previousElementSibling);
    }

    after = (element) => {
        var self = this;
        if(element instanceof IntermediateFormat)
            self[0].insertAdjacentElement('afterend', element[0]);
        else
            self[0].insertAdjacentElement('afterend', element);
    }

    clone = () => {
        var self = this;
        return self.caller.$j(this[0].cloneNode(true));
    };

    appendTo = (destination) => {
        var self = this;
        self.caller.$j(destination).append(self[0]);

        return self;
    };

    remove = () => {
        if(this.length > 0)
            this[0].parentNode.removeChild(this[0]);
    };
    //#endregion

    // #region Posição
    offset = () => {
        var rect = this[0].getBoundingClientRect();

        return {
            top: rect.top + document.body.scrollTop,
            left: rect.left + document.body.scrollLeft
        };
    }

    width = () => {
        var self = this;
        return parseFloat(getComputedStyle(self[0], null).width.replace("px", ""));
    }

    height = () => {
        var self = this;
        if(self[0] != null && self[0] === self[0].window)
            return self[0].document.documentElement[ "clientHeight" ];
        else if ( self[0].nodeType === 9 )
            return Math.max(
                self[0].body[ "scrollHeight" ], self[0].documentElement[ "scrollHeight" ],
                self[0].body[ "offsetHeight" ], self[0].documentElement[ "offsetHeight" ],
                self[0].documentElement[ "clientHeight" ]
            );

        var height = getComputedStyle(self[0], null).height.replace("px", "");

        return height == 'auto' ? 0 : parseFloat(height);
    }

    outerWidth = (withMargin?) => {
        var self = this;
        function outerWidth(el) {
            var width = el.offsetWidth;
            var style = getComputedStyle(el);

            width += parseInt(style.marginLeft) + parseInt(style.marginRight);
            return width;
        }

        if(withMargin)
            return outerWidth(self[0]);
        else
            return self[0].offsetWidth;
    }

    outerHeight = (withMargin?) => {
        var self = this;
        function outerHeight(el) {
            var height = el.offsetHeight;
            var style = getComputedStyle(el);

            height += parseInt(style.marginTop) + parseInt(style.marginBottom);
            return height;
        }

        if(withMargin)
            return outerHeight(self[0]);
        else
            return self[0].offsetHeight;
    }

    scrollTop = () => {
        var self = this;
        var win;
        if(self[0] != null && self[0] === self[0].window)
            win = self[0];
        else if ( self[0].nodeType === 9 )
            win = self[0].defaultView;

        return win ? win[ 'pageYOffset' ] : self[0][ 'scrollTop' ];

    }


    // #endregion

    // #region Style
    css = (ruleName, value?) => {
        function camelize(str) {
            return str.replace(/-/g, ' ').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index === 0 ? match.toLowerCase() : match.toUpperCase();
            });
        }

        function formatValue(key, value) {
            var type = typeof value;
            var val = value;
            if(['left', 'top', 'width', 'height'].includes(key)) {
                if(type == 'number')
                    val += 'px';
                else if(type == 'string' && type.indexOf('px') == 0)
                    val += 'px';
            }

            return val;
        }

        if(typeof(ruleName) == 'object') {
            for (var r in ruleName) {
                if( ruleName.hasOwnProperty(r) )
                    this[0].style[ camelize(r) ] = formatValue( r, ruleName[r]);
            }
        }
        else {
            if(value != undefined) {
                this[0].style[ camelize(ruleName) ] = formatValue(ruleName, value);
            }
            else
                return getComputedStyle(this[0])[ruleName];
        }


    }
    // #endregion

    //#region UI Manipulation
    modal = (mode) => {
      var self = this;
      if(mode == 'show') {
        //this[0].style = 'display: block;padding-right: 15px;'
        if(!self.hasClass('show')) {
          self.addClass('show');
          self.attr('aria-modal', 'true');
          self.removeAttr('aria-hidden');
          self.attr('style', 'display: block;padding-right: 15px;');
          self.caller.$j(document.body).append(
            self.caller.$j('<div class="modal-backdrop fade show"></div>')
          );
        }
      }
      else {
        if(self.hasClass('show')) {
          self.removeClass('show');
          self.attr('aria-hidden', 'true');
          self.removeAttr('aria-modal');
          self.attr('style', 'display: none;');
          self.caller.$j('.modal-backdrop').forEach(x => self.caller.$j(x).remove() );
        }
      }
    }
    //#endregion

    // #region Animation and Effect
    animate = (conf: any, duration: number, callback?: Function) => {
        var self = this;
        var ms_update = 10;
        var offset = self.caller.$j(self[0]).offset();
        var steps = Math.trunc(duration / ms_update);
        var step_left = conf.left ? (conf.left - offset.left) / steps : null;
        var step_top = conf.top ? (conf.top - offset.top) / steps : null;
        var step_scrollTop = conf.scrollTop ? ( conf.scrollTop - self[0].scrollTop ) / steps : null;
        var step_scrollLeft = conf.scrollLeft ? ( conf.scrollLeft - self[0].scrollLeft ) / steps : null;
        var cur_step = 0;
        var ct = setInterval(function() {
            var cur_pos = self.caller.$j(self[0]).offset();
            if(step_left)
                self.caller.$j(self[0]).css('left', ( cur_pos.left + step_left ) + 'px');
            if(step_top)
                self.caller.$j(self[0]).css('top', ( cur_pos.top + step_top ) + 'px');
            if(step_scrollTop)
                self[0].step_scrollTop += step_scrollTop;
            if(step_scrollLeft)
                self[0].scrollLeft += step_scrollLeft;
            if(cur_step > steps){
                clearInterval(ct);
                if(callback)
                    callback();
            }
            else
                cur_step++;
        }, ms_update);
    };
    //#endregion

    // #region Values
    val = (value?) => {
        var self = this;
        var default_value = undefined;
        if(self.length ==0 )
            return default_value;

        if( value != undefined ) { //Atribui o valor de cada elemento correspondido
            self.forEach(function(e,i) {
                if (e.tagName == 'SELECT') {
                    var optionSet = false;
                    var values = ( value instanceof Array == true ? value : [value] );
                    Array.from(e.options).forEach(function(option: HTMLOptionElement) {
                        option.selected = values.includes( option.value );
                        if (option.selected)
                            optionSet = true;
                    });
                    // Force browsers to behave consistently when non-matching value is set
                    if ( !optionSet )
                        e.selectedIndex = -1;
                }
                else if(e.tagName == 'INPUT' || e.tagName == 'TEXTAREA')
                    return e.value = value;
            });
        }
        else { //Obtem o valor atual do primeiro elemento no conjunto de elementos correspondidos
            if(this[0].tagName == 'NG-SELECT')
                return self.caller.getComponent(this[0]).itemsList.selectedItems.map(function(e) {
                    return e.value.id || e.value;
                });
            else if(this[0].tagName == 'SELECT' && this[0].multiple)
                return Array.prototype.slice.call( this[0].options ).filter(x => x.selected ).map(x => x.value);
            else if(this[0].tagName == 'INPUT' || this[0].tagName == 'SELECT' || this[0].tagName == 'TEXTAREA')
                return this[0].value;
        }
    };

    html = (value?) => {
        var self = this;
        if(value != undefined) {
            if( ( typeof(value) == "string" && value.trim() != '' ) || ( typeof(value) == "number" ))
                self[0].innerHTML = value;
            else if(value instanceof IntermediateFormat)
                value.forEach(function(e) {
                    self[0].appendChild(e)
                });
            else
                self.empty();
        }
        else
            return self[0].innerHTML;
    };

    text = (value?) => {
        var self = this;
        var default_value = undefined;
        if(self.length ==0 )
            return default_value;

        if( value ) { //Atribui o valor de cada elemento correspondido
            self.forEach(function(e,i) {
                if(e.tagName == 'INPUT')
                    return e.textContent = value;
            });
        }
        else { //Obtem o valor atual do primeiro elemento no conjunto de elementos correspondidos
            if(this[0].tagName == 'NG-SELECT')
                return self.caller.getComponent( this[0] ).itemsList.selectedItems.map(function(e) {
                    return e.value.id;
                });
            else if(this[0].tagName == 'INPUT' || this[0].tagName == 'SELECT')
                return this[0].textContent;
            else
                return this[0].textContent
        }
    };

    selectData = () => {
        var self = this;
        return self.caller.getComponent(self[0]).itemsList.selectedItems;
    };

    replaceWith = (content) => {
        if(typeof(content) == 'string')
            this[0].outerHTML = content;
        else
            this[0].parentNode.replaceChild(content, this[0]);

    };

    empty = () => {
        if(this.length > 0)
            while(this[0].firstChild)
                this[0].removeChild(this[0].firstChild);
    };
    //#endregion

    // #region Attr
    attr = (key, value?) : any => {
        var self = this;
        if(value != undefined) {
            self.forEach(function(e) {
                e.setAttribute(key, value);
            });
            return;
        }
        else
            return self[0].getAttribute(key);
    };

    removeAttr = (value) => {
        var self = this;
        self[0].removeAttribute(value);
    };

    prop = (key, value?) : any => {
        var self = this;
        if(self[0] == undefined)
            return;

        if(value != undefined) {
            if(self[0][key] != undefined)
                self[0][key] = value;
        }
        else
            return self[0][key];
    }
    //#endregion

    // #region Manipulate class
    addClass = (className) => {
        var self = this;
        self.forEach(function(el) {
            el.classList.add(className);
        });
    }

    removeClass = (className) => {
        var self = this;
        self.forEach(function(el) {
            el.classList.remove(className);
        });
    }
    // #endregion

    // #region Testes Boleanos -- isElement, hasClass, ...
    is = (value) => {
        var self = this;
        var isElementVisible = function(element) {
            if (element.offsetWidth || element.offsetHeight || element.getClientRects().length)
                return true;
            else
                return false;
        };
        var matches = function(el, selector) {
            return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
        };

        if( value == ':visible' )
            return isElementVisible(self[0]);
        else
            return matches(self[0], value);
    };

    hasClass = (value) => {
        if(this.length == 0)
            return false;

        return this[0].classList.contains(value);
    };

    not = (selector) : any[] => {
        var self = this;
        var raw_self = [...self];
        var result = [];
        if(selector == undefined)
            result = raw_self;
        else if(typeof(selector) == 'string' && selector[0] == '#')
            result = self.filter(x => x.id != selector );
        else if (selector instanceof IntermediateFormat)
            result = self.filter(x => ![...selector].includes(x) );
        else if (selector instanceof Array == true)
            result = self.filter(x => !selector.includes(x) );

        return self.caller.$j(result);
    }
    //#endregion

    // #region Hide and Show Methods
    hide  = () => {
        this.forEach(function(x) {
            x.style.display = "none";
        });
    };

    show  = () => {
        this.forEach(function(x) {
            x.style.display = "";
        });
    };

    toggle  = () => {
        this.forEach(function(x) {
            if (x.style.display === "none")
                x.style.display = "";
            else
                x.style.display = "none";
        });
    };
    // #endregion

    // #region Events

    ready = (callback) => {
        var loadDoc = this[0];
        if (loadDoc.readyState != 'loading')
            callback();
        else
            loadDoc.addEventListener('DOMContentLoaded', callback);

    }

    //Form Events
    change = (callback) => {
        this.bind('change', callback);
        return this;
    };

    blur = (callback) => {
        this.bind('blur', callback);
        return this;
    };

    focus = (callback) => {
        this.bind('focus', callback);
        return this;
    };

    submit = (callback) => {
        var self = this;
        if(!callback){
            self[0].submit();
            return self;
        }
        else
            this.bind('submit', callback);
        return this;
    };

    //Keyboard Events
    keydown = (callback) => {
        this.bind('keydown', callback);
        return this;
    };

    keypress = (callback) => {
        this.bind('keypress', callback);
        return this;
    };

    keyup = (callback) => {
        this.bind('keyup', callback);
        return this;
    };

    //Mouse Events
    click = (callback) => {
        this.bind('click', callback);
        return this;
    };

    mouseup = (callback) => {
        this.bind('mouseup', callback);
        return this;
    }

    mousedown = (callback) => {
        this.bind('mousedown', callback);
        return this;
    };

    mousemove = (callback) => {
        this.bind('mousemove', callback);
        return this;
    };

    trigger = (eventName) => {
        var event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, false);
        this[0].dispatchEvent(event);
    };

    buildGlobalID = (element) => {
        var self = this;
        var cur_el = element;
        var global_id = [];
        var current_id = '';
        do {
            if ( cur_el.nodeName == '#document' )
                current_id = cur_el.nodeName;
            else if( self.caller.$j(cur_el).attr('id') != null )
                current_id = '#'+ self.caller.$j(cur_el).attr('id');
            else
                current_id = cur_el.tagName + Array.prototype.slice.call( cur_el.attributes ).map(x=> '[' + x.name + '="' + self.caller.$j(cur_el).attr(x.name) + '"]' ).join('');

            global_id.push(current_id);
            cur_el = self.caller.$j(cur_el).parent()[0];
            //Caso em que o objeto ainda não foi adicionado ao DOM principal da página existindo somente como um objeto em memória
            if( current_id == '#document' )
                return '#document';
            if ( cur_el == undefined )
                return '#document-fragment';
            if( cur_el != undefined  && cur_el.nodeName == '#document-fragment' && current_id.indexOf('#') == -1)
                return '#document-fragment';
        } while( current_id.indexOf('#') == -1);

        return global_id.reverse().join(' ');
    };

    checkPendent = () => {
        var self = this;
        window.pendentMap.forEach( (el, ix) => {
            var curr = self.caller.$j('#' + el[0]);
            if( curr.length > 0 ) {
                curr.attr('distingsh', curr.attr('id'))
                curr.removeAttr('id');
                curr.bind(el[1], el[2]);
                window.pendentMap[ix] = 'remove';
            }
        });
        window.pendentMap = window.pendentMap.filter(x => x != 'remove');
        if( window.pendentMap.length > 0 )
            setTimeout(self.checkPendent, 500);
    };

    bind = (eventName, eventHandler) => {
        var self = this;
        self.forEach(function(e) {
            var globalID = self.buildGlobalID(e);
            if(globalID == '#document-fragment') {
                var generated = 'c_' + 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                    return v.toString(16);
                });
                if(window.pendentMap.length == 0) {
                    window.pendentMap.push([generated, eventName, eventHandler]);
                    self.checkPendent()
                }
                else
                    window.pendentMap.push([generated, eventName, eventHandler]);


                self[0].setAttribute('id', generated);
            }
            else {
                if(!window.eventMap[globalID])
                    window.eventMap[globalID] = {};
                if(!window.eventMap[globalID][eventName])
                    window.eventMap[globalID][eventName] = [];

                window.eventMap[globalID][eventName].push(eventHandler);
                // var destination = self.caller.$j( globalID != '#document' ? globalID : document );
                // if(destination.length > 0)
                //     destination[0].addEventListener(eventName, eventHandler);
                e.addEventListener(eventName, eventHandler);
            }
        });
    };

    unbind = (eventName?, eventHandler?) => {
        var implemented = ['change', 'blur', 'focus', 'submit', 'keydown', 'keypress', 'keyup', 'click', 'mousedown', 'mouseup'];
        var self = this;

        if(self.length == 0)
            return;

        var globalID = self.buildGlobalID(self[0]);
        if(!eventName && !eventHandler) {
            implemented.forEach(function(element) {
                if (window.eventMap[globalID] && window.eventMap[globalID][element])
                    window.eventMap[globalID][element].forEach(function(e_internal) {
                        self[0].removeEventListener(element, e_internal);
                    });
            });
            window.eventMap[globalID] = {};
        }
        else if(eventName && !eventHandler){
            if(window.eventMap[globalID])
                if (window.eventMap[globalID] && window.eventMap[globalID][eventName])
                    window.eventMap[globalID][eventName].forEach(function(e_internal) {
                        self[0].removeEventListener(eventName, e_internal);
                    });
            //window.eventMap[globalID][eventName] = [];
        }
        else if(eventName && eventHandler){
            self[0].removeEventListener(eventName, eventHandler);
            if(window.eventMap[globalID] && window.eventMap[globalID][eventName]) {
                var index = window.eventMap[globalID][eventName].indexOf(eventHandler);
                if(index != -1)
                    window.eventMap[globalID][eventName].splice(index, 1);
            }

        }

        return self;
    }

    // #endregion
}

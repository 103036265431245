import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-customize',
  templateUrl: './equipment-container.component.html',
  styleUrls: ['./equipment-container.component.css'],
})
export class EquipmentContainerComponent implements OnInit, DoCheck {
  selectedIndex: number = 0;

  items: any[] = [
    { 
      id: 0, 
      label: 'Tipo', 
      alias: 'Tipo',
      permission: 'view_equipmenttype', 
      available: false 
    },
    {
      id: 1,
      label: 'Natureza',
      alias: 'Natureza',
      permission: 'view_equipmentnature',
      available: false,
    },
    { 
      id: 2, 
      alias: 'Natureza',
      label: 'IoT', 
      permission: 'view_equipment', 
      available: false 
    },
    { 
      id: 3, 
      alias: 'Natureza',
      label: 'Imagem', 
      permission: 'view_equipmentimage', 
      available: false 
    },
    { 
      id: 4, 
      alias: 'Natureza',
      label: 'Agrupador', 
      permission: '', 
      available: true 
    },
  ];

  constructor(public authService: AuthService, private router: Router, public layout: LayoutService) {}

  ngOnInit(): void {
    this.onVerifyPermissions();
    this.onSetFirstAvailableTab();
  }

  ngDoCheck(): void {
    this.items.map((item) => {
      item.alias = this.layout.aliasTitle(item.label);
    });
  }

  onVerifyPermissions() {
    const permissions = JSON.parse(this.authService.getPermissions()) as any[];
    this.items.map((rp) =>
      permissions.some((pe) => {
        if (rp.permission == pe.codename) {
          rp.available = true;
        }
      }),
    );
  }

  onSetFirstAvailableTab() {
    const availableItems = this.items.filter((item) => item.available == true);

    if (availableItems.length == 0) {
      this.router.navigate(['/403']);
    }

    if (availableItems.length > 0) {
      this.setTabIndex(availableItems[0].id);
    }
  }

  setTabIndex(index: number) {
    this.selectedIndex = index;
  }
}

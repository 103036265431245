import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }
  httpOptionsUpload = {
    headers: new HttpHeaders({
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }
  params = new HttpParams();
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getCompanyGroupAll(url: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  getCompanyDivisionAll(url: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  getCompanyAll(url: string): Observable<any> {

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  deleteCompanyGroup(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'companygroup/' + id + '/', this.httpOptions);
  }

  getCompanyGroupId(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'companygroup/' + id + '/', this.httpOptions);
  }

  createCompanyGroup(group): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'companygroup/', group, this.httpOptions);
  }

  updateCompanyGroup(group): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'companygroup/' + group.company_group_id + '/', group, this.httpOptions);
  }

  getCompanyDivisionId(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'companydivision/' + id + '/', this.httpOptions);
  }

  createCompanyDivision(division): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'companydivision/', division, this.httpOptions);
  }

  updateCompanyDivision(division): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'companydivision/' + division.company_division_id + '/', division, this.httpOptions);
  }

  deleteCompanyDivision(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'companydivision/' + id + '/', this.httpOptions);
  }

  createCompanyLogoUpload(formData, id): Observable<any> {
    let url = this.authService.endpoint_main + 'company/logoupload/' + id;
    return this.http.post(url, formData, this.httpOptionsUpload);
  }

  createCompany(company): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'company/create', company, this.httpOptions);
  }

  updateCompany(company): Observable<any> {

    return this.http.put(this.authService.endpoint_main + 'company/update/' + company.id, company, this.httpOptions);
  }

  deleteCompany(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'company/delete/' + id, this.httpOptions);
  }

  getCompany(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'company/detail/' + id, this.httpOptions);
  }

}

import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UsersService } from './../users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { BootstrapModalConfirmService } from '../../shared/bootstrap-modal-confirm/bootstrap-modal-confirm.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { UsersProfilesService } from './../../users-profiles/users-profiles.service';
import { take, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { User } from './../user.model';
import { Group } from '../../users-groups/group.model';
import { fade } from 'src/app/core/animations/fade.animation';


@Component({
  selector: 'app-users-read',
  templateUrl: './users-read.component.html',
  styleUrls: ['./users-read.component.css'],
  animations: [
    fade
  ]
})
export class UsersReadComponent implements OnInit, AfterViewInit {

  users: User[] = [];
  usersFiltered: User[] = [];
  next: string;
  previous: string;
  isChecked: boolean = false;
  itemChecked: boolean[];
  selectedUsers: any[];
  allProfiles: any;
  allGroups: Group[];

  userForm: FormGroup;
  profile: string[];

  deleteModalRef: BsModalRef;
  @ViewChild('deleteModal') deleteModal;
  selectedItem;
  modalRef?: BsModalRef;

  private urlUsers = this.authService.endpoint + '/users';
  isLoadingUsers: boolean = false;

  selectedFilter: string;
  isOptionsOpened: boolean = false;
  search: FormGroup;

  offset: number = 0;
  limit: number = 5;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'first_name', type: 'text', value: null },
    { label: 'Email', field: 'email', type: 'text', value: null },
    { label: 'Perfil', field: 'group.name', type: 'text', value: null },
    { label: 'Ativo', field: 'is_active', type: 'boolean', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }


  get isHorizontal() {
    return window['whiteLabelConf']('horizontal');
  }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private modalService: BsModalService,
    private modalAlertService: BootstrapModalAlertService,
    private usersProfilesService: UsersProfilesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
    // check if user is enable
    this.authService.isEnable();

    this.initializeForm();

    // check permission
    var is_mdb = this.authService && (this.authService.getProfile()?.indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1))

    var is_terceirizada = this.authService && (this.authService.getProfile()?.indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1))

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('view_user') == false) {
      this.router.navigate(['/403']);
    };

    this.selectedUsers = new Array<any>();
    this.itemChecked = new Array<boolean>();

    this.readAll(this.urlUsers)
  }

  ngAfterViewInit(): void {

    // all profiles
    this.usersProfilesService.getUsersProfiles(this.authService.endpoint + '/profiles/nopage', this.authService.getPerson()).pipe(take(1)).subscribe(
      data => {
        this.allProfiles = data;
      },
      error => {
        this.modalAlertService.showAlertDanger('Não foi possível listar os perfis');
      }
    )
  }
  readAll(url: string) {
    if (this.authService.hasPerson()) {
      let person = this.authService.getPerson();

      this.isLoadingUsers = true;
      this.usersService.getUsers(url, person).pipe(take(1)).subscribe(
        data => {
          this.users = data.results;
          this.usersFiltered = data.results;

          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          this.modalAlertService.showAlertDanger('Não foi possível listar usuários.');
          console.log('Falha ao buscar usuários:');
          console.log(error)
        }
      ).add(() => {
        this.isLoadingUsers = false;
      });
    } else {
      this.authService.doLogout();
    }
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onFilterBoolean(value: boolean) {
    const filter = this.filters.filter((el) => el.label == 'Ativo')[0]
    filter.value = value;

    this.usersFiltered = this.users.filter((el) => el[filter.field] == value);
  }

  onShowFilters(field: string) {
    this.selectedFilter = field;
    this.isOptionsOpened = !this.isOptionsOpened;
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.usersFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onClearFilters() {
    this.usersFiltered = this.users;
  }

  onSearch(value: string) {
    const { field } = this.filters.filter((el) => el.label == this.selectedFilter)[0]
    if(value == '') {
      this.usersFiltered = this.users;
      return;
    }
    this.usersFiltered = this.usersFiltered.filter((el: any) => el[field].toLowerCase().includes(value))
  }


  // function fetches the next paginated items by using the url in the next property
  fetchNext() {
    this.readAll(this.next);
  }
  // function fetches the previous paginated items by using the url in the previous property
  fetchPrevious() {
    this.readAll(this.previous);
  }

  readId = (id) => {
    this.router.navigate(['/users/update', id]);
  }

  create = () => {
    this.router.navigate(['/users/create']);
  }

  onChange = ($event, item, i) => {
    this.isChecked = $event.target.checked;
    this.itemChecked[i] = this.isChecked;

    if (this.isChecked) {
      this.selectedUsers.push(item);
      return;
    }
    this.selectedUsers = this.selectedUsers.filter((el: any) => {
      return el.id !== item.id
    })
  }

  onEditChange = ($event, item, i) => {

    if (item.is_active == true) {

      $event.target.checked = false;
      item.is_active = false;

    }

    else {
      $event.target.checked = true;
      item.is_active = true;
    }


  }

  onEditMultiples() {
    this.selectedUsers.map((user: any) => {
      const data = { ...user, groups: user.groups.map((group: any) =>  group.id )}
      this.update(data)
    })
  }

  update = (user) => {
    if (this.authService.hasPerson() && this.authService.hasToken()) {

      for (let i = 0; i < this.allProfiles.length; i++) {
        if (this.allProfiles[i].id == user.profile) {
          user.profile = this.allProfiles[i];
        }
      }
      this.usersService.updateUser(user).pipe(take(1)).subscribe(
        data => {
          // return to list
          this.router.navigate(['/users']);
          this.modalAlertService.showAlertSuccess('Registro(s) incluído(s) com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao incluir o(s) registro(s)');
        }
      )
    } else {
      this.modalAlertService.showAlertDanger('Erro ao incluir o(s) registro(s)');
      this.authService.clearAll();
      this.authService.doLogout();
    }
  }

  onDeleteMultiples = () => {

    var itens = this.selectedUsers;

    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir estes usuários?'
    );

    for (let i = 0; i < itens.length; i++) {

      this.selectedItem = itens[i]

      result$.asObservable()
        .pipe(
          take(1),
          switchMap(result => result ? this.usersService.deleteUser(itens[i].id) : EMPTY)
        )
        .subscribe(
          success => {
            let index;
            this.users.forEach((e, i) => {
              if (e.id === itens[i].id) {
                index = i;
              }
            });
            this.users.splice(index, 1);
            this.modalAlertService.showAlertSuccess('Registros removido com sucesso.');
          },
          error => {
            this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
          }
        );
    }

  }

  onDelete = (item) => {
    this.selectedItem = item;
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este usuário?'
    );
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.usersService.deleteUser(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          let index;
          this.users.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.users.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

  /**
   *
   * @param user Recebe o usuário que irá ser inativado.
   * @returns Exibe uma mensagem indicando que o usuário foi inativado em caso de sucesso.
   * Se o usuário já esteja inativado, informa ao usuário.
   */
  inactiveUser(user) {
    const userIndex = this.users.findIndex(u => u.id === user.id);

    if (userIndex === -1) {
      console.error('Usuário não encontrado.');
      return;
    }

    const userFound = { ...this.users[userIndex] };
    const nameUserSelected = `${userFound.first_name} ${userFound.last_name}`;

    if (!userFound.is_active) {
      this.modalAlertService.showAlertDanger(`${nameUserSelected} já se encontra inativo.`);
      return;
    }

    this.modalAlertService.showConfirm(
      'Confirmação',
      `Confirma a intenção de inativar o usuário correspondente ao ${nameUserSelected}?`
    ).asObservable()
      .pipe(
        take(1),
        switchMap(result => {
          if (result) {
            // Faça as alterações somente após a confirmação
            userFound.is_active = false;
            userFound.groups = userFound.groups.map(g => g.id);

            return this.usersService.updateUser(userFound);
          }
          return EMPTY;
        })
      )
      .subscribe(
        success => {
          // Atualize o array de usuários após a confirmação e sucesso da operação

          this.readAll(this.urlUsers)
          this.modalAlertService.showAlertSuccess(`Usuário ${nameUserSelected} inativado com sucesso.`);
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar inativar.');
        }
      );
  }

   /**
     * Inicializa o formulário reativo.
     */
   private initializeForm(): void {
    this.userForm = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      email: [''],
      groups: [[]],
      phone_number: [''],
      app_id: [""],
      person: [""],
      profile: [""],
      avatar: [""],
      is_active: false,
      id: []
    });
  }

  getUserDetails(Id: number) {
    this.usersService.detailUser(Id).pipe(take(1)).subscribe(
      data => {
        console.log(data);
        this.userForm.get('id').setValue(data.id);
        this.userForm.get('app_id').setValue(data.app_id);
        this.userForm.get('email').setValue(data.email);
        this.userForm.get('person').setValue(data.person);
        this.userForm.get('first_name').setValue(data.first_name);
        this.userForm.get('last_name').setValue(data.last_name);
        this.userForm.get('is_active').setValue(data.is_active);
        this.userForm.get('phone_number').setValue(data.phone_number);
        this.userForm.get('groups').setValue(data.groups.map(g => g.id));
        this.allGroups = data.groups;
      },
      error => {
        console.error(error.error);
        this.modalAlertService.showAlertDanger('Não foi possível recuperar usuário.');
      }
    )
  }

  openModalWithClass(template: TemplateRef<void>,user) {
    this.getUserDetails(user.id);
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from '../../../../../auth/auth.service';
import { CompanyGroup } from '../../../shared/model/company-group.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para atualização de uma unidade empresarial.
 */
@Component({
  selector: 'app-company-division-update',
  templateUrl: './company-division-update.component.html',
  styleUrls: ['./company-division-update.component.css'],
})
export class CompanyDivisionUpdateComponent implements OnInit {
  /** Item selecionado para atualização. */
  selectedItem = { id: '', name: '', active: true, app_id: '', company_group_id: '' };

  /** Lista de grupos empresariais. */
  groups: CompanyGroup[];

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private route: ActivatedRoute,
    private modalAlertService: BootstrapModalAlertService,
    private companyService: CompanyService,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService,
  ) {}

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e obtém os detalhes da divisão de empresa.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('change_companydivision') == false) {
      this.router.navigate(['/403']);
    }

    // Obtém o ID da divisão de empresa da rota ativa e carrega os detalhes da divisão.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Obtém a lista de grupos empresariais.
    this.companyService
      .getCompanyGroupAll(this.authService.endpoint_main + 'companygroups/nopage')
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.groups = data;
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível listar grupos empresariais');
        },
      );
  }

  /**
   * Obtém os detalhes da divisão de empresa com base no ID fornecido.
   *
   * @param id ID da divisão de empresa.
   */
  read = (id) => {
    this.companyService
      .getCompanyDivisionId(id)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.selectedItem = data;
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Não foi possível recuperar unidade empresarial');
        },
      );
  };

  /**
   * Atualiza os detalhes da divisão de empresa.
   */
  update = () => {
    this.isLoading = true;
    this.companyService
      .updateCompanyDivision(this.selectedItem)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.selectedItem = data;
          this.modalAlertService.showAlertSuccess('Registro atualizado com sucesso');
        },
        (error) => {
          this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
        },
      )
      .add(() => {
        scrollTo(0, 0);
        this.isLoading = false;
      });
  };
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { ColorPickerModule } from 'ngx-color-picker';

import {
  NgModule,
  LOCALE_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MultipleSelectComponent } from './components/shared/multiple-select/multiple-select.component';
import { MultipleSelectUpdateComponent } from './components/shared/multiple-select-update/multiple-select-update.component';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgIdleModule } from '@ng-idle/core';
// import { MomentModule } from 'angular2-moment';

import { DateTimePickerModule } from 'ngx-datetime-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CompanyComponent } from './components/company/components/company/company.component';
import { CompanyModule } from './components/company/company.module';

import { PersonsPerfilComponent } from './components/persons-perfil/persons-perfil.component';
import { PersonsPerfilCreateComponent } from './components/persons-perfil/persons-perfil-create/persons-perfil-create.component';
import { PersonsPerfilReadComponent } from './components/persons-perfil/persons-perfil-read/persons-perfil-read.component';
import { PersonsPerfilUpdateComponent } from './components/persons-perfil/persons-perfil-update/persons-perfil-update.component';

import { PersonReadComponent } from './components/person/person-read/person-read.component';
import { PersonCreateComponent } from './components/person/person-create/person-create.component';
import { PersonUpdateComponent } from './components/person/person-update/person-update.component';

import { FielderrordisplayComponent } from './components/fielderrordisplay/fielderrordisplay.component';

import { NavComponent } from './components/shared/nav/nav.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { MainlayoutComponent } from './views/mainlayout/mainlayout.component';
import { AuthModule } from './auth/auth.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DealershipComponent } from './components/dealership/dealership.component';
import { DealershipReadComponent } from './components/dealership/dealership-read/dealership-read.component';
import { DealershipCreateComponent } from './components/dealership/dealership-create/dealership-create.component';
import { DealershipUpdateComponent } from './components/dealership/dealership-update/dealership-update.component';

import { PlantComponent } from './components/plant/plant.component';
import { PlantModule } from './components/plant/plant.module';

import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BootstrapModalConfirmComponent } from './components/shared/bootstrap-modal-confirm/bootstrap-modal-confirm.component';
import { SharedModule } from './components/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReportsComponent } from './components/reports/reports.component';
import { CrudsComponent } from './components/cruds/cruds.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportsModule } from './components/reports/reports.module';
import { CrudsModule } from './components/cruds/cruds.module';
import { NewsletterModule } from './components/newsletter/newsletter.module';

import { UsersCreateComponent } from './components/users/users-create/users-create.component';
import { UsersReadComponent } from './components/users/users-read/users-read.component';
import { UsersUpdateComponent } from './components/users/users-update/users-update.component';

import { UsersGroupsCreateComponent } from './components/users-groups/users-groups-create/users-groups-create.component';
import { UsersGroupsReadComponent } from './components/users-groups/users-groups-read/users-groups-read.component';
import { UsersGroupsUpdateComponent } from './components/users-groups/users-groups-update/users-groups-update.component';

import { UsersProfilesComponent } from './components/users-profiles/users-profiles.component';
import { UsersProfilesCreateComponent } from './components/users-profiles/users-profiles-create/users-profiles-create.component';
import { UsersProfilesReadComponent } from './components/users-profiles/users-profiles-read/users-profiles-read.component';
import { UsersProfilesUpdateComponent } from './components/users-profiles/users-profiles-update/users-profiles-update.component';

import { DataService } from './components/shared/data.service';
import { ReportContainerComponent } from './components/reports/report-container/report-container.component';
import { CustomizeContainerComponent } from './components/customize-container/customize-container.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

//AzureAd
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';

// Cadastros

import { PersonContainerComponent } from './components/customize-container/person-container/person-container.component';
import { PlantContainerComponent } from './components/customize-container/plant-container/plant-container.component';
import { DealershipContainerComponent } from './components/customize-container/dealership-container/dealership-container.component';
import { UserContainerComponent } from './components/customize-container/user-container/user-container.component';
import { CompanyContainerComponent } from './components/customize-container/company-container/company-container.component';
import { EquipmentContainerComponent } from './components/customize-container/equipment-container/equipment-container.component';
import { PubsubComponent } from './components/pubsub/pubsub.component';
import { StatusComponent } from './components/status/status.component';
// import { CoreModule } from './core/core.module';
import { UploadPanelComponent } from './components/upload-panel/upload-panel.component';
import { InactivityService } from './components/shared/services/inactivity-service.service';
import { TreeviewModule } from 'ngx-treeview';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TruncateTextPipe } from './core/custom-pipes/truncate-text/truncate-text.pipe';
import { TransformDbNamePipe } from './core/custom-pipes/transform-db-name.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrganizationLevelCardComponent } from './components/shared/organization-level-card/organization-level-card.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EditSupervisoryPageComponent } from './components/supervisory/pages/edit-supervisory-page/edit-supervisory-page.component'
import { PersonModule } from './components/person/person.module';


// tslint:disable-next-line:typedef
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '8ed9f718-80ec-4de6-8b67-a7902fc0d362',
      redirectUri: 'https://localhost:4200',
      postLogoutRedirectUri: 'https://localhost:4200',
      authority: 'https://login.microsoftonline.com/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  });
}

registerLocaleData(localePt);

@NgModule({
  declarations: [
    CompanyContainerComponent,
    CompanyComponent,
    AppComponent,

    PersonsPerfilComponent,
    PersonsPerfilCreateComponent,
    PersonsPerfilReadComponent,
    PersonsPerfilUpdateComponent,

    PersonContainerComponent,

    NavComponent,
    DashboardComponent,
    MainlayoutComponent,
    HeaderComponent,
    FooterComponent,

    BootstrapModalConfirmComponent,
    ReportsComponent,
    CrudsComponent,

    UserContainerComponent,
    UsersCreateComponent,
    UsersReadComponent,
    UsersUpdateComponent,

    UsersGroupsCreateComponent,
    UsersGroupsReadComponent,
    UsersGroupsUpdateComponent,

    UsersProfilesComponent,
    UsersProfilesCreateComponent,
    UsersProfilesReadComponent,
    UsersProfilesUpdateComponent,

    DashboardComponent,
    MainlayoutComponent,
    CustomizeContainerComponent,
    ReportContainerComponent,

    DealershipContainerComponent,
    DealershipComponent,
    DealershipReadComponent,
    DealershipCreateComponent,
    DealershipUpdateComponent,

    FielderrordisplayComponent,

    PlantComponent,
    PlantContainerComponent,
    EquipmentContainerComponent,
    PubsubComponent,
    StatusComponent,
    UploadPanelComponent,
    TruncateTextPipe,
    TransformDbNamePipe,
    OrganizationLevelCardComponent,
    EditSupervisoryPageComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    CompanyModule,
    PlantModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    NgxSliderModule,
    ReportsModule,
    CrudsModule,
    NewsletterModule,
    MsalModule,
    AuthModule,
    DateTimePickerModule,
    NgIdleModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    // MomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    NgxSliderModule,
    NewsletterModule,
    AuthModule,
    TreeviewModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    // CoreModule,
    GuidedTourModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    ColorPickerModule,
    PersonModule
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({
      //   showDialog: true,
      // }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      // deps: [Sentry.TraceService],
      multi: true,
    },
    MsalService,
    MultipleSelectComponent,
    DataService,
    BsModalRef,
    InactivityService,
    GuidedTourService,
    
  ],

  exports: [FooterComponent, FielderrordisplayComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="row mx-5">
  <div class="col-12 col-md-6">
    <div class="d-flex h-100 justify-content-end align-items-center">
      <img src="assets/img/403.png" alt="" />
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="d-flex justify-content-center align-items-start flex-column px-5 h-100">
      <h1>403</h1>
      <h3>Acesso negado</h3>
      <span>Parece que você não tem acesso a essa página.</span>
      <button class="btn btn-primary mt-3 mx-0" routerLink="/">Ir para início</button>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center">
  <span class="text-center">Copyright &copy; IndustryCare 2020</span>
</div>

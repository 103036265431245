<ul class="nav nav-pills m-t-30 m-b-30">

  <li class="nav-item" (click)="setTabIndex(i)" *ngFor="let item of items; index as i">
    <a href="#navpills-{{ i }}" class="nav-link" [class.active]="selectedIndex == i" data-toggle="tab" aria-expanded="false" *ngIf="item.available">{{ item.label }}</a>
  </li>
</ul>


<div class="tab-content br-n pn" [ngClass]="{ 'bg-white': isHorizontal }">
  <div id="navpills-0" *ngIf="selectedIndex == 0">
    <app-users-groups-read>
    </app-users-groups-read>
  </div>
  <div id="navpills-1" *ngIf="selectedIndex == 1">
    <app-users-read>
    </app-users-read>
  </div>
</div>

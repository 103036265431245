import { Component, OnInit, ElementRef, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { EquipmentType } from './../../plant.model';
import { take } from 'rxjs/operators';
import { Global } from 'src/app/components/shared/base-func/global';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IcSelectComponent } from 'src/app/components/shared/ic-select/ic-select.component';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-alarm-update',
  templateUrl: './alarm-update.component.html',
  styleUrls: ['./alarm-update.component.css']
})
export class AlarmUpdateComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  @ViewChild('selectref', { static: true }) selectref;
  selectedAlarm ={};
  global: Global = new Global();


  text = 'DD:HH:mm:ss';
  day: string;
  hour: string;
  minute: string;
  seconds: string;

  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  is_outlier: boolean = true;

  ngOnInit(): void {
    // check if user is enable

    this.authService.isEnable();

    // check permission
    if (this.authService.checkPermission('change_equipment') == false) {
      this.router.navigate(['/403']);
    };

    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });



  }

  read = (id) => {

    this.plantService.getAlarm(id).pipe(take(1)).subscribe(
      data => {
        this.form.get('id').setValue(data.id);
        this.form.get('is_outlier').setValue(data.is_outlier);
          this.form.get('alert_name').setValue(data.alert_name);
        this.form.get('value_min').setValue(data.value_min);
        this.form.get('value_max').setValue(data.value_max);
        this.form.get('plant_equipment').setValue(data.plant_equipment.plant_equipment_id);
        this.selectedAlarm = {'id':data.plant_equipment.plant_equipment_id, 'name':data.plant_equipment.name}
        var map ={};
        this.selectref.itemListService.getItemList(this.selectref.key, null, map).pipe(take(1)).subscribe(
          data => {
            this.selectref.itemlist = [];
            for (var r in data.results) {
              if (data.results.hasOwnProperty( r ))
                this.selectref.itemlist.push({ id: data.results[r].id, name: data.results[r].text  });
            }
            this.selectref.global.addToSelect2(this.selectref.selectref, this.selectedAlarm['id'], this.selectedAlarm['name']);
          },
          error => {
            console.log(error);
          }
        );
        this.form.get('is_bot').setValue(data.is_bot);
        this.form.get('is_mail').setValue(data.is_mail);
        this.form.get('is_notify').setValue(data.is_notify);

        this.form.get('interval').setValue(data.interval);
      }

      , error => {
      }
    );




    this.form = this.formBuilder.group({
      id: '',
      company: '',
      alert_name: ['', Validators.required],
      value_min: '',
      value_max: '',
      plant_equipment: '',
      is_bot: false,
      is_mail: false,
      is_notify: false,
      is_outlier: true,
      interval: '',
    });




  }

  keyup(value) {
    this.day = (value/86400 | 0).toString();
    this.hour = ((value%86400)/3600 | 0).toString();
    this.minute = (((value%86400)%3600)/60 | 0).toString();
    this.seconds = (((value%86400)%3600)%60).toString();
    this.text = this.day + ":" + this.hour + ":" + this.minute + ":" + this.seconds;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get f() { return this.form.controls; }

  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  update = () => {
    this.submitted = true;
    if (this.form.valid) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        this.form.get('company').setValue(this.authService.getPerson());
        this.form.get('plant_equipment').setValue(this.selectref.selectedItemsCollection[0].id)

        this.isLoading = true;
        this.plantService.updateAlarm(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = true;
        })

      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

}

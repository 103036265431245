<section class="section">
    <div class="section-header">
        <h1>{{ layout.aliasTitle('Tipo') }}</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipmenttypes">Tipo</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header">
                    <h4>Adicionar {{ layout.aliasTitle('Tipo') }}</h4>
                </div> 
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="logo">Nome</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="name"
                                    [ngClass]="displayFieldCss('name')">
                                <div class="invalid-feedback">
                                    Por favor informe o nome
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="has_ip"
                                    >
                                    <label class="form-check-label" for="has_ip">
                                        Tem IP ?
                                    </label>

                                </div>

                            </div>
                            <div class="form-group col-md-6">

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="is_measurement"
                                    >
                                    <label class="form-check-label" for="is_measurement">
                                        É medidor?
                                    </label>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-footer text-right">
                    <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

import { Injectable } from '@angular/core';
import { GridStack } from 'gridstack';

@Injectable({
  providedIn: 'root'
})
export class StoreThreeRenderService {

  preRenderMaps: any[] = []
  componentRefs: any[] = []

  grid: GridStack;

  constructor() { }

  onRemovePrerender(id: number) {
    this.preRenderMaps = this.preRenderMaps.filter((pr) => pr.id != id);
    this.componentRefs = this.componentRefs.filter((co) => co.id != id);

    const el = document.getElementById(`supervisory-${id}`)
    this.grid.removeWidget(el)
  }

  onClear() {
    this.preRenderMaps = []
    this.componentRefs = []
  }
}

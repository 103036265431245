import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';
import { BaseFunc } from '../../shared/base-func/base-func';
import { NewsletterSampleService } from './newsletter-sample.service';
import { NewsletterLinkService } from '../newsletter-link/newsletter-link.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { LabelType, Options } from '@angular-slider/ngx-slider';

import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter-sample',
  templateUrl: './newsletter-sample.component.html',
  styleUrls: ['./newsletter-sample.component.css']
})
export class NewsletterSampleComponent extends BaseFunc implements OnInit {
  id = null;
  title = "-";
  items:any[] = [];
  emails: any[];
  selected;
  loading_data = false;
  form: FormGroup;

  global: Global = new Global();

  constructor( private newsletterSampleService: NewsletterSampleService,
    private newsletterLinkService: NewsletterLinkService,
    private router: Router,
    private formBuilder: FormBuilder) {
      super()
    }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    self.loading_data = true;

    self.newsletterLinkService.getNewsletterLinkerConf().pipe(take(1)).subscribe(
      data => {
        self.emails = data.emails;
        self.id = self.router.url.split('/')[2];
        self.title = "Painel de Amostras - Boletim " + self.router.url.split('/')[3];
        self.selected = data.emails[0];
        self.loading_data = false;
        self.form = self.formBuilder.group({ });
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );


  }

  sendSample = (event) => {
    var self = this;
    self.loading_data = true;
    self.newsletterSampleService.sendSampleTo({id: this.id, to: self.selected}).pipe(take(1)).subscribe(
      data => {
        self.loading_data = false;
        self.items.push({
          action: "Enviou para " + self.selected,
          response: "Resposta do servidor:\n" + data.status
        });
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );

  }

  cancel = () => {
    this.router.navigate(['panel-list/newsletter']);
  }

}

import { take, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from 'src/app/auth/auth.service';

/**
 * Serviço responsável por monitorar a inatividade do usuário e efetuar ações correspondentes.
 */
@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  // Indica se a verificação de inatividade está em andamento
  private inactivityCheckInProgress = false;

  /**
   * Construtor para InactivityService.
   * @param idle - Serviço NgIdle para lidar com inatividade.
   * @param keepalive - Serviço Keepalive para manter a sessão.
   * @param authService - Serviço de autenticação para realizar consultas de inatividade nos serviços.
   */
  constructor(private idle: Idle, private keepalive: Keepalive, private authService: AuthService) {
    // Configurações para inatividade
    this.idle.setIdle(600); // temmpo em segundos, após esse tempo inicia a verificação de inatividade
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Configuração do intervalo keepalive
    this.keepalive.interval(15); // mantém a sessão viva a cada 15 segundos

    // Assina o evento de início de inatividade
    idle.onIdleStart.subscribe(() => {
      // Verifica se a verificação de inatividade já está em andamento
      if (!this.inactivityCheckInProgress) {
        // Marca que a verificação de inatividade está em andamento
        this.inactivityCheckInProgress = true;

        // Reinicia a inatividade
        this.restartInactivity();

        // Realiza a verificação de inatividade
        this.checkInactivity();
      }
    });

    // Assina o evento de término de inatividade
    idle.onIdleEnd.subscribe(() => {
      // Marca que a verificação de inatividade não está mais em andamento
      this.inactivityCheckInProgress = false;
    });

    // Inicia a observação de inatividade
    this.startInactivity();
  }

  /**
   * Inicia a observação de inatividade.
   */
  startInactivity() {
    this.idle.watch();
  }

  /**
   * Para a observação de inatividade.
   */
  stopInactivity() {
    this.idle.stop();
  }

  /**
   * Reinicia o monitoramento de inatividade.
   */
  restartInactivity() {
    this.idle.watch();
  }

/**
 * Realiza a verificação de inatividade do usuário e toma ações correspondentes.
 */
async checkInactivity(): Promise<void> {
  try {
    // Lista de serviços a serem verificados
    const services = [
      this.authService.getCheckInactivityAuth(),
      this.authService.getCheckInactivityMain(),
      this.authService.getCheckInactivityRepo(),
      this.authService.getCheckInactivityForm(),
      this.authService.getCheckInactivitySimulation(),
      this.authService.getCheckInactivityMail(),
    ];

    // Cria um array de promises com tempo limite
    const promises = services.map(async service => {
      try {
        const result = await Promise.race([
          service.pipe(take(1)).toPromise(),
          new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 5000)) // 5 segundos de timeout
        ]);
        return result;
      } catch (error) {
        console.error('Erro ao verificar inatividade de um serviço:', error);
        // Pode retornar um objeto ou valor padrão para indicar falha na chamada do serviço
        return null;
      }
    });

    // Aguarda até que todas as promises estejam resolvidas
    const data = await Promise.all(promises);

    // Verifica se o usuário está ativo em pelo menos um serviço
    const activeServices = data.filter(serviceData => serviceData && serviceData.user_active === true).length;

    if (activeServices > 0) {
      console.log("Usuário ativo");
    } else {
      alert("Identificamos inatividade, sua sessão será encerrada!");
      this.authService.doLogout();

      // Aguarda 300ms antes de recarregar a página
      setTimeout(() => location.reload(), 300);
    }
  } catch (error) {
    console.error('Erro ao verificar inatividade:', error);
  }
}

  /**
   * Manipula erros durante a execução das requisições.
   * @param error - Erro a ser tratado.
   * @returns Um Observable representando o erro.
   */
  private handleError(error: any): never {
    console.error('Ocorreu um erro:', error);
    throw new Error('Erro ao processar a requisição.');
  }
}

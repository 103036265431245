import { Component, OnInit, ViewEncapsulation, ɵExtraLocaleDataIndex } from '@angular/core';
import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';
import { DataEngineManager } from '../../shared/base-func/data-engine-manager';
import { BaseFunc } from '../../shared/base-func/base-func';
import { CubeService } from './cube.service';
import { CubeInstance } from './cube-instance';
import { CubeMetric } from './cube-metric';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { Moment } from 'moment';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cube',
  templateUrl: './cube.component.html',
  styleUrls: ['./cube.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CubeComponent extends BaseFunc implements OnInit {

  filters: any[];
  dimensions: any[];
  metricSource: any[];

  hasMetricEQP = false;
  hasMetricNature = false;
  hasMetricGeneral = false;

  m_map: {};

  pheader = [];
  metrics = [];
  metrics2 = [];
  lineIndex = [];
  colIndex = [];
  filterIndex = [];
  PivotTableRef;
  PivotTableRef2;
  preConfiguration : { [key:string] : [] } = {};
  lastConfSent;
  ConfFil;
  baseURL: string =  environment.repoUrl;
  visualizationTools = new DataEngineManager(this);
  validateEngine = null;

  //Avulsos
  metricsCollection = {};

  // Date Range
  global: Global = new Global();
  ranges = this.global.getRanges();
  selected = { startDate: moment().startOf('day'), endDate: moment().add(1, 'days').startOf('day') };

  constructor(
    private cube: CubeService,
    private http: HttpClient,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    this.cube.getCubeConfig().pipe(take(1)).subscribe(
      data => {
        self.filters = data.filters;
        self.dimensions = data.dimensions;
        self.metricSource = data.metrics;
        self.hasMetricEQP = data.metrics.filter(x => x[0].startsWith('METRIC') ).length > 0;
        self.hasMetricNature = data.metrics.filter(x => x[0].startsWith('NATURE') ).length > 0;
        self.hasMetricGeneral = data.metrics.filter(x => !x[0].startsWith('METRIC') && !x[0].startsWith('NATURE') ).length > 0;
        self.m_map = data.m_map;
        self.initializeSettings( eval( data.config ) )
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {

      }
    );
  }

  buildToServerConf = (callbackFunc?) => {
    var self = this;
    var toServer = {
        dateStart: null, dateEnd: null,
        DimensionNames: {}, metricNames: [], MetricNames: {},
        Dimensions: [],
        Metrics: [],
        Filters: [],
        RadioValues: {},
        readableFilters: []
    };

    self.metrics = [];
    self.lineIndex = [];
    self.colIndex = [];
    self.filterIndex = [];
    self.pheader = [];

    toServer.dateStart = self.selected.startDate.format('YYYY-MM-DDTHH:mm:00.000000');
    toServer.dateEnd = self.selected.endDate.format('YYYY-MM-DDTHH:mm:00.000000');

    //Dimensões: Objeto toServer e self
    var dims = self.getComponent(self.$j('#dim_cols')[0]).itemsList.selectedItems.map(x=>{ x.origin="cols"; return x; })
      .concat(self.getComponent(self.$j('#dim_lines')[0]).itemsList.selectedItems.map(x=>{ x.origin="lines"; return x; }))
      .concat(self.getComponent(self.$j('#dim_dis')[0]).itemsList.selectedItems.map(x=>{ x.origin="dis"; return x; }));
    dims.forEach(function (el, index) {
        var obj = el;
        toServer.Dimensions.push(obj.value);
        self.pheader.push(obj.label);
        if (obj.origin == 'cols') self.colIndex.push(index);
        else if (obj.origin == 'lines') self.lineIndex.push(index);
        else if (obj.origin == 'dis') self.filterIndex.push(index);
    });

    //Métricas: Objeto toServer e self
    self.$j('.m_check:checked').forEach(function (el) {
      var metric = self.metricsCollection[self.$j(el).val()];
      toServer.Metrics.push(self.$j(el).val());
      self.metrics.push(self.extend(true, {}, metric));
    });

    //Filtros
    self.$j("#pills-filters .ic_selectcomponent").forEach(function (el) {
      var values = self.getComponent(el).itemsList.selectedItems;
      if (values.length > 0) {
          var key = self.$j(el).attr('id').replace('select', '');
          toServer.Filters.push({
              'key': key,
              'values': values.map(function (e, i) {
                  return e.value.id;
              })
          });
      }
    });

    self.$j('.radiogroup').forEach(function (el) {
      toServer.RadioValues[self.$j(el).attr('id')] = self.$j(el).find('input[type=radio]:checked').val();
    });
    if (callbackFunc) {
        callbackFunc(toServer);
    }

    var formData = this.global.saveData();
    delete formData['saveFilterList'];
    delete formData['selectFilterName'];

    //Variáveis para guardar o status dos seletores na tela: dimensionsFilter, metricsFilter
    var dimensionsFilter = {};
    self.$j("#pills-dimensions").find('#dim_cols,#dim_lines,#dim_dis').forEach(function (el, index) {
        var origin = self.$j(el).attr('id');
        self.getComponent( el ).itemsList.selectedItems.forEach(function(ei) {
            dimensionsFilter[ei.label] = {
                id: ei.value,
                class: origin
            };
        });
    });
    var metricsFilter = [];
    self.$j("#pills-metrics").find('.m_check').forEach(function (el ,index) {
        metricsFilter.push({
            id: self.$j(el).attr('id'),
            class: self.$j(el).is(':checked')
        });
    });

    toServer.DimensionNames = {};
    self.$j('#selectDimensions option').forEach(function (el) {
        toServer.DimensionNames[self.$j(el).attr('value')] = self.$j(el).text();
    });

    toServer.MetricNames = {};
    for (var i in self.metricsCollection)
        if (self.metricsCollection.hasOwnProperty(i))
            toServer.MetricNames[i] = self.metricsCollection[i].name;

    toServer.readableFilters.push(['Data', self.selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + self.selected.endDate.format('DD/MM/YYYY HH:mm') ]);
    toServer.readableFilters = toServer.readableFilters.concat(toServer.Filters.map( x => [x.key, x.values] ));
    toServer.readableFilters.push(['Dimensões Linhas', dims.filter(x => x.origin == 'lines').map( x => x.label ) ]);
    toServer.readableFilters.push(['Dimensões Colunas', dims.filter(x => x.origin == 'cols').map( x => x.label ) ]);
    toServer.readableFilters.push(['Dimensões Disposição', dims.filter(x => x.origin == 'dis').map( x => x.label ) ]);
    toServer.readableFilters.push(['Métricas', self.metrics.map( x => x.name ) ]);

    return {
        serverObj: toServer,
        dimension: dimensionsFilter,
        metric: metricsFilter,
        form: formData
    };
  };

  callbackFormatter = (item) => {
      if (item.dimension == "Mês") {
          var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
          return months[item.value - 1];
      } else if (item.dimension == "Ano/Mês") {
          var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
          var year = item.value.split('-')[0];
          return months[parseInt(item.value.split('-')[1]) - 1] + "/" + year;
      } else
          return item.value;
  };

  navigateCommand = (position, direction) => {
      var self = this;
      if(position == 'init') {
          if(self.$j("#main-help-div")[0].style.marginLeft != '30%')
            self.$j('#expand').trigger('click');
          self.$j('#filter_button').trigger('click');
      }
      else {
          if(self.$j("#f_div").is(':visible') && direction == 'go')
            self.$j('#dimension_button').trigger('click');
          else if(self.$j("#d_div").is(':visible')  && direction == 'go')
            self.$j('#metric_button').trigger('click');
          else if(self.$j("#d_div").is(':visible')  && direction == 'back')
            self.$j('#filter_button').trigger('click');
          else if(self.$j("#m_div").is(':visible')  && direction == 'back')
            self.$j('#dimension_button').trigger('click');
      }
  };

  pivotFilterConf = (name, values) => {
    var self = this;
    if (name == 'get') {
        if (values.pivotHeaders)
            self.preConfiguration.headers = eval(values.pivotHeaders);

        if (values.pivotRows)
            self.preConfiguration.rows = eval(values.pivotRows);

        if (values.pivotCols)
            self.preConfiguration.cols = eval(values.pivotCols);

        if (values.pivotFilters)
            self.preConfiguration.filters = eval(values.pivotFilters);

        if (values.pivotMetrics)
            self.preConfiguration.metrics = eval(values.pivotMetrics);

        if (values.pivotRankedIndex)
            self.preConfiguration.rank = eval("(" + values.pivotRankedIndex + ")");

        if (values.pivotGraphConfig)
            self.preConfiguration.graphConfig = eval(values.pivotGraphConfig);

        if (values.pivotMetricCollection)
            self.preConfiguration.metricCollection = eval(values.pivotMetricCollection);

        if (values.pivotMetricDinamycMap)
            self.preConfiguration.metricDinamycMap = eval(values.pivotMetricDinamycMap);

        if (values.pivotMetricShowedLabel)
            self.preConfiguration.metricShowedLabel = eval(values.pivotMetricShowedLabel);

        if (values.pivotMapAssociationIndex)
            self.preConfiguration.mapAssociationIndex = eval(values.pivotMapAssociationIndex);


    }
    else if (name == 'put') {
        if (self.PivotTableRef) { //Obtem configuraçoes do cubo
            //Constroe vetor das dimensoes segundo o cubo
            if (self.ConfFil.dimension) {
                var dimensions = [],
                    dimList = self.extend(true, {}, self.ConfFil.dimension),
                    heads = self.PivotTableRef.GPivot.headerRow;

                var rows = [];
                for (var i = 0; i < self.PivotTableRef.GPivot.rowConditions.length; ++i)
                    rows.push( heads[self.PivotTableRef.GPivot.rowConditions[i]] + ' **&& ' + dimList[heads[self.PivotTableRef.GPivot.rowConditions[i]]].id );
                values['dim_lines'] = rows.join(' ¶¶µµ ');

                var cols = [];
                for (var i = 0; i < self.PivotTableRef.GPivot.colConditions.length; ++i)
                    cols.push( heads[self.PivotTableRef.GPivot.colConditions[i]] + ' **&& ' + dimList[heads[self.PivotTableRef.GPivot.colConditions[i]]].id );
                values['dim_cols'] = cols.join(' ¶¶µµ ');

                var dis = [];
                for (var i = 0; i < self.PivotTableRef.GPivot.filterIndexes.length; ++i)
                    dis.push( heads[self.PivotTableRef.GPivot.filterIndexes[i]] + ' **&& ' + dimList[heads[self.PivotTableRef.GPivot.filterIndexes[i]]].id );
                values['dim_dis'] = dis.join(' ¶¶µµ ');
            }
            //Constroe vetor das metricas segundo o cubo
            values.metricsFilter = JSON.stringify(self.ConfFil.metric);
            values.pivotHeaders = JSON.stringify(self.PivotTableRef.GPivot.headerRow);
            values.pivotRows = JSON.stringify(self.PivotTableRef.GPivot.rowConditions);
            values.pivotCols = JSON.stringify(self.PivotTableRef.GPivot.colConditions);
            values.pivotFilters = JSON.stringify(self.PivotTableRef.GPivot.filterIndexes);
            values.pivotRankedIndex = JSON.stringify(self.PivotTableRef.GPivot.rankedIndex);
            values.pivotGraphConfig = JSON.stringify(self.PivotTableRef.GPivot.graphConfig);
            values.pivotMetricCollection= JSON.stringify(self.PivotTableRef.GPivot.metricCollection);
            values.pivotMetricDinamycMap = JSON.stringify(self.PivotTableRef.GPivot.metricDinamycMap);
            values.pivotMetricShowedLabel = JSON.stringify(self.PivotTableRef.GPivot.metricShowedLabel);
            values.pivotMapAssociationIndex = JSON.stringify(self.PivotTableRef.GPivot.mapAssociationIndex);

            var metricNames = [];
            for (var j = 0; j < self.PivotTableRef.GPivot.metricCollection.length; ++j)
                metricNames.push(self.PivotTableRef.GPivot.metricCollection[j].name);

            values.pivotMetrics = JSON.stringify(metricNames);

            return values;
        }
        else { //Obtem configuraçoes do formulario
            var dimensions = [];
            self.$j("#pills-dimensions").find('#dim_cols,#dim_lines,#dim_dis').forEach(function (index) {
                var origin = self.$j(this).attr('id');
                // self.$j(this).select2('data').forEach(function() {
                //     dimensions.push({
                //         id: this.id,
                //         class: origin
                //     });
                // });
            });
            values.dimensionsFilter = JSON.stringify(dimensions);

            var metricsF = [];
            self.$j("#pills-metrics").find('.m_check').forEach(function (index) {
                metricsF.push({
                    id: self.$j(this).attr('id'),
                    class: self.$j(this).is(':checked')
                });
            });
            values.metricsFilter = JSON.stringify(metricsF);
        }
    }
  };

  validDateFilter = (toServer) => {
    if (toServer.Metrics.length == 0) {
        alert("Entrada inválida: É necessário preencher pelo menos uma métrica");
        return false;
    } else if (toServer.Dimensions.length == 0) {
        alert("Entrada inválida: É necessário que pelo menos uma dimensão não esteja como inativa.");
        return false;
    }
    return true;

  };

  changeButtonState = (enabled, id) => {
    var self = this;
    if (!enabled) {
        self.$j(id).addClass('btWait');
        if (self.$j(id).length > 0) {
          self.$j(id).removeClass('btnAcao');
          self.$j(id).removeClass('btnShowTable');
          self.$j(id).removeClass('btnFilter');
          self.$j(id).attr("disabled", "disabled");
        }
    } else {
      self.$j(id).removeClass('btWait');
      self.$j(id).addClass('btnAcao');
      self.$j(id).addClass('btnShowTable');
      self.$j(id).addClass('btnFilter');
      self.$j(id).removeAttr("disabled");
    }
  };

  selectDimensions = (e, i) => {
    var self = this;
    document.querySelectorAll('.dim_select').forEach(function(l, index) {
      if(index != i)
        self.getComponent(l).itemsList.items.find(x => x.value == e).disabled = true;
    });
    self.$j('#selectDimensions option[value=' + e + ']').prop('disabled', true);
  };

  unselectDimensions = (e, i) => {
    var self = this;
    document.querySelectorAll('.dim_select').forEach(function(l, index) {
      if(index != i)
        self.getComponent(l).itemsList.items.find(x => x.value == e.value).disabled = false;
    });
    self.$j('#selectDimensions option[value=' + e.value + ']').prop('disabled', false);
  };

  metric_change = (e) => {
    var self = this;
    var depend = self.m_map[ self.$j(e.currentTarget).val() ];
    if(depend.length > 0) {
      if (e.currentTarget.checked ) {
        self.$j("#pills-dimensions").find('#dim_cols,#dim_lines,#dim_dis').forEach(function (el, index) {
          self.getComponent( el ).itemsList.selectedItems.forEach(function(ei) {
            if( depend.indexOf(ei.value) != -1 )
              self.global.removeToSelect2(el, null, ei.label);
          });
        });

        for( var i = 0; i < depend.length; ++ i) {
          var label = self.dimensions.find(x => x[0] == depend[i])[1];
          self.$j('#selectDimensions option[value="' + depend[i] + '"]').prop('disabled', true);
          self.getComponent( self.$j('#dim_cols')[0] ).itemsList.findByLabel(label).disabled = true;
          self.getComponent( self.$j('#dim_lines')[0] ).itemsList.findByLabel(label).disabled = true;
          self.getComponent( self.$j('#dim_dis')[0] ).itemsList.findByLabel(label).disabled = true;
        }

      }
      else {
        for( var i = 0; i < depend.length; ++ i) {
          var label = self.dimensions.find(x => x[0] == depend[i])[1];
          self.$j('#selectDimensions option[value="' + depend[i] + '"]').prop('disabled', false);
          self.getComponent( self.$j('#dim_cols')[0] ).itemsList.findByLabel(label).disabled = false;
          self.getComponent( self.$j('#dim_lines')[0] ).itemsList.findByLabel(label).disabled = false;
          self.getComponent( self.$j('#dim_dis')[0] ).itemsList.findByLabel(label).disabled = false;
        }
      }
    }
  };

  searchBtn = (e) => {
    this.$j('#applyFilters').prop('disabled', true);
    this.searchClick(false, false);

  }

  //Método com as ações do formulário
  initializeSettings = (map) => {
    var self = this;
    self.$j('.createDP').click(function(){
      self.$j("#favoriteDiv,#dataDiv").hide();
      self.$j("#parameterDiv").show();
    });

    self.$j('.favoriteDP').click(function(){
      self.$j("#parameterDiv, #dataDiv").hide();
      self.$j("#favoriteDiv").show();
    });

    self.$j('.dataDP').click(function(){
      self.$j("#parameterDiv,#favoriteDiv").hide();
      self.$j("#dataDiv").show();
    });

    self.$j('.onlyWithData').hide();

    self.$j('#addLine, #addColumn, #addDispose').click(function(el){
        var destination = {'col': '#dim_cols', 'line': '#dim_lines', 'dis': '#dim_dis' }[self.$j(this).attr('destination')];
        var data = self.$j('#selectDimensions').val();
        for(var i = 0; i < data.length; ++i) {
          //TODO Desenvolver forma mais adequada de fazer a adição dos elementos
          var el = self.$j(destination)[0];
          self.getComponent( document.querySelector(destination) ).open();
          var htmlID = self.getComponent(el).itemsList.items.find(x => x.value == data[i]).htmlId
          self.$j('#'+htmlID).trigger('click');

        }
        //self.$j('#addDimension').trigger('click');
        self.$j('#selectDimensions').val('');
    });


    for (var i = 0; i < map.length; ++i) {
        var args = [ map[i][0], map[i][1] ].slice().concat( map[i][2].slice() );
        self.metricsCollection[map[i][0]] = CubeMetric.factory(args);
    }


    // self.$j('#applyFilters').click(function () {
    //   self.$j('#applyFilters').prop('disabled', true);
    //   self.searchClick(false, false);
    // });

  };

  //Método que monta o objeto do cubo
  searchClick = (isCSV, runQuery) => {
    var self = this;
    self.ConfFil = self.buildToServerConf();
    var toServer = self.ConfFil.serverObj;

    //TODO: Adiciona as métricas ocultas a partir das métricas com association
    var mtc_depend = self.unique( self.metrics.map(function(e,i){ return e.association && e.association.length > 0 ? e.association : null; }).filter(x => x !== null) );
    mtc_depend.forEach(function( depends_array ){
      depends_array.forEach(function(m) {
        self.metrics.push(self.extend(true, {}, self.metricsCollection[m]));
        toServer.Metrics.push(m);
      })
    });

    if(!self.validDateFilter(toServer)) {
        self.$j('#applyFilters').prop('disabled', false);
        return;
    }

    var deferreds = [];

    self.cube.getCubeData(JSON.stringify({
      dateStart: toServer.dateStart,
      dateEnd: toServer.dateEnd,
      Dimensions: toServer.Dimensions,
      Metrics: toServer.Metrics,
      Filters: toServer.Filters,
      readableFilters: toServer.readableFilters
      //RadioValues: {}
    })).pipe(take(1)).subscribe(
      data => {
        var list = new Array();
        var resultData = eval("(" + data + ")");
        if (resultData.data.length > 0)
            list = list.concat(resultData.data);

        var message = "";
        if (list.length == 0) {
            alert('Os filtros não retornaram dados.');
            self.$j('#dataDiv').hide();
            self.$j('#applyFilters').prop('disabled', false);
        } else {
            self.lastConfSent = toServer;
            self.$j('.onlyWithData').show();
            self.$j("#parameterDiv, #favoriteDiv").hide();
            self.$j('#dataDiv').show();
            for (var i = 0; i < self.metrics.length; ++i)
                self.metrics[i].metricIndex = i + self.pheader.length;

            self.pheader.push("");

            self.PivotTableRef = new CubeInstance("p_table_area", "pivot_chart", "pivot_page", self.metrics, self.pheader, list, self.lineIndex, self.colIndex, self.filterIndex, {
                  defaultgraphapi: self.visualizationTools
              },
              self.callbackFormatter,
              function () { self.$j('#applyFilters').prop('disabled', false);
            });
            self.PivotTableRef.initPivotTableWithConfig(self.preConfiguration);
            self.preConfiguration = {};
            self.$j('#cubeDiv').show();
        }

        if (message != "")
            alert(message);

      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.$j('#applyFilters').prop('disabled', false);
      },
      () => {

      }
    )

  };

}

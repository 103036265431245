import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Error403Component } from './pages/error403/error403.component';
import { HubComponent } from './../auth/hub/hub.component';
import { AuthenticatedGuard } from '../core/guards/authenticated.guard';

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'password/reset/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'client/hub',
    component: HubComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: '403',
    component: Error403Component,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

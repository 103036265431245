<div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
  <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div id="customNewsletterPartialContent" class="tab-content container-fluid" style="min-height: 500px">
  <div id="customNewsletters_div" class="needs-validation">
    <div id="customNewsletters_box_esquerda">
      <form id="AddCustomNewsletterform" name="AddCustomNewsletterform" action="" method="post">

        <div style="max-height: 400px; overflow: auto;">

          <input id="codi_cnl" name="codi_cnl" type="hidden" (change)="searchLinkers($event)"  class="form-control"/>
          <fieldset *ngFor="let i of numLayout" id="{{ 'Layout' + i }}" class="layoutbulletin" style="{{ i == 0 ? 'display: table;width: 95%;' : 'display: none;width: 95%;' }}">
            <legend>
              <div class="addButton" style="text-align:right">
                <ng-container [ngSwitch]="true">
                  <ng-container *ngSwitchCase=" i == 0 ">
                    <input type="button" value="Adicionar Configuração" (click)="nextLayout(i, $event)" class="btn btn-primary classCreateComand" />
                  </ng-container>
                  <ng-container *ngSwitchCase=" i != 0 && i != numLayout.length - 1 ">
                    <input type="button" value="Remover Configuração" (click)="hideLayout(i, numLayout, $event)" class="btn btn-danger classDeleteComand" />
                    <input type="button" value="Adicionar Configuração" (click)="nextLayout(i, $event)" class="btn btn-primary classCreateComand" />
                  </ng-container>
                  <ng-container *ngSwitchCase=" i == numLayout.length - 1 ">
                    <input type="button" value="Remover Configuração" (click)="nextLayout(i, $event)" class="btn btn-danger classDeleteComand" />
                  </ng-container>
                </ng-container>
              </div>
            </legend>

            <input id="{{ 'code' + i }}" name="{{ 'code' + i }}" type="hidden" class="form-control"/>

            <label for="{{ 'selectTO' + i }}" class="alwaysTour">Para</label>
            <ng-select class="dim_select" id="{{ 'selectTO' + i }}" name="{{ 'selectTO' + i }}" >
              <ng-option *ngFor="let e of emails" value="{{e}}">{{e}}</ng-option>
            </ng-select>

            <label for="{{ 'selectCC' + i }}" class="alwaysTour">Cópia</label>
            <ng-select class="dim_select" id="{{ 'selectCC' + i }}" name="{{ 'selectCC' + i }}"
              multiple="multiple">
              <ng-option *ngFor="let e of emails" value="{{e}}">{{e}}</ng-option>
            </ng-select>

            <label for="{{ 'selectCCO' + i }}" class="alwaysTour">Cópia Oculta</label>
            <ng-select class="dim_select" id="{{ 'selectCCO' + i }}" name="{{ 'selectCCO' + i }}"
              multiple="multiple">
              <ng-option *ngFor="let e of emails" value="{{e}}">{{e}}</ng-option>
            </ng-select>

            <div style="width:95%;display:inline-block">
              <div style="float:left;width:50%;display:inline-block">
                <label for="{{ 'dayDispatch' + i }}">Enviar:</label>
                <select id="{{ 'dayDispatch' + i }}" name="{{ 'dayDispatch' + i }}" (change)="dayDispatchChange(i, $event)" class="form-control">
                  <option value="daily">Diariamente</option>
                  <option value="startmonth">Primeiro Dia de Mês</option>
                  <option value="endmonth">Último Dia do Mês</option>
                  <option value="starttrimester">Primeiro Dia do Trimestre</option>
                  <option value="endtrimester">Último Dia do Trimestre</option>
                  <option value="startyear">Primeiro Dia do Ano</option>
                  <option value="endyear">Último Dia do Ano</option>
                  <option value="_dayofnewtrimester">Dia Específico do Início de Um Trimestre</option>
                  <option value="_dayofmonth">Dia do Mês Específico</option>
                  <option value="_dayofweek">Dia da Semana Específico</option>
                  <option value="_monthname">Todos os Dias do Mês</option>
                  <option *ngFor="let t of trigger_options" value="trigger_{{t.id}}">{{t.label}}</option>
                </select>

                <div id="{{ '_dayofmonth' + i }}" class="sentCategory" style="display:none">
                  <label for="{{ 'day' + i }}">Dia:</label>
                  <select id="{{ 'day' + i }}" name ="{{ 'day' + i }}" class="form-control">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>

                <div id="{{ '_dayofnewtrimester' + i }}" class="sentCategory" style="display:none">
                  <label for="{{'dayOfNewTrimester' + i}}">Dia:</label>
                  <select id="{{'dayOfNewTrimester' + i}}" name="{{'dayOfNewTrimester' + i}}" class="form-control">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>

                <div id="{{ '_dayofweek' + i }}" class="sentCategory" style="display:none">
                  <label for="{{ 'week' + i }}">Dia da Semana:</label>
                  <select id="{{ 'week' + i }}" name="{{ 'week' + i }}" class="form-control">
                    <option value="1">Domingo</option>
                    <option value="2">Segunda</option>
                    <option value="3">Terça</option>
                    <option value="4">Quarta</option>
                    <option value="5">Quinta</option>
                    <option value="6">Sexta</option>
                    <option value="7">Sábado</option>
                  </select>
                </div>

                <div id="{{ '_monthname' + i }}" class="sentCategory" style="display:none">
                  <label for="{{'month' + i}}">Mês:</label>
                  <select id="{{'month' + i}}" name="{{'month' + i}}" class="form-control">
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                </div>

                <!--<div id="func" class="sentCategory" style="display:none">
                    <label for="condition">Condição:</label>
                    <select id="condition"></select>
                </div>-->
              </div>

              <div id="{{ 'daydispatchtime' + i }}" name="{{ 'daydispatchtime' + i }}" style="float:left;width:49%;display:inline-block">
                <label for="{{ 'freq' + i }}" >Horário:</label>
                <select id="{{ 'freq' + i }}" name="{{ 'freq' + i }}" (change)="freqChange(i, $event)" class="form-control">
                  <option value="anytime">Qualquer Hora Qualquer Número de Vezes</option>
                  <option value="once">Qualquer Hora uma Única Vez</option>
                  <option value="between">Durante o Intervalo</option>
                  <option value="oncebetween">Uma Única Vez Durante o Intervalo</option>
                  <option value="intervalhours">A cada intervalo de</option>
                  <option value="intervalbusinesshours">A cada intervalo de (Durante o Horário Comercial)</option>
                </select>

                <div id="{{ 'hour' + i }}" class="freqCategory" style="display:none">
                  <ngx-slider [(value)]="bottomValue[i]" [(highValue)]="topValue[i]" [options]="options"></ngx-slider>
                </div>

                <div id="{{ '_hourinterval' + i }}" class="freqCategory" style="display:none">
                  <ngx-slider [(value)]="bottomValue[i]"  [options]="options"></ngx-slider>
                </div>
              </div>
            </div>

            <br /><br /><br />
          </fieldset>

        </div>

        <hr />

        <div class="row">
          <div style="margin: 0 auto;">
            <button type="button" class="btn btn-secondary"
              data-dismiss="modal" (click)='cancel()'>Cancelar</button>
            <button type="button" class="btn btn-primary"
              data-dismiss="modal" (click)="saveLinkers($event)">Salvar</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

import { ImpactValueType } from "./impact-value-type";
import { IndustrialUnity } from "./industrial-unity";
import { Project } from "./project";
import { ProjectType } from "./project-type";
import { Projection } from "./projection";
import { ProjectionDetail } from "./projection-detail";

export class Scenery {
    id: number;
    name: string;
    industrialUnity?: IndustrialUnity;
    projects?: Project[];
    creationDate?: Date;
    editingName?: boolean;
    projections?: Projection[][]; // Cards de projeções
    projectionDetailsEnergy?: ProjectionDetail[]; // Linhas da tabela
    projectionDetailsEmit?: ProjectionDetail[]; // Linhas da tabela
    baseYear: number;
    impactValueType: ImpactValueType;
    projectType: ProjectType;
    comparisonYear: number;
    expanded: boolean;
}
<section class="section">
    <div class="section-header">
        <h1>{{ layout.aliasTitle('Local de medição' )}}</h1>
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header">
                    <h4>Editar {{ layout.aliasTitle('Local de medição' )}}</h4>
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="logo">Nome</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="name"
                                    [ngClass]="displayFieldCss('name')">
                                <div class="invalid-feedback">
                                    Por favor informe o nome
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="logo">Latitude</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="latitud"
                                    [ngClass]="displayFieldCss('latitud')">
                                <div class="invalid-feedback">
                                    Valor excede limite de 12 caracteres
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="logo">Longitude</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="longitud"
                                    [ngClass]="displayFieldCss('longitud')">
                                <div class="invalid-feedback">
                                    Valor excede limite de 12 caracteres
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="Sector">{{ layout.aliasTitle('Setor' )}}</label>
                                <select [value]="selectedSector" class="form-control" formControlName="sector">
                                    <option *ngFor="let item of sectors" value="{{item.plant_sector_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-row mt-4">
                            <ng-container *ngFor="let item of customFields">
                                <app-field-engine
                                [field]="item"
                                [reportFilters]="[]"
                                [hasLabel]="true"
                                #fields
                                #wrapper>
                                </app-field-engine>
                                <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                            </ng-container>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-right">
                  <app-default-button [isLoading]="isLoading" (pressed)="update()"></app-default-button>
                  <app-default-button [label]="'Limpar'" [type]="'secondary'" (pressed)="reset()"></app-default-button>
                  <app-default-button [label]="'Cancelar'" [type]="'secondary'" [navigateTo]="'/plant'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomCrudsComponent } from './custom-cruds/custom-cruds.component';
import { CrudBuilderComponent } from './crud-builder/crud-builder.component';
import { CrudScopeCardComponent } from './crud-scope-card/crud-scope-card.component';

@NgModule({
  declarations: [
    CustomCrudsComponent,
    CrudBuilderComponent,
    CrudScopeCardComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    CustomCrudsComponent,
  ]
})
export class CrudsModule { }

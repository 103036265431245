<div  class="tab-content br-n pn mt-3">
  <section class="section">
    <div class="section-body">
      <div class="row" >
        <div class="col-12" >
          <div class="card">
            <div class="card-body mx-2">

                <div class="row" *ngIf="form">
                  <div class="col-5 align-self-center">
                    <h4 class="page-title tour-table-title">{{ crud.nome_crd }}</h4>
                  </div>
                  <div class="col-7 align-self-center">
                    <div class="d-flex no-block justify-content-end align-items-center">
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <a routerLink="/">{{ 'Cadastro' | translate }}</a>
                          </li>
                          <li class="breadcrumb-item" *ngIf="crud.fgrp_crd != '' ">
                            {{ crud.fgrp_crd | translate }}
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">
                            <a routerLink="/get_crud/{{crudId}}">{{ crud.nome_crd | translate }}</a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>

              <!-- Form de entidades div -->
              <div class="container-fluid" *ngIf="form != null" >

                <div class="row" *ngIf="head_type == 'DEFAULT' ">
                  <div class="headCrud col-12" style="display: none;min-height: 400px;">
                      <div class="card">
                          <div class="card-body">
                              <h4 class="card-title"></h4>
                              <form #f="ngForm" id="headerFormRep_{{crud.codi_crd}}" name="headerFormRep_{{crud.codi_crd}}" class="form-horizontal managedform" *ngIf="form" [formGroup]="form">

                                <div class="form-body">
                                  <div class="form-group">
                                    <div class="row">
                                      <ng-container *ngFor="let field of crudFields">
                                          <app-field-engine #fields #wrapper
                                            [field]="field"
                                            [positionField]=" ( config['positionHeader'][field.dom_id] ) "
                                            [reportFilters]="reportFilters"
                                            formType="CRUD" >
                                          </app-field-engine>
                                          <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-actions">
                                  <input type="hidden" id="pk" name="pk" value=""/>
                                  <div class="text-right">
                                    <button type="button" id="cancelFilters" name="cancelFilters" class="btn btn-secondary" (click)="cancelBtn()" >
                                        <i class="fas fa-trash-alt"></i>
                                        Cancelar
                                    </button>
                                    <button type="button" id="applyFilters" name="applyFilters" class="btn btn-success" (click)="applyBtn($event)" >
                                        <i class="far fa-save"></i>
                                        Salvar
                                    </button>
                                  </div>
                                </div>
                              </form>
                          </div>
                      </div>
                  </div>
                </div>

              </div>

              <!-- Lista de entidades -->
              <div class="listCrud" *ngIf="form" >
                <div id="crud-content" name="crud-content">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4">

                      <div class="row mt-4">
                        <div class="col-sm-12 col-md-6 d-flex align-items-end">
                          <ng-container *ngIf=" !config.hasOwnProperty('hasNew') || (config.hasOwnProperty('hasNew') && config.hasNew) && (head_type != 'INLINE' || (head_type == 'INLINE' && !newInline))">
                            <button id="create_entity" name="create_entity" class="btn btn-primary mx-0 mb-0 tour-table-create" (click)="createBtn()" *ngIf="userPermissions.create">
                              <i class="fas fa-plus-circle"></i>
                              Criar Novo
                            </button>
                          </ng-container>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-sm-12">
                          <div class="table-responsive">
                            <div class="overflow-auto">
                              <table id="crud-table" name="crud-table" class="table table-bordered table-hover dataTable tour-table-content" role='grid' width='100%'>
                                <thead class="tour-table-fields">
                                  <tr>
                                      <th class="cursor-pointer th-index-{{i}}" id="{{ f.dom_id }}" *ngFor="let f of crudFieldsVisible; let i = index">
                                        <div class="d-flex justify-content-between">
                                          <span>{{ f.label }}</span>
                                          <i class="fa-solid fa-sort pointer px-1 py-1 tour-table-filters" (click)="onShowFilters(f, i)" *ngIf="f.type !== 'IMAGE'"></i>
                                        </div>
                                        <div [class.d-none]="selectedFilter.label !== f.label">
                                          <ng-container *ngTemplateOutlet="options"></ng-container>
                                        </div>
                                      </th>
                                    <th class="w-25 tour-table-actions">Ações</th>
                                  </tr>
                                  <tr *ngIf=" head_type == 'INLINE' && newInline ">
                                    <th *ngFor="let field of crudFields">
                                      <app-field-engine #fields #wrapper
                                        [field]="field"
                                        [reportFilters]="reportFilters"
                                        [hasLabel]="false"
                                        positionField=""
                                        formType="CRUD" ></app-field-engine>
                                      <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                                    </th>
                                    <th>
                                      <input type="hidden" id="pk" name="pk" value=""/>
                                      <button type="button" id="cancelFilters" name="cancelFilters" class="btn btn-secondary" (click)="cancelBtn()" >
                                        <i class="fas fa-trash-alt"></i>
                                          Cancelar
                                      </button>
                                      <button type="button" id="applyFilters" name="applyFilters" class="btn btn-success" (click)="applyBtn($event)" >
                                          <i class="far fa-save"></i>
                                          Salvar
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let line of dataResultFiltered | slice:offset:limit; let x = index" id="{{line[0]}}">
                                    <ng-container *ngIf="( head_type != 'INLINE' || ( head_type == 'INLINE' && line[0] != editLine) ); then defaultLineDiv; else editLineDiv">
                                    </ng-container>

                                    <!-- Linha Padrão da Tabela-->
                                    <ng-template #defaultLineDiv>
                                      <ng-container *ngFor="let col of ( config.hasOwnProperty('id') ? line.slice(1) : line ); let i = index" >
                                        <td class="tour-table-item" *ngIf=" isColumnVisible(i) ">


                                          <ng-container [ngSwitch]="true">
                                            <!-- Célula do tipo imagem -->
                                            <ng-container *ngSwitchCase=" crudFieldsVisible[ decodeIndexColVisible(i) ].type == 'IMAGE' ">
                                              <img [src]="sanitizeImageValue(col)" width="75" height="75" />
                                            </ng-container>

                                            <!-- Célula Padrão -->
                                            <ng-container *ngSwitchDefault>{{ getCellFormatted(col, i)}}</ng-container>
                                          </ng-container>

                                        </td>
                                      </ng-container>
                                      <td class="">
                                        <app-default-icon-button id="btndel_{{line[0]}}" [tooltiptext]="'Excluir'" [type]="'danger'" data-toggle="modal" data-target="#deleteModal" (click)="delete_prepare(line[0])" *ngIf="userPermissions.delete">
                                          <i class="fa-solid fa-trash fa-lg"></i>
                                        </app-default-icon-button>

                                        <app-default-icon-button id="btnupd_{{line[0]}}" [tooltiptext]="'Editar'" [type]="'warning'" (click)="searchFor($event.target, line[0])" *ngIf="userPermissions.update">
                                          <i class="fa-solid fa-pen-to-square fa-lg"></i>
                                        </app-default-icon-button>
                                      </td>
                                    </ng-template>

                                    <!-- Linha Editável da Tabela-->
                                    <ng-template #editLineDiv>
                                      <td *ngFor="let field of crudFields">
                                        <app-field-engine #fields #wrapper
                                          [field]="field"
                                          [reportFilters]="reportFilters"
                                          [hasLabel]="false"
                                          positionField=""
                                          formType="CRUD" ></app-field-engine>
                                        <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                                      </td>
                                      <td>
                                        <input type="hidden" id="pk" name="pk" value=""/>
                                        <button type="button" id="cancelFilters" name="cancelFilters" class="btn btn-secondary" (click)="cancelBtn()" >
                                          <i class="fas fa-trash-alt"></i>
                                            Cancelar
                                        </button>
                                        <button type="button" id="applyFilters" name="applyFilters" class="btn btn-success" (click)="applyBtn($event)" >
                                            <i class="far fa-save"></i>
                                            Salvar
                                        </button>
                                      </td>
                                    </ng-template>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>

                        <app-paginator-control class="w-100" [content]="dataResultFiltered" (change)="onPaginate($event)"></app-paginator-control>

                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <!-- Loading spinner -->
              <div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
                <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
                  <span class="sr-only">Carregando...</span>
                </div>
              </div>

              <!-- Modal de Configuração da Coluna -->


              <!-- Modal de Deleção de linhas  -->
              <div id="deleteModal"class="modal fade" tabindex="-1" role="dialog">
                <input id="curr_delete_formcode" name="curr_delete_formcode" type="hidden" />
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title">Apagar Item</h4>
                    </div>
                    <div class="modal-body">
                      <p>Tem certeza que deseja excluir esse item? Essa ação não poderá ser desfeita.</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete($event)">Apagar</button>
                    </div>
                  </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
              </div>

              <!-- Form de entidades Modal -->
              <div id="form-container" class="headCrud modal fade over-all-assets-cruds" tabindex="-1" role="dialog" *ngIf="head_type == 'MODAL' ">
                <div class="modal-dialog modal-xl" role="document" id="headerFormRep_{{crud.codi_crd}}" name="headerFormRep_{{crud.codi_crd}}" style="max-width: 65%">
                  <div class="modal-content" >
                    <div class="modal-header">
                      <h4 class="modal-title"></h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                        (click)='cancelBtn()'>×</button>
                    </div>
                    <div id="custom-modal-body" class="modal-body" style="overflow-y: auto; height: 500px;">

                      <div class="form-group" style="height: 85%">
                        <div class="row">
                          <ng-container *ngFor="let field of crudFields">
                            <app-field-engine #fields #wrapper
                              [field]="field"
                              [reportFilters]="reportFilters"
                              formType="CRUD" ></app-field-engine>
                            <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                          </ng-container>
                        </div>
                      </div>

                      <div class="form-actions">
                        <input type="hidden" id="pk" name="pk" value=""/>
                        <div class="text-right">
                          <button type="button" id="cancelFilters" name="cancelFilters" class="btn btn-secondary" (click)="cancelBtn()" >
                              <i class="fas fa-trash-alt"></i>
                              Cancelar
                          </button>
                          <button type="button" id="applyFilters" name="applyFilters" class="btn btn-success" (click)="applyBtn($event)" >
                              <i class="far fa-save"></i>
                              Salvar
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-button-how-to [sequence]="tourSequence"></app-button-how-to>
</div>

<ng-template #options>
  <app-table-filters-popup *ngIf="selectedFilter"
  [visible]="isOptionsOpened"
  [field]="selectedFilter?.label"
  [hasStatus]="false"
  [hasOrdernator]="true"
  [hasSearch]="selectedFilter.type !== 'RADIO'"
  (close)="onHideFilters()"
  (orderBy)="onOrderBy($event)"
  (clear)="onClearFilters()"
  (search)="onSearch($event)">
  >
</app-table-filters-popup>
</ng-template>


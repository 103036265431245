import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-filters-popup',
  templateUrl: './table-filters-popup.component.html',
  styleUrls: ['./table-filters-popup.component.css']
})
export class TableFiltersPopupComponent implements OnInit {
  @Output() orderBy: EventEmitter<'asc' | 'desc'> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() status: EventEmitter<boolean> = new EventEmitter();
  @Output() clear: EventEmitter<boolean> = new EventEmitter();

  @Input() visible: boolean = false;
  @Input() field: string;
  @Input() hasOrdernator: boolean = true;
  @Input() hasStatus: boolean = true;
  @Input() hasSearch: boolean = true;

  config: any = {
    order: null,
    status: null
  }

  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      value: ['']
    })
  }

  onOrderBy(value: 'asc' | 'desc') {
    this.config.order = value;
    this.orderBy.emit(value);
  }

  onSearch() {
    const value = this.form.get('value').value;
    this.search.emit(value.toLowerCase());
  }

  onClose() {
    this.visible = false;
    this.close.emit(true);
  }

  onChangeStatus(status: boolean) {
    this.config.status = status;
    this.status.emit(status);
  }

  onClear() {
    this.form.reset();
    this.config = {
      status: null,
      order: null
    }

    this.clear.emit(true);
  }

}

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GridStack } from 'gridstack';
import { Observable } from 'rxjs';

import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  TextureLoader,
  WebGLRenderer,
  PlaneGeometry,
  Raycaster,
  Vector2,
  Clock,
  OrthographicCamera,
} from 'three';

@Component({
  selector: 'app-three-render',
  templateUrl: './three-render.component.html',
  styleUrls: ['./three-render.component.css'],
})
export class ThreeRenderComponent implements OnInit, OnChanges {
  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('popup') popup: ElementRef<HTMLElement>;

  popupStyle: any;

  @Input() data: any;
  @Input() images: any[] = [];
  @Input() ancor: string;
  @Input() grid: GridStack;
  @Input() title: string;

  windthScreen: number = 0;
  heightScreen: number = 0;

  sizeRef: string;

  @ViewChild('rCanvas', { static: true })
  canvasRef: ElementRef<HTMLCanvasElement>;

  @HostListener('window:resize')
  onResize() {
    if (this.ancor && !this.sizeRef) {
      this.sizeRef = this.ancor;
    }

    if (this.sizeRef) {
      const ancor = document.getElementById(this.sizeRef);

      this.windthScreen = ancor?.clientWidth;
      this.heightScreen = ancor?.clientHeight - 90;
      this.renderer?.setSize(this.windthScreen, this.heightScreen);
    }

    if (this.camera) {
      // this.camera.aspect = this.windthScreen / this.heightScreen;
      this.camera.updateProjectionMatrix();
    }
  }

  scene: Scene;
  camera: OrthographicCamera;
  renderer: WebGLRenderer;
  raycaster: Raycaster;
  mouse: Vector2;
  clock: Clock;

  cameraZ = 1.5;

  background0 = localStorage.getItem('img1');
  background1 = localStorage.getItem('img2');
  pin = localStorage.getItem('ping');

  selected: number = 0;

  levels: any[] = [];
  hasPopup: boolean = false;
  popupValue: any;

  constructor(private sanitizer: DomSanitizer, private ngZone: NgZone) {}

  onCanvasClick(event: MouseEvent) {
    const canvas = this.canvasRef.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    this.mouse.x = (offsetX / rect.width) * 2 - 1;
    this.mouse.y = -(offsetY / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children, true);

    intersects.map((item) => {
      if (item.object.name == 'background') {
        this.hasPopup = false;
      }

      if (item.object.name !== 'background') {
        const levelSelected = this.levels[this.selected];
        const items = levelSelected.items.filter((i) => i.sensor == item.object.name);

        if (items.length > 0) {
          this.popupValue = {
            title: item.object.name,
            values: items[0].values,
            color: items[0].color,
          };
        }

        this.hasPopup = true;
        this.popupStyle = {
          left: `${event.clientX - rect.left}px`,
          top: `${event.clientY - rect.top}px`,
          display: 'block',
          'z-index': 2,
          color: '#000',
          'border-radius': '10px',
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && this.images.length > 0) {
      this.onTransformData();
    }

    if (this.ancor && !this.sizeRef) {
      this.sizeRef = this.ancor;
    }

    if (this.levels.length > 0) {
      this.onInitScene();
      this.onMountMap(this.levels[this.selected]);

      this.renderScene();
      this.onResize();
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.observerDivDimensionsChanges().subscribe(() => {
      this.onResize();
    });
  }

  observerDivDimensionsChanges(): Observable<DOMRectReadOnly> {
    return new Observable((obs) => {
      const resizeObserver = new ResizeObserver((entries) => {
        this.ngZone.run(() => {
          for (let entry of entries) {
            obs.next(entry.contentRect);
          }
        });
      });
      resizeObserver.observe(this.container.nativeElement);
    });
  }

  onTransformData() {
    const values = JSON.parse(this.data);

    const { data, cols } = values;

    data.map((el: any) => {
      const levelIndex = this.levels.findIndex((level) => level.id == el[1]);

      if (levelIndex == -1) {
        this.levels.push({
          id: el[1],
          img: this.sanitizer.bypassSecurityTrustUrl(this.onGetImageById(el[1])),
          bg: this.onGetImageById(el[1]),
          items: [
            { sensor: cols[5], value: el[5], x: el[2], y: el[3], z: el[4], color: 'f87171' },
            { sensor: cols[6], value: el[6], x: el[2], y: el[3], z: el[4], color: '84cc16' },
          ],
        });
      } else {
        const items = [
          { sensor: cols[5], value: el[5], x: el[2], y: el[3], z: el[4], color: '84cc16' },
          { sensor: cols[6], value: el[6], x: el[2], y: el[3], z: el[4], color: 'f87171' },
        ];
        const level = this.levels[levelIndex];
        level.items = [...level.items, ...items];
      }
    });
  }

  onGetImageById(id: number | string) {
    const imageExists = this.images.filter(
      (el: { graph_image_id: number; content: string }) => el.graph_image_id == id,
    );
    if (imageExists.length == 0) {
      console.log('Imagem não encontrada');
      return 'image not found';
    }

    return imageExists[0].content;
  }

  onInitScene() {
    const width = window.innerWidth / 2;
    const height = window.innerHeight / 2;

    this.scene = new Scene();
    this.camera = new OrthographicCamera();
    this.renderer = new WebGLRenderer();
    this.renderer.setSize(width, height);
    this.raycaster = new Raycaster();
    this.mouse = new Vector2();
    this.clock = new Clock();

    this.canvasRef.nativeElement.appendChild(this.renderer.domElement);
    this.camera.position.z = this.cameraZ;
  }

  onMountMap(level: any) {
    const map = new TextureLoader().load(level.bg);
    const material = new MeshBasicMaterial({ map });
    const geometry = new PlaneGeometry(0.5, 1);

    const cube = new Mesh(geometry, material);
    cube.name = 'background';
    this.scene.add(cube);

    level.items.map((item: any) => {
      this.onPositionItem(item.color, item.x, item.y, item.z, item.sensor);
    });
  }

  onChangeLevel(index: number) {
    if (this.selected === index) {
      return;
    }

    this.selected = index;
    this.onMountMap(this.levels[index]);
    this.onResetCamera();
  }

  onZoon(type: 'in' | 'out') {
    if (type == 'out') {
      this.scene.children.map((child) => {
        child.scale.z += 0.2;
        child.scale.x += 0.2;
        child.scale.y += 0.2;
      });
    }
    if (type == 'in') {
      this.scene.children.map((child) => {
        child.scale.z -= 0.2;
        child.scale.x -= 0.2;
        child.scale.y -= 0.2;
      });
    }
  }

  onMoveCamera(type: 'left' | 'up' | 'right' | 'down') {
    switch (type) {
      case 'right':
        this.camera.position.x -= 0.1;
        break;

      case 'left':
        this.camera.position.x += 0.1;
        break;

      case 'down':
        this.camera.position.y -= 0.1;
        break;

      case 'up':
        this.camera.position.y += 0.1;
        break;

      default:
        break;
    }
  }

  onResetCamera() {
    this.camera.position.set(0, 0, this.cameraZ);
  }

  onPositionItem(color: any, x: number, y: number, z: number, name: string) {
    const geometry = new PlaneGeometry(0.02, 0.05);
    const material = new MeshBasicMaterial({ color: `#${color}` });

    const plane = new Mesh(geometry, material);
    plane.name = name;
    plane.position.set(x, y, z);

    this.scene.add(plane);
  }

  renderScene() {
    const animate = () => {
      requestAnimationFrame(animate);
      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }
}

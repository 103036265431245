import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomReportsService } from '../../components/reports/custom-reports/custom-reports.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { take } from 'rxjs/operators';
import { BaseFunc } from 'src/app/components/shared/base-func/base-func';
import { HelpComponent } from 'src/app/components/shared/help/help.component';
import { LayoutService } from 'src/app/core/services/layout/layout.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseFunc implements OnInit {
  paginationTour: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private customreport: CustomReportsService,
    public layout: LayoutService,
    public authService: AuthService
  ) { super(); }

  ngOnInit(): void {
    const isAuthenticated = this.authService.hasToken();
    var init = this.authService.getInitialConf();
    this.validationShowTour();


    if (isAuthenticated === false)
      this.router.navigate(['/signin']);
    else if (init == null)
      this.readCustomInitialPage();
    else
      this.has_init_navigate_page(init);
  }


  readCustomInitialPage = () => {
    this.customreport.getCustomReportsDBConf().pipe(take(1)).subscribe(
      data => {
        // const d = {
        //   ...data,
        //   initial_page: '',
        //   dynamic_repos: ['gAAAAABmRM3B8JvJDKD7vhRCkBlZ7WaVmmj8u3vXFpU5Yb4a35N_-rLrE5RIvZew6V5t50QJMLthbRMmTfsF5hNUdyOwKHgQSK3ZlzKxymDGPmOI3fAdKe8jocAkrwdNa_BCZUgjhx3q9r67pnQrPi85968ae-my-2TGO0GTV6oMS3_OtFNHWpxRyYY5nRGP0OI4C-vX3HA0hVOkqGSzLDijWqzlGUF-AEr5ragJttAydvnYsr2ulGs%3D']
        // }
        
        if (data.initial_page != '' && data.dynamic_repos?.length == 0) {
          this.authService.setInitialConf(data.initial_page)
          this.has_init_navigate_page(data.initial_page);
        }

        if(data.dynamic_repos?.length > 0 && data.initial_page == '') {
          this.authService.setInitialConf(`custom-report/${data.dynamic_repos[0]}`)
        }

        this.authService.setStatistics(data.statistics);
      },
      error => {
        console.log('Erro ao obter a lista de relatórios customizados.');
      }
    );
  }

  listUG() {
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      let company = this.authService.getPerson();
      let url = this.authService.endpoint_main + 'plant/'
      // this.dashboardService.getUgList().subscribe(
      //   data => {

      //   },error => {

      //   }
      // )
    } else {
      this.authService.doLogout();
    }

  }

  fetchPrevious = () => {

  }

  fetchNext = () => {

  }

  has_init_navigate_page = (init) => {
    if (init != '')
      this.router.navigate(['/' + init]);
  }

  validationShowTour = () => {
    var self = this;
    var trueShowTour = self.authService.getShowTour();

    window.onload = function () {
      document.getElementById("helpButton")?.click();
      self.$j('#checkbox-tour-form').show();
      self.$j('#help_window_button').hide();
    }

    function customReportTour() {
      if (document.querySelector('[href^="/custom-report') != null)
        return null;

      document.querySelector('[href^="/custom-report')?.classList?.add('pagination-tour');
      let selectCustomReport = document.querySelector('[href^="/custom-report')?.parentElement;
      selectCustomReport?.setAttribute('id', 'custom-report-tour');
      let selectCustomTO = document.getElementById('custom-report-tour')?.getBoundingClientRect().top;
      let parserStringToHtml = new DOMParser();

      let spanTourCustomReport = `<span class="helpTag_1" position="L_MARGIN" help_type="Component" ref_to="#custom-report-tour"
      style="display: none;">
      <h2 class="help_title">Relatórios</h2>
      <p class="help_body">Este campo é responsável por apresentar relatórios analíticos e dashboards construídos a partir de dados recebidos da indústria.</p>
      </span>`
      let spanTourCustomReportHTML = parserStringToHtml.parseFromString(spanTourCustomReport, 'text/html');
      let spanHelpTag = spanTourCustomReportHTML.querySelector('span');
      document.getElementById('custom-report-tour')?.appendChild(spanHelpTag);

       if (window.innerHeight > selectCustomTO) {
        window.scroll({
          top: selectCustomTO,
          behavior: "smooth",
        });
      }

    }

    function CubeTour() {
      var cubeRef = document.querySelector('[href^="cube"');
      if (cubeRef == null)
        return;

      cubeRef.classList.add('pagination-tour');
      let selectCube = cubeRef.parentElement;
      selectCube.setAttribute('id', 'cube-tour');
      let selectCubeTO = selectCube.getBoundingClientRect().top;
      let parserStringToHtml = new DOMParser();

      let spanTourCube = `<span class="helpTag_1" position="L_MARGIN" help_type="Component" ref_to="#cube-tour"
      style="display: none;">
      <h2 class="help_title">Cubo de Dados</h2>
      <p class="help_body">Este campo é responsável por apresentar relatórios analíticos e dashboards construídos a partir de dados recebidos da indústria.</p>
      </span>`
      let spanTourCubeHTML = parserStringToHtml.parseFromString(spanTourCube, 'text/html');
      let spanHelpTag = spanTourCubeHTML.querySelector('span');
      document.getElementById('cube-tour').appendChild(spanHelpTag);


       if (window.innerHeight > selectCubeTO) {
        window.scroll({
          top: selectCubeTO,
          behavior: "smooth",
        });
      }

    }

    function paginationTour() {

      self.paginationTour = false;
      localStorage.setItem('pagination_tour', JSON.stringify(self.paginationTour));

      document.querySelector('#help_tour_button')?.addEventListener('click', function() {
        self.paginationTour = true;
        localStorage.setItem('pagination_tour', JSON.stringify(self.paginationTour));
      });
    }

    function activateTour() {
      if (trueShowTour) {
        self.$j('.helpTag_1').addClass('helpTag');
      };
    }

    setTimeout(function () {
      var helpButton = document.getElementById("helpButton");
      if (helpButton) {
        helpButton.style.visibility = "hidden";
      }
    }, 100);

    setTimeout(function () {
      customReportTour();
      CubeTour();
      activateTour();
      paginationTour()
    }, 1000)
  };
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../auth/auth.service';
import { HeaderService } from './header.service';
import { take } from 'rxjs/operators';
import { isDevMode } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NotificationMessage } from './notification-message';
import { InactivityService } from '../services/inactivity-service.service';
import { environment } from 'src/environments/environment';
import { TourService } from 'src/app/core/services/tour/tour.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular para o cabeçalho da aplicação.
 * Gerencia a exibição de notificações, idiomas, verificação de inatividade e logout do usuário.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // Idioma selecionado
  langSelected = 'pt';

  // Lista de notificações do usuário
  notifications: NotificationMessage[] = [];

  // Intervalo para leitura de notificações
  private readNotificationsInterval: any;

  isHorizontal = window['whiteLabelConf']('horizontal');

  constructor(
    public authService: AuthService,
    public headerService: HeaderService,
    private translate: TranslateService,
    private msalService: MsalService,
    private inactivityService: InactivityService,
    public tour: TourService,
    public layout: LayoutService
  ) {
    translate.setDefaultLang('pt');
  }

  /**
   * Método chamado após a inicialização do componente.
   * Inicializa a verificação de inatividade e a leitura de notificações.
   */
  ngOnInit(): void {
    this.inactivityService.startInactivity();
    // this.readNotifications();
  }

  /**
   * Método chamado ao destruir o componente.
   * Limpa os intervalos de verificação de inatividade e leitura de notificações.
   */
  ngOnDestroy(): void {
    this.inactivityService.stopInactivity();
    clearInterval(this.readNotificationsInterval);
  }

    //Configurações White Label
    navlogo_wl = window['whiteLabelConf']('navlogo');


  onHandleSidebar() {
    const isSidebarOpened = document.getElementById('main-wrapper')?.getAttribute('data-sidebartype') == 'full';
        
    if(isSidebarOpened) {
      document.getElementById('main-wrapper')?.classList.add('mini-sidebar')
      document.getElementById('main-wrapper').setAttribute('data-sidebartype', 'mini-sidebar')

      this.layout.onHandleLogo(true)
    } else {
      document.getElementById('main-wrapper')?.classList.remove('mini-sidebar');
      document.getElementById('main-wrapper').setAttribute('data-sidebartype', 'full')
      this.layout.onHandleLogo(false)
    }
  }

  /**
   * Realiza a leitura de notificações do usuário.
   * A cada 30 segundos, busca novas notificações.
   */
  private readNotifications(): void {
    const seconds = 30;
    const searchLast = () => {
      this.headerService.getNofificationList().pipe(take(1)).subscribe(
        (data) => {
          this.notifications = data;
        },
        (error) => {
          console.log('Ocorreu um erro ao buscar a lista de alertas ' + error);
          if (isDevMode()) {
            console.log('Desabilitando chamada recorrente ao mainservice no modo debug');
            clearInterval(this.readNotificationsInterval);
          }
        }
      );
    };

    searchLast();
    this.readNotificationsInterval = setInterval(searchLast, seconds * 1000);
  }

  /**
   * Atualiza o status das notificações no servidor.
   */
  updateStatusAlert(): void {
    this.headerService.postNofificationList(this.notifications).pipe(take(1)).subscribe(
      () => { },
      (error) => {
        console.log('Ocorreu um erro ao tentar enviar a atualização de alertas' + error);
      }
    );
  }

  /**
   * Altera o idioma da aplicação.
   * @param language - Idioma a ser definido.
   */
  switchLanguage(language: string): void {
    this.translate.use(language);
    this.langSelected = language;
  }

  /**
   * Efetua o logout do usuário.
   * Se estiver autenticado com o Azure, realiza o logout no Azure e na aplicação.
   * Caso contrário, realiza o logout apenas na aplicação.
   */
  logout(): void {
    const userAzure = this.msalService.instance.getActiveAccount();
    if (userAzure != null) {
      this.authService.azureLogout();
      this.msalService.logout();
    } else {
      this.authService.doLogout();

      if (this.readNotificationsInterval) {
        clearInterval(this.readNotificationsInterval);
      }
    }
  }
/**
 *
 * @return Se o usuario logado, for super usuário, irá retornar verdadeiro.
 */
  get isSuperUser(): boolean {
    return this.authService.getSuperUser();
  }

  get userName(): string {
    return this.authService.getFullName();
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BootstrapModalAlertService } from './components/shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var self = this;

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // Tratamento de erro HTTP do servidor
          const services = {
            MainService: environment.mainApiUrl,
            RepoService: environment.repoUrl,
            UserAuthService: environment.authApiUrl,
            MailService: environment.mailUrl,
            SimulationService: environment.simulationUrl,
            BotService: environment.botUrl,
          };

          for (const port in services) {
            if (services.hasOwnProperty(port) && error.url.includes(services[port])) {
              console.log(`Ocorreu um erro no serviço ${port}`);
            }
          }
          console.log('Erro HTTP codigo ' + error.status + ' na url ' + error.url);

          if (error.error && error.error.ic_exception && error.error.user_message) {
            var message = error.error.message
              ? `<hr/><p class="isCopyable"> Erro do servidor: ${error.error.message} </p>`
              : '';
            var stack = error.error.traceback
              ? '<div class="div_textArea_error isCopyable">' + error.error.traceback + '</div>'
              : '';
            var contact_us = error.error.contact_us ? '<p>Por favor contate o suporte técnico da IndustryCare</p>' : '';
            self.modalAlertService.showAlertDanger(
              `<h3>Servidor Informa</h3> <p class="isCopyable">${error.error.user_message}</p>
              ` +
                contact_us +
                message +
                stack,
            );
          } else {
            console.log('Erro não corresponde a uma falha padrão ic_exception');
            console.log(error);
          }
        } else {
          // Outros tipos de erro
          console.error('Erro desconhecido:', error);
        }

        // Você pode reformatar o erro ou lançá-lo novamente se necessário
        return throwError(error);
      }),
    );
  }
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para a atualização de imagens de IoT.
 */
@Component({
  selector: 'app-equipment-image-update',
  templateUrl: './equipment-image-update.component.html',
  styleUrls: ['./equipment-image-update.component.css']
})
export class EquipmentImageUpdateComponent implements OnInit {

  /** Formulário para a atualização de imagens de IoT. */
  form: FormGroup;

  /** Indica se o formulário foi enviado. */
  submitted = false;

  /** Lista de equipamentos. */
  equipments = [];

  /** Objeto FormData para manipulação de dados binários. */
  formData = new FormData();

  /** Equipamento selecionado. */
  selectedEquipment = '';

  /** Domínio principal. */
  domain_main = '';

  /** URL da imagem. */
  image = '';

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  /** Inicialização do componente. */
  ngOnInit(): void {
    // Define o domínio principal.
    this.domain_main = this.authService.endpoint_main;

    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('change_equipmentimage') == false) {
      this.router.navigate(['/403']);
    }

    // Obtém o parâmetro da rota para identificação da imagem.
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.initForm();
      const id = +param.get('id');
      this.read(id);
    });
  }

  initForm() {
    // Inicializa o formulário.
    this.form = this.formBuilder.group({
      id: '',
      company: '',
      description: '',
      qr_code: '',
      plant_equipment: ['', Validators.required],
    });
  }

  /** Obtém os controles do formulário. */
  get f() { return this.form.controls; }

  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field Nome do campo.
   * @returns `true` se o campo for inválido, `false` caso contrário.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes de estilo para exibição do campo no formulário.
   *
   * @param field Nome do campo.
   * @returns Objeto com as classes de estilo.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /** Realiza a leitura dos dados da imagem de IoT. */
  read = (id) => {
    this.plantService.getEquipmentImage(id).pipe(take(1)).subscribe(
      data => {
        this.form.get('plant_equipment').setValue(data.bundle_equipment_id.id);
        this.form.get('id').setValue(id);
        this.form.get('company').setValue(data.company.id);
        this.form.get('description').setValue(data.description);
        this.form.get('qr_code').setValue(data.qr_code);
        this.image = data.image;
      }, error => {
      }
    );

    // Obtém a lista de equipamentos.
    this.onFindAllBundlers();
  }

  onFindAllBundlers() {
    const url = this.authService.endpoint_main + 'plant/equipmentbundle/list'
    const company = this.authService.getPerson();

    this.plantService.getEquipmentBundleAllList(url, company).subscribe((data) => {
      this.equipments = data;
      this.selectedEquipment = this.form.get('plant_equipment').value;
    });
  }

  /** Atualiza os dados da imagem de IoT. */
  update = () => {
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Chama o serviço para atualizar os dados da imagem.

        const { id } = this.route.params['_value']
        this.form.get('id').setValue(id);

        const payload = {
          id: Number(this.form.get('id').value),
          company: this.form.get('company').value,
          description: this.form.get('description').value,
          qrd_code: this.form.get('qr_code').value,
          bundle_equipment_id: Number(this.form.get('plant_equipment').value)
        }
        
        this.isLoading = true;
        this.plantService.updateEquipmentImage(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            console.log(error);
            
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro 1');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de perigo caso não haja um ID de aplicativo ou pessoa disponíveis.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário não seja válido, valida todos os campos.
      this.validateAllFormFields(this.form);
    }
  }

  /** Reinicia o formulário. */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PersonService } from './../person.service';
import { HttpParams } from '@angular/common/http';
import { Person } from './../person.model';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { FormBuilder, FormGroup } from '@angular/forms';

/**
 * Componente para exibição e manipulação de dados de pessoas.
 */
@Component({
  selector: 'app-person-read',
  templateUrl: './person-read.component.html',
  styleUrls: ['./person-read.component.css']
})
export class PersonReadComponent implements OnInit {

  // Lista de pessoas
  persons: Person[];
  personsFiltered: Person[] = [];
  // URL da próxima página paginada
  next: string;
  // URL da página anterior paginada
  previous: string;

  // Parâmetros HTTP
  params = new HttpParams();

  // Referência modal de exclusão
  deleteModalRef: BsModalRef;
  @ViewChild('deleteModal') deleteModal;
  // Item selecionado para exclusão
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  offset: number = 0;
  limit: number = 5;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'trade_name', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

 /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private personService: PersonService,
    public layout: LayoutService,
    private fb: FormBuilder
  ) { }

  /**
   * Executado ao inicializar o componente.
   * Verifica permissões e lê todas as pessoas.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissão
    if (this.authService.checkPermission('view_person') == false) {
      this.router.navigate(['/403']);
    };

    // Lê todas as pessoas
    this.readAll(this.authService.endpoint_main + 'person/list')
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.persons.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.personsFiltered = this.persons;
      return;
    }

    this.personsFiltered = this.personsFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onClearFilters() {
    this.personsFiltered = this.persons;
  }

  /**
   * Lê todas as pessoas a partir de uma URL fornecida.
   * @param url A URL para recuperar a lista de pessoas.
   */
  readAll(url: string) {

    // Verifica se existe token.
    if (this.authService.hasToken()) {

      this.personService.getPersons(url).pipe(take(1)).subscribe(
        data => {
          this.persons = data.results;
          this.personsFiltered = this.persons;

          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          this.modalAlertService.showAlertDanger('Não foi possível listar clientes');
        }
      );

    } else {
      this.modalAlertService.showAlertDanger('Erro ao recuperar clientes');
      this.authService.doLogout();
    }

  }

  /**
   * Busca os itens paginados da próxima página.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Busca os itens paginados da página anterior.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização com o ID fornecido.
   * @param id O ID da pessoa para atualizar.
   */
  readId = (id) => {
    this.router.navigate(['/persons/update', id]);
  }

  /**
   * Navega para a página de criação de uma nova pessoa.
   */
  create = () => {
    this.router.navigate(['/person/create']);
  }

  /**
   * Manipula a exclusão de uma pessoa.
   * @param item A pessoa a ser excluída.
   */
  onDelete = (item) => {

    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissão
    if (this.authService.checkPermission('delete_person') == false) {
      this.router.navigate(['/403']);
    };

    this.selectedItem = item;
    // Exibe um modal de confirmação
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este cliente'
    );
    result$.asObservable()
      .pipe(
        take(1),
        // Se o usuário confirmar, realiza a exclusão usando o serviço
        switchMap(result => result ? this.personService.deletePerson(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o item da lista após a exclusão bem-sucedida
          let index;
          this.persons.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.persons.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }
}

<section class="section">
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
        <div class="login-brand">
          <img [src]="logo_wl" alt="logo" width="160" class="mt-2" />
        </div>

        <div class="card card-primary">
          <div class="card-header">
            <h4>Selecione o cliente:</h4>
          </div>

          <div class="card-body">
            <form method="POST">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <select
                    [(ngModel)]="selected_source"
                    (change)="changeSource()"
                    name="source"
                    id="id_source"
                    class="form-control"
                  >
                    <option *ngFor="let item of source" [value]="item">
                      {{ item.toUpperCase() }}
                    </option>
                  </select>
                </div>
                <div class="d-flex justify-content-start align-items-center mt-2" *ngIf="errorMsg">
                  <i class="fa-solid fa-circle-exclamation mx-2"></i>
                  <span>Falha encontrada ao listar bases do cliente</span>
                </div>

                <div class="input-group mt-3" id="company_div" name="company_div" *ngIf="companies?.length > 1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fa fa-building"></i>
                    </div>
                  </div>
                  <select [(ngModel)]="selected" name="client" id="id_client" class="form-control">
                    <option value="" disabled selected>-- Selecione --</option>
                    <option *ngFor="let item of companies" [value]="item.id">
                      <ng-container *ngIf="item.person.is_physical; else elseBlock">
                        {{ item.person.first_name }}&nbsp;{{ item.person.last_name }}
                      </ng-container>
                      <ng-template #elseBlock>
                        {{ item.person.trade_name }}
                      </ng-template>
                    </option>
                  </select>
                </div>
              </div>

              <div class="d-flex flex-column">
                <button class="btn btn-primary mx-0 py-2" [disabled]="errorMsg" (click)="setCompany()">Selecionar</button>

                <div class="d-flex justify-content-center align-items-center my-3" routerLink="/dashboard">
                  <i class="fa-solid fa-chevron-left mx-2"></i>
                  <span class="pointer">Voltar</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section mx-3">
    <div class="section-header">
        <h1 class="mt-3">{{ layout.aliasTitle('Alarmes' )}}</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active"><a href="">Dashboard</a></div>
            <div class="breadcrumb-item">Natureza da Medição</div>
        </div> -->
    </div>

    <div class="section-body">
        <div class="row">
            <div class="col-12 ">
                <div class="card">
                    <div class="card-header">
                        <h4><a class="btn btn-primary" routerLink='/plant/alarm/create'>Novo</a></h4>
                        <div class="card-header-action">
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-md">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="d-flex justify-content-between">
                                                <span>Nome</span>
                                                <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Nome')"></i>
                                              </div>
                                            <ng-container *ngTemplateOutlet="options"></ng-container>
                                        </th>
                                        <th class="w-25">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of alarmListFiltered | slice:offset:limit">
                                        <td>
                                            {{ item.title }}
                                        </td>
                                        <td>
                                            <app-default-icon-button [tooltiptext]="'Editar'" [type]="'warning'" (pressed)="readId(item.id)">
                                                <i class="fa fa-edit fa-lg mx-0"></i>
                                              </app-default-icon-button>
                        
                                             <app-default-icon-button [tooltiptext]="'Excluir'" [type]="'danger'" (pressed)="onDelete(item)">
                                              <i class="fa fa-trash fa-lg mx-0"></i>
                                             </app-default-icon-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                          <app-paginator-control [content]="alarmListFiltered" (change)="onPaginate($event)"></app-paginator-control>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #options>
    <app-table-filters-popup 
    [visible]="isOptionsOpened" 
    [field]="selectedFilter" 
    [hasStatus]="false" 
    [hasSearch]="true"
    [hasOrdernator]="true"
    (orderBy)="onOrderBy($event)" 
    (close)="onHideFilters()" 
    (search)="onSearch($event)"
    (clear)="onClearFilters()"
    (status)="onFilterBoolean($event)">
    </app-table-filters-popup>
</ng-template>
  
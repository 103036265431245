import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scenery-comparison',
  templateUrl: './scenery-comparison.component.html',
  styleUrls: ['./scenery-comparison.component.css']
})
export class SceneryComparisonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

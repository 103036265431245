import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { StoreInstanceConfService } from '../../instance-configuration/stores/store-instance-conf.service';

@Component({
  selector: 'app-customize',
  templateUrl: './plant-container.component.html',
  styleUrls: ['./plant-container.component.css'],
})
export class PlantContainerComponent implements OnInit, DoCheck {
  selectedIndex: number = null;
   items: any[] = [
    {
      id: 0,
      label: 'Local de medição',
      alias: 'Local de medição',
      permission: 'view_point',
      key: 'PON'
    },
    {
      id: 1,
      label: 'Setor',
      alias: 'Setor',
      permission: 'view_sector',
      key: 'SET'
    },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService,
    public storeInstance: StoreInstanceConfService,
  ) {}

  ngOnInit(): void {
    this.onVerifyPermissions();
    this.onSetFirstAvailableTab();
  }

  ngDoCheck(): void {
    this.storeInstance.sectorTabs.map((item) => {
      item.alias = this.layout.aliasTitle(item.label);
    });
  }

  onVerifyPermissions() {
    const permissions = JSON.parse(this.authService.getPermissions()) as any[];

    this.items.map((rp) =>
      permissions.some((pe) => {
        if (rp.permission == pe.codename) {
          rp.available = true;
        }
      }),
    );
  }

  onSetFirstAvailableTab() {
    const availableItems = this.items.filter((item) => item.available == true);

    if (availableItems.length == 0) {
      this.router.navigate(['/403']);
    }

    if (availableItems.length > 0) {
      this.setTabIndex(availableItems[0].id);
    }
  }

  setTabIndex(index: number) {
    this.selectedIndex = index;
    console.log(this.selectedIndex);
    
  }
}

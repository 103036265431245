<div class="input-group-prepend dependson w-100">
    <ng-container *ngIf="hasLabel">
      <span *ngIf="getIcon() != ''; else elseIcon" class="{{ class_label }} input-group-text" [style]="{ style_label }">
          <i class='{{ getIcon() }}'></i>
      </span>
      <ng-template #elseIcon>
          <span class="{{ class_label }} input-group-text" [style]="{ style_label }" ><b>{{getLabel()}}</b></span>
      </ng-template>
    </ng-container>
    <ng-select
      #selectref
      [id]="getId()"
      [name]="getId()"
      class="ic_selectcomponent"
      [class.w-100]="fullwidth"
      [items]="itemlist"
      groupBy="grouping"
      bindLabel="name"
      placeholder="{{getLabel()}}{{obg == 'true'? '*' : ''}} (Todos)"
      appendTo="body"
      [multiple]="true"
      notFoundText = "Nenhum item encontrado"
      maxSelectedItems="{{ limit }}"
      [clearOnBackspace] = "true"
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="selectedItemsCollection"
      (change)="onSelectItem($event)"
      (open)="onOpen()"
      (keyup)="onKeyPress($event)"
      >
      <ng-template ng-header-tmp >
        <div >
          <button class="btn btn-link" (click)="onSelectAll()">Selecionar Todos</button>
          <button class="btn btn-link" (click)="onClearAll()">Limpar Todos</button>
        </div>
      </ng-template>
    </ng-select>
</div>
<div class="message-feedback" [ngClass]="{ 'invalid-option': validateOption }">
  Por favor, selecione pelo menos uma opção
</div>

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { HelpService } from './help.service';
import { BaseFunc } from '../../shared/base-func/base-func';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomReportsComponent } from '../../reports/custom-reports/custom-reports.component';

import Speech from 'speak-tts'

import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';

import { take } from 'rxjs/operators';
import { CustomReportsService } from '../../reports/custom-reports/custom-reports.service';
import { TourService } from 'src/app/core/services/tour/tour.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent extends BaseFunc implements OnInit {
  @Input() key: string = null;
  @Input() tourMode: boolean = false;
  @Input() type: string = 'report';
  @Input() callback: string = null;

  hasTTS: boolean = false;
  speech;

  link;
  cfuncTour;
  isPaginationTour: string = localStorage.getItem('pagination_tour');

  global: Global = new Global();

  constructor(
    private service: HelpService,
    private authService: AuthService,
    public tourService: TourService
  ) { super(); }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    var callback = (self.callback == null ? null : self.callback);
    self.cfuncTour = (callback != null ? new Function('position', 'direction', callback) : null);

    try {
      self.speech = new Speech() // will throw an exception if not browser supported
      self.hasTTS = self.speech.hasBrowserSupport();
    }
    catch (e) {
      this.hasTTS = false;
    }

    if (this.key != null)
      this.service.getHelpConfig(this.key, this.tourMode, this.type, this.callback).pipe(take(1)).subscribe(
        data => {
          var tags = data.tags;
          tags.forEach(function (e, i) {
            var span = self.$j('<span class="helpTag"></span>');
            if (e.position)
              self.$j(span).attr('position', e.position);
            self.$j(span).attr('help_type', e.group);
            self.$j(span).attr('ref_to', e.path);

            self.$j(span).append(self.$j('<h2 class="help_title">' + e.title + '</h2>'));
            self.$j(span).append(self.$j('<p class="help_body">' + e.body + '</p>'));
            if (e.examples)
              self.$j(span).append(self.$j('<b>Ex.:</b><p class="help_examples">' + e.examples + '</p>'));
            else
              self.$j(span).append(self.$j('<p class="help_examples"></p>'))

            if (self.$j(e.path).length > 0)
              self.$j(e.path).after(span);
          });
        },
        error => {
          console.log('Aconteceu um erro.', error.message);
        },
        () => {
        }
      );
  }
  tourEngine = function () {
    var self = this;
    /*
    the json config obj.
    name: the class given to the element where you want the tooltip to appear
    bgcolor: the background color of the tooltip
    color: the color of the tooltip text
    text: the text inside the tooltip
    time: if automatic tour, then this is the time in ms for this step
    position: the position of the tip. Possible values are
    TL	top left
    TR  top right
    BL  bottom left
    BR  bottom right
    LT  left top
    LB  left bottom
    RT  right top
    RB  right bottom
    T   top
    R   right
    B   bottom
    L   left
    */

    var config = [],
      //define if steps should change automatically
      autoplay = false,
      //timeout for the step
      showtime,
      //current step of the tour
      step = 0, total_steps = 0;
    //total number of steps

    //calculate_tour()
    //show the tour controls
    var isRight = self.direction == 'True';
    showControls(isRight);

    /*
    we can restart or stop the tour,
    and also navigate through the steps
    */
    self.$j('#activatetour').click(startTour);
    self.$j('#canceltour').click(endTour);
    self.$j('#endtour').click(endTour);
    self.$j('#restarttour').click(restartTour);
    self.$j('#nextstep').click(nextStep);
    self.$j('#prevstep').click(prevStep);

    function calculate_tour() {
      config = [];
      self.$j('.helpTag').forEach(function (e) {
        var idHelpTag = self.$j(e).prev();
        if (!self.$j(idHelpTag).is(':visible') && !self.$j(idHelpTag).hasClass('alwaysTour'))
          return;

        var positionHelpTag = "TL";
        if (self.$j(e)[0].hasAttribute('position')) { positionHelpTag = self.$j(e).attr('position'); }
        if (self.$j(e).hasClass('reportHelpTag')) { positionHelpTag = "BL"; }
        config.push({
          "name": idHelpTag,
          "bgcolor": "white",
          "color": "black",
          "position": positionHelpTag,
          "text": self.$j(e).html(),
          "time": 5000
        });
      });

      let paginationTour = document.body.contains(document.querySelector('.pagination-tour'));

      if (self.isPaginationTour == '"cubo"') {
        localStorage.setItem('pagination_tour', JSON.stringify(false));
      } else if (self.isPaginationTour == 'true') {
        var cubeRef = document.querySelector('[href^="cube"');
        if (cubeRef != null ) {
          total_steps = config.length + 1;
          cubeRef.classList.add('pagination-tour');
          localStorage.setItem('pagination_tour', JSON.stringify("cubo"));
        }
      } else {
        if (paginationTour) { total_steps = config.length + 1 }
        else { total_steps = config.length; }
      }




    }

    function startTour() {
      var t = 1;
      if (self.cfuncTour) {
        self.cfuncTour('init');
        t = 500;
      }

      setTimeout(function () {
        calculate_tour();
        self.$j('#activatetour').remove();
        self.$j('#endtour,#restarttour').show();
        if (!autoplay && total_steps > 1)
          self.$j('#nextstep,#prevstep').show();
        showOverlay();
        nextStep();
      }, t);
    }

    function nextStep() {
      if (!autoplay) {
        if (step > 0)
          self.$j('#prevstep').prop('disabled', false);
        else
          self.$j('#prevstep').prop('disabled', true);
        if (step == total_steps - 1)
          self.$j('#nextstep').prop('disabled', true);
        else
          self.$j('#nextstep').prop('disabled', false);
      }
      if (step >= total_steps) {
        //if last step then end tour
        endTour();
        return false;
      }
      ++step;
      self.$j('#fromStep').html(step);
      let paginationTour = document.body.contains(document.querySelector('.pagination-tour'));

      if (paginationTour && step > config.length) {
        let selectPagination = self.$j('.pagination-tour');
        selectPagination[0].click();

        /* document.querySelector('.idioma').classList.add('pagination-tour'); */
      }

      self.$j('#toStep').html(total_steps);
      showTooltip('go');
    }

    function prevStep() {
      if (!autoplay) {
        if (step > 2)
          self.$j('#prevstep').prop('disabled', false);
        else
          self.$j('#prevstep').prop('disabled', true);
        if (step == total_steps)
          self.$j('#nextstep').prop('disabled', false);
      }
      if (step <= 1)
        return false;
      --step;
      self.$j('#fromStep').html(step);
      self.$j('#toStep').html(total_steps);
      showTooltip('back');
    }

    function endTour() {
      step = 0;
      if (autoplay) clearTimeout(showtime);
      removeTooltip();
      hideControls();
      hideOverlay();
    }

    function restartTour() {
      step = 0;
      if (autoplay) clearTimeout(showtime);
      nextStep();
    }

    function showTooltip(direction) {
      //remove current tooltip
      removeTooltip();

      var step_config = config[step - 1];
      var $elem = self.$j(step_config.name);

      if (!$elem.is(':visible') && self.cfuncTour)
        self.cfuncTour($elem, direction);

      if (autoplay)
        showtime = setTimeout(nextStep, step_config.time);

      var bgcolor = step_config.bgcolor;
      var color = step_config.color;

      var $tooltip = self.$j('<div id="tour_tooltipHelpTag" class="tooltipHelpTag"><p>' + step_config.text + '</p><span class="tooltipHelpTag_arrow"></span></div>');
      $tooltip.css({
        'display': 'none',
        'background-color': bgcolor,
        'color': color
      });

      //position the tooltipHelpTag correctly:

      //the css properties the tooltipHelpTag should have
      var properties = {};

      var tip_position = step_config.position;

      //append the tooltipHelpTag but hide it
      self.$j('body').prepend($tooltip);

      //get some info of the element
      var e_w = $elem.outerWidth();
      var e_h = $elem.outerHeight();
      var e_l = $elem.offset().left;
      var e_t = $elem.offset().top;

      switch (tip_position) {
        case 'TL':
          properties = {
            'left': e_l,
            'top': e_t + e_h + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_TL');
          break;
        case 'TR':
          properties = {
            'left': e_l + e_w - $tooltip.width() + 'px',
            'top': e_t + e_h + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_TR');
          break;
        case 'BL':
          properties = {
            'left': e_l + 'px',
            'top': e_t - $tooltip.height() + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_BL');
          break;
        case 'BR':
          properties = {
            'left': e_l + e_w - $tooltip.width() + 'px',
            'top': e_t - $tooltip.height() + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_BR');
          break;
        case 'LT':
          properties = {
            'left': e_l + e_w + 'px',
            'top': e_t + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_LT');
          break;
        case 'LB':
          properties = {
            'left': e_l + e_w + 'px',
            'top': e_t + e_h - $tooltip.height() + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_LB');
          break;
        case 'RT':
          properties = {
            'left': e_l - $tooltip.width() + 'px',
            'top': e_t + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_RT');
          break;
        case 'RB':
          properties = {
            'left': e_l - $tooltip.width() + 'px',
            'top': e_t + e_h - $tooltip.height() + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_RB');
          break;
        case 'T':
          properties = {
            'left': e_l + e_w / 2 - $tooltip.width() / 2 + 'px',
            'top': e_t + e_h + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_T');
          break;
        case 'T_MARGIN':
          properties = {
            'left': e_l + e_w / 2 - $tooltip.width() / 2 + 'px',
            'top': (e_t + 30) + e_h + 'px'
          }
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_T');
          break;
        case 'R':
          properties = {
            'left': e_l - $tooltip.width() + 'px',
            'top': e_t + e_h / 2 - $tooltip.height() / 2 + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_R');
          break;
        case 'B':
          properties = {
            'left': e_l + e_w / 2 - $tooltip.width() / 2 + 'px',
            'top': e_t - $tooltip.height() + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_B');
          break;
        case 'L':
          properties = {
            'left': e_l + e_w + 'px',
            'top': e_t + e_h / 2 - $tooltip.height() / 2 + 'px'
          };
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_L');
          break;
        case 'L_MARGIN':
          properties = {
            'left': (e_l + 10) + e_w + 'px',
            'top': e_t + e_h / 2 - $tooltip.height() / 2 + 'px'
          }
          $tooltip.find('span.tooltipHelpTag_arrow').addClass('tooltipHelpTag_arrow_L');
          break;
      }

      /*
      if the element is not in the viewport
      we scroll to it before displaying the tooltipHelpTag
      */
      var w_t = self.$j(window).scrollTop();
      var w_b = self.$j(window).scrollTop() + self.$j(window).height();
      //get the boundaries of the element + tooltipHelpTag
      var b_t = parseFloat(properties['top']);

      if (e_t < b_t)
        b_t = e_t;

      var b_b = parseFloat(properties['top']) + $tooltip.height();
      if ((e_t + e_h) > b_b)
        b_b = e_t + e_h;


      if ((b_t < w_t || b_t > w_b) || (b_b < w_t || b_b > w_b)) {
        self.$j('html, body') //.stop()
          .animate({ scrollTop: b_t }, 500, function () {
            //need to reset the timeout because of the animation delay
            if (autoplay) {
              clearTimeout(showtime);
              showtime = setTimeout(nextStep, step_config.time);
            }
            //show the new tooltip
            $tooltip.css(properties);
            $tooltip.show();
            self.scrollToTooltip();
          });
      }
      else
        //show the new tooltip
        $tooltip.css(properties);
      $tooltip.show();
      self.scrollToTooltip()
    }

    function removeTooltip() {
      self.$j('#tour_tooltipHelpTag').remove();
    }

    function showControls(right) {
      var $tourcontrols = '<div id="tourcontrols" class="tourcontrols ' + (right ? 'rightD' : 'leftD') + '">';
      $tourcontrols += '<p style="text-align: center;">' + 'Modo Tour' + '</p>';
      //$tourcontrols += '<span class="buttonHelpTag" id="activatetour">' + ckTrl('Iniciar Tour') + '</span>';
      if (!autoplay) {
        $tourcontrols += '<div class="navHelpTag"><button class="buttonHelpTag" id="prevstep" style="display:none;">< ' + ('Anterior') + '</button>';
        $tourcontrols += '<button class="buttonHelpTag" id="nextstep" style="display:none;">' + 'Próximo' + ' ></button></div>';
      }

      $tourcontrols += '<div style="text-align: center;"> <span id="fromStep" name="fromStep">1</span> ' + 'de' + ' <span id="toStep" name="toStep">1</span></div>';
      $tourcontrols += '<a id="restarttour" style="display:none;">' + ('Reiniciar Tour') + '</a>';
      $tourcontrols += '<a id="endtour" style="display:none;">' + ('Finalizar Tour') + '</a>';
      $tourcontrols += '<span class="closeHelpTag" id="canceltour">X</span>';
      $tourcontrols += '</div>';

      self.$j('body').prepend(self.$j($tourcontrols));
      var from = right ? { 'right': 30 } : { 'left': 1 };
      self.$j('#tourcontrols').animate(from, 500);
      startTour();
    }

    function hideControls() {
      self.$j('#tourcontrols').remove();
    }

    function showOverlay() {
      self.$j('#tour_overlay').remove();
      var $overlay = self.$j('<div id="tour_overlay" class="overlay"></div>');
      self.$j('body').prepend($overlay);
    }

    function hideOverlay() {
      self.$j('#tour_overlay').remove();
    }
  }

  openPopup = (pagina, largura, altura) => {

    //pega a resolução do visitante
    var w = screen.width;
    var h = screen.height;

    //divide a resolução por 2, obtendo o centro do monitor
    var meio_w = w / 2;
    var meio_h = h / 2;

    //diminui o valor da metade da resolução pelo tamanho da janela, fazendo com q ela fique centralizada
    var altura2 = altura / 2;
    var largura2 = largura / 2;
    var meio1 = meio_h - altura2;
    var meio2 = meio_w - largura2;

    //abre a nova janela, já com a sua devida posição
    window.open(pagina, 'help', 'height=' + altura + ', width=' + largura + ', top=' + meio1 + ', left=' + meio2 + ',toolbar=no, menubar=no, scrollbars=yes');
  }

  windowHelpMode = (pagina, largura, altura) => {
    var self = this;
    if (pagina && pagina != '')
      return self.openPopup("/help_index/" + pagina, largura, altura);
    else if (self.type == 'report')
      return self.openPopup("/help_index/custom-report__" + self.key, largura, altura);

  }

  tourHelpMode = () => {
    this.tourEngine();
  }

  ttsHelpMode = () => {
    var self = this;
    var messages = [];
    var ctx_map: { [key: string]: any } = {};
    var global_index = 0;
    var tags = self.$j('.helpTag').filter(e => {
      var idHelpTag = self.$j(e).prev();
      return (self.$j(idHelpTag).is(':visible') || self.$j(idHelpTag).hasClass('alwaysTour'));
    }).map(x => [self.$j(x).attr('help_type'), self.$j(x).find('.help_title').text(), self.$j(x).find('.help_body').text(), self.$j(x).find('.help_examples').text(), self.$j(x).attr('ref_to')]);
    var tags_head = tags.filter(x => x[0] == 'Head');
    var tags_filters = tags.filter(x => x[0] == 'Filters');
    var tags_component = tags.filter(x => x[0] == 'Component');
    var tags_body = tags.filter(x => x[0] == 'Body');

    self.speech.init({ volume: 1, lang: "pt-BR", rate: 1, pitch: 1, splitSentences: true });

    self.global

    var highlightDiv = function (ids) {
      self.$j('.highlight').removeClass('highlight');

      if (typeof (ids) == 'string')
        self.$j(ids).addClass('highlight');
      else if (ids instanceof Array == true)
        ids.forEach(x => self.$j(x).addClass('highlight'));
    };

    var speakNow = function (message, index, ctx_track) {
      if (!ctx_map[ctx_track]) {
        console.log('Nova fala no índice ' + index);
        global_index = index;
        if (index == 0)
          self.$j('#rewindPlayer').attr('disabled', 'disabled');
        else
          self.$j('#rewindPlayer').removeAttr('disabled');

        if (index + 1 == message.length)
          self.$j('#forwardPlayer').attr('disabled', 'disabled');
        else
          self.$j('#forwardPlayer').removeAttr('disabled');

        self.speech.speak({
          text: message[index][0],
          queue: false,
          listeners: {
            onstart: () => {
              highlightDiv(message[index][1] != null ? message[index][1] : []);
            },
            onend: () => {
              if (index + 1 < message.length)
                speakNow(message, index + 1, ctx_track)
              else if (index + 1 == message.length)
                hidePlayer(ctx_track);
            },
          }
        });
      }
      else
        console.log('Finalizado contexto de execução');
    };

    var showPlayer = function () {
      self.$j('#tour_overlay').remove();
      var $overlay = self.$j('<div id="tour_overlay" class="overlay"></div>');
      self.$j('body').prepend($overlay);

      var $playercontrols = `
      <div id="playercontrols" class="playercontrols ">
        <div class="navHelpTag">
          <button class="buttonHelpTag xlarge" id="rewindPlayer" >◀◀</button>
          <button class="buttonHelpTag xlarge" id="stopPlayer" >◼</button>
          <button class="buttonHelpTag xlarge" id="pausePlayer" >▌▌</button>
          <button style="display:none;" class="buttonHelpTag xlarge" id="resumePlayer" >▶</button>
          <button class="buttonHelpTag xlarge" id="forwardPlayer" >▶▶</button>
        </div>
      </div>`;

      self.$j('body').prepend(self.$j($playercontrols));
      var from = { 'right': 30 };
      self.$j('#playercontrols').animate(from, 500);

      self.$j('#rewindPlayer').click(function () {
        self.speech.cancel();
        cancelGuid(null);
        var new_guid = self.global.createGuid();
        ctx_map[new_guid] = false;
        speakNow(messages, --global_index, new_guid);
      });
      self.$j('#forwardPlayer').click(function () {
        self.speech.cancel();
        cancelGuid(null);
        var new_guid = self.global.createGuid();
        ctx_map[new_guid] = false;
        speakNow(messages, ++global_index, new_guid);
      });
      self.$j('#pausePlayer').click(function () {
        self.$j('#resumePlayer').show();
        self.$j('#pausePlayer').hide();
        self.speech.pause();
      });
      self.$j('#stopPlayer').click(function () {
        self.speech.cancel();
        hidePlayer(null);
      });
      self.$j('#resumePlayer').click(function () {
        self.$j('#resumePlayer').hide();
        self.$j('#pausePlayer').show();
        self.speech.resume();
      });

    };

    var hidePlayer = function (ctx_track) {
      self.$j('#tour_overlay').remove();
      self.$j('#playercontrols').remove();
      cancelGuid(ctx_track);
      highlightDiv([]);
    };

    var cancelGuid = function (ctx_track) {
      if (!ctx_track) {
        for (var i in ctx_map) {
          if (ctx_map.hasOwnProperty(i))
            ctx_map[i] = true;
        }
      }
      else
        ctx_map[ctx_track] = true;
    }

    messages.push(["Seja bem vindo à ajuda do sistema Industry Quer", null]);
    if (tags_head.length > 0) {
      messages.push(['Este é a opção ' + tags_head[0][1] + ' do sistema Industry Quer', '.page-title']);
      tags_head[0][2].split('.').forEach(x => messages.push([x, '.page-title']));
    }

    if (tags_filters.length > 0) {
      var filter_names = tags_filters.map(x => x[1]).join(', ');
      var curr = tags_filters.length > 1 ?
        "Existem " + tags_filters.length + " filtros nesta tela. Que são: " + filter_names :
        "Existe somente um filtro nesta tela chamado " + filter_names;
      curr.split('.').forEach(x => messages.push([x, '.form-body']));
      tags_filters.forEach(x => {
        messages.push(['Sobre o filtro ' + x[1], x[4]]);
        x[2].split('.').forEach(y => messages.push([y, x[4]]))
      });

    }

    if (tags_component.length > 0) {
      var component_names = tags_component.map(x => x[1]).join(', ');
      var ids = tags_component.map(x => x[4]);
      var curr = tags_component.length > 1 ?
        "Destaque para " + tags_component.length + " componentes nesta tela. Que são: " + component_names :
        "Destaque para o componente " + component_names;
      curr.split('.').forEach(x => messages.push([x, ids]));
      tags_component.forEach(x => {
        messages.push(['Sobre o componente ' + x[1], x[4]]);
        x[2].split('.').forEach(y => messages.push([y, x[4]]))
      });
    }

    if (tags_body.length > 0) {
      var body_names = tags_body.map(x => x[1]).join(', ');
      var curr = tags_body.length > 1 ?
        "Os dados podem ser visualizados através de " + tags_body.length + " seções. Que são: " + body_names :
        "Os dados podem ser visualizados através da seção " + body_names;
      curr.split('.').forEach(x => messages.push([curr, '.report_body']));
      tags_body.forEach(x => {
        messages.push(['Sobre a seção ' + x[1], x[4]]);
        x[2].split('.').forEach(y => messages.push([y, x[4]]))
      });
    }

    messages = messages.filter(x => x[0].trim() != '');

    //Inicializa mapa de contexto dessa execução
    var guid = self.global.createGuid();
    ctx_map[guid] = false;
    showPlayer();
    speakNow(messages, 0, guid);
  }

  //Alteração do estado do Tour na tela de Dashboard(tela inicial)
  //Na tela de apresentação de Tour é apresentado um checkbox responsável por alterar o aparecimento do Tour
  //Caso o checkbox esteja checado o tour é desativado
  //Em caso de algum evento inesperado há uma mensagem de erro apresentada no console
  changeShowTour = (event) => {
    this.service.updateTourStatus(!event.target.checked).pipe(take(1)).subscribe(
      data => {
        this.authService.setShowTour(!event.target.checked);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  scrollToTooltip() {
    let isHelpTag = document.querySelector('.tooltipHelpTag');
    let isHelpTagExis = isHelpTag.contains(isHelpTag);
    let positionTooltip = document.querySelector('.tooltipHelpTag').getBoundingClientRect();
    if (isHelpTagExis) {
      window.scroll({
        top: positionTooltip.top - 80,
        behavior: "smooth",
      });
      const NavHeigth = document.querySelector('nav.sidebar-nav').getBoundingClientRect().height;
      if (NavHeigth >= window.innerHeight) {
        if (positionTooltip.left <= 270) {
          document.querySelector('.scroll-sidebar.ps-container.ps-theme-default').scrollTop = positionTooltip.top;
        }
      }
    }
  }
}

<section class="section mx-3 my-3">
    <div class="section-header cm-2">
        <h1>{{ layout.aliasTitle('Empresa') }}</h1>
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header cm mt-3">
                    <h4>Editar {{ layout.aliasTitle('Empresa') }} </h4>
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="companyForm">
                        <div class="form-row cm-2">
                            <div class="form-group col-md-6">
                                <label for="company_division">Unidade Industrial</label>
                                <select class="form-control" [value]="company_division_selected"
                                    formControlName="company_division" [ngClass]="displayFieldCss('company_division')">
                                    <option *ngFor="let item of companyDivisions" value="{{item.company_division_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione uma unidade de grupo empresarial
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="timezone">Timezones</label>
                                <select class="form-control" formControlName="timezone"
                                    [ngClass]="displayFieldCss('timezone')">
                                    <option *ngFor="let item of timezones" value="{{item.id}}">
                                        {{item.value}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione um timezone
                                </div>
                            </div>
                        </div>

                        <app-person-update (created)="update()" *ngIf="personId" [personId]="personId" [parentFormStatus]="companyForm.valid"></app-person-update>
                    </form>
                </div>
                
            </div>
        </div>
    </div>
</section>

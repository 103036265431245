

<!DOCTYPE html>
<html>
<head>
<link rel="stylesheet" type="text/css" href="../pubsub/pubsub.component.css">
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>

</head>
<body>

<p><a href="#"><span class="glyphicon glyphicon-circle-arrow-left" id="return"></span></a></p>
<h1 id ="titulo">Painel de Publicações</h1>
<br><br>


<table id="tabela_publicacoes">
  <!-- Linha Mainservice -->
  <tr>
      <th>Serviço</th>
      <th>Publicar</th>
      <th>Integração</th>
      <th>Última Atualização</th>
      <th>Entidades</th>
  </tr>
  <tr>
      <td rowspan="5">
          Mainservice
      </td>
      <td rowspan="5">
          <div class="iconeAlinhamentoNoCheck">
            <button type="button" class="btn btn-info" (click)="mainPub()">
              <i class="fa fa-envelope-square"></i>
              Assíncrono
            </button><br/>
            <button type="button" class="btn btn-info" (click)="mainPub(true)">
              <i class="fa fa-envelope-square"></i>
              Síncrono
            </button>
          </div>
      </td>
      <td>Reposervice</td>
      <td>{{main_status.repo ? main_status.repo.last : '-' }}</td>
      <td>{{main_status.repo ? main_status.repo.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>{{main_status.userauth ? main_status.userauth.last : '-' }}</td>
    <td>{{main_status.userauth ? main_status.userauth.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>{{main_status.mail ? main_status.mail.last : '-' }}</td>
    <td>{{main_status.mail ? main_status.mail.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>{{main_status.bot ? main_status.bot.last : '-' }}</td>
    <td>{{main_status.bot ? main_status.bot.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Formservice</td>
    <td>{{main_status.form ? main_status.form.last : '-' }}</td>
    <td>{{main_status.form ? main_status.form.entities : '-' }}</td>
  </tr>

  <!-- Linha Reposervice -->
  <tr>
      <td rowspan="5">
          Reposervice
      </td>
      <td rowspan="5">
        <div class="iconeAlinhamentoNoCheck">
          <button type="button" class="btn btn-info"
            (click)="repoPub()">
            <i class="fa fa-envelope-square"></i>
            Assíncrono
          </button><br/>

          <button type="button" class="btn btn-info"
            (click)="repoPub(true)">
            <i class="fa fa-envelope-square"></i>
            Síncrono
          </button>
        </div>
      </td>
      <td>Mainservice</td>
      <td>{{repo_status.main ? repo_status.main.last : '-' }}</td>
      <td style="display: block;overflow-y: auto;max-height: 100px;">{{repo_status.main ? repo_status.main.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>{{repo_status.userauth ? repo_status.userauth.last : '-' }}</td>
    <td>{{repo_status.userauth ? repo_status.userauth.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>{{repo_status.mail ? repo_status.mail.last : '-' }}</td>
    <td>{{repo_status.mail ? repo_status.mail.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>{{repo_status.bot ? repo_status.bot.last : '-' }}</td>
    <td>{{repo_status.bot ? repo_status.bot.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Formservice</td>
    <td>{{repo_status.form ? repo_status.form.last : '-' }}</td>
    <td>{{repo_status.form ? repo_status.form.entities : '-' }}</td>
  </tr>

  <!-- Linha Userauthservice -->
  <tr>
      <td rowspan="5">
          Userauthservice
      </td>
      <td rowspan="5">
        <div class="iconeAlinhamentoNoCheck">
          <button type="button" class="btn btn-info"
            (click)="userPub()">
            <i class="fa fa-envelope-square"></i>
            Assíncrono
          </button><br/>

          <button type="button" class="btn btn-info"
            (click)="userPub(true)">
            <i class="fa fa-envelope-square"></i>
            Síncrono
          </button>
        </div>
      </td>
      <td>Reposervice</td>
      <td>{{user_status.repo ? user_status.repo.last : '-' }}</td>
      <td>{{user_status.repo ? user_status.repo.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mainservice</td>
    <td>{{user_status.main ? user_status.main.last : '-' }}</td>
    <td style="display: block;overflow-y: auto;max-height: 100px;">{{user_status.main ? user_status.main.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>{{user_status.mail ? user_status.mail.last : '-' }}</td>
    <td>{{user_status.mail ? user_status.mail.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>{{user_status.bot ? user_status.bot.last : '-' }}</td>
    <td>{{user_status.bot ? user_status.bot.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Formservice</td>
    <td>{{user_status.form ? user_status.form.last : '-' }}</td>
    <td>{{user_status.form ? user_status.form.entities : '-' }}</td>
  </tr>




  <!-- Linha Mailservice -->
  <tr>
      <td rowspan="5">
          Mailservice
      </td>
      <td rowspan="5">
        <div class="iconeAlinhamentoNoCheck">
          <button type="button" class="btn btn-info"
            (click)="mailPub()">
            <i class="fa fa-envelope-square"></i>
            Assíncrono
          </button><br/>

          <button type="button" class="btn btn-info"
            (click)="mailPub(true)">
            <i class="fa fa-envelope-square"></i>
            Síncrono
          </button>
        </div>
      </td>
      <td>Reposervice</td>
      <td>{{mail_status.repo ? mail_status.repo.last : '-' }}</td>
      <td>{{mail_status.repo ? mail_status.repo.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mainservice</td>
    <td>{{mail_status.main ? mail_status.main.last : '-' }}</td>
    <td style="display: block;overflow-y: auto;max-height: 100px;">{{mail_status.main ? mail_status.main.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>{{mail_status.userauth ? mail_status.userauth.last : '-' }}</td>
    <td>{{mail_status.userauth ? mail_status.userauth.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>{{mail_status.bot ? mail_status.bot.last : '-' }}</td>
    <td>{{mail_status.bot ? mail_status.bot.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Formservice</td>
    <td>{{mail_status.form ? mail_status.form.last : '-' }}</td>
    <td>{{mail_status.form ? mail_status.form.entities : '-' }}</td>
  </tr>




  <!-- Linha Botservice -->
  <tr>
      <td rowspan="5">
          Botservice
      </td>
      <td rowspan="5">
        <div class="iconeAlinhamentoNoCheck">
          <button type="button" class="btn btn-info"
            (click)="botPub()">
            <i class="fa fa-envelope-square"></i>
            Assíncrono
          </button><br/>

          <button type="button" class="btn btn-info"
            (click)="botPub(true)">
            <i class="fa fa-envelope-square"></i>
            Síncrono
          </button>
        </div>
      </td>
      <td>Reposervice</td>
      <td>{{bot_status.repo ? bot_status.repo.last : '-' }}</td>
      <td>{{bot_status.repo ? bot_status.repo.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mainservice</td>
    <td>{{bot_status.main ? bot_status.main.last : '-' }}</td>
    <td style="display: block;overflow-y: auto;max-height: 100px;">{{bot_status.main ? bot_status.main.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>{{bot_status.userauth ? bot_status.userauth.last : '-' }}</td>
    <td>{{bot_status.userauth ? bot_status.userauth.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>{{bot_status.mail ? bot_status.mail.last : '-' }}</td>
    <td>{{bot_status.mail ? bot_status.mail.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Formservice</td>
    <td>{{bot_status.form ? bot_status.form.last : '-' }}</td>
    <td>{{bot_status.form ? bot_status.form.entities : '-' }}</td>
  </tr>


  <!-- Linha Formservice -->
  <tr>
      <td rowspan="5">
          Formservice
      </td>
      <td rowspan="5">
        <div class="iconeAlinhamentoNoCheck">
          <button type="button" class="btn btn-info"
            (click)="formPub()">
            <i class="fa fa-envelope-square"></i>
            Assíncrono
          </button><br/>
          <button type="button" class="btn btn-info"
            (click)="formPub(true)">
            <i class="fa fa-envelope-square"></i>
            Síncrono
          </button>
        </div>
      </td>
      <td>Reposervice</td>
      <td>{{form_status.repo ? form_status.repo.last : '-' }}</td>
      <td>{{form_status.repo ? form_status.repo.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mainservice</td>
    <td>{{form_status.main ? form_status.main.last : '-' }}</td>
    <td style="display: block;overflow-y: auto;max-height: 100px;">{{form_status.main ? form_status.main.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>{{form_status.userauth ? form_status.userauth.last : '-' }}</td>
    <td>{{form_status.userauth ? form_status.userauth.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>{{form_status.bot ? form_status.bot.last : '-' }}</td>
    <td>{{form_status.bot ? form_status.bot.entities : '-' }}</td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>{{form_status.mail ? form_status.mail.last : '-' }}</td>
    <td>{{form_status.mail ? form_status.mail.entities : '-' }}</td>
  </tr>

</table>



</body>
</html>

<div class="tab-content container-fluid" style="min-height: 550px">
  <app-button-how-to [sequence]="tourSequence"></app-button-how-to>

  <div id="reportformdiv" code="" name="reportformdiv" class="needs-validation">
      <h1 class="header-title">Criar formulário</h1>
      <div class="card customreport_card">
        <div class="row">
          <div class="col-4 tour-form-name">
            <small id="name">Nome do formulário*</small>
            <input type="text" class="form-control mb-3" id="crudname" placeholder="Digite aqui" maxlength="50" [(ngModel)]="toServer.name">

            <small for="divFormType" class="mb-2">Tipo de formulário*</small>
            <div id="divFormType" name="divFormType" class="divGroupCHECKBOX">
              <div class="d-flex align-items-center">
                <input class="radio-style" type="checkbox" value="DEFAULT" [(ngModel)]="toServer.type" name="asc-desc" id="asc">
                <label for="asc" class="mx-2 my-0">Padrão</label>
              </div>

              <div class="d-flex align-items-center">
                <input class="radio-style" type="checkbox" value="INLINE" [(ngModel)]="toServer.type" name="asc-desc" id="desc">
                <label for="desc" class="mx-2 my-0">Tabela</label>
              </div>
            </div>

          </div>
          <div class="col-4 tour-form-group">
            <small id="group">Grupo*</small>
            <input type="text" class="form-control" id="crudgroup" placeholder="Digite aqui" maxlength="50" [(ngModel)]="toServer.group">
          </div>

          <div class='col-4' [hidden]="!authService.isSuperUser()">
            <div class="d-flex flex-column tour-form-scope">
              <small id="description">
                Escopo do Formulário
              </small>
  
              <div class="custom-select" (click)="onHandleSelect()">
                <span class="badge badge-primary mr-1 mt-1 pointer" *ngFor="let item of pushedScopeNames" (click)="onRemoveSingle(item.id)">
                  {{ item.name }}
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </div>
            </div>

            <div class="d-flex flex-column border px-3 pt-3 pb-1 mt-1 bg-white container-vertical shadow-md tour-form-permissions" *ngIf="showAll">
              <div class="d-flex justify-content-between mb-2 tour-form-scope-search">
                <form [formGroup]="form" class="w-100 mr-1">
                  <input type="text" class="form-control mr-1" placeholder="Buscar" formControlName="search" (keyup)="onFilter()">
                </form>
                <button class="btn btn-sm btn-primary my-0 mx-0 tour-scope-close" (click)="onCloseFolders()">Fechar</button>
              </div>
              <ng-container >
                <small class="text-center mb-2" *ngIf="filteredScopesWithPermissions.length == 0">Nenhum perfil foi encontrado</small>
                <app-crud-scope-card *ngFor="let scope of filteredScopesWithPermissions; index as i" 
                [scope]="scope" 
                (fullChecked)="onFillPreviusSelectedScope($event)"
                (checked)="pushSelectedToCustomSelect($event)">
              </app-crud-scope-card>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="card customreport_card tour-form-created-fields" style="height: 485px">
        <div class="card-body">

          <div [style.visibility]=" fields.length != 0 ? 'visible' : 'hidden' ">
            <ul class="nav nav-pills ">
              <li class=" nav-item"> <a href="#navpills-1" class="nav-link active" data-toggle="tab" aria-expanded="false">Configuração</a> </li>
              <li class="nav-item"> <a href="#navpills-2" class="nav-link" data-toggle="tab" aria-expanded="false">Pré-Visualização {{ toServer.type != 'INLINE' ? ' lista' : ''}}</a> </li>
              <li class="nav-item" *ngIf="toServer.type != 'INLINE' "> <a href="#navpills-3" class="nav-link" data-toggle="tab" aria-expanded="false">Pré-Visualização cadastro</a> </li>
            </ul>
            <div class="tab-content br-n pn" style="background-color:white !important; height: 300px; overflow: auto">
              <div id="navpills-1" class="tab-pane active">

                <div class="row"  style="">
                    <div class="col-12">
                      <div role="alert" class="card card-hover alert alert-dismissible border" *ngFor="let fd of fields; let i = index">

                        <div class="card-body">
                          <h3 class="card-title">{{fd.name + (fd.obg ? ' *' : '') }}</h3>
                          <h5 class="card-title">{{ field_types[fd.type] + ( fd.type == 'AUTOCOMPLETE' ? ' ' + autocomplete_dict[fd.autocomplete_type]  : '' )  }}</h5>
                          <small class="card-text">{{fd.description}}</small>


                          <div class="mt-4" *ngIf="fd.items != null && fd.items.length > 0">
                            <div class="card border" *ngFor="let item of fd.items">
                              <div class="card-body">
                                {{item.text}}
                              </div>
                            </div>
                          </div>

                          <button type="button" aria-label="Close" class="close cursor h4" style=" margin-right: 45px;" data-toggle="modal" data-target="#right-bar-modal" (click)="editField(fd)" >
                            <i class="fas fa-cog"></i>
                          </button>
                          <button id="btn_dots_{{fd.id}}" type="button" class="close dropdown-toggle cursor h4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            <i class="mdi mdi-dots-vertical"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="btn_dots_{{fd.id}}">
                            <div class="downloadGraphSection_ inner_dropdown" style="margin: 15px;">
                              <button class="btn btn-block btn-md" (click)="duplicateField(i)">
                                <i class="mdi mdi-content-copy"></i>Duplicar
                              </button>
                              <button class="btn btn-block btn-md" [disabled]="i == 0" (click)="moveUpField(i)">
                                <i class="fas fa-angle-up"></i>Mover para cima
                              </button>
                              <button class="btn btn-block btn-md" [disabled]="i == ( fields.length - 1 )" (click)="moveDownField(i)">
                                <i class="fas fa-angle-down"></i>Mover para baixo
                              </button>
                              <button class="btn btn-block btn-md" (click)="excludeField(i)">
                                <i class="fas fa-trash-alt"></i>Excluir
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                </div>
              </div>
              <div id="navpills-2" class="tab-pane">
                <form #f="ngForm" id="headerFormRep_1" name="headerFormRep_1" class="form-horizontal managedform" >
                  <div class="form-body">
                    <div class="form-group">
                      <div class="row">

                        <div class="col-sm-12 fixed-header-table-custom overflow-auto" style="height: 400px;">
                          <table id="crud-table" name="crud-table" class='table table-striped table-bordered table-hover dataTable' role='grid' width='100%'>
                            <thead>
                              <tr>
                                <ng-container *ngFor="let fd of fields; let i = index"  >
                                  <th class="cursor-pointer th-index-{{i}}">
                                    {{fd.name}}
                                  </th>
                                </ng-container>
                                <th>Ações</th>
                              </tr>
                              <tr *ngIf="toServer.type == 'INLINE' ">
                                <th *ngFor="let fd of fields">
                                  <app-field-engine #wrapper
                                    [field]="convertField(fd)"
                                    [reportFilters]="reportFields"
                                    [hasLabel]="false" positionField=""  formType="CRUD" >
                                  </app-field-engine>
                                  <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                                </th>
                                <th>
                                  <button type="button" id="cancelFilters" name="cancelFilters" class="btn btn-secondary"  >
                                    <i class="fas fa-trash-alt"></i>
                                      Cancelar
                                  </button>
                                  <button type="button" id="applyFilters" name="applyFilters" class="btn btn-success"  >
                                      <i class="far fa-save"></i>
                                      Salvar
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let r of range;">
                                <ng-container *ngFor="let fd of fields; let i = index"  >
                                  <td class="cursor-pointer th-index-{{i}}">
                                    {{ field_types[fd.type] + ( fd.type == 'AUTOCOMPLETE' ? ' ' + autocomplete_dict[fd.autocomplete_type]  : '' ) + ' Linha #' + r }}
                                  </td>
                                </ng-container>
                                <td>
                                  <button class="btn btn-danger">Excluir</button>
                                  <button class="btn btn-info" >Alterar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
              </div>


              <div id="navpills-3" class="tab-pane" *ngIf="toServer.type != 'INLINE' ">
                <form #f="ngForm" id="headerFormRep_2" name="headerFormRep_2" class="form-horizontal managedform" >
                  <div class="form-body">
                    <div class="form-group">
                      <div class="row">
                        <ng-container *ngFor="let fd of fields">
                          <app-field-engine #wrapper
                            [field]="convertField(fd)"
                            [positionField]=" ( 'm-auto col-xs-12 col-sm-12 col-md-' + fd.size ) "
                            [reportFilters]="reportFields"
                            formType="CRUD" >
                          </app-field-engine>
                          <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate" ></ng-content>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="align-self-center mx-auto text-center" >
              <button id="conf_1" name="conf_1"  class="btn btn-info btn-circle btn-lg tour-form-new-field" data-toggle="modal" data-target="#right-bar-modal" (click)="showConfBar()">
                <i class="mdi mdi-plus"></i>
              </button>
              <br/>
              <span class="col-12" >Clique no botão para adicionar um novo campo</span>
            </div>
          </div>



        </div>
      </div>

      <div class="row">
        <div style="margin: 0 auto;">
          <button type="button" class="btn btn-secondary"
            data-dismiss="modal" (click)='cancelCrud()'>Cancelar</button>
          <button type="button" class="btn btn-primary"
            data-dismiss="modal" (click)='saveCrud()'>Salvar</button>
        </div>
      </div>

  </div>
</div>

<div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
  <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>


<div class="modal left" id="right-bar-modal" tabindex="" role="dialog" aria-labelledby="right-bar-modal-label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body tour-form-configuration">
        <input id="widget_id" name="widget_id" type="hidden" val="" />
        <div class="right-bar-header">Configurar o Campo</div>
        <div class="row">

          <div class="d-flex flex-column tour-form-configuration-name w-100">
            <small id="name col-11">
              <strong>Nome do campo*</strong>
            </small>

            <input type="text" class="form-control"
            [(ngModel)]="field.name"
            id="componentName"
            placeholder="Digite aqui" maxlength="50">

          </div>
        </div>

        <div class="row mt-4">

          <div class="d-flex flex-column w-100 tour-form-configuration-type">
            <small id="name col-11">
              <strong>Tipo de Campo*</strong>
            </small>

            <select name="select" class="form-control selectpicker tour-form-configuration-select" [(ngModel)]="field.type" (change)="changeType($event)">
              <option value="{{item.key}}" *ngFor="let item of field_types | keyvalue : returnZero">{{item.value}}</option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <small id="name col-11">
            <strong>Propriedades do Campo</strong>
          </small>
        </div>
        <div class="row custom-switch mt-4 mb-4 tour-form-configuration-switchers">
          <div class="custom-control col-6">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="field.obg" >
            <label class="custom-control-label" for="customSwitch1">Obrigatório</label>
          </div>

          <div class="custom-control col-6">
            <input type="checkbox" class="custom-control-input" id="customSwitch2" [(ngModel)]="field.unique" >
            <label class="custom-control-label" for="customSwitch2">Único</label>
          </div>
        </div>


        <div class="row">
          <div class="d-flex flex-column w-100 tour-form-configuration-desc">
            <small id="name col-11">
              <strong>Descrição do campo</strong>
            </small>

            <input type="text" class="form-control"
            #componentDescription
            id="componentDescription"
            [(ngModel)]="field.description"
            placeholder="Digite aqui" maxlength="50">

          </div>
        </div>

        <div id="field-details" name="field-details" class="row mt-4 mb-4" [ngSwitch]="true">
          <ng-container *ngSwitchCase="field.items != null">
            <small class="col-12"><strong>Opções</strong></small>

            <div class=" col-12">
              <div class="alert alert-secondary alert-dismissible fade show" role="alert" *ngFor="let it of field.items">

                <strong>{{it.text}}</strong>
                <button type="button" class="close" aria-label="Close" (click)="removeItem(it.id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>

            <input type="text" class="form-control mt-2" *ngIf="field.itemName != null"
              (focusout)="addItem($event)"
              id="itemName"
              placeholder="Nome da opção" maxlength="50" >

            <div class="input-group-prepend col-12" *ngIf="field.items.length > 0" >
              <span for="selectDefaultOption" class="input-group-text">
                Padrão
              </span>
              <select id="selectDefaultOption" name="selectDefaultOption" class="form-control" (change)="changeDefault($event)">
                <option value="{{it.id}}" *ngFor="let it of field.items; let i= index" [selected]= "( field.default_value == null && i == 0 ) || field.default_value.id == it.id" >{{it.text}}</option>
              </select>
            </div>

            <button type="button" style="background-color:transparent !important;" class="btn btn-primary waves-effect" (click)="showItemDiv()">
              <a class="card_link">Adicionar Opção</a>
            </button>
          </ng-container>

          <ng-container *ngSwitchCase=" field.type === 'AUTOCOMPLETE' ">
            <div class="row">

              <div class="input-group-prepend col-12">
                <span for="selectAutocomplete" class="input-group-text">
                  Tipo
                </span>
                <select id="selectAutocomplete" name="selectAutocomplete" class="form-control" [(ngModel)]="field.autocomplete_type">
                  <option value="{{ft.id}}" *ngFor="let ft of autocompletes">{{ft.label}}</option>
                </select>
              </div>

              <div class="input-group-prepend col-12">
                <span for="limit" class="input-group-text">
                  Limite
                </span>
                <select id="limit" name="limit" class="form-control limit" [closeOnSelect]="true" [(ngModel)]="field.limit">
                    <option value="ilimitado">Ilimitado</option>
                    <option *ngFor="let r of range" value="{{r}}">{{r}}</option>
                </select>
              </div>

            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="d-flex flex-column w-100 tour-form-configuration-size">
            <small id="name col-11">
              <strong>Tamanho do campo</strong>
            </small>

            <div class="form-group">
              <input type="range" class="form-control" min="1" max="12" step="1" [(ngModel)]="field.size" style="padding: 0px !important">
              <p>Tablets / Notebooks / TV's: <output id="value">{{  (100*field.size/12).toFixed(2)  }}%</output></p>
              <p>Celulares: <output id="value_cel">{{  (100).toFixed(2)  }}%</output></p>
            </div>

          </div>
        </div>

        <div class="row">
          <div style="margin: 0 auto;">
            <button type="button" id="right-bar-modal-close" class="btn btn-secondary waves-effect tour-form-configuration-close" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary waves-effect tour-form-configuration-save" (click)='saveField()'>Adicionar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

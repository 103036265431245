import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsletterLinkService {
  mailUrl = environment.mailUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getNewsletterLinkerConf(): Observable<any> {
    const body = { };
    return this.http.post(this.mailUrl + 'conf_newsletter_linker', body,
      {headers: this.httpHeaders});
  }

  getNewsletterLinkerSave(content, codi_cnl): Observable<any> {
    const body = {content: content, codi_cnl: codi_cnl };
    return this.http.post(this.mailUrl + 'save_newsletter_linker', body,
      {headers: this.httpHeaders});
  }

  getNewsletterLinkerSearch(code): Observable<any> {
    const body = {id: code };
    return this.http.post(this.mailUrl + 'search_newsletter_linker', body,
      {headers: this.httpHeaders});
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

/**
 * Componente para listagem de IOT.
 */
@Component({
  selector: 'app-equipment-read',
  templateUrl: './equipment-read.component.html',
  styleUrls: ['./equipment-read.component.css']
})
export class EquipmentReadComponent implements OnInit {


  /** Lista de equipamentos. */
  equipments = [];
  equipmentsFiltered = [];

  /** Link para a próxima página. */
  next: string;

  /** Link para a página anterior. */
  previous: string;

  /** Parâmetros HTTP para a solicitação. */
  params = new HttpParams();

  /** Referência para o modal de exclusão. */
  deleteModalRef: BsModalRef;

  /** Referência para o template do modal de exclusão. */
  @ViewChild('deleteModal') deleteModal;

  /** Item selecionado para exclusão. */
  selectedItem;
  
  
  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
    { label: 'Local de Medição', field: 'group', type: 'text', value: null },
    { label: 'Ligado', field: 'is_on', type: 'boolean', value: null },
    { label: 'Real', field: 'is_real', type: 'boolean', value: null },
    { label: 'Concessionária de Energia', field: 'is_dealership', type: 'boolean', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;
  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e recupera informações necessárias.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_equipment') == false) {
      this.router.navigate(['/403']);
    }

    this.readAll(this.authService.endpoint_main + 'plant/equipment/list');
  }
  
  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = !this.isOptionsOpened;
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.equipmentsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.equipmentsFiltered = this.equipments;
      return;
    }
    
    this.equipmentsFiltered = this.equipmentsFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onFilterBoolean(value: boolean) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0]
    filter.value = value;

    this.equipmentsFiltered = this.equipments.filter((el) => el[filter.field] == value);
  }


  onClearFilters() {
    this.equipmentsFiltered = this.equipments;
  }

  /**
  * Lê todos os IOT a partir da URL fornecida.
  *
  * @param url URL para obter a lista de IOT.
  */
  readAll(url: string): void {
    // Verifica se existe token e uma pessoa autenticada.
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      // Obtém o ID da empresa associada ao usuário autenticado.
      let company = this.authService.getPerson();

      // Chama o serviço para obter a lista de equipamentos IoT.
      this.plantService.getEquipmentAllList(url, company).pipe(take(1)).subscribe(
        data => {
          // Atualiza a lista de equipamentos com os dados obtidos.
          this.equipments = data.results;
          this.equipmentsFiltered = data.results;
          

          // Atualiza os URLs para navegação paginada.
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Exibe um alerta de perigo em caso de erro na obtenção da lista de IoTs.
          this.modalAlertService.showAlertDanger('Não foi possível listar IoTs');
        }
      );
    } else {
      // Exibe um alerta de perigo e realiza o logout se não houver ID de aplicativo ou pessoa autenticada.
      this.modalAlertService.showAlertDanger('Erro ao recuperar IoTs');
      this.authService.doLogout();
    }
  }

  /**
   * Carrega os próximos itens paginados usando a URL na propriedade next.
   */
  fetchNext(): void {
    this.readAll(this.next);
  }

  /**
   * Carrega os itens paginados anteriores usando a URL na propriedade previous.
   */
  fetchPrevious(): void {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização do equipamento com o ID fornecido.
   *
   * @param id ID do equipamento a ser atualizado.
   */
  readId = (id): void => {
    this.router.navigate(['/plant/equipment/update', id]);
  }

  /**
   * Navega para a página de criação de IOT.
   */
  create = (): void => {
    this.router.navigate(['/plant/equipment/create']);
  }

  /**
 * Manipula a exclusão de um item.
 *
 * @param item Item a ser excluído.
 */
  onDelete = (item): void => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('delete_equipment') == false) {
      this.router.navigate(['/403']);
    }

    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este IoT?'
    );

    // Aguarda a resposta do modal de confirmação.
    result$.asObservable()
      .pipe(
        take(1),
        // Se a resposta for verdadeira, chama o serviço para excluir o equipamento.
        switchMap(result => result ? this.plantService.deleteEquipment(item.plant_equipment_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o item da lista após a exclusão bem-sucedida.
          let index;
          this.equipments.forEach((e, i) => {
            if (e.plant_equipment_id === item.plant_equipment_id) {
              index = i;
            }
          });
          this.equipments.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Exibe um alerta de erro em caso de falha na exclusão.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  };

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Server-Context': this.authService.server_context })
  }

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  validateEmail(email, token): Observable<any> {
    const body = { "email": email, "token": token };
    return this.http.post(this.authService.endpoint + '/validate/email', body, this.httpOptions);
  }

  resetPassword(email, token, password): Observable<any> {
    const body = { "email": email, "token": token, "password": password };
    return this.http.patch(this.authService.endpoint + '/reset/password', body, this.httpOptions);
  }

  changePassword(email): Observable<any> {
    const body = { "email": email};
    return this.http.post(this.authService.endpoint + '/users/change-password', body, this.httpOptions);
  }

}

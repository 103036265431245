import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { AuthService } from './../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  repoServiceUrl = environment.repoUrl;
  formServiceUrl = environment.formUrl;
  userAuthUrl = environment.authApiUrl;
  botServiceUrl = environment.botUrl;
  mailServiceUrl = environment.mailUrl;
  mainServiceUrl = environment.mainApiUrl;
  simulateServiceUrl = environment.simulationUrl;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  })

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getStatusMail() {
    return this.http.get(this.mailServiceUrl + 'mail/status',
      {headers: this.httpHeaders});
  }
  getStatusRepo() {
    return this.http.get(this.repoServiceUrl + 'repo/status',
      {headers: this.httpHeaders});
  }
  getStatusForm() {
    return this.http.get(this.formServiceUrl + 'form/status',
      {headers: this.httpHeaders});
  }
  getStatusMain() {
    var mainUrl = this.mainServiceUrl.endsWith('/') ? this.mainServiceUrl : this.mainServiceUrl + '/';
    return this.http.get(mainUrl + 'company/main/status',
      {headers: this.httpHeaders});
  }
  getStatusUserAuth() {
    return this.http.get(this.userAuthUrl + 'user/status',
      {headers: this.httpHeaders});
  }
  getStatusSimulate() {
    return this.http.get(this.simulateServiceUrl + 'simulation/status',
      {headers: this.httpHeaders});
  }
  getStatusBot() {
    return this.http.get(this.botServiceUrl + 'bot/status',
      {headers: this.httpHeaders});
  }

}



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fade } from 'src/app/core/animations/fade.animation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-organization-level-card',
  templateUrl: './organization-level-card.component.html',
  styleUrls: ['./organization-level-card.component.css'],
  animations: [fade],
})
export class OrganizationLevelCardComponent implements OnInit {
  @Output() removeSingle: EventEmitter<any> = new EventEmitter();
  @Output() removeAll: EventEmitter<any> = new EventEmitter();

  @Input() data: { company: string; plants: { id: number; report_db: string; person: any }[] };

  @Input() isCollapsed: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  onHandleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  onRemoveItem(index: number) {
    this.removeSingle.emit({ company: this.data.company, plantIndex: index });
  }

  onRemoveAll() {
    Swal.fire({
      icon: 'question',
      title: `Você deseja remover todas plantas do ${this.data.company.toUpperCase()}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim, remover',
    }).then((result) => {
      if(result.isConfirmed) {
        this.removeAll.emit(this.data);
      }
    });
  }
}

<ul class="nav nav-pills m-t-30 m-b-30">
  <li class="nav-item" id="{{ item.label }}{{ i }}" (click)="setTabIndex(i)" *ngFor="let item of items; index as i">
    <a href="#navpills-{{ i }}" class="nav-link" [class.active]="selectedIndex == i" data-toggle="tab" aria-expanded="false" *ngIf="item.available">{{ item.alias }}</a>
  </li>
</ul>


<div class="tab-content br-n pn">
  <div id="navpills-0" *ngIf="selectedIndex == 0">
    <app-type-read>
    </app-type-read>
  </div>
  <div id="navpills-1" *ngIf="selectedIndex == 1">
    <app-nature-read>
    </app-nature-read>
  </div>
  <div id="navpills-2"  *ngIf="selectedIndex == 2">
    <app-equipment-read>
    </app-equipment-read>
  </div>
  <div id="navpills-3"  *ngIf="selectedIndex == 3">
    <app-equipment-image-read>
    </app-equipment-image-read>
  </div>
  <div id="navpills-4"  *ngIf="selectedIndex == 4">
    <app-bundler-read>
    </app-bundler-read>
  </div>
</div>

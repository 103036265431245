import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import * as Sentry from '@sentry/angular';
//import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Tratamento global de erros do JavaScript
window.onerror = function (message, source, lineno, colno, error) {
  // console.log('Ocorreu um erro global no javascript/angular');
  // console.error('Erro global:', source, error.message);
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.production) {
  enableProdMode();
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log(`Bootstrap success`))
    .catch((err) => console.error(err));
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CompanyDivision } from '../../../shared/model/company-division.model';
import { CompanyModule } from '../../../company.module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para exibir e gerenciar a leitura da unidade empresarial.
 */
@Component({
  selector: 'app-company-division-read',
  templateUrl: './company-division-read.component.html',
  styleUrls: ['./company-division-read.component.css']
})
export class CompanyDivisionReadComponent implements OnInit {

  /** Lista de divisões de empresa. */
  divisions: CompanyDivision[];
  divisionsFiltered: CompanyDivision[] = [];

  /** URL da próxima página de divisões. */
  next: string;

  /** URL da página anterior de divisões. */
  previous: string;

  /** Parâmetros HTTP. */
  params = new HttpParams();

  /** Referência modal para exclusão. */
  deleteModalRef: BsModalRef;

  /** Referência ao modal de exclusão. */
  @ViewChild('deleteModal') deleteModal;

  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
    { label: 'Ativo', field: 'active', type: 'boolean', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }
  
  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private companyService: CompanyService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e obtém a lista de divisões de empresas.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_companydivision') == false) {
      this.router.navigate(['/403']);
    };

    // Obtém a lista de divisões de empresa.
    this.readAll(this.authService.endpoint_main + 'companydivision/');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.divisionsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.divisionsFiltered = this.divisions;
      return;
    }

    this.divisionsFiltered = this.divisionsFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onFilterBoolean(value: boolean) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0]
    filter.value = value;

    this.divisionsFiltered = this.divisions.filter((el) => el[filter.field] == value);
  }

  onClearFilters() {
    this.divisionsFiltered = this.divisions;
  }

  /**
 * Obtém todas as divisões de empresa com base na URL fornecida.
 *
 * @param url URL para recuperar divisões de empresa.
 */
  readAll(url: string) {
    // Verifica se existe token.
    if (this.authService.hasToken()) {

      // Chama o serviço para recuperar todas as divisões de empresas.
      this.companyService.getCompanyDivisionAll(url).pipe(take(1)).subscribe(
        data => {
          // Atribui os resultados ao array 'divisions'.
          this.divisions = data.results;
          this.divisionsFiltered = data.results;

          // Se houver uma próxima página, atribui à propriedade 'next'.
          if (data.next) {
            this.next = data.next;
          }

          // Se houver uma página anterior, atribui à propriedade 'previous'.
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Não foi possível listar divisões de empresas');
        }
      );
    } else {
      // Se não houver ID, exibe um alerta de perigo e faz logout.
      this.modalAlertService.showAlertDanger('Não foi possível listar divisões de empresas');
      this.authService.doLogout();
    }
  }

  /**
   * Obtém a próxima página de divisões.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Obtém a página anterior de divisões.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização da divisão de empresa com o ID fornecido.
   *
   * @param id ID da divisão de empresa.
   */
  readId = (id) => {
    this.router.navigate(['/companydivision/update', id]);
  }

  /**
   * Navega para a página de criação de uma nova divisão de empresa.
   */
  create = () => {
    this.router.navigate(['/companydivision/create']);
  }

  /**
  * Executado ao confirmar a exclusão de uma divisão de empresa.
  *
  * @param item Item a ser excluído.
  */
  onDelete = (item) => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('delete_companydivision') == false) {
      this.router.navigate(['/403']);
    };

    // Atribui o item selecionado à propriedade 'selectedItem'.
    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir esta unidade?'
    );

    // Observa o resultado do modal de confirmação.
    result$.asObservable()
      .pipe(
        // Leva apenas uma emissão do resultado.
        take(1),
        // Se o resultado for verdadeiro, chama o serviço para excluir a divisão de empresa.
        switchMap(result => result ? this.companyService.deleteCompanyDivision(item.company_division_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Encontra o índice do item a ser excluído no array 'divisions'.
          let index;
          this.divisions.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });

          // Remove o item do array 'divisions'.
          this.divisions.splice(index, 1);

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }
}

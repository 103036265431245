<section class="section ">
    <div class="row">
        <div class="col-12 ">
            <div class="card">
                
                <div class="card-body">
                    <div *ngFor="let msg of backend_validation_message" class="alert alert-danger alert-dismissible fade" [ngClass]="{'show':has_error}" >
                        <div class="alert-body">
                          <button class="close" data-dismiss="alert">
                            <span>×</span>
                          </button>
                          {{msg}}
                        </div>
                    </div>
                    <form  method="POST" [formGroup]="form" (ngSubmit)="onSubmit(form)">
                        <div class="card-body">
                            <div class="section-title mt-0 tittle-section-person_create">Informações gerais</div>
                            <div class="form-row formated-form">
                                <div class="form-group col-md-4" formGroupName="person_address">
                                  <label for="address_email">Email</label>

                                  <input required type="email"
                                    [ngClass]="displayFieldCss('person_address.email')"
                                    class="form-control mb-2 mr-sm-2 formated-control" id="address_email" formControlName="email">

                                  <div class="alert alert-danger invalid-feedback"
                                    *ngIf="( form.get('person_address').get('email').invalid || form.get('person_address').get('email').hasError('required') )  && ( form.get('person_address').get('phone').touched || form.get('person_address').get('phone').dirty)">
                                      Por favor informe um email válido
                                  </div>
                                </div>
                                <div class="form-group col-md-4" formGroupName="person_address">
                                  <label for="address_phone">Telefone</label>

                                  <input required  mask="(00) 0 0000-0000 || (00) 0000-0000" type="text"
                                    [ngClass]="displayFieldCss('person_address.phone')"
                                    class="form-control mb-2 mr-sm-2 formated-control" id="address_phone" formControlName="phone">

                                    <div *ngIf="( form.get('person_address').get('phone').invalid || form.get('person_address').get('phone').hasError('required') )  && ( form.get('person_address').get('phone').touched || form.get('person_address').get('phone').dirty)"
                                    class="alert alert-danger invalid-feedback">
                                    Por favor informe telefone válido
                                  </div>
                                </div>
                                
                            </div>
                            <div class="section-title mt-0 tittle-section-person_create mt-4">Seleção de cadastro</div>
                            <div class="form-row">
                                <div class="form-group col-md-6" [formGroup]="form" >
                                    <label for="cpf">CPF</label>
                                    <input mask="000.000.000-00" type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                        (focus)="focusPerson('cpf')" formControlName="cpf" mask="000.000.000-00" [dropSpecialCharacters]="true">
                                    <div *ngIf="form.get('cpf').touched && form.get('cpf').hasError('maxlength')"
                                      class="alert alert-danger invalid-feedback">
                                      Por favor informe um CPF válido no formato 00000000000
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="cnpj">CNPJ</label>
                                    <input mask="00.000.000/0000-00" type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                        (focus)="focusPerson('cnpj')" formControlName="cnpj" mask="00.000.000/0000-00" [dropSpecialCharacters]="true">
                                    <div *ngIf="form.get('cpf').touched && form.get('cpf').hasError('maxlength')"
                                      class="alert alert-danger invalid-feedback">
                                      Por favor informe um CNPJ válido no formato 00000000000000
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade {{select_form_cpf}}" id="physical" role="tabpanel"
                                    aria-labelledby="-tab3">
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="first_name">Nome</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="first_name">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="last_name">Sobrenome</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="last_name">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="custom-control custom-checkbox mt-5 ml-5">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        formControlName="icms_free">
                                                    <label class="form-check-label" for="person_icms_free">Isento de
                                                        ICMS</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade {{select_form_cnpj}}" id="legal" role="tabpanel"
                                    aria-labelledby="profile-tab3">
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="trade_name">Razão social</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="trade_name">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="company_name">Nome comercial</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="company_name">
                                        </div>
                                        <div class="form-group col-md-4" formGroupName="person_address">
                                            <label for="site">Site</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control" formControlName="site">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="section-title mt-0 tittle-section-person_create mt-4">Cadastros adicionais</div>
                            <br>
                            <ul class="nav nav-tabs tablist-person_create" id="myTab3" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab3" data-toggle="tab" href="#home3" role="tab"
                                        aria-controls="home" aria-selected="true">Endereço</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " id="profile-tab3" data-toggle="tab" href="#profile3" role="tab"
                                        aria-controls="profile" aria-selected="false">Contatos</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab"
                                        aria-controls="contact" aria-selected="false">Documentos</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent2" formGroupName="person_address">
                                <div class="tab-pane fade show active" id="home3" role="tabpanel"
                                    aria-labelledby="home-tab3">
                                    <br>
                                    <div class="form-row">
                                        <div class="form-group col-md-3">
                                            <label for="street">Rua</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="street"  [ngClass]="displayFieldCss('person_address.street')">
                                            <div class="invalid-feedback">
                                                Por favor informe a rua
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="number">Número</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="number">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="sector">Bairro</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="sector">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="complement">Complemento</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                                formControlName="complement">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="zipcode">CEP</label>
                                            <input mask="00000-000" type="text" class="form-control mb-2 mr-sm-2 formated-control"
                                            [ngClass]="displayFieldCss('person_address.zipcode')"
                                                formControlName="zipcode">
                                                <div class="invalid-feedback">
                                                    Por favor informe um cep válido
                                                </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="uf">UF</label>
                                            <select class="form-control formated-control"
                                            (change)="onUfSelected()" formControlName="uf" [ngClass]="displayFieldCss('person_address.uf')">
                                                <option *ngFor="let uf of ufs" value="{{uf.id}}">
                                                    {{uf.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Por favor informe um UF
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="city">Cidade</label>
                                            <select class="form-control formated-control" formControlName="city" [ngClass]="displayFieldCss('person_address.city')">
                                                <option *ngFor="let citie of cities" value="{{citie.id}}">
                                                    {{citie.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Por favor informe uma cidade
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="profile3" role="tabpanel"
                                    aria-labelledby="profile-tab3">
                                    <br>
                                    <button class="btn btn-primary" (click)="addContactToHtml()">Adicionar
                                        contato</button>
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-md">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Nome</th>
                                                    <th>E-mail</th>
                                                    <th>Telefone</th>
                                                    <th>Função</th>
                                                </tr>
                                            </thead>
                                            <tbody id="contact-tbody" #tbodyContact>

                                            </tbody>
                                        </table>
                                    </div>
                                    <button class="btn btn-danger" (click)="deleteContactTr()">Remover
                                        selecionados</button>
                                </div>
                                <div class="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab3">
                                    <br>
                                    <button class="btn btn-primary" (click)="addFileToHtml()">Adicionar
                                        documento</button>
                                    <div class=" table-responsive">
                                        <table class="table table-bordered table-md">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Nome</th>
                                                    <th>Documentos</th>
                                                </tr>
                                            </thead>
                                            <tbody id="file-tbody" #tbodyFile>

                                            </tbody>
                                        </table>
                                    </div>
                                    <button class="btn btn-danger" (click)="deleteFileTr()">Remover
                                        selecionados</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-right px-5 cm">
                    <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/company'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

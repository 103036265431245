import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BootstrapModalAlertService } from 'src/app/components/shared/bootstrap-modal-alert/bootstrap-modal-alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalAlertService: BootstrapModalAlertService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.hasToken()) {
      return true;
    }

    this.modalAlertService.showAlertDanger('Sessão expirada, por favor realize um novo login');
    this.router.navigate(['/signin']);
    return false;
  }
}


<div class="row" *ngIf="config">
  <ng-container *ngIf="compact; then true_block; else false_block">
  </ng-container>
  <ng-template #true_block>
    <div  class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-2">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <i class="fa fa-bar-chart"></i>
            {{plain_text}}
          </h3>
          <div class="box-tools pull-right">
            <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
            </button>
          </div>
          <!-- /.box-tools -->
        </div>
        <!-- /.box-header -->
        <div class="box-body compact_cards">
          <ul >
            <li *ngFor="let f of filters"><a href="{{f[1]}}" onclick="localStorage.setItem('onloadfilteraction', '{{f[0]}}')">{{f[0]}}</a></li>
          </ul>
        </div>
        <!-- /.box-body -->
      </div>
    </div>
  </ng-template>
  <ng-template #false_block>
    <ng-container *ngIf="filters.length > 0; then has_filters; else no_filters">
    </ng-container>

    <ng-template #has_filters>
      <div *ngFor="let f of filters" title="{{ f[0] }}" class="card_filter col-xs-6 col-sm-3 col-lg-3 col-xl-2 mt-3">
        <div class="card card-hover card-filter-wall card-wrap">
          <div class="card-header">
            <button type="button" class="close_card deletefilter  close" data-toggle="modal" data-target="#deleteModal" (click)='delete_filter_modal($event, formcode, f[0])' >
              <span aria-hidden="true">×</span>
            </button>
          </div>


            <div class="card-body ">
              <h5 class="waves-effect card-title getfilter " (click)="get_filter_click($event, formcode, f[0])"  >
                {{f[0]}}
              </h5>
            </div>
        </div>

      </div>
      <div id="deleteModal"class="modal fade" tabindex="-1" role="dialog">
        <input id="curr_delete_formcode" name="curr_delete_formcode" type="hidden" />
        <input id="curr_delete_filtername" name="curr_delete_filtername" type="hidden" />
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title">Apagar Visualização</h4>
            </div>
            <div class="modal-body">
              <p>Tem certeza que deseja excluir essa visualização? Essa ação não poderá ser desfeita.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete_filter_click($event)">Apagar</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </ng-template>
    <ng-template #no_filters>
      <h1>{{ "Nenhuma Visualização Salva" }}</h1>
    </ng-template>
  </ng-template>
</div>

<div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
  <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div id="customNewsletterPartialContent" class="tab-content container-fluid  br-n pn" style="min-height: 500px">
  <div id="customNewsletters_div" class="needs-validation section">
    <div id="customNewsletters_box_esquerda section-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form id="AddCustomNewsletterform" name="AddCustomNewsletterform" action="" method="post">

                <div class="row">
                  <div class="col-12 align-self-center">
                    <h1 id="page-title">{{title}}</h1>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 input-group-prepend">
                    <label for="selectTO" class="input-group-text">Para</label>
                    <select class="form-control simpleSelect" id="selectTO" name="selectTO" [(ngModel)]="selected">
                      <option *ngFor="let e of emails" value="{{e}}">{{e}}</option>
                    </select>
                    <button type="button" class="btn btn-primary"
                      (click)="sendSample($event)">Enviar</button>
                  </div>
                </div>

                <div class="mt-5" >

                  <table class="table table-striped table-bordered table-hover dataTable">
                    <thead>
                      <tr>
                        <th>Ação</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of items" >
                        <td>{{ item.action }}</td>
                        <td><pre>{{ item.response }}</pre></td>
                      </tr>

                      <tr *ngIf="items.length ==0 " >
                        <td colspan="2">Nenhuma ação registrada</td>
                      </tr>

                    </tbody>
                  </table>


                </div>


                <div class="row">
                  <div style="margin: 0 auto;">
                    <button type="button" class="btn btn-secondary"
                      data-dismiss="modal" (click)='cancel()'>Voltar</button>

                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

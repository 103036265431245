import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomCrudsService {
  formUrl = environment.formUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

    getCustomCrudsList(): Observable<any> {
      var httpHeadersNoCache = new HttpHeaders({
        'Content-Type': 'application/json',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      });
      return this.http.get(this.formUrl + 'customcrud',
        {headers: httpHeadersNoCache});
    }

    getCustomCrudsConf(id): Observable<any> {
      return this.http.get(this.formUrl + 'get_crud/' + id,
        {headers: this.httpHeaders});
    }

    //(id, pkey, content): Observable<any>
    getCustomCrudsSave(body): Observable<any> {
      //const body = {id: id, pkey: pkey, content: content};

      return this.http.post(this.formUrl + 'save_entity', body,
        {headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data',
          'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
          'Server-Context': this.authService.server_context,
          'Authorization': `JWT ${this.authService.getToken()}`
        })});
    }

    getCustomCrudsSearch(code, pkey?): Observable<any> {
      const body = {id: code, pkey: pkey};
      return this.http.post(this.formUrl + 'search_entity', body,
        {headers: this.httpHeaders});
    }

    getCustomCrudsDelete(source, id): Observable<any> {
      const body = {id: source, pkey: id};
      return this.http.post(this.formUrl + 'delete_entity', body,
        {headers: this.httpHeaders});
    }

}

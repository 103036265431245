import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IcSelectService {
  repoUrl = environment.repoUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}` 
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getItemComponent(key: string): Observable<any> {
    var params = new HttpParams();
    params = params.set('key', key);
    var options = { params: params, headers: this.httpHeaders };

    return this.http.get(this.repoUrl + 'ac_component', options);
  }

  getItemList(key: string, term : string, map: any): Observable<any> {
    //autocomplete?key=SET&q[term]=to&depends[EQP]=1<spt_s>100
    //console.log('valor do term ' + term)
    var params = new HttpParams();
    params = params.set('key', key);
    if(term && term != '')
      params = params.set('q[term]', term);
    if(map && Object.keys(map).length !== 0)
      Object.keys(map).forEach(function(e){ params = params.set('depends[' + e + ']', map[e]); });
    var options = { params: params, headers: this.httpHeaders };
    
    return this.http.get(this.repoUrl + 'autocomplete', options);
  }
}

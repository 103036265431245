<label>{{title}}</label>
<hr>
<div class="form-row">
    <div class="form-group" class="col-sm-12 col-md-3">
        <label>Itens disponíveis</label>
        <select [(ngModel)]="selectedItems" name="allitems" id="allitems" class="wrapper-component form-control"
            style="height: 200px;" multiple>
            <option *ngFor="let item of allItems" value={{item.id}}>{{item.name}}</option>
        </select>
    </div>
    <div class="form-group" class="col-sm-12 col-md-2">
        <div class="controls-wrapper" style="text-align: center; padding-top: 40px;">
            <button class="btn btn-icon btn-secondary mt-5 d-block ml-5" (click)="addItems()">
                <i class="fa fa-chevron-right"></i>
            </button>
            <button class="btn btn-icon btn-secondary d-block ml-5" (click)="removeItems()">
                <i class="fa fa-chevron-left"></i>
            </button>
        </div>
    </div>
    <div class="form-group" class="col-sm-12 col-md-3">
        <label for="{{fieldName}}">Itens escolhidos</label>
        <select [(ngModel)]="selectedItemsForSave" name="{{fieldName}}" id="{{fieldName}}" class="form-control"
            style="height: 200px;" multiple>
            <option *ngFor="let item of initialItems" value="{{item.id}}">{{item.name}}</option>
        </select>
    </div>
</div>


<div class="d-flex flex-column">

  <div class="d-flex flex-column mb-3 mt-3 mx-3" *ngIf="gridView">
    <div class="d-flex justify-content-between align-items-center">
      <h5>{{ context.maps.title }}</h5>
      <i class="fa-solid fa-xmark pointer mx-3" (click)="onRemove()"></i>
    </div>
    <a class="card_link"  (click)="onFillForm()">Configurar Visualização</a>
    <a href="" id="open-sidemenu" data-toggle="modal" data-target="#right-bar-modal" class="d-none"></a>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center grid-stack-item-content card-body ui-draggable-handle" id="canvas-container"  style="background-color: black">
    <div class="d-flex justify-content-center align-items-center imgs-wraper" *ngIf="levels.length > 0">
      <img class="mx-2" [src]="onSanitizeImg(level.content)" alt="" *ngFor="let level of levels; index as i" (click)="onChangeView(i)">
    </div>
    <h5 class="text-white mt-2" *ngIf="gridView">{{ data?.title }}</h5>
  
    <div #rCanvas id="renderCanvas" class="mx-2 my-1" style="z-index: 1"></div>
  
    <div class="d-flex align-items-center mb-2">
      <i class="fa-solid fa-circle-chevron-up direction-btn" (click)="onMoveCamera('up')"></i>
      <i class="fa-solid fa-circle-chevron-down direction-btn mx-2" (click)="onMoveCamera('down')"></i>
      <i class="fa-solid fa-circle-chevron-left direction-btn" (click)="onMoveCamera('left')"></i>
      <i class="fa-solid fa-circle-chevron-right direction-btn mx-2" (click)="onMoveCamera('right')"></i>
      <i class="fa-solid fa-circle-plus direction-btn mr-2" (click)="onZoon('out')"></i>
      <i class="fa-solid fa-circle-minus direction-btn" (click)="onZoon('in')"></i>
    </div>
  </div>
</div>



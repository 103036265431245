import { PlantRoutingModule } from './components/plant/plant-routing.module';


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PersonsPerfilCreateComponent } from './components/persons-perfil/persons-perfil-create/persons-perfil-create.component';
import { PersonsPerfilReadComponent } from './components/persons-perfil/persons-perfil-read/persons-perfil-read.component';
import { PersonsPerfilUpdateComponent } from './components/persons-perfil/persons-perfil-update/persons-perfil-update.component';
import { PersonsPerfilComponent } from './components/persons-perfil/persons-perfil.component';

import { PersonReadComponent } from './components/person/person-read/person-read.component';
import { PersonCreateComponent } from './components/person/person-create/person-create.component';
import { PersonUpdateComponent } from './components/person/person-update/person-update.component';

import { CompanyComponent } from './components/company/components/company/company.component';
import { CompanyRoutingModule } from './components/company/company-routing.module';

import { CompanyGroupReadComponent } from './components/company/components/company-group/company-group-read/company-group-read.component';
import { CompanyGroupUpdateComponent } from './components/company/components/company-group/company-group-update/company-group-update.component';
import { CompanyGroupComponent } from './components/company/components/company-group/company-group.component';
import { CompanyGroupCreateComponent } from './components/company/components/company-group/company-group-create/company-group-create.component';

import { CompanyDivisionReadComponent } from './components/company/components/company-division/company-division-read/company-division-read.component';
import { CompanyDivisionCreateComponent } from './components/company/components/company-division/company-division-create/company-division-create.component';
import { CompanyDivisionUpdateComponent } from './components/company/components/company-division/company-division-update/company-division-update.component';

import { CustomReportsComponent } from './components/reports/custom-reports/custom-reports.component';

import { SigninComponent } from './auth/signin/signin.component';
import { MainlayoutComponent } from './views/mainlayout/mainlayout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

import { UsersUpdateComponent } from './components/users/users-update/users-update.component';
import { UsersCreateComponent } from './components/users/users-create/users-create.component';
import { UsersReadComponent } from './components/users/users-read/users-read.component';

import { UsersGroupsUpdateComponent } from './components/users-groups/users-groups-update/users-groups-update.component';
import { UsersGroupsCreateComponent } from './components/users-groups/users-groups-create/users-groups-create.component';
import { UsersGroupsReadComponent } from './components/users-groups/users-groups-read/users-groups-read.component';

import { UsersProfilesUpdateComponent } from './components/users-profiles/users-profiles-update/users-profiles-update.component';
import { UsersProfilesCreateComponent } from './components/users-profiles/users-profiles-create/users-profiles-create.component';
import { UsersProfilesReadComponent } from './components/users-profiles/users-profiles-read/users-profiles-read.component';

import { CubeComponent } from './components/reports/cube/cube.component';
import { CustomCrudsComponent } from './components/cruds/custom-cruds/custom-cruds.component';
import { HelpIndexComponent } from './components/shared/help-index/help-index.component';

import { PubsubComponent } from './components/pubsub/pubsub.component';
import { StatusComponent } from './components/status/status.component';

/*Menu de Cadastros*/
import { PersonContainerComponent } from './components/customize-container/person-container/person-container.component';
import { CompanyContainerComponent } from './components/customize-container/company-container/company-container.component';
import { UserContainerComponent } from './components/customize-container/user-container/user-container.component';
import { PlantContainerComponent } from './components/customize-container/plant-container/plant-container.component';
import { DealershipContainerComponent } from './components/customize-container/dealership-container/dealership-container.component';
import { EquipmentContainerComponent } from './components/customize-container/equipment-container/equipment-container.component';


import { CustomizeContainerComponent } from './components/customize-container/customize-container.component';
import { ReportContainerComponent } from './components/reports/report-container/report-container.component';
import { ReportBuilderComponent } from './components/reports/report-builder/report-builder.component';
import { CrudBuilderComponent } from './components/cruds/crud-builder/crud-builder.component';

import { PanelListComponent } from './components/shared/panel-list/panel-list.component';

import { UploadPanelComponent } from './components/upload-panel/upload-panel.component';


import { DealershipReadComponent } from './components/dealership/dealership-read/dealership-read.component';
import { DealershipCreateComponent } from './components/dealership/dealership-create/dealership-create.component';
import { DealershipUpdateComponent } from './components/dealership/dealership-update/dealership-update.component';

import { SceneryBuilderComponent } from './components/reports/scenery-builder/scenery-builder.component';
import { SceneryComparisonComponent } from './components/reports/scenery-comparison/scenery-comparison.component';
import { NewsletterLinkComponent } from './components/newsletter/newsletter-link/newsletter-link.component';
import { NewsletterSampleComponent } from './components/newsletter/newsletter-sample/newsletter-sample.component';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';


const routes: Routes = [
  {path: '',component: SigninComponent,
    data: {imageBg: '../assets/img/bg.jpg'}
  },
  {
    path: 'dashboard',
    component: MainlayoutComponent,
    children: [
      { path: '', component: DashboardComponent }
    ]
  },

  //PubSub
  { path: 'pubsub', component:PubsubComponent, 
     canActivate: [AuthenticatedGuard],
  },

  //Status
  {
    path: 'status', component:StatusComponent,
    canActivate: [AuthenticatedGuard],
  },


  // Users
  {path: 'users',component: MainlayoutComponent,
    children: [{ path: '', component: UserContainerComponent }]},
  {path: 'users-read',component: MainlayoutComponent,
    children: [{ path: '', component: UsersReadComponent }]},
  {path: 'users/create',component: MainlayoutComponent,
    children: [{ path: '', component: UsersCreateComponent }]},
  {path: 'users/details-list',component: MainlayoutComponent,
    children: [{ path: '', component: UserContainerComponent }]},
  {path: 'users/update/:id',component: MainlayoutComponent,
    children: [{ path: '', component: UsersUpdateComponent }]},

  // User Groups
  {path: 'groups',component: MainlayoutComponent,
    children: [{ path: '', component: UsersGroupsReadComponent }]},
  {path: 'groups/create',component: MainlayoutComponent,
    children: [{ path: '', component: UsersGroupsCreateComponent }]},
  {path: 'groups/update/:id',component: MainlayoutComponent,
    children: [{ path: '', component: UsersGroupsUpdateComponent }]},

  // User Profiles
  {path: 'profiles',component: MainlayoutComponent,
    children: [{ path: '', component: UsersProfilesReadComponent }]},
  {path: 'profiles/create',component: MainlayoutComponent,
    children: [{ path: '', component: UsersProfilesCreateComponent }]},
  {path: 'profiles/update/:id',component: MainlayoutComponent,
    children: [{ path: '', component: UsersProfilesUpdateComponent }]},


  // Persons Perfil
  {path: 'persons-perfil', component: PersonsPerfilComponent},
  {path: 'personsperfil/create',component: MainlayoutComponent,
    children: [{ path: '', component: PersonsPerfilCreateComponent }]},
  {path: 'personsperfil',component: MainlayoutComponent,
    children: [{ path: '', component: PersonsPerfilReadComponent }]},
  {path: 'personsperfil/update/:id',component: MainlayoutComponent,
    children: [{ path: '', component: PersonsPerfilUpdateComponent }]},

  // Company
  {path: 'company', component: MainlayoutComponent,
    children: [{ path: '', component: CompanyContainerComponent}]},
  {path: 'company-group-create', component: CompanyContainerComponent},
  {path: 'company-group-read', component: CompanyContainerComponent},
  {path: 'company-group-update/:id', component: CompanyContainerComponent},
  {path: 'company-division-create', component: CompanyContainerComponent},
  {path: 'company-division-read', component: CompanyContainerComponent},
  {path: 'company-division-update/:id', component: CompanyContainerComponent},

  // Dealership
  {path: 'dealerships',component: MainlayoutComponent,
    children: [{ path: '', component: DealershipContainerComponent }]},
  {path: 'dealership/create',component: MainlayoutComponent,
    children: [{ path: '', component: DealershipCreateComponent }]},
  {path: 'dealership/update/:id',component: MainlayoutComponent,
    children: [{ path: '', component: DealershipUpdateComponent }]},

  // Plant
  {path: 'plant',component: MainlayoutComponent,
    children: [{ path: '', component: PlantContainerComponent }]},
  {path: 'plant/sectors',component: PlantRoutingModule,
    children: [{ path: '', component: PlantContainerComponent }]},
  {path: 'plant/points',component: PlantRoutingModule,
    children: [{ path: '', component: PlantContainerComponent }]},

  // Equipment
  {path: 'plant/equipment',component: MainlayoutComponent,
    children: [{ path: '', component: EquipmentContainerComponent }]},
  {path: 'plant/equipmenttypes',component: PlantRoutingModule,
    children: [{ path: '', component: EquipmentContainerComponent }]},
  {path: 'plant/equipmentnatures',component: PlantRoutingModule,
    children: [{ path: '', component: EquipmentContainerComponent }]},
  {path: 'plant/equipments',component: PlantRoutingModule,
    children: [{ path: '', component: EquipmentContainerComponent }]},
  {path: 'plant/equipmentimages',component: PlantRoutingModule,
    children: [{ path: '', component: EquipmentContainerComponent }]},

  // Crud Builder
  {
    path: 'get_crud/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: CustomCrudsComponent }
    ]
  },
  // Custom Report
  {
    path: 'custom-report/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: ReportContainerComponent }
    ]
  },
  // Report Builder
  {
    path: 'get_report_builder',
    component: MainlayoutComponent,
    children: [
      { path: '', component: CustomizeContainerComponent }
    ]
  },
  // Newsletter
  {
    path: 'newsletter_link/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: NewsletterLinkComponent }
    ]
  },
  {
    path: 'newsletter_sample/:id/:name',
    component: MainlayoutComponent,
    children: [
      { path: '', component: NewsletterSampleComponent }
    ]
  },
  {
    path: 'cube',
    component: MainlayoutComponent,
    children: [
      { path: '', component: CubeComponent }
    ]
  },
  {
    path: 'help_index',
    component: MainlayoutComponent,
    children: [
      { path: ':url', component: HelpIndexComponent },
      { path: 'results/:q', component: HelpIndexComponent }
    ]
  },
  {
    path: 'panel-list/:type',
    component: MainlayoutComponent,
    children: [
      { path: '', component: PanelListComponent }
    ]
  },
  {
    path: 'upload-panel',
    component: MainlayoutComponent,
    children: [
      { path: '', component: UploadPanelComponent }
    ]
  },
  {
    path: 'crud-builder',
    component: MainlayoutComponent,
    children: [
      { path: '', component: CrudBuilderComponent }
    ]
  },
  {
    path: 'crud-builder/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: CrudBuilderComponent }
    ]
  },
  {
    path: 'report-builder/analytics',
    component: MainlayoutComponent,
    children: [
      { path: '', component: ReportBuilderComponent }
    ]
  },
  {
    path: 'report-builder/dashboard',
    component: MainlayoutComponent,
    children: [
      { path: '', component: ReportBuilderComponent }
    ]
  },
  {
    path: 'report-builder/newsletter',
    component: MainlayoutComponent,
    children: [
      { path: '', component: ReportBuilderComponent }
    ]
  },
  {
    path: 'report-builder/:t/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: ReportBuilderComponent }
    ]
  },
  {
    path: 'scenery-comparison',
    component: MainlayoutComponent,
    children: [
      { path: '', component: SceneryComparisonComponent }
    ]
  },
  { 
    path: 'data-importer', 
    loadChildren: () => import('./components/data-importer/data-importer.module')
    .then(m => m.DataImporterModule)
  },
  {
    path: 'supervisory',
    loadChildren: () => import('./components/supervisory/supervisory.module')
    .then(m => m.SupervisoryModule)
  },
  {
    path: 'instance-configurations',
    loadChildren: () => import('./components/instance-configuration/instance-configuration.module')
    .then(m => m.InstanceConfigurationModule)
  },
  {
    path: 'booking-variables',
    loadChildren: () => import('./components/booking/booking.module')
    .then(m => m.BookingModule)
  },
  // {
  //   path: 'persons',
  //   loadChildren: () => import('./components/person/person.module')
  //   .then(m => m.PersonModule)
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

export const routingComponents = [
  CompanyContainerComponent,
  CompanyDivisionCreateComponent,
  CompanyDivisionReadComponent,
  CompanyDivisionUpdateComponent,

  CompanyContainerComponent,
  CompanyGroupComponent,
  CompanyDivisionCreateComponent,
  CompanyGroupReadComponent,
  CompanyGroupUpdateComponent,

  PersonContainerComponent,
  PersonsPerfilComponent,
  PersonsPerfilCreateComponent,
  PersonsPerfilReadComponent,
  PersonsPerfilUpdateComponent,
  UserContainerComponent,
  UsersCreateComponent,
  UsersReadComponent
];

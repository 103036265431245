import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import {
  BoxGeometry,
  Clock,
  Mesh,
  MeshBasicMaterial,
  OrthographicCamera,
  PerspectiveCamera,
  PlaneGeometry,
  Raycaster,
  Scene,
  TextureLoader,
  Vector2,
  WebGLRenderer,
} from 'three';
import { BaseFunc } from '../base-func/base-func';
import { Global } from '../base-func/global';
import { StoreThreeRenderService } from './store-three-render.service';

@Component({
  selector: 'app-three-render-visualizer',
  templateUrl: './three-render-visualizer.component.html',
  styleUrls: ['./three-render-visualizer.component.css'],
})
export class ThreeRenderVisualizerComponent extends BaseFunc implements OnInit, OnChanges, AfterViewInit {
  @Input() data: { title: string; content: string; points: any[] };
  @Input() levels: any[] = [];

  @Input() fullWidth: boolean = false;
  @Input() gridView: boolean = false;

  @Input() ancor: string;
  @Input() context: any;

  @ViewChild('rCanvas', { static: true })
  canvasRef: ElementRef<HTMLCanvasElement>;

  global: Global = new Global();

  // @HostListener('window:resize')
  onResize() {
    let frame = document.getElementById('content-preview') as HTMLElement;

    if (!this.fullWidth) {
      this.windthScreen = frame.clientWidth;
      this.heightScreen = frame.clientHeight;
    }

    if (this.fullWidth) {
      const ancor = document.getElementsByClassName(this.ancor)[0];

      frame = document.getElementsByClassName('grid-stack-item')[0] as HTMLElement;
      this.windthScreen = ancor.clientWidth;
      this.heightScreen = ancor.clientHeight - 90;
    }

    this.renderer.setSize(this.windthScreen, this.heightScreen);
    // this.camera.aspect = this.windthScreen / this.heightScreen;
    this.camera.updateProjectionMatrix();
  }

  windthScreen: number = 0;
  heightScreen: number = 0;

  scene: Scene;
  camera: OrthographicCamera;
  renderer: WebGLRenderer;
  raycaster: Raycaster;
  mouse: Vector2;
  clock: Clock;

  cameraZ = 1.3;

  constructor(private sanitizer: DomSanitizer, private ngZone: NgZone, private store: StoreThreeRenderService) {
    super();
  }

  onFillForm() {
    const el = document.getElementById('open-sidemenu') as HTMLElement;
    el?.click();

    const { xValues } = this.context.maps;
    const { yValues } = this.context.maps;
    const { strats } = this.context.maps;
    const { maps, id, graphConfig } = this.context.maps;

    const widgetId = document.getElementById('supervisory_id') as HTMLElement;
    widgetId.setAttribute('supervisory_id', id);

    this.global.clearSelect(this.context.xAxisSelect);
    this.context.xAxisSelect._onChange();

    this.global.clearSelect(this.context.yAxisSelect);
    this.context.yAxisSelect._onChange();

    if (this.context.strats) {
      this.global.clearSelect(this.context.strats);
      this.context.strats._onChange();
    }

    this.$j('#componentName').val(this.context.maps.title);

    this.$j('#hideLegend')[0].checked = graphConfig.hideLegend;
    this.$j('#consolidate')[0].checked = graphConfig.consolidate;

    xValues.map((value) => {
      const elExists = this.context.xAxisSelect.itemsList.items.filter((el) => el.label == value.text)[0];
      const elHasAdded = this.context.xAxisSelect.itemsList.selectedItems.filter((el) => el.label == value.text)[0];

      if (elExists && !elHasAdded) {
        this.context.xAxisSelect.itemsList.selectedItems.push(elExists);
        this.context.xAxisSelect._onChange();
      }
    });

    yValues.map((value) => {
      const elExists = this.context.yAxisSelect.itemsList.items.filter((el) => el.label == value.text)[0];
      const elHasAdded = this.context.yAxisSelect.itemsList.selectedItems.filter((el) => el.label == value.text)[0];

      if (elExists && !elHasAdded) {
        this.context.yAxisSelect.itemsList.selectedItems.push(elExists);
        this.context.yAxisSelect._onChange();
      }
    });

    strats.map((value) => {
      const elExists = this.context.strats.itemsList.items.filter((el) => el.label == value.text)[0];
      const elHasAdded = this.context.strats.itemsList.selectedItems.filter((el) => el.label == value.text)[0];

      if (elExists && !elHasAdded) {
        this.global.addToSelect2(this.context.strats, null, elExists.label);
        this.context.strats._onChange();
      }
    });

    this.context.xAxisSelect._onChange();

    setTimeout(() => {
      const supervisoryBtn = document.getElementById('view_supervisory') as HTMLElement;
      supervisoryBtn?.click();
    }, 500);

    setTimeout(() => {
      maps.map((el, index) => {
        const img = document.getElementById(`image-${index}`) as HTMLImageElement;
        if (!img.classList.contains('selected-map')) {
          img?.click();
        }
      });
    }, 500);

    this.$j('#title_x').val(graphConfig.x.title);
    this.$j('#title_y').val(graphConfig.y.title);
  }

  onRemove() {
    this.store.onRemovePrerender(this.context.maps.id);
  }

  ngAfterViewInit(): void {
    if (this.ancor) {
      this.observerDivDimensionsChanges().subscribe(() => {
        this.onResize();
      });
    }
  }

  observerDivDimensionsChanges(): Observable<DOMRectReadOnly> {
    const element = document.getElementsByClassName(this.ancor)[0];

    return new Observable((obs) => {
      const resizeObserver = new ResizeObserver((entries) => {
        this.ngZone.run(() => {
          for (let entry of entries) {
            obs.next(entry.contentRect);
          }
        });
      });
      resizeObserver.observe(element);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.scene) {
      this.onInitScene();
    }

    if (this.data) {
      this.onMountMap();
    }
  }

  ngOnInit(): void {
    if (!this.scene) {
      this.onInitScene();
      this.onResize();
    }
    this.renderScene();
  }

  onInitScene() {
    let frame = document.getElementById('content-preview') as HTMLElement;

    if (!this.fullWidth) {
      this.windthScreen = frame.clientWidth;
      this.heightScreen = frame.clientHeight;
    }

    if (this.fullWidth) {
      const ancor = document.getElementsByClassName(this.ancor)[0];

      frame = document.getElementsByClassName('grid-stack-item')[0] as HTMLElement;
      this.windthScreen = ancor.clientWidth;
      this.heightScreen = ancor.clientHeight - 90;
    }

    this.scene = new Scene();
    this.camera = new OrthographicCamera();
    this.renderer = new WebGLRenderer();
    this.renderer.setSize(this.windthScreen, this.heightScreen);
    this.raycaster = new Raycaster();
    this.mouse = new Vector2();
    this.clock = new Clock();

    this.canvasRef.nativeElement.appendChild(this.renderer.domElement);
    this.camera.position.z = this.cameraZ;
  }

  onChangeView(index: number) {
    this.data = this.levels[index];
    this.onMountMap();
  }

  onMountMap() {
    this.scene.children = this.scene.children.filter((el) => el.name !== 'background');

    const src = this.data ? this.data : this.levels[0];

    if (!src?.content) {
      return;
    }

    const map = new TextureLoader().load(src.content);
    const material = new MeshBasicMaterial({ map });
    const geometry = new PlaneGeometry(!this.fullWidth ? 1 : 0.5, 1);

    const cube = new Mesh(geometry, material);
    cube.name = 'background';
    this.scene.add(cube);

    src.points.map((item: any) => {
      this.onPositionItem(item.color, item.x, item.y, 0.5);
    });
  }

  onPositionItem(color: any, x: number, y: number, z: number) {
    const geometry = new PlaneGeometry(!this.fullWidth ? 0.05 : 0.02, 0.05);
    const material = new MeshBasicMaterial({ color });

    const plane = new Mesh(geometry, material);
    plane.name = 'sensor';
    plane.position.set(x, y, z);

    this.scene.add(plane);
  }

  onZoon(type: 'in' | 'out') {
    if (type == 'out') {
      this.scene.children.map((child) => {
        child.scale.z += 0.2;
        child.scale.x += 0.2;
        child.scale.y += 0.2;
      });
    }
    if (type == 'in') {
      this.scene.children.map((child) => {
        child.scale.z -= 0.2;
        child.scale.x -= 0.2;
        child.scale.y -= 0.2;
      });
    }
  }

  onMoveCamera(type: 'left' | 'up' | 'right' | 'down') {
    switch (type) {
      case 'right':
        this.camera.position.x -= 0.1;
        break;

      case 'left':
        this.camera.position.x += 0.1;
        break;

      case 'down':
        this.camera.position.y -= 0.1;
        break;

      case 'up':
        this.camera.position.y += 0.1;
        break;

      default:
        break;
    }
  }

  onSanitizeImg(src: string) {
    const trusted = this.sanitizer.bypassSecurityTrustUrl(src);
    return trusted;
  }

  renderScene() {
    let frameCounter = 0;
    const animate = () => {
      requestAnimationFrame(animate);

      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PersonService } from './../../person/person.service';
import { Company } from '../../company/shared/model/company-division.model'
import { Person } from './../../person/person.model';
import { DealershipService } from './../dealership.service';
import { take } from 'rxjs/operators';
import { BaseFunc } from '../../shared/base-func/base-func';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-dealership-update',
  templateUrl: './dealership-update.component.html',
  styleUrls: ['./dealership-update.component.css']
})
export class DealershipUpdateComponent extends BaseFunc implements OnInit {

  form: FormGroup;
  persons: Person[];
  companies: Company[];

  hour_capacitive_start = "";
  hour_capacitive_end = "";
  hour_point_start = "";
  hour_point_end = "";

  submitted = false;
  selectedPerson = "";

  isLoading: boolean = false;

  data: any = [];
  selectedConcessionary: any;


  constructor(
    private formBuilder: FormBuilder,
    private dealershipService: DealershipService,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { super() }

  ngOnInit(): void {
    // check if user is enable
    this.authService.isEnable();

    // check permission
    if (this.authService.checkPermission('change_dealership') == false) {
      this.router.navigate(['/403']);
    };

    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
      this.onInitForm();
      this.onListDealerFields(id);
    });
  }

  onInitForm() {
    this.form = this.formBuilder.group({
      id: [''],
      app_id: [''],
      company: [''],
      person: ['', Validators.required],
      concessionary: ['', Validators.required],
      dl_id: [''],
      hour_point_start: [this.hour_point_start],
      hour_point_end: [this.hour_point_end],
      hour_capacitive_start: [this.hour_capacitive_start],
      hour_capacitive_end: [this.hour_capacitive_end],
    });
  }

  read = (id) => {
    this.personService.getPersons(this.authService.endpoint_main + 'person/list/nopage').pipe(take(1)).subscribe(
      data => {
        this.persons = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
      }
    )
  }

  onFillDealerInfo(id: string | number) {
    this.dealershipService.onDealerInfo(id).subscribe((data: any) => {
     
    const concess = this.data?.findIndex((el: any) => el.type === data.concessionary);

    this.form.get('person').setValue(data.person.id)
    this.form.get('company').setValue(data.company)
    this.form.get('concessionary').setValue(concess)
    this.onSelectConcessionary();
  
     setTimeout(() => {
      data.fields.map((el: any) => {
        const field = document.getElementById(el.field_id) as HTMLInputElement
        field.value = el.value;
      })
      }, 1000)
    })
  }

  onListDealerFields(id: number | string) {

    this.dealershipService.onListFieldsToCreateDealer().subscribe((data) => {
      this.data = Object.entries(data).map(([key, value]) => {
        return { type: key, items: value };
      });

      this.onFillDealerInfo(id);
    })
  }

  onSelectConcessionary() {
    const index = this.form.get('concessionary').value;
    this.selectedConcessionary = this.data[index];
  }

  onSubmit() {
    const { id } = this.route.params['_value'];

    const payload = {
      id,
      person: this.form.get('person').value,
      company: this.authService.getPerson(),
      concessionary: this.selectedConcessionary.type,
      fields: this.selectedConcessionary.items.map((el: any) => {
        return {
          field_id: el.field.id,
          field_name: el.field.name,
          value: this.$j(`#${el.field.id}`).val(),
        };
      }),
    };

    const hasEmpty = payload.fields.some((el) => !el.value)
    if(!hasEmpty) {
      this.dealershipService.onUpdateDealer(payload).subscribe((data) => {
        this.modalAlertService.showAlertSuccess('Concessionária criada com sucesso!')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
    }

  }


  get f() { return this.form.controls; }

  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  update = () => {
    this.submitted = true;

    if (this.form.valid) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {

        this.isLoading = true;
        this.dealershipService.updateDealership(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })

      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      this.validateAllFormFields(this.form);
    }
  }

  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

}

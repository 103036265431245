<section class="section">
    <div class="section-header user-profile-header_read">
        <h1>Perfis de usuário</h1>
        <!-- <div class="section-header-breadcrumb user-profile-header-breadcrumb_read">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item active">Grupos de usuário</div>
        </div> -->
    </div>

    <div class="section-body">
        <!-- <h2 class="section-title">Table</h2>
        <p class="section-lead">Example of some Bootstrap table components.</p> -->

        <div class="row">
            <div class="col-12 ">
                <div class="card">
                    <div class="card-header">
                        <h4><a class="btn btn-primary" routerLink='/profiles/create'>Novo</a></h4>
                        <div class="card-header-action">
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-md">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of profiles">
                                        <td>
                                            {{item.name}}
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-icon btn-warning"
                                                (click)="readId(item.id)">
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button class="btn btn-icon btn-danger" (click)='onDelete(item)'>
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class=" card-footer text-right">
                        <nav class="d-inline-block">
                            <ul class="pagination mb-0">
                                <li class="page-item">
                                    <a class="page-link" attr.disabled="previous==null ? true : null"
                                        (click)="fetchPrevious()">
                                        <i class="fa fa-chevron-left"></i>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" attr.disabled="next==null ? true : null" (click)="fetchNext()">
                                        <i class="fa fa-chevron-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

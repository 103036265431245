import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`
    }),
  }
  httpOptionsTest = {
    headers: new HttpHeaders({
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }
  params = new HttpParams();
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getPersons(url: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  getCities(url): Observable<any> {
    return this.http.get(url, this.httpOptions);
  }

  getUfs(url): Observable<any> {
    return this.http.get(url, this.httpOptions);
  }

  getAllPersonPerfil(url: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  getPerson(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'person/detail/' + id, this.httpOptions);
  }

  getPersonAddress(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'person/address/detail/' + id, this.httpOptions);
  }

  getPersonContactByPersonId(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'person/contact/list/' + id, this.httpOptions);
  }

  getPersonFileByPersonId(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'person/file/list/' + id, this.httpOptions);

  }

  updatePerson(perfil): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'person/update/' + perfil.id, perfil, this.httpOptions);
  }

  createPerson(perfil): Observable<any> {
    let url = this.authService.endpoint_main + 'person/create';
    return this.http.post(url, perfil, this.httpOptions);
  }

  createPersonFileUpload(formData, id): Observable<any> {
    let url = this.authService.endpoint_main + 'person/fileupload/' + id;
    return this.http.post(url, formData, this.httpOptionsTest);
  }

  deletePerson(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'person/delete/' + id, this.httpOptions);
  }

  deletePersonFile(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + '/person/file/delete/' + id, this.httpOptions);
  }
  mainPublisher(forceSync): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'person/main/publisher', forceSync, this.httpOptions);
  }

  mainPublisherStatus(): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'person/main/publisher_status', this.httpOptions);
  }
}

<section class="section">
  <div class="section-header person-profile-header_create">
    <h1>{{ layout.aliasTitle('Perfil de pessoas') }}</h1>
          <!-- <div class="section-header-breadcrumb person-profile-header-breadcrumb_create">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/personperfils">Perfis de Pessoa Física/Jurídica</a></div>
          <div class="breadcrumb-item active">Adicionar</div>
      </div> -->
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-6">
      <div class="card">
        <div class="card-header">
          <h4>Adicionar {{ layout.aliasTitle('Perfil de pessoas') }}</h4>
        </div>

        <div class="card-body">
          <form [formGroup]="profileForm" (ngSubmit)="save()" method="POST">
            <div class="form-group">
              <label for="name">Nome</label>
              <input type="text" class="form-control mb-2 mr-sm-2" id="name"
                placeholder="Digite nome do novo perfil" formControlName="name" [ngClass]="displayFieldCss('name')">
              <div class="invalid-feedback">
                Por favor, informe um nome.
              </div>
            </div>

            <div class="form-group">
              <label class="d-block">Status</label>
              <div class="form-check">
                <input class="form-check-input" formControlName="active" type="checkbox">
                <label class="form-check-label" for="active">Ativo</label>
              </div>
            </div>

            <div class="card-footer text-right">
              <app-default-button [label]="'Salvar'"></app-default-button>
              <app-default-button [type]="'secondary'" [label]="'Cancelar'" [navigateTo]="'/personsperfil'"></app-default-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild('inputfile', { static: false }) public inputfile: ElementRef;

  truefile: HTMLElement;
  filesrc = '../assets/img/avatar/avatar-1.png';

  constructor() { }

  ngOnInit(): void {
  }

  callFileInput = (event: any) => {
    this.inputfile.nativeElement.click();
  }

  onFileChange = (event: any) => {
    console.log(event);
    event.preventDefault();
    const files = event.target.files;
    this.filesrc = event.target.formAction + '/' + files[0].name;
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-default-icon-button',
  templateUrl: './default-icon-button.component.html',
  styleUrls: ['./default-icon-button.component.css'],
})
export class DefaultIconButtonComponent implements OnInit {
  @Output() pressed: EventEmitter<boolean> = new EventEmitter();
  @Output() hover: EventEmitter<boolean> = new EventEmitter();

  @Input() tooltiptext: string = null;
  @Input() type: 'default' | 'success' | 'danger' | 'warning' | 'primary' = 'default';
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onPress() {
    this.pressed.emit(true);
  }

  onHover() {
    this.hover.emit(true);
  }
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PersonService } from '../../../../person/person.service';
import { Location } from '@angular/common';
import { Company, CompanyDivision } from '../../../shared/model/company-division.model';
import { Person } from '../../../../person/person.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para criar uma nova empresa.
 */
@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.css']
})
export class CompanyCreateComponent implements OnInit {

  /** FormGroup para o formulário da empresa. */
  companyForm: FormGroup;

  /** Lista de pessoas disponíveis. */
  persons: Person[];

  /** Lista de divisões de empresas disponíveis. */
  companyDivisions: CompanyDivision[];

  /** Lista de fuso horário. */
  timezones = [];

  /** Lista de extensões de imagens. */
  allowedExtensions: string[] = ['.png', '.jpeg', '.jpg', '.jfif', '.gif'];

  /** Indica se o formulário foi enviado/submetido. */
  submitted = false;

  isLoading: boolean = false;


  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e recupera informações necessárias.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('add_company') == false) {
      this.router.navigate(['/403']);
    };

    // Obtém todas as pessoas.
    this.personService.getPersons(this.authService.endpoint_main + 'person/list/nopage').pipe(take(1)).subscribe(
      data => {
        this.persons = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
      }
    );

    // Obtém todas as divisões de empresas.
    this.companyService.getCompanyDivisionAll(this.authService.endpoint_main + 'companydivisions/nopage').pipe(take(1)).subscribe(
      data => {
        this.companyDivisions = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de unidade de grupos empresariais');
      }
    );

    // Configura a lista de fusos horários.
    this.timezones = [
      { "id": "America/Noronha", "value": "America/Noronha" },
      { "id": "America/Belem", "value": "America/Belem" },
      { "id": "America/Fortaleza", "value": "America/Fortaleza" },
      { "id": "America/Recife", "value": "America/Recife" },
      { "id": "America/Araguaina", "value": "America/Araguaina" },
      { "id": "America/Maceio", "value": "America/Maceio" },
      { "id": "America/Bahia", "value": "America/Bahia" },
      { "id": "America/Sao_Paulo", "value": "America/Sao_Paulo" },
      { "id": "America/Campo_Grande", "value": "America/Campo_Grande" },
      { "id": "America/Cuiaba", "value": "America/Cuiaba" },
      { "id": "America/Santarem", "value": "America/Santarem" },
      { "id": "America/Porto_Velho", "value": "America/Porto_Velho" },
      { "id": "America/Boa_Vista", "value": "America/Boa_Vista" },
      { "id": "America/Manaus", "value": "America/Manaus" },
      { "id": "America/Eirunepe", "value": "America/Eirunepe" },
      { "id": "America/Rio_Branco", "value": "America/Rio_Branco" },
    ]

    // Inicializa o FormGroup do formulário da empresa.
    this.companyForm = this.formBuilder.group({
      app_id: [''],
      company_division: null,
      timezone: ['', Validators.required],
    });
  }


  /**
   * Verifica se um campo específico do formulário é válido.
   *
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo for inválido e o formulário foi submetido, caso contrário, `false`.
   */
  isFieldValid(field: string) {
    return !this.companyForm.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes CSS apropriadas para destacar campos inválidos.
   *
   * @param field Nome do campo a ser verificado.
   * @returns Objeto CSS com a classe 'is-invalid' se o campo for inválido, caso contrário, um objeto vazio.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário, marcando-os como tocados.
   *
   * @param formGroup FormGroup a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Manipula a mudança de imagem quando um arquivo é selecionado no campo de upload.
   *
   * @param event O evento de mudança de imagem.
   */
  onImageChange(event: Event): void {
    // Obtém o elemento de entrada de arquivo do evento.
    const inputElement = event.target as HTMLInputElement;
    // Obtém o arquivo selecionado.
    const file = (inputElement.files as FileList)[0];

    // Verifica se há um arquivo.
    if (file) {
      // Verifica se o tipo do arquivo é uma imagem.
      if (!file.type.startsWith('image/')) {
        // Se não for uma imagem, define um erro no campo 'logo' e limpa o valor do input.
        this.companyForm.get('logo').setErrors({ 'invalidFileType': true });
        inputElement.value = '';
      } else {
        // Se for uma imagem, remove qualquer erro no campo 'logo'.
        this.companyForm.get('logo').setErrors(null);
      }
    }
  }

  /**
 * Salva os dados do formulário de criação da empresa.
 * Este método é chamado ao enviar o formulário de criação.
 */
  save = (data: any) => {
    // Define que o formulário foi enviado.
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.companyForm.valid) {
      // Verifica se existe Token.
      if (this.authService.hasToken()) {

        // Chama o serviço para criar a empresa.
        this.isLoading = true;
        const payload = {
          ...this.companyForm.value,
          person: data.id,
          company_division: String(this.companyForm.value['company_division']),
        }

        this.companyService.createCompany(payload).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.router.navigate(['/company'])
          },
          error => {
            // Exibe um alerta de erro ao criar o registro.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de erro caso não haja um ID de aplicativo disponível.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário não seja válido, valida todos os campos.
      this.validateAllFormFields(this.companyForm);
    }
  }


  /**
   * Reseta o formulário, marcando-o como não submetido e limpando os campos.
   */
  reset() {
    this.submitted = false;
    this.companyForm.reset();
  }

  /**
 * Obtém a mensagem de erro para o tipo de arquivo inválido.
 *
 * @returns A mensagem de erro ou `null` se não houver erro.
 */
  get invalidFileTypeError() {
    const logoControl = this.companyForm.get('logo');
    return logoControl.hasError('invalidFileType') ? 'Tipo de arquivo inválido. Por favor, selecione uma imagem.' : null;
  }

}

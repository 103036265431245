<section class="section">
  <div class="section-header user-header_read" [ngClass]="{ 'bg-white': isHorizontal }">
    <h1>Gestão de Usuários</h1>
    <!-- <div class="section-header-breadcrumb user-header-breadcrumb_read">
      <div class="breadcrumb-item active"><a href="">Dashboard</a></div>
      <div class="breadcrumb-item">Usuários</div>
    </div> -->
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-12 ">
        <div class="card">
          <div class="card-header" [ngClass]="{ 'bg-white': isHorizontal }">
            <h4><a class="btn btn-primary" routerLink='/users/create'>Novo Usuário</a></h4>
            <div class="card-header-action">
            </div>
          </div>
          <div class="mx-3">
            <button *ngIf="isChecked" type="button" class="btn btn-danger" (click)="onDeleteMultiples()">
              Excluir usuários
            </button>
            <button *ngIf="isChecked" type="button" class="btn btn-secondary" (click)="onEditMultiples()">
              Confirmar alterações
            </button>
          </div>

          <ng-container *ngIf="users?.length > 0 && !isLoadingUsers">
            <div class="card-body" [@fade]>
              <div class="table-responsive">
                <div class="fixed-header-table">
                  <table class="table table-bordered table-md">
                    <thead>
                      <tr>
                        <th>
                          <div class="d-flex justify-content-between">
                            <span>Nome</span>
                            <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Nome')"></i>
                          </div>
                          <div [class.d-none]="selectedFilter !== 'Nome'">
                            <ng-container *ngTemplateOutlet="options"></ng-container>
                          </div>
                        </th>
                        <th>
                          <div class="d-flex justify-content-between">
                            <span>E-mail</span>
                            <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Email')"></i>
                          </div>
                          <div [class.d-none]="selectedFilter !== 'Email'">
                            <ng-container *ngTemplateOutlet="options"></ng-container>
                          </div>
                        </th>
                        <th>
                          <div class="d-flex justify-content-between">
                            <span>Perfil</span>
                          </div>
                        </th>
                        <th>
                          <div class="d-flex justify-content-between">
                            <span>Ativo</span>
                            <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('Ativo')"></i>
                          </div>
                          <div [class.d-none]="selectedFilter !== 'Ativo'">
                            <ng-container *ngTemplateOutlet="options"></ng-container>
                          </div>
                        </th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of usersFiltered | slice:offset:limit; let i = index">
                        <td class="align-middle">
                          <div class="form-check">
                            <input class="checkbox-style" type="checkbox" name="{{item.id}}"
                              (change)='onChange($event,item,i)' id="chk_{{item.id}}">
                            <label class="form-check-label" for="chk_{{item.id}}">
                              <div *ngIf="!itemChecked[i]">{{item?.first_name}} {{item?.last_name}}</div>
                              <input *ngIf="itemChecked[i]" type="text" name="first_name" class="form-control"
                                [(ngModel)]="item.first_name">
                              <input *ngIf="itemChecked[i]" type="text" name="first_name" class="form-control"
                                [(ngModel)]="item.last_name">
                            </label>
                          </div>
                        </td>
                        <td class="align-middle">
                          <div *ngIf="!itemChecked[i]">{{item.email}}</div>
                          <input *ngIf="itemChecked[i]" type="email" name="email" class="form-control"
                            [(ngModel)]="item.email">
                        </td>

                        <td class="align-middle">
                          <ul *ngFor='let group of item.groups'>
                            <li>{{group.name}}</li>
                          </ul>
                        </td>
                        <td class="align-middle">
                          <div *ngIf="!itemChecked[i]" class="text-center">
                            <ng-template [ngIf]="item.is_active==true" [ngIfElse]="elseBlock">
                              <i class="fa fa-check-circle text-success" tooltip="Ativo"></i>
                            </ng-template>
                            <ng-template #elseBlock>
                              <i class="fa fa-times-circle text-danger" tooltip="Inativo"></i>
                            </ng-template>
                          </div>
                          <div *ngIf="itemChecked[i]" class="form-check">
                            <input *ngIf="item.is_active" class="checkbox-style" type="checkbox" name="{{i}}"
                              (change)="onEditChange($event,item,i)" id="chk_{{i}}" checked>
                            <input *ngIf="!item.is_active" class="checkbox-style" type="checkbox" name="{{i}}"
                              (change)="onEditChange($event,item,i)" id="chk_{{i}}">
                            <label class="form-check-label" for="chk_{{i}}">
                              Ativo
                            </label>
                          </div>
                        </td>
                        <td class="text-center align-middle">
                          <button type="button" class="btn btn-icon btn-warning" (click)="readId(item.id)"
                            tooltip="Editar">
                            <i class="fa fa-edit"></i>
                          </button>
                          <button class="btn btn-icon btn-danger" (click)='onDelete(item)' tooltip="Excluir">
                            <i class="fa fa-trash"></i>
                          </button>
                          <button class="btn btn-icon btn-info" (click)="openModalWithClass(template,item)"
                            tooltip="Visualizar">
                            <i class="fa fa-eye"></i>
                          </button>
                          <button class="btn btn-icon btn-lock" (click)='inactiveUser(item)' tooltip="Desativar">
                            <i class="fa fa-lock"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>


          <div class="row" [ngClass]="{ 'bg-white': isHorizontal }">
            <div class="col-12">
              <app-paginator-control [content]="usersFiltered" (change)="onPaginate($event)"></app-paginator-control>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Visualizar Cadastro de Usuário</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form method="POST" [formGroup]="userForm" class="forms">
      <div class="form-row justify-content-around">
        <div class="form-group col-md-5">
          <label>Nome</label>
          <input type="text" name="first_name" class="form-control" formControlName="first_name" readonly>
        </div>
        <div class="form-group col-md-5">
          <label>Sobrenome</label>
          <input type="text" name="last_name" class="form-control" formControlName="last_name" readonly>
        </div>
      </div>
      <div class="form-row justify-content-around">
        <div class="form-group col-md-5">
          <label>Email</label>
          <input type="email" name="email" class="form-control" formControlName="email" readonly>
        </div>
        <div class="form-group col-md-5">
          <label>Celular</label>
          <input type="text" name="phone_number" class="form-control" formControlName="phone_number"
            mask="(00) 0 0000-0000" readonly>
        </div>
      </div>
      <div class="form-row justify-content-around">
        <div class="form-group col-md-5">
          <label>Perfil</label>
          <ng-select [items]="allGroups" bindLabel="name" bindValue="id" class="form-control-select" [multiple]="true"
            formControlName="groups" [readonly]="true">
          </ng-select>
        </div>
        <div class="form-group col-md-5"></div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef?.hide()">Fechar</button>
  </div>
</ng-template>




<ng-template #options>
  <app-table-filters-popup 
  [visible]="isOptionsOpened" 
  [field]="selectedFilter" 
  [hasStatus]="selectedFilter == 'Ativo'" 
  [hasSearch]="selectedFilter != 'Ativo'"
  [hasOrdernator]="selectedFilter != 'Ativo'"
  (orderBy)="onOrderBy($event)" 
  (close)="onHideFilters()" 
  (search)="onSearch($event)"
  (clear)="onClearFilters()"
  (status)="onFilterBoolean($event)">
  </app-table-filters-popup>
</ng-template>

<ng-container *ngIf="type == 'primary'">
  <button class="btn btn-primary mr-1" type="submit" *ngIf="isLoading" disabled>
    <i class="fa-solid fa-circle-notch fa-lg fa-spin"></i>
    <span class="mx-2">{{ loadingLabel }}</span>
  </button>

  <button class="btn btn-primary mr-1" type="submit" *ngIf="!isLoading" (click)="onPress()">
    <span class="mx-2">{{ label }}</span>
  </button>
</ng-container>

<ng-container *ngIf="type == 'secondary'">
  <button class="btn cancel-button mr-1" type="button" (click)="onPress()">
    <span class="mx-2">{{ label }}</span>
  </button>
</ng-container>

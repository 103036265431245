import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertComponent } from './bootstrap-modal-alert.component';
import { BootstrapModalConfirmComponent } from '../bootstrap-modal-confirm/bootstrap-modal-confirm.component';

export enum AlertTypes {
  DANGER = 'danger',
  SUCCESS = 'success'
}

@Injectable({
  providedIn: 'root'
})
export class BootstrapModalAlertService {

  constructor(private modalService: BsModalService) { }

  private showAlert = (message: string, type: AlertTypes, dismissTimeout?: number) => {
    const bsModalRef: BsModalRef = this.modalService.show(BootstrapModalAlertComponent);
    bsModalRef.content.type = type;
    bsModalRef.content.message = message;

    if (dismissTimeout) {
        setTimeout(() => bsModalRef.hide(), dismissTimeout);
    }
  }

  showAlertDanger = (message: string) => {
    scrollTo(0,0)
    this.showAlert(message, AlertTypes.DANGER);
  }

  showAlertSuccess = (message: string) => {
    scrollTo(0,0)
    this.showAlert(message, AlertTypes.SUCCESS, 1000);
  }

  showConfirm = (title: string, msg: string, confirmTxt?: string, cancelTxt?: string) => {
    const bsModalRef: BsModalRef = this.modalService.show(BootstrapModalConfirmComponent);
    bsModalRef.content.title = title;
    bsModalRef.content.msg = msg;

    if (confirmTxt) {
      bsModalRef.content.confirmTxt = confirmTxt;
    }

    if (cancelTxt) {
      bsModalRef.content.cancelTxt = cancelTxt;
    }

    return bsModalRef.content.confirmResult;
  }
}

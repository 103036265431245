import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Company } from '../../../shared/model/company-division.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para exibir a lista de empresas.
 */
@Component({
  selector: 'app-company-read',
  templateUrl: './company-read.component.html',
  styleUrls: ['./company-read.component.css']
})
export class CompanyReadComponent implements OnInit {

  /** Lista de empresas. */
  companies: Company[];
  companiesFiltered: any[] = [];

  /** URL da próxima página paginada. */
  next: string;

  /** URL da página paginada anterior. */
  previous: string;

  /** Parâmetros HTTP para a requisição. */
  params = new HttpParams();

  /** Referência ao modal de exclusão. */
  deleteModalRef: BsModalRef;

  /** Referência ao modal de exclusão no template. */
  @ViewChild('deleteModal') deleteModal;

  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'person_id', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private companyService: CompanyService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e recupera a lista de empresas.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_company') == false) {
      this.router.navigate(['/403']);
    };

    // Lê todas as empresas.
    this.readAll(this.authService.endpoint_main + 'company/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.companiesFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onClearFilters() {
    this.companiesFiltered = this.companies;
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.companiesFiltered = this.companies;
      return;
    }

    this.companiesFiltered = this.companiesFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  /**
 * Obtém e exibe uma lista de empresas com base na URL fornecida.
 *
 * @param url A URL para recuperar a lista de empresas.
 */
  readAll(url: string) {
    // Verifica se existe token
    if (this.authService.hasToken()) {

      // Chama o serviço para obter a lista de empresas.
      this.companyService.getCompanyAll(url).pipe(take(1)).subscribe(
        data => {
          // Atribui os resultados à propriedade 'companies'.
          this.companies = data.results;
          this.companiesFiltered = data.results;

          // Verifica se há uma página seguinte disponível.
          if (data.next) {
            this.next = data.next;
          }

          // Verifica se há uma página anterior disponível.
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Não foi possível listar empresas');
        }
      );
    } else {
      // Se não houver ID, exibe um alerta de perigo e faz logout.
      this.modalAlertService.showAlertDanger('Erro ao recuperar empresas');
      this.authService.doLogout();
    }
  }

  /**
   * Carrega a próxima página de empresas paginadas.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Carrega a página anterior de empresas paginadas.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Redireciona para a página de atualização de uma empresa específica.
   *
   * @param id ID da empresa a ser atualizada.
   */
  readId = (id) => {
    this.router.navigate(['/company/update', id]);
  }

  /**
   * Navega para a página de criação de uma nova empresa.
   */
  create = () => {
    this.router.navigate(['/company/create']);
  }

  /**
   * Executa a exclusão de uma empresa com confirmação do usuário.
   *
   * @param item A empresa a ser excluída.
   */
  onDelete = (item) => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica as permissões de acesso para excluir uma empresa.
    if (this.authService.checkPermission('delete_company') == false) {
      // Se o usuário não tiver permissão, redireciona para a página de erro 403.
      this.router.navigate(['/403']);
    };

    // Armazena a empresa selecionada para exclusão.
    this.selectedItem = item;

    // Exibe um modal de confirmação para o usuário.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir esta empresa'
    );

    // Observa o resultado do modal de confirmação.
    result$.asObservable()
      .pipe(
        take(1),
        // Se o usuário confirmar, chama o serviço para excluir a empresa.
        switchMap(result => result ? this.companyService.deleteCompany(item.company_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Se a exclusão for bem-sucedida, encontra o índice da empresa no array e remove.
          let index;
          this.companies.forEach((e, i) => {
            if (e['company_id'] === item.company_id) {
              index = i;
            }
          });
          this.companies.splice(index, 1);
          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Em caso de erro na exclusão, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

import { Component, OnInit, ViewChildren, ViewEncapsulation, QueryList, ElementRef, Input, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';
import { DataEngineManager } from '../../shared/base-func/data-engine-manager';
import { BaseFunc } from '../../shared/base-func/base-func';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { CustomReportsService } from './custom-reports.service';
import { ReportBuilderService } from './../report-builder/report-builder.service';

import { AuthService } from './../../../auth/auth.service';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { Moment } from 'moment';

import { GridStack } from 'gridstack';
// import 'gridstack/dist/h5/gridstack-dd-native';

import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

import { FieldEngineComponent } from '../../shared/field-engine/field-engine.component';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { GuidedTour, Orientation } from 'ngx-guided-tour';
import { ThreeRenderVisualizerComponent } from '../../shared/three-render-visualizer/three-render-visualizer.component';
import { ThreeRenderComponent } from '../../shared/three-render/three-render.component';
import { StoreInstanceConfService } from '../../instance-configuration/stores/store-instance-conf.service';

class TableConfig {
  header: string[];
  rows: string[][];

  constructor(header: string[] = [], rows: string[][] = [[]]) {
    this.header = [''];
    this.rows = [['']];
  }
}

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: [
    './custom-reports.component.css',
    "../../../../../node_modules/leaflet/dist/leaflet.css",
    '../../../../../node_modules/gridstack/dist/gridstack.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomReportsComponent extends BaseFunc implements OnInit {
  @ViewChildren('cells') cells: QueryList<ElementRef>;
  @ViewChildren('fields') fields: QueryList<FieldEngineComponent>;

  @Input() reportId: number;

  form: FormGroup;
  reportName: string;
  reportFields: any[];
  reportFilters: any[];
  config: any;
  cellList: any[];
  baseURL: string = environment.repoUrl;
  tables: { [id: string]: TableConfig } = {};
  public defaultTableConfig = new TableConfig();
  isPaginationTour: string = localStorage.getItem('pagination_tour');

  self = this;
  lastsent: any;
  isReport: boolean;
  cell_js: any;
  valid: any;
  count: number = 0;
  page: number = 1;
  nextPage: boolean = false;
  prevPage: boolean = false;

  curr_lang: string;
  manager: any[];
  dashboardRef: any = 0;
  schedule: any = 0;
  nDate = new Date();
  stepDays = 1;
  grid: GridStack = null;

  hasSuspensoryGraph: boolean = false;
  information: any[] = [];
  fileAssets: any[] = []

  //text_array = Array.from(Array(10).keys());

  // Date Range
  global: Global = new Global();
  //ranges = this.global.getRanges();
  static_guid = this.global.createGuid();
  //selected = { startDate: moment().startOf('day'), endDate: moment().add(1, 'days').startOf('day'), };

  tourSequence: GuidedTour;
  hasFilters: boolean = false;

  constructor(
    public authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private sanitizer: DomSanitizer,
    private customreports: CustomReportsService,
    private rbbuider: ReportBuilderService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public layout: LayoutService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private el: ElementRef,
    private appRef: ApplicationRef,
    private instanceConfig: StoreInstanceConfService
  ) {
    super();
  }

  ngOnInit(): void {
    this.read_config_data();
    this.buttonHiddenFilters();
  }

  ngAfterViewInit(): void {
    var self = this;
    var loaded = setInterval(function() {
      if ( self.form != null ) {
        self.setup(
          self.config.freq != undefined ? ['OperatorFocusedManagement', self.config.freq] : 'Report'
        );
        self.paginationTour();
        clearInterval(loaded);
      }
    }, 100);
  }


  onPrepareGuideTour({ fields }: any) {
    this.tourSequence = {
      tourId: 'custom-reports',
      steps: [
        {
          title: 'Lista de relatórios',
          selector: '.tour-report-header',
          orientation: Orientation.Bottom,
          content: 'Aqui você encontra a lista de relatórios disponíveis.',
        },
        {
          title: 'Relatórios selecionado',
          selector: '.tour-report-header-selected',
          orientation: Orientation.Right,
          content: 'Você pode alterar a exibição do relatório, a listra abaixo indica qual esta sendo visualizado no momento.',
        },
        {
          title: 'Filtros de visualização',
          selector: '.tour-report-filters',
          orientation: Orientation.Bottom,
          content: 'Aqui você encontra a lista de filtros disponíveis. Os filtros possibilitam diferentes formas de exibição das informações.',
        },
      ]
    }

    const finalSequence = [
      {
        title: 'Opções de exbição',
        selector: '.tour-report-options',
        orientation: Orientation.Left,
        content: 'Você pode controlar a forma como a página é exibida.',
      },
      {
        title: 'Opções de exibição',
        selector: '.tour-report-save',
        orientation: Orientation.Left,
        content: 'Após selecionar os filtros que deseja, clique aqui para realizar a consulta. Fique atendo caso exista campos obrigatórios.',
      },
    ]

    const previusAdded = []

    fields.map((field) => {
      if(previusAdded.includes(field.label)) {
        return
      }

      switch (field.type) {
        case 'DATEBTW':
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
            selector: '.tour-field-datebtw',
            orientation: Orientation.Bottom,
            content: field.description ?? 'Esse é um campo de seleção de datas em sequência.',
        });
        previusAdded.push(field.label);
        break;

        case 'DATA_WITHOUT_HOURS':
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
            selector: '.tour-field-datebtw',
            orientation: Orientation.Bottom,
            content: field.description ?? 'Esse é um campo de seleção de datas em sequência.',
          });
        previusAdded.push(field.label);
        break;

        case 'DATA':
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
            selector: '.tour-field-date',
            orientation: Orientation.Bottom,
            content: field.description ?? 'Esse é um campo de seleção de datas em sequência.',
          });
          previusAdded.push(field.label);
        break;


        case 'HOUR':
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
              selector: `#${field.dom_id}`,
              orientation: Orientation.Bottom,
              content: field.description ?? 'Esse é um campo de seleção de horas.',
              });
          previusAdded.push(field.label);
        break;

        case 'YEARBTW':
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
              selector: `#${field.dom_id}`,
              orientation: Orientation.Bottom,
              content: field.description ?? 'Esse é um campo de seleção de anos em sequência.',
              });
          previusAdded.push(field.label);
        break;

        default:
          this.tourSequence.steps.push({
            title: `Campo ${field.label}`,
              selector: `#${field.dom_id}`,
              orientation: Orientation.Bottom,
              content: field.description ?? `Esse é um campo de seleção de ${field.label}.`,
              });
          previusAdded.push(field.label);
          break;
      }
    })

    this.tourSequence.steps = [...this.tourSequence.steps, ...finalSequence]
  }


  onHandleFilters() {
    this.hasFilters = !this.hasFilters;
  }

  onShowHelper() {
    const btn = document.getElementById('helpButton') as HTMLElement;
    btn.click();
  }

  read_config_data = () => {
    var self = this;
    this.customreports.getCustomReportHeader(this.reportId).pipe(take(1)).subscribe( (data) => {

      this.fileAssets = data.file_assets;

      this.reportName = data.report.nome_rep;
      this.cellList = data.cellList;
      this.reportFilters = data.filters;
      this.reportFields = data.fields;
      if ( data.config.addContent != null)
        data.config.addContentSanitized = this.sanitizer.bypassSecurityTrustHtml(data.config.addContent);

      if ( data.config.seeds != null && data.config.seeds.length > 0 )
        data.config.seeds.forEach( (x) => (x['templateSanitized'] = this.sanitizer.bypassSecurityTrustHtml(x['template'])) );

      this.cellList.forEach((x) => {
        if ( x.addContent )
          x.addContentSanitized = this.sanitizer.bypassSecurityTrustHtml( x.addContent );
        if ( x.func_rbd )
          x.func_js = eval('(' + x.func_rbd + ')')
      });

      this.config = data.config;


      this.cell_js = eval(data.cell_js);
      this.valid = eval(data.valid);
      this.manager = this.cell_js.map(function () {
        var currDataManager = new DataEngineManager(self);
        currDataManager.colors = self.instanceConfig.graficColors;
        return currDataManager;
      });

      self.cell_js = self.cell_js.map((x) => {
        x.semaphore = true;
        return x;
      });
      this.form = this.formBuilder.group({});
      this.onPrepareGuideTour(data);
    },
    (error) => {
      console.log('Aconteceu um erro.', error.message);
      if(document.getElementById('applyFilters') != null) { document.getElementById('applyFilters').style.display = ''; }
      if(document.getElementById('processing') != null) { document.getElementById('processing').style.display = 'none'; }
    },
    () => {});
  };

  setup = (configPanel) => {
    this.grid = GridStack.init({
      alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
      ),
      resizable: {
        autoHide: true,
        handles: 'e, se, s, sw, w'
      },
      float: true,
      auto: true,
      removable: '#removeWidget',
      removeTimeout: 100,
      acceptWidgets: '.grid-stack-item-content',
      handleClass: 'grid-stack-item-content',
    });
    if (configPanel == 'Report')
      this.setupReport();
    else
      this.setupOperatorFocusedManagement(configPanel[1]);
  };

  setupReport = () => {
    var self = this;
    self.isReport = true;
    if (self.$j('.date-interval').length > 0)
      self.initializeTimeControls();

    self.$j('#favoriteView, #saveView, #navigateButtons, #btnPDF').hide();

    self.$j('#update_after').change(function () {
      var boundDate = function () {
        var step = parseInt(self.$j('#update_after').val());
        var refNow = new Date();
        refNow.setSeconds(0);
        var nextPeriod = step * (Math.floor(refNow.getMinutes() / step) + 1) - refNow.getMinutes();
        var bound = refNow.addMinutes(nextPeriod);
        bound.setSeconds(10);
        return bound;
      };
      self.nDate = boundDate();

      var update_clock = function () {
        var now = new Date();
        var diff = self.nDate.getTime() - new Date().getTime();
        if (diff <= 0) {
          self.nDate = boundDate();
          diff = self.nDate.getTime() - now.getTime();
          self.$j('#applyFilters')[0].click();
        }

        var minutes = Math.floor(diff / 60000);
        var seconds = Math.trunc((diff % 60000) / 1000);
        seconds = seconds == 60 ? 59 : seconds;
        self.$j('label[for="update_after"]').html(minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
      };
      if (self.$j(this).is(':checked')) {
        update_clock();
        self.schedule = setInterval(function () {
          update_clock();
        }, 1000);
      }
      else {
        clearInterval(self.schedule);
        self.$j('label[for="update_after"]').html(self.$j('label[for="update_after"]').attr('period'));
      }
    });

    self.$j('#favoriteView').click(() => {
      //GetFavoriteViews(source, '#report_wall');
      self.$j('#report_wall').show();
      self.$j('#report-content').hide();
    });

    self.checkBound();
  };

  applyFilters_btn = (event) => {
    var self = this;
    event.preventDefault();
    self.showContent(true);
  };

  setupOperatorFocusedManagement = (frequency) => {
    var self = this;
    self.isReport = false;
    self.$j('.align-items-center').hide();
    self.$j('#report-content').show();
    self.showContent(false);
    self.dashboardRef = setInterval(function () {
      if (self.$j('#report_header_filters').length != 0)
        clearInterval(self.dashboardRef);
      else self.showContent(false);
    }, frequency * 1000);
  };

  getFilterData = () => {
    var self = this;
    var data = { id: self.reportId, isReport: self.isReport, from: null, to: null, readableFilters: [], drillDown: null, };

    if (self.$j('.datebtw').length > 0) { // DATEBTW
      var datebtw = self.fields.find(x => x.field.type == 'DATEBTW');

      // Ajuste de horas para intervalos maiores que 1 dia
      var isStartDay = datebtw.selected.startDate.hour() == 0 && datebtw.selected.startDate.minute() == 0;
      var isEndDay = datebtw.selected.endDate.hour() == 0 && datebtw.selected.endDate.minute() == 0;
      var daysInterval = datebtw.selected.endDate.diff(datebtw.selected.startDate, 'days');
      if ( daysInterval > 1 && ( !isStartDay || !isEndDay ) ) {
        datebtw.selected.startDate.set({hour: 0, minute:0 });
        datebtw.selected.endDate.set({hour: 0, minute:0 });
        self.$j('#date-interval').val(datebtw.selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + datebtw.selected.endDate.format('DD/MM/YYYY HH:mm'));
      }

      data.from = moment(datebtw.selected.startDate);
      data.to = moment(datebtw.selected.endDate);

      //Calculate Drilldown
      var drillDown;
      var days = data.to.diff(data.from, 'days');
      var minutes = data.to.diff(data.from, 'minutes');
      if (minutes <= 60) drillDown = 'minutes';
      else if (days <= 1) drillDown = 'hours';
      else if (days > 1 && days <= 45) drillDown = 'week';
      else if (days > 45 && days <= 365 + 30) drillDown = 'month';
      else drillDown = 'year';
      data.drillDown = drillDown;
      self.checkBound();

      //Converte o dado para formato de exportação como JSON removendo o timezone
      //e deixando com intervalo aberto a direita
      data.from = new Date(data.from.format('YYYY-MM-DDTHH:mm') + ':00.000Z');
      data.to = new Date( data.to.add(-1, 'm').format('YYYY-MM-DDTHH:mm') + ':00.000Z' );
      data.readableFilters.push([ 'Data', datebtw.selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + datebtw.selected.endDate.format('DD/MM/YYYY HH:mm'), ]);
    }
    if (self.$j('.single-date').length > 0) { // DATA
      var datesingle = self.fields.find(x => x.field.type == 'DATA');
      data.from = moment(datesingle.selected.startDate);
      data.to = moment(datesingle.selected.endDate);
      data.from = new Date(data.from.format('YYYY-MM-DDTHH:mm') + ':00.000Z');
      data.to = new Date( data.to.add(-1, 'm').format('YYYY-MM-DDTHH:mm') + ':00.000Z' );
    }
    if (self.$j('.date-interval-year').length > 0) {
      data.from = moment( new Date(self.fields.find(x => x.field.type == 'YEARBTW').options.minValue, 0, 1, 0, 0) );
      data.to = moment( new Date(self.fields.find(x => x.field.type == 'YEARBTW').options.maxValue, 11, 31, 23, 59) );

      //Converte o dado para formato de exportação como JSON removendo o timezone
      //e deixando com intervalo aberto a direita
      data.from = new Date(data.from.format('YYYY-MM-DDTHH:mm') + ':00.000Z');
      data.to = new Date( data.to.format('YYYY-MM-DDTHH:mm') + ':00.000Z' );
    }
    /*if (self.$j('.dateym-interval').length > 0) { //DATEYMBTW
        var dateFrom = $("#datepickerPartialFrom").val().split('/');
        data.from = new Date(parseInt(dateFrom[1]), parseInt(dateFrom[0]) - 1, 1).dtformat('MM/dd/yyyy');
        var dateTo = $("#datepickerPartialTo").val().split('/');
        data.to = new Date(parseInt(dateTo[1]), parseInt(dateTo[0]), 1).addDays(-1).dtformat('MM/dd/yyyy');
    }
    if (self.$j('.dateym').length > 0) { // DATEYM
        var dateFrom = $("#datepickerPartialFrom").val().split('/');
        data.from = new Date(parseInt(dateFrom[1]), parseInt(dateFrom[0]) - 1, 1).dtformat('MM/dd/yyyy');
    }*/
    if (self.$j('.date-simple').length > 0) { //DATA
      data.from = self.fields.find(x => x.field.type == 'DATA').selected.startDate.format('MM/dd/yyyy');
      data.to = self.fields.find(x => x.field.type == 'DATA').selected.endDate.format('MM/dd/yyyy');
      data.readableFilters.push([ 'Data', self.fields.find(x => x.field.type == 'DATA').selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + self.fields.find(x => x.field.type == 'DATA').selected.endDate.format('DD/MM/YYYY HH:mm'), ]);
    }
    if (self.$j('div[ac_tools]').length > 0) {
      self.$j('div[ac_tools]').each(function (element) {
        var acVal = self.$j(element).attr('ac_tools');
        var selElements = self.getComponent( document.querySelector('#select' + acVal) ).itemsList.selectedItems;
        data[acVal] = selElements.map(function (e, i) { return e.value.id; });
        data.readableFilters.push([ self.reportFields.find((x) => x.type == acVal).label, selElements.map(function (e, i) { return e.value.name || e.value.label; }), ]);
      });
    }
    if (self.$j('.textInput').length > 0) {
      data['TEXT'] = [];
      self.$j('.textInput').forEach(function (e) { data['TEXT'].push(self.$j(e).val()); });
    }
    if (self.$j('.plainInput').length > 0) {
      data['PLAIN'] = [];
      self.$j('.plainInput').forEach(function (e) { data['PLAIN'].push(self.$j(e).val()); });
    }
    if (self.$j('input[type="radio"]:checked').length > 0) {
      data['RADIO'] = [];
      self.$j('input[type="radio"]:checked').forEach(function (e) { data['RADIO'].push(self.$j(e).val()); });
    }
    if (self.$j('select.simpleSelect').length > 0) {
      data['SELECT'] = [];
      self.$j('select.simpleSelect').forEach(function (e) { data['SELECT'].push(self.$j(e).val()); });
    }
    if (self.$j('ng-select.multipleSelect').length > 0) {
      data['SELECTMULTIPLE'] = [];
      self.$j('ng-select.multipleSelect').forEach(function (e) { data['SELECTMULTIPLE'].push(self.$j(e).val()); });
    }
    if (self.$j('.divGroupCHECKBOX').length > 0) {
      data['CHECKBOX'] = [];
      self.$j('.divGroupCHECKBOX').each(function (e) {
        var values = [];
        e.find('input[type="checkbox"]:checked').each(function (ei) {
          values.push(self.$j(ei).val());
        });
        data['CHECKBOX'].push(values.join(','));
      });
    }

    return data;
  };

  clearContent = () => {
    var self = this;
    var loadImg = '<center><img src="/assets/dist/img/loading.gif"/></center>';
    //Limpando assets de visualização comuns
    self.$j('.customReportCell:not(.notClear)').forEach(function (e, indexManager) {
      var id = self.$j(e).attr('id');
      if (self.$j(e).is('canvas'))
        if (self.manager[indexManager] instanceof DataEngineManager)
          self.manager[indexManager].clean_canvas(id, loadImg);
        else self.$j(e).html('');
    });

    //Limpando assets de visualização do tipo many
    self.manager.filter((x) => !(x instanceof DataEngineManager)).forEach(function (elements, i) {
      for (var e in elements) {
        if (elements.hasOwnProperty(e)) {
          var id = e;
          if (self.$j('#' + e).is('canvas'))
            elements[e].clean_canvas(id, loadImg);
          else self.$j(e).html('');
        }
      }
    });

    //Limpando upper e bottom cells
    self.$j('.auxDivClass').forEach(function (e, indexManager) {
      self.$j(e).find('select, input').map(x => {
        self.$j(x).unbind()
      } );
      self.$j(e).html('');
    });

  };

  showContent = (isReport) => {
    var self = this;
    if (isReport) {

      var callback = null;
      if (self.config.beforeValidate)
        eval(self.config.beforeValidate);


      // Analisa validade dos filtros selecionados
      if ( !self.global.validateToServer(self.valid, self.modalAlertService, callback) )
        return false;

      self.$j('#applyFilters').hide();
      self.$j('#processing').show();

      // Analisa desempenho dos filtros selecionados
      var filters = self.getFilterData();

      if ( !self.analyseFilter(filters) )
        return false

      self.lastsent = filters;
      localStorage.setItem('lastsent', JSON.stringify(self.lastsent));

      self.$j('#favoriteView, #saveView, #auto_update, #btnPDF').show();
      if (self.$j('.datefilter').length > 0) self.$j('#navigateButtons').show();

      self.clearContent();
      self.$j('#report_wall').hide();
      self.$j('#report-content').show();
    }
    else
      self.lastsent = self.getFilterData();

    var current_guid = isReport ? self.global.createGuid() : self.static_guid;

    // monta os códigos para a criação das seções do relatório. Cada seção pode ter um gráfico, mapa ou tabela.
    for (var i = 0; i < self.cell_js.length; ++i) {

      const headerInfo = self.cellList[i]

      if (!isReport && !self.cell_js[i].semaphore) continue;
      else if (!isReport && self.cell_js[i].semaphore)
        self.cell_js[i].semaphore = false;

      var cellRef = self.$j('#customReportCell_' + self.cell_js[i].id);

      self.$j('#customReportAuxCell_' + self.cell_js[i].id).html(''); // limpa div auxiliar

      var type = self.cell_js[i].type.replace('\n', '');
      var url = '/customreport/get_report_body';
      (function (indexManager, toURL, div, bodyCellId, tp, extra) {
        var toServerCopy = self.extend( true, { id_universal: current_guid }, self.lastsent ); // clonagem do toServer
        toServerCopy.idBody = bodyCellId;

        toServerCopy.type = tp;
        if (tp == 'tb_pagination')
          toServerCopy.page = self.page;

        self.customreports.getCustomReportBody(JSON.stringify(toServerCopy)).pipe(take(1)).subscribe( (data) => {

          // customJS.hasPreShowBodyContent(data, tp, div);
          self.cell_js[indexManager].semaphore = true;
          var response = eval('(' + data + ')');
          var div_id = div.length > 0 ? div.attr('id') : '';
          var fieldset_id = '#' + div_id.replace('customReportCell_', 'fieldset_');
          var colNames = response.cols;
          var query_result = response.data;
          var reportFormat = tp;
          var interval = self.lastsent.drillDown;
          var config = response.config;
          self.nextPage = response.next;
          self.prevPage = response.prev;

          if (!isReport) {
            var f_id = fieldset_id.replace('#', '');
            config.title = {
              display: true,
              position: 'top',
              text: ( f_id != '' ? self.$j( '#' + f_id + ' .card-title_alt').text() : '' ),
            };
          }

          if (self.cellList[indexManager].func_rbd)
            config.callerAction = self.cellList[indexManager].func_rbd;

          if (config.hasEvalPreShow)
            eval(config.hasEvalPreShow);

          var sourceObj = [];
          if (reportFormat.indexOf('many') != -1) {
            self.cellList[0].func_js.hideShowDiv(query_result)

            var ids = query_result.map(function (e, i) { return e[0].split('<IC_SEP_DIM>')[0]; }).filter(function (value, index, colection) { return colection.indexOf(value) === index; });
            ids = ids.map(x => x.startsWith('graphval_') ? x : 'graphval_' + x);
            if (self.manager[indexManager] instanceof DataEngineManager)
              self.manager[indexManager] = ids.reduce(function ( dict, el, i) {
                var currDataManager = new DataEngineManager(self);
                currDataManager.colors = self.instanceConfig.graficColors;
                dict[el] = currDataManager;
                return dict;
              }, {});

            for (var i = 0; i < ids.length; ++i) {
              var filterData = query_result.filter(function (e, ix, array) { return e[0].split('<IC_SEP_DIM>')[0].replace('graphval_', '') == ids[i].replace('graphval_', ''); }).map(function (e, i) {
                if ( e[0].indexOf('<IC_SEP_DIM>') != -1 )
                  return [e[0].split('<IC_SEP_DIM>').slice(1).join('<IC_SEP_DIM>')].concat(e.slice(1));
                else
                  return e.slice(1);
              });
              sourceObj.push({ source: self.manager[indexManager][ids[i]], data: filterData, div: ids[i],});
            }
            //colNames = colNames.slice(1);
            reportFormat = reportFormat.replace('many', '');
          }
          else
            sourceObj.push({ source: self.manager[indexManager], data: query_result, colspan: response.colspan, rowspan: response.rowspan, div: div_id, });

          try {

            //Remove o separador de dimensões gerados no lado do servidor
            sourceObj.forEach( x => {
              if (x.data.forEach)
                x.data.forEach( y => {
                  if( Array.isArray(y) && y.length > 0 && y[0] != null && y[0].includes && y[0].includes('<IC_SEP_DIM>') )
                    y[0] = y[0].replace(/<IC_SEP_DIM>/g, "-");
                  else if( config.checkboxData != null && Array.isArray(y) && y.length > 1 && y[1] != null && y[1].includes && y[1].includes('<IC_SEP_DIM>') )
                    y[1] = y[1].replace(/<IC_SEP_DIM>/g, "-");
                })
              }
            );

            if (reportFormat == 'table' || reportFormat == 'tb_pagination')
              sourceObj.forEach(function (el) {
                el.source.to_table( colNames, el.data, el.div, el.rowspan, el.colspan, config, interval, true );
              });
            else if (reportFormat == 'map')
              sourceObj.forEach(function (el) {
                el.source.initializeMap(colNames, el.data, el.div, config);
              });
            else if(reportFormat == 'supervisory') {
              const engine = sourceObj[0].source
              engine.initializeSupervisory(data, headerInfo, self.grid, sourceObj[0].div, self.componentFactoryResolver, self.appRef, self.injector, self.fileAssets)
            }
            else if (reportFormat == 'correlationmatrix')
              sourceObj.forEach(function (el) {
                el.source.initializeCorrelation( colNames, el.data, el.div, config, true );
              });
            else if (reportFormat == 'tree')
              sourceObj.forEach(function (el) {
                console.log(el);
                el.source.initializeTree( colNames, el.data, el.div, config, true );
              });
            else if (reportFormat == 'storyboard')
              sourceObj.forEach(function (el) {
                el.source.initializeStoryboard( colNames, el.data, el.div, config, true );
              });
            else if (reportFormat.indexOf('text') != -1)
              sourceObj.forEach(function (el) {
                el.source.initializeText(colNames, el.data, el.div, config);
              });
            else if (reportFormat == 'nothing')
              console.log('nothing');
            else
              sourceObj.forEach(function (el) {
                el.source.initializeGraph( colNames, el.data, {}, reportFormat, el.div, config, interval, true, !isReport );
              });
          }
          catch (e) {
            if (div_id != '' && !self.$j('#' + div_id).hasClass('notClear'))
              self .$j('#' + div_id).html('<center>Falha ao exibir relatório</center>');
          }

          for (var n = 0; n < extra.length; ++n)
            self.hasCustomExtra(extra[n], null, false);

          if (config.hasEvalPostShow)
            eval( config.hasEvalPostShow.base64 ? atob(config.hasEvalPostShow.base64) : config.hasEvalPostShow);

          self.count++;

          if (self.count == self.cell_js.length) {
            self.$j('#applyFilters').show();
            const navigateButtonsPosition = document.getElementById('date-interval');
            if (navigateButtonsPosition != null) {
              let navigateButtonPositionInScreen = navigateButtonsPosition.offsetLeft + navigateButtonsPosition.offsetWidth;
              let fixedButon = document.getElementById('navigateButtons');
              self.$j('#navigateButtons').css('left', navigateButtonPositionInScreen + 'px');
            }
            self.$j('#processing').hide();
            self.count = 0;
            if (self.config && self.config.rawConfig && self.config.rawConfig.hasEvalPosGlobal)
              eval(self.config.rawConfig.hasEvalPosGlobal);

          }
        },
        (error) => {
          self.cell_js[indexManager].semaphore = true;
          if(document.getElementById('applyFilters') != null) { document.getElementById('applyFilters').style.display = ''; }
          if(document.getElementById('processing') != null) { document.getElementById('processing').style.display = 'none'; }
        },
        () => {}
        );

      })(i, url, cellRef, self.cell_js[i].id, type, self.cell_js[i].extra);
    }


    //Corrige o fuso horário dos campos de data após o envio ao servidor
    var hasPickerComp = self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA' );
    if (self.lastsent.from && self.lastsent.to && hasPickerComp) {
      self.lastsent.from = moment(hasPickerComp.selected.startDate).toDate();
      self.lastsent.to = moment(hasPickerComp.selected.endDate).toDate();
    }
  };

  analyseFilter = (filters) => {
    var self = this;
    try {
      // Verifica se os assets do relatório possuem definição do eixo X para torna-los analisáveis
      var analyzable = self.cellList.filter(x => x.xAxis != null);
      if ( analyzable.length > 0 ) {
        var hasDateInterval = self.reportFields.some( x => ['DATEBTW', 'YEARBTW'].indexOf(x.type) != -1 );
        var statistics = self.authService.getStatistics();
        var autocompletes = self.reportFields.filter( x => self.reportFilters.indexOf(x.type) != -1 && filters[x.type].length > 0 );

        // Aplica os filtros provenientes de filtros autocompletáveis ao vetor de estatísticas quando algum filtro tiver sido aplicado
        if ( autocompletes.length > 0 )
          for (var i = 0; i < autocompletes.length; ++i)
            statistics = statistics.filter( x => filters[ autocompletes[i].type ].indexOf( x[ autocompletes[i].type ] ) != -1 );

        // Verifica se o relatório possui um intervalo de tempo, se não existir usa o campo total, caso contrário calcula o total em cada objeto statistic
        var result_statistics;
        if (hasDateInterval)
          result_statistics = statistics.map(s => {
            var minFrom = moment( s.first_register ) > filters.from ? s.first_register : filters.from;
            var days = moment( filters.to ).diff( moment( minFrom ), 'days');
            return s.avg_register * days;
          });
        else
          result_statistics = statistics.map( x => x.total );

        // Define quais seriam os assets que poderia gerar problemas
        var problematics = []
        for (var i=0; i < analyzable.length; ++i) {
          var factor = -1
          if ( ['ANO', 'MES', 'DIA', 'HORA', 'MINUTO'].every( x => analyzable[i].xAxis.indexOf(x) != -1 ) )
            factor = 1;
          else if ( ['ANO', 'MES', 'DIA', 'HORA'].every( x => analyzable[i].xAxis.indexOf(x) != -1 ) )
            factor = 1;
          else if ( ['ANO', 'MES', 'DIA'].every( x => analyzable[i].xAxis.indexOf(x) != -1 ) )
            factor = 1;
          else if ( ['ANO', 'MES'].every( x => analyzable[i].xAxis.indexOf(x) != -1 ) )
            factor = 1;
          else if ( ['ANO'].every( x => analyzable[i].xAxis.indexOf(x) != -1 ) )
            factor = 1;

          if ( factor != -1 && result_statistics.reduce((ac, cv) => ac + cv, 0) > 10000 )
            problematics.append(analyzable[i])
        }

        if( problematics.length > 0) {
          var confirmed = confirm('Filtro selecionado possui ' + problematics.length + 'gráficos problemáticos, deseja prosseguir com a consulta?');
          return confirmed;
        }
        else
          return true;
      }
      else
        return true;
    } catch(err) {
      console.log('Ocorreu uma falha ao analisar o tamanho do retorno por asset');
      console.log(err);
      return true;
    }

    return true;
  }

  hasCustomExtra = function (extra, refCall, sync) {
    var callExtra = function ( id, parameters, filterParameters, successFunc, frequency, isSync ) {
      var conf = { idExtra: id, paramFilter: filterParameters, filters: parameters, };
      var errorFunc = function (error) {
        console.log('Aconteceu um erro.', error.message);
      };

      if (frequency == 'offline')
        return;
      else if (frequency == 'once')
        self.customreports.getCustomReportBodyExtra(conf).pipe(take(1)).subscribe(
          (data) => {
            successFunc(data);
          },
          (error) => {
            errorFunc(error);
          },
          () => {}
        );
      else if (frequency.indexOf('once') > -1)
        setTimeout(function () {
          self.customreports.getCustomReportBodyExtra(conf).pipe(take(1)).subscribe(
            (data) => {
              successFunc(data);
            },
            (error) => {
              errorFunc(error);
              if(document.getElementById('applyFilters') != null) { document.getElementById('applyFilters').style.display = ''; }
              if(document.getElementById('processing') != null) { document.getElementById('processing').style.display = 'none'; }
            },
            () => {}
          );

        }, parseInt(frequency.replace('once', '')));
      else
        setInterval(function () {
          self.customreports.getCustomReportBodyExtra(conf).pipe(take(1)).subscribe(
            (data) => {
              successFunc(data);
            },
            (error) => {
              errorFunc(error);
              if(document.getElementById('applyFilters') != null) { document.getElementById('applyFilters').style.display = ''; }
              if(document.getElementById('processing') != null) { document.getElementById('processing').style.display = 'none'; }
            },
            () => {}
          );
        }, parseInt(frequency.replace('multiple', '')));
    };

    var self = this;
    var conf = eval('(' + extra.comp + ')');
    var callback = conf.success ? conf.success : function () {};
    var condition = conf.condition ? conf.condition : null;
    var parameters = [], filterParameters = self.lastsent, refObj;
    var frequency = extra.freq;

    if (extra.type && extra.type != '' && extra.cdom && extra.cdom != '')
      self.$j(extra.cdom).bind(extra.type, function () {
        refObj = this;

        if (conf.data) {
          parameters = [];
          conf.data();
        }

        if (!condition || (condition && condition()))
          callExtra( extra.codi, parameters, filterParameters, callback, frequency, sync );
      });
    else
      callExtra( extra.codi, parameters, filterParameters, callback, frequency, sync );
  };

  checkBound = () => {
    //TODO: Essa atualização do valor no span deve sumir depois que relatórios e cubos estejam usando o mesmo componente
    var self = this;
    var dateField = self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA');
    if (dateField != undefined) {
      var start_date = dateField.selected.startDate;
      var end_date = dateField.selected.endDate;
      self.stepDays = end_date.diff(start_date, 'days');

      var step = end_date['_d'].addDays(self.stepDays);
      var boundLimit = moment().add(1, 'days').startOf('day');
      if (moment(step) > boundLimit) self.$j('#forward').prop('disabled', true);
      else self.$j('#forward').prop('disabled', false);
    }
  };

  initializeTimeControls = () => {
    //init_daterangepicker();
    var self = this;
    self.$j('#rewind').click(function (event) {
      event.preventDefault();
      var datebtw = self.fields.find(x => x.field.type == 'DATEBTW');

      var callback = null;
      if (self.config.beforeValidate)
        eval(self.config.beforeValidate);


      if (!self.global.validateToServer(self.valid, self.modalAlertService, callback))
        return;

      self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.startDate.subtract(self.stepDays, 'days');
      self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.endDate.subtract(self.stepDays, 'days');
      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setStartDate(datebtw.selected.startDate);
      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setEndDate(datebtw.selected.endDate);
      self.$j('.date-interval').val(self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.endDate.format('DD/MM/YYYY HH:mm') );

      self.$j('#applyFilters').trigger('click');
    });

    self.$j('#forward').click(function (event) {
      event.preventDefault();

      var callback = null;
      if (self.config.beforeValidate)
        eval(self.config.beforeValidate);

      if (!self.global.validateToServer(self.valid, self.modalAlertService, callback)) return;

      self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.startDate.add(self.stepDays, 'days');
      self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.endDate.add(self.stepDays, 'days');

      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setStartDate(self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.startDate);
      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setEndDate(self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.endDate);
      self.$j('.date-interval').val(self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + self.fields.find(x => x.field.type == 'DATEBTW' || x.field.type == 'DATA').selected.endDate.format('DD/MM/YYYY HH:mm') );

      self.$j('#applyFilters').trigger('click');
    });

    self.$j('#bnt_drillup').click(function (event) {
      event.preventDefault();
      var datebtw = self.fields.find(x => x.field.type == 'DATEBTW');

      var callback = null;
      if (self.config.beforeValidate)
        eval(self.config.beforeValidate);

      if (!self.global.validateToServer(self.valid, self.modalAlertService, callback))
        return;

      /*O drillup está ordenando por meio dos seguintes filtros:
          -Última semana
          -Últimos 30 dias
          -Últimos 6 meses
          -Últimos 12 meses */
      if (self.stepDays >= 1 && self.stepDays <= 1)
        datebtw.selected.startDate.subtract((self.stepDays = 6), 'days');
      else if (self.stepDays >= 6 && self.stepDays <= 10) {
        let selectEndDate = moment(datebtw.selected.endDate);
        self.stepDays = 30;
        datebtw.selected.startDate = selectEndDate.subtract(self.stepDays, 'days');
      }
      else if (self.stepDays >= 30 && self.stepDays <= 32) {
        let selectEndDate = moment(datebtw.selected.endDate);
        self.stepDays = 6;
        datebtw.selected.startDate = selectEndDate.subtract( self.stepDays, 'month');
      }
      else if (self.stepDays >= 180 && self.stepDays <= 200) {
        let selectEndDate = moment(datebtw.selected.endDate);
        self.stepDays = 12;
        datebtw.selected.startDate = selectEndDate.subtract(self.stepDays, 'month' );
      }

      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setStartDate(datebtw.selected.startDate);
      self.getDirectives(document.querySelector('#date-interval'))[0].picker.setEndDate(datebtw.selected.endDate);
      self.$j('.date-interval').val(datebtw.selected.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + datebtw.selected.endDate.format('DD/MM/YYYY HH:mm') );

      self.$j('#applyFilters').trigger('click');
    });
  };

  hasTitleControls = (cell, type) => {
    var invalid_types = [];

    if (type == 'image' || type == 'graph') {
      invalid_types = ['table', 'tb_pagination', 'correlationmatrix', 'map', 'text', 'nothing', 'gauge'];
      return !( invalid_types.indexOf(cell.type_rbd ) != -1 );

    }
    else if (type == 'sheet') {
      invalid_types = ['table', 'tb_pagination', 'correlationmatrix'];
      return ( invalid_types.indexOf(cell.type_rbd ) != -1 );
    }

    return true;
  }

  getGraphManager = (indexManager) => {
    return this.manager[indexManager];
  };

  factoryGraphManager = () => {
    var self = this;
    var currDataManager = new DataEngineManager(self);
    currDataManager.colors = self.instanceConfig.graficColors;
    return currDataManager;
  };

  closeGeneralModal = () => {
    var self = this;
    self.$j('#form-container').modal('hide');
  };

  get_tline = (data, callback) => {
    var self = this;
    self.customreports.getTLine(data).pipe(take(1)).subscribe(
      (data) => {
        callback(data);
      },
      (error) => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {}
    );
  };

  // function fetches the next paginated items by using the url in the next property
  fetchNext() {
    this.count--;
    this.page++;
    this.showContent(true);
  }
  // function fetches the previous paginated items by using the url in the previous property
  fetchPrevious() {
    this.count--;
    this.page--;
    this.showContent(true);
  }

  //Metodo para esconder os seletores de filtro
  //Os filtros são selecionados e o icone é localizado, caso o icone possua uma classe fa-eye o mesmo apresentará a div de seleção de filtros e mudará o icone para o fa-eye-slash
  //Já caso o icone do botão seja o fa-eye-slash o icone será modificado para o fa-eye e a div de seleção de filtros é ocultada.
  buttonHiddenFilters = () => {
    var self = this;
    let hiddenExistButton = document.querySelector('i.fa-eye');
    let isExistHiddenButton = document.body.contains(hiddenExistButton);
    let showExistButton = document.querySelector('i.fa-eye-slash');
    let isExistShowButton = document.body.contains(showExistButton);

    if (isExistHiddenButton) {
      document.getElementById('hidden-div-filters').style.display = 'block';
      hiddenExistButton.classList.remove('fa-eye');
      hiddenExistButton.classList.add('fa-eye-slash');

      const navigateButtonsPosition = document.getElementById('date-interval');
      if (navigateButtonsPosition != null) {
        let navigateButtonPositionInScreen = navigateButtonsPosition.offsetLeft + navigateButtonsPosition.offsetWidth;
        let fixedButon = document.getElementById('navigateButtons');
        self.$j('#navigateButtons').css('left', navigateButtonPositionInScreen + 'px');
      }
    }
    else if (isExistShowButton) {
      document.getElementById('hidden-div-filters').style.display = 'none';
      showExistButton.classList.remove('fa-eye-slash');
      showExistButton.classList.add('fa-eye');
    }
  };

  fullScreenButton = () => {
    var self = this;
    let showExistReportBody = document.getElementById('report_body');
    let fullScreenButton = document.getElementById('fullscreen-button');

    //Bloco de código que reage ao clique no botão de maximizar a tela cheia
    //É feita a verificação se de fato há o atributo adicionado ao deixar a tela cheia
    //Ao pressionar o botão de tela cheia, é adicionado o atributo utilizado como chave para acionar as mudanças para ocultar os menus e outros elementos da tela
    //Após a inserção do atributo, é feita a adição de uma classe (is-hidden) própria para ocultar os elementos em diversos elementos da tela
    let selectFullScreen = document.querySelector('[data-fullscreen^=fullscreen]');
    let isFullScreen = selectFullScreen == null || selectFullScreen == undefined;
    //let navbarSupportedContentChildren = document.getElementById('navbarSupportedContent').childNodes;
    if (isFullScreen) {
      // fullScreenButton.setAttribute('data-fullscreen', 'fullscreen');
      let reportBodyHeight = showExistReportBody.offsetHeight;
      //let reportBodyWidth = window.screen.width;
      showExistReportBody.classList.toggle( 'fullscreen-background-modal', isFullScreen );
      document.getElementById('navbarSupportedContent').style.marginLeft = '0px';
      Array.from( document.getElementById('navbarSupportedContent').children).forEach(function (el, index, array) { el.classList.toggle('is-hidden', isFullScreen); });
      document.querySelector('app-footer').classList.toggle('is-hidden', isFullScreen);
      document.querySelector('aside').classList.toggle('is-hidden', isFullScreen);
      document.getElementById('tabscontainer').classList.toggle('is-hidden', isFullScreen);
      document.getElementById('report_header_filters').classList.toggle('is-hidden', isFullScreen);
      document.querySelector('.page-wrapper').classList.toggle('hidden-menu-wrapper', isFullScreen);
      document.getElementById('logo-topbar-menu-white').style.display = 'flex';
      document.getElementById('close-fullscreen').style.display = 'inline-block';
      //showExistReportBody.style.height = `${reportBodyHeight}px`
      //showExistReportBody.style.width = `${reportBodyWidth}px`
    }

    //Bloco de código que reage ao clique no botão de fechar tela cheia
    //Ao pressionar o botão de fechar, é selecionado o atributo utilizado como chave para acionar as mudanças para exibir novamente os menus e outros elementos da tela
    //O atributo será removido, antes de que seja dada a continuidade na alteração de estado dos elementos em tela
    //É feita a verificação se de fato há o atributo adicionado ao deixar a tela cheia
    //Se sim, é dada a continuidade ao bloco de código que remove a classe com propriedades de display none, utilizada como base para ocultar os elementos da tela
    //Se não, não é feito nada
    self.$j('#close-fullscreen').click(function () {
      let selectFullScreen = document.querySelector( '[data-fullscreen^=fullscreen]' );
      let isNotFullScreen = selectFullScreen == null || selectFullScreen == undefined;
      let isFullScreen = selectFullScreen !== null || selectFullScreen !== undefined;
      if (isFullScreen) {
        // selectFullScreen.removeAttribute('data-fullscreen');
        //selectFullScreen.setAttribute('data-fulscreen', 'none');
        showExistReportBody.classList.toggle( 'fullscreen-background-modal', isNotFullScreen );
        document.getElementById('navbarSupportedContent').style.marginLeft = '';
        Array.from( document.getElementById('navbarSupportedContent').children).forEach(function (el, index, array) { el.classList.toggle('is-hidden', isNotFullScreen); });
        document .querySelector('app-footer').classList.toggle('is-hidden', isNotFullScreen);
        document.querySelector('aside').classList.toggle('is-hidden', isNotFullScreen);
        document.getElementById('tabscontainer').classList.toggle('is-hidden', isNotFullScreen);
        document.getElementById('report_header_filters').classList.toggle('is-hidden', isNotFullScreen);
        document.querySelector('.page-wrapper').classList.toggle('hidden-menu-wrapper', isNotFullScreen);
        document.getElementById('close-fullscreen').style.display = 'none';
        document.getElementById('logo-topbar-menu-white').style.display = '';
      }
    });
  };

  paginationTour = () => {
    var self = this;

    setTimeout(() => {
      if ( this.isPaginationTour == 'true' || this.isPaginationTour == '"cubo"' ) {
        document.getElementById('helpButton').click();
        setTimeout(() => {
          document.getElementById('help_tour_button').click();
        }, 500);
      }
    }, 100);
  };


}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsService { 
  repoUrl = environment.repoUrl;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}` 
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getCustomReportsList(): Observable<any> {
    var httpHeadersNoCache = new HttpHeaders({
      'Content-Type': 'application/json',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
      'Server-Context': this.authService.server_context, 
      'Authorization': `JWT ${this.authService.getToken()}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get(this.repoUrl + 'customreport',
      {headers: httpHeadersNoCache});
  }

  getCustomReportsDBConf(): Observable<any> {
    var httpHeadersNoCache = new HttpHeaders({
      'Content-Type': 'application/json',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
      'Server-Context': this.authService.server_context, 
      'Authorization': `JWT ${this.authService.getToken()}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get(this.repoUrl + 'customreportDBConf',
      {headers: httpHeadersNoCache});
  }

  getCustomReportHeader(id): Observable<any> {
    return this.http.get(this.repoUrl + 'get_report/' + id,
      {headers: this.httpHeaders});
  }

  getCustomReportBody(content): Observable<any> {
    const body = content;
    return this.http.post(this.repoUrl + 'get_report_body', body,
      {headers: this.httpHeaders});
  }

  getCustomReportBodyExtra(content): Observable<any> {
    const body = content;
    return this.http.post(this.repoUrl + 'get_report_body_extra', body,
      {headers: this.httpHeaders});
  }

  getTLine(content) {
    const body = content;
    return this.http.post(this.repoUrl + 'get_tline', body,
      {headers: this.httpHeaders});
  }
}

<div
  class="d-flex justify-content-center align-items-center mt-3 relative"
  #container
  style="background-color: black; overflow-x: hidden"
>
  <div class="d-flex flex-column align-items-center mt-2">
    <div class="d-flex flex-column text-center" *ngIf="title">
      <h5>{{ title }}</h5>
    </div>

    <div #rCanvas id="renderCanvas" class="mx-2 my-1" style="z-index: 1" (click)="onCanvasClick($event)"></div>

    <div class="d-flex justify-content-center mx-2">
      <div class="d-flex flex-column align-items-center mb-2">
        <i class="fa-solid fa-circle-chevron-up direction-btn" (click)="onMoveCamera('up')"></i>
        <div class="d-flex">
          <i class="fa-solid fa-circle-chevron-left direction-btn mr-2" (click)="onMoveCamera('left')"></i>
          <i class="fa-solid fa-circle-chevron-right direction-btn" (click)="onMoveCamera('right')"></i>
        </div>
        <i class="fa-solid fa-circle-chevron-down direction-btn" (click)="onMoveCamera('down')"></i>
      </div>

      <div class="d-flex align-items-center mb-2 mx-2">
        <i class="fa-solid fa-circle-plus direction-btn mr-2" (click)="onZoon('out')"></i>
        <i class="fa-solid fa-circle-minus direction-btn" (click)="onZoon('in')"></i>
      </div>
    </div>

    <div class="d-flex justify-content-center preview-levels mb-2">
      <img
        *ngFor="let item of levels; index as i"
        [src]="item.img"
        class="level-img mx-2"
        [ngClass]="{ 'level-selected': i == selected }"
        (click)="onChangeLevel(i)"
      />
    </div>

    <div class="d-flex flex-wrap justify-content-center mb-2 indicators">
      <div class="d-flex mx-2 my-2" *ngFor="let item of levels[selected]?.items">
        <i class="fa-regular fa-object-group" [ngStyle]="{ color: '#' + item?.color }"></i>
        <div class="d-flex flex-column">
          <span class="mx-2" [ngStyle]="{ color: '#' + item?.color }">{{ item.sensor }}</span>
        </div>
      </div>
    </div>
  </div>


  <div #popup class="d-flex flex-column shadow absolute bg-white px-3 py-3 popup" *ngIf="hasPopup" [ngStyle]="popupStyle">
    <span [ngStyle]="{ color: '#' + popupValue?.color }"><b>{{ popupValue?.title }}</b></span>
    <small [ngStyle]="{ color: '#' + popupValue?.color }"  *ngFor="let value of popupValue.values" class="mt-1">{{ value }}</small>
  </div>

</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../../app-routing.module';
import { CompanyRoutingModule } from './company-routing.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgxFileDropModule } from 'ngx-file-drop';
import { PersonModule } from '../person/person.module';
import { CompanyGroupComponent } from './components/company-group/company-group.component';
import { CompanyGroupCreateComponent } from './components/company-group/company-group-create/company-group-create.component';
import { CompanyGroupReadComponent } from './components/company-group/company-group-read/company-group-read.component';
import { CompanyGroupUpdateComponent } from './components/company-group/company-group-update/company-group-update.component';
import { CompanyDivisionCreateComponent } from './components/company-division/company-division-create/company-division-create.component';
import { CompanyDivisionUpdateComponent } from './components/company-division/company-division-update/company-division-update.component';
import { CompanyDivisionReadComponent } from './components/company-division/company-division-read/company-division-read.component';
import { CompanyReadComponent } from './components/company/company-read/company-read.component';
import { CompanyCreateComponent } from './components/company/company-create/company-create.component';
import { CompanyUpdateComponent } from './components/company/company-update/company-update.component';

@NgModule({
  declarations: [
    CompanyGroupComponent,
    CompanyGroupCreateComponent,
    CompanyGroupReadComponent,
    CompanyGroupUpdateComponent,
    CompanyDivisionCreateComponent,
    CompanyDivisionUpdateComponent,
    CompanyDivisionReadComponent,
    CompanyReadComponent,
    CompanyCreateComponent,
    CompanyUpdateComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    CompanyRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    AppRoutingModule,
    FormsModule,
    NgxFileDropModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    PersonModule
  ],
  exports: [
    CompanyGroupReadComponent,
    CompanyGroupCreateComponent,
    CompanyGroupUpdateComponent,
    CompanyDivisionReadComponent,
    CompanyDivisionCreateComponent,
    CompanyDivisionUpdateComponent,
    CompanyReadComponent,
    CompanyCreateComponent,
    CompanyUpdateComponent
  ]
})
export class CompanyModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadPanelService {
  repoUrl = environment.repoUrl;
  httpHeaders = new HttpHeaders({
    //'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getUploadPanel(): Observable<any> {
    return this.http.get(this.repoUrl + 'upload_panel', {headers: this.httpHeaders});
  }

  uploadFile(content): Observable<any> {
    const body = content;
    return this.http.post<any>(this.repoUrl + 'upload_file', body,
      {headers: this.httpHeaders});
  }

}

<section class="section bg-white">
  <div class="row">
    <ng-container *ngIf="!isHorizontal">
      <div class="col-12 col-md-6 col-lg-4 min-vh-100 bg-white">
        <div class="d-flex flex-column justify-content-center h-100 px-5">
          <img [src]="logo_wl" alt="logo" class="mx-auto d-block logo-login w-75" />
          <h4 class="mb-5 text-center" *ngIf="!forgotPassword">Preencha com suas credenciais para acessar o sistema</h4>
          <h4 class="mb-5 text-center" *ngIf="forgotPassword">Informe seu E-mail para recuperar o acesso</h4>
          <div *ngIf="showAlertErrorLogin">
            <app-bootstrap-modal-alert
              #alertDiv
              divType="login"
              [type]="alertType"
              [message]="alertMessage"
            ></app-bootstrap-modal-alert>
          </div>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <h6>Email</h6>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text bg-grey px-3">
                  <i class="fa-regular fa-envelope"></i>
                </span>
              </div>
              <input type="text" class="form-control px-0" autocomplete="on" formControlName="email" />
            </div>
            <div class="d-flex align-items-center text-error" *ngIf="hasInvalidEmail">
              <i class="fa-solid fa-circle-exclamation"></i>
              <small class="mx-1">Campo inválido</small>
            </div>

            <ng-container *ngIf="!forgotPassword">
              <h6 class="mt-3">Senha</h6>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-grey px-3">
                    <i class="fa-solid fa-lock" *ngIf="isPass"></i>
                    <i class="fa-solid fa-lock-open" *ngIf="!isPass"></i>
                  </span>
                </div>
                <input [type]="isPass ? 'password' : 'text'" class="form-control" formControlName="password" />
                <div class="input-group-append" (click)="isPass = !isPass">
                  <span class="input-group-text bg-grey px-3">
                    <i class="fa-solid fa-eye" *ngIf="!isPass"></i>
                    <i class="fa-solid fa-eye-slash" *ngIf="isPass"></i>
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center text-error" *ngIf="hasInvalidPass">
                <i class="fa-solid fa-circle-exclamation"></i>
                <small class="mx-1">Campo inválido</small>
              </div>

              <div class="d-flex justify-content-end">
                <span class="pointer" (click)="onForgotPassword()">Esqueci minha senha</span>
              </div>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" *ngIf="!isLoading">Entrar</button>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" disabled *ngIf="isLoading">
                Entrando...
              </button>
            </ng-container>

            <ng-container *ngIf="forgotPassword">
              <button
                type="submit"
                class="btn btn-primary btn-lg w-100 mx-0 mt-3"
                *ngIf="!isLoading"
                (click)="onChangePassword()"
              >
                Recuperar Senha
              </button>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" disabled *ngIf="isLoading">
                Enviando
              </button>

              <div class="d-flex justify-content-center align-items-center mt-3 pointer" (click)="onForgotPassword()">
                <i class="fa-solid fa-chevron-left"></i>
                <span class="mx-2">voltar</span>
              </div>
            </ng-container>
          </form>
          <div class="text-center mt-5 text-small">Copyright &copy; IndustryCare.</div>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-6 col-12 min-vh-100"
        [ngStyle]="{ 'background-image': 'url(assets/img/bg.jpg)', 'object-fit': 'cover', width: '100%' }"
        *ngIf="layout.width > 575"
      ></div>
    </ng-container>

    <ng-container *ngIf="isHorizontal">
      <div class="col-12 col-md-8 col-lg-4 offset-md-2 offset-lg-4 min-vh-100 bg-white">
        <div class="d-flex flex-column justify-content-center h-100 px-4 px-md-0">
          <img [src]="logo_wl" alt="logo" class="mx-auto d-block logo-login w-75" />
          <h4 class="mb-5 text-center" *ngIf="!forgotPassword">Preencha com suas credenciais para acessar o sistema</h4>
          <h4 class="mb-5 text-center" *ngIf="forgotPassword">Informe seu E-mail para recuperar o acesso</h4>
          <div *ngIf="showAlertErrorLogin">
            <app-bootstrap-modal-alert
              #alertDiv
              divType="login"
              [type]="alertType"
              [message]="alertMessage"
            ></app-bootstrap-modal-alert>
          </div>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <h6>Email</h6>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text bg-grey px-3">
                  <i class="fa-regular fa-envelope"></i>
                </span>
              </div>
              <input type="text" class="form-control px-0" autocomplete="on" formControlName="email" />
            </div>
            <div class="d-flex align-items-center text-error" *ngIf="hasInvalidEmail">
              <i class="fa-solid fa-circle-exclamation"></i>
              <small class="mx-1">Campo inválido</small>
            </div>

            <ng-container *ngIf="!forgotPassword">
              <h6 class="mt-3">Senha</h6>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-grey px-3">
                    <i class="fa-solid fa-lock" *ngIf="isPass"></i>
                    <i class="fa-solid fa-lock-open" *ngIf="!isPass"></i>
                  </span>
                </div>
                <input [type]="isPass ? 'password' : 'text'" class="form-control" formControlName="password" />
                <div class="input-group-append" (click)="isPass = !isPass">
                  <span class="input-group-text bg-grey px-3">
                    <i class="fa-solid fa-eye" *ngIf="!isPass"></i>
                    <i class="fa-solid fa-eye-slash" *ngIf="isPass"></i>
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center text-error" *ngIf="hasInvalidPass">
                <i class="fa-solid fa-circle-exclamation"></i>
                <small class="mx-1">Campo inválido</small>
              </div>

              <div class="d-flex justify-content-end">
                <span class="pointer" (click)="onForgotPassword()">Esqueci minha senha</span>
              </div>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" *ngIf="!isLoading">Entrar</button>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" disabled *ngIf="isLoading">
                Entrando...
              </button>
            </ng-container>

            <ng-container *ngIf="forgotPassword">
              <button
                type="submit"
                class="btn btn-primary btn-lg w-100 mx-0 mt-3"
                *ngIf="!isLoading"
                (click)="onChangePassword()"
              >
                Recuperar Senha
              </button>
              <button type="submit" class="btn btn-primary btn-lg w-100 mx-0 mt-3" disabled *ngIf="isLoading">
                Enviando
              </button>

              <div class="d-flex justify-content-center align-items-center mt-3 pointer" (click)="onForgotPassword()">
                <i class="fa-solid fa-chevron-left"></i>
                <span class="mx-2">voltar</span>
              </div>
            </ng-container>
          </form>
          <div class="text-center mt-5 text-small">Copyright &copy; IndustryCare.</div>
        </div>
      </div>
    </ng-container>
  </div>
</section>

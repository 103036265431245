<section class="section">
    <div class="section-header">
        <h1>{{ layout.aliasTitle('Alarmes')}}</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipmentnatures">Natureza da Medição</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header">
                   <!--  <h4>Adicionar Alarme</h4> -->
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label >Tipo de Cadastro </label>
                                <div class="form-group col-md-12">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="is_outlier" value = '' formControlName="is_outlier"  [(ngModel)]="is_outlier">
                                        <label class="form-check-label" for="is_outlier">
                                            Marcado-Valores (Max/Min)  / Desmarcado-Falha de recebimento de dados
                                        </label>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label >Nome</label>  
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="alert_name"
                                    [ngClass]="displayFieldCss('alert_name')">
                                <div class="invalid-feedback">
                                    Por favor informe o nome do(a) {{ layout.aliasTitle('Alarmes') }}
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                

                                <label for="IoT">IOT</label>
                                <div>
                                    <app-ic-select #selectref key='EQP' limit="1" >
                                    </app-ic-select>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="is_outlier" class="form-row" id="outlier">

                            <div class="form-group col-md-6">
                                <label >Valor Mínimo</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="value_min"
                                    [ngClass]="displayFieldCss('value_min')" (keypress)="keyPressNumbers($event)">
                            </div>
                            <div class="form-group col-md-6">
                                <label >Valor Máximo</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="value_max"
                                    [ngClass]="displayFieldCss('value_max')" (keypress)="keyPressNumbers($event)">
                            </div>

                        </div>
                        <div *ngIf="!is_outlier" class="form-row" id="outlier">

                            <div class="form-group col-md-8">
                                <label >Intervalo (segundos) para a notificação da falta de recebimento de dados</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="interval"
                                    [ngClass]="displayFieldCss('interval')" #tempoGran (keyup)="keyup(tempoGran.value)"
                                    (keypress)="keyPressNumbers($event)">
                            </div>

                            <div class="form-group col-md-4">
                                <label>Tempo (DD:HH:mm:ss)</label>
                                <label class="form-control mb-2 mr-sm-2">{{text}}</label>
                            </div>


                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label >Tipo de {{ layout.aliasTitle('Alarmes') }}</label>
                                <div class="form-group col-md-4">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="is_bot" value = '' formControlName="is_bot" >
                                        <label class="form-check-label" for="is_bot">
                                            Bot
                                        </label>
    
                                    </div>
    
                                </div>
                                <div class="form-group col-md-4">
    
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="is_mail" value = ''  formControlName="is_mail">
                                        <label class="form-check-label" for="is_mail">
                                            Mail
                                        </label>
                                    </div>
    
                                </div>
                                <div class="form-group col-md-4">
    
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="is_notify" value = '' formControlName="is_notify">
                                        <label class="form-check-label" for="is_notify">
                                            Notificação
                                        </label>
                                    </div>
    
                                </div>
                            </div>
                            
                        </div>
                        
                    </form>
                </div>
                <div class="card-footer text-right">
                    <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
                    <app-default-button [label]="'Limpar'" [type]="'secondary'" (pressed)="reset()"></app-default-button>
                    <app-default-button [label]="'Cancelar'" [type]="'secondary'" [navigateTo]="'/plant/alarm'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

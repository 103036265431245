import { Component, OnInit, ElementRef, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { EquipmentType } from './../../plant.model';
import { take } from 'rxjs/operators';
import { Global } from 'src/app/components/shared/base-func/global';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IcSelectComponent } from 'src/app/components/shared/ic-select/ic-select.component';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para atualizar um agrupador de IOT.
 */
@Component({
  selector: 'app-bundler-update',
  templateUrl: './bundler-update.component.html',
  styleUrls: ['./bundler-update.component.css']
})
export class BundlerUpdateComponent implements OnInit {

  /** Formulário para o agrupador de IOT. */
  form: FormGroup;

  /** Indica se o formulário foi enviado/submetido. */
  submitted = false;

  /** Referência para o componente de seleção de IOT. */
  @ViewChild('selectref', { static: true }) selectref;

  /** Informações do bundle selecionado. */
  selectedBundle = {};

  /** Instância da classe Global. */
  global: Global = new Global();

  /** Texto para exibição do tempo. */
  text = 'DD:HH:mm:ss';

  /** Componentes para exibição do tempo. */
  day: string;
  hour: string;
  minute: string;
  seconds: string;

  /** Variável para controlar a exibição do outlier. */
  is_outlier: boolean = true;

  types: EquipmentType[] = [];



  isLoading: boolean = false;
  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  /**
   * Método chamado ao inicializar o componente.
   * Verifica se o usuário está habilitado, se tem permissão para alterar IOT e inicializa a leitura do agrupador.
   */
  ngOnInit(): void {

    // Inicializa o formulário reativo.
    this.form = this.formBuilder.group({
      id: '',
      company: '',
      bundle_name: ['', Validators.required],
      bundle_group: [],
      plant_equipment: ['', Validators.required],
      equipment_type: ['', Validators.required],
    });

    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('change_equipment')) {
      this.router.navigate(['/403']);
    };

    // Lê as informações do bundle com base nos parâmetros da rota.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
      this.onFindTypes();
    });
  }

  onFindTypes() {
    let url_types = this.authService.endpoint_main + 'plant/equipmenttype/list/nopage';
    let company = this.authService.getPerson();

    this.plantService.getEquipmentTypeAllList(url_types,  company).pipe(take(1)).subscribe(
      data => {
        this.types = data;
      }
    )
  }

  /**
  * Realiza a leitura das informações do agrupador com base no ID.
  * @param id Identificador do agrupador.
  */
  read(id): void {
    // Chama o serviço para obter as informações do agrupador.
    this.plantService.getEquipmentBundle(id).pipe(take(1)).subscribe(
      data => {
        // Preenche os campos do formulário com os dados obtidos.
        this.form.get('id').setValue(data.id);
        this.form.get('bundle_name').setValue(data.bundle_name);
        this.form.get('bundle_group').setValue(data.bundle_group);
        this.form.get('equipment_type').setValue(data.plant_equipment_type_id.plant_equipment_type_id);

        // Divide a string de agrupamento em partes usando o ponto e vírgula como delimitador.
        this.selectedBundle = data.bundle_group.split(';');
        const equipments = data.equipments;

        if(equipments.length > 0) {
          equipments.map((el: any) => {
            this.selectref.selectedItemsCollection.push({ id: el.id, name: el.text })
            this.selectref.selectref.itemsList._selectionModel._selected.push({ label: el.text, selected: true, value: { id: el.id, name: el.text } })
          })
        }


        // Obtém a lista de itens para o componente de seleção.
        var map = {};
        this.selectref.itemListService.getItemList(this.selectref.key, null, map).pipe(take(1)).subscribe(
          data => {
            // Limpa a lista de itens.
            this.selectref.itemlist = [];

            // Preenche a lista de itens com os resultados obtidos.
            for (var r in data.results) {
              if (data.results.hasOwnProperty(r)) {
                this.selectref.itemlist.push({ id: data.results[r].id, name: data.results[r].text });
              }
            }

            // Adiciona os itens selecionados ao componente de seleção.
            // for (var item in this.selectedBundle) {
            //   this.selectref.global.addToSelect2(
            //     this.selectref.selectref,
            //     this.selectedBundle[item].split(',')[0],
            //     this.selectedBundle[item].split(',')[1]
            //   );
            //   console.log(this.selectedBundle);
            // }
            
          },
          error => {
            console.error('Erro ao obter a lista de itens:', error);
          }
        );
      },
      error => {
        console.error('Erro ao obter informações do agrupador:', error);
      }
    );
  }

  /**
   * Obtém os controles do formulário.
   * @returns Objeto com os controles do formulário.
   */
  get f() {
    return this.form.controls;
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos de um formulário reativo.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
 * Atualiza as informações do agrupador.
 */
  update(): void {
    // Marca o formulário como submetido.
    this.submitted = true;

    // Define os equipamentos selecionados no formulário.
    this.form.get('plant_equipment').setValue(this.selectref.selectedItemsCollection);

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Preenche os campos de aplicativo e empresa no formulário.
        this.form.get('company').setValue(this.authService.getPerson());

        // Chama o serviço para atualizar o agrupador de equipamentos.
        this.isLoading = true;
        this.plantService.updateEquipmentBundle(this.form.value).pipe(take(1)).subscribe(
          data => {
            // Exibe um alerta de sucesso.
            this.modalAlertService.showAlertSuccess('Registro atualizado com sucesso');
          },
          error => {
            // Exibe um alerta de erro em caso de falha ao atualizar o registro.
            this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de erro em caso de falha na autenticação.
        this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário e marca como não submetido.
   */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }
}

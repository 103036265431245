import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Global } from '../../shared/base-func/global';
import { DataService } from '../data.service';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.css']
})
export class FilterSelectComponent implements OnInit {
  @ViewChild('selectref', { static: true }) selectref;
  @Output() selectedOutput = new EventEmitter<[]>();

  global: Global = new Global()

  itemlistSource = [];
  itemlist = [];
  selectedItemsCollection = [];
  new;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.filters.subscribe(param => {
      if (param) {
        this.itemlist = [];
        this.itemlistSource = param;
        for(let i=0; i<this.itemlistSource.length; i++) {
          var filter = {name: '', id: '', enabled: true};
          filter.name = this.itemlistSource[i].label;
          filter.id = this.itemlistSource[i].id;
          filter.enabled = this.itemlistSource[i].enabled == undefined ? true : this.itemlistSource[i].enabled;
          if (filter.enabled)
            this.itemlist.push(filter);
          else
            this.selectedItemsCollection = this.selectedItemsCollection.filter((x,i) => x.id != filter.id)
        }
      }
    });
  }

  onSelectItem = (newValue) => {
    if (newValue.length > 0) {
      const id = [];
      for (const i of newValue) {
        id.push(i.id);
      }
      this.new = newValue;
      this.selectedOutput.emit(newValue);
    }
  }

  onSelectAll(select: NgSelectComponent) {
    var selected:any = this.itemlist;
    this.selectedOutput.emit(selected);
    this.selectedItemsCollection = this.itemlist;
    select.close();
  }

  onClearAll(select: NgSelectComponent) {
    this.selectedOutput.emit([]);
    this.selectedItemsCollection = null;
    select.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { EquipmentType } from './../../plant.model';
import { take } from 'rxjs/operators';
import { BaseFunc } from 'src/app/components/shared/base-func/base-func';

/**
 * Componente para a criação de IOT.
 */
@Component({
  selector: 'app-equipment-create',
  templateUrl: './equipment-create.component.html',
  styleUrls: ['./equipment-create.component.css']
})
export class EquipmentCreateComponent extends BaseFunc implements OnInit {

  /** Formulário reativo para a criação de IOT. */
  form: FormGroup;

  /** Pontos disponíveis. */
  points = '';

  /** Tipos de equipamento. */
  types: EquipmentType[];

  /** Naturezas disponíveis. */
  natures = '';

  /** Lista de IOT de planta. */
  plant_equipment = [];

  /** URL da página anterior. */
  previous: string;

  /** URL da próxima página. */
  next: string;

  /** Lista de pontos virtuais. */
  virtual = [];

  /** Flag para indicar se o formulário foi submetido. */
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router
  ) { super() }

  /**
   * Método chamado ao inicializar o componente.
   */
  ngOnInit(): void {
    // Inicializa o formulário
    this.form = this.formBuilder.group({
      company: '',
      name: ['', [Validators.required, Validators.maxLength(100)]],
      equipment_nature: ['', Validators.required],
      point: ['', Validators.required],
      id_capture: null,
      is_on: false,
      is_real: true,
      is_dealership: true,
      theorical_consumption: null,
      ip_address: ['', Validators.maxLength(12)],
      mid_value: [null, Validators.required],
      period: [null, Validators.required],
      plant_equipment: [null],
      virtual: [],
    });

    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissão
    if (this.authService.checkPermission('add_equipment') == false) {
      this.router.navigate(['/403']);
    };

    // Verifica pontos virtuais
    this.pointVirtual();

    // Obtém pontos
    let url_points = this.authService.endpoint_main + 'plant/point/list/nopage';
    let company = this.authService.getPerson();
    this.plantService.getPointAllList(url_points, company).pipe(take(1)).subscribe(
      data => {
        this.points = data;
      }, error => { }
    )

    // Obtém naturezas
    let url_natures = this.authService.endpoint_main + 'plant/equipmentnature/list/nopage';
    this.plantService.getEquipmentNatureAllList(url_natures,  company).pipe(take(1)).subscribe(
      data => {
        this.natures = data;
      }, error => { }
    )

    let url_plant_equipment = this.authService.endpoint_main + 'plant/equipment/list/nopage';
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      let company = this.authService.getPerson();
      this.plantService.getEquipmentAllList(url_plant_equipment,  company).pipe(take(1)).subscribe(
        data => {
          this.plant_equipment = data;
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => { }
      );
    }

  }

  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo for inválido e foi submetido, `false` caso contrário.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes CSS para exibição do campo.
   *
   * @param field Nome do campo.
   * @returns Objeto contendo classes CSS a serem aplicadas ao campo.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Verifica o tipo de IOT selecionado.
   *
   * @returns `true` se o tipo de IOT for válido, `false` caso contrário.
   */
  verifyType = () => {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == true && this.form.get('ip_address').value == '') {
        return false;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == true && this.form.get('ip_address').value != '') {
        return true;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == false && this.form.get('ip_address').value == '') {
        return true;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == false && this.form.get('ip_address').value != '') {
        return true;
      }
    }
  }

  /**
  * Salva os dados do formulário de criação do IOT.
  * Este método é chamado ao enviar o formulário de criação.
  */
  save = () => {
    // Define que o formulário foi enviado.
    this.submitted = true;

    // Chama o método para processar os pontos virtuais.
    this.pointVirtual();

    // Remove a propriedade 'plant_equipment' se for nula ou indefinida.
    if (this.form.value['plant_equipment'] == null || this.form.value['plant_equipment'] == undefined) {
      delete this.form.value['plant_equipment'];
    }

    // Define a propriedade 'virtual' no formulário.
    this.form.value.virtual = this.virtual;

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se há um ID de aplicativo e uma pessoa logada.
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Define a empresa no formulário.
        this.form.get('company').setValue(this.authService.getPerson());
        this.form.value.virtual = this.virtual;

        // Verifica o tipo de equipamento.
        if (this.verifyType()) {
          // Chama o serviço para criar o equipamento.
          this.isLoading = true;
          this.plantService.createEquipment(this.form.value).pipe(take(1)).subscribe(
            data => {
              // Adiciona as informações sobre os pontos virtuais ao objeto de resposta.
              data.virtual = this.virtual;

              // Verifica a existência de pontos virtuais duplicados.
              let verifyEqual = [];
              let lengthEqual = [];

              if (data['virtual'] == undefined) {
                data.virtual = this.virtual;
              }

              if (data['virtual'].length >= 1) {
                for (let i = 0; data['virtual'].length > i; i++) {
                  verifyEqual.push(data['virtual'][i]['plant_equipment_id_real']);
                }

                for (let i = 0; verifyEqual.length - 1 > i; i++) {
                  for (let j = 0; verifyEqual.length - 1 > j; j++)
                    if (verifyEqual[i] == verifyEqual[j]) {
                      lengthEqual.push(verifyEqual[j]);
                    }
                }
              }

              // Exibe um alerta de erro se houver pontos virtuais duplicados.
              if (verifyEqual.length - 1 > lengthEqual.length && lengthEqual.length > 0) {
                this.modalAlertService.showAlertDanger(`Existem pontos virtuais duplicados`);
              } else {
                // Exibe um alerta de sucesso.
                this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');

                // Navega de volta para a página anterior.
                this.location.back();
              }
            },
            error => {
              // Exibe um alerta de erro ao criar o registro.
              this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            }
          ).add(() => {
            scrollTo(0,0);
            this.isLoading = false;
          });
        } else {
          // Exibe um alerta de erro se o endereço IP for necessário para esse tipo de IoT.
          this.modalAlertService.showAlertDanger('Endereço de IP é necessário para esse tipo de IoT');
        }
      } else {
        // Exibe um alerta de erro caso não haja um ID de aplicativo ou uma pessoa logada.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário não seja válido, valida todos os campos.
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário.
   */
  reset = () => {
    this.submitted = false;
    this.form.reset();
  }

  /**
  * Lida com a lógica de pontos virtuais no formulário.
  *
  * Esta função gerencia a exibição dinâmica de pontos virtuais no formulário com base na seleção da opção "is_real".
  */
  pointVirtual = () => {

    // Elementos do DOM
    let selectIsReal = document.querySelector('[formcontrolname="is_real"]');
    let selectDivVirtualPoint = document.querySelector('.contain-virtual-point');
    let selectADDCircle = document.querySelector('.add-circle');
    let selectRemoveCircle = document.querySelector('.remove-circle');

    /**
     * Duplica o ponto virtual no formulário.
     */
    function duplicateVirtualPoint() {
      let selectDivVirtualPointTOClone = document.querySelector('.virtual-point').cloneNode(true);
      document.querySelector('.contain-virtual-point').appendChild(selectDivVirtualPointTOClone);

      // Adiciona eventos de remoção para os novos botões de remoção
      let selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      selectRemoveCircleArray[selectRemoveCircleArray.length - 1].addEventListener('click', removeVirtualPoint);

      // Adiciona eventos de duplicação para os novos botões de adição
      let selectADDCircleArray = document.querySelectorAll('.add-circle');
      selectADDCircleArray.forEach(function (el, index, array) {
        el.removeEventListener('click', duplicateVirtualPoint);
        el.addEventListener('click', duplicateVirtualPoint);
      });
    }

    /**
     * Remove o último ponto virtual do formulário.
     */
    function removeVirtualPoint() {
      let selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      if (selectRemoveCircleArray.length >= 2) {
        selectRemoveCircleArray[selectRemoveCircleArray.length - 1].parentElement.parentElement.remove();
      }
      selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      selectRemoveCircleArray.forEach(function (el, index, array) {
        el.removeEventListener('click', removeVirtualPoint);
        el.addEventListener('click', removeVirtualPoint);
      });
    }

    // Adiciona evento de alteração para a opção "is_real"
    selectIsReal.removeEventListener('change', () => { });
    selectIsReal.addEventListener('change', () => {
      if (!selectIsReal['checked']) {
        selectDivVirtualPoint.classList.remove('is-hidden');
      } else {
        selectDivVirtualPoint.classList.add('is-hidden');
      }
    });

    // Adiciona eventos de duplicação e remoção iniciais
    selectADDCircle.addEventListener('click', duplicateVirtualPoint);
    selectRemoveCircle.addEventListener('click', removeVirtualPoint);
  }

  /**
  * Obtém os pontos virtuais do formulário e atualiza a propriedade 'virtual'.
  *
  * Esta função coleta informações dos pontos virtuais no formulário e atualiza a propriedade 'virtual'
  * com os dados relevantes, como fator, operação e identificador do equipamento real associado.
  */
  getVirtualPoint = () => {

    // Seleciona elementos do DOM relacionados aos pontos virtuais
    let plant_equipment_id = document.querySelectorAll('[ng-reflect-name="plant_equipment"]');
    let factor = document.querySelectorAll('.factor');
    let operation = document.querySelectorAll('.operation');

    let virtualPointArrayData = [];

    // Verifica se há pelo menos um ponto virtual
    if (plant_equipment_id.length >= 1) {
      for (let i = 0; plant_equipment_id.length > i; i++) {
        // Coleta informações do ponto virtual
        let x = {
          factor: factor[i]['value'],
          operation: operation[i]['value'],
          plant_equipment_id_real: plant_equipment_id[i]['value']
        };

        // Adiciona o ponto virtual ao array
        virtualPointArrayData.push(x);
      }

      // Atualiza a propriedade 'virtual' com os pontos virtuais coletados
      this.virtual = virtualPointArrayData;
    } else {
      // Se não houver pontos virtuais, atribui um array vazio à propriedade 'virtual'.
      this.virtual = [];
    }
  }

  /**
   * Transforma o valor do campo para número.
   * @param field O nome do campo que será transformado para número.
   */
  transformToNumber(field: string) {
    // Obtém o valor atual do campo no formulário.
    let currentValue = this.form.get(field).value;

    // Remove caracteres não numéricos do valor.
    currentValue = currentValue.replace(/[^0-9.-]/g, '');

    // Atualiza o valor no formulário sem acionar um evento.
    this.form.get(field).setValue(currentValue, { emitEvent: false });
  }
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PersonService } from './../person.service';
import { Location } from '@angular/common';
import { PersonPerfil } from './../../persons-perfil/person-perfil.model';
import { Person } from '../person.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular responsável pela criação de uma pessoa fisica/juridica.
 */
@Component({
  selector: 'app-person-create',
  templateUrl: './person-create.component.html',
  styleUrls: ['./person-create.component.css']
})
export class PersonCreateComponent implements OnInit {

  form: FormGroup;

  @ViewChildren('tbodyContact') tbodyContact: QueryList<ElementRef>;
  @ViewChildren('tbodyFile') tbodyFile: QueryList<ElementRef>;

  @Output() created: EventEmitter<boolean> = new EventEmitter();
  @Output() clear: EventEmitter<boolean> = new EventEmitter();
  @Output() back: EventEmitter<boolean> = new EventEmitter();
  @Output() checkFields: EventEmitter<boolean> = new EventEmitter();
  @Input() parentFormStatus: boolean = false;

  select_form_cnpj = "";
  select_form_cpf = "show active";
  contact_remove = "";
  has_error = false;
  backend_validation_message = [];

  person = "";
  ufs = "";
  cities = "";

  personProfiles: PersonPerfil[];
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {

    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão
    if (this.authService.checkPermission('add_person') == false) {
      this.router.navigate(['/403']);
    };


    // Obtém todas as UFs para endereço
    this.personService.getUfs(this.authService.endpoint_main + 'uf').pipe(take(1)).subscribe(
      data => {
        this.ufs = data
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de UFs');
      }
    )

    // Inicializa o formulário com os campos e validadores necessários.
    this.form = this.formBuilder.group({
      trade_name: [''],
      company_name: [''],
      cnpj: ['', ],
      cpf: ['', ],
      is_physical: [true],
      first_name: [''],
      last_name: [''],
      icms_free: [false],
      person_address: this.formBuilder.group({
        street: ['', Validators.required],
        number: ['', Validators.maxLength(9)],
        complement: ['', Validators.maxLength(40)],
        sector: ['', Validators.maxLength(40)],
        zipcode: ['', Validators.required],
        city: ['', Validators.required],
        uf: ['', Validators.required],
        email: ['', [Validators.email]],
        phone: ['', [Validators.required]],
        site: ['']
      })
    })
  }

  /**
   * Manipula o envio do formulário.
   * @param value - Valores do formulário.
   * @param valid - Indica se o formulário é válido.
   */
  onSubmit({value, valid}: { value: Person, valid: boolean }) {
    console.log(value, valid)
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field - Nome do campo a ser verificado.
   * @returns True se o campo for inválido e o formulário tiver sido submetido, false caso contrário.
   */
  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  /**
   * Retorna as classes CSS necessárias para a exibição de erros no campo.
   * @param field - Nome do campo a ser verificado.
   * @returns Objeto contendo a classe 'is-invalid' se o campo for inválido, vazio caso contrário.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Manipula a seleção de uma UF, obtendo as cidades correspondentes.
   */
  onUfSelected() {
    let value = this.form.get('person_address.uf').value;
    this.personService.getCities(this.authService.endpoint_main + 'city/' + value).pipe(take(1)).subscribe(
      data => {
        this.cities = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de cidades');
      }
    )
  }

  /**
   * Obtém os controles do formulário para facilitar o acesso.
   */
  get f() { return this.form.controls; }

  /**
   * Manipula o foco nos campos relacionados a pessoa (CPF ou CNPJ).
   * @param value - Tipo de pessoa ('cpf' ou 'cnpj').
   */
  focusPerson(value: string) {
    if (value == 'cpf') {
      this.select_form_cnpj = "";
      this.select_form_cpf = "show active";
      this.form.get('trade_name').setValue("");
      this.form.get('company_name').setValue("");
      this.form.get('cnpj').setValue("");
      this.form.get('person_address.site').setValue("");
      this.form.get('is_physical').setValue(true);
    } else {
      this.select_form_cnpj = "show active";
      this.select_form_cpf = "";
      this.form.get('icms_free').setValue(false);
      this.form.get('first_name').setValue("");
      this.form.get('last_name').setValue("");
      this.form.get('cpf').setValue("");
      this.form.get('is_physical').setValue(false);
    }
  }

  /**
   * Adiciona uma linha de contato ao HTML.
   */
  addContactToHtml() {
    let html = '<tr><td><div class="form-check form-check-inline"><input class="form-check-input"  type="checkbox" name="remove"></div></td> <td><input type="text"  class="form-control mb-2 mr-sm-2"  name = "contact_name" > </td><td><input type="email" class="form-control mb-2 mr-sm-2" name="contact_email"></td><td><input mask="(00) 00000-0000" type="text" class="form-control mb-2 mr-sm-2"  name="contact_phone"></td> <td><input type="text" class="form-control mb-2 mr-sm-2"  name="contact_office"></td></tr>';
    this.tbodyContact.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Adiciona uma linha de arquivo ao HTML.
   */
  addFileToHtml() {
    let html = '<tr><td><div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" name="remove"></div></td><td><input type="text" class="form-control mb-2 mr-sm-2"  name="file_name"> </td><td><input type="file" class="form-control mb-2 mr-sm-2"  name="file_file"></td></tr>';
    this.tbodyFile.first.nativeElement.insertAdjacentHTML('beforeend', html);
  }

  /**
   * Remove linhas de contato selecionadas.
   */
  deleteContactTr() {
    let rows = document.getElementById('contact-tbody');
    let selected = rows.querySelectorAll('tr td .form-check-inline input:checked');
    let selected_length = rows.querySelectorAll('tr td .form-check-inline input:checked').length;

    for (let i = 0; i < selected_length; i++) {
      selected[i].parentNode.parentNode.parentNode.parentNode.removeChild(selected[i].parentNode.parentNode.parentNode);
    }
  }

  /**
   * Remove linhas de arquivo selecionadas.
   */
  deleteFileTr() {
    let rows = document.getElementById('file-tbody');
    let selected = rows.querySelectorAll('tr td .form-check-inline input:checked');
    let selected_length = rows.querySelectorAll('tr td .form-check-inline input:checked').length;

    for (let i = 0; i < selected_length; i++) {
      selected[i].parentNode.parentNode.parentNode.parentNode.removeChild(selected[i].parentNode.parentNode.parentNode);
    }
  }

  /**
   * Valida todos os campos do formulário.
   * @param formGroup - Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Salva o formulário após a submissão.
   */
  save = () => {
    this.submitted = true;

    if(!this.parentFormStatus) {
      this.created.emit(true)
      return
    }

    // Verifica se o formulário é válido
    if (this.form.valid && this.parentFormStatus) {

      if (this.authService.hasToken()) {

        // Cria um objeto FormData para lidar com o envio de arquivos
        const formData = new FormData();
        let has_file = false;
        let person_file = [];
        let person_contact = [];

        let is_physical = this.form.get('is_physical').value;
        let cpf = this.form.get('cpf').value;

        // Obtém os elementos de contato do HTML
        let contacts_name = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_name"]');
        let contacts_email = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_email"]');
        let contacts_phone = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_phone"]');
        let contacts_office = this.tbodyContact.first.nativeElement.querySelectorAll('input[name="contact_office"]');
        let contacts_length = contacts_name.length;

        // Prepara o objeto de contatos da pessoa
        for (var i = 0; i < contacts_length; i++) {
          let rowContact = {
            "name": contacts_name[i].value,
            "email": contacts_email[i].value,
            "phone": contacts_phone[i].value,
            "office": contacts_office[i].value
          }
          if (rowContact.phone != '' || rowContact.email != '') {
            person_contact.push(rowContact);
          }
        }

        // Obtém os elementos de arquivo do HTML
        let person_file_names = this.tbodyFile.first.nativeElement.querySelectorAll('input[name="file_name"]');
        let person_file_files = this.tbodyFile.first.nativeElement.querySelectorAll('input[name="file_file"]');
        let files_length = person_file_names.length;

        // Adiciona os arquivos ao objeto FormData
        for (let i = 0; i < files_length; i++) {
          if (person_file_files[i].files[0]) {
            formData.append(person_file_names[i].value, person_file_files[i].files[0]);
            has_file = true;
          }
        }

        // Adiciona as chaves ao objeto de pessoa
        this.person = this.form.value;
        this.person["person_contact"] = person_contact;


        // Chama o serviço para criar uma pessoa
        this.isLoading = true;
        this.personService.createPerson(this.person).pipe(take(1)).subscribe(
          data => {
            this.created.emit(data)
          
            this.location.back();
            this.has_error = false;
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
          }, error => {

            this.modalAlertService.showAlertDanger('Falha ao realizar operação.');

            // Lida com os erros de validação do backend
            this.backend_validation_message = [];
            if ('person_address' in error.error) {
              if ('site' in error.error['person_address']) {
                this.backend_validation_message.push(error.error['person_address']['site'][0]);
              }
            }
            if ('person_contact' in error.error) {
              if ('email' in error.error['person_contact']) {
                this.backend_validation_message.push(error.error['person_contact']['email'][0]);
              }
            }
            if ('cnpj' in error.error) {
              this.backend_validation_message.push(error.error['cnpj'][0]);
            }
            if ('cpf' in error.error) {
              this.backend_validation_message.push(error.error['cpf'][0]);
            }
            this.has_error = true;
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })
      } else {
        // Trata o caso em que o aplicativo não tem um ID
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Se o formulário for inválido, valida todos os campos
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário.
   */
  reset() {
    this.submitted = false;
    this.form.reset();
  }
}


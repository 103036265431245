export class CubeCluster {

    static createCluster = (e) => {
        var t = function ()
		{
			for (var e = 3, t = document.createElement("b"), l = t['all'] || []; t.innerHTML = "<!--[if gt IE " + ++e + "]><i><![endif]-->", l[0];);
			return e > 4 ? e : document['documentMode']
		}(),
		l = navigator.platform.toLowerCase().indexOf("mac") + 1,
		
		n = function (e) : void
		{
			if (!(this instanceof n)) return new n(e);
			var t = this,
				i = {
					rows_in_block: 50,
					blocks_in_cluster: 4,
					tag: null,
					show_no_data_row: !1,
					no_data_class: "dc-without-data",
					no_data_text: "Sem dados",
					keep_parity: !1,
					callbacks:
					{}
				};
			t.options = {};
			for (var r, c = ["rows_in_block", "blocks_in_cluster", "show_no_data_row", "no_data_class", "no_data_text", "keep_parity", "tag", "callbacks"], f = 0; r = c[f]; f++) t.options[r] = void 0 !== e[r] && null != e[r] ? e[r] : i[r];
			var d, u = ["scroll", "content"];
			for (f = 0; d = u[f]; f++)
				if (t[d + "_elem"] = e[d + "Id"] ? document.getElementById(e[d + "Id"]) : e[d + "Elem"], !t[d + "_elem"]) throw new Error("Error! Could not find " + d + " element");
			t.content_elem.hasAttribute("tabindex") || t.content_elem.setAttribute("tabindex", 0);
			var h = a(e.rows) ? e.rows : t.fetchMarkup(),
				p = {},
				g = t.scroll_elem.scrollTop;
			t.insertToDOM(h, p), t.scroll_elem.scrollTop = g;
			var m:any = !1,
				v:any = 0,
				b:any = !1,
				C = function ()
				{
					l && (b || (t.content_elem.style.pointerEvents = "none"), b = !0, clearTimeout(v), v = setTimeout(function ()
					{
						t.content_elem.style.pointerEvents = "auto", b = !1
					}, 50)), m != (m = t.getClusterNum()) && t.insertToDOM(h, p), t.options.callbacks.scrollingProgress && t.options.callbacks.scrollingProgress(t.getScrollProgress())
				},
				w:any = 0,
				y = function ()
				{
					clearTimeout(w), w = setTimeout(t.refresh, 100)
				};
			o("scroll", t.scroll_elem, C), o("resize", window, y), t.destroy = function (e)
			{
				s("scroll", t.scroll_elem, C), s("resize", window, y), t.html((e ? t.generateEmptyRow() : h).join(""))
			}, t.refresh = function (e)
			{
				(t.getRowsHeight(h) || e) && t.update(h)
			}, t.update = function (e)
			{
				h = a(e) ? e : [];
				var l = t.scroll_elem.scrollTop;
				h.length * t.options.item_height < l && (t.scroll_elem.scrollTop = 0, m = 0), t.insertToDOM(h, p), t.scroll_elem.scrollTop = l
			}, t.clear = function ()
			{
				t.update([])
			}, t.getRowsAmount = function ()
			{
				return h.length
			}, t.getScrollProgress = function ()
			{
				return this.options.scroll_top / (h.length * this.options.item_height) * 100 || 0
			};
			var D = function (e, l)
			{
				var n = a(l) ? l : [];
				n.length && (h = "append" == e ? h.concat(n) : n.concat(h), t.insertToDOM(h, p))
			};
			t.append = function (e)
			{
				D("append", e)
			}, t.prepend = function (e)
			{
				D("prepend", e)
			}
		};

		function o(e, t, l)
		{
			return t.addEventListener ? t.addEventListener(e, l, !1) : t.attachEvent("on" + e, l)
		}

		function s(e, t, l)
		{
			return t.removeEventListener ? t.removeEventListener(e, l, !1) : t.detachEvent("on" + e, l)
		}

		function a(e)
		{
			return "[object Array]" === Object.prototype.toString.call(e)
		}

		function i(e, t)
		{
			return window.getComputedStyle ? window.getComputedStyle(t)[e] : t.currentStyle[e]
		}
		return n.prototype = {
			constructor: n,
			fetchMarkup: function ()
			{
				for (var e = [], t = this.getChildNodes(this.content_elem); t.length;) e.push(t.shift().outerHTML);
				return e
			},
			exploreEnvironment: function (e, l)
			{
				var n = this.options;
				n.content_tag = this.content_elem.tagName.toLowerCase(), e.length && (t && t <= 9 && !n.tag && (n.tag = e[0].match(/<([^>\s/]*)/)[1].toLowerCase()), this.content_elem.children.length <= 1 && (l.data = this.html(e[0] + e[0] + e[0])), n.tag || (n.tag = this.content_elem.children[0].tagName.toLowerCase()), this.getRowsHeight(e))
			},
			getRowsHeight: function (e)
			{
				var t = this.options,
					l = t.item_height;
				if (t.cluster_height = 0, e.length)
				{
					var n = this.content_elem.children,
						o = n[Math.floor(n.length / 2)];
					if (t.item_height = o.offsetHeight, "tr" == t.tag && "collapse" != i("borderCollapse", this.content_elem) && (t.item_height += parseInt(i("borderSpacing", this.content_elem), 10) || 0), "tr" != t.tag)
					{
						var s = parseInt(i("marginTop", o), 10) || 0,
							a = parseInt(i("marginBottom", o), 10) || 0;
						t.item_height += Math.max(s, a)
					}
					return t.block_height = t.item_height * t.rows_in_block, t.rows_in_cluster = t.blocks_in_cluster * t.rows_in_block, t.cluster_height = t.blocks_in_cluster * t.block_height, l != t.item_height
				}
			},
			getClusterNum: function ()
			{
				return this.options.scroll_top = this.scroll_elem.scrollTop, Math.floor(this.options.scroll_top / (this.options.cluster_height - this.options.block_height)) || 0
			},
			generateEmptyRow: function ()
			{
				var e = this.options;
				if (!e.tag || !e.show_no_data_row) return [];
				var t, l = document.createElement(e.tag),
					n = document.createTextNode(e.no_data_text);
				return l.className = e.no_data_class, "tr" == e.tag && ((t = document.createElement("td")).colSpan = 100, t.appendChild(n)), l.appendChild(t || n), [l.outerHTML]
			},
			generate: function (e, t)
			{
				var l = this.options,
					n = e.length;
				if (n < l.rows_in_block) return {
					top_offset: 0,
					bottom_offset: 0,
					rows_above: 0,
					rows: n ? e : this.generateEmptyRow()
				};
				var o = Math.max((l.rows_in_cluster - l.rows_in_block) * t, 0),
					s = o + l.rows_in_cluster,
					a = Math.max(o * l.item_height, 0),
					i = Math.max((n - s) * l.item_height, 0),
					r = [],
					c = o;
				a < 1 && c++;
				for (var f = o; f < s; f++) e[f] && r.push(e[f]);
				return {
					top_offset: a,
					bottom_offset: i,
					rows_above: c,
					rows: r
				}
			},
			renderExtraTag: function (e, t)
			{
				var l = document.createElement(this.options.tag);
				return l.className = ["clusterize-extra-row", "clusterize-" + e].join(" "), t && (l.style.height = t + "px"), l.outerHTML
			},
			insertToDOM: function (e, t)
			{
				this.options.cluster_height || this.exploreEnvironment(e, t);
				var l = this.generate(e, this.getClusterNum()),
					n = l.rows.join(""),
					o = this.checkChanges("data", n, t),
					s = this.checkChanges("top", l.top_offset, t),
					a = this.checkChanges("bottom", l.bottom_offset, t),
					i = this.options.callbacks,
					r = [];
				o || s ? (l.top_offset && (this.options.keep_parity && r.push(this.renderExtraTag("keep-parity")), r.push(this.renderExtraTag("top-space", l.top_offset))), r.push(n), l.bottom_offset && r.push(this.renderExtraTag("bottom-space", l.bottom_offset)), i.clusterWillChange && i.clusterWillChange(), this.html(r.join("")), "ol" == this.options.content_tag && this.content_elem.setAttribute("start", l.rows_above), this.content_elem.style["counter-increment"] = "clusterize-counter " + (l.rows_above - 1), i.clusterChanged && i.clusterChanged()) : a && (this.content_elem.lastChild.style.height = l.bottom_offset + "px")
			},
			html: function (e)
			{
				var l = this.content_elem;
				if (t && t <= 9 && "tr" == this.options.tag)
				{
					var n, o = document.createElement("div");
					for (o.innerHTML = "<table><tbody>" + e + "</tbody></table>"; n = l.lastChild;) l.removeChild(n);
					for (var s = this.getChildNodes(o.firstChild.firstChild); s.length;) l.appendChild(s.shift())
				}
				else l.innerHTML = e
			},
			getChildNodes: function (e)
			{
				for (var t = e.children, l = [], n = 0, o = t.length; n < o; n++) l.push(t[n]);
				return l
			},
			checkChanges: function (e, t, l)
			{
				var n = t != l[e];
				return l[e] = t, n
			}
		}, n(e)

    }
}

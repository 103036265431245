<section class="section">
  <div class="section-header user-header_update" [ngClass]="{ 'bg-white mt-3': isHorizontal }">
    <h1>Gestão de Usuários</h1>
    <div class="section-header-breadcrumb user-header-breadcrumb_update">
      <div class="breadcrumb-item "><a routerLink="">Dashboard</a></div>
      <div class="breadcrumb-item"><a routerLink="/users">Gestão de Usuários</a></div>
      <div class="breadcrumb-item active">Editar Usuário</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="header">
            <h4>Editar Cadastro de Usuário</h4>
          </div>
          <form method="POST" [formGroup]="userForm" class="forms">
            <div class="form-row justify-content-around">
              <div class="form-group col-md-5">
                <label>Nome</label>
                <input type="text" name="first_name" class="form-control" formControlName="first_name"
                  placeholder="Nome*" [ngClass]="displayFieldCss('first_name')">
                <div class="invalid-feedback">
                  Por favor informe o nome.
                </div>
              </div>
              <div class="form-group col-md-5">
                <label>Sobrenome</label>
                <input type="text" name="last_name" class="form-control" formControlName="last_name"
                  placeholder="Sobrenome*" [ngClass]="displayFieldCss('last_name')">
                <div class="invalid-feedback">
                  Por favor informe o sobrenome.
                </div>
              </div>
            </div>
            <div class="form-row justify-content-around">
              <div class="form-group col-md-5">
                <label>Email</label>
                <input type="email" name="email" class="form-control" formControlName="email"
                  placeholder="Email*" [ngClass]="displayFieldCss('email')">
                <div class="invalid-feedback">
                  Por favor informe o email.
                </div>
              </div>
              <div class="form-group col-md-5">
                <label>Celular</label>
                <input type="text" name="phone_number" class="form-control" formControlName="phone_number"
                  mask="(00) 0 0000-0000" placeholder="Telefone">
              </div>
            </div>
            <div class="form-row justify-content-around">
              <div class="form-group col-md-5">
                <label>Perfil</label>
                <ng-select  class="form-control-select" placeholder="Selecione um ou mais perfis*" [multiple]="true" formControlName="groups">
                  <ng-option *ngFor="let item of allGroups" [value]="item.id">{{ item.name }}</ng-option>
                </ng-select>
                <div class="message-feedback" [ngClass]="{ 'invalid-option': isFieldValid('groups') }">
                  Por favor selecione um ou mais perfis.
                </div>
              </div>
              <div class="form-group col-md-5"></div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="buttons text-right">
            <app-default-button [isLoading]="isLoading" (pressed)="updateUser()"></app-default-button>
            <button class="btn btn-secondary cancel-button" (click)="cancel()">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

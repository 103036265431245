import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { last, take } from 'rxjs/operators';
import { Global } from 'src/app/components/shared/base-func/global';
import { ReportsService } from 'src/app/components/reports/reports.service';
import { StoreInstanceConfService } from 'src/app/components/instance-configuration/stores/store-instance-conf.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular responsável pela criação de novos setores.
 */
@Component({
  selector: 'app-sector-create',
  templateUrl: './sector-create.component.html',
  styleUrls: ['./sector-create.component.css']
})
export class SectorCreateComponent implements OnInit {

  /**
   * Representa o formulário utilizado para coletar dados do novo setor.
   */
  form: FormGroup;

  /**
   * Armazena informações sobre os setores pai, utilizadas em seleção durante a criação de um novo setor.
   */
  parents = "";

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  isLoading: boolean = false;

  customFields: any[] = []

  defaultKey: string = 'SET'
  defaultSupKey: string = 'SET'
  hiddenFather: boolean = false;

  blockSubmition: boolean = false;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private storeInstance: StoreInstanceConfService,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {
      if(data.key) {
        this.defaultKey = data.key
        this.defaultSupKey = data.supkey

        const lastItem = this.storeInstance.sectorTabs.filter(el => el.last)
        
        if(lastItem[0]?.key == this.defaultKey) {
          this.hiddenFather = true;
          return 
        }

        this.authService.onFindSectors(this.defaultSupKey).subscribe((data) => {
          this.parents = data.results
        })
      }
    })
    
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();
    this.onFindCustomField();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('add_sector')) {
      this.router.navigate(['/403']);
    };

    let company = this.authService.getPerson();

    // // Obtém a lista de setores pai.
    // this.plantService.getSectorAllList(this.authService.endpoint_main + 'plant/sector/list/nopage', company).pipe(take(1)).subscribe(
    //   data => {
    //     this.parents = data;
    //   }, error => {
    //     // Lida com erros na obtenção da lista de setores pai.
    //     console.error(error);
    //   }
    // );

    // Inicializa o formulário reativo.
    this.form = this.formBuilder.group({
      company: [''],
      parent: null,
      name: ['', Validators.required],
      latitud: ['', [Validators.maxLength(12), Validators.pattern(/^[-]?\d+(\.\d+)?$/)]],
      longitud: ['', [Validators.maxLength(12), Validators.pattern(/^[-]?\d+(\.\d+)?$/)]],
    });
  }

  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns Verdadeiro se o campo é inválido.
   */
  isFieldValid(field: string): boolean {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna a classe CSS apropriada para o campo do formulário.
   *
   * @param field - Nome do campo.
   * @returns Objeto CSS.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  onFindCustomField() {
    this.plantService.onFindCustomFields(this.defaultKey).subscribe((data) => {
      this.customFields = data;
    })
  }

  /**
   * Getter conveniente para fácil acesso aos campos do formulário.
   *
   * @returns Controles do formulário.
   */
  get f() { return this.form.controls; }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup - Grupo de formulários a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onVerifyRequiredFields(result: any) {
    const requiredFields = this.customFields.filter(el => el.obg);

    requiredFields.map((el) => {
      const { dom_id } = el;

      if(!result[dom_id]) {
        document.getElementById(dom_id).classList.add('is-invalid');
        return
      }

      if(result[dom_id]) {
        document.getElementById(dom_id).classList.remove('is-invalid');
        return
      }
    })

    const hasInvalidRequiredFields = Array.from(document.getElementsByClassName('is-invalid'));
    if(hasInvalidRequiredFields.length > 0) {
      this.blockSubmition = true;
      this.modalAlertService.showAlertDanger('O formulário possui campos inválidos')
    } else {
      this.blockSubmition = false;
    }
  }

  /**
   * Método chamado ao salvar os dados do setor.
   * Realiza validações, define valores necessários e chama o serviço para criar o setor.
   * Exibe alertas modais conforme necessário.
   */
  save = () => {
    this.submitted = true;

    const global = new Global()
    const result = global.saveData()

    this.onVerifyRequiredFields(result)

    if (this.form.valid && !this.blockSubmition) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {

        this.form.get('company').setValue(this.authService.getPerson());
        const payload = {
          ...this.form.value,
          ...result,
          key: this.defaultKey
        }


        this.isLoading = true;
        this.plantService.createSector(payload).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })

      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário, revertendo alterações e indicando que o formulário não foi submetido.
   */
  reset = () => {
    this.submitted = false;
    this.form.reset();
  }

  /**
   * Formata o valor da coordenada no campo de formulário especificado.
   *
   * @param field - O nome do campo de formulário que contém o valor da coordenada.
   */
  formatCoordinate(field: string) {
    // Obtém o valor atual do campo de formulário especificado
    let currentValue = this.form.get(field).value;

    // Remove caracteres não numéricos do valor
    currentValue = currentValue.replace(/[^0-9.-]/g, '');

    // Atualiza o valor no formulário sem acionar um evento
    this.form.get(field).setValue(currentValue, { emitEvent: false });
  }

}

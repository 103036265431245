import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BootstrapModalAlertService } from 'src/app/components/shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from '../../plant.service';
import { Location } from '@angular/common';
import { EquipmentType } from '../../plant.model';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para criar um novo agrupamento de IOT.
 */
@Component({
  selector: 'app-bundler-create',
  templateUrl: './bundler-create.component.html',
  styleUrls: ['./bundler-create.component.css']
})
export class BundlerCreateComponent implements OnInit {

  /**
   * Formulário para o agrupamento.
   */
  form: FormGroup;

  /**
   * Referência para o componente de seleção.
   */
  @ViewChild('selectref', { static: false }) selectref;

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;
  types: EquipmentType[];


  /**
   * Indica se existe uma opção selecionada.
   */
  optionSelected = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método chamado ao inicializar o componente.
   * Verifica se o usuário está habilitado e se tem permissão para adicionar.
   * Inicializa o formulário.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('add_equipment')) {
      this.router.navigate(['/403']);
    };

    // Inicializa o formulário reativo.
    this.initializeForm();
    this.onFindTypes();
  }

  onFindTypes() {
    let url_types = this.authService.endpoint_main + 'plant/equipmenttype/list/nopage';
    let company = this.authService.getPerson();

    this.plantService.getEquipmentTypeAllList(url_types,  company).pipe(take(1)).subscribe(
      data => {
        this.types = data;
      }
    )
  }

  /**
   * Inicializa o formulário reativo.
   */
  private initializeForm(): void {
    this.form = this.formBuilder.group({
      company: '',
      bundle_name: ['', Validators.required],
      plant_equipment: ['', Validators.required],
      equipment_type: ['', Validators.required],
    });
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.form.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos de um formulário reativo.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
 * Verifica se a opção selecionada é válida.
 * @returns `true` se nenhuma opção foi selecionada e o formulário foi submetido, caso contrário `false`.
 */
  isOptionValid(): boolean {
    this.optionSelected = this.selectref?.selectedItemsCollection?.length < 1 ? true : false;
    return this.optionSelected && this.submitted;
  }

  /**
   * Salva os dados do formulário ao criar um novo agrupamento.
   */
  save = (): void => {
    // Marca o formulário como submetido.
    this.submitted = true;

    // Define os equipamentos selecionados no formulário.
    this.form.get('plant_equipment')?.setValue(this.selectref?.selectedItemsCollection);

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe token e se há uma pessoa autenticada.
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Preenche oe empresa no formulário.
        this.form.get('company').setValue(this.authService.getPerson());

        // Chama o serviço para criar um novo agrupamento de equipamentos.
        this.isLoading = true;
        this.plantService.createEquipmentBundle(this.form.value).pipe(take(1)).subscribe(
          data => {
            // Exibe um alerta de sucesso e volta à página anterior.
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          },
          error => {
            // Exibe um alerta de erro em caso de falha ao incluir o registro.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });

      } else {
        // Exibe um alerta de erro em caso de falha na autenticação.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário e marca como não submetido.
   */
  reset = (): void => {
    this.submitted = false;
    this.optionSelected = false;
    this.selectref.selectedItemsCollection = [];
    this.form.reset();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-customize',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.css'],
})
export class UserContainerComponent implements OnInit {
  selectedIndex: number = 0;

  items: any[] = [
    {
      id: 0,
      label: 'Perfil',
      permission: 'view_group',
      available: false,
    },
    {
      id: 1,
      label: 'Usuário',
      permission: 'view_user',
      available: false,
    },
  ];

  get isHorizontal() {
    return window['whiteLabelConf']('horizontal');
  }

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.onVerifyPermissions();
    this.onSetFirstAvailableTab();
  }

  onVerifyPermissions() {
    const permissions = JSON.parse(this.authService.getPermissions()) as any[];
    this.items.map((rp) =>
      permissions.some((pe) => {
        if (rp.permission == pe.codename) {
          rp.available = true;
        }
      }),
    );
  }

  onSetFirstAvailableTab() {
    const availableItems = this.items.filter((item) => item.available == true);

    if (availableItems.length == 0) {
      this.router.navigate(['/403']);
    }

    if (availableItems.length > 0) {
      this.setTabIndex(availableItems[0].id);
    }
  }

  setTabIndex(index: number) {
    this.selectedIndex = index;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para visualização de tipos de IOT.
 */
@Component({
  selector: 'app-type-read',
  templateUrl: './type-read.component.html',
  styleUrls: ['./type-read.component.css']
})
export class TypeReadComponent implements OnInit {

  /**
   * Lista de tipos de IOT.
   */
  equipmentTypes = [];
  equipmentTypesFiltered: any[] = [];

  /**
   * Próxima página da lista paginada.
   */
  next: string;

  /**
   * Página anterior da lista paginada.
   */
  previous: string;

  /**
   * Parâmetros para a solicitação HTTP.
   */
  params = new HttpParams();

  /**
   * Referência para o modal de exclusão.
   */
  deleteModalRef: BsModalRef;

  /**
   * Referência para o modal de exclusão.
   */
  @ViewChild('deleteModal') deleteModal;

  /**
   * Item selecionado para exclusão.
   */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método chamado ao inicializar o componente.
   * Verifica se o usuário está habilitado e se tem permissão para visualizar tipos de IOT.
   * Inicializa a leitura da lista de tipos.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('view_equipmenttype')) {
      this.router.navigate(['/403']);
    }

    // Inicia a leitura da lista de tipos.
    this.readAll(this.authService.endpoint_main + 'plant/equipmenttype/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.equipmentTypesFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.equipmentTypesFiltered = this.equipmentTypes;
      return;
    }

    this.equipmentTypesFiltered = this.equipmentTypesFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onClearFilters() {
    this.equipmentTypesFiltered = this.equipmentTypes;
  }

  /**
 * Realiza a leitura da lista completa de tipos de IOT.
 * @param url URL para a leitura da lista.
 */
  readAll(url: string): void {
    // Verifica se existe token e uma pessoa autenticada.
    if (this.authService.hasToken() && this.authService.hasPerson()) {

      const company = this.authService.getPerson();

      // Extrai o parâmetro de página da URL.
      const page = url.split('&').find(x => x.startsWith('page='));

      // Chama o serviço para obter a lista completa de tipos de equipamentos.
      this.plantService.getEquipmentTypeAllList(this.authService.endpoint_main + 'plant/equipmenttype/list?' + page, company)
        .pipe(take(1))
        .subscribe(
          data => {
            // Atualiza a lista de tipos de equipamentos, próxima e anterior.
            this.equipmentTypes = data.results;
            this.equipmentTypesFiltered = data.results;
            
            this.next = data.next;
            this.previous = data.previous;
          },
          error => {
            // Exibe um alerta de perigo em caso de erro.
            this.modalAlertService.showAlertDanger('Não foi possível listar tipos de equipamentos');
          }
        );
    } else {
      // Exibe um alerta de perigo e realiza o logout em caso de falha na autenticação.
      this.modalAlertService.showAlertDanger('Erro ao recuperar tipos de equipamentos');
      this.authService.doLogout();
    }
  }


  /**
   * Carrega a próxima página da lista paginada.
   */
  fetchNext(): void {
    this.readAll(this.next);
  }

  /**
   * Carrega a página anterior da lista paginada.
   */
  fetchPrevious(): void {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de um tipo específico.
   * @param id Identificador do tipo de equipamento.
   */
  readId(id): void {
    this.router.navigate(['/plant/equipmenttype/update', id]);
  }

  /**
   * Navega para a página de criação de um novo tipo de equipamento.
   */
  create(): void {
    this.router.navigate(['/plant/equipmenttype/create']);
  }

  /**
   * Realiza a exclusão de um tipo de equipamento.
   * @param item Tipo de equipamento a ser excluído.
   */
  onDelete(item): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('delete_equipmenttype')) {
      this.router.navigate(['/403']);
    }

    // Define o item selecionado para exclusão.
    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este tipo?'
    );

    // Aguarda a resposta do modal e, se confirmado, realiza a exclusão.
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.plantService.deleteEquipmentType(item.plant_equipment_type_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o item excluído da lista.
          let index;
          this.equipmentTypes.forEach((e, i) => {
            if (e.plant_equipment_type_id === item.plant_equipment_type_id) {
              index = i;
            }
          });
          this.equipmentTypes.splice(index, 1);

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgForm } from '@angular/forms';

import { HelpIndexService } from './help-index.service';
import { BaseFunc } from '../../shared/base-func/base-func';
import { DataEngineManager } from '../../shared/base-func/data-engine-manager';

import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-help-index',
  templateUrl: './help-index.component.html',
  styleUrls: ['./help-index.component.css']
})
export class HelpIndexComponent extends BaseFunc implements OnInit {
  
  query;
  url;
  header;
  filters;
  body;
  fields;
  tags;
  type;
  results;
  founded;

  request_type;
  sample;
  manager = new DataEngineManager(this);
  form: FormGroup = null;

  constructor(private service: HelpIndexService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private routerManager:Router) { super(); }

  ngOnInit(): void {
    this.read_config_data();
  }

  getURL = () => {
    this.route.paramMap.pipe(take(1)).subscribe((param: ParamMap) => {
      this.url = param.get('url');
      this.query = param.get('q');
    });
  }

  read_config_data = () => {
    var self = this;
    self.getURL();
    self.service.getHelpIndexConfig(this.url, this.query).pipe(take(1)).subscribe(
      data => {
        self.founded = data.founded;
        if(self.founded) {
          self.filters = data.filters;
          self.fields = data.fields;
          self.body = data.body;
          self.body.forEach( x => x['id'] = x['path'].split(' ')[0].replace('#', '').replace('fieldset_', '') );
          self.tags = data.tags;
          self.header = data.header;
          self.type = data.type;
          self.request_type = data.request_type;
          self.results = data.results;
          self.sample = data.sample;
        }
        self.form = self.formBuilder.group({ });
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
        setTimeout(function() {
          if( self.sample )
            self.map(self.sample, function(x, id) { self.buildImg(id, x) });

          self.$j('.user-profile').remove();
          self.$j('#sidebarnav li.sidebar-item a[href]').forEach(function(e,i){
            var href = self.$j(e).attr('href');
            if( href == 'javascript:void(0)' )
              return;

            href = href.startsWith('/') ? href.substring(1) : href;
      
            self.$j(e).attr('href', '/help_index/' + href.replace('/', '__'));
            self.$j(e).attr('routerlink', '/help_index/' + href.replace('/', '__'));
            self.$j(e).attr('router-link', '/help_index/' + href.replace('/', '__'));
            self.$j(e).attr('ng-reflect-router-link', '/help_index/' + href.replace('/', '__'));
            
          });
        }, 500)
      }
    );
  }

  buildImg = (key, data) => {
    try {
      var self = this;
      var response = data;
      var div_id = 'fieldset_' + key;
      var colNames = response.cols;
      var query_result = response.data;
      var reportFormat = response.type;
      var toServerCopy = response.toServer;
      var interval = 'month';

      var config;
      if(reportFormat == 'table')
        config = (response.config && response.config.trim() != '' ? response.config : '{}');
      else
        config = (response.config && response.config.trim() != '' ? eval('('+ response.config +')') : '{}');

      if(config.hasEvalPreShow) 
        eval(config.hasEvalPreShow);

      if (reportFormat == 'table') 
        self.manager.to_table(colNames, query_result, div_id, null, null, config, interval, true);
      else if (reportFormat.indexOf('text') != -1)
        self.manager.initializeText(colNames, query_result, div_id, config);
      else 
        self.manager.initializeGraph(colNames, query_result, {}, reportFormat, div_id, config, interval, true, false);
    }
    catch(e) {
      console.log('Não foi possível criar a amostra: ' + e);
    }
  }

  saveSamples = () => {
    // var self = this;
    // setTimeout(function() {
    //   var img = self.manager.curr_graph.canvas.toDataURL().replace('data:image/png;base64,', '');
    //   self.$j('#'+div_id).replaceWith( '<div id="{0}" class="card-report"><img src="data:image/png;base64,{1}" /></div>'
    //     .replace(/\{0\}/g, div_id).replace(/\{1\}/g, img) 
    //   );
    // }, 500)
  }

  submitSearch = (search_field) => {
    var self = this;
    var value = search_field.value;
    if(value.trim() == '') {
      self.$j(search_field).css('border-color', 'red');
      return;
    }
    else
      self.$j(search_field).css('border-color', '');
    
    window.location.href = '/help_index/results/' + search_field.value;
  }

}

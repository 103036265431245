import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { PersonsPerfilService } from '../persons-perfil.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from './../../../auth/auth.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Interface representando os dados do formulário do perfil da pessoa.
 */
interface PerfilForm {
  name: string;
  active: boolean;
}

/**
 * Componente Angular para a criação de perfis de pessoas.
 */
@Component({
  selector: 'app-persons-perfil-create',
  templateUrl: './persons-perfil-create.component.html',
  styleUrls: ['./persons-perfil-create.component.css']
})
export class PersonsPerfilCreateComponent implements OnInit {

  // FormGroup para o formulário de perfil.
  profileForm: FormGroup;
  // Flag indicando se o formulário foi enviado.
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor do componente.
   */
  constructor(
    private personsPerfilService: PersonsPerfilService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('add_personperfil')) {
      this.router.navigate(['/403']);
    }

    // Inicializa o formulário.
    this.initializeForm();
  }

  /**
   * Inicializa o formulário de perfil usando o FormBuilder.
   */
  private initializeForm(): void {
    this.profileForm = this.formBuilder.group({
      name: ['', Validators.required],
      active: [true]
    });
  }

  /**
   * Verifica se um campo específico é válido.
   *
   * @param field Nome do campo a ser verificado.
   * @returns true se o campo for inválido e o formulário foi enviado, caso contrário, false.
   */
  isFieldValid(field: string): boolean {
    const control = this.profileForm.get(field);
    return !control.valid && this.submitted;
  }

  /**
   * Obtém a classe CSS para um campo específico.
   *
   * @param field Nome do campo.
   * @returns Objeto CSS indicando se o campo é inválido.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   */
  validateFormField(): void {
    Object.values(this.profileForm.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.validateFormField();
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
   * Salva o perfil no serviço, realizando verificações de formuilario e ID da aplicação.
   * Exibe mensagens de sucesso ou erro conforme necessário.
   */
  save(): void {
    // Define a flag como verdadeira indicando que o formulário foi enviado.
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.profileForm.valid) {
      // Cria um objeto PerfilForm com os valores do formulário.
      const perfil: PerfilForm = { ...this.profileForm.value };

      // Verifica se existe token.
      if (this.authService.hasToken()) {

        // Chama o serviço para criar o perfil da pessoa.
        this.isLoading = true;
        this.personsPerfilService.createPersonsPerfil(perfil)
          .pipe(take(1))
          .subscribe(
            data => {
              // Atualiza o objeto PerfilForm com os dados retornados.
              Object.assign(perfil, data);
              // Navega de volta à página anterior.
              this.location.back();
              // Exibe um alerta de sucesso.
              this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
            },
            error => {
              // Em caso de erro, exibe um alerta de perigo e loga o erro no console.
              console.error(error);
              this.modalAlertService.showAlertDanger('Erro ao incluir o registro.');
            }
          ).add(() => {
            this.isLoading = false;
          });
      } else {
        // Caso não haja ID de aplicativo, exibe um alerta de perigo e realiza o logout.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Caso o formulário seja inválido, realiza a validação dos campos.
      this.validateFormField();
    }
  }
}

<section class="section">
    <div class="d-flex flex-wrap align-items-stretch">
        <div class="col-lg-4 col-md-6 col-12 order-lg-1 min-vh-100 order-2 bg-white">
            <div class="p-4 m-3">
                <img [src]="logo_wl" alt="logo" width="160" class="mb-5 mt-2">
                <h4>Cadastro de nova senha</h4>
                <form>
                    <div class="form-group has-feedback">
                        <label for="email">E-mail</label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="login.email" name="email">
                        <div class="invalid-feedback"></div>
                    </div>
                    <div class="form-group text-right" [ngClass]="{'d-none':login.emailChecked}">
                        <button type="submit" class="btn btn-primary" tabindex="4"
                            (click)="checkEmail()">
                            Verificar email
                        </button>
                    </div>
                    <div class="form-group has-feedback " *ngIf="login.emailChecked">
                        <label for="password1">Senha</label>
                        <input type="password" class="form-control form-control-lg" [(ngModel)]="login.password1"
                            name="password1">
                        <div class="invalid-feedback"></div>
                    </div>

                    <div class="form-group has-feedback " *ngIf="login.emailChecked">
                        <label for="confirmacao">Confirmação de Senha</label>
                        <input type="password" class="form-control form-control-lg" [(ngModel)]="login.password2"
                            name="password2">
                        <div class="invalid-feedback"></div>
                    </div>
                    <div class="form-group text-right">
                        <button type="submit" class="btn btn-primary" tabindex="4"
                            (click)="confirm()" *ngIf="login.emailChecked">
                            Salvar
                        </button>
                    </div>
                </form>
                <div class="text-center mt-5 text-small">
                    Copyright &copy; IndustryCare.
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
            </div>
        </div>
        <div class="col-lg-8 col-12 order-lg-2 order-1 min-vh-100 background-walk-y position-relative overlay-gradient-bottom">
        </div>
    </div>
</section>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator-control',
  templateUrl: './paginator-control.component.html',
  styleUrls: ['./paginator-control.component.css'],
})
export class PaginatorControlComponent implements OnChanges {
  @Output() change: EventEmitter<any> = new EventEmitter();

  pagination: any = {
    total: 0,
    itemsPerPage: 0,
    pages: [],
    actualPage: 1,
    offset: 0,
    limit: 0,
  };

  @Input() content: any[] = [];
  @Input() showCount: boolean = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onCalculatePages();
  }

  onCalculatePages() {
    this.onChangePage(1)
    this.pagination.pages = [];

    const pages = Math.ceil(this.content.length / 5);

    for (let index = 0; index < pages; index++) {
      if (!this.pagination.pages.includes(index + 1)) {
        this.pagination.pages.push(index + 1);
      }
    }

    this.pagination.itemsPerPage = 5;
    this.pagination.limit = 5;
    this.pagination.total = this.content.length;
  }

  onChangePage(value: number) {
    if (value > this.pagination.pages.length) {
      return;
    }
    this.pagination.actualPage = value;

    this.pagination.offset = this.pagination.itemsPerPage * (this.pagination.actualPage - 1);
    this.pagination.limit = this.pagination.offset + this.pagination.itemsPerPage;
    this.change.emit(this.pagination);
  }

  onNext() {
    this.pagination.actualPage++;
    this.onChangePage(this.pagination.actualPage);
    this.change.emit(this.pagination);
  }

  onPrevius() {
    this.pagination.actualPage--;
    this.onChangePage(this.pagination.actualPage);
    this.change.emit(this.pagination);
  }
}

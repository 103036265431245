<ng-container *ngIf="isHorizontal && !layout.isFullScreen">
  <div class="w-100" *ngIf="layout.width >= 900" style="margin-top: 140px;">
    <div class="d-flex align-items-center horizontal-myecos-bar justify-content-center">
      <ng-container *ngFor="let item of navbarItems">
        <button class="btn d-flex w-fit align-items-center justify-content-center py-3 my-0" routerLink="{{ item.url }}" *ngIf="item.condition()">
          <i [class]="item.icon"></i>
          <span class="mx-2">{{ item.label }}</span>
        </button>
      </ng-container>
  
      <div class="btn-group" dropdown *ngIf="navbarItems.size >= 4">
        <button dropdownToggle type="button" class="btn d-flex w-fit align-items-center justify-content-center py-3 my-0">
          <i class="fa-solid fa-bars fa-xl"></i>
          <span class="mx-2">Ver mais</span>
        </button>
        <ul class="py-0" id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <ng-container *ngFor="let item of navbarItems">
            <li role="menuitem" class="d-flex justify-content-start align-items-center px-2 py-2 pointer" routerLink="{{ item.url }}" *ngIf="!item.condition()">
              <i [class]="item.icon"></i>
              <span class="mx-2">{{ item.label }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
  
      <div class="btn-group" dropdown *ngIf="configItems.size > 0">
        <button dropdownToggle type="button" class="btn dropdown-toggle py-3 my-0">
          <i class="fa-solid fa-gear fa-xl"></i>
          <span class="mx-2">Configurações</span>
        </button>
        <ul class="py-0" id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <ng-container *ngFor="let item of configItems">
            <li role="menuitem" class="d-flex justify-content-start align-items-center px-2 py-2 pointer" routerLink="{{ item.url }}" *ngIf="item.condition()">
              <i [class]="item.icon"></i>
              <span class="mx-2">{{ item.label }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  
  
  <div (mouseenter)="isHide = false" (mouseleave)="isHide = true" class="d-flex flex-column justify-content-between vertical-myecos-bar bg-white shadow-lg px-2 py-3" [ngClass]="{ 'w-70': isHide }" *ngIf="layout.width < 900">
    <div class="d-flex flex-column mt-3">
      <img class="logo" src="assets/myEcos/image/logo-my-ecos.png" alt="" *ngIf="!isHide">
      <img  src="assets/img/logo-my-ecos.png" alt="" *ngIf="isHide">
      <hr class="my-3 w-100">

      <div class="d-flex justify-content-start align-items-center flex-column mb-2 shadow-md" [ngClass]="{ 'py-3': isHide, 'py-2': !isHide }">
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-circle-user fa-xl mx-2"></i>
          <div class="d-flex flex-column" *ngIf="!isHide">
            <span>{{ userName }}</span>
            <small>{{ authService.getEmail() }}</small>
            <small *ngIf="authService.checkPermission('access_company_switcher') || authService.getSuperUser()">{{ authService.getDbName() | uppercase }}</small>
          </div>
        </div>
        <hr class="my-3 w-100">
      </div>

      <button class="btn close-button d-flex justify-content-center fa-xl" (click)="onHandleHide()">
        <i class="fa-solid fa-arrow-right-arrow-left"></i>
      </button>
  
      <button class="btn d-flex justify-content-start align-items-center mt-2 py-3" routerLink="{{ item.url }}" *ngFor="let item of navbarItems">
        <i [class]="item.icon"></i>
        <span class="mx-2" *ngIf="!isHide">{{ item.label }}</span>
      </button>
  
      <button class="btn d-flex justify-content-start align-items-center mt-2 py-3" (click)="onHandleConfig()" *ngIf="configItems.size > 0">
        <i class="fa-solid fa-gear fa-xl"></i>
        <span class="mx-2" *ngIf="!isHide">Configurações</span>
        <i class="fa-solid fa-chevron-down mx-3" *ngIf="!isHide && hasConfigVertialOpened"></i>
        <i class="fa-solid fa-chevron-up mx-3" *ngIf="!isHide && !hasConfigVertialOpened"></i>
      </button>
  
      <div class="d-flex flex-column content-overflow" *ngIf="!isHide && hasConfigVertialOpened">
        <ng-container *ngFor="let item of configItems">
          <button class="btn" routerLink="{{ item.url }}" *ngIf="item.condition()">{{ item.label }}</button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
import { Component, OnInit, Input } from '@angular/core';
import { FilterSaveService } from './filter-save.service';
import { BaseFunc } from '../../shared/base-func/base-func';

import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';

import { take } from 'rxjs/operators';

@Component({
  selector: 'app-filter-save',
  templateUrl: './filter-save.component.html',
  styleUrls: ['./filter-save.component.css']
})
export class FilterSaveComponent extends BaseFunc implements OnInit {

  @Input() key:string = null;
  @Input() hasID:string = null;
  @Input() callback:string = null;
  
  id;
  formcode;
  plain_text;
  buttonID;
  cfuncSave;

  global: Global = new Global();
  
  constructor(private service: FilterSaveService) { super(); }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    self.id = "report" + (self.key.replace(' ', '_'));
    self.formcode = self.key.replace(' ', '_');
    self.plain_text = self.key;
    self.buttonID = self.hasID ? self.hasID : 'saveView';
    var callbackSave = (self.callback == null ? null : self.callback);
    self.cfuncSave = ( callbackSave != null ? new Function('name', 'values', "return " + callbackSave) : null);

    self.$j('#viewName').keypress(function(e){ 
      self.$j('#viewName').css('border-color', ''); 
    });
  }

  dropdownMenu = (e) => {
    e.stopPropagation();
  }

  save_filter = (event) => {
    var self = this;
    event.preventDefault();
    var name = self.$j('#viewName').val();
    if(name.trim() == '') {
        self.$j('#viewName').css('border-color', 'red');
        return;
    }
    var formData = self.global.saveData();
    formData["saveFilterFormCode"] = self.formcode;
    formData["saveFilterFilterName"] = self.$j('#viewName').val();
    formData["url_path"] = window.location.pathname;
    if(self.cfuncSave) { formData = self.cfuncSave('put', formData); }
    this.service.saveFilter(formData).pipe(take(1)).subscribe(
      data => {
        self.$j('#viewName').val('');
        self.$j('#saveFilterForm').hide();
        self.$j('#ok_message').show();
        setTimeout(function(){
          self.$j('#' + self.buttonID).trigger('click');
          self.$j('#saveFilterForm').show();
          self.$j('#ok_message').hide();
        }, 2000)
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
    self.$j('#' + self.buttonID).trigger('click');
  }

}

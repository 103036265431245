<div class="tab-content container-fluid" style="min-height: 500px">
  <div id="reportformdiv" code="" name="reportformdiv" class="needs-validation ">
    <h1 class="header-title">Criar {{name}}</h1>
    <div class="card customreport_card tour-report-container">
      <p>Olá usuário, comece dando um nome para o {{name}}</p>
      <div class="row">
        <div class="mt-2 col-6 col-md-4 col-lg-3 tour-report-name">
          <small id="name">Nome do {{name}}*</small>
          <input type="text" class="form-control" id="reportname" placeholder="Digite aqui" maxlength="50">
        </div>

        <div class="mt-2 col-6 col-md-4 col-lg-2 tour-report-scope" [hidden]="!authService.isSuperUser()">
          <small id="descriptionScope" data-toggle="tooltip" data-placement="bottom" title="Indica se o relatório estará disponível somente ao usuário ou se será visível a todos os usuários" >
            Escopo do {{name}}
            <i class="mdi-14 mdi mdi-information-outline tour-report-help"></i>
          </small>
          <ng-select #permissionAxis style="width: 100%;"
            [(ngModel)]="permissionAxisBridge"
            [multiple]="true"
            maxSelectedItems="99999" >
            <ng-option *ngFor="let c of permissionAxisValue" value="{{c[0]}}">{{c[1]}}</ng-option>
          </ng-select>
        </div>


        <div class="mt-2 col-6 col-md-4 col-lg-2 tour-report-group" *ngIf="( builderType != 'newsletter' )">
          <small id="group">Grupo*</small>
          <input type="text" class="form-control" id="reportgroup" placeholder="Digite aqui" maxlength="50">
        </div>

        <div class="mt-2 col-6 col-md-4 col-lg-2 tour-report-desc">
          <small id="description" data-toggle="tooltip" data-placement="bottom" title="A descrição do relatório serve para identificar o que é o relatório" >
            Descrição do {{name}}
            <i class="mdi-14 mdi mdi-information-outline mx-0"></i>
          </small>
          <input type="text" class="form-control" id="reportdescription" placeholder="Digite aqui" maxlength="200">
        </div>

        <div class="mt-2 col-6 col-md-4 col-lg-2 tour-report-freq" *ngIf="isDashboard">
          <small id="description" data-toggle="tooltip" data-placement="bottom" title="Frequencia em que o relatorio deve ser atualizado durante sua exibição" (click)="onCalculateTime()">
            Frequencia de atualização
            <i class="mdi-14 mdi mdi-information-outline mx-0"></i>
          </small>
          <form [formGroup]="formFreq">
            <div class="d-flex">
              <input type="number" [min]="formMin" [max]="formMax" class="form-control" formControlName="time" (change)="onChangeFreq()">
              <select class="form-control" style="width: 50%;" formControlName="type" (click)="onSelectFreqType()">
                <option value="seg">seg</option>
                <option value="min">min</option>
              </select>
            </div>
            <small *ngIf="errorFreq" class="text-danger">{{ errorFreq }}</small>
          </form>
        </div>
      </div>
    </div>

    <div class="card customreport_card tour-report-filters">
      <div class="col-12">
        <small id="name"><strong>Configure os tipos de filtro deste {{name}}</strong></small>
        <app-filter-select (selectedOutput)="onChange($event)"></app-filter-select>
      </div>
      <ng-container *ngIf="( builderType == 'analytics' )">
        <ng-container *ngIf="filters && filters.length > 0">
          <div *ngFor="let f of filters_visible; let ii = index" id="{{f.id}}_div" name="{{f.id}}_div" class="row filter-report filter-border mb-2 {{ ii == 0 ? ' mt-4' : '' }}" >
            <div class="col-4">
              <strong style="line-height: 30px;">{{f.label}}</strong>
            </div>
            <div class="col-4">
              <ng-select class="is_required" [closeOnSelect]="true" [ngModel]="f.req ? f.req : selectedItemRequired">
                <ng-option value="FAC" *ngIf=" !['DATEBTW', 'YEARBTW' ].includes(f.id) " >Facultativo</ng-option>
                <ng-option value="OBG">Obrigatório</ng-option>
              </ng-select>
            </div>
            <div class="col-4">
              <ng-select class="limit" [closeOnSelect]="true" [ngModel]="f.lim? f.lim : selectedItemLimit">
                <ng-option value="ilimitado">Ilimitado</ng-option>
                <ng-option  *ngFor="let i of ( !['DATEBTW', 'YEARBTW' ].includes(f.id) ? range : [] )" value="{{i}}">{{i}}</ng-option>
              </ng-select>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="( builderType == 'dashboard' || builderType == 'newsletter' )">
        <div class="card row">
          <div class="card-body activeFilterContainer">
            <div>
              <div *ngFor="let f of filters_visible" id="{{f.id}}_div" name="{{f.id}}_div"   class="row filter-report filter-border mb-2" >
                <app-field-engine #fields #wrapper [field]="{ 'type': f.id, 'count': 0 }" [reportFilters]="reportFilters" positionField="col-12" formType="CRUD" ></app-field-engine>
                <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="filters_visible && filters_visible.length > 0">
        <div class="card" >
          <div class="card-body" >
            <h4 class="card-title">Layout do {{name}}</h4>

            <div class="row" >
              <div class="btn-group1 btn-group-toggle col-3" >
                <label class="btn btn-secondary {{ seedTypeSelector == 1 ?  'focus active' : '' }}">
                  <input type="radio" name="seedType" value="1" (change)="seedTypeChange($event)" [(ngModel)]="seedTypeSelector" > Personalizado
                </label>
                <label class="btn btn-secondary {{ seedTypeSelector == 2 ?  'focus active' : '' }}">
                  <input type="radio" name="seedType" value="2" (change)="seedTypeChange($event)" [(ngModel)]="seedTypeSelector"> Por Entidades
                </label>

              </div>

              <div class="col-7" *ngIf="seedTypeSelector == 2">
                <ng-select
                  [(ngModel)]="seedAxisBridge"
                  [multiple]="false"
                  (change)="seedTypeChange($event)"
                  maxSelectedItems="1" >
                  <ng-option *ngFor="let c of seed_dict" value="{{c[0]}}">{{c[1]}}</ng-option>
                </ng-select>
              </div>

              <div class="col-2" *ngIf="seedTypeSelector == 2">
                <button style="background-color:transparent !important;" id="confxxx_1" name="confxxx_1" class="btn btn-primary waves-effect tour-report-config" data-toggle="modal" data-target="#right-bar-modal" (click)="configWidget(1)">
                  <a class="card_link" data-target="#right-bar-modal">Configurar Visualização</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>


    </div>
    <div [hidden]="!filters || filters.length<=0">
      <div class="row">
        <strong style="margin-left: 20px; margin-bottom: 15px; margin-top: 5px;">Pré-Visualização do {{name}}</strong>
      </div>
      <div class="col-12 tour-report-preview">
        <div id="report-content" name="report-content" class="grid-stack grid-stack-10" style="min-height: 400px; background-color: whitesmoke">
        </div>
      </div>

      <div class="row">
        <div style="margin: 0 auto;">
          <button type="button" class="btn btn-secondary"
            data-dismiss="modal" (click)='cancelReport()'>Cancelar</button>
          <button type="button" class="btn btn-primary"
            data-dismiss="modal" (click)='saveReport()'>Salvar {{name}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
  <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div class="modal left fade" id="right-bar-modal" tabindex="" role="dialog" aria-labelledby="right-bar-modal-label" aria-hidden="true">
  <div class="modal-graph card" [hidden]="!showSample || hasSupervisorySelected">
    <div class="card-body">
      <div id="reportbodyname_sample" name="reportbodyname_sample" style="font-weight: bold; margin-bottom: 20px;"></div>

      <!-- Conteúdo acima do asset -->
      <div id="customReportUpperAuxCell_widgetcontent_sample" class="auxDivClass customReportUpperAuxCell_widgetcontent_sample"></div>

      <div class="row" style="height: 500px; width: 40vw; overflow: auto">
        <div id="widgetcontent_sample" name="widgetcontent_sample" class="widgetcontent map-height" ></div>
      </div>
    </div>
  </div>

  <div class="modal-graph card" *ngIf="visible_graph_button('supervisory')">
    <div class="card-body d-flex flex-column justify-content-center align-items-center">
      <ng-container *ngIf="groupedMaps.length > 0">
        <h5>{{ groupedMaps[selectedMap].title }}</h5>
        <div class="row d-flex justify-content-center" style="height: 500px; width: 40vw; overflow: auto" id="content-preview">
          <app-three-render-visualizer [data]="previewMap"></app-three-render-visualizer>
        </div>
      </ng-container>

      <div class="d-flex flex-column justify-content-center align-items-center mt-2">
        <h5 *ngIf="groupedMaps.length > 0">Ordem de exibição dos mapas</h5>

        <ng-container *ngIf="groupedMaps.length == 0">
          <span>Você ainda não selecionou nenhum mapa</span>
        </ng-container>

        <div class="d-flex justify-content-center align-items-center" *ngIf="groupedMaps.length > 0">
          <div class="d-flex flex-column justify-content-center align-items-center img-indicator" *ngFor="let map of groupedMaps; index as i">
            <img class="grap-general-size mr-1" [src]="onSanitizeImg(map.content)" alt="" (click)="onPreviewMap(i)">
            <div>
              <span class="text-white bg-primary rounded-circle w-fit px-1 py-1 mt-1">{{ i + 1}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>



  <div class="modal-dialog" role="document">
    <div class="modal-content tour-report-aside">
      <div class="modal-body">
        <input id="widget_id" name="widget_id" type="hidden" val="" />
        <input id="supervisory_id" type="hidden" val="" />
        <div class="right-bar-header">Configurar Visualização</div>
        <p>Preencha os campos abaixo para criar uma nova visualização para seu {{name}}. </p>
        <div class="row {{ seedTypeSelector.toString() == '2' ? 'd-none' : '' }}" >
          <small id="name col-11"><strong>Nome da visualização</strong></small>
        </div>
        <div class="row {{ seedTypeSelector.toString() == '2' ? 'd-none' : '' }}" >
          <input type="text" class="form-control tour-report-aside-name"
            #componentName
            id="componentName"
            (change)="sampleWidgetConf()"
            placeholder="Digite aqui" maxlength="50">
        </div>
        <div class='row'>
          <small class="form-text col-11"><strong>Eixo X</strong></small>
        </div>
        <div class='row tour-report-aside-x'>
          <ng-select #xAxis style="width: 100%;"
            [(ngModel)]="xAxisBridge"
            (change)="sampleWidgetConf(true, 'x', $event); visible_axis_options('x');"
            (open)="visible_axis_options('x');"
            [multiple]="true"
            maxSelectedItems="{{ isMultipleX ? 99999 : 1 }}" >
            <ng-option *ngFor="let c of xAxisValue" value="{{c[0]}}">{{c[1]}}</ng-option>
          </ng-select>
        </div>
        <div class='row'>
          <small class="form-text col-11"><strong>Eixo Y</strong></small>
        </div>
        <div class='row tour-report-aside-y'>
          <ng-select #yAxis style="width: 100%;"
            [(ngModel)]="yAxisBridge"
            (change)="sampleWidgetConf(true, 'y', $event); visible_axis_options('y');"
            (open)="visible_axis_options('y');"
            [multiple]="true"

            [items]="yAxisValue"
            groupBy="grouping"
            bindLabel="name"

            maxSelectedItems="{{ isMultipleY ? 99999 : 1 }}" >
            <!--<ng-option *ngFor="let c of yAxisValue" value="{{c[0]}}">{{c[1]}}</ng-option>-->
          </ng-select>
        </div>

        <div class='row' [hidden]=" !['1'].includes(assetType) && axisHas.hasY !== 1 ">
          <small class="form-text col-11"><strong>Estratificação</strong></small>
        </div>
        <div class='row' [hidden]=" !['1'].includes(assetType) && axisHas.hasY !== 1 ">
          <ng-select #stratAxis style="width: 100%;"
            (change)="sampleWidgetConf(true, 'x', $event);"
            [multiple]="true"
            maxSelectedItems="{{ 1 }}" >
            <ng-option *ngFor="let c of xAxisValue" value="{{c[0]}}">{{c[1]}}</ng-option>
          </ng-select>
        </div>

        <br/>
        <div class="d-flex flex-column w-100 tour-report-aside-graph">
          <div class='row'>
            <small><strong>Visualização</strong></small>
          </div>
          <div class='row'>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('pie') " >
              <button type="button" id="view_pie" name="view_pie" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('pie');"><i class="mdi mdi-chart-pie"></i>Pizza</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('radar') " >
              <button type="button" id="view_radar" name="view_radar" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('radar');"><i class="mdi mdi-asterisk"></i>Rede</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('line') " >
              <button type="button" id="view_line" name="view_line" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('line');"><i class="mdi mdi-chart-line"></i>Linhas</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('doughnut') " >
              <button type="button" id="view_doughnut" name="view_doughnut" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('doughnut');"><i class="mdi mdi-chart-arc"></i>Donut</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('column') " >
              <button type="button" id="view_column" name="view_column" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('column');"><i class="mdi mdi-chart-bar"></i>Colunas</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('bar') " >
              <button type="button" id="view_bar" name="view_bar" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('bar');"><i class="mdi mdi-chart-gantt"></i>Barras</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('stackedcolumn') ">
              <button type="button" id="view_stackedcolumn" name="view_stackedcolumn" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('stackedcolumn');"><i class="mdi mdi-chart-bar"><sup>E</sup></i>Colunas Emp.</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('stackedbar') " >
              <button type="button" id="view_stackedbar" name="view_stackedbar" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('stackedbar');"><i class="mdi mdi-chart-gantt"><sup>E</sup></i>Barras Emp.</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('scatter') " >
              <button type="button" id="view_scatter" name="view_scatter" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('scatter');"><i class="mdi mdi-chart-scatterplot-hexbin"></i>Dispersão</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('bubble') " >
              <button type="button" id="view_bubble" name="view_bubble" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('bubble');"><i class="mdi mdi-chart-bubble"></i>Bolhas</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('histogram') " >
              <button type="button" id="view_histogram" name="view_histogram" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('histogram');"><i class="mdi mdi-chart-histogram"></i>Histograma</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('boxplot') " >
              <button type="button" id="view_boxplot" name="view_boxplot" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('boxplot');"><i class="mdi mdi-textbox fa fa-rotate-90"></i>Boxplot</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('combo') " >
              <button type="button" id="view_combo" name="view_combo" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('combo');"><i class="far fa-edit"></i>Combo</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf=" visible_graph_button('table') " >
              <button type="button" id="view_table" name="view_table" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('table');"><i class="mdi mdi-table-large"></i>Tabela</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="visible_graph_button('map') " >
              <button type="button" id="view_map" name="view_map" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('map');"><i class="mdi mdi-map-marker-radius"></i>Mapa</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="visible_graph_button('supervisory')">
              <button type="button" id="view_supervisory" name="view_map" class="btn btn-secondary waves-effect selectableExpand"
                (click)="changeGraphFormat('supervisory')">
                <i class="fa-solid fa-layer-group mr-1"></i>
                Supervisório
              </button>
            </div>
        </div>
        </div>
        <select id="graphFormat" name="graphFormat" style="display: none;">
          <option value="line" [selected]="selectedGraph==='line'">Linhas</option>
          <option value="column" [selected]="selectedGraph==='column'">Colunas</option>
          <option value="bar" [selected]="selectedGraph==='bar'">Barras</option>

          <option value="stackedcolumn" [selected]="selectedGraph==='stackedcolumn'">Colunas Emp.</option>
          <option value="stackedbar" [selected]="selectedGraph==='stackedbar'">Barras Emp.</option>

          <option value="pie" [selected]="selectedGraph==='pie'">Pizza</option>
          <option value="doughnut" [selected]="selectedGraph==='doughnut'">Donut</option>
          <option value="radar" [selected]="selectedGraph==='radar'">Radar</option>
          <option value="scatter" [selected]="selectedGraph==='scatter'">Dispersão</option>
          <option value="bubble" [selected]="selectedGraph==='bubble'">Bolhas</option>

          <option value="histogram" [selected]="selectedGraph==='histogram'">Histograma</option>
          <option value="boxplot" [selected]="selectedGraph==='boxplot'">Boxplot</option>

          <option value="combo" [selected]="selectedGraph==='combo'">Combo</option>
          <option value="table" [selected]="selectedGraph==='table'">Tabela</option>

          <option value="map" [selected]="selectedGraph==='map'">Mapa</option>
        </select>
        <br/>

        <div class='row'>
          <div class="col-12 form-group m-b-30" [hidden]=" !( selectedGraph == 'scatter' && assetType != '1' ) " >
            <label for="granularity">Granularidade Dispersão</label>
            <select id="granularity" name="granularity" class="custom-select" >
              <option value="monthly" >Mensal</option>
              <option value="daily" selected="selected">Diário</option>
              <option value="hour">Hora</option>
              <option value="minute">Minuto a Minuto</option>
            </select>
          </div>
        </div>
        <br/>

        <!-- Zona de configuração do asset de visualização -->
        <div class="conf_area tour-report-aside-asset">
          <div class='row title_config_global'>
            <small class="form-text col-12"><strong>Configurações do asset</strong></small>
          </div>


          <!-- Supervisório -->

          <ng-container *ngIf="visible_graph_button('supervisory')">
            <div class="row mt-2">
              <div class="d-flex align-items-center img-group w-100">
                <img class="grap-general-size mr-2 my-1 pointer" id="image-{{ i }}" [class.selected-map]="isSelected(map)" [src]="onSanitizeImg(map.content)" alt="" *ngFor="let map of mapsWithPoints; index as i" (click)="onSelectMap(i)">
              </div>
            </div>
          </ng-container>


          <!-- Geral -->
          <div class='row title_config mt-2'>
            <small class="form-text col-11"><strong>Gerais</strong></small>

            <small class="form-text col-1 cursor-pointer"
              (click)="configArea('general');" >
              <i title="Opções Gerais" class="fas fa-cogs"></i>
            </small>

          </div>

          <div class='row mt-2'>
            <div class="col-md-12" [hidden]="!show_general_panel">
              <div class="card">
                <div class="card-body">

                  <!-- Checkboxes com as opções gerais -->
                  <div class="row" >
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('legend')">
                      <input type="checkbox" class="custom-control-input" id="hideLegend"
                        [checked]=" assetType == '3' "
                        [disabled]=" assetType == '3' "
                        (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="hideLegend">Ocultar Legendas</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('gridline')" >
                      <input type="checkbox" class="custom-control-input" id="hideGridLinesFunc" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="hideGridLinesFunc">Ocultar GridLines </label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('label')" >
                      <input type="checkbox" class="custom-control-input" id="labels" (change)="sampleWidgetConf()" [(ngModel)]="checkboxes.labels">
                      <label class="custom-control-label" for="labels">Adicionar Rótulos </label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('splitFirstDimension')" >
                      <input type="checkbox" class="custom-control-input" id="splitFirstDimension" (change)="sampleWidgetConf()" [(ngModel)]="checkboxes.splitFirstDimension">
                      <label class="custom-control-label" for="splitFirstDimension">Seletor da 1ª Dimensão</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('beginAtZeroAxis')" >
                      <input type="checkbox" class="custom-control-input" id="beginAtZeroAxis" (change)="sampleWidgetConf()" >
                      <label class="custom-control-label" for="beginAtZeroAxis">Iniciar Valores em Zero</label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="false" >
                      <input type="checkbox" class="custom-control-input" id="regularTranspose" (change)="sampleWidgetConf()" [(ngModel)]="checkboxes.regularTranspose">
                      <label class="custom-control-label" for="regularTranspose">Transpor Dados</label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('clustermarket')" >
                      <input type="checkbox" class="custom-control-input" id="isClusterMarket" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="isClusterMarket">Agrupar Marcadores</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('heatmap')" >
                      <input type="checkbox" class="custom-control-input" id="heatmap" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="heatmap">Mapa de Calor</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('typemarket')" >
                      <input type="checkbox" class="custom-control-input" id="mapType" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="mapType">Desagrupar na 2ª Dimensão </label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('table')" >
                      <input type="checkbox" class="custom-control-input" id="withoutHeader" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="withoutHeader">Remover Cabeçalhos</label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('table')" >
                      <input type="checkbox" class="custom-control-input" id="withoutBorder" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="withoutBorder">Remover Bordas</label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('table')" >
                      <input type="checkbox" class="custom-control-input" id="withStriped" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="withStriped">Zebrada</label>
                    </div>

                    <div class="custom-control custom-checkbox col-4" [hidden]="true">
                      <input type="checkbox" class="custom-control-input" id="consolidate" >
                      <label class="custom-control-label" for="consolidate">Usar Dado Consolidado </label>
                    </div>
                    <div class="custom-control custom-checkbox col-4" [hidden]="visible_general_checkbox('regression')" >
                      <input type="checkbox" class="custom-control-input" id="rgPanel" >
                      <label class="custom-control-label" for="rgPanel">Ativar Painel de Regressão </label>
                    </div>
                  </div>

                  <!-- Configurações do Seletor sobre a Primeira Dimensão -->
                  <div class="row splitFirst_config_div" *ngIf="checkboxes.splitFirstDimension" >
                    <small class="form-text col-11 mb-4"><strong>Configurações Seletor da 1ª Dimensão</strong></small>

                    <div class="col-11 d-flex form-group" >
                      <label for="divselectSplitFirstDimensionType" class="pr-3">Tipo</label>

                      <div id="divselectSplitFirstDimensionType" name="divselectSplitFirstDimensionType" class="divGroup">
                        <input type="radio" id="splitFirstDimensionTypeCheckbox" name="splitFirstDimensionType" value="checkbox" (change)="sampleWidgetConf()" [(ngModel)]="splitFirstDimensionType" >
                        <label class="inputright {{ splitFirstDimensionType == 'checkbox' ?  'focus active' : '' }}">
                          Múltipla
                        </label>
                        <input type="radio" id="splitFirstDimensionTypeRadio" name="splitFirstDimensionType" value="radio" (change)="sampleWidgetConf()" [(ngModel)]="splitFirstDimensionType">
                        <label class="inputright {{ splitFirstDimensionType == 'radio' ?  'focus active' : '' }}">
                          Única
                        </label>
                      </div>

                    </div>

                  </div>

                  <!-- Configurações das labels -->
                  <div class="row labels_config_div" *ngIf="checkboxes.labels" >
                    <small class="form-text col-11 mb-4"><strong>Configurações dos Rótulos</strong></small>

                    <div class="col-4 form-group m-b-30">
                      <label for="anchor">Posição</label>
                      <select id="anchor" name="anchor" class="custom-select" [(ngModel)]="general_config.anchor" (change)="sampleWidgetConf()">
                        <option value="start">Início</option>
                        <option value="center" selected="selected">Centro</option>
                        <option value="end">Fim</option>
                      </select>
                    </div>

                    <div class="col-4 form-group m-b-30">
                      <label for="align">Alinhamento</label>
                      <select id="align" name="align" class="custom-select" [(ngModel)]="general_config.align" (change)="sampleWidgetConf()">
                        <option value="start">Início</option>
                        <option value="center" selected="selected">Centro</option>
                        <option value="end">Fim</option>
                        <option value="right">Direita</option>
                        <option value="bottom">Embaixo</option>
                        <option value="left">Esquerda</option>
                        <option value="top">Topo</option>
                      </select>
                    </div>

                    <div class="col-4 form-group m-b-30">
                      <label for="label_color">Cor</label>
                      <select id="label_color" name="label_color" class="custom-select" [(ngModel)]="general_config.label_color" (change)="sampleWidgetConf()">
                        <option value="{{bc[1]}}" *ngFor="let bc of band_colors" >{{bc[0]}}</option>
                      </select>
                    </div>

                    <div class="col-11 form-group m-b-30">
                      <label for="label_value">Valor</label>
                      <select id="label_value" name="label_value" class="custom-select" [(ngModel)]="general_config.label_value" (change)="sampleWidgetConf()">
                        <option value="value" selected="selected">Valor</option>
                        <option value="percentual">Percentual</option>
                        <option value="valuePercentual">Valor e Percentual</option>
                      </select>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- Eixo X -->
          <div class='row title_config mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) ">
            <small class="form-text col-11"><strong>Eixo X</strong></small>

            <small class="form-text col-1 cursor-pointer"
              (click)="configArea('x');" >
              <i title="Opções do Eixo X" class="fas fa-cogs"></i>
            </small>
          </div>

          <div class='row mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) ">
            <div class="col-md-12" [hidden]="!show_x_panel">
              <div class="card">
                <div class="card-body">

                  <div class="row" [hidden]="['table', 'map'].includes(selectedGraph)">
                    <small id="name_title_x">Título Eixo X</small>
                    <input type="text" class="form-control" id="title_x" name="title_x" placeholder="Digite aqui" maxlength="50" (change)="sampleWidgetConf()">
                  </div>

                  <div class="row">
                    <label for="position">Ordenação</label>
                    <select class="form-control selector-position"  id="orderSeries" [(ngModel)]="orderSeries" (change)="sampleWidgetConf()">
                      <option value="lexicographic" selected>Alfabética</option>
                      <option value="reverseLexicographic">Alfabética Inversa</option>
                      <ng-container *ngFor="let f of (mtr_sel && mtr_sel['itemsList'] ? mtr_sel['itemsList'].selectedItems : [] ); index as i; trackBy: trackByIndex;"  >
                        <option value="numeric_{{i+1}}">Numérica Série {{i+1}}</option>
                        <option value="reversenumeric_{{i+1}}">Numérica Inversa Série {{i+1}}</option>
                      </ng-container>
                    </select>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- Eixo Y -->
          <div class='row title_config mt-2'>
            <small class="form-text col-11"><strong>Eixo Y</strong></small>

            <small class="form-text col-1 cursor-pointer"
              (click)="configArea('y');" >
              <i title="Opções do Eixo Y" class="fas fa-cogs"></i>
            </small>
          </div>

          <div class='row mt-2' >
            <div class="col-md-12" [hidden]="!show_y_panel">
              <div class="card">
                <div class="card-body">

                  <div class="row" [hidden]="['table', 'map'].includes(selectedGraph)">
                    <small id="name_title_y">Título Eixo Y (esquerda)</small>
                    <input type="text" class="form-control" id="title_y" name="title_y" placeholder="Digite aqui" maxlength="50" (change)="sampleWidgetConf()">
                  </div>



                  <div class="row my-2" [class.d-none]="!hasMultipleTitle">
                    <small id="name_title_y">Título Eixo Y (direita)</small>
                    <input type="text" class="form-control" id="title_y_right" name="title_y_right" placeholder="Digite aqui" maxlength="50" (change)="sampleWidgetConf()">
                  </div>


                  <ng-container *ngIf="!hasSupervisorySelected">
                    <div *ngFor="let f of (mtr_sel && mtr_sel['itemsList'] ? mtr_sel['itemsList'].selectedItems : [] )" id="{{f.value.id }}_mdiv" name="{{f.value.id }}_mdiv" class="row 4 mt-5" >
                      <strong class="col-12" style="line-height: 30px;">Conf. {{metrics_dict[f.value.id]}}</strong>

                      <div class="col-12 form-group mb-3" [hidden]="selectedGraph !=='combo'" >
                        <label for="serie_{{f.value.id}}">Tipo</label>
                        <select id="serie_{{f.value.id}}" name="serie_{{f.value.id}}" class="custom-select" (change)="sampleWidgetConf()">
                          <option value="bar">Barra</option>
                          <option value="line" selected="selected">Linha</option>
                          <option value="dotted-line">Linha Pontilhada</option>
                          <option value="area">Área</option>
                        </select>
                      </div>

                      <div class="col-8 form-group mb-3">
                        <label for="alias_{{f.value.id}}">Apelido</label>
                        <input type="text" class="form-control" id="alias_{{f.value.id}}" (change)="sampleWidgetConf()">
                      </div>

                      <div class="col-4 form-group mb-3">
                        <label for="precision_{{f.value.id}}">Precisão</label>
                        <select class="custom-select" id="precision_{{f.value.id}}" (change)="sampleWidgetConf()">
                          <option value="default0">0</option>
                          <option value="default1">0.0</option>
                          <option value="default2" selected="selected">0.00</option>
                          <option value="default3">0.000</option>
                          <option *ngIf=" selectedGraph == 'table' " value="hour">00:00:00.000</option>
                        </select>
                      </div>

                      <div class="col-4 form-group mb-3">
                        <label for="limit_{{f.value.id}}">Restriçoes</label>
                        <select class="custom-select" id="limit_{{f.value.id}}" (change)="sampleWidgetConf()">
                            <option value=">"> > </option>
                            <option value=">="> >= </option>
                            <option value="<" selected="selected"> < </option>
                            <option value="<"> <= </option>
                            <option value="between"> entre </option>
                        </select>
                      </div>

                      <div class="col-4 form-group mb-3">
                        <label for="limit_from_{{f.value.id}}"></label>
                        <input type="number" class="form-control" id="limit_from_{{f.value.id}}" (change)="sampleWidgetConf()">
                      </div>

                      <div class="col-4 form-group mb-3">
                        <label for="limit_to_{{f.value.id}}"></label>
                        <input type="number" class="form-control" id="limit_to_{{f.value.id}}" (change)="sampleWidgetConf()">
                      </div>

                      <div class="col-12">
                        <label for="position">Posição no gráfico</label>
                        <select class="form-control selector-position"  id="graph_position_{{f.value.id}}" (change)="onChangeAxisYPosition($event.target['value'])">
                          <option value="left">Esquerda</option>
                          <option value="right">Direita</option>
                        </select>
                      </div>


                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>

          <!-- Bandas -->
          <div class='row title_config mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) ">
            <small class="form-text col-11"><strong>Bandas - Customizadas</strong></small>

            <small class="form-text col-1 cursor-pointer"
              (click)="configArea('bands');" >
              <i title="Opções de Bandas" class="fas fa-cogs"></i>
            </small>
          </div>

          <div class='row mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) ">
            <div class="col-md-12" [hidden]="!show_bands_panel">
              <div class="card">
                <div class="card-body">
                  <div class="form-group m-b-30">
                    <label for="number_bands">Número de Bandas</label>
                    <input type="number" class="form-control" id="number_bands"
                      [(value)]="number_bands"
                      (change)="change_band_number($event); sampleWidgetConf()"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      min="0" max="5">
                  </div>

                  <div *ngFor="let f of number_bands_array" id="band_line_{{f}}" name="band_line_{{f}}"  class="row" >
                    <strong class="col-12" style="line-height: 30px;">Banda Linear #{{f+1}} </strong>

                    <div class="col-8 form-group m-b-30">
                      <label for="band_name_{{f}}">Nome</label>
                      <input type="text" class="form-control" id="band_name_{{f}}" name="band_name_{{f}}" (change)="sampleWidgetConf()">
                    </div>

                    <div class="col-4 form-group m-b-30">
                      <label for="band_value_{{f}}">Valor</label>
                      <input type="number" class="form-control" id="band_value_{{f}}" name="band_value_{{f}}" (change)="sampleWidgetConf()">
                    </div>

                    <div class="col-6 form-group m-b-30">
                      <label for="band_position_{{f}}">Posição</label>
                      <select class="custom-select" id="band_position_{{f}}" (change)="sampleWidgetConf()">
                        <option value="top" >Topo</option>
                        <option value="bottom" >Embaixo</option>
                        <option value="left"  >Esquerda</option>
                        <option value="right" selected="selected">Direita</option>
                        <option value="center" >Centro</option>
                      </select>
                    </div>

                    <div class="col-6 form-group m-b-30">
                      <label for="band_color_{{f}}">Cor</label>
                      <select class="custom-select" id="band_color_{{f}}" (change)="sampleWidgetConf()">
                        <option value="black" selected="selected" >Padrão</option>
                        <option value="{{bc[1]}}" *ngFor="let bc of band_colors" >{{bc[0]}}</option>
                      </select>
                    </div>

                    <div class="col-6 form-group m-b-30">
                      <label for="band_mode_{{f}}">Modo</label>
                      <select class="custom-select" id="band_mode_{{f}}" (change)="sampleWidgetConf()">
                        <option value="horizontal" selected="selected" >Horizontal</option>
                        <option value="vertical" >Vertical</option>
                      </select>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- Medidas de Tendência -->
          <div class='row title_config mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) ">
            <small class="form-text col-11"><strong>Bandas - Medidas de Tendência</strong></small>

            <small class="form-text col-1 cursor-pointer"
              (click)="configArea('tendency');" >
              <i title="Opções de Bandas" class="fas fa-cogs"></i>
            </small>
          </div>

          <div class='row mt-2' [hidden]=" ( ['table', 'map'].includes(selectedGraph) ) " >
            <div class="col-md-12" [hidden]="!show_tendency_panel">
              <div class="card">
                <div class="card-body">
                  <div class="row" >
                    <div class="custom-control custom-checkbox col-4">
                      <input type="checkbox" class="custom-control-input" id="avg" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="avg">Média</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4">
                        <input type="checkbox" class="custom-control-input" id="std" (change)="sampleWidgetConf()">
                        <label class="custom-control-label" for="std">Desvio Padrão</label>
                    </div>
                    <div class="custom-control custom-checkbox col-4">
                      <input type="checkbox" class="custom-control-input" id="var" (change)="sampleWidgetConf()">
                      <label class="custom-control-label" for="var">Variância</label>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row tour-report-aside-controlls">
            <div style="margin: 0 auto;">
                <button type="button" id="right-bar-modal-close" class="btn btn-secondary waves-effect tour-report-aside-cancel" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary waves-effect" (click)='saveWidgetConf()'>Salvar</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-button-how-to [sequence]="tourSequence"></app-button-how-to>

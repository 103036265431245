import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-customize',
  templateUrl: './company-container.component.html',
  styleUrls: ['./company-container.component.css'],
})
export class CompanyContainerComponent implements OnInit, DoCheck {
  items: any[] = [
    {
      id: 0,
      label: 'Grupo',
      alias: 'Grupo',
      permission: 'view_companygroup',
    },
    {
      id: 1,
      label: 'Unidade',
      alias: 'Unidade',
      permission: 'view_companydivision',
    },
    {
      id: 2,
      label: 'Empresa',
      alias: 'Empresa',
      permission: 'view_company',
    },
  ];

  selectedIndex: number = null;

  constructor(private authService: AuthService, private router: Router, public layout: LayoutService) {}

  ngDoCheck(): void {
    this.items.map((item) => {
      item.alias = this.layout.aliasTitle(item.label);
    });
  }

  ngOnInit(): void {
    this.onVerifyPermissions();
    this.onSetFirstAvailableTab();
  }

  setTabIndex(index: number) {
    this.selectedIndex = index;
  }

  onVerifyPermissions() {
    const permissions = JSON.parse(this.authService.getPermissions()) as any[];
    this.items.map((rp) =>
      permissions.some((pe) => {
        if (rp.permission == pe.codename) {
          rp.available = true;
        }
      }),
    );
  }

  onSetFirstAvailableTab() {
    const availableItems = this.items.filter((item) => item.available == true);

    if (availableItems.length == 0) {
      this.router.navigate(['/403']);
    }

    if (availableItems.length > 0) {
      this.setTabIndex(availableItems[0].id);
    }
  }
}

<ul class="nav nav-pills m-t-30 m-b-30">
  <li class=" nav-item" (click)="setTabIndex(0)">
    <a href="#navpills-0" class="nav-link active" data-toggle="tab" aria-expanded="false">Relatório Dinâmico</a>
  </li>
  <li class="nav-item" (click)="setTabIndex(1)">
    <a href="#navpills-1" class="nav-link" data-toggle="tab" aria-expanded="false">Alerta Dinâmico</a>
  </li>
</ul>
<div class="tab-content br-n pn">
  <div id="navpills-0" class="tab-pane active" *ngIf="selectedIndex == 0">
    <app-report-builder>
    </app-report-builder>
  </div>
  <div id="navpills-1" class="tab-pane" *ngIf="selectedIndex == 1">

  </div>
</div>

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class StoreInstanceConfService {
  entities: any[] = [];

  icons: string[] = [
    'fa-solid fa-industry',
    'fa-solid fa-phone-volume',
    'fa-solid fa-address-card',
    'fa-solid fa-question',
    'fa-solid fa-bell',
    'fa-solid fa-radiation',
    'fa-solid fa-triangle-exclamation',
    'fa-solid fa-car',
    'fa-solid fa-truck',
    'fa-solid fa-gas-pump',
    'fa-solid fa-charging-station',
    'fa-solid fa-gauge-high',
    'fa-solid fa-globe',
    'fa-solid fa-moon',
    'fa-solid fa-shuttle-space',
    'fa-solid fa-satellite-dish',
    'fa-solid fa-house',
    'fa-solid fa-city',
    'fa-solid fa-tower-observation',
    'fa-solid fa-building',
    'fa-solid fa-house-fire',
    'fa-solid fa-book',
    'fa-solid fa-tag',
    'fa-solid fa-calendar-days',
    'fa-solid fa-paperclip',
    'fa-solid fa-envelope',
    'fa-solid fa-phone',
    'fa-solid fa-clipboard',
    'fa-solid fa-compass',
    'fa-solid fa-scale-balanced',
    'fa-solid fa-chart-pie',
    'fa-solid fa-chart-line',
    'fa-solid fa-chart-simple',
    'fa-solid fa-chart-gantt',
    'fa-solid fa-droplet',
    'fa-solid fa-wifi',
    'fa-solid fa-flask',
    'fa-solid fa-fire',
    'fa-solid fa-flag',
    'fa-solid fa-wrench',
    'fa-solid fa-recycle',
    'fa-regular fa-map',
    'fa-solid fa-lightbulb',
    'fa-solid fa-solar-panel',
    'fa-solid fa-plug-circle-check',
    'fa-solid fa-plug-circle-plus',
    'fa-solid fa-plug-circle-minus',
    'fa-solid fa-water',
    'fa-solid fa-seedling',
    'fa-solid fa-wind',
    'fa-solid fa-power-off',
    'fa-solid fa-battery-half',
    'fa-solid fa-square-check',
  ];

  items: any[] = [
    {
      label: 'Setor',
      alias: 'Setor',
      table: 'plant_sector',
      formName: 'plant_sector',
      key: 'SET',
      supkey: 'SET_1',
    },
    {
      label: 'Empresa',
      alias: 'Empresa',
      table: 'company',
      formName: 'company',
    },
    {
      label: 'Grupo',
      alias: 'Grupo',
      table: 'company_group',
      formName: 'company_group',
    },
    {
      label: 'Unidade',
      alias: 'Unidade',
      table: 'company_division',
      formName: 'company_division',
    },
    {
      label: 'Concessionárias',
      alias: 'Concessionárias',
      table: 'dealership',
      formName: 'dealership',
    },
    {
      label: 'Local de medição',
      alias: 'Local de medição',
      table: 'plant_point',
      formName: 'plant_point',
    },
    {
      label: 'Natureza',
      alias: 'Natureza',
      table: 'plant_equipment_nature',
      formName: 'plant_equipment_nature',
    },
    {
      label: 'IoT',
      alias: 'IoT',
      table: 'plant_equipment',
      formName: 'plant_equipment',
    },
    {
      label: 'Imagem',
      alias: 'Imagem',
      table: 'plant_equipment_image',
      formName: 'plant_equipment_image',
    },
    {
      label: 'Agrupador',
      alias: 'Agrupador',
      table: 'plant_equipment_buddle',
      formName: 'plant_equipment_buddle',
    },
    {
      label: 'Tipo',
      alias: 'Tipo',
      table: 'plant_equipment_type',
      formName: 'plant_equipment_type',
    },
    {
      label: 'Alarmes',
      alias: 'Alarmes',
      table: 'alert_config',
      formName: 'alert_config',
    },
    {
      label: 'Usuário',
      alias: 'Usuário',
      table: 'users',
      formName: 'users',
    },
    {
      label: 'Supervisórios',
      alias: 'Supervisórios',
      table: 'supervisory',
      formName: 'supervisory',
    },
  ];

  readerFullLogo: any;
  readerMiniLogo: any;

  sectorTabs: any[] = [];

  isLoading: boolean = false;
  hasInit: boolean = false;

  form: FormGroup;

  formTitles: FormGroup;

  tempColor: string = '#000';
  themeColors: any = {};

  graficColors: string[] = [];
  defaultColors: string[] = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#0099C6', '#DD4477'];

  defaultTheme: any = {
    color1: '#E83E8C',
    color2: '#000',
    color3: '#FFF',
    color4: '#c23d3d',
    color5: '#40ae2c',
  };

  stage: number = 1;

  isLoadedVariables: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: BootstrapModalAlertService,
    private authService: AuthService,
    private layout: LayoutService,
  ) {}

  initForm() {
    this.form = this.fb.group({
      initialPage: [''],
    });
  }

  onInitFormTitles() {
    this.formTitles = this.fb.group(
      this.items.reduce((acc, field) => {
        acc[field.formName] = [field.alias, Validators.required];
        return acc;
      }, {}),
    );

    this.formTitles.valueChanges.subscribe((changes) => {
      Object.keys(changes).forEach((key) => {
        const item = this.items.find((i) => i.formName === key);
        if (item) {
          item.alias = changes[key];
        }
      });
    });
  }

  onAddNewSectorLevel() {
    const totalSectors = this.items.filter((el) => el.table == 'plant_sector');

    totalSectors.map((el, index) => {
      if (el?.key == 'SET') {
        el.key = `SET_1`;

        const [_, value] = el.key.split('_');
        el.supkey = `SET_${Number(value) + 1}`;
        return;
      }

      if (el?.key?.includes('SET_')) {
        const [pref, value] = el?.key?.split('_');
        el.key = `SET_${Number(value) + 1}`;

        const [_, supValue] = el.supkey.split('_');
        el.supkey = `SET_${Number(supValue) + 1}`;
        return;
      }
    });

    const newItem = {
      label: `Setor nível ${totalSectors.length}`,
      alias: `Setor nível ${totalSectors.length}`,
      table: 'plant_sector',
      formName: `plant_sector_${totalSectors.length}`,
      recurrence: true,
      supkey: `SET_1`,
      key: `SET`,
    };

    this.items = [newItem, ...this.items];
    this.onInitFormTitles();
  }

  onRemoveSectorLevel(index: number) {
    this.items.splice(index, 1);

    const totalSectors = this.items.filter((el) => el.table == 'plant_sector');

    totalSectors.map((el, index) => {
      if (el?.key?.includes('SET_')) {
        const [pref, value] = el?.key?.split('_');

        if (Number(value) == 1) {
          el.key = `SET`;
          el.supkey = `SET_1`;
          return;
        }

        if (Number(value) > 1) {
          el.key = `SET_${Number(value) - 1}`;
          const [_, supValue] = el.supkey.split('_');
          el.supkey = `SET_${Number(supValue) - 1}`;
          return;
        }
      }
    });
  }

  // por exemplo, eu tenho um:
  // SET, SET_1
  // se eu adicionar um novo SET, os previamente existentes devem subir 1 nivel e o novo ser adicionado no começo?
  // no caso o novo set adicionado passa a ser o SET

  onChangeStage(stage: number) {
    this.stage = stage;
  }

  onResetTheme() {
    this.themeColors = this.defaultTheme;
    this.graficColors = this.defaultColors;
    this.layout.logoFull = window['whiteLabelConf']('navlogo');
    this.layout.logoMini = window['whiteLabelConf']('navlogo');

    this.readerFullLogo = null;
    this.readerMiniLogo = null;

    this.entities.map((entity) => {
      if (entity?.alias) {
        entity.alias = '';
      }

      if (entity?.icon) {
        entity.icon = '';
      }
    });

    this.onSubmit();
  }

  onConfirmReset() {
    const result$ = this.alertService.showConfirm(
      'Resetar tema da aplicação',
      'Você deseja resetar o tema da aplicação para o padrão Industry Care?',
    );

    result$.asObservable().subscribe((result) => {
      if (result) {
        this.onResetTheme();
      }
    });
  }

  onValidateSector() {
    const setor = this.items.filter(item => item.label == 'Setor');

    if(setor.length > 0) {
      setor[0] = {
        ...setor[0],
        key: 'SET',
        supkey: 'SET_1',
      }
    }
  }

  onSubmit() {
    this.isLoading = true;
    this.onValidateSector();

    let payload: any = {
      theme: this.themeColors,
      graficColors: this.graficColors,
      initialPage: this.form.get('initialPage').value,
      mainTitles: this.items,
    };

    if (this.readerFullLogo || this.readerMiniLogo) {
      payload = {
        logoFull: this.readerFullLogo,
        logoMini: this.readerMiniLogo,
        ...payload,
      };
    }

    this.authService
      .onInstanceConfig(payload)
      .subscribe(
        (data) => {
          const alias = this.entities.filter((item) => item.icon || item.alias);

          if (alias.length > 0) {
            this.authService
              .onSaveAlias({ aliasMap: alias })
              .subscribe((data) => {
                this.alertService.showAlertSuccess('Instancia configurada com sucesso!');

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              })
              .add(() => {
                this.isLoading = false;
              });
          }

          if (alias.length == 0) {
            this.alertService.showAlertSuccess('Instancia configurada com sucesso!');

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          if (error?.status == 413) {
            this.alertService.showAlertDanger('O Arquivo enviado excede os tamanho máximo');
          }
        },
      )
      .add(() => {});
  }
}

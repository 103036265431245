<div class="card mx-2">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center w-100" *ngIf="content.length > 0">
      <span *ngIf="showCount">Mostrando {{ pagination.offset + 1 }} até {{ pagination.limit <= pagination.total ? pagination.limit : pagination.total }} de {{ pagination.total }} entradas</span>
    
      <div class="d-flex tour-table-paginator">
        <button class="btn border radius" [disabled]="pagination.actualPage == 1" (click)="onPrevius()">Anterior</button>
        <button
          class="btn border radius"
          [class.btn-primary]="pagination.actualPage == item"
          *ngFor="let item of pagination.pages | slice : 0 : 3"
          (click)="onChangePage(item)"
        >
          {{ item }}
        </button>
        <ng-container *ngIf="pagination.pages.length > 3">
          <button class="btn border radius">...</button>
          <button class="btn btn-primary border radius" *ngIf="pagination.actualPage > 3 && pagination.actualPage != pagination.pages.length">{{ pagination.actualPage }}</button>
        </ng-container>

        <button
          class="btn border radius"
          [class.btn-primary]="pagination.actualPage == pagination.pages.length"
          *ngIf="pagination.pages.length > 3"
          (click)="onChangePage(pagination.pages.length)"
        >
          {{ pagination.pages.length }}
        </button>

        <button class="btn border radius" [disabled]="pagination.actualPage == pagination.pages.length" (click)="onNext()">
          Próximo
        </button>
      </div>
    </div>
    
  </div>
</div>
<ng-select *ngIf="itemlist && itemlist.length > 0"
  #selectref
  id="filter-select"
  name="filter-select"
  class="ic_selectcomponent"
  [items]="itemlist"
  bindLabel="name"
  placeholder="Selecione os filtros"
  appendTo="body"
  [multiple]="true"
  notFoundText = "Nenhum item encontrado"
  [clearOnBackspace] = "true"
  [ngModelOptions]="{standalone: true}"
  [(ngModel)]="selectedItemsCollection"
  (change)="onSelectItem($event)">
  <ng-template ng-header-tmp >
    <div >
      <button class="btn btn-link" (click)="onSelectAll(selectref)">Selecionar Todos</button>
      <button class="btn btn-link" (click)="onClearAll(selectref)">Limpar Seleção</button>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="custom-control custom-checkbox">
      <input type="checkbox"
        id="{{item.id}}_chk"
        name="{{item.id}}_chk"
        [ngModel]="item$.selected"
        class="custom-control-input"/>
      <label class="form-check-label ml-2 custom-control-label">{{item.name}}</label>
    </div>
    <hr class="solid">
  </ng-template>
</ng-select>

import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

/**
 * O componente 'HubComponent' é responsável pela seleção de empresas.
 *
 * Este componente gerencia a exibição de empresas disponíveis, permitindo que o usuário
 * selecione uma empresa específica para acessar o painel.
 */
@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.css']
})
export class HubComponent implements OnInit {
  // Recebe as informações relativas as empresas (mapping).
  mapping: any = {};

  // Recebe a empresa com suas respectivas informações .
  companies: any[] = [];

  // Recebe a lista das empresas cadastradas no banco de dados (Keys).
  source: any[] = [];

  // Empresa selecionada.
  selected = '';

  // Empresa selecionada juntamente de suas informações.
  selected_source = '';

  // Referência ao elemento DOM para o bloco de empresas.
  company_div: HTMLElement;

  //Configurações White Label
  logo_wl = window['whiteLabelConf']('logo');

  errorMsg: boolean = false;

  // Construtor para injetar as dependências necessárias.
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }


  ngOnInit(): void {
    // Carrega a lista de empresas.
    this.getCompanyList();

  }

  /**
   * Método para obter a lista de empresas disponíveis.
   * Obtém as empresas disponivies e suas respectivas informações.
   * Define as empresas iniciais com base na primeira informação disponível.
   */
  getCompanyList(): void {
    this.authService.getCompanyList().pipe(take(1)).subscribe(
      data => {
        this.source = data.keys;
        this.mapping = data.mapping;

        if (data.keys.length > 0) {
          this.companies = this.mapping[data.keys[0]];
        }
      },
      error => {
        console.error('Falha ao carregar empresas.', error.msg);
      }
    );
  }

  /**
   * Método para alterar a fonte selecionada de empresas.
   * Atualiza a lista de empresas com base na fonte selecionada.
   * Se houver apenas uma empresa, oculta o bloco de seleção de empresa e define a empresa automaticamente.
   */
  changeSource(): void {
    this.errorMsg = false;
    this.selected = null;
    this.companies = [];
    this.companies = this.mapping[this.selected_source];

    if(!this.companies) {
      this.errorMsg = true
      return;
    }

    if (this.companies.length === 1) {
      this.selected = this.companies[0].id;
    } else {
      this.selected = '';
    }
  }

  /**
   * Método para definir a empresa selecionada.
   * Define a empresa no serviço de autenticação e navega para o dashboard.
   */
  setCompany(): void {
    if (this.selected !== '') {
      const selectedCompany = this.companies.find(company => company.id == this.selected);

      if (selectedCompany) {
        this.authService.setPerson(selectedCompany);
        localStorage.removeItem('users_service');
        localStorage.removeItem('main_service');

        this.router.navigate(['/dashboard']);
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      }

    }
  }
}

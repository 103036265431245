<div class="tab-content container-fluid" style="min-height: 500px">

    <div class="row">
        <div class="col-8">
            <ng-container *ngIf="!scenery.editingName">
                <h1 class="header-title">
                    {{scenery.name}}
                    <span *ngIf="!isComparison" class="material-icons" style="float: none" (click)="editName(true)">edit</span>
                </h1>
            </ng-container>
            <ng-container *ngIf="scenery.editingName">
                <h1><input type="text" class="header-title" id="scenery-name" name="scenery-name"
                    (keyup)="$event.keyCode == 13 ? editName(false) : null"
                    (ngInit)="this.focus();" [(ngModel)]="scenery.name" #sceneryName >
                    <span id='editingName' class="material-icons" style="float: none" (click)="editName(true)" >edit</span>
                </h1>
            </ng-container>
        </div>
        <ng-container *ngIf="isComparison">
            <div class="col-2">
            </div>
            <div class="col-2">
                <button id="cancel-scenery" name="cancel-scenery" type="submit" style="width: 132px;"
                    class="btn btn-gray-light" routerLink="/panel-list/scenary" >
                    {{ 'Cancelar' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="!isComparison">
            <div class="col-2">
                <button id="cancel-scenery" name="cancel-scenery" type="submit" style="width: 132px;"
                    class="btn btn-gray-light" routerLink="/panel-list/scenary" >
                    {{ 'Cancelar' | translate }}
                </button>
            </div>
            <div class="col-2">
                <button id="save-scenery" name="save-scenery" type="submit" style="width: 132px;"
                    class="btn btn-black" (click)="saveScenery()">
                    {{ 'Salvar cenário' | translate }}
                </button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isComparison">
        <div class="card customreport_card">
            <div class="row">
                <div class="col-4">
                    <label>Unidade industrial do cenário</label>
                    <ng-select [items]="unities" bindLabel="name" [(ngModel)]="scenery.industrialUnity" #industrialUnity
                        (change)="filtrarCenarios()"></ng-select>
                </div>
                <div class="col-8" >
                    <label>Cenários para comparar</label>

                    <ng-select multiple="multiple" [(ngModel)]="sceneriesSelected" [items]="sceneriesFiltered" bindLabel="name" (change)="selecionarCenarios()" >
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" [ngModel]="item$.selected" class="custom-control-input" />
                                <label class="form-check-label ml-2 custom-control-label">{{item.name}}</label>
                            </div>
                            <hr class="solid">
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isComparison">
        <div class="card customreport_card">
            <div class="row">
                <div class="col-3 vertical-center">
                    <label>Unidade industrial do cenário</label>
                </div>
                <div class="col-4" >
                    <ng-select [items]="unities" bindLabel="name" [(ngModel)]="scenery.industrialUnity" #industrialUnity
                    (change)="filtrarLocais()"></ng-select>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngFor="let scenery of sceneriesCompared; let h = index">
        <ng-container *ngIf="isComparison">
            <div class="card customreport_card" style="margin-bottom: 1px; margin-top: 15px; font-weight: bold; font-size: 18px; border-radius: 0px;">
                {{scenery.name}}
                <ng-container *ngIf="!scenery.expanded">
                    <div class="col-1 expand-icon">
                        <span class="material-icons" (click)="expandSceneryMore(h)" style="float: right">
                            expand_more
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="scenery.expanded">
                    <div class="col-1 expand-icon">
                        <span class="material-icons" (click)="expandSceneryLess(h)" style="float: right">
                            expand_less
                        </span>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="scenery.expanded">
            <div *ngFor="let project of scenery.projects; let i = index" [attr.data-index]="i"
                [ngClass]="isComparison ? 'card customreport_card_no_margin': 'card customreport_card'">
                <ng-container *ngIf="project.expanded">
                    <div class="row">
                        <div class="col-11">
                            <h2 class="project-title">Projeto #{{i+1}}</h2>
                        </div>
                        <div class="col-1 expand-icon">
                            <span class="material-icons" (click)="expandLess(h, i)" style="float: right">
                                expand_less
                            </span>
                        </div>
                    </div>
                    <div class="row project-row">
                        <div class="col-4">
                            <label>Nome do projeto</label>
                            <input [attr.readonly]="isComparison ? 'readonly' : null" type="text" class="form-control mb-2 mr-sm-2" id="name{{i}}" name="name{{i}}" [(ngModel)]="project.name">
                        </div>
                        <div class="col-4">
                            <label>Local do projeto</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="filterLocations" bindLabel="name" [(ngModel)]="project.location"></ng-select>
                        </div>
                        <div class="col-4">
                            <label>Tipo de projeto</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="projectTypes" bindLabel="name" [(ngModel)]="project.projectType"></ng-select>
                        </div>
                    </div>
                    <div class="row project-row">
                        <div class="col-4">
                            <label>Impacto</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="energyImpacts" bindLabel="name" [(ngModel)]="project.energyImpact"></ng-select>
                        </div>
                        <div class="col-4">
                            <label>Valor do impacto</label>
                            <input [attr.readonly]="isComparison ? 'readonly' : null" type="number" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" class="form-control mb-2 mr-sm-2" id="valueImpact{{i}}" name="valueImpact{{i}}"
                                [(ngModel)]="project.valueImpact">
                        </div>
                        <div class="col-4">
                            <label>O impacto é</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="impactTypes" bindLabel="name" [(ngModel)]="project.impactType"></ng-select>
                        </div>
                    </div>
                    <div class="row project-row">
                        <div class="col-4">
                            <label>Frequência do impacto</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="impactValueTypes" bindLabel="name" [(ngModel)]="project.impactValueType"></ng-select>
                        </div>
                        <div class="col-4">
                            <label>Data inicial de vigência</label>
                            <input [attr.readonly]="isComparison ? 'readonly' : null" type="date" class="form-control" style="cursor: pointer;"
                                [value]="project.startPeriod | date:'yyyy-MM-dd'"
                                (input)="project.startPeriod=parseDate($event.target.value)" />
                        </div>
                        <div class="col-4">
                            <label>Data final de vigência</label>
                            <input [attr.readonly]="isComparison ? 'readonly' : null" type="date" class="form-control" style="cursor: pointer;"
                                [value]="project.endPeriod | date:'yyyy-MM-dd'"
                                (input)="project.endPeriod=parseDate($event.target.value)" />
                        </div>
                    </div>
                    <ng-container *ngIf="!isComparison">
                        <div class="row project-row">
                            <div class="col-8">
                            </div>
                            <div class="col-2">
                                <button id="remove-project" name="remove-project" type="submit" style="width: 132px;"
                                    class="btn btn-gray" (click)="removeProject(i)" >
                                    {{ 'Excluir' | translate }}
                                </button>
                            </div>
                            <div class="col-2">
                                <button id="create-new-scenery" name="create-new-scenery" type="submit" style="width: 132px;"
                                    class="btn btn-blue" (click)="expandLess(h, i)">
                                    {{ 'Salvar projeto' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!project.expanded">
                    <div class="row">
                        <div class="col-3 vertical-center">
                            <h2 class="project-title">Projeto #{{i+1}}</h2>
                        </div>
                        <div class="col-3">
                            <label>Nome do projeto</label>
                            <input type="text" [attr.readonly]="isComparison ? 'readonly' : null" class="form-control mb-2 mr-sm-2" id="name{{i}}" name="name{{i}}" [(ngModel)]="project.name">
                        </div>
                        <div class="col-2">
                            <label>Valor do impacto</label>
                            <input type="number" [attr.readonly]="isComparison ? 'readonly' : null" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" class="form-control mb-2 mr-sm-2" id="valueImpact{{i}}" name="valueImpact{{i}}"
                                [(ngModel)]="project.valueImpact" >
                        </div>
                        <div class="col-3">
                            <label>Tipo de projeto</label>
                            <ng-select [attr.readonly]="isComparison ? 'readonly' : null" [items]="projectTypes" bindLabel="name" [(ngModel)]="project.projectType"></ng-select>
                        </div>
                        <div class="col-1 expand-icon">
                            <span class="material-icons" (click)="expandMore(h, i)" style="float: right">
                                expand_more
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isComparison">
        <div class="row">
            <div class="col-2">
                <button id="compare-scenery" name="compare-scenery" type="submit" style="width: 132px; margin-top: 10px;"
                    class="btn btn-black" (click)="simulate()">
                    {{ 'Simular cenário' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isComparison">
        <div class="row">
            <div class="col-2">
                <button id="create-new-scenery" name="create-new-scenery" type="submit" style="width: 132px;"
                    class="btn btn-primary" (click)="addProject()">
                    {{ 'Adicionar projeto' | translate }}
                </button>
            </div>

            <div class="col-2" *ngIf="scenery.projects && scenery.projects.length > 0">
                <button id="compare-scenery" name="compare-scenery" type="submit" style="width: 132px;"
                    class="btn btn-black" (click)="simulate()">
                    {{ 'Simular cenário' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="scenery.projections && scenery.projections.length > 0">
        <div class="card customreport_card" style="margin-top: 15px">
            <div class="row">
                <ng-container *ngIf="isComparison">
                    <div class="col-3">
                        <label>Cenário para comparação</label>
                        <ng-select [items]="sceneriesCompared" bindLabel="name" [(ngModel)]="sceneryBase"
                            (change)="selecionarCenarioBase()"></ng-select>
                    </div>
                </ng-container>
                <div class="col-2">
                    <label>Ano base</label>
                    <ng-select [items]="baseYears" [(ngModel)]="scenery.baseYear" (click)="changeBaseYear()"
                        ></ng-select>
                </div>
                <div class="col-2">
                    <label>Ano comparação</label>
                    <ng-select [items]="comparisonYears" [(ngModel)]="scenery.comparisonYear"
                        (click)="updateProjectionTotals()" ></ng-select>
                </div>
                <ng-container *ngIf="isComparison">
                    <div class="col-6">
                    </div>
                </ng-container>
                <ng-container *ngIf="!isComparison">
                    <div class="col-8">
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-container *ngFor="let projection of scenery.projections;">
            <div class="row">
                <ng-container *ngFor="let proj of projection;">
                    <ng-container *ngIf="proj.total != null">
                        <div class="card customreport_card col shadow_card" style="margin: 10px;">
                            <div class="row">
                                <div class="col horizontal-center">{{proj.name}}</div>
                            </div>
                            <div class="row">
                                <div class="col horizontal-center" style="font-size: 30px; font-weight: bold; margin-top: 30px; margin-bottom: 30px;">
                                    <ng-container *ngIf="proj.total>0">
                                        <span class="material-icons" style="color: rgb(255, 50, 138);">north_east</span>
                                    </ng-container>
                                    <ng-container *ngIf="proj.total<=0">
                                        <span class="material-icons" style="color: rgb(38, 186, 1);">south_east</span>
                                    </ng-container>

                                    <ng-container *ngIf="proj.unity!='%'">{{proj.total | number : '1.0-0'}}{{proj.unity}}</ng-container>
                                    <ng-container *ngIf="proj.unity=='%'">{{proj.total | number : '1.2-2'}}{{proj.unity}}</ng-container>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="proj.total == null">
                        <div class="card customreport_card col shadow_card" style="margin: 10px;">
                            <div class="row">
                                <div class="col" style="font-weight: bold;">{{proj.name}}</div>
                                <ng-container *ngIf="proj.baseYear">
                                    <div class="col-3" style="float: right; text-align: right;">
                                        <ng-select [items]="baseYearsComparison" [(ngModel)]="proj.baseYear"
                                            (change)="updateComparisonGraphs()"></ng-select>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 4px;">
                                    {{proj.totalFormatted}}{{proj.unity}}
                                </div>
                            </div>
                            <div style="height: 84%; margin-top: 20px">
                                <canvas id="projection_graph_{{proj.id}}" ></canvas></div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="scenery.projections && scenery.projections.length > 0">
            <div class="card customreport_card shadow_card" style="margin-top: 25px; width: 100%;">
                <div class="row">
                    <div class="col" style="font-weight: bold; font-size: 16px; margin-bottom: 25px;">
                        Comparação com cenário atual
                    </div>
                </div>
                <div class="row" style="color: rgb(127, 127, 127); font-size: 10px">
                    <ng-container *ngIf="isComparison">
                        <div class="col">
                            <label>Cenário base</label>
                            <ng-select [items]="sceneriesCompared" bindLabel="name" [(ngModel)]="sceneryBase"
                                style="width:150px" (change)="selecionarCenarioBase()"></ng-select>
                        </div>
                    </ng-container>

                    <div class="col">
                        <label>Ano base</label>
                        <ng-select [items]="baseYears" [(ngModel)]="scenery.baseYear" (click)="changeBaseYear()"
                            style="width:100px"></ng-select>
                    </div>
                    <div class="col">
                        <label>Ano comparação</label>
                        <ng-select [items]="comparisonYears" [(ngModel)]="scenery.comparisonYear"
                            (click)="updateProjectionTotals()" style="width:100px"></ng-select>
                    </div>
                    <div class="col">
                        <label>Visão do periodo</label>
                        <ng-select [items]="impactValueTypes" bindLabel="name" [(ngModel)]="scenery.impactValueType"></ng-select>
                    </div>
                    <div class="col">
                        <label>Indicador de comparação</label>
                        <ng-select [items]="projectTypes" bindLabel="name" [(ngModel)]="scenery.projectType"
                            style="width:150px"></ng-select>
                    </div>
                    <div [ngClass]="isComparison ? 'col-4': 'col-6'">
                    </div>
                </div>

                <div class="row" style="margin-top: 30px;">
                    <div class="col">
                        <table style="width: 100%">
                            <tr style="background-color: rgb(244, 244, 244); color: rgb(127, 127, 127); font-size: 10px; height: 32px;">
                                <th class="horizontal-center">PERIODO</th>
                                <th class="horizontal-center">SE NADA MUDAR</th>
                                <ng-container *ngIf="!isComparison">
                                    <th class="horizontal-center">{{toUpperCase(scenery.name)}}</th>
                                </ng-container>
                                <ng-container *ngIf="isComparison && sceneryBase">
                                    <th class="horizontal-center">{{toUpperCase(sceneryBase.name)}}</th>
                                </ng-container>
                                <th class="horizontal-center">VARIAÇÃO</th>
                                <th class="horizontal-center" colspan="2">VARIAÇÃO %</th>
                            </tr>
                            <ng-container *ngIf="scenery.projectType">
                                <ng-container *ngIf="scenery.projectType.id==1">
                                    <ng-container *ngFor="let pd of scenery.projectionDetailsEnergy">
                                        <ng-container *ngIf="pd.baseYear >= scenery.baseYear && pd.baseYear <= scenery.comparisonYear">
                                            <tr>
                                                <td class="horizontal-center">{{pd.baseYear}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueifNothingChange)}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueScenery)}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueVariation)}}</td>
                                                <td class="horizontal-center" style="border-right: 0px; padding-right: 5px; text-align: right; width:80px;">
                                                    {{formatNumber(pd.valueVariationPercentage)}}%</td>
                                                <td class="horizontal-center vertical-center" style="border-left: 0px;  width:80px;">
                                                    <ng-container *ngIf="pd.valueVariationRaw>0">
                                                        <span class="material-icons" style="color: rgb(255, 50, 138); float: left;
                                                            padding-left: 5px; text-align: left;">north_east</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="pd.valueVariationRaw<=0">
                                                        <span class="material-icons" style="color: rgb(38, 186, 1); float: left;
                                                            padding-left: 5px; text-align: left;">south_east</span>
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="scenery.projectType.id==2">
                                    <ng-container *ngFor="let pd of scenery.projectionDetailsEmit" style="font-weight: bold;">
                                        <ng-container *ngIf="pd.baseYear >= scenery.baseYear && pd.baseYear <= scenery.comparisonYear">
                                            <tr>
                                                <td class="horizontal-center">{{pd.baseYear}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueifNothingChange)}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueScenery)}}</td>
                                                <td class="horizontal-center">{{formatNumber(pd.valueVariation)}}</td>
                                                <td class="horizontal-center" style="border-right: 0px; padding-right: 5px; text-align: right; width:80px;">
                                                    {{formatNumber(pd.valueVariationPercentage)}}%</td>
                                                <td class="horizontal-center vertical-center" style="border-left: 0px;  width:80px;">
                                                    <ng-container *ngIf="pd.valueVariation>0">
                                                        <span class="material-icons" style="color: rgb(255, 50, 138); float: left;
                                                            padding-left: 5px; text-align: left;">north_east</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="pd.valueVariation<=0">
                                                        <span class="material-icons" style="color: rgb(38, 186, 1); float: left;
                                                            padding-left: 5px; text-align: left;">south_east</span>
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>



<!DOCTYPE html>
<html>
<head>
<link rel="stylesheet" type="text/css" href="../status/status.component.css">
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>

</head>
<body>

<p><a href="#"><span class="glyphicon glyphicon-circle-arrow-left" id="return"></span></a></p>
<h1 id ="titulo">Status de Serviço</h1>
<br><br>

<table id="tabela">
  <tr>
    <th>Service</th>
    <th>Status</th>
  </tr>
  <tr>
    <td>Mainservice</td>
    <td>
      <div *ngIf="showMainService;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
  <tr>
    <td>Reposervice</td>
    <td>
      <div *ngIf="showRepoService;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  <tr>
    <td>Formservice</td>
    <td>
      <div *ngIf="showFormService;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
  <tr>
    <td>Userauthservice</td>
    <td>
      <div *ngIf="showUserAuth;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
  <tr>
    <td>Mailservice</td>
    <td>
      <div *ngIf="showMailService;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
  <tr>
    <td>Botservice</td>
    <td>
      <div *ngIf="showBotService;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
  <tr>
    <td>Simulationservice</td>
    <td>
      <div *ngIf="showSimulate;then validCondition else invalidCondition"></div>
        <div>
        <ng-template #validCondition><span class="glyphicon glyphicon-ok" id="iconeAlinhamentoCheck"></span></ng-template>
        <ng-template #invalidCondition><span class="glyphicon glyphicon-remove" id="iconeAlinhamentoNoCheck"></span></ng-template>
      </div>
  </td>
  </tr>
</table>

</body>
</html>



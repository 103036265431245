import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from './../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlantService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }
  httpOptionsUpload = {
    headers: new HttpHeaders({
      'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }
  params = new HttpParams();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getSectorAllList(url: string, company, key?: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company).set('key', key)
    });
  }

  getSectorAll(url: string, company, sector, key?: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company).set('sector', sector)
    });
  }

  createSector(sector): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/sector/create', sector, this.httpOptions);
  }

  updateSector(sector): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/sector/update/' + sector.plant_sector_id, sector, this.httpOptions);
  }

  deleteSector(id, key: string): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + `plant/sector/delete/${id}`, { ...this.httpOptions, params: { key } });
  }

  getSector(id, key?: string): Observable<any> {
    return this.http.get(this.authService.endpoint_main + `plant/sector/detail/${id}`, { ...this.httpOptions, params: { key: key } });
  }

  getSensorsByAutoComplete(key: string, dependsKey: string, dependsValue: number): Observable<any> {
    return this.http.get<any>(`${this.authService.endpoint_repo}autocomplete?key=${key}&depends[${dependsKey}]=${dependsValue}`, this.httpOptions)
  }

  onFindCustomFields(entity: string): Observable<any> {
    return this.http.get<any>(`${this.authService.endpoint_main}plant/entity-details/fields/${entity}`, this.httpOptions)
  }

  getPointAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }
  // point endpoints
  createPoint(point): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/point/create', point, this.httpOptions);
  }

  updatePoint(point): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/point/update/' + point.plant_point_id, point, this.httpOptions);
  }

  deletePoint(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/point/delete/' + id, this.httpOptions);
  }

  getPoint(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/point/detail/' + id, this.httpOptions);
  }

  // type endpoints
  getEquipmentTypeAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  createEquipmentType(equipmentType): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/equipmenttype/create', equipmentType, this.httpOptions);
  }

  updateEquipmentType(equipmentType): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/equipmenttype/update/' + equipmentType.plant_equipment_type_id, equipmentType, this.httpOptions);
  }

  deleteEquipmentType(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/equipmenttype/delete/' + id, this.httpOptions);
  }

  getEquipmentType(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/equipmenttype/detail/' + id, this.httpOptions);
  }

  // nature endpoints
  getEquipmentNatureAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  createEquipmentNature(equipmentType): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/equipmentnature/create', equipmentType, this.httpOptions);
  }

  updateEquipmentNature(equipmentType): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/equipmentnature/update/' + equipmentType.plant_equipment_nature_id, equipmentType, this.httpOptions);
  }

  deleteEquipmentNature(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/equipmentnature/delete/' + id, this.httpOptions);
  }

  getEquipmentNature(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/equipmentnature/detail/' + id, this.httpOptions);
  }

  // equipment endpoints
  getEquipmentAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  getEquipmentVirtual(id, url: string, company): Observable<any> {
    return this.http.get(url + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  createEquipment(equipment): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/equipment/create', equipment, this.httpOptions);
  }

  updateEquipment(equipment): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/equipment/update/' + equipment.plant_equipment_id, equipment, this.httpOptions);
  }

  deleteEquipment(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/equipment/delete/' + id, this.httpOptions);
  }

  getEquipment(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/equipment/detail/' + id, this.httpOptions);
  }

  // equipment endpoints
  getEquipmentImageAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  createEquipmentImage(equipment): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/equipmentimage/create', equipment, this.httpOptions);
  }

  updateEquipmentImage(equipment): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/equipmentimage/update/' + equipment.id, equipment, this.httpOptions);
  }

  deleteEquipmentImage(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/equipmentimage/delete/' + id, this.httpOptions);
  }

  getEquipmentImage(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/equipmentimage/detail/' + id, this.httpOptions);
  }

  createEquipmentImageUpload(formData, id): Observable<any> {
    let url = this.authService.endpoint_main + 'plant/equipmentimage/upload/' + id;
    return this.http.post(url, formData, this.httpOptionsUpload);
  }

  getEquipmentBundleAllList(url: string, company): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
  }

  createEquipmentBundle(equipmentbundle): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/equipmentbundle/create', equipmentbundle, this.httpOptions);
  }

  updateEquipmentBundle(equipmentbundle): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/equipmentbundle/update/' + equipmentbundle.id, equipmentbundle, this.httpOptions);
  }

  deleteEquipmentBundle(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/equipmentbundle/delete/' + id, this.httpOptions);
  }

  getEquipmentBundle(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/equipmentbundle/detail/' + id, this.httpOptions);
  }

  createAlarm(alarm): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'plant/alarm/create', alarm, this.httpOptions);
  }

  getAlarmList(url: string, company): Observable<any> {
    let auxiliar = this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify([this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson()]),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      }),
      params: this.params.set('company', company)
    });
    return auxiliar;
  }

  updateAlarm(alarm): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'plant/alarm/update/' + alarm.id, alarm, this.httpOptions);
  }

  deleteAlarm(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'plant/alarm/delete/' + id, this.httpOptions);
  }

  getAlarm(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'plant/alarm/detail/' + id, this.httpOptions);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ResetPasswordService } from '../reset-password.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  login = {
    email: '',
    password1: '',
    password2: '',
    emailChecked: false,
    token: ''
  };

  //Configurações White Label
  logo_wl = window['whiteLabelConf']('logo');
  bg_wl = window['whiteLabelConf']('bg_img');

  constructor(
    private route: ActivatedRoute,
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(take(1)).subscribe((param: ParamMap) => {
      this.login.token = param.get('token');
    });
  }

  checkEmail() {
    this.resetPasswordService.validateEmail(this.login.email, this.login.token).pipe(take(1)).subscribe(
      data => {
        if (data) {
          this.login.emailChecked = true;
          this.toastr.success('Email válido');
        } else {
          this.login.emailChecked = false;
          this.toastr.error('Email inválido');
        }
      },
      error => {
        this.toastr.error('Não foi possível verificar email');
      }
    )
  }


  confirm() {
    if (this.login.password1 === this.login.password2) {
      this.resetPasswordService.resetPassword(this.login.email, this.login.token, this.login.password1).pipe(take(1)).subscribe(
        data => {
          this.toastr.success('Senha cadastrada com sucesso!');
          this.router.navigate(['']);
        }, error => {

          if(error.error){
            alert(error.error.message);
          }
          else{
            this.toastr.error('Não foi possível cadastrar senha');
          }
        }
      )
    } else {
      this.toastr.warning('Senhas não conferem');
    }

  }
}

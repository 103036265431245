<ng-container *ngIf="tourMode == false; then true_block; else false_block">
</ng-container>
<ng-template #true_block>
    <button class='btn btn-default bthelp d-none' id='helpButton' title="Ajuda" (click)="openPopupHelp(null, '900', '600');"><i
            class="fa fa-question"></i></button>
</ng-template>
<ng-template #false_block>
    <button class='btn btn-default btn-circle bthelp d-none' type="button" id='helpButton' data-toggle="modal" data-target="#modal_help_tag"
        data-backdrop="false" style="margin-left: 10px;" title="Ajuda"><i class="fa fa-question"></i>
    </button>
</ng-template>

<!-- Modal -->
<div class="modal fade not_report" id="modal_help_tag" tabindex="-2000" role="dialog" aria-labelledby="helpButtonTitle">
    <div class="modal-dialog" role="document">
        <div class="modal-content box box-default box-solid">
            <div class="modal-header">
                <h4 class="modal-title">Sistema de Ajuda</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div>
                    <h5>Por favor selecione a forma como deseja acessar a ajuda do sistema.</h5>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="button" id="help_window_button" class="btn btn-primary mr-1" (click)="windowHelpMode(null, '900', '600');"
                        data-dismiss="modal">Janela</button>
                    <button type="button" id="help_tour_button" class="btn btn-primary mr-1" (click)="tourService.start()"
                        data-dismiss="modal">Tour</button>
                    <button type="button" id="help_speak_button" class="btn btn-primary" *ngIf="hasTTS" (click)="ttsHelpMode()"
                        data-dismiss="modal">Fale Comigo!</button>
                </div>
                <form id="checkbox-tour-form" style="display: none" >
                    <input type="checkbox" id="checkbox-tour" (change)="changeShowTour($event)">
                    <label for="checkbox-tour" class="tour-question">Deseja desativar o modo Tour ao iniciar a sessão ?</label>
                </form>
            </div>
        </div>
    </div>
</div>

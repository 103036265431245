import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PlantRoutingModule } from './plant-routing.module';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from '../shared/shared.module';

import { SectorComponent } from './sector/sector.component';
import { SectorReadComponent } from './sector/sector-read/sector-read.component';
import { SectorCreateComponent } from './sector/sector-create/sector-create.component';
import { SectorUpdateComponent } from './sector/sector-update/sector-update.component';

import { PointComponent } from './point/point.component';
import { PointReadComponent } from './point/point-read/point-read.component';
import { PointCreateComponent } from './point/point-create/point-create.component';
import { PointUpdateComponent } from './point/point-update/point-update.component';

import { TypeComponent } from './type/type.component';
import { TypeReadComponent } from './type/type-read/type-read.component';
import { TypeCreateComponent } from './type/type-create/type-create.component';
import { TypeUpdateComponent } from './type/type-update/type-update.component';

import { NatureComponent } from './nature/nature.component';
import { NatureReadComponent } from './nature/nature-read/nature-read.component';
import { NatureCreateComponent } from './nature/nature-create/nature-create.component';
import { NatureUpdateComponent } from './nature/nature-update/nature-update.component';

import { EquipmentComponent } from './equipment/equipment.component';
import { EquipmentReadComponent } from './equipment/equipment-read/equipment-read.component';
import { EquipmentCreateComponent } from './equipment/equipment-create/equipment-create.component';
import { EquipmentUpdateComponent } from './equipment/equipment-update/equipment-update.component';

import { EquipmentImageComponent } from './equipment-image/equipment-image.component';
import { EquipmentImageReadComponent } from './equipment-image/equipment-image-read/equipment-image-read.component';
import { EquipmentImageCreateComponent } from './equipment-image/equipment-image-create/equipment-image-create.component';
import { EquipmentImageUpdateComponent } from './equipment-image/equipment-image-update/equipment-image-update.component';

import { AlarmComponent } from './alarm/alarm.component';
import { AlarmCreateComponent } from './alarm/alarm-create/alarm-create.component';
import { AlarmReadComponent } from './alarm/alarm-read/alarm-read.component';
import { AlarmUpdateComponent } from './alarm/alarm-update/alarm-update.component';


import { BundlerComponent } from './bundler/bundler.component';
import { BundlerReadComponent } from './bundler/bundler-read/bundler-read.component';
import { BundlerCreateComponent } from './bundler/bundler-create/bundler-create.component';
import { BundlerUpdateComponent } from './bundler/bundler-update/bundler-update.component';


@NgModule({
  declarations: [
    SectorComponent,
    SectorReadComponent,
    SectorCreateComponent,
    SectorUpdateComponent,

    PointComponent,
    PointReadComponent,
    PointCreateComponent,
    PointUpdateComponent,

    TypeComponent,
    TypeReadComponent,
    TypeCreateComponent,
    TypeUpdateComponent,

    NatureComponent,
    NatureReadComponent,
    NatureCreateComponent,
    NatureUpdateComponent,

    EquipmentComponent,
    EquipmentReadComponent,
    EquipmentCreateComponent,
    EquipmentUpdateComponent,

    EquipmentImageComponent,
    EquipmentImageReadComponent,
    EquipmentImageCreateComponent,
    EquipmentImageUpdateComponent, 

    BundlerComponent,
    BundlerReadComponent,
    BundlerCreateComponent,
    BundlerUpdateComponent,

    AlarmComponent,
    AlarmCreateComponent,
    AlarmReadComponent,
    AlarmUpdateComponent,
  ],
  imports: [
    CommonModule,
    PlantRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    SectorComponent,
    SectorReadComponent,
    SectorCreateComponent,
    SectorUpdateComponent,

    PointComponent,
    PointCreateComponent,
    PointReadComponent,
    PointUpdateComponent,

    TypeComponent,
    TypeReadComponent,
    TypeCreateComponent,
    TypeUpdateComponent,

    NatureComponent,
    NatureReadComponent,
    NatureCreateComponent,
    NatureUpdateComponent,

    EquipmentComponent,
    EquipmentReadComponent,
    EquipmentCreateComponent,
    EquipmentUpdateComponent,

    EquipmentImageComponent,
    EquipmentImageReadComponent,
    EquipmentImageCreateComponent,
    EquipmentImageUpdateComponent,

    AlarmComponent,
    AlarmCreateComponent,
    AlarmReadComponent,
    AlarmUpdateComponent,

    BundlerComponent,
    BundlerReadComponent,
    BundlerCreateComponent,
    BundlerUpdateComponent,

  ]
})
export class PlantModule { }

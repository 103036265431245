import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ReportBuilderService {
  repoUrl = environment.repoUrl;
  mailUrl = environment.mailUrl;
  authUrl = environment.authApiUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  params = new HttpParams();
  constructor(private http: HttpClient,
    private authService: AuthService) { }

  shareReportWith(id, groups): Observable<any> {
    var self = this;
    var url = self.authUrl.endsWith('/') ? self.authUrl : self.authUrl + '/';
    const body = {id: id, groups: groups};
    return this.http.post(url + 'groups/shared', body,
      {headers: this.httpHeaders});
  }

  getReportBuilderConf(isDashboard): Observable<any> {
    var self = this;
    return this.http.get(self.repoUrl + 'get_report_builder',
      {headers: this.httpHeaders, params: this.params.set('t', (isDashboard ? '1' : '0') )});
  }

  getReportBuilderSave(content): Observable<any> {
    var self = this;
    const body = content;
    return this.http.post(self.repoUrl + 'save_report', body,
      {headers: this.httpHeaders});
  }

  getReportBuilderSearch(code): Observable<any> {
    var self = this;
    const body = {codi_rep: code};
    return this.http.post(self.repoUrl + 'search_report', body,
      {headers: this.httpHeaders});
  }

  getReportBuilderDelete(codi_rep): Observable<any> {
    var self = this;
    const body = {codi_rep: codi_rep};
    return this.http.post(self.repoUrl + 'delete_report', body,
      {headers: this.httpHeaders});
  }

  repoPublisher(forceSync): Observable<any> {
    var self = this;
    return this.http.post(self.repoUrl + 'repo/publisher', forceSync, {headers: this.httpHeaders});
  }

  repoPublisherStatus(): Observable<any> {
    var self = this;
    return this.http.post(self.repoUrl + 'repo/publisher_status', {headers: this.httpHeaders});
  }


  getNewsletterBuilderConf(): Observable<any> {
    return this.http.get(this.mailUrl + 'get_newsletter_builder',
      {headers: this.httpHeaders});
  }

  getNewsletterBuilderSave(content): Observable<any> {
    const body = content;
    return this.http.post(this.mailUrl + 'save_newsletter', body,
      {headers: this.httpHeaders});
  }

  getNewsletterBuilderSearch(code): Observable<any> {
    const body = {id: code};
    return this.http.post(this.mailUrl + 'search_newsletter', body,
      {headers: this.httpHeaders});
  }

  getNewsletterBuilderDelete(code): Observable<any> {
    const body = {id: code};
    return this.http.post(this.mailUrl + 'delete_newsletter', body,
      {headers: this.httpHeaders});
  }

  mailPublisher(forceSync): Observable<any> {
    return this.http.post(this.mailUrl + 'mail/publisher', forceSync, {headers: this.httpHeaders});
  }

  mailPublisherStatus(): Observable<any> {
    return this.http.post(this.mailUrl + 'mail/publisher_status', {headers: this.httpHeaders});
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { CompanyGroup } from '../../../shared/model/company-group.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para criar uma nova unidade empresarial.
 */
@Component({
  selector: 'app-company-division-create',
  templateUrl: './company-division-create.component.html',
  styleUrls: ['./company-division-create.component.css']
})
export class CompanyDivisionCreateComponent implements OnInit {

  /** Objeto representando a nova divisão de empresa. */
  division = {
    name: '',
    active: '',
    app_id: '',
    company_group_id: []
  };

  /** Lista de grupos de empresa disponíveis. */
  groups: CompanyGroup[];

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private companyService: CompanyService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e obtém a lista de grupos de empresa.
   */
  ngOnInit(): void {

    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('add_companydivision') == false) {
      this.router.navigate(['/403']);
    };

    // Obtém a lista de grupos de empresa disponíveis.
    this.companyService.getCompanyGroupAll(this.authService.endpoint_main + 'companygroups/nopage').pipe(take(1)).subscribe(
      data => {
        this.groups = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível listar grupos');
      }
    );
  }

  /**
 * Salva uma nova divisão de empresa.
 */
  save = () => {
    // Verifica se existe token.
    if (this.authService.hasToken()) {

      // Se o campo 'active' estiver vazio, define como 'false'.
      if (this.division.active == "") {
        this.division.active = 'false';
      }

      // Chama o serviço para criar a nova divisão de empresa.
      this.isLoading = true;
      this.companyService.createCompanyDivision(this.division).pipe(take(1)).subscribe(
        data => {
          // Atribui os dados retornados à propriedade 'division'.
          this.division = data;

          // Navega de volta à página anterior.
          this.location.back();

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Não foi possível incluir registro');
        }
      ).add(() => {
        scrollTo(0,0);
        this.isLoading = false;
      });
    } else {
      // Se não houver ID, exibe um alerta de perigo e faz logout.
      this.modalAlertService.showAlertDanger('Não foi possível incluir registro');
      this.authService.doLogout();
    }
  }

}

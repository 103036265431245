import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-customize',
  templateUrl: './person-container.component.html',
  styleUrls: ['./person-container.component.css'],
})
export class PersonContainerComponent implements OnInit {

  selectedIndex: number = 0;

  constructor(public layout: LayoutService) { }

  ngOnInit(): void {
    this.selectedIndex = 0;
  }

  setTabIndex(index: number) {
    this.selectedIndex = index;
  }
}

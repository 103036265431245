import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UsersProfilesService } from './../users-profiles.service';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { BootstrapModalConfirmService } from '../../shared/bootstrap-modal-confirm/bootstrap-modal-confirm.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { take, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users-profiles-read',
  templateUrl: './users-profiles-read.component.html',
  styleUrls: ['./users-profiles-read.component.css']
})
export class UsersProfilesReadComponent implements OnInit {

  profiles = [
    { id: '', name: '', person: '' },
  ]
  next: string;
  previous: string;

  deleteModalRef: BsModalRef;
  @ViewChild('deleteModal') deleteModal;
  selectedItem;

  constructor(
    private authService: AuthService,
    private usersProfilesService: UsersProfilesService,
    private modalService: BsModalService,
    private modalAlertService: BootstrapModalAlertService,
    private toastr: ToastrService,
    private modalConfirmService: BootstrapModalConfirmService,
    private router: Router
  ) { }

  ngOnInit(): void {

    // check if user is enable
    this.authService.isEnable();

    // check permission
    var is_mdb = this.authService && ( this.authService.getProfile().indexOf('2') != -1) && this.authService && ( ( this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1 ) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1 ) )

    var is_terceirizada = this.authService && ( this.authService.getProfile().indexOf('2') != -1) && this.authService && ( ( this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1 ) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1 ) )

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('view_profile') == false) {
      this.router.navigate(['/403']);
    };


    this.readAll(this.authService.endpoint + '/profiles')
  }

  readAll(url: string) {
    if(this.authService.hasPerson()){
      let person = this.authService.getPerson();
      this.usersProfilesService.getUsersProfiles(url, person).pipe(take(1)).subscribe(
        data => {
          this.profiles = data.results;
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          this.toastr.error('Não foi possível listar registros');
        }
      );
    }else{
      this.authService.doLogout();
    }

  }
  // function fetches the next paginated items by using the url in the next property
  fetchNext() {
    this.readAll(this.next);
  }
  // function fetches the previous paginated items by using the url in the previous property
  fetchPrevious() {
    this.readAll(this.previous);
  }

  readId = (id) => {
    this.router.navigate(['/profiles/update', id]);
  }

  create = () => {
    this.router.navigate(['/profiles/create']);
  }


  onDelete = (item) => {
    this.selectedItem = item;
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este perfil?'
    );
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.usersProfilesService.deleteUsersProfiles(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          // this.deleteList(perfil.id);
          let index;
          this.profiles.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.profiles.splice(index, 1);
          this.toastr.success('Registro removido com sucesso.');
        },
        error => {
          this.toastr.error('Erro ao tentar excluir.');
        }
      );
  }

}

import { AfterViewInit, Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Report } from 'src/app/entities/report';
import { DataService } from '../../shared/data.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.css']
})
export class ReportContainerComponent implements OnInit, DoCheck {

  reportId: any;
  navName: string;
  tabs: Report[];
  selectedIndex: number = 0;
  wrappedTabs = [];
  areWrappedTabsAvailable: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    public layout: LayoutService,
    private router: Router) {
    this.reportId = this.route.snapshot.params.id;
  }

  ngDoCheck(): void {
    this.onResize()
  }

  ngOnInit(): void {
    this.onVerifyUrl()
  }

  onVerifyUrl() {
    this.route.params.subscribe(({ id }: any) => { 
      this.reportId = id;
      this.onFindData()
    });
  }
  
  onFindData() {
    // Buscando no "repositório compartilhado" entre telas
    this.dataService.tabs.subscribe(param => {
      if (param) {
        const folders: Report[] = param[0].Folders;
        const items: Report[] = param[0].Items;

        if (folders && Object.keys(folders).length > 0) {

          const mapped = Object.keys(folders).map(key => ({ type: key, value: folders[key] }));
          this.navName = mapped[0].type;

          mapped.forEach(element => {
            const codi_rep = element.value.map(key => key.codi_rep == this.reportId);
            if (codi_rep[0]) {
              this.tabs = element.value;
            }
          });
        }

        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].codi_rep == this.reportId) {
              this.tabs = [items[i]];
            }
          }
        }

        if (this.reportId == 'temporary') {
          this.tabs = [{ codi_rep: this.navName, codi_usu: null, company: null, desc_rep: this.navName, fgrp_rep: '', flab_rep: 'icon:far fa-calendar-alt', ftyp_rep: 'DATEBTW', nome_rep: 'Espelho', report_db: null }]
        }

        this.onResize()
      }
    });
  }

  setTabIndex(index: number, wrappedIndex?: string) {
    if (wrappedIndex) {
      const extractedNumbers = this.extractNumbersFromString(wrappedIndex);
      this.selectedIndex = this.convertFirstNumberToInteger(extractedNumbers);
    } else {
      this.selectedIndex = index;
    }

    this.navigateToCurrentTab();
  }

  detectWrap(className) {
    var wrappedItems = [];
    var firstItem;
    var currItem;
    var items = document.getElementsByClassName(className);

    for (var i = 0; i < items.length; i++) {
      items[i].className = 'collapsable-tab';
    }

    for (var i = 0; i < items.length; i++) {
      currItem = items[i].getBoundingClientRect();
      if (i == 0) {
        firstItem = currItem;
      } else if (firstItem && firstItem.top < currItem.top) {
        wrappedItems.push(items[i]);
      }
    };
    if (wrappedItems.length > 0) {
      wrappedItems.push(items[items.length - wrappedItems.length - 1]);
    }

    this.areWrappedTabsAvailable = this.wrappedTabs.length > 0;

    return wrappedItems;
  }

  onResize() {
    this.wrappedTabs = this.detectWrap('collapsable-tab');
    for (var k = 0; k < this.wrappedTabs.length; k++) {
      this.wrappedTabs[k].className = "collapsable-tab wrapped";
      this.wrappedTabs[k].href = this.wrappedTabs[k].innerHTML.split('href')[1].split('>')[0].replaceAll('"', '').replace('=', '');
    }
  };


  private extractNumbersFromString(str: string): string[] | null {
    const regex = /\b\d+\.\d+|\d+\b/g;
    return str.match(regex);
  }

  private convertFirstNumberToInteger(numbers: string[] | null): number {
    if (numbers && numbers.length > 0) {
      return parseInt(numbers[0]);
    }
    return 0;
  }

  private navigateToCurrentTab() {
    const currentTab = this.tabs[this.selectedIndex];
    this.router.navigate([`/custom-report/${currentTab.codi_rep}`]);
  }
}

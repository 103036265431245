<router-outlet></router-outlet>

<app-company-switcher></app-company-switcher>
<div id="snack-modal" class="modal fade" tabindex="" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="snack-modal-title"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="hideSnackbarModal()">×</button>
      </div>
      <div class="modal-body" id="snack-modal-message"></div>
        <div class="modal-footer">
          <button type="button" id="snack-modal-close" class="btn btn-primary waves-effect" data-dismiss="modal" (click)="hideSnackbarModal()">OK</button>
        </div>
      </div>
    </div>
</div>
<ngx-guided-tour [skipText]="'Fechar'" [backText]="'Voltar'" [nextText]="'Próximo'" [doneText]="'Concluído'"></ngx-guided-tour>

<div id="snackbar" style="z-index: 9999999999999;"></div>

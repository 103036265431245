import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnInit {
  @Input() values: any[] = [];

  @Input() selectedValues: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }
  
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { UsersProfilesService } from '../users-profiles.service';

@Component({
  selector: 'app-users-profiles-update',
  templateUrl: './users-profiles-update.component.html',
  styleUrls: ['./users-profiles-update.component.css']
})
export class UsersProfilesUpdateComponent implements OnInit {
  selectedItem = { id: '', name: '', person: '' };

  constructor(
    private route: ActivatedRoute,
    private usersProfilesService: UsersProfilesService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // check if user is enable
    this.authService.isEnable();

    // check permission
    // tslint:disable-next-line:triple-equals
    var is_mdb = this.authService && ( this.authService.getProfile().indexOf('2') != -1) && this.authService && ( ( this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1 ) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1 ) )

    var is_terceirizada = this.authService && ( this.authService.getProfile().indexOf('2') != -1) && this.authService && ( ( this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1 ) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1 ) )

    if (is_mdb ==false && is_terceirizada == false &&this.authService.checkPermission('change_profile') == false) {
      this.router.navigate(['/403']);
    }

    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = parseInt(param.get('id'), 10);
      this.read(id);
    });
  }

  read = (id) => {
    this.usersProfilesService.detailUserProfile(id).subscribe(
      data => {
        this.selectedItem = data;
      },
      error => {
        console.log('Aconteceu um erro', error.message);
      }
    );
  }

  update = () => {
    // tslint:disable-next-line:triple-equals
    if (this.authService.hasPerson() == true) {
      this.usersProfilesService.updateUsersProfiles(this.selectedItem).subscribe(
        data => {
          this.selectedItem = data;
          this.modalAlertService.showAlertSuccess('Registro alterado com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao alterar o registro');
        }
      );
    } else {
      this.modalAlertService.showAlertDanger('Erro ao alterar o registro');
      this.authService.clearAll();
      this.authService.doLogout();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Global } from '../../shared/base-func/global';
import { CrudBuilderService } from './crud-builder.service';
import { BaseFunc } from '../../shared/base-func/base-func';

import { AuthService } from './../../../auth/auth.service';

import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { take } from 'rxjs/operators';
import { UsersGroupsService } from '../../users-groups/users-groups.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HowToGuideService } from 'src/app/core/services/how-to-guide/how-to-guide.service';
import { GuidedTour, Orientation } from 'ngx-guided-tour';


@Component({
  selector: 'app-crud-builder',
  templateUrl: './crud-builder.component.html',
  styleUrls: ['./crud-builder.component.css']
})
export class CrudBuilderComponent extends BaseFunc implements OnInit {

  crudId: string; // variável alimentada quando tela é chamada pela opção "editar"

  // filters;
  autocompletes;
  global: Global = new Global();
  range = Array(100).fill(0).map((v,i)=>++i);
  loading_data = false;

  reportFields: any[];
  field_types = {
    "DATA_WITHOUT_HOURS": "Data", "DATA": "Data e Hora", "TEXT": "Texto", "HOUR": "Hora",
    "IMAGE": "Imagem", "FILE": "Arquivo", "TEXT_AREA": "Área de Texto", "NINT32": "Número Natural",
    "NINT64": "Número Natural Longo", "IINT32": "Número Inteiro", "IINT64": "Número Inteiro Longo",
    "FLOAT": "Número de Real", "SELECT": "Seleção", "CHECKBOX": "Checkbox", "RADIO": "Radio", "AUTOCOMPLETE": "Autocompletável"
  };
  autocomplete_dict: any;
  base_field: any = {
    'id': null,
    'name': '',
    'description': '',
    'default_value': null,
    'type': 'TEXT',
    'items': null,
    'itemName': null,
    'obg': false,
    'autocomplete_type': null,
    'limit': 'ilimitado',
    'unique': false,
    'size': 12
  };
  fields: any = [];
  field: any = Object.assign({}, this.base_field);

  toServer = { 'codi_crd': null, 'name': '', 'group': '', 'scope': 'user', 'type': 'DEFAULT', 'fields': this.fields, 'creationDate': null }

  scopes: any[] = [];
  scopesWithPermissions: any[] = [];
  filteredScopesWithPermissions: any[] = [];
  selectedScope: any;
  showAll: boolean = false;
  pushedScopeNames: { id: number, name: string }[] = [];

  form: FormGroup;

  tourSequence: GuidedTour = {
    tourId: 'crud-builder',
    steps: [
      {
        title: 'Nome do formulário',
        selector: '.tour-form-name',
        content: 'Por aqui você adiciona o nome do formulário que você deseja criar.',
        orientation: Orientation.Bottom,
        action: () => {},
      },
      {
        title: 'Grupo do formulário',
        selector: '.tour-form-group',
        content: 'Por aqui você pode agrupar os formulários previamente criados.',
        orientation: Orientation.Bottom,
      },
      {
        title: 'Permissões do formulário',
        selector: '.tour-form-scope',
        content: 'Agora você deve selecionar quais perfis poderão ver esse formulário.',
        orientation: Orientation.Bottom,
        closeAction: () => {
          const select = document.getElementsByClassName('custom-select')[0] as HTMLElement;
          select.click();
        },
        skipStep: !this.authService.isSuperUser(),
      },
      {
        title: 'Permissões do formulário',
        selector: '.tour-form-permissions',
        content: 'Por aqui você escolhe para quais grupos de permissões esse formulário ficará disponível.',
        orientation: Orientation.Left,
        action: () => {},
        skipStep: !this.authService.isSuperUser(),
      },
      {
        title: 'Filtrar permissões',
        selector: '.tour-form-scope-search',
        content: 'Você pode filtrar os perfils pelo nome.',
        orientation: Orientation.Bottom,
        action: () => {},
        skipStep: !this.authService.isSuperUser(),
      },
      {
        title: 'Exibir / Esconder permissões',
        selector: '.tour-form-scope-folder',
        content:
          'Aqui você pode exibir ou esconder todas permissões, assim como visualizar quantas foram selecionadas.',
        orientation: Orientation.Left,
        skipStep: !this.authService.isSuperUser(),
      },
      {
        title: 'Selecionar permissões',
        selector: '.tour-form-scope-items',
        content: 'Aqui você seleciona qual permissão será atribuida ao perfil selecionado.',
        orientation: Orientation.Bottom,
        skipStep: !this.authService.isSuperUser(),
        action: () => {
          const folders = document.getElementsByClassName('tour-form-open')[0] as HTMLElement;
          folders.click();
        },
        closeAction: () => {
          const btnClose = document.getElementsByClassName('tour-scope-close')[0] as HTMLElement;
          btnClose.click();
        },
      },
      {
        title: 'Adicionar campos ao formulário',
        selector: '.tour-form-new-field',
        content: 'Cique aqui para adicionar campos ao seu formulário.',
        orientation: Orientation.Top,
        closeAction: () => {
          const btnOpen = document.getElementsByClassName('tour-form-new-field')[0] as HTMLElement;
          btnOpen.click();
        },
      },
      {
        title: 'Nome do campo',
        selector: '.tour-form-configuration-name',
        content: 'Aqui você define o nome do campo.',
        orientation: Orientation.Left,
        action: () => {
          const tag = document.getElementsByClassName('tour-form-configuration-name')[0] as HTMLElement;
        },
      },
      {
        title: 'Tipo do valor do campo',
        selector: '.tour-form-configuration-type',
        content: 'Nesse seletor é possível escolher qual tipo o campo vai receber.',
        orientation: Orientation.Left,
      },
      {
        title: 'Propriedades do campo',
        selector: '.tour-form-configuration-switchers',
        content: 'Defina se o campo é obrigatório e/ou único.',
        orientation: Orientation.Left,
      },
      {
        title: 'Descrição do campo',
        selector: '.tour-form-configuration-desc',
        content:
          'Informe uma descrição sobre o campo que você está criando, é essencial descrever de forma clara, isso vai facilitar o entendimento do usuário.',
        orientation: Orientation.Left,
      },
      {
        title: 'Descrição do campo',
        selector: '.tour-form-configuration-size',
        content: 'Aqui você define o tamanho que o campo vai ocupar na tela durante sua exibição.',
        orientation: Orientation.Left,
      },
      {
        title: 'Salvar alterações',
        selector: '.tour-form-configuration-save',
        content: 'Salve para que o campo possa ser adicionado ao formulário.',
        orientation: Orientation.Bottom,
        closeAction: () => {
          const tag = document.getElementsByClassName('tour-form-configuration-close')[0] as HTMLElement;
          tag.click();
        },
      },
      {
        title: 'Tudo pronto!',
        selector: '.tour-form-created-fields',
        content:
          'Após serem criados, os campos ficarão disponíveis aqui. Você pode repetir esse processo e adicionar quantos campos forem necessários.',
        orientation: Orientation.Top,
      },
    ],
  };

  constructor(public authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private crudBuilderService: CrudBuilderService,
    private route: ActivatedRoute,
    private usersGroupsService: UsersGroupsService,
    private fb: FormBuilder,
    private router: Router) {
    super();
    this.crudId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.initForm();
    this.read_config_data();
    this.onFindUserGroups();
  }

  initForm() {
    this.form = this.fb.group({
      search: ['']
    })
  }

  onCloseFolders() {
    this.form.reset();
    this.filteredScopesWithPermissions = this.scopesWithPermissions;
    this.selectedScope = null;
    this.showAll = false;
  }

  onSelectScope(scopeId: number) {
    const scope = this.scopesWithPermissions.filter(scop => scop.id == scopeId)[0];
    this.selectedScope = scope;
    this.showAll = true;
  }

  onFindUserGroups() {
    const url = `${this.authService.endpoint}/groups`
    this.usersGroupsService.getUsersGroups(url).subscribe((data) => {
      this.onMountScopes(data.results);
    }).add(() => {})
  }

  onMountScopes(scopes: any[]) {
    const { id } = this.route.params['_value']
    scopes.map((scop) => {
      const scopeWithPermission = { 
        ...scop, 
        permissions: [
          {
            name: id ? `add_form_${id}` : 'add_form_',
            label: 'Adicionar',
            checked: false,
          },
          {
            name: id ? `change_form_${id}` : 'change_form_',
            label: 'Alterar',
            checked: false,
          },
          {
            name: id ? `view_form_${id}` :'view_form_',
            label: 'Visualizar',
            checked: false,
          },
          {
            name: id ? `delete_form_${id}` : 'delete_form_',
            label: 'Excluir',
            checked: false,
          },
        ]
      }
      this.scopesWithPermissions.push(scopeWithPermission);
    })
    
    this.filteredScopesWithPermissions = this.scopesWithPermissions;
  }

  onFillPreviusSelectedScope(data: { id: number, name: string }) {
    const scopeExists = this.pushedScopeNames.filter((el) => el.id === data.id);

    if(scopeExists.length == 0) {
      this.pushedScopeNames.push(data);
    }
    console.log(this.pushedScopeNames);
    
  }

  pushSelectedToCustomSelect(data: any) {
    const scopeHasPushed = this.pushedScopeNames.filter((el: any) => el.id === data.id);
    
    if(data.values.length > 0 && scopeHasPushed.length == 0) {
      this.pushedScopeNames.push({ id: data.id, name:  data.name })
    }

    if(data.values.length == 0 && scopeHasPushed.length > 0) {
      this.pushedScopeNames = this.pushedScopeNames.filter((el: any) => el.id !== data.id)
    }
  }

  onHandleSelect() {
    if(this.pushedScopeNames.length == 0) {
      this.showAll = !this.showAll
    }

    if(this.pushedScopeNames.length > 0) {
      this.showAll = true;
    }
  }

  onRemoveSingle(id: number) {
    this.pushedScopeNames = this.pushedScopeNames.filter(el => el.id !== id);
    const scope = this.scopesWithPermissions.filter(scp => scp.id === id)[0];
    
    scope.permissions.map((per) => per.checked = false)
  }

  onFilter() {
    const scope = this.form.get('search').value;
    this.filteredScopesWithPermissions = this.scopesWithPermissions.filter(scp => scp.name.toLowerCase().includes(scope.toLowerCase()));
  }

  onUpdatePermissions(id: number) {
    const { id: paramId } = this.route.params['_value']

    const permissions = []
    this.scopesWithPermissions.map((el) => {
      el.permissions.map(per => { 
        if(per.checked) {
          permissions.push({
            name: paramId ? per.name : `${per.name}${id}`,
            id: el.id,
            label: el.name
          }) 
        }
      })
    });

    const groupedPermissions = permissions.reduce((acc, permission) => {
      const existingPermission = acc.find((p) => p.id === permission.id);
  
      if (existingPermission) {
          existingPermission.permissions.push(permission.name);
      } else {
          acc.push({ id: permission.id, permissions: [permission.name] });
      }
  
      return acc;
    }, []);

    const payload = {
      form_id: id,
      name: this.toServer.name,
      group_permissions: groupedPermissions
    }

    this.crudBuilderService.onShareWithScope(payload).subscribe(() => {
      this.router.navigate(['panel-list/crud']);
    })
  }

  read_config_data = () => {
    var self = this;
    self.loading_data = true;
    this.crudBuilderService.getCRUDBuilderConf(true).pipe(take(1)).subscribe(
      data => {
        // self.filters = data.filters;

        
        self.autocompletes = data.autocompletes;
        self.reportFields = data.autocompletes.map(x => x.id)
        self.autocomplete_dict = Object.assign({}, ...data.autocompletes.map(c => ({[c.id]: c.label})) );

        // Se esta tela foi chamada com opção de "editar"
        if (this.crudId) {
          self.loading_data = true;
          setTimeout(function(){
            self.searchCrud(self.crudId);
          }, 1000);
        }
        else
          self.loading_data = false;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Aconteceu um erro inesperado<br/><ul><li>" + error.message + '</li></ul>');
      },
      () => {
      }
    );
  }

  onFillScope(groups: any[]) {
    // const groups = [
    //   {
    //     id: 19,
    //     name: "Gisely Oliveira - teste 1103 1400",
    //     permissions: [
    //         "add_form_5000013",
    //         "change_form_5000013",
    //         "view_form_5000013",
    //         "delete_form_5000013"
    //     ]
    //   },
    // ]

    groups.map((el) => {
      const scope = this.filteredScopesWithPermissions.filter((sc) => sc.id == el.id)[0];

      el.permissions.map((per) => {
        const permissionExistis = scope.permissions.filter((sp) => sp.name == per);
        if(permissionExistis.length > 0) {
          permissionExistis[0].checked = true;
        }
      })
    })
  }

  searchCrud(code) {
    var self = this;
    this.crudBuilderService.getCrudBuilderSearch(code).pipe(take(1)).subscribe(
      data => {

        this.onFillScope(data.group_permissions);
        console.log(data.group_permissions);
        console.log(this.filteredScopesWithPermissions);
        
        self.toServer.codi_crd = code;
        self.toServer.name = data.name;
        self.toServer.group = data.group;
        self.toServer.type = data.type;
        self.toServer.fields = data.fields;
        self.toServer.scope = data.scope;

        self.fields = data.fields;

        self.fields.forEach(x => x.limit = x.limit.toString() );
        self.loading_data = false;
        if ( parseInt(data.num_registers) > 0 ) {
          self.modalAlertService.showAlertDanger("Não é possível editar um formulário que possui registros salvos.<br/>Por favor apague os registros desse formulário para poder editar esse formulário");
          setTimeout(function() {
            self.router.navigate(['panel-list/crud']);
          }, 2000);
        }
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Aconteceu um erro inesperado<br/><ul><li>" + error.message + '</li></ul>');
      },
      () => {
      }
    );
  }

  validateToServer(type): boolean {
    var self = this;
    var messages = [];
    if (type == 'server') {
      if( self.toServer.name.trim() == '' )
        messages.push('Nome do formulário é obrigatório');
      if( self.toServer.fields.length == 0 )
        messages.push('Pelo menos um campo deve ser adicionado ao formulário');
    }
    else {
      if ( self.field.name.trim() == '' )
        messages.push('Nome do campo é obrigatório');

      if ( self.field.type == 'AUTOCOMPLETE' ) {
        var autocompletes = self.fields.filter(x => x.type == 'AUTOCOMPLETE').map(x => x.autocomplete_type );
        if ( self.field.autocomplete_type == null )
          messages.push('Tipo do autocomplete é obrigatório');
        else if ( self.field.autocomplete_type != null && autocompletes.includes(self.field.autocomplete_type) )
          messages.push('O tipo do autocomplete já foi adicionado ao formulário. Não é possível ter dois campos autocompletáveis iguais.');
      }

      if ( ['RADIO', 'CHECKBOX', 'SELECT'].includes(self.field.type) && self.field.items != null && self.field.items.length == 0 )
        messages.push('Pelo menos uma opção deve ser adicionada a lista');
    }

    if (messages.length > 0) {
      self.modalAlertService.showAlertDanger("Falha ao validar o dado<br/><ul>" + messages.map(x => '<li>' + x + '</li>').join('') + '</ul>');
      return false;
    }

    return true;
  }

  convertField(fd): any {
    return {
      'label': fd.name,
      'type': fd.type != 'AUTOCOMPLETE' ? fd.type : fd.autocomplete_type,
      'is_autocomplete': fd.type == 'AUTOCOMPLETE',
      'default_value': fd.default_value != null ? fd.default_value.text : null,
      'obg': fd.obg,
      'unique': fd.unique,
      'limit': fd.limit != 'ilimitado' ? parseInt(fd.limit) : null,
      'items': fd.items != null ? fd.items.map(x=>[x.text]) : [],
      'count': Math.round(1 + Math.random() * 100000000),
      'dom_id':  fd.type == 'AUTOCOMPLETE' ? "select" + fd.type: fd.type,
      'icon': ""
    };
  }

  showItemDiv(): void {
    this.field.itemName = '';
  }

  editField(fd): void {
    var self = this;
    self.field = Object.assign({}, fd);
  }

  showConfBar(): void {
    var self = this;
    self.field = Object.assign({}, self.base_field);
  }

  addItem(event): void {
    var self = this;
    if( event.target.value != '') {
      self.field.items.push({ 'id': self.global.createGuid(), 'text': event.target.value });
      self.field.default_value = ( self.field.default_value == null ? self.field.items[0] : self.field.default_value );
      self.field.itemName = null;
    }
  }

  removeItem(id): void {
    var self = this;
    self.field.items = self.field.items.filter(x => x.id != id);
  }

  changeDefault(event):void {
    var self = this;
    self.field.default_value = self.field.items.filter(x => x.id == event.target.value)[0];
  }

  changeType(event):void {
    var self = this;
    if (['RADIO', 'CHECKBOX', 'SELECT'].includes(event.target.value))
      self.field.items = [];
    else{
      self.field.items = null;
      self.field.autocomplete_type = null;
      self.field.limit = 'ilimitado';
    }
  }

  saveField():void {
    var self = this;

    if( !self.validateToServer('field') ) return;

    if( self.field.id == null ) {
      self.field.id = self.global.createGuid();
      self.fields.push( Object.assign({}, self.field) );
    }
    else
      self.fields[ self.fields.findIndex(x=> x.id == self.field.id) ] = Object.assign({}, self.field);

    self.$j('#right-bar-modal-close').trigger('click');
  }

  duplicateField(i): void {
    var self = this;
    var fd = Object.assign({}, self.fields[i]);
    fd.id = self.global.createGuid();
    fd.name = 'Cópia de ' + fd.name;
    self.fields.push( fd );
    self.showSnack('Campo ' + self.fields[i].name + ' duplicado com sucesso!');
  }

  moveUpField(i): void {
    var self = this;
    var current = Object.assign({}, self.fields[i]);
    var newPosition = Object.assign({}, self.fields[i-1]);
    self.fields[i] = newPosition;
    self.fields[i-1] = current;
  }

  moveDownField(i): void {
    var self = this;
    var current = Object.assign({}, self.fields[i]);
    var newPosition = Object.assign({}, self.fields[i+1]);
    self.fields[i] = newPosition;
    self.fields[i+1] = current;
  }

  excludeField(i): void {
    var self = this;
    var name = self.fields[i].name;
    self.fields = self.fields.filter((num, index) => index !== i);
    self.showSnack('Campo ' + name + ' excluído com sucesso!');
  }

  saveCrud = () => {
    //Valida o relatório
    var self = this;
    if(!self.validateToServer('server'))
      return;


    self.loading_data = true;
    self.toServer.fields = self.fields;
    self.toServer.creationDate = new Date();
    
    this.crudBuilderService.getCrudBuilderSave(self.toServer).pipe(take(1)).subscribe(
      data => {
        console.log(data);
        this.onUpdatePermissions(data.id);
        
        self.loading_data = false;
        this.showSnackBeforeReload("Formulário salvo com sucesso!");
        this.router.navigate(['panel-list/crud']);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
        self.loading_data = false;
        if ( !error.error.ic_exception || ( error.error.ic_exception && error.error.user_message == null ) )
          self.modalAlertService.showAlertDanger("Aconteceu um erro inesperado<br/><ul><li>" + error.message + '</li></ul>');
      },
      () => {
      }
    );
  }

  cancelCrud = () => {
    this.router.navigate(['panel-list/crud']);
  }

  returnZero() {
    return 0
  }

}

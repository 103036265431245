<div id="upload_panel_header_filters" name="upload_panel_header_filters" class="container-fluid" >
  <div class="row">
    <div class="col-12">
      <div class="card customreport_card">

        <form #f="ngForm" id="headerFormRep_" name="headerFormRep_" class="form-horizontal managedform"   >

          <div id="hidden-div-filters" class="form-body">
            <div class="form-group">
              <div class="row customreport_formbody">

                <div id="div_selectTag0" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 fieldLab" >
                  <div class="input-group-prepend">
                    <span for="selectTag0" class="input-group-text">Relatório*</span>
                    <select id="selectTag0" name="selectTag0"
                      class="simpleSelect form-control" [(ngModel)]="template_option"
                      (change)="onTemplateSelected($event)">
                      <option value=""></option>
                      <option *ngFor="let item of template | keyvalue" [value]="item.key">{{item.key}}</option>
                    </select>
                  </div>
                </div>


                <div id="div_upload" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 fieldLab" >
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">Fonte de Dados*</span>
                      </div>
                      <div class="custom-file">
                          <input type="file" multiple class="custom-file-input" id="inputGroupFile01" name="files[]"
                            (change)="onFileSelected($event)" accept=".csv, .xlsx" >
                          <label class="custom-file-label" for="inputGroupFile01">Escolha um arquivo</label>
                      </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="form-actions">
            <div class="text-right">
              <button id="applyFilters" name="applyFilters" type="submit" class="btn btn-info" (click)="submit($event)" >
                <i class="fas fa-search"></i>
                {{ 'Consult' | translate }}
              </button>
              <button id="processing" class="btn btn-info" style="display: none" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Carregando...
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Corpo do Relatório-->
<div id="upload_panel_body" name="upload_panel_body" class="upload_panel_body container-fluid" style="min-height: 400px" >

</div>



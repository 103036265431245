import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-profiles',
  templateUrl: './users-profiles.component.html',
  styleUrls: ['./users-profiles.component.css']
})
export class UsersProfilesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PersonsPerfilService } from './../persons-perfil.service';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from './../../../auth/auth.service';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para atualizar perfis de pessoas.
 */
@Component({
  selector: 'app-persons-perfil-update',
  templateUrl: './persons-perfil-update.component.html',
  styleUrls: ['./persons-perfil-update.component.css']
})
export class PersonsPerfilUpdateComponent implements OnInit {

  /** Item selecionado para atualização. */
  selectedItem = { id: '', name: '', active: true, app_id: '' };

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private route: ActivatedRoute,
    private modalAlertService: BootstrapModalAlertService,
    private personPerfilService: PersonsPerfilService,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso e a habilitação do usuário, em seguida, realiza a leitura inicial do perfil.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('change_personperfil') == false) {
      this.router.navigate(['/403']);
    };

    // Lê o perfil inicial ao inicializar o componente.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

  /**
   * Realiza a leitura do perfil com base no ID fornecido.
   *
   * @param id ID do perfil a ser lido.
   */
  read = (id) => {
    this.personPerfilService.getPersonsPerfilId(id).pipe(take(1)).subscribe(
      data => {
        // Atualiza o item selecionado com os dados retornados.
        this.selectedItem = data;
      },
      error => {
        // Exibe uma mensagem de alerta em caso de erro.
        this.modalAlertService.showAlertDanger('Não foi possível recuperar perfil de pessoa');
      }
    );
  }

  /**
   * Atualiza o perfil utilizando o serviço PersonsPerfilService.
   */
  update = () => {
    this.personPerfilService.updatePersonsPerfil(this.selectedItem).pipe(take(1)).subscribe(
      data => {
        // Atualiza o item selecionado com os dados retornados.
        this.selectedItem = data;
        // Exibe uma mensagem de sucesso.
        this.modalAlertService.showAlertSuccess('Registro atualizado com sucesso.');
      },
      error => {
        // Exibe uma mensagem de erro em caso de falha na atualização.
        this.modalAlertService.showAlertDanger('Erro ao atualizar o registro');
      }
    );
  }

}

import { Component, OnInit, Input, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, AfterContentChecked, OnChanges, SimpleChanges } from '@angular/core';
import { Global } from '../../shared/base-func/global';

import * as moment from 'moment';
import { BaseFunc } from '../../shared/base-func/base-func';

@Component({
  selector: 'app-field-engine',
  templateUrl: './field-engine.component.html',
  styleUrls: ['./field-engine.component.css']
})
export class FieldEngineComponent extends BaseFunc implements OnInit, AfterContentChecked {
  @ViewChild('childComponentTemplate', {  static: true }) childComponentTemplate : TemplateRef<any>;
  @Input() field!: any;
  @Input() reportFilters!: any[];
  @Input() formType!: string;
  @Input() hasLabel: boolean = true;
  @Input() positionField: string = "col-xs-12 col-sm-6 col-md-4 col-lg-4";

  text_array = Array.from(Array(100).keys());

  // Date Range
  global: Global = new Global();
  ranges = this.global.getRanges();
  static_guid = this.global.createGuid();
  selected = {
    startDate: moment().startOf('day'),
    endDate: moment().add(1, 'days').startOf('day'),
  };

  options = {
    floor: new Date().getFullYear() - 5,
    ceil: new Date().getFullYear() + 5,
    minValue:  new Date().getFullYear() - 1,
    maxValue:  new Date().getFullYear(),
    noSwitching: true
  };

  files = null;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    var self = this;
    if ( self.field.default_value ) {
      if ( self.field.type == 'RADIO' ) {
        var name = self.field.dom_id.replace('divselect', 'select').replace(self.field.count, '');
        name = ( name.indexOf('select') == 0 ? name : 'select' + name );
        self.$j('input[name="' + name + self.field.count + '"]' ).filter(x => x.value == self.field.default_value).forEach(x => x.checked = true);
      }
      else if ( self.field.type == 'CHECKBOX' ) {
        var name = self.field.dom_id.replace('divselect', 'select').replace(self.field.count, '');
        name = ( name.indexOf('select') == 0 ? name : 'select' + name );
        self.$j('input[name="' + name + self.field.count + '"]' ).filter(x => self.field.default_value.indexOf(x.value) != -1 ).forEach(x => x.checked = true);
      }
      else if ( self.field.type == 'SELECT' )
        self.$j('#' + 'selectTag' + self.field.count).val(self.field.default_value);
      else
        self.$j('#' + self.field.dom_id).val(self.field.default_value);
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.files = event.target.files;
    }
  }

  removeLine = (count, h) => {
    var self = this;
    for (var s = h; s < self.text_array.length; ++s) {
      self.$j('#array_div_' + count + '_' + s).hide();
      self.$j('#array_div_' + count + '_' + s + ' .textArrayInput').val('');
    }
  }

  addLine = (count, h) => {
    var self = this;
    self.$j('#array_div_' + count + '_' + (h+1) ).show();
  }

}

<section class="section">
  <div class="section-header">
      <h1>{{ layout.aliasTitle('Unidade') }}</h1>
      <!-- <div class="section-header-breadcrumb">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/companydivision">Divisões empresariais</a></div>
          <div class="breadcrumb-item active">Editar</div>
      </div> -->
  </div>

  <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
          <div class="card">
              <div class="card-header">
                  <h4>Editar {{ layout.aliasTitle('Unidade') }} </h4>
              </div>
              <div class="card-body">
                  <form method="POST">
                      <div class="form-group">
                          <label for="name">Nome</label>
                          <select [(ngModel)]="selectedItem.company_group_id" class="form-control mb-2 mr-sm-2"
                              id="company_group_id" name="company_group_id">
                              <option value="">Selecione um grupo empresarial</option>
                              <option *ngFor="let item of groups" value="{{item.company_group_id}}">{{item.name}}</option>
                          </select>
                      </div>
                      <div class="form-group">
                          <label for="name">Nome</label>
                          <input type="text" class="form-control mb-2 mr-sm-2" id="name"
                              placeholder="Digite nome do novo grupo empresarial" [(ngModel)]="selectedItem.name"
                              name="name">
                      </div>
                      <div class="form-group">
                          <label class="d-block">Status</label>
                          <div class="form-check">
                              <input class="form-check-input" [(ngModel)]="selectedItem.active"
                                  [ngModelOptions]="{standalone: true}" type="checkbox" id="active">
                              <label class="form-check-label" for="active">
                                  Ativo
                              </label>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="card-footer text-right">
                <app-default-button [isLoading]="isLoading" (pressed)="update()"></app-default-button>
                <app-default-button [label]="'Cancelar'" [type]="'secondary'" [navigateTo]="'/company'"></app-default-button>
              </div>
          </div>
      </div>
  </div>
</section>

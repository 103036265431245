<ul class="nav nav-pills m-t-30 m-b-30 tour-report-header" #tabscontainer id="tabscontainer" (window:resize)="onResize($event)" *ngIf="!layout.isFullScreen">
  <ng-container *ngFor="let tab of tabs; let i = index; let last = last">
    <li class="nav-item collapsable-tab" (click)="setTabIndex(i)">
      <a href="#navpills-{{i}}" class="nav-link tour-report-header-selected" [class.active]="selectedIndex === i" data-toggle="tab"
        aria-expanded="false">{{tab.nome_rep}}</a>
    </li>
    <!-- <span *ngIf="last">{{onResize()}}</span> -->
  </ng-container>
  <li class="tab-nav-link">
    <ng-container *ngIf="areWrappedTabsAvailable">
      <span class="nav-item">
        <a data-toggle="tab" aria-expanded="false">
          <button type="button" class="btn-menu dropdown-toggle" aria-haspopup="false" data-toggle="dropdown">Ver Todos
            <i class="fas fa-chevron-down" style="margin-left: 5px;"></i>
          </button>

          <div class="dropdown-menu">
            <a *ngFor="let item of wrappedTabs; let i = index" (click)="setTabIndex(i,item.href)" class="dropdown-item"
              href="{{item.href}}">
              {{item.innerText}}
            </a>
          </div>
        </a>
      </span>
    </ng-container>
  </li>
</ul>

<div class="tab-content br-n pn">
  <div id="#navpills-{{i}}" class="tab-pane" [class.active]="selectedIndex === i"
    *ngFor="let tab of tabs; let i = index;">
    <app-custom-reports *ngIf="selectedIndex === i" reportId="{{tab.codi_rep}}"></app-custom-reports>
  </div>
</div>

<app-custom-reports *ngIf="!tabs" reportId="{{ reportId }}"></app-custom-reports>
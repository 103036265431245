import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CrudBuilderService {
  formUrl = environment.formUrl;
  authUrl = environment.authApiUrl
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }


  onShareWithScope(payload: any): Observable<any> {
    return this.http.post(`${this.authUrl}/groups/shared-form`, payload, {headers: this.httpHeaders });
  }

  getCRUDBuilderConf(firstParameter?): Observable<any> {
    return this.http.get(this.formUrl + 'get_crud_builder',
      {headers: this.httpHeaders });
  }

  getCrudBuilderSave(content): Observable<any> {
    const body = content;
    return this.http.post(this.formUrl + 'save_crud', body,
      {headers: this.httpHeaders});
  }

  getCrudBuilderSearch(code): Observable<any> {
    const body = {codi_crd: code};
    return this.http.post(this.formUrl + 'search_crud', body,
      {headers: this.httpHeaders});
  }

  getCrudBuilderDelete(codi_crd): Observable<any> {
    const body = {codi_crd: codi_crd};
    return this.http.post(this.formUrl + 'delete_crud', body,
      {headers: this.httpHeaders});
  }

  formPublisher(forceSync): Observable<any> {
    var self = this;
    return this.http.post(self.formUrl + 'form/publisher', forceSync, {headers: this.httpHeaders});
  }

  formPublisherStatus(): Observable<any> {
    var self = this;
    return this.http.post(self.formUrl + 'form/publisher_status', {headers: this.httpHeaders});
  }
}

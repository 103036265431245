import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainlayoutComponent } from './../../views/mainlayout/mainlayout.component';
import { CompanyGroupReadComponent } from './components/company-group/company-group-read/company-group-read.component';
import { CompanyGroupCreateComponent } from './components/company-group/company-group-create/company-group-create.component';
import { CompanyGroupUpdateComponent } from './components/company-group/company-group-update/company-group-update.component';
import { CompanyDivisionReadComponent } from './components/company-division/company-division-read/company-division-read.component';
import { CompanyDivisionCreateComponent } from './components/company-division/company-division-create/company-division-create.component';
import { CompanyDivisionUpdateComponent } from './components/company-division/company-division-update/company-division-update.component';
import { CompanyReadComponent } from './components/company/company-read/company-read.component';
import { CompanyCreateComponent } from './components/company/company-create/company-create.component';
import { CompanyUpdateComponent } from './components/company/company-update/company-update.component';

const routes: Routes = [
    {
        path: "companygroups",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyGroupReadComponent }
        ]
    },
    {
        path: "companygroups/create",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyGroupCreateComponent }
        ]
    },
    {
        path: "companygroups/update/:id",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyGroupUpdateComponent }
        ]
    },
    {
        path: "companydivision",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyDivisionReadComponent }
        ]
    },
    {
        path: "companydivision/create",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyDivisionCreateComponent }
        ]
    },
    {
        path: "companydivision/update/:id",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyDivisionUpdateComponent }
        ]
    },
    {
        path: "company",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyReadComponent }
        ]
    },
    {
        path: "company/create",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyCreateComponent }
        ]
    },
    {
        path: "company/update/:id",
        component: MainlayoutComponent,
        children: [
            { path: '', component: CompanyUpdateComponent }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CompanyRoutingModule { }

import { Injectable } from '@angular/core';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { LayoutService } from '../layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  constructor(private layout: LayoutService, private guidedTourService: GuidedTourService) {}

  tour: GuidedTour = null;

  start() {
    this.onFillInformation();
    this.guidedTourService.startTour(this.tour);
    localStorage.setItem('tour_mode', JSON.stringify(true));
  }

  elementsExists(className: string) {
    const el = document.getElementsByClassName(className);
    return el[0].childElementCount > 0;
  }

  onIgnoreIfDontExists(className: string, ignoreOnMyEcos: boolean) {
    const tag = document.getElementsByClassName(className);

    if (ignoreOnMyEcos || tag.length == 0) {
      console.log(tag);

      return true;
    }
  }

  onFillInformation() {
    this.tour = {
      tourId: 'purchases-tour',
      useOrb: false,
      steps: [
        {
          title: 'Início',
          selector: '.tour-home',
          content: 'Por aqui você consegue voltar para página inicial, sempre que precisar.',
          orientation: Orientation.Right,
          action: () => {
            const sidebar = document.getElementsByClassName('ps-container');
            if (sidebar[0]) {
              sidebar[0].scrollTo({ top: 0 });
            }
          },
        },
        {
          title: 'Usuário logado',
          selector: '.tour-user',
          content: 'Aqui você consegue acessar as informações sobre, perfil, logout dentre outras informações.',
          orientation: Orientation.Bottom,
        },
        {
          title: 'Cadastros',
          selector: '.tour-cadastros',
          content:
            'Estes campos São responsáveis por gerenciar os cadastros de informações no sistema, como dados de usuários e empresas, assim como, o gerenciamento de seus respectivos níveis de permissões.',
          orientation: Orientation.Right,
          skipStep: this.layout.layoutType === 'MYECOS' ? true : false,
        },
        {
          title: 'Cadastros',
          selector: '.tour-cadastro-ecos',
          content:
            'Este campo é responsável por gerenciar os cadastros de informações no sistema, como dados de usuários e empresas, assim como, o gerenciamento de seus respectivos níveis de permissões.',
          orientation: Orientation.Bottom,
          skipStep: this.layout.layoutType === 'MYECOS' ? false : true,
        },
        {
          title: 'Analytics',
          selector: '.tour-analytics',
          content:
            'Estes campos são responsáveis por apresentar informações sobre parâmetros analíticos e de monitoramento por meio de tabelas, gráficos e dashboards.',
          orientation: Orientation.Right,
          skipStep: this.onIgnoreIfDontExists('tour-analytics', false),
          action: () => {
            const tag = document.getElementsByClassName('tour-analytics')[0] as HTMLElement;
            console.log(tag);
            tag.scrollIntoView();
          },
        },
        {
          title: 'Analytics',
          selector: '.tour-analytics-ecos',
          content:
            'Estes campos são responsáveis por apresentar informações sobre parâmetros analíticos e de monitoramento por meio de tabelas, gráficos e dashboards.',
          orientation: Orientation.Bottom,
          skipStep: this.layout.layoutType === 'MYECOS' ? false : true,
        },

        {
          title: 'Contructor',
          selector: '.tour-constructor',
          content:
            'Estes campos são responsáveis por acessar ferramentas responsáveis pela construção de seus próprios dashboards, analytics, regras de disparos automáticos de e-mail e cubos de dados.',
          orientation: Orientation.Right,
          skipStep: this.onIgnoreIfDontExists('tour-constructor', false),
          action: () => {
            const tag = document.getElementsByClassName('tour-constructor')[0] as HTMLElement;
            tag.scrollIntoView();
          },
          closeAction: () => {
            const tag = document.getElementsByClassName('tour-home')[0] as HTMLElement;
            tag.scrollIntoView();
          },
        },
      ],
    };

    if (this.elementsExists('tour-constructor-ecos')) {
      this.tour.steps.push({
        title: 'Contructor',
        selector: '.tour-constructor-ecos',
        content:
          'Estes campos são responsáveis por acessar ferramentas responsáveis pela construção de seus próprios dashboards, analytics, regras de disparos automáticos de e-mail e cubos de dados.',
        orientation: Orientation.Bottom,
        skipStep: this.layout.layoutType === 'MYECOS' ? false : true,
      });
    }
  }
}

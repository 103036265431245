<section class="section">
  <div class="section-header user-group-header_create mt-3">
    <h1 class="mt-3">Gestão de Perfis</h1>
    <div class="section-header-breadcrumb user-header-breadcrumb_create">
      <div class="breadcrumb-item "><a routerLink="">Dashboard</a></div>
      <div class="breadcrumb-item"><a routerLink="/users">Gestão de Perfis</a></div>
      <div class="breadcrumb-item active">Editar Perfil</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="header">
            <h4>Editar Cadastro de Perfiil</h4>
          </div>
          <form method="POST" [formGroup]="formGroup" class="forms">
            <div class="form-col justify-content-around">
              <div class="form-group">
                <label for="name">Nome</label>
                <input type="text" class="form-control mb-2 mr-sm-2 col-sm-12 col-md-5" id="name"
                  placeholder="Nome do perfil*" name="name" formControlName="name" [ngClass]="displayFieldCss('name')">
                <div class="invalid-feedback">
                  Por favor informe o nome.
                </div>
              </div>
              <div class="form-group">
                <label for="description">Descrição</label>
                <textarea class="form-control mb-2 mr-sm-2 col-sm-12 col-md-5" id="description"
                  placeholder="Informe uma breve descrição. ( Opcional )" name="description"
                  formControlName="description" rows="3"></textarea>
              </div>

              <!-- Nível Organizacional -->
              <div *ngIf="hasHierarchyChangePermission()">
                <!-- Toggle Collapse Hierarquias -->
                <button type="button" class="btn btn-toggle-collapse" (click)="isCollapsedHierarchy = !isCollapsedHierarchy"
                  [attr.aria-expanded]="!isCollapsedHierarchy" aria-controls="collapseBasic">
                  <i [ngClass]="!isCollapsedHierarchy ? 'fa fa-caret-square-down' : 'fa fa-minus-square'"
                    aria-hidden="true"></i>
                    Nivel Organizacional
                </button>
                <hr>
                <div class="row">
                  <div class="col-lg-6">
                    <div id="collapseBasic" [collapse]="isCollapsedHierarchy" [isAnimated]="true">
                      <div class="form-col justify-content-around">
                        <div class="form-group w-75">
                          <label>Selecione a empresa:</label>
                          <ng-select [items]="source" bindLabel="item" bindValue="item" class="form-control-select"
                            [multiple]="false" formControlName="companies">
                          </ng-select>
                        </div>
                      </div>
                      <div class="form-group w-75">
                        <label>Selecione a planta:</label>
                        <ng-select [items]="companies" (change)="onSelectPlant($event)" bindLabel="person.trade_name" class="form-control-select"
                          placeholder="Selecione uma ou mais planta.*" [multiple]="true" formControlName="plants">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <app-organization-level-card *ngFor="let company of selectedCompanies" [data]="company" [isCollapsed]="isOpened(company)" (removeSingle)="onRemoveSingle($event)" (removeAll)="onRemoveAll($event)"></app-organization-level-card>
                  </div>
                </div>
              </div>
              <!-- Toggle Collapse Permissoes  -->
              <button type="button" class="btn btn-toggle-collapse" (click)="isCollapsedPermissions = !isCollapsedPermissions"
                [attr.aria-expanded]="!isCollapsedPermissions" aria-controls="collapseBasic">
                <i [ngClass]="!isCollapsedPermissions ? 'fa fa-caret-square-down' : 'fa fa-minus-square'"
                  aria-hidden="true"></i>
                Permissões
              </button>

              <div class="row mx-2" *ngIf="!isCollapsedPermissions">
                <span class="btn btn-sm btn-primary px-3 py-1 rounded-lg mr-0" [class.d-none]="!hasCompanyToHisUser(onGetDbName(i))" (click)="onSelectDb(i)" [class.btn-disabled]="i !== selectedDb" *ngFor="let db of itemsByDb; index as i">
                  <i class="fa-solid fa-database mr-1"></i>
                  {{ onGetDbName(i) }}
                </span>
              </div>

              <div class="row mt-3 mx-2" *ngIf="treeViewByDb[selectedDb] && !isCollapsedPermissions">
                <div class="col-12">
                  <ngx-treeview [items]="treeViewByDb[selectedDb].tree" [config]="config" (selectedChange)="onSelectedChange($event, treeViewByDb[selectedDb])">
                  </ngx-treeview>
                </div>
              </div>

            </div>
          </form>
        </div>
        <div class="row">
          <div class="buttons text-right">
            <app-default-button [isLoading]="isLoading" (pressed)="updateProfilePermissions()"></app-default-button>
            <app-default-button [label]="'Cancelar'" [type]="'secondary'" (pressed)="cancel()"></app-default-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

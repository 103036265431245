import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';
import { BaseFunc } from '../../shared/base-func/base-func';
import { NewsletterLinkService } from './newsletter-link.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { LabelType, Options } from '@angular-slider/ngx-slider';

import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter-link',
  templateUrl: './newsletter-link.component.html',
  styleUrls: ['./newsletter-link.component.css']
})
export class NewsletterLinkComponent extends BaseFunc implements OnInit {

  @Input() callback: string = null;

  loading_data = false;

  bottomValue: number[] = Array(10).fill(100);
  topValue: number[] = Array(10).fill(1000)

  options: Options = {
    floor: 0,
    ceil: 24 * 60 - 1,
    step: 1,
    noSwitching: true,
    translate: (value: number): string => {
      var hours = Math.trunc(value / 60);
      var minutes = ( value % 60 );
      return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }
  };

  numLayout: any[];
  emails: any[];
  trigger_options: any[];
  cfunc: any;
  form: FormGroup;

  global: Global = new Global();

  constructor(
    private newsletterLinkService: NewsletterLinkService,
    private router: Router,
    private formBuilder: FormBuilder) {
      super()
    }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    self.loading_data = true;
    self.numLayout = [0,1,2,3,4,5,6,7,8,9,10];

    var callback = (self.callback == null ? null : self.callback);
    self.cfunc = ( callback != null ? new Function('self', callback) : null);

    this.newsletterLinkService.getNewsletterLinkerConf().pipe(take(1)).subscribe(
      data => {
        self.emails = data.emails;
        self.trigger_options = data.trigger_options;
        this.form = this.formBuilder.group({ });

        var urls = this.router.url.split('/');
        var id = urls[2];

        self.$j('#codi_cnl').val(id);
        self.$j('#codi_cnl').trigger('change');
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  nextLayout = (id, ref) => {
    var self = this;
    self.$j('#Layout' + (id+1)).show();
    self.$j(ref.target).css('visibility', 'hidden');
  }

  hideLayout = (id, total, ref) => {
    var self = this;
    self.$j('#Layout' + (id - 1)).find('input[value="Adicionar Configuração"]').css('visibility', '');
    for (var i = id; i < total.length; ++i) {
      self.$j('#Layout' + i).find('input[value="Adicionar Configuração"]').css('visibility', '');
      self.$j('#Layout' + i).hide();

      // eval("RemoveselectTO" + i + "();");
      // eval("RemoveselectCCO" + i + "();");
      // eval("RemoveselectCC" + i + "();");
    }
  }

  resetConfig = () => {
    var self = this;
    self.$j('input[value="Adicionar Configuração"]').forEach(function(e) { self.$j(e).css('visibility', ''); });
    self.$j('.layoutbulletin').hide();
    self.$j('#Layout0').show();
  }

  dayDispatchChange = (id, event) => {
    var self = this;
    self.$j('#Layout' + id + ' .sentCategory').hide();
    self.$j('#' + self.$j(event.target).val() + id ).show();
    if( self.$j(event.target).val().indexOf('trigger_') != 0 )
      self.$j('#daydispatchtime' + id).show();
    else
      self.$j('#daydispatchtime' + id).hide();
  }

  freqChange = (id, event) => {
    var self = this;
    self.$j('#Layout' + id + ' .freqCategory').hide();
    if(self.$j(event.target).val().indexOf('between') != -1)
      self.$j('#hour' + id).show();
    else if(self.$j(event.target).val().indexOf('interval') != -1)
      self.$j('#_hourinterval' + id).show();

  }

  searchLinkers = (event) => {
    var self = this;
    var id = event.target.value
    self.newsletterLinkService.getNewsletterLinkerSearch(id).pipe(take(1)).subscribe(
      data => {
        self.resetConfig()
        data.config.forEach(function(e, i){
          if( i!= 0)
            self.$j('#Layout' + (i-1) + ' .classCreateComand').trigger('click');

          self.$j('#code'+ i).val(e.id);
          e.addressTO.forEach(x => self.global.addToSelect2('#selectTO' + i , x, x ) );
          e.addressCC.forEach(x => self.global.addToSelect2('#selectCC' + i , x, x ) );
          e.addressCCO.forEach(x => self.global.addToSelect2('#selectCCO' + i , x, x ) );

          var parts = e.dispatch.split(' ');
          if (e.dispatch.indexOf("trigger_") != -1)
            self.$j('#dayDispatch' + i).val(e.dispatch);
          else if( parts[0].indexOf('_') == -1 || e.dispatch.indexOf("customdate") != -1)
            self.$j('#dayDispatch' + i).val(parts[0]);
          else if(e.dispatch.indexOf("customdate") == -1)
            self.$j('#dayDispatch' + i).val('_' + parts[0].split('_')[1]);

          self.$j('#dayDispatch' + i).trigger('change');

          var daySubItem = self.$j('#' + self.$j('#dayDispatch' + i).val() + i );
          if(daySubItem.length > 0) {
            daySubItem.show();
            daySubItem.find('select').val('' + e.dispatch.split('_')[0]);
          }

          if( e.dispatch.indexOf('between') == -1 && e.dispatch.indexOf('interval') == -1)
            self.$j('#freq' + i).val(parts[1]);
          else
            self.$j('#freq' + i).val(parts[1].split('_')[0]);

          self.$j('#freq' + i).trigger('change');


          if(e.dispatch.indexOf('between') != -1) {
            self.$j('#hour' + i).show();
            var start = parts[1].split('_')[1];
            var end = parts[1].split('_')[2];
            self.bottomValue[i] = parseInt(start.split(':')[0])*60 + parseInt(start.split(':')[1]);
            self.topValue[i] = parseInt(end.split(':')[0])*60 + parseInt(end.split(':')[1]);
          }
          else if(e.dispatch.indexOf('interval') != -1) {
            self.$j('#_hourinterval' + i).show();
            var start = parts[1].split('_')[1];
            self.bottomValue[i] = parseInt(start.split(':')[0])*60 + parseInt(start.split(':')[1]);
          }
        });
        self.loading_data = false;

      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  saveLinkers = (event) => {
    var self = this;
    self.loading_data = true;
    var visibles = self.$j('.layoutbulletin').filter(x=> self.$j(x).is(':visible') );

    var configs = visibles.map(function(e, id){
      var result : {[key:string]: any} = {};
      var code = self.$j('#code' + id).val();
      var TO = self.$j('#selectTO' + id).val() ? self.$j('#selectTO' + id).val() : [];
      var CC = self.$j('#selectCC' + id).val() ? self.$j('#selectCC' + id).val() : [];
      var CCO = self.$j('#selectCCO' + id).val() ? self.$j('#selectCCO' + id).val() : [];
      if( TO.length > 0 ) {
        result.code = code;
        result.TO = TO;
        result.CC = CC;
        result.CCO = CCO;
      }
      else
        return null;

      var fistpart = self.$j('#dayDispatch' + id).val();
      var secondpart = self.$j('#freq' + id).val();
      if( fistpart.indexOf('trigger_') == -1 ) {
        if(fistpart.indexOf('_') != -1)
          fistpart = self.$j('#' + fistpart + id).find('select').val() + fistpart;

        if(secondpart.indexOf('between') != -1)
            secondpart += '_' + self.options.translate( self.bottomValue[id], 0 ) + '_' + self.options.translate( self.topValue[id], 0 );
        else if(secondpart.indexOf('interval') != -1)
            secondpart += '_' + self.options.translate( self.bottomValue[id], 0 );

        result.dispatch =  fistpart + ' ' + secondpart;
      }
      else
        result.dispatch =  fistpart;

      return result;
    }).filter(x => x != null);

    var codi_cnl = self.$j('#codi_cnl').val();
    self.newsletterLinkService.getNewsletterLinkerSave(configs, codi_cnl).pipe(take(1)).subscribe(
      data => {
        if(self.cfunc) { self.cfunc(self); }
        self.loading_data = false;
        self.cancel();
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );

  }

  cancel = () => {
    this.router.navigate(['panel-list/newsletter']);
  }

}

<section class="section">
    <div class="section-header">
        <h1>IoT</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipments">IoT</a></div>
            <div class="breadcrumb-item active">Editar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header">
                    <h4>Editar IoT</h4>
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="equipment_nature">Natureza</label>
                                <select [value]="selectedNature" class="form-control" formControlName="equipment_nature"
                                    [ngClass]="displayFieldCss('equipment_nature')">
                                    <option *ngFor="let item of natures" value="{{item.plant_equipment_nature_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione uma natureza para o IoT
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="equipment_type">Tipo</label>
                                <select [value]="selectedType" class="form-control" formControlName="equipment_type"
                                    [ngClass]="displayFieldCss('equipment_type')">
                                    <option *ngFor="let item of types" value="{{item.plant_equipment_type_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione um tipo para o IoT
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="point">Local de Medição</label>
                                <select [value]="selectedPoint" class="form-control" formControlName="point"
                                    [ngClass]="displayFieldCss('point')">
                                    <option *ngFor="let item of points" value="{{item.plant_point_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Por favor selecione um tipo para o IoT
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="name">Nome</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="name"
                                    [ngClass]="displayFieldCss('name')">
                                <div class="invalid-feedback">
                                    Por favor informe o nome
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="id_capture">ID de captura</label>
                                <input class="form-control mb-2 mr-sm-2" type="text" formControlName="id_capture"
                                    [ngClass]="displayFieldCss('id_capture')">
                                <div class="invalid-feedback">
                                    Por favor informe o ID de captura
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="mid_value">Valor médio</label>
                                <input class="form-control mb-2 mr-sm-2" type="text" formControlName="mid_value"
                                    [ngClass]="displayFieldCss('mid_value')">
                                <div class="invalid-feedback">
                                    Por favor informe o valor médio
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="period">Periodicidade de medição</label>
                                <input class="form-control mb-2 mr-sm-2" type="text" formControlName="period"
                                    [ngClass]="displayFieldCss('period')">
                                <div class="invalid-feedback">
                                    Por favor informe o período
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="theorical_consumption">Valor teórico</label>
                                <input type="number" class="form-control mb-2 mr-sm-2"
                                    formControlName="theorical_consumption">
                            </div>

                        </div>

                        <div class="form-row">

                            <div class="form-group col-md-4">
                                <label for="ip_address">Endereço de IP</label>
                                <input mask="000.000.000.000" type="text" class="form-control mb-2 mr-sm-2"
                                    formControlName="ip_address">
                            </div>

                        </div>

                        <div class="form-row">

                            <div class="form-group col-md-4">

                                <div class="form-check">
                                    <input class="form-check-input padding-update-user" type="checkbox"
                                        formControlName="is_on">
                                    <label class="form-check-label" for="is_on">
                                        Ligado
                                    </label>
                                </div>

                            </div>

                            <div class="form-group col-md-4">

                                <div class="form-check">
                                    <input class="form-check-input padding-update-user" type="checkbox"
                                        formControlName="is_real">
                                    <label class="form-check-label" for="is_real">
                                        Real
                                    </label>
                                </div>

                            </div>

                            <div class="form-group col-md-4">

                                <div class="form-check">
                                    <input class="form-check-input padding-update-user" type="checkbox"
                                        formControlName="is_dealership">
                                    <label class="form-check-label" for="is_dealership">
                                        Concessionária de Energia
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div class="contain-virtual-point is-hidden">

                            <div class="form-group col-md-4 virtual-point">
                                <label for="virtual">Ponto Virtual</label>
                                <select class="form-control" formControlName="plant_equipment"
                                    [ngClass]="displayFieldCss('plant_equipment')">
                                    <option *ngFor="let item of plant_equipment" value="{{item.plant_equipment_id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                                <input class="form-control mb-2 mr-sm-2 factor" type="number" placeholder="Fator">
                                <select class="form-control operation">
                                    <option value="+">
                                        +
                                    </option>
                                    <option value="-">
                                        -
                                    </option>
                                </select>

                                <div class="icons-create">
                                    <span class="add-circle">
                                        <img src="./../../../../../assets/alfa_adminbite/assets/images/icons/add-circle.svg"
                                            alt="icone de adicionar ponto virtual">
                                    </span>
                                    <span class="remove-circle">
                                        <img src="./../../../../../assets/alfa_adminbite/assets/images/icons/remove-circle.svg"
                                            alt="icone de remover ponto virtual">
                                    </span>
                                </div>

                                <div class="invalid-feedback">
                                    Por favor selecione um ponto virtual
                                </div>

                            </div>

                        </div>

                    </form>
                </div>
                <div class="card-footer text-right">
                    <app-default-button [isLoading]="isLoading" (pressed)="update()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
                    <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>
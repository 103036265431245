import { Component, OnInit, ElementRef, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { IcSelectComponent } from 'src/app/components/shared/ic-select/ic-select.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { IndexKind } from 'typescript';
import { months } from 'moment';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-alarm-create',
  templateUrl: './alarm-create.component.html',
  styleUrls: ['./alarm-create.component.css']
})
export class AlarmCreateComponent implements OnInit {

  plant_equipment_obj = [];
  form: FormGroup;

  text = 'DD:HH:mm:ss';
  day: string;
  hour: string;
  minute: string;
  seconds: string;

  isLoading: boolean = false;

  @ViewChild('selectref', { static: true }) selectref;

  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }



  is_outlier: boolean = true;

  ngOnInit(): void {

    // check if user is enable
    this.authService.isEnable();

    // check permission
    if (this.authService.checkPermission('add_equipment') == false) {
      this.router.navigate(['/403']);
    };

     this.form = this.formBuilder.group({
      company: '',
      alert_name: ['', Validators.required],
      value_min: 0,
      value_max: 0,
      plant_equipment: ['', Validators.required],
      is_bot: false,
      is_mail: false,
      is_notify: false,
      is_outlier: true,
      interval: 0,
    });



  }
  keyup(value) {
    this.day = (value/86400 | 0).toString();
    this.hour = ((value%86400)/3600 | 0).toString();
    this.minute = (((value%86400)%3600)/60 | 0).toString();
    this.seconds = (((value%86400)%3600)%60).toString();
    this.text = this.day + ":" + this.hour + ":" + this.minute + ":" + this.seconds;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }



  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  save = () => {
    this.submitted = true;
    this.form.get('plant_equipment').setValue(this.selectref.selectedItemsCollection[0].id)
     if (this.form.valid) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        this.form.get('company').setValue(this.authService.getPerson());

        this.isLoading = true;
        this.plantService.createAlarm(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })

      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      this.validateAllFormFields(this.form);
    }
  }

  reset = () => {
    this.submitted = false;
    this.form.reset();
  }

}

<header class="topbar" data-navbarbg="skin6" *ngIf="!layout.isFullScreen">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <img src="/assets/alfa_adminbite/assets/images/logo-industrycare-white.png" id="logo-topbar-menu-white"
      alt="homepage" />
    <button id="close-fullscreen">Fechar</button>
    <div class="navbar-header" data-logobg="skin5">
      <a class="topbartoggler d-block d-md-none waves-effect waves-light" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="ti-more"></i>
      </a>
    </div>
    <!-- Logo-->
    <div class="navbar" *ngIf="isHorizontal">
      <img [src]="navlogo_wl" class="light-logo-header mt-4" alt="homepage" />
    </div>

    <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin6">
      <ul class="navbar-nav float-left mr-auto d-flex justify-content-center align-items-center">
        <li class="nav-item d-md-block">
          <a (click)="onHandleSidebar()" class="nav-link  waves-effect waves-light font-weight-bold d-flex align-items-center px-3">
            <i id="bell-tour" class="sl-icon-menu font-20"></i>
          </a>
        </li>
        <span class="helpTag_1" position="T_MARGIN" help_type="Component" ref_to="#bell-tour" style="display: none;">
          <h2 class="help_title">Expansão de menu e notificações</h2>
          <p class="help_body">O primeiro botão atua como uma forma de expandir o menu lateral para uma melhor
            visualização de seus campos.</p>
          <p class="help_body">O segundo botão, em formato de sino, atua como uma caixa de entrada para informar os
            usuarios sobre suas notificações.</p>
        </span>

        <li class="nav-item dropdown">
          <app-notification-bell></app-notification-bell>
        </li>

      </ul>
      <!-- Dropdown Usuário-->
      <div id="user-tour" class="usuario">
        <div class="row tour-user" data-toggle="dropdown" style="cursor: pointer;">
          <div class="d-flex flex-column">
            <div id="dropdown-user-name">{{ userName | truncateText:12 }}</div>
            <small class="mx-2 small-text" *ngIf="authService.checkPermission('access_company_switcher') || authService.getSuperUser()">{{ authService.getDbName() | uppercase }}</small>
          </div>
          <span class="helpTag_1" position="TR" help_type="Component" ref_to="#user-tour .usuario"
            style="display: none;">
            <h2 class="help_title">Usuário</h2>
            <p class="help_body">Este campo é responsável pelo acesso de informações sobre perfil, logout(sair) e a
              ativação
              das explicações do sistema por meio do tour do sistema.</p>
            <p class="help_body">Perfil: este campo redireciona o usuário ao seu campo de perfil, sendo possivel a
              alteração da forma como o usuário é referenciado no sistema.
            </p>
            <p class="help_body">Tour: ativa o modo tour que apresenta explicações sobre o funcionamento do sistema, de
              maneira semelhante a como atualmente você está recebendo explicações.
            </p>
            <p class="help_body">Sair: sai de sua conta do sistema, o direcionando a tela de login.
            </p>
          </span>
          <div class="btn-group-user">
            <button type="button" class="btn btn-secondary btn-circle btn-lg btn-menu dropdown-toggle"
              aria-haspopup="false" data-toggle="dropdown">
              <i class="fas fa-angle-down" [ngClass]="isHorizontal ? 'ecos-down' : 'analytics'"></i>
            </button>
            <div class="dropdown-menu" id="dropdown-user">

              <a class="dropdown-item">Perfil</a>
              <a class="dropdown-item" (click)="tour.start()">Tour</a>
              <ng-container *ngIf="isSuperUser">
                <a class="dropdown-item" routerLink="/client/hub">Mudar visão</a>
              </ng-container>
              <ng-container *ngIf="isSuperUser">
                <a class="dropdown-item" routerLink="/pubsub">Publisher</a>
              </ng-container>
              <ng-container *ngIf="isSuperUser">
                <a class="dropdown-item" routerLink="/status">Status</a>
              </ng-container>
              <ng-container *ngIf="isSuperUser">
                <a class="dropdown-item" routerLink="/instance-configurations">Configurar Instancia</a>
              </ng-container>
              <ng-container *ngIf="isSuperUser">
                <a class="dropdown-item" routerLink="/booking-variables">Dicionário de variáveis</a>
              </ng-container>
              <a class="dropdown-item" (click)="logout()">Sair</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

import { Injectable } from '@angular/core';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from '../layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class HowToGuideService {
  constructor(
    private layout: LayoutService,
    private guidedTourService: GuidedTourService,
    private authService: AuthService,
  ) {}

  start(sequence: GuidedTour) {
    this.guidedTourService.startTour(sequence);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from './../../environments/environment';
import { HeaderComponent } from '../components/shared/header/header.component';
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private secretKey = environment.secret;

  prefixKeyName = 'ic_';

  endpoint = environment.authApiUrl;
  endpoint_main = environment.mainApiUrl;
  endpoint_repo = environment.repoUrl;
  endpoint_form = environment.formUrl;
  endpoint_simulation = environment.simulationUrl;
  endpoint_mail = environment.mailUrl;

  server_context = 'ZGVmYXVsdA==';
  check: HeaderComponent;

  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Server-Context': this.server_context,
  });

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Server-Context': this.server_context,
      Authorization: `JWT ${this.getToken()}`,
      uw: JSON.stringify([this.getReportDb(), this.getUserId(), this.getPerson()]),
    }),
  };

  userDetails: any = null;

  hasDecrypted: boolean = false;
  decryptedData: any = null;
  userDb: string = null;

  constructor(private http: HttpClient, private router: Router) {}

  onListPlantEquipment(): Observable<any> {
    const company = this.getPerson();
    return this.http.get<any>(`${this.endpoint_main}/plant/equipment/list?company=${company}`, this.httpOptions);
  }

  onListPlantNature(): Observable<any> {
    const company = this.getPerson();
    return this.http.get<any>(`${this.endpoint_main}/plant/equipmentnature/list?company=${company}`, this.httpOptions);
  }

  onListVariables(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_main}plant/booking/variables`, this.httpOptions);
  }

  onSaveVariables(payload: any[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint_main}plant/booking/variables`, { variables: payload }, this.httpOptions);
  }

  onFindNewSectors(): Observable<any> {
    const response = [
      {
        id: 1,
        label: 'Setor nível 1',
        alias: 'Setor nível 1',
        permission: 'view_sector',
      },
      {
        id: 2,
        label: 'Setor nível 2',
        alias: 'Setor nível 2',
        permission: 'view_sector',
      },
    ]
    return of(response)
    // return this.http.get<any>(`${this.endpoint_main}sector/booking/variables`, this.httpOptions)
  }

  onFindSectors(key: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint_repo}autocomplete?key=${key}`, this.httpOptions);
  }

  onDeleteAllVariables(id: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint_main}plant/booking/variables/equipment/${id}`, this.httpOptions);
  }

  onDeleteAllVariablesByNature(id: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint_main}plant/booking/variables/nature/${id}`, this.httpOptions);
  }

  onDeleteVariable(id: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint_main}plant/booking/variables/classification/${id}`, this.httpOptions);
  }

  public getDecryptedData() {
    const encryptedData = localStorage.getItem(this.prefixKeyName + 'w');
    if (this.userDetails) {
      return this.userDetails;
    }

    return this.decryptCredentials(encryptedData);
  }

  onPrepareUserDetails(data: any) {
    let decryptedData = JSON.parse(this.decryptCredentials(data));

    this.userDetails = {
      id: decryptedData.id,
      email: decryptedData.email,
      first_name: decryptedData.first_name,
      last_name: decryptedData.last_name,
      avatar: decryptedData.avatar,
      db_active: decryptedData.db_active,
      report_db: decryptedData.report_db,
      show_tour: decryptedData.show_tour,
      token: decryptedData.token,
      person: decryptedData.person,
      person_data: decryptedData.person_data,
      profile: decryptedData.profile,
      is_superuser: decryptedData.is_superuser,
      permissions: decryptedData.permissions,
    };
    this.hasDecrypted = true;
  }

  onVerifyCredentials(data: string) {
    const credentials = localStorage.getItem(`${this.prefixKeyName}w`);

    if (!credentials) {
      return;
    }

    if (!this.hasDecrypted || !this.userDetails[data]) {
      this.onPrepareUserDetails(credentials);
    }
    return this.userDetails[data];
  }

  onFindThemeColors(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_main}company/instance-config`, this.httpOptions);
  }

  onInstanceConfig(payload: any): Observable<any> {
    return this.http.post(`${this.endpoint_main}company/instance-config`, payload, this.httpOptions);
  }

  onSaveAlias(payload: any): Observable<any> {
    return this.http.post(`${this.endpoint_main}company/instance-config/alias`, payload, this.httpOptions);
  }

  onListTitles(): Observable<any> {
    return this.http.get(`${this.endpoint_main}company/instance-config/titles`, this.httpOptions);
  }

  onListAlias(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_main}company/instance-config/alias`, this.httpOptions);
  }

  getCompanyList(): Observable<any> {
    return this.http.get(this.endpoint + '/company/list', this.httpOptions);
  }

  onSendXlsFile(payload: FormData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.post<any>(`${this.endpoint_form}data-import`, payload, { headers });
  }

  onSaveConfig(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint_form}data-import/config/save`, payload, this.httpOptions);
  }

  onListConfigs(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_form}data-import/config/list`, this.httpOptions);
  }

  onDeleteConfig(id: number): Observable<any> {
    return this.http.delete<any>(`${this.endpoint_form}data-import/config/delete/${id}`, this.httpOptions);
  }

  onSaveTemporary(payload: any, meta: any): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint_form}data-import/status`,
      { config_data: payload, meta, company: this.getPerson() },
      this.httpOptions,
    );
  }

  onVerifyIfHasDataImporterInProgress(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_form}data-import/status`, this.httpOptions);
  }

  onCreateMap(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint_main}plant/graphimage/upload-graphimage-data`,
      payload,
      this.httpOptions,
    );
  }

  onUpdateMap(payload: any, id: number | string): Observable<any> {
    return this.http.put<any>(`${this.endpoint_main}plant/graphimage/${id}`, payload, this.httpOptions);
  }

  onDeleteMap(id: number | string): Observable<any> {
    return this.http.delete<any>(`${this.endpoint_main}plant/graphimage/${id}`, this.httpOptions);
  }

  onListMaps(): Observable<any> {
    return this.http.get<any>(`${this.endpoint_main}plant/graphimage`, this.httpOptions);
  }

  onListSupervisoryById(id: number | string): Observable<any> {
    return this.http.get<any>(`${this.endpoint_main}plant/graphimage/${id}`, this.httpOptions);
  }

  getSwitchCompanyList(): Observable<any> {
    return this.http.get(`${this.endpoint}/userhierarchy/list`, this.httpOptions);
  }

  getCheckInactivityAuth(): Observable<any> {
    return this.http.get(this.endpoint + '/check-inactivity', this.httpOptions);
  }

  getCheckInactivityMain(): Observable<any> {
    var mainUrl = this.endpoint_main.endsWith('/') ? this.endpoint_main : this.endpoint_main + '/';
    return this.http.get(mainUrl + 'api/check-inactivity', this.httpOptions);
  }

  getCheckInactivityRepo(): Observable<any> {
    return this.http.get(this.endpoint_repo + 'api/check-inactivity/', this.httpOptions);
  }

  getCheckInactivityForm(): Observable<any> {
    return this.http.get(this.endpoint_form + 'api/check-inactivity/', this.httpOptions);
  }

  getCheckInactivitySimulation(): Observable<any> {
    return this.http.get(this.endpoint_simulation + 'check-inactivity', this.httpOptions);
  }

  getCheckInactivityMail(): Observable<any> {
    return this.http.get(this.endpoint_mail + 'check-inactivity', this.httpOptions);
  }

  getToken() {
    const token = this.onVerifyCredentials('token');
    return token;
  }

  getPerson() {
    const person = this.onVerifyCredentials('person');
    return person;
  }

  getUserId() {
    const id = this.onVerifyCredentials('id');
    return id;
  }

  getReportDb() {
    const db = this.onVerifyCredentials('report_db');
    return db;
  }

  getDbName() {
    this.userDb = this.decryptDb(this.getReportDb());
    return this.userDb;
  }

  getDbActive() {
    const db = this.onVerifyCredentials('db_active');
    return db;
  }

  getProfile() {
    const profile = this.onVerifyCredentials('profile');
    return JSON.stringify(profile);
  }

  getEmail() {
    const email = this.onVerifyCredentials('email');
    return email;
  }

  getFullName() {
    const first_name = this.onVerifyCredentials('first_name');
    const last_name = this.onVerifyCredentials('last_name');

    return `${first_name} ${last_name}`;
  }

  getPermissions() {
    const permissions = this.onVerifyCredentials('permissions');
    return JSON.stringify(permissions);
  }

  getSuperUser() {
    const isSuper = this.onVerifyCredentials('is_superuser');
    return isSuper;
  }

  getShowTour() {
    const tour = this.onVerifyCredentials('show_tour');
    return tour;
  }

  getDataJsonUser() {
    return this.userDetails;
  }

  getPersonName() {
    return localStorage.getItem(this.prefixKeyName + 'p_name');
  }

  getInitialConf() {
    return localStorage.getItem(this.prefixKeyName + 'init_on');
  }

  getStatistics() {
    return JSON.parse(localStorage.getItem(this.prefixKeyName + 'statistics'));
  }

  hasPerson() {
    return this.getPerson() ? true : false;
  }

  hasUserId() {
    return this.getUserId() ? true : false;
  }

  hasReportDb() {
    return this.getReportDb() ? true : false;
  }

  hasFullName() {
    return this.getFullName() ? true : false;
  }

  hasToken() {
    return this.getToken() ? true : false;
  }

  hasPermissions() {
    return this.getPermissions() ? true : false;
  }

  isSuperUser() {
    return this.getSuperUser() ? true : false;
  }

  isEnable() {
    if (!this.hasPerson() || !this.hasToken()) {
      this.doLogout();
    }
  }

  setShowTour(showTour) {
    localStorage.setItem(this.prefixKeyName + 'show_tour', showTour);
  }

  setInitialConf(init) {
    localStorage.removeItem(this.prefixKeyName + 'init_on');
    localStorage.setItem(this.prefixKeyName + 'init_on', init);
  }

  setStatistics(init) {
    localStorage.removeItem(this.prefixKeyName + 'statistics');
    localStorage.setItem(this.prefixKeyName + 'statistics', JSON.stringify(init));
  }

  setPerson(company) {
    localStorage.removeItem(this.prefixKeyName + 'init_on');
    localStorage.removeItem(this.prefixKeyName + 'p_name');

    let dataDecrypted = this.getDataJsonUser();
    dataDecrypted.report_db = company.report_db;
    dataDecrypted.person = company.id;

    let dataEncrypted = this.encrypt(JSON.stringify(dataDecrypted));
    localStorage.setItem(`${this.prefixKeyName}w`, dataEncrypted);

    if (company.person.is_physical == true) {
      localStorage.setItem(this.prefixKeyName + 'p_name', company.person.first_name + ' ' + company.person.last_name);
    } else {
      localStorage.setItem(this.prefixKeyName + 'p_name', company.person.trade_name);
    }
  }

  azureLogout() {
    this.clearAll();
    if (!this.hasToken()) {
      this.router.navigate(['/signin']);
    }
  }

  clearAll() {
    localStorage.removeItem(this.prefixKeyName + 'init_on');
    localStorage.removeItem(this.prefixKeyName + 'show_tour');
    localStorage.removeItem(this.prefixKeyName + 'p_name');
    localStorage.removeItem(this.prefixKeyName + 'report_db');
    localStorage.removeItem(this.prefixKeyName + 'p_id');
    localStorage.removeItem(this.prefixKeyName + 'w');
    localStorage.removeItem(this.prefixKeyName + 'origin');

    this.userDetails = null;
  }

  checkPermission(permission_name) {
    var perms = this.getPermissions();
    if (perms == undefined || perms == null) return false;

    let permissions_list = JSON.parse(perms);
    let checkString = '';
    if (permissions_list != null && permissions_list != undefined) {
      for (let i = 0; i < permissions_list.length; i++) {
        if (permissions_list[i].codename == permission_name) {
          checkString += 'a';
        } else {
          checkString += 'b';
        }
      }
    }
    if (checkString.indexOf('a') != -1) {
      return true;
    } else {
      return false;
    }
  }

  decryptDb(data: string) {
    if (this.userDb) {
      return this.userDb;
    }

    this.userDb = this.decrypt(data);
    return this.userDb;
  }

  decryptCredentials(data: string) {
    if (this.decryptedData) {
      return this.decryptedData;
    }

    this.decryptedData = this.decrypt(data);
    return this.decryptedData;
  }

  decrypt(encryptedData: string): string {
    try {
      if (encryptedData) {
        // Divida a string criptografada em IV e dados
        const [ivBase64, ctBase64] = encryptedData?.split('.');
        // Converta o IV e o texto criptografado de volta para bytes
        const keyHex = CryptoJS.enc.Base64.parse(this.secretKey);
        const ivHex = CryptoJS.enc.Base64.parse(ivBase64);
        const ctHex = CryptoJS.enc.Base64.parse(ctBase64);

        // Descriptografe usando a chave, o IV e os dados criptografados
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: ctHex } as CryptoJS.lib.CipherParams, keyHex, {
          iv: ivHex,
        }).toString(CryptoJS.enc.Utf8);

        return decrypted;
      } else {
        return '{}';
      }
    } catch (error) {
      console.error('Erro ao descriptografar:', error.message);
      return '{}';
    }
  }

  encrypt(data: string): string {
    // Gerar IV aleatório
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    // Converter a chave de string base64 para WordArray
    const key = CryptoJS.enc.Base64.parse(this.secretKey);

    // Criptografar os dados
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });

    // Concatenar IV e dados criptografados, ambos em base64
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const ctBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    return `${ivBase64}.${ctBase64}`;
  }

  doLogout() {
    this.clearAll();
    if (!this.hasToken()) {
      this.router.navigate(['/signin']);
    }
  }
}

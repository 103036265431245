import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Project } from './entities/project';
import { IndustrialUnity } from './entities/industrial-unity';
import { ProjectType } from './entities/project-type';
import { EnergyImpact } from './entities/energy-impact';
import { ImpactType } from './entities/impact-type';
import { ImpactValueType } from './entities/impact-value-type';
import { Scenery } from './entities/scenery';
import { Location } from './entities/location';
import { SceneryService } from './scenery.service';
import { ActivatedRoute } from '@angular/router';
import { DataEngineManager } from '../../shared/base-func/data-engine-manager';
import * as moment from 'moment';
import { BaseFunc } from '../../shared/base-func/base-func';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SceneryItemCombo } from './entities/scenery-item-combo';

@Component({
  selector: 'app-scenery-builder',
  templateUrl: './scenery-builder.component.html',
  styleUrls: ['./scenery-builder.component.css']
})
export class SceneryBuilderComponent extends BaseFunc implements OnInit {
  @ViewChild('industrialUnity') inputIndustrialUnity: NgSelectComponent;
  @ViewChild('sceneryName') inputSceneryName: HTMLInputElement;
  @Input() isComparison: boolean;
  unities: IndustrialUnity[];
  locations: Location[];
  filterLocations: Location[];
  projectTypes: ProjectType[];
  energyImpacts: EnergyImpact[];
  impactTypes: ImpactType[];
  impactValueTypes: ImpactValueType[];
  baseYears: number[] = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029];
  baseYearsComparison: number[] = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  comparisonYears: number[] = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2030];
  scenery: Scenery = new Scenery;     
  graphManager = [new DataEngineManager(this), new DataEngineManager(this), new DataEngineManager(this), new DataEngineManager(this), new DataEngineManager(this), new DataEngineManager(this)];  
  readonly defaultSceneryName = "Meu novo cenário";
  readonly defaultComparisonName = "Comparação de cenários";
  // Variáveis para poder controlar atualização de gráficos de comparação
  // Somente atualiza o gráfico que teve valor de ano modificado
  oldIndex30 = null;
  oldIndex31 = null;

  sceneries:Scenery[];
  sceneriesCompared:Scenery[];

  sceneriesCombo:SceneryItemCombo[];
  sceneriesFiltered:SceneryItemCombo[];
  sceneriesSelected:SceneryItemCombo[];
  sceneryBase:Scenery;
  colNames:string[] = [];

  constructor(private sceneryService: SceneryService, private route: ActivatedRoute) { 
    super();
  }

  ngOnInit(): void {
    var idScenery = this.route.snapshot.params.id;
    this.sceneriesCompared = [];   
    this.scenery = new Scenery();     
    
    if (!this.isComparison) {
      if (idScenery) { // Carregando tela com cenário selecionado e enviado via parâmetro
        this.getScenery(idScenery);        
      } else {      
        this.scenery.baseYear = 2016;
        this.scenery.comparisonYear = 2030;
        this.scenery.projects = [];
        this.scenery.projections = [];
        this.scenery.projectionDetailsEnergy = [];
        this.scenery.projectionDetailsEmit = [];
        this.scenery.name = this.defaultSceneryName;
        this.scenery.expanded = true;
        this.sceneriesCompared.push(this.scenery);
      }            
    } else {
      this.scenery.name = this.defaultComparisonName;        
      this.getSceneries();
    }
    this.getBackendData();
  }
    
  addProject(): void {        
    var project = new Project();
    project.expanded = true;        
    this.scenery.projects.push(project);
  }

  removeProject(i): void {       
    this.scenery.projects.splice(i, 1);    
  }

  expandLess(h, i): void {
    this.sceneriesCompared[h].projects[i].expanded = false;
  }

  expandMore(h, i): void {
    this.sceneriesCompared[h].projects[i].expanded = true;
  }

  expandSceneryMore(h): void {
    this.sceneriesCompared[h].expanded = true;
  }

  expandSceneryLess(h): void {
    this.sceneriesCompared[h].expanded = false;
  }
  
  editName(value): void {
    this.scenery.editingName = value;        
  }

  toUpperCase(value: string) {
    if (value) {
      return value.toUpperCase();
    } else {
      return '';
    }
  }
  
  changeBaseYear() {
    var years = [];
    for (var i=this.scenery.baseYear+1; i<=2030; i++) {
      years.push(i);
    }
    this.comparisonYears = years;
    if (this.scenery.comparisonYear < this.scenery.baseYear+1) {
      this.scenery.comparisonYear = this.scenery.baseYear+1;
    }

    this.updateProjectionTotals();
  }

  // Utilizado para fazer operações matemáticas no número
  formatDecimal(value):number {   
    var strValue = String(value);    
    if (strValue.indexOf(",") != -1) {    
      strValue = strValue.split(".").join("");  
      strValue = strValue.split(",").join(".");           
    }
    var newValue: number = Number(strValue);    
    return newValue;
  }

  // Utilizado para mostrar o número formatado na tela
  formatNumber(value):string {        
    var strValue = String(value);
    var newValue = '';
    if (strValue.indexOf(",") != -1) {
      newValue = value.substring(0, strValue.length-3);
    } else {      
      newValue = new Intl.NumberFormat('pt-BR').format(value);      
    }    
    return newValue;        
  }

  parseDate(dateString: string): Date {
    if (dateString) {
        var newDate = new Date(dateString);
        newDate.setDate(newDate.getDate()+1);
        return newDate;
    } else {
        return null;
    }
  }

  filtrarLocais() {
    this.filterLocations = this.locations.filter(x => x.parent == this.scenery.industrialUnity.id);            
    for (var i=0; i<this.scenery.projects.length; i++) {      
      this.scenery.projects[i].location = null;      
    }
  }

  // Métodos que fazem requisição ao Backend
  simulate(): void {
    if (!this.isComparison) {
      if (this.scenery.id == null) {
        this.showSnack("É necessário salvar o cenário antes de fazer a simulação!");     
      } else {
        this.sceneryService.simulate(this.scenery.id).subscribe(
          backData => {          
            this.scenery.projectType = this.scenery.projects[0].projectType;
            this.scenery.impactValueType = this.scenery.projects[0].impactValueType;          
            this.scenery.projections = backData.data.projections;              
            this.scenery.projections[2][0].baseYear = null;
            this.scenery.projections[3][0].baseYear = null;
            this.scenery.projections[4][0].baseYear = 2016;            
            this.scenery.projections[4][1].baseYear = 2016;
            this.scenery.projectionDetailsEnergy = backData.data.projectionDetails_energy;
            this.scenery.projectionDetailsEmit = backData.data.projectionDetails_emit;                    
            this.changeBaseYear();
            this.updateProjectionTotals();
          },
          error => {
            console.log('Aconteceu um erro.', error.message);          
          }, 
          () => { 
            // Construção dos gráficos
            var self = this;          
            self.updateGraphs();
            self.updateProjectionTotals();  
            self.updateComparisonGraphs();                    
          }
        );
      }
    } else {
      var totalCalculados = 0;
      for (var i=0; i<this.sceneriesCompared.length; i++) {
        var s = this.sceneriesCompared[i];
        this.sceneryService.simulate(s.id).subscribe(
          backData => {                           
            var i = this.getSceneryIndex(backData.data.id);                        
            this.sceneriesCompared[i].projectType = this.sceneriesCompared[i].projects[0].projectType;
            this.sceneriesCompared[i].impactValueType = this.sceneriesCompared[i].projects[0].impactValueType;          
            this.sceneriesCompared[i].projections = backData.data.projections;              
            this.sceneriesCompared[i].projections[2][0].baseYear = null;
            this.sceneriesCompared[i].projections[3][0].baseYear = null;
            this.sceneriesCompared[i].projections[4][0].baseYear = 2016;
            this.sceneriesCompared[i].projections[4][1].baseYear = 2016;
            this.sceneriesCompared[i].projectionDetailsEnergy = backData.data.projectionDetails_energy;
            this.sceneriesCompared[i].projectionDetailsEmit = backData.data.projectionDetails_emit; 
            totalCalculados++;
            if (totalCalculados==this.sceneriesCompared.length) {             
              // Construção dos gráficos
              var self = this;              
              this.scenery.baseYear = 2016;
              this.scenery.comparisonYear = 2030;
              this.scenery.projections = this.sceneriesCompared[0].projections;
              this.scenery.projectionDetailsEmit = this.sceneriesCompared[0].projectionDetailsEmit;
              this.scenery.projectionDetailsEnergy = this.sceneriesCompared[0].projectionDetailsEnergy;
              this.sceneryBase = this.sceneriesCompared[0];          
              self.updateGraphs();
              self.updateComparisonGraphs();                   
              self.updateProjectionTotals();   
              this.scenery = this.sceneryBase;
            }
          },
          error => {
            console.log('Aconteceu um erro.', error.message);          
          }, 
          () => {             
          }
        );
      }
    }
  } 

  getSceneryIndex(sceneryId:number):number {
    for (var i=0; i<this.sceneriesCompared.length; i++) {
      if (this.sceneriesCompared[i].id == sceneryId) {
        return i;
      }
    }
    return 0;
  }

  updateProjectionTotals() {    
    var totalVariacaoEnergia:number = 0;
    var totalVariacaoEnergiaPerc:number = 0;
    var totalVariacaoEmissoes:number = 0;
    var totalVariacaoEmissoesPerc:number = 0;
    
    for (var i=0; i<this.scenery.projectionDetailsEnergy.length; i++) {     
      var pd = this.scenery.projectionDetailsEnergy[i];
      if (pd.baseYear >= this.scenery.baseYear && pd.baseYear <= this.scenery.comparisonYear) {
        totalVariacaoEnergia = totalVariacaoEnergia + this.formatDecimal(pd.valueVariation);
        totalVariacaoEnergiaPerc = totalVariacaoEnergiaPerc + this.formatDecimal(pd.valueVariationPercentage);           
      }
    }
    
    for (var i=0; i<this.scenery.projectionDetailsEmit.length; i++) {     
      var pd = this.scenery.projectionDetailsEmit[i];
      if (pd.baseYear >= this.scenery.baseYear && pd.baseYear <= this.scenery.comparisonYear) {
        totalVariacaoEmissoes = totalVariacaoEmissoes + this.formatDecimal(pd.valueVariation);
        totalVariacaoEmissoesPerc = totalVariacaoEmissoesPerc + this.formatDecimal(pd.valueVariationPercentage);           
      }
    }
    
    this.scenery.projections[0][0].total = totalVariacaoEmissoesPerc;
    this.scenery.projections[0][1].total = totalVariacaoEmissoes;
    this.scenery.projections[0][2].total = totalVariacaoEnergiaPerc;
    this.scenery.projections[0][3].total = totalVariacaoEnergia;      
  }

  // Filtra os dados das projeções de totalização por ano base e ano de comparação
  filterProjection(projectionData) {
    var filteredData = projectionData.filter(x => x[0]>=this.scenery.baseYear && x[0]<=this.scenery.comparisonYear);    
    return filteredData;
  }

  getColNames() {
    var colNames = ['Ano', 'BAU', this.scenery.name ];
    return colNames;
  }
  
  updateGraphs() {        
    this.colNames = ['Ano', 'BAU'];           
    if (this.isComparison) {
      this.colNames.push(this.sceneriesCompared[0].name); 
      for (var i=0; i<this.sceneriesCompared.length; i++) {
        var s = this.sceneriesCompared[i];
        if (s.id != this.sceneriesCompared[0].id) {
          this.colNames.push(s.name);                
          // Sempre adiciona no primerio cenário a segunda coluna dos outros cenários
          for (var j=0; j<s.projections[1][0]['data'].length; j++) { 
            this.scenery.projections[1][0]['data'][j].push(s.projections[1][0]['data'][j][2]);
            this.scenery.projections[2][0]['data'][j].push(s.projections[2][0]['data'][j][2]);
            this.scenery.projections[3][0]['data'][j].push(s.projections[3][0]['data'][j][2]);
            this.scenery.projections[3][1]['data'][j].push(s.projections[3][1]['data'][j][2]);
          }          
        }
      }        
    } 
    if (!this.isComparison) {
      this.colNames.push(this.scenery.name);             
    }  
    setTimeout(function() {            
      this.graphManager[0].initializeGraph(this.colNames, this.scenery.projections[1][0]['data'], {}, 'line', 'projection_graph_5', {"formatter": "default2,default2"}, 'months', true, false);
      this.graphManager[1].initializeGraph(this.colNames, this.scenery.projections[2][0]['data'], {}, 'line', 'projection_graph_6', {"formatter": "default2,default2"}, 'months', true, false);
      this.graphManager[2].initializeGraph(this.colNames, this.scenery.projections[3][0]['data'], {}, 'line', 'projection_graph_7', {"formatter": "default2,default2"}, 'months', true, false);
      this.graphManager[3].initializeGraph(this.colNames, this.scenery.projections[3][1]['data'], {}, 'line', 'projection_graph_8', {"formatter": "default2,default2"}, 'months', true, false);            
    }.bind(this), 100);                   
  }

  updateComparisonGraphs() {  
    setTimeout(function() {     
      var newBaseYears = this.baseYears.slice();
      newBaseYears.push(2030);
      var index30 = this.searchIndex(this.scenery.projections[4][0].baseYear, newBaseYears);

      if (this.oldIndex30 == null || index30 != this.oldIndex30) {    
        var comp_e = this.scenery.projections[3][0]['data'];  
        if (!this.isComparison) {
          comp_e = comp_e.map(x => [x[0], x[1] / comp_e[index30][1], x[2] / comp_e[index30][2]]);        
        } else {         
          comp_e = comp_e.map(x => {
            var series = [];
            series.push(x[0]);  
            for (var i=0; i<this.scenery.projections[3][0].data[0].length-1; i++) {              
              series.push(x[i+1] / comp_e[index30][i+1])
            }
            return series;           
          });                  
        }
        this.graphManager[4].initializeGraph(this.colNames, comp_e, {}, 'line', 'projection_graph_9', {"formatter": "default2,default2"}, 'months', true, false);      
      }
      this.oldIndex30 = index30;

      var index31 = this.searchIndex(this.scenery.projections[4][1].baseYear, newBaseYears);
      if (this.oldIndex31 == null || index31 != this.oldIndex31) {    
        var comp_w = this.scenery.projections[3][1]['data'];      
        if (!this.isComparison) {
          comp_w = comp_w.map(x => [x[0], x[1] / comp_w[index31][1], x[2] / comp_w[index31][2]]);                        
        } else {         
          comp_w = comp_w.map(x => {
            var series = [];
            series.push(x[0]);  
            for (var i=0; i<this.scenery.projections[3][1].data[0].length-1; i++) {              
              series.push(x[i+1] / comp_w[index31][i+1])
            }
            return series;           
          });                  
        }
        this.graphManager[5].initializeGraph(this.colNames, comp_w, {}, 'line', 'projection_graph_10', {"formatter": "default2,default2"}, 'months', true, false);      
      }
      this.oldIndex31 = index31;
    }.bind(this), 100);   
  }

  searchIndex(selectedYear: number, years: number[]): number {        
    if (selectedYear == null) {
      return 0; // retorna o primeiro como padrão
    }
    for (var i=0; i<years.length; i++) {
      if (years[i]==selectedYear) {
        return i;
      } 
    }
    return 0;
  }

  saveScenery(): void {
    if (this.scenery.name == this.defaultSceneryName) {
      this.showSnack('É obrigatório renomear o cenário!');                  
      this.scenery.editingName = true;
      this.inputSceneryName.focus();      
    } else if (!this.scenery.industrialUnity) {
      this.showSnack('É obrigatório informar a unidade industrial do cenário!');            
      this.inputIndustrialUnity.focus();
    } else if (this.scenery.projects.length <= 0) {
      this.showSnack('É obrigatório adicionar pelo menos um projeto!');            
    } else {                 
      this.sceneryService.saveScenery(this.scenery).subscribe(
        backData => {          
          this.scenery.id = backData.data;
          this.showMessage(backData.status, 'Cenário salvo com sucesso!', 'Erro ao salvar cenário!');        
        },
        error => {          
          this.showSnack('Erro ao salvar cenário. ' + error.message);
        }, 
        () => { }
      );
    }
  }

  getScenery(idScenery: number) {
    this.sceneriesCompared = [];    
    this.sceneryService.getScenary(idScenery).subscribe(
      backData => {          
        backData.data.creationDate = moment(backData.data.creationDate);
        backData.data.projects.forEach(x => {
          x.startPeriod = moment(x.startPeriod);
          x.endPeriod = moment(x.endPeriod);
          x.valueImpact = parseFloat(x.valueImpact);
        });
        this.scenery = backData.data;         
        this.scenery.expanded = true;
        this.sceneriesCompared.push(this.scenery);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      }, 
      () => { }
    );
  }

  showMessage(status, successMessage: string, errorMessage: string) {
    if (status == 'success') {
      this.showSnack(successMessage);
    } else if(status == 'error') {
      this.showSnack(errorMessage);
    }
  }

  getBackendData() {      
    this.sceneryService.getUnities().subscribe(
      backData => {                
        this.unities = <IndustrialUnity[]>backData.data;  
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );    

    this.sceneryService.getLocal().subscribe(
      backData => {        
        this.locations = <Location[]>backData.data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );

    this.sceneryService.getEnergyImpacts().subscribe(
      backData => {        
        this.energyImpacts = <EnergyImpact[]>backData.data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );     

    this.sceneryService.getProjectTypes().subscribe(
      backData => {        
      this.projectTypes = <ProjectType[]>backData.data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );
    
    this.sceneryService.getImpactTypes().subscribe(
      backData => {        
        this.impactTypes = <ImpactType[]>backData.data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );

    this.sceneryService.getImpactValueTypes().subscribe(
      backData => {        
        this.impactValueTypes = <ImpactValueType[]>backData.data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);        
      }, 
      () => { }
    );
    
  }

  getSceneries(): void {
    this.sceneries = [];
    this.sceneryService.getScenaries().subscribe(
      backData => {      
        this.sceneriesCombo = <SceneryItemCombo[]> backData.data;                      
        for (var i=0; i<this.sceneriesCombo.length; i++) {          
          this.sceneryService.getScenary(this.sceneriesCombo[i].id).subscribe(
            backData => {              
              backData.data.creationDate = moment(backData.data.creationDate);
              backData.data.projects.forEach(x => {
                x.startPeriod = moment(x.startPeriod);
                x.endPeriod = moment(x.endPeriod);
                x.valueImpact = parseFloat(x.valueImpact);
              });
              var sce: Scenery;
              sce = backData.data; 
              sce.expanded = false;
              this.sceneries.push(sce);              
            },
            error => {
              console.log('Aconteceu um erro.', error.message);
            }, 
            () => { }
          );
        }
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      }, 
      () => { }
    );
  }

  filtrarCenarios() {          
    this.sceneriesSelected = [];  
    if (this.scenery.industrialUnity && this.sceneries) {
      this.sceneriesFiltered = [];
      for (var i=0; i<this.sceneriesCombo.length; i++) {
        var s = this.sceneriesCombo[i];
        if (s.industrialUnity == this.scenery.industrialUnity.name) {
          var scombo = new SceneryItemCombo();
          scombo.id = s.id;
          scombo.name = s.name;
          scombo.industrialUnity = s.industrialUnity;
          scombo.creationDate = s.creationDate;          
          this.sceneriesFiltered.push(scombo);
        }
      }      
    }        
  }

  selecionarCenarios() {    
    this.sceneriesCompared = [];
    for (var i=0; i<this.sceneriesSelected.length; i++) {
      var s = this.sceneriesSelected[i];
      var index = this.sceneries.findIndex(x => x.id == s.id);
      if (index != -1) {
        this.sceneriesCompared.push(this.sceneries[index])
      }
    }  
  }

  selecionarCenarioBase() {        
    this.scenery.projectionDetailsEmit = this.sceneryBase.projectionDetailsEmit;
    this.scenery.projectionDetailsEnergy = this.sceneryBase.projectionDetailsEnergy;
    this.updateProjectionTotals();
  }
}
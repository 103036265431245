import { Injectable, ɵConsole } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from './../../auth/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  // Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    })
  }

  httpOptionsfiles = {
    headers: new HttpHeaders({
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] ),
      'Authorization': `JWT ${this.authService.getToken()}`,
    })
  }

  params = new HttpParams();

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  getUsersGroups(url: string, params): Observable<any> {
    return this.http.get(url, {
      headers: this.httpOptions.headers,
      params: this.params.set('person', params)
    })
  }

  getUsersPermissions(): Observable<any> {
    return this.http.get(this.authService.endpoint + '/permissions/', this.httpOptions)
  }

  getUsers(url: string, params): Observable<any> {
    return this.http.get(url, { headers: this.httpOptions.headers })
  }

  getUsersDetails(url: string): Observable<any> {
    return this.http.post(url, {},{ headers: this.httpOptions.headers });
  }

  createUserAvatar(userId, filename, file): Observable<any> {
    return this.http.put(this.authService.endpoint + '/users/create/avatar/' + filename + '/' + userId, file, this.httpOptionsfiles);
  }
  createUser(user): Observable<any> {
    return this.http.post(this.authService.endpoint + '/users/create', user, this.httpOptions);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(this.authService.endpoint + '/users/delete/' + id, this.httpOptions);
  }

  detailUser(id): Observable<any> {
    return this.http.get(this.authService.endpoint + '/users/detail/' + id, this.httpOptions);
  }

  updateUser(user): Observable<any> {
    return this.http.patch(this.authService.endpoint + '/users/update/' + user.id, user, this.httpOptions);
  }

  userPublisher(forceSync): Observable<any> {
    return this.http.post(this.authService.endpoint + '/users/publisher', forceSync , this.httpOptions);
  }

  userPublisherStatus(): Observable<any> {
    return this.http.post(this.authService.endpoint + '/users/publisher_status', this.httpOptions);
  }

}

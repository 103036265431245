import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilterWallService {
  repoUrl = environment.repoUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}` 
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getFilterWallConfig(key, compact, event, callback): Observable<any> {
    const body = {key: key, compact: compact, event: event, callback: callback};
    return this.http.post(this.repoUrl + 'filter_wall', body,
      {headers: this.httpHeaders});
  }

  getFilterWallListFields(formCode, filterName): Observable<any> {
    const body = { formCode: formCode, filterName: filterName };
    return this.http.post(this.repoUrl + 'get_filters', body,
      {headers: this.httpHeaders});
  }

  deleteFilter(formCode, filterName): Observable<any> {
    const body = { formCode: formCode, filterName: filterName };
    return this.http.post(this.repoUrl + 'delete_filters', body,
      {headers: this.httpHeaders});
  }
}

<button
  type="button"
  class="btn btn-icon rounded"
  [ngClass]="{ 'text-warning': type == 'warning', 'text-danger': type == 'danger', 'text-primary': type == 'primary' }"
  [tooltip]="disabled ? (tooltiptext + ' desabilitado'): tooltiptext"
  [disabled]="disabled"
  (click)="onPress()"
>
  <ng-content></ng-content>
</button>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './../../../auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  repoUrl = environment.repoUrl;
  userUrl = environment.authApiUrl;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context, 
    'Authorization': `JWT ${this.authService.getToken()}` 
  });

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getHelpConfig(key, compact, type, callback): Observable<any> {
    const body = {key: key, compact: compact, type: type, callback: callback};
    return this.http.post(this.repoUrl + 'help', body,
      {headers: this.httpHeaders});
  }

  //Método de alteração de status de exibição do  tour
  updateTourStatus(showTour): Observable<any> {
    const body = {showTour: showTour};
    return this.http.put(this.userUrl + '/users/tour', body,
      {headers: this.httpHeaders});
  }
}

<div class="tab-content container-fluid" style="min-height: 500px">
  <div class="row">
    <div class="col-8">
      <h1 class="header-title">{{name}}</h1>
    </div>
    <div class="col-4">
      <button id="criar-novo-analytics" name="criar-novo-analytics" type="submit"
        class="btn btn-black float-right" routerLink="{{router_create}}">
        {{ 'Criar novo ' + name | translate }}
      </button>

      <button *ngFor="let a of actions"
        id="{{a[0]}}" name="{{a[0]}}" type="submit"
        class="btn btn-black float-right" routerLink="{{a[1]}}">
        {{ a[2] | translate }}
      </button>
    </div>
  </div>

  <div class="row" *ngIf="entity_list && entity_list.length > 0">

    <button id="find-entity" name="find-entity" class="btn btn-secondary pr-0 " (click)="showText = !showText; calculate_list();" >
      <i class="mdi mdi-magnify font-20"></i>
    </button>
    <input type="search unique-table-report" *ngIf="showText"
      class="form-control rounded filter-table-input"
      placeholder="Filtragem de informações"
      [(ngModel)]="filterText"
      (keyup)="calculate_list()" >
    <!--
    <button id="filter-entity" name="filter-entity" class="btn btn-secondary pr-0 m-1" (click)="calculate_list();" >
      <i class="mdi mdi-filter-variant font-20"></i>
    </button>
    -->
    <button id="order-entity" name="order-entity" class="btn btn-secondary ml-0" (click)="order = order*-1; calculate_list();" >
      <i class=" fas {{ order == -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-up' }} font-20"></i>
    </button>
    <!--
    <button id="check-entity" name="check-entity" class="btn btn-secondary pr-0 m-1"  >
      <i class="mdi mdi-playlist-check font-20"></i>
    </button>
    -->
    <div style="border-left:1px solid #000"></div>

    <button id="list-view" name="list-view" class="btn btn-secondary " [attr.disabled]=" !isGrid ? 'disabled' : null " (click)='changeView("list")'>
      <i class="mdi mdi-view-list font-20"></i>Lista
    </button>
    <button id="grid-view" name="grid-view" class="btn btn-secondary " [attr.disabled]=" isGrid ? 'disabled' : null " (click)='changeView("grid")'>
      <i class="mdi mdi-view-grid font-20"></i>Grade
    </button>
  </div>



  <div class="row " *ngIf="entity_list">
      <div class="card-body" *ngIf="!isGrid">
        <div class="row">
          <div class="col-2 text-center font-weight-bold">Nome</div>
          <div class="col-1 text-center font-weight-bold" *ngIf="colVisible.group">Grupo</div>
          <div class="col-2 text-center font-weight-bold" *ngIf="colVisible.graph">Grafico</div>
          <div class="col-1 text-center font-weight-bold" *ngIf="colVisible.create_date">Criado em</div>
          <div class="col-1 text-center font-weight-bold" *ngIf="colVisible.update_date">Última Atualização</div>
          <div class="col-1 text-center font-weight-bold" *ngIf="colVisible.user">Criado por</div>
          <div class="col-5 text-center font-weight-bold" *ngIf="colVisible.complement">Complemento</div>
        </div>
      </div>

    <div *ngFor="let entity of entity_show" class="card_filter {{ isGrid ? 'col-xs-6 col-sm-3 col-lg-3 col-xl-2' : 'col-12' }} mt-3">

      <div class="card card-filter-wall card-wrap">
        <div class="btn-group">
          <button type="button"
            class="btn btn-secondary btn-circle btn-lg btn-menu dropdown-toggle"
            aria-haspopup="false"
            data-toggle="dropdown">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="searchReport(entity.code)" *ngIf="entity.editable">Editar</a>
            <!--<a class="dropdown-item" href="javascript:void(0)">Duplicar</a>-->
            <a class="dropdown-item" (click)="deleteReport(entity.code)">Excluir</a>
          </div>
        </div>
        <div class="card-body" (click)="searchReport(entity.code)" style="cursor: pointer;">
          <div class="{{ !isGrid ? 'row' : '' }}">
            <div class="{{ !isGrid ? 'col-2 text-center' : '' }}" style="font-size: 14px;font-weight: bold;">{{entity.name}}</div>
            <div class="{{ !isGrid ? 'col-1 text-center' : '' }}" style="{{ isGrid ? 'font-size: 10px; margin-bottom: 15px' : '' }}" *ngIf="( isGrid && entity.group ) || ( !isGrid && colVisible.group)">
              <span *ngIf="isGrid">Grupo:</span> {{entity.group}}
            </div>

            <div class="{{ !isGrid ? 'col-2' : 'row' }}" style="height: 120px; width: 270px; margin-left: -35px;" *ngIf="entity.graph">
              <canvas id="projection_graph_{{entity.code}}"></canvas>
            </div>

            <div class="{{ !isGrid ? 'col-1 text-center' : '' }}" style="{{ isGrid ? 'font-size: 8px;' : '' }} " *ngIf="( isGrid && entity.create_date ) || ( !isGrid && colVisible.create_date)">
              <span *ngIf="isGrid">Criado em</span> {{formatDate(entity.create_date)}}
            </div>
            <div class="{{ !isGrid ? 'col-1 text-center' : '' }}" style="{{ isGrid ? 'font-size: 8px;' : '' }} " *ngIf="( isGrid && entity.update_date ) || ( !isGrid && colVisible.update_date)">
              <span *ngIf="isGrid">Última Atualização em</span> {{formatDate(entity.update_date)}}
            </div>
            <div class="{{ !isGrid ? 'col-1 text-center' : '' }}" style="{{ isGrid ? 'font-size: 8px;' : '' }} " *ngIf="( isGrid && entity.user ) || ( !isGrid && colVisible.user)">
              <span *ngIf="isGrid">Criado por</span> {{entity.user}}
            </div>
            <div class="{{ !isGrid ? 'col-5' : '' }}" *ngIf="( entity.complement && entity.complement.length > 0) || ( entity.btn_complement && entity.btn_complement.length > 0)">
              <div class="row" *ngIf="( entity.complement && entity.complement.length > 0)">
                <div style="{{ isGrid ? 'font-size: 8px;' : '' }}" class="col-6" *ngFor="let c of entity.complement">{{c[0]}}: {{c[1]}}</div>
              </div>
              <div class="row mt-2" *ngIf="( entity.btn_complement && entity.btn_complement.length > 0)">
                <button style="{{ isGrid ? 'font-size: 8px;' : '' }}" type="button" class="btn btn-primary waves-effect" *ngFor="let c of entity.btn_complement" routerLink="{{ c[1].replace(':id', entity.code).replace(':name', entity.name) }}">{{c[0]}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="modal-backdrop fade show" [style]="!loading_data ? 'display:none' : '' ">
  <div class="spinner-border" style="width: 5rem; height: 5rem; position: fixed; top: 50%; left: 50%;font-size: xx-large;" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>

<div id="snack-modal-delete" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="snack-modal-title">Confirmação</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="hideSnackbarModal()">×</button>
      </div>
      <div class="modal-body" id="snack-modal-message">
        Deseja realmente excluir o {{name}}?
      </div>
      <div class="row" style="padding: 0px 20px 20px 20px; margin: auto;">
        <button type="button" class="btn btn-secondary waves-effect"
          style="width: 80px;"
          data-dismiss="modal" data-backdrop="false"
          (click)="hideSnackbarModal()">Cancelar</button>

        <button type="button" class="btn btn-primary waves-effect"
          style="width: 80px;"
          data-dismiss="modal" data-backdrop="false"
          (click)="confirmDeleteReport()" [disabled]="loading_data">Sim</button>
      </div>
    </div>
  </div>
</div>

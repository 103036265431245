export class NumberConfig {
    
    conf: { [key:string]: any }
    
    constructor(u_conf) {
        this.conf = {
            prefix: u_conf && u_conf.prefix ? u_conf.prefix : "",
            sufix: u_conf && u_conf.sufix ? u_conf.sufix : "",
            decimalPlaces: u_conf && u_conf.decimalPlaces != undefined ? u_conf.decimalPlaces : 2,
            decimalSymbol: u_conf && u_conf.decimalSymbol ? u_conf.decimalSymbol : ",",
            groupingSymbol: u_conf && u_conf.groupingSymbol ? u_conf.groupingSymbol : ".",
            intervals: u_conf && u_conf.intervals ? u_conf.intervals : [],
            limitTotal: u_conf && u_conf.limitTotal ? u_conf.limitTotal : null
        };
    }
    
    getConf = () => { 
        return this.conf; 
    };

    formatValue = (value) => {
        var self = this;
        var ft = '';
        if(value != null && value != undefined) {
            var rounded = ( Math.abs(value) - Math.trunc(Math.abs(value)) ).toFixed(self.conf.decimalPlaces);
            var decimalP = rounded.startsWith('1.') ? rounded.replace('1.', '') : rounded.replace('0.', '');
            var significant = rounded.startsWith('1.') ? Math.round(value) : Math.trunc(value);
            ft = significant.toString().replace(/\B(?=(\d{3})+(?!\d))/g, self.conf.groupingSymbol) +
                (self.conf.decimalPlaces != 0 ? self.conf.decimalSymbol + decimalP : "" );
        }
        ft = self.conf.prefix + ft + self.conf.sufix;
        
        for(var i=0; i < self.conf.intervals.length; ++i) {
            if( self.conf.intervals[i][0] == 'lessthan' && value < self.conf.intervals[i][1] ) {
                ft = "<p style='color:" + self.conf.intervals[i][2] + "'>" + ft + "</p>";
                break;
            }
            else if( self.conf.intervals[i][0] == 'greatherthanorequal' && value >= self.conf.intervals[i][1] ) {
                ft = "<p style='color:" + self.conf.intervals[i][2] + "'>" + ft + "</p>";
                break;
            }
            else if( self.conf.intervals[i][0] == 'between' && value >= self.conf.intervals[i][1] && value <= self.conf.intervals[i][2] ) {
                ft = "<p style='color:" + self.conf.intervals[i][3] + "'>" + ft + "</p>";
                break;
            }
        }
        
        return ft;
    };
}

export class CubeMetric {
    key: any;
    name: any
    association: any
    formula: any
    show: any
    geo: any
    aggIndex: any
    aggTotalsIndex: any
    agg: any
    aggTotals: any
    metricIndex: any
    hasFormat: any
    colorCells: any
    totalWithRawData: any
    //Objeto utilizado para formatar numeros da tabela 
    numberConfigInstance: any;
    
    constructor(key, name, association, formula, show, geo, aggSelected, agg, aggTotalsSelected, aggTotals, metricIndex?, hasFormat?, prefixCell?, sufixCell?, colorCells?, totalWithRawData?) {
        var self = this;
        self.key = key;
        self.name = name;
        self.association = association;
        self.formula = formula;
        self.show = show;
        self.geo = geo;
        self.aggIndex = aggSelected;
        self.aggTotalsIndex = aggTotalsSelected;
        self.agg = agg;
        self.aggTotals = aggTotals;
        self.metricIndex = metricIndex;
        self.hasFormat = hasFormat;
        self.colorCells = colorCells;
        self.totalWithRawData = totalWithRawData;
        //Objeto utilizado para formatar numeros da tabela 
        self.numberConfigInstance = new NumberConfig({
            prefix: prefixCell ? prefixCell : "",
            sufix: sufixCell ? sufixCell : "",
            decimalSymbol: ",",
            groupingSymbol: "."
        });
    }

    static factory = (array) => {
        return new CubeMetric(
            array[0], 
            array.length > 1 ? array[1] : null,
            array.length > 2 ? array[2] : null,
            array.length > 3 ? array[3] : null,
            array.length > 4 ? array[4] : null,
            array.length > 5 ? array[5] : null,
            array.length > 6 ? array[6] : null,
            array.length > 7 ? array[7] : null,
            array.length > 8 ? array[8] : null,
            array.length > 9 ? array[9] : null,
            array.length > 10 ? array[10] : null,
            array.length > 11 ? array[11] : null,
            array.length > 12 ? array[12] : null,
            array.length > 13 ? array[13] : null,
            array.length > 14 ? array[14] : null,
            array.length > 15 ? array[15] : null
        );
    }
}

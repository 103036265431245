import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DealershipService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8',
    'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
    'Server-Context': this.authService.server_context,
    'Authorization': `JWT ${this.authService.getToken()}`, }),
  }
  params = new HttpParams();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getDealershipAll(url: string, company): Observable<any> {
    var ctx = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Server-Context': this.authService.server_context,
      'Authorization': `JWT ${this.authService.getToken()}`, }),
      params: this.params.set('company', company)
    };

    return this.http.get(url, ctx);
  }

  onCreateDealer(payload: any) {
    return this.http.post<any>(`${this.authService.endpoint_main}company/dealership/create`, payload, this.httpOptions)
  }

  onUpdateDealer(payload: any) {
    return this.http.put<any>(`${this.authService.endpoint_main}company/dealership/update/${payload.id}`, payload, this.httpOptions)
  }

  onDealerInfo(id: string | number) {
    return this.http.get(`${this.authService.endpoint_main}company/dealership/detail/${id}`, this.httpOptions);
  }

  onListFieldsToCreateDealer() {
    return this.http.get<any>(`${this.authService.endpoint_main}company/concessionary-fields`, this.httpOptions)
  }

  createDealership(dealership): Observable<any> {
    return this.http.post(this.authService.endpoint_main + 'company/dealership/create', dealership, this.httpOptions);
  }

  deleteDealership(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'company/dealership/delete/' + id, this.httpOptions);
  }

  updateDealership(dealership): Observable<any> {

    return this.http.put(this.authService.endpoint_main + 'company/dealership/update/' + dealership.id, dealership, this.httpOptions);
  }

  getDealership(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'company/dealership/detail/' + id, this.httpOptions);
  }

  getDealershipElectricity(dealership): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'company/dealershipelectricity/detail/' + dealership, this.httpOptions);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonsPerfilService {
  mainUrl = environment.mainApiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
      'Authorization': `JWT ${this.authService.getToken()}`,
    }),
  }

  params = new HttpParams();
  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) { }

  getPersonsPerfils(url: string): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson() ] ),
        'Server-Context': this.authService.server_context,
        'Authorization': `JWT ${this.authService.getToken()}`,
      })
    });
  }

  getPersonsPerfilId(id): Observable<any> {
    return this.http.get(this.authService.endpoint_main + 'personsperfil/' + id + '/', this.httpOptions);
  }

  updatePersonsPerfil(perfil): Observable<any> {
    return this.http.put(this.authService.endpoint_main + 'personsperfil/' + perfil.id + '/', perfil, this.httpOptions);
  }

  createPersonsPerfil(perfil): Observable<any> {
    //let url = this.authService.endpoint_main + '/personsperfil/create/';
    return this.http.post(this.authService.endpoint_main + 'personsperfil/' , perfil, this.httpOptions);
  }

  deletePersonsPerfil(id): Observable<any> {
    return this.http.delete(this.authService.endpoint_main + 'personsperfil/' + id + '/', this.httpOptions);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular para a listagem de imagens de IoT.
 */
@Component({
  selector: 'app-equipment-image-read',
  templateUrl: './equipment-image-read.component.html',
  styleUrls: ['./equipment-image-read.component.css']
})
export class EquipmentImageReadComponent implements OnInit {

  /** Lista de imagens de IoT. */
  equipmentImages = [];
  equipmentImagesFiltered: any[] = [];
  /** URL para a próxima página de resultados paginados. */
  next: string;
  /** URL para a página anterior de resultados paginados. */
  previous: string;
  /** Domínio principal para construção de URLs. */
  domain_main = '';

  /** Parâmetros HTTP. */
  params = new HttpParams();

  /** Referência para o modal de exclusão. */
  deleteModalRef: BsModalRef;
  /** Elemento filho que referencia o modal de exclusão. */
  @ViewChild('deleteModal') deleteModal;
  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'IoT', field: 'plant_equipment.name', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /** Método executado na inicialização do componente. */
  ngOnInit(): void {
    this.initForm();

    this.domain_main = this.authService.endpoint_main;

    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_equipmentimage') == false) {
      this.router.navigate(['/403']);
    };

    this.readAll(this.authService.endpoint_main + 'plant/equipmentimage/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }
  
  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.equipmentImagesFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a.plant_equipment.name.localeCompare(b.plant_equipment.name);
      } else {
        return b.plant_equipment.name.localeCompare(a.plant_equipment.name);
      }
    })
  }

  onSearch(value: string) {
    if(value == '') {
      this.equipmentImagesFiltered = this.equipmentImages;
      return;
    }

    this.equipmentImagesFiltered = this.equipmentImagesFiltered.filter((el: any) => el.plant_equipment.name.toLowerCase().includes(value))
  }

  onClearFilters() {
    this.equipmentImagesFiltered = this.equipmentImages;
  }

  /**
 * Realiza a leitura da lista completa de imagens de IoT.
 *
 * @param url URL para a leitura da lista.
 */
  readAll(url: string): void {
    // Verifica se existe token e pessoa disponíveis.
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      const company = this.authService.getPerson();

      // Chama o serviço para obter a lista de imagens de IoT.
      this.plantService.getEquipmentImageAllList(url, company)
        .pipe(take(1))
        .subscribe(
          data => {
            // Atualiza a lista de imagens de IoT com os resultados obtidos.
            this.equipmentImages = data.results;
            this.equipmentImagesFiltered = data.results;
            // Atualiza as URLs para a próxima e anterior página de resultados paginados.
            if (data.next) {
              this.next = data.next;
            }
            if (data.previous) {
              this.previous = data.previous;
            }
          },
          error => {
            // Exibe um alerta de perigo se não for possível obter as imagens de IoT.
            this.modalAlertService.showAlertDanger('Não foi possível listar imagens de IoTs');
          }
        );
    } else {
      // Exibe um alerta de perigo e realiza logout se não houver ID de aplicativo ou pessoa disponíveis.
      this.modalAlertService.showAlertDanger('Erro ao recuperar imagens de IoTs');
      this.authService.doLogout();
    }
  }


  /**
   * Função que busca os próximos itens paginados usando a URL na propriedade 'next'.
   */
  fetchNext(): void {
    this.readAll(this.next);
  }

  /**
   * Função que busca os itens paginados anteriores usando a URL na propriedade 'previous'.
   */
  fetchPrevious(): void {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de uma imagem de IoT com o ID fornecido.
   *
   * @param id ID da imagem de IoT.
   */
  readId(id): void {
    this.router.navigate(['/plant/equipmentimage/update', id]);
  }

  /** Navega para a página de criação de uma nova imagem de IoT. */
  create(): void {
    this.router.navigate(['/plant/equipmentimage/create']);
  }

  /**
  * Manipula a exclusão de uma imagem de IoT específica.
  *
  * @param item Item a ser excluído.
  */
  onDelete(item): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('delete_equipmentimage') == false) {
      this.router.navigate(['/403']);
    }

    // Define o item selecionado para exclusão.
    this.selectedItem = item;

    // Exibe um modal de confirmação para a exclusão.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir esta imagem de IoT?'
    );

    // Aguarda a resposta do modal de confirmação.
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.plantService.deleteEquipmentImage(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o item excluído da lista.
          let index;
          this.equipmentImages.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.equipmentImages.splice(index, 1);

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Exibe um alerta de perigo em caso de erro na exclusão.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownService } from './services/dropdown.service';
import { IcSelectService } from './ic-select/ic-select.service';
import { HttpClientModule } from '@angular/common/http';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { MultipleSelectUpdateComponent } from './multiple-select-update/multiple-select-update.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { IcSelectComponent } from './ic-select/ic-select.component';
import { PanelListComponent } from './panel-list/panel-list.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FilterWallComponent } from './filter-wall/filter-wall.component';
import { FilterSaveComponent } from './filter-save/filter-save.component';
import { HelpComponent } from './help/help.component';
import { HelpIndexComponent } from './help-index/help-index.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { FieldEngineComponent } from './field-engine/field-engine.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { BootstrapModalAlertComponent } from './bootstrap-modal-alert/bootstrap-modal-alert.component';
import { CompanySwitcherComponent } from './company-switcher/company-switcher.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MyecosNavbarComponent } from './myecos-navbar/myecos-navbar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TruncateTextPipe } from 'src/app/core/custom-pipes/truncate-text/truncate-text.pipe';
import { TableFiltersPopupComponent } from './table-filters-popup/table-filters-popup.component';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { PaginatorControlComponent } from './paginator-control/paginator-control.component';
import { DefaultButtonComponent } from './default-button/default-button.component';
import { DefaultIconButtonComponent } from './default-icon-button/default-icon-button.component';
import { ButtonHowToComponent } from './button-how-to/button-how-to.component';
import { ThreeRenderComponent } from './three-render/three-render.component';
import { ThreeRenderVisualizerComponent } from './three-render-visualizer/three-render-visualizer.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    MultipleSelectComponent,
    MultipleSelectUpdateComponent,
    FileUploadComponent,
    IcSelectComponent,
    PanelListComponent,
    FilterWallComponent,
    FilterSaveComponent,
    HelpComponent,
    HelpIndexComponent,
    FilterSelectComponent,
    CustomSelectComponent,
    FieldEngineComponent,
    BootstrapModalAlertComponent,
    CompanySwitcherComponent,
    MyecosNavbarComponent,
    TableFiltersPopupComponent,
    NotificationBellComponent,
    NotificationPopupComponent,
    PaginatorControlComponent,
    DefaultButtonComponent,
    DefaultIconButtonComponent,
    ButtonHowToComponent,
    ThreeRenderComponent,
    ThreeRenderVisualizerComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSliderModule,
    TooltipModule.forRoot(),
    BsDropdownModule,
    RouterModule
  ],
  providers: [
    DropdownService,
    IcSelectService
  ],
  exports: [
    MultipleSelectComponent,
    MultipleSelectUpdateComponent,
    IcSelectComponent,
    PanelListComponent,
    FilterWallComponent,
    FilterSaveComponent,
    HelpComponent,
    FilterSelectComponent,
    CustomSelectComponent,
    FieldEngineComponent,
    BootstrapModalAlertComponent,
    CompanySwitcherComponent,
    TooltipModule,
    MyecosNavbarComponent,
    TableFiltersPopupComponent,
    DefaultButtonComponent,
    DefaultIconButtonComponent,
    NotificationBellComponent,
    NotificationPopupComponent,
    PaginatorControlComponent,
    ButtonHowToComponent,
    ThreeRenderComponent,
    ThreeRenderVisualizerComponent,
    FileUploadComponent
  ]
})
export class SharedModule {
}

<ng-container *ngIf="layout.layoutType === 'DEFAULT'">
  <div class="section container-fluid tab-content" style="min-height: 500px">
    <div class="section-header">
      <h1 class="header-title-dashboard">IndustryCare Analytics</h1>
    </div>
    <h4 class="mt-3 subtitle">Seja bem-vindo ao sistema IndustryCare</h4>
  </div>
</ng-container>

<ng-container *ngIf="layout.layoutType === 'MYECOS'">
  <div class="section container-fluid tab-content bg-white" style="min-height: 500px">
    <div class="section-header">
      <h1 class="header-title-dashboard">IndustryCare Analytics</h1>
    </div>
    <h4 class="mt-3 subtitle">Seja bem-vindo ao sistema IndustryCare</h4>
  </div>
</ng-container>

<ng-template [ngIf]="authService.getShowTour()">
  <form>
    <div>
      <app-help tourMode="true"></app-help>
    </div>
  </form>
</ng-template>

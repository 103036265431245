import { Group } from '../group.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../auth/auth.service';
import { UsersGroupsService } from '../users-groups.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { BootstrapModalConfirmService } from '../../shared/bootstrap-modal-confirm/bootstrap-modal-confirm.service';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { User } from '@sentry/angular';
import { UsersService } from '../../users/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-users-groups-read',
  templateUrl: './users-groups-read.component.html',
  styleUrls: ['./users-groups-read.component.css']
})
export class UsersGroupsReadComponent implements OnInit {
  users: User[] = []; // Sua lista de usuários
  groups: Group[] = []; // Sua lista de grupos
  groupsFiltered: Group[] = []; // Sua lista de grupos
  userCountPerGroup: Map<number, number>;

  next: string;
  previous: string;

  deleteModalRef: BsModalRef;
  @ViewChild('deleteModal') deleteModal;
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;

  filters: { label: string, type: any, value: any }[] = [
    { label: 'name', type: 'text', value: null },
    { label: 'qtd', type: 'number', value: null },
  ]

  search: FormGroup;
  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  countGroups: any = {}

  offset: number = 0;
  limit: number = 5;

  private urlUsers = this.authService.endpoint + '/users';
  private urlGroups = this.authService.endpoint + '/groups';

  get isHorizontal() {
    return window['whiteLabelConf']('horizontal');
  }

  constructor(
    private authService: AuthService,
    private usersGroupsService: UsersGroupsService,
    private modalService: BsModalService,
    private modalAlertService: BootstrapModalAlertService,
    private modalConformService: BootstrapModalConfirmService,
    private router: Router,
    private usersService: UsersService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.initForm();

    // check if user is enable
    this.authService.isEnable();

    // check permission
    // tslint:disable-next-line:triple-equals
    var is_mdb = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1))

    var is_terceirizada = this.authService && (this.authService.getProfile().indexOf('2') != -1) && this.authService && ((this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('terceirizadomdb') != -1) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1))

    if (is_mdb == false && is_terceirizada == false && this.authService.checkPermission('view_group') == false) {
      this.router.navigate(['/403']);
    }

    this.readAll(this.urlGroups);
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(label: string) {
    this.selectedFilter = label;
    this.isOptionsOpened = !this.isOptionsOpened;
  }

  onHideFilters() {
    this.selectedFilter = null;
    this.isOptionsOpened = false;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const target = this.selectedFilter;
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    filter.value = value;

    this.groupsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[target].localeCompare(b[target]);
      } else {
        return b[target].localeCompare(a[target]);
      }
    })
  }
 
  onReverse() {
    this.groupsFiltered.reverse();
  }

  onSearch(value: string) {

    if(value == '') {
      this.groupsFiltered = this.groups;
      return;
    }

    this.groupsFiltered = this.groupsFiltered.filter((el: any) => el.name.toLowerCase().includes(value))
  }

  onClearFilters() {
    this.groupsFiltered = this.groups;
  }

  onCountUsersByProfile(data) {
    const users = data.results;

    users.forEach(user => {
      user.groups.forEach(grupo => {
          const groupName = grupo.name;
          if (this.countGroups[groupName]) {
              this.countGroups[groupName]++;
          } else {
              this.countGroups[groupName] = 1;
          }
      });
    });

    this.groups.map((gr) => {
      Object.assign(gr, { usersCount: 2 })
    })
    
  }



  readAll(url: string) {
    const person = this.authService.getPerson();

    // Inicialize um array vazio para grupos e usuários
    this.groups = [];
    this.users = [];
    this.userCountPerGroup = new Map<number, number>();

    // Carrega grupos
    this.usersGroupsService.getUsersGroups(url).subscribe(
      data => {
        this.groups = data.results;
        this.groupsFiltered = data.results;

        this.updateUserCountPerGroup();

        if (data.next) {
          this.next = data.next;
        }
        if (data.previous) {
          this.previous = data.previous;
        }
      },
      error => {
        console.log('Aconteceu um erro ao buscar grupos.');
        console.error(error);
      }
    );

    // Carrega usuários
    this.usersService.getUsers(this.urlUsers, person).pipe(take(1)).subscribe(
      data => {
        this.users = data.results;
        this.updateUserCountPerGroup();
        this.onCountUsersByProfile(data);

        if (data.next) {
          this.next = data.next;
        }
        if (data.previous) {
          this.previous = data.previous;
        }
      },
      error => {
        this.modalAlertService.showAlertDanger('Não foi possível listar usuários.');
        console.log('Falha ao buscar usuários:');
        console.error(error);
      }
    );
  }

  

  // function fetches the next paginated items by using the url in the next property
  fetchNext = () => {
    this.readAll(this.next);
  }

  // function fetches the previous paginated items by using the url in the previous property
  fetchPrevious = () => {
    this.readAll(this.previous);
  }

  readId = (id) => {
    this.router.navigate(['/groups/update', id]);
  }

  create = () => {
    this.router.navigate(['/groups/create']);
  }

  onDelete = (item) => {
    this.selectedItem = item;
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este grupo?'
    );
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.usersGroupsService.deleteUsersGroups(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          let index;
          this.groups.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.groups.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

  // Método auxiliar para atualizar a contagem de usuários por grupo
  updateUserCountPerGroup() {
    if (this.groups.length > 0 && this.users.length > 0) {
      this.userCountPerGroup = this.countUsersPerGroup(this.users, this.groups);
    }
  }


  countUsersPerGroup(users: User[], groups: Group[]): Map<number, number> {
    const groupCount = new Map<number, number>();

    // Inicialize a contagem para todos os grupos com zero
    groups.forEach(group => {
      if (group.id !== undefined) {
        groupCount.set(group.id, 0);
      }
    });

    // Conte os usuários em cada grupo
    users.forEach(user => {
      user.groups.forEach(group => {
        const groupId = group.id;
        if (groupId !== undefined) {
          const currentCount = groupCount.get(groupId) || 0;
          groupCount.set(groupId, currentCount + 1);
        }
      });
    });

    return groupCount;
  }


}

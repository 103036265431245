import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bootstrap-modal-alert',
  templateUrl: './bootstrap-modal-alert.component.html',
  styleUrls: ['./bootstrap-modal-alert.component.css']
})
export class BootstrapModalAlertComponent implements OnInit {

  @Input() type = 'success';
  @Input() message: string;
  @Input() divType: string = 'default';

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  onClose = () => {
    this.bsModalRef.hide();
  }
}

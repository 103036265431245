import { BaseFunc } from '../../shared/base-func/base-func';
import { CubePreConfig } from './cube-pre-config';

export class CubeDragEvent extends BaseFunc {
	static lock: any = false;
	static base: BaseFunc = new BaseFunc();

	static up = (event) => {
		if (!CubeDragEvent.lock) return;
		var elm = CubeDragEvent.lock; 
		var obj = elm.object;
		if (obj.pending) {
			obj.pending = 0;
			CubeDragEvent.lock = false;
			return;
        }

		CubeDragEvent.lock = false;
		var exact = CubeDragEvent.base.$j(elm).offset();
		var x = exact.left + parseInt(elm.offsetWidth)/2;
		var y = exact.top + parseInt(elm.offsetHeight)/2;
		var ok = 0;
		for (var i=0;i<obj.targets.length;i++) {
			var t = obj.targets[i];
			var test = (t[1] ? t[1](x,y) : CubeDragEvent.pos(t[0],x,y));
			if (!ok && test) { /* only the first gets executed! */
				ok = 1;
				obj.callback(t[0],x,y);
			}
		}
		if (ok) { 
			/* mouseup at correct place - remove element */
			CubeDragEvent.base.$j(elm).remove();
			
		} else {
			/* mouseup at wrong place - let's animate it back */
			obj.onFail();
			var coords = CubeDragEvent.base.$j(obj.originalElement).offset();
			var x = coords.left;
			var y = coords.top;
			CubeDragEvent.base.$j(elm).animate({ top: y, left: x }, 200, function(){ CubeDragEvent.base.$j(elm).remove(); });
		}
	}; 

	static move = (event) => {
		if (!CubeDragEvent.lock) return;
		event.preventDefault();
		var elm = CubeDragEvent.lock;
		var obj = elm.object;
		if (obj.pending) {
			/* create the duplicate */
			document.body.appendChild(elm);
			elm.style.zIndex = 2000;
			if (obj.process) { obj.process(elm); }
			obj.pending = 0;
		}
		
		//Remove seleção
		var selObj: Selection;
		var isGecko = (!navigator.userAgent.match(/khtml/i) && navigator.userAgent.match(/Gecko/i));
        if (document.getSelection && isGecko) { selObj = document.getSelection(); }
        if (window.getSelection) { selObj = window.getSelection(); }
        if (document['selection']) { selObj = document['selection']; }
        if (selObj) {
            if (selObj.empty) { selObj.empty(); }
            if (selObj.removeAllRanges) { selObj.removeAllRanges(); }
        }

		/* ok, now move */
		var offs_x = event.clientX - elm.mouse_x;
		var offs_y = event.clientY - elm.mouse_y;
		var new_x = parseInt(CubeDragEvent.base.$j(elm).css("left")) + offs_x;
		var new_y = parseInt(CubeDragEvent.base.$j(elm).css("top")) + offs_y;
		elm.style.left = new_x + "px";
		elm.style.top = new_y + "px";
		elm.mouse_x = event.clientX;
		elm.mouse_y = event.clientY;
	}; 

	static pos = (elm,x_,y_) => {
		/* is [x_,y_] inside elm ? */
		if (!elm) return 0;
		if (elm.style.display.toLowerCase() == "none") return 0;
		var coords = CubeDragEvent.base.$j(elm).offset();
		var x = coords.left-2;
		var y = coords.top-2;
		var w = parseInt(elm.offsetWidth)+2;
		var h = parseInt(elm.offsetHeight)+2;
		return (x_ >= x && x_ <= x+w && y_ >= y && y_ <= y+h);
	} 
}

export class CubeDrag extends BaseFunc {

    onFail = function(){};
    sources = [];
    processes = [];
    callbacks = [];
    targets = [];
    pending = 0;
    originalElement;
    callback;
    process;

    constructor() {
        super();
    }

	addSource = (node,process,callback) => {
        var self = this;
		var elm = self.$j(node)[0];
		self.sources.push(elm);
		self.processes.push(process);
		self.callbacks.push(callback);
		var ref = function(event) {
			event.preventDefault();
			var index = self.sources.pivotCustomFind(elm);
			if (index == -1) return;
			var x = event.clientX;
			var y = event.clientY;
			self.startDrag(self.sources[index],self.processes[index],self.callbacks[index],x,y);
		};
		self.$j(elm).mousedown(ref);
	};
	
	delSource = (node) => {
        var self = this;
		var elm = self.$j(node)[0];
		var index = self.sources.pivotCustomFind(elm);
		if (index == -1) { return; }
		self.sources.splice(index,1);
		self.processes.splice(index,1);
		self.callbacks.splice(index,1);
	};
	
	clearSources = () => {
        var self = this;
		self.sources = [];
		self.processes = [];
		self.callbacks = [];
	};
	
	addTarget = (node, customTest, isLast) => {
        var self = this;
		var elm = self.$j(node)[0];
		var newTriple = [elm, customTest, isLast];
		if (self.targets.length && self.targets[self.targets.length-1][2]) {
			/* there is last target */
			self.targets.splice(self.targets.length-1, 0, newTriple);
		} else {
			self.targets.push(newTriple);
		}
	};
	
	delTarget = (node) => {
        var self = this;
		var elm = self.$j(node)[0];
		var index = -1;
		for (var i=0;i<self.targets.length;i++) {
			if (self.targets[i][0] == elm) { index = i; }
		}
		if (index == -1) { return; }
		self.targets.splice(index,1);
	};

	clearTargets = () => {
        var self = this;
		self.targets = [];
	};

	startDrag = (elm,process,callback,x,y) => {
        var self = this;
		if (CubeDragEvent.lock) { return; }
		self.pending = 1;
		self.originalElement = elm;
        self.callback = callback;
        self.process = process;
		var coords = self.$j(elm).offset();
		var obj = self.$j('<div style="position: absolute"></div>')[0];
		obj.style.left = coords.left+"px";
        obj.style.top = coords.top+"px";
        obj.style.width = self.$j(elm).children().css('width');
        obj.style.height = self.$j(elm).children().css('height');
		self.$j(obj).css('opacity', '0.5');
		obj.appendChild(elm.cloneNode(true));
		obj.mouse_x = x;
		obj.mouse_y = y;
		obj.object = self;
		CubeDragEvent.lock = obj;
	};
}

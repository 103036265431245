import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para criar um novo tipo de IOT.
 */
@Component({
  selector: 'app-type-create',
  templateUrl: './type-create.component.html',
  styleUrls: ['./type-create.component.css']
})
export class TypeCreateComponent implements OnInit {

  /**
   * Formulário para o tipo de IOT.
   */
  form: FormGroup;

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método chamado ao inicializar o componente.
   * Verifica se o usuário está habilitado e se tem permissão para adicionar pontos de medição.
   * Inicializa o formulário.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('add_equipmenttype')) {
      this.router.navigate(['/403']);
    };

    // Inicializa o formulário reativo.
    this.initializeForm();
  }

  /**
   * Inicializa o formulário reativo.
   */
  private initializeForm(): void {
    this.form = this.formBuilder.group({
      company: '',
      has_ip: true,
      name: ['', Validators.required],
      is_measurement: true,
    });
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.form.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos de um formulário reativo.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
   * Salva os dados do formulário ao criar um novo tipo de equipamento.
   */
  save = (): void => {
    // Marca o formulário como submetido.
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Preenche os campos de aplicação e empresa no formulário.
        this.form.get('company').setValue(this.authService.getPerson());

        // Chama o serviço para criar um novo tipo de IOT.
        this.isLoading = true;
        this.plantService.createEquipmentType(this.form.value).pipe(take(1)).subscribe(
          data => {
            // Exibe um alerta de sucesso e volta à página anterior.
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          },
          error => {
            // Exibe um alerta de erro em caso de falha ao incluir o registro.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })

      } else {
        // Exibe um alerta de erro em caso de falha na autenticação.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário e marca como não submetido.
   */
  reset = (): void => {
    this.submitted = false;
    this.form.reset();
  }

}

<div class="d-flex flex-column py-2 px-1 hover">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center mb-1">
      <input type="checkbox" [checked]="countCheckeds == 4" (click)="onSelectAllPermissions($event.target.checked)" />
      <span class="mx-2" (click)="onHandleFolder()">{{ scope.name }}</span>
    </div>
    <div class="d-flex align-items-center tour-form-scope-folder">
      <div class="d-flex align-items-center mx-2">
        <span class="text-grey">{{ countCheckeds }}</span>
        <i
          class="fa-solid fa-layer-group"
          [ngClass]="{ 'text-primary': countCheckeds > 0, 'text-grey': countCheckeds == 0 }"
        ></i>
      </div>
      <i class="fa-solid fa-folder-open pointer text-primary" *ngIf="isOpened" (click)="onHandleFolder()"></i>
      <i class="fa-solid fa-folder pointer text-grey tour-form-open" *ngIf="!isOpened" (click)="onHandleFolder()"></i>
    </div>
  </div>
  <div class="tour-form-scope-items">
    <div class="d-flex flex-column px-2" *ngIf="isOpened">
      <div class="d-flex mx-2" *ngFor="let per of scope.permissions">
        <input type="checkbox" [checked]="per.checked" (change)="onCheck(per)" />
        <span class="mx-2">{{ per.label }}</span>
      </div>
    </div>
  </div>
</div>

<section class="section">
  <div class="section-header person-profile-header_update">
      <h1>{{ layout.aliasTitle('Perfil de pessoas') }}</h1>
      <!-- <div class="section-header-breadcrumb person-header-breadcrumb_update">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/personprofiles">Perfil de Pessoa Física/Jurídica</a></div>
          <div class="breadcrumb-item active">Editar</div>
      </div> -->
  </div>

  <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
          <div class="card">
              <div class="card-header">
                  <h4>Editar {{ layout.aliasTitle('Perfil de pessoas') }} </h4>
              </div>
              <div class="card-body">
                  <form method="POST">
                      <div class="form-group">
                          <label for="name">Nome</label>
                          <input type="text" class="form-control mb-2 mr-sm-2" id="name"
                              placeholder="Digite nome do novo perfil" [(ngModel)]="selectedItem.name" name="name">
                      </div>
                      <div class="form-group">
                          <label class="d-block">Status</label>
                          <div class="form-check">
                              <input class="form-check-input" [(ngModel)]="selectedItem.active" type="checkbox"
                                  id="active" [ngModelOptions]="{standalone: true}">
                              <label class="form-check-label" for="active">
                                  Ativo
                              </label>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="card-footer text-right">
                  <button class="btn btn-primary mr-1" type="submit" (click)="update()">Salvar</button>
                  <a class="btn btn-secondary cancel-button" style="color:white" routerLink="/personsperfil">Cancelar</a>
              </div>
          </div>
      </div>
  </div>
</section>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fielderrordisplay',
  templateUrl: './fielderrordisplay.component.html',
  styleUrls: ['./fielderrordisplay.component.css']
})
export class FielderrordisplayComponent implements OnInit {
  @Input() errorMsg: string;
  @Input() displayError: boolean;


  ngOnInit(): void {

  }

} 

<section class="section mx-3 my-3">
    <div class="section-header">
        <h1>{{ layout.aliasTitleByKey(defaultKey) }}</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/sectors">Setor</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 ">
            <div class="card">
                <div class="card-header mx-3">
                    <h4>Adicionar {{ layout.aliasTitleByKey(defaultKey) }}</h4>
                </div>
                <div class="card-body">
                    <form method="POST" [formGroup]="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="logo">Nome</label>
                                <input type="text" class="form-control mb-2 mr-sm-2"
                                formControlName="name"  [ngClass]="displayFieldCss('name')">
                                <div class="invalid-feedback">
                                    Por favor informe o nome
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="logo">Latitude</label>
                                <input type="text" class="form-control mb-2 mr-sm-2"
                                formControlName="latitud" [ngClass]="displayFieldCss('latitud')"  (input)="formatCoordinate('latitud')" maxlength="12">
                                <div class="invalid-feedback">
                                    Valor excede limite de 12 caracteres
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="logo">Longitude</label>
                                <input type="text" class="form-control mb-2 mr-sm-2"
                                formControlName="longitud"  [ngClass]="displayFieldCss('longitud')"  (input)="formatCoordinate('longitud')" maxlength="12">
                                <div class="invalid-feedback">
                                    Valor excede limite de 12 caracteres
                                </div>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="!hiddenFather">
                            <div class="form-group col-md-12">
                                <label for="Sector">{{ layout.aliasTitle('Setor') }} pai</label>
                                <select class="form-control" formControlName="parent">
                                    <option *ngFor="let item of parents" value="{{ item.id }}">
                                        {{item.text}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <ng-container *ngFor="let item of customFields">
                                <app-field-engine
                                [field]="item"
                                [reportFilters]="[]"
                                [hasLabel]="true"
                                #fields
                                #wrapper>
                                </app-field-engine>
                                <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                            </ng-container>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-right mx-3">
                  <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
                  <app-default-button [label]="'Limpar'" [type]="'secondary'" (pressed)="reset()"></app-default-button>
                  <app-default-button [label]="'Cancelar'" [type]="'secondary'" [navigateTo]="'/plant'"></app-default-button>
                </div>
            </div>
        </div>
    </div>
</section>

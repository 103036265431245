import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-button',
  templateUrl: './default-button.component.html',
  styleUrls: ['./default-button.component.css'],
})
export class DefaultButtonComponent implements OnInit {
  @Output() pressed: EventEmitter<boolean> = new EventEmitter();
  @Output() hovered: EventEmitter<boolean> = new EventEmitter();

  @Input() isLoading: boolean = false;
  @Input() label: string = 'Salvar';
  @Input() loadingLabel: string = 'Salvando';
  @Input() type: 'primary' | 'secondary' = 'primary';
  @Input() navigateTo: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onPress() {
    this.pressed.emit(true);
    if (this.navigateTo) {
      this.onNavigate();
    }
  }

  onHover() {
    this.hovered.emit(true);
  }

  onNavigate() {
    this.router.navigate([this.navigateTo]);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CustomReportsService } from '../../reports/custom-reports/custom-reports.service';
import { CustomCrudsService } from '../../cruds/custom-cruds/custom-cruds.service';

@Component({
  selector: 'app-myecos-navbar',
  templateUrl: './myecos-navbar.component.html',
  styleUrls: ['./myecos-navbar.component.css'],
})
export class MyecosNavbarComponent implements OnInit {
  get isHorizontal() {
    return window['whiteLabelConf']('horizontal');
  }

  navbarItems: Set<{ label: string; icon: string; url: string; condition: Function }> = new Set([
    {
      label: 'Home',
      icon: 'fa-solid fa-house fa-xl',
      url: '/',
      condition: () => {
        return this.layout.width >= 500;
      },
    },
  ]);

  configItems: Set<any> = new Set([
    {
      label: 'Usuário',
      icon: 'fa-regular fa-user',
      url: '/users',
      condition: () => this.authService.checkPermission('view_user'),
    },
    {
      label: 'Pessoa Física/Jurídica',
      icon: 'fa-solid fa-users',
      url: '/persons',
      condition: () => this.authService.checkPermission('view_person'),
    },
    {
      label: 'Empresa',
      icon: 'fa-solid fa-industry',
      url: '/company',
      condition: () => this.authService.checkPermission('view_company'),
    },
    {
      label: 'Concessionária',
      icon: 'fa-solid fa-industry',
      url: '/dealerships',
      condition: () => this.authService.checkPermission('view_dealership'),
    },
    {
      label: 'Planta',
      icon: 'fa-solid fa-layer-group',
      url: '/plant',
      condition: () => this.authService.checkPermission('view_sector'),
    },
    {
      label: 'IoT',
      icon: 'fa-solid fa-microchip',
      url: '/plant/equipment',
      condition: () => this.authService.checkPermission('view_equipment'),
    },
    {
      label: 'Alarmes',
      icon: 'fa-regular fa-bell',
      url: '/plant/alarm',
      condition: () => this.authService.checkPermission('view_sector'),
    },
  ]);

  hasConfigVertialOpened: boolean = false;
  isHide: boolean = true;

  get userName() {
    return this.authService.getFullName().split(' ')[0] ?? '';
  }

  mockIcons: any[] = [
    {
      label: 'mapa de equipamentos',
      icon: 'fa-regular fa-map fa-xl',
    },
    {
      label: 'monitoramento',
      icon: 'fa-solid fa-chart-simple fa-xl',
    },
    {
      label: 'indicadores',
      icon: 'fa-solid fa-chart-area fa-xl',
    },
    {
      label: 'análise',
      icon: 'fa-solid fa-magnifying-glass-chart fa-xl',
    },
    {
      label: 'carbon manager',
      icon: 'fa-solid fa-cloud fa-xl',
    },
    {
      label: 'glossário',
      icon: 'fa-solid fa-book-open fa-xl',
    },
  ];

  constructor(
    private customreport: CustomReportsService,
    private customcrud: CustomCrudsService,
    public layout: LayoutService,
    public authService: AuthService,

  ) {}

  ngOnInit(): void {
    this.onFindConfigs();
  }

  onRemoveItemIfAlreadyHome() {
    const initialPage = this.authService.getInitialConf();
    
    if(!initialPage) {
      return;
    }

    const navItems = Array.from(this.navbarItems);
    const navExists = navItems.filter((el) => el.url.includes(initialPage));

    if(navExists.length > 0) {
      this.navbarItems.delete(navExists[0]);
    }
  }

  onHandleConfig() {
    this.hasConfigVertialOpened = !this.hasConfigVertialOpened;
  }

  onHandleHide() {
    this.isHide = !this.isHide;
  }

  onFindConfigs() {
    this.customreport.getCustomReportsList().subscribe((data) => {
      const folders = Object.keys(data.folders);
      const items = data.items;

      if (folders.length == 0 && items.length == 0) {
        return;
      }

      folders.map((fo, index) => {
        const mockIndex = this.mockIcons.findIndex((mo) => fo.toLowerCase() === mo.label);
        this.navbarItems.add({
          label: fo,
          icon: mockIndex !== -1 ? this.mockIcons[mockIndex].icon : 'fa-solid fa-chart-line',
          url: `/custom-report/${data.folders[fo][0]['codi_rep']}`,
          condition: () => (index >= 3 ? false : true),
        });
      });

      items.map((item: any) => {
        const mockIndex = this.mockIcons.findIndex((mo) => item.nome_rep.toLowerCase() === mo.label);
        this.navbarItems.add({
          label: item.nome_rep,
          icon: mockIndex == -1 ? 'icon-Record' : this.mockIcons[mockIndex].icon,
          url: `/custom-report/${item.codi_rep}`,
          condition: () => this.navbarItems.size <= 4,
        });
      });

      this.onRemoveItemIfAlreadyHome();
    });

    this.customcrud.getCustomCrudsList().subscribe((data) => {
      const folders = Object.keys(data.folders_crud);
      const items = data.cruds;

      folders.map((fo, index) => {
        const mockIndex = this.mockIcons.findIndex((mo) => fo.toLowerCase() === mo.label);
        this.configItems.add({
          label: fo,
          icon: mockIndex !== -1 ? this.mockIcons[mockIndex].icon : 'icon-Receipt-3',
          url: `/get_crud/${data.folders_crud[fo][0]['codi_crd']}`,
          condition: () => true,
        });
      });

      items.map((crd) => {
        const mockIndex = this.mockIcons.findIndex((mo) => crd.nome_crd.toLowerCase() === mo.label);
        this.configItems.add({
          label: crd.nome_crd,
          icon: mockIndex == -1 ? 'icon-Record' : this.mockIcons[mockIndex].icon,
          url: `/get_crud/${crd.codi_crd}`,
          condition: () => true,
        });
      });
    });
  }
}

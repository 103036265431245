<!-- Botão flutuante com ícone de empresas -->
<div class="switcher-container shadow-lg" *ngIf="!isHiden && hasPermissionCompanySwitcher()">
  <div *ngIf="!isOpened" class="icon-button card-primary" placement="left" (click)="onHandle()">
    <i class="fa fa-building fa-xl"></i>
  </div>

  <div class="shadow-lg" *ngIf="isOpened">
    <div class="card-primary py-2 px-4 d-flex justify-content-between" (click)="onHandle()">
      <h4 class="text-white mb-0">Alternar Empresa</h4>
      <i class="fa-solid fa-minus text-white"></i>
    </div>

    <form class="d-flex flex-column mt-3 mx-4">
      <div class="d-flex align-items-center mt-4">
        <i class="fa-solid fa-database"></i>
        <span class="mx-2">Selecione a base de dados:</span>
      </div>
      <select class="form-control py-2 mt-2" (click)="onSelectCompany($event.target.value)">
        <option value="" selected disabled>-- Selecione --</option>
        <option value="{{ company }}" *ngFor="let company of data.keys">
          {{ company }}
        </option>
      </select>

      <ng-container *ngIf="databasesByCompany.length > 0">
        <div class="d-flex align-items-center mt-4">
          <i class="fa-solid fa-building"></i>
          <span class="mx-2">Selecione a empresa:</span>
        </div>
        <select class="form-control mt-2 py-2" (click)="onSelectDatabase($event.target.value)">
          <option value="" selected>-- Selecione --</option>
          <option value="{{ i }}"  *ngFor="let db of databasesByCompany; index as i">
            {{ db.person.trade_name }}
          </option>
        </select>
      </ng-container>

      <button class="btn btn-primary w-100 mt-3 mb-3" (click)="onSave()" *ngIf="!isLoading">Salvar</button>
      <button class="btn btn-primary w-100 mt-3 mb-3" disabled *ngIf="isLoading">Salvando...</button>

      <div class="d-flex justify-content-center align-items-center mb-4" (click)="onNavigateToOriginDb()">
        <span class="text-center pointer">Ir para base principal</span>
        <i class="fa-solid fa-chevron-right mx-2"></i>
      </div>
    </form>
  </div>
</div>

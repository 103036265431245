import { Component, OnInit, Input } from '@angular/core';
import { FilterWallService } from './filter-wall.service';
import { BaseFunc } from '../../shared/base-func/base-func';

import '../../shared/base-func/global';
import { Global } from '../../shared/base-func/global';

import { take } from 'rxjs/operators';

@Component({
  selector: 'app-filter-wall',
  templateUrl: './filter-wall.component.html',
  styleUrls: ['./filter-wall.component.css']
})
export class FilterWallComponent extends BaseFunc implements OnInit {
  @Input() key: string = null;
  @Input() compact: boolean = false;
  @Input() event: string = null;
  @Input() callback: string = null;

  id:string;
  formcode:string;
  plain_text:string;
  applyID: any;
  filters:any[] = [];
  config:any;

  cfunc;

  global: Global = new Global();

  constructor(private service: FilterWallService) {
    super()
  }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    this.service.getFilterWallConfig(this.key, this.compact, this.event, this.callback).pipe(take(1)).subscribe(
      data => {
        self.id = data.id;
        self.formcode = data.formcode;
        self.plain_text = data.plain_text;
        self.applyID = data.applyID;
        self.filters = data.filters;
        self.config = true;

        var callback = (self.callback == null ? null : self.callback);
        self.cfunc = ( callback != null ? new Function('name', 'values', callback) : null);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
        //Navegação para um filtro específico configurado na página anterior
        var onloadAct = localStorage.getItem('onloadfilteraction');
        if(onloadAct != null) {
            self.$j('.getfilter[filtername="' + onloadAct + '"]').trigger('click');
            localStorage.removeItem('onloadfilteraction');
        }
      }
    );
  }

  get_filter_click = (event, formCode?, filterName?) => {
    var self = this;
    self.$j(event.currentTarget).prop( "disabled", true );
    //Realiza o preenchimento com o objeto selecionado
    this.service.getFilterWallListFields(formCode, filterName).pipe(take(1)).subscribe(
      data => {
        var dataX = new Array();
        for (var i = 0; i < data.length; i++) {
            dataX[data[i][0]] = data[i][1];
        }
        self.global.clearForm('.managedform');
        self.global.fillFormWithHistoryObject( dataX );
        if(self.cfunc) { self.cfunc('get', dataX); }
        //if(callback) { callback(); }
        self.$j(self.applyID).trigger('click');
        //self.$j('.getfilter').prop( "disabled", false );
        // setTimeout(function(){
        //     $(btn).one("click", function() { get_filter_click(btn) });
        // }, 2000)
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  delete_filter_modal = (event, formcode?, filterName?) => {
    var self = this;
    self.$j('#curr_delete_formcode').val(formcode );
    self.$j('#curr_delete_filtername').val(filterName);
  }

  delete_filter_click = (btn) => {
      var self = this;
      var formCode = self.$j('#curr_delete_formcode').val();
      var filterName = self.$j('#curr_delete_filtername').val();
      this.service.deleteFilter(formCode, filterName).pipe(take(1)).subscribe(
        data => {
          self.$j('.card_filter[title="' + filterName + '"]').remove();
          //self.$j('#deleteModal').modal('hide');
        },
        error => {
          console.log('Aconteceu um erro.', error.message);
        },
        () => {
        }
      );
  }

}

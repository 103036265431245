import { Component, OnInit } from '@angular/core';
import { UploadPanelService } from './upload-panel.service';
import { take } from 'rxjs/operators';
import { BaseFunc } from '../shared/base-func/base-func';
import { Global } from '../shared/base-func/global';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BootstrapModalAlertService } from '../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { $ } from 'protractor';




@Component({
  selector: 'app-upload-panel',
  templateUrl: './upload-panel.component.html',
  styleUrls: ['./upload-panel.component.css']
})
export class UploadPanelComponent extends BaseFunc implements OnInit {

  global: Global = new Global();
  uploadForm: FormGroup;
  template_option = '';
  template;

  constructor(private formBuilder: FormBuilder,
    private modalAlertService: BootstrapModalAlertService,
    private uploadPanel: UploadPanelService,) {
    super();
  }

  ngOnInit(): void {
    this.read_config_data();
  }

  read_config_data = () => {
    var self = this;
    this.uploadPanel.getUploadPanel().pipe(take(1)).subscribe(
      data => {
        self.template = data;
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
        self.uploadForm = self.formBuilder.group({
          source: ['']
        });
      }
    );
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('source').setValue(file);
    }
  }

  onTemplateSelected(event) {
    console.log(event.target.value);
    var self = this;
    self.$j('#upload_panel_body').html('');
    if (event.target.value in this.template) {
      var def = '<div class="row"><div class="col-sm-12 col-lg-12"><div class="card bg-light-info no-card-border"><div class="card-body"><div class="d-flex align-items-center"><b>Descrição: </b> ' +
        this.template[event.target.value]['definition'] +
      '</div></div></div></div>';
      var head = ['#', 'Nome da Coluna', 'Descrição', 'Tipo', 'Unidade', 'Observação'];
      var tabs = this.template[event.target.value]['columns'];
      self.$j('#upload_panel_body').append([ self.$j(def)  ]);
      for (var i = 0; i < tabs.length; ++i) {
        var cols = tabs[i]['cols'];
        var table = '<table class="table table-bordered table-hover dataTable"><thead><tr>' + head.map(x => '<th>' + x + '</th>').join('') + '</tr></thead><tbody>' +
          cols.map((x, i) => '<tr><td>' + ( i + 1 ) + '</td>' + ['name', 'description', 'type', 'unid', 'observation'].map(y => '<td>' + x[y] +'</td>').join('') + '</tr>').join('')  +'</tbody></table>';
        var template_table = `<div class="row">
          <div class="col-lg-12">
              <div class="card bg-light">
                  <div class="card-body">
                      <div class="d-flex no-block align-items-center m-b-10">
                          <h4 class="card-title">Nome da Aba: {title}</h4>
                      </div>
                      <div class="table-responsive">
                        {content}
                      </div>
                  </div>
              </div>
          </div>
        </div>`.replace('{title}', tabs[i]['filename']).replace('{content}', table);

        self.$j('#upload_panel_body').append([ self.$j(template_table) ]);
      }
    }
  }

  submit(e: Event) {
    var self = this;
    e.preventDefault();

    if (!this.validateToServer()) {
      return false;
    }

    const formData = new FormData();
    formData.append('name', this.template_option);
    formData.append('file', this.uploadForm.get('source').value);
    this.uploadPanel.uploadFile(formData).pipe(take(1)).subscribe(
      data => {
        if (data.data.length == 0) {
          self.showSnack(data.message);
          setTimeout(function() { location.reload() }, 2000);
        }
        else
          this.modalAlertService.showAlertDanger(data.message + '<br/><ul>' + data.data.map(x => '<li>' + x + '</li>').join('') + '</ul>');
      },
      error => {
        console.log('Erro');
        console.log(error);
        this.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + error + '</ul>');
      },
      () => {

      }
    );
  }

  validateToServer() {
    var messages = [];
    if ( !(this.template_option != null && this.template_option != '') ) {
      messages.push("Filtro Relatório é obrigatorio.");
      //return false;
    }

    if ( !(this.uploadForm.get('source').value != null && this.uploadForm.get('source').value != '') ) {
      messages.push("Filtro Fonte de Dados é obrigatorio.");
      //return false;
    }

    //It's the right extension
    if( !['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes( this.uploadForm.get('source').value.type ) ) {
      messages.push("Extensão do arquivo é invalida.");
      //return false;
    }

    if (messages.length > 0) {
      this.modalAlertService.showAlertDanger("Entrada inválida<br/><ul>" + messages.map(x => '<li>' + x + '</li>').join('') + '</ul>');
      return false;
    }

    return true;
  }

}

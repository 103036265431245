<!-- Cabeçalho do Relatório-->
<div id="report_header_filters" name="report_header_filters" class="container-fluid " *ngIf="config && config.freq == undefined">
  <div class="row">
    <div class="col-12">
      <div class="card customreport_card">
        <form #f="ngForm" id="headerFormRep_{{reportId}}" name="headerFormRep_{{reportId}}"
          class="form-horizontal managedform" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

          <div id="hidden-div-filters" class="form-body" [hidden]="layout.isFullScreen">
            <div class="form-group tour-report-filters" [hidden]="hasFilters">
              <div class="row customreport_formbody">
                <ng-container *ngFor="let field of reportFields">
                  <app-field-engine #fields #wrapper [field]="field" [reportFilters]="reportFilters" formType="REPORT" ></app-field-engine>
                  <ng-content *ngTemplateOutlet="wrapper.childComponentTemplate"></ng-content>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="form-actions">
              <div class="d-flex justify-content-end align-items-center">
                <div class="tour-report-options">
                  <app-default-icon-button [tooltiptext]="'Expandir'" [type]="'primary'" (pressed)="layout.onHandleFullScreen()">
                    <i class="fa-solid fa-expand fa-xl"></i>
                  </app-default-icon-button>

                  <app-default-icon-button [tooltiptext]="'Filtros'" [type]="'primary'" (pressed)="onHandleFilters()">
                    <i class="fa-solid fa-filter fa-xl"></i>
                  </app-default-icon-button>

                  <app-default-icon-button [tooltiptext]="'Ajuda'" [type]="'primary'" (pressed)="onShowHelper()">
                    <i class="fa-regular fa-circle-question fa-xl"></i>
                  </app-default-icon-button>
                </div>

                <app-help key="{{reportId}}" tourMode="true"></app-help>
                <app-filter-save key='{{ reportId }}' style="display: inline-block;"></app-filter-save>

                <button id="applyFilters" name="applyFilters" type="submit" class="btn btn-primary mr-0 tour-report-save" [disabled]="!form.valid" (click)="applyFilters_btn($event)">
                  {{ 'Consult' | translate }}
                </button>
                <button id="processing" class="btn btn-primary" style="display: none" type="button" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Carregando...
                </button>
              </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- Corpo do Relatório-->
<div id="report_body" name="report_body" class="report_body container-fluid px-0" style="min-height: 400px" *ngIf="form">
  <div class="col-12">
    <div id="report-content" name="report-content" class="grid-stack report-content-body" style="display: none;" [ngSwitch]="true">

      <!-- Layoyt de cards resultados de consulta do banco de dados -->
      <ng-container *ngSwitchCase=" ( config.seeds != null && config.seeds.length > 0 ) ">
        <div *ngFor="let cell of config.seeds" id="fieldset_{{cell.id}}" [attr.name]="( 'fieldset_' + cell.id )"
          class='grid-stack-item ui-draggable ui-resizable ui-resizable-autohide' [attr.gs-x]="cell.x"
          [attr.gs-y]="cell.y" [attr.gs-w]="cell.width" [attr.gs-h]="cell.height">
          <div class="px-3 py-3 card-body grid-stack-item-content report-content-cards fixed-header-table-custom"
            [innerHTML]="cell.templateSanitized">
          </div>
        </div>

        <div *ngIf="config.addContent != null" [outerHTML]="config.addContentSanitized">
        </div>
      </ng-container>
      <!-- Layout de relatório com somente 1 tabela  -->
      <ng-container *ngSwitchCase="cellList.length == 1 && cellList[0].type_rbd == 'table' ">
        <!-- Conteúdo acima do asset -->
        <div id="customReportUpperAuxCell_{{cellList[0].codi_rbd}}" class="auxDivClass customReportUpperAuxCell_{{cellList[0].codi_rbd}}"></div>


        <!-- Botões de Download de tabela como CSV, XLSX -->
        <button id="downloadTable_{{cellList[0].codi_rbd}}" name="downloadTable_{{cellList[0].codi_rbd}}" type="button"
          class="btn btn-box-tool d-inline float-right csv_onlycell">
          <i class="fa fa-download"></i>
        </button>

        <div class="input-group rounded filter-table-div">
          <input type="search" id="unique-table-report" class="form-control rounded filter-table-input"
            placeholder="Filtragem de informações">
          <span class="input-group-text border-0">
            <i class="fas fa-search"></i>
          </span>
        </div>

        <div #cells id="customReportCell_{{cellList[0].codi_rbd}}"
          class="col-12 overflow-auto fixed-header-table-custom">
          <div class="table-responsive customReportCell">
          </div>
        </div>
      </ng-container>


      <!-- Layout Geral de Grid de Assets -->
      <ng-container id="" *ngSwitchDefault>
        <div #cells *ngFor="let cell of cellList" id="fieldset_{{cell.codi_rbd}}"
          class='/*card*/ grid-stack-item ui-draggable-disabled ui-resizable-disabled'
          [attr.gs-x]="cell.x" [attr.gs-y]="cell.y" [attr.gs-w]="cell.width" [attr.gs-h]="cell.height" >
          <div class=" px-3 py-3 card-body grid-stack-item-content {{ cell.type_rbd == 'table' ? 'tableOverflowCR' : '' }} {{cell.upperclass}} report-content-cards" [ngSwitch]="true">
            <div class="align-items-center">
              <div id="head_cell_row_{{cell.codi_rbd}}" name="head_cell_row_{{cell.codi_rbd}}" class="row">
                <h4 id="title_{{cell.codi_rbd}}" name="title_{{cell.codi_rbd}}"
                 class="{{ hasTitleControls(cell, 'image') || hasTitleControls(cell, 'sheet') || hasTitleControls(cell, 'graph') ? 'col-7' : 'col-11' }} card-title_alt align-self-center">
                  <span>{{cell.nome_rbd}}</span>
                </h4>

                <div class="col-5 align-self-center" *ngIf="hasTitleControls(cell, 'image') || hasTitleControls(cell, 'sheet') || hasTitleControls(cell, 'graph')">
                  <!-- Botões de Download de Imagem do Gráfico como PNG, JPG e Bitmap -->
                  <div *ngIf=" hasTitleControls(cell, 'image') " class="d-inline float-right">
                    <button id="bnt_drillup" class="btn btn-box-tool dropdown-toggle">
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button id="btn_download_graph_{{cell.codi_rbd}}" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" class="btn btn-box-tool dropdown-toggle">
                      <i class="fa fa-download"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btn_download_graph_{{cell.codi_rbd}}">
                      <div class="downloadGraphSection_{{cell.codi_rbd}} inner_dropdown" style="margin: 15px;">
                        <button dtype="png" type="button" class="btn btn-block btn-default"> Baixar em PNG
                        </button>
                        <button dtype="jpg" type="button" class="btn btn-block btn-default"> Baixar em JPG
                        </button>
                        <button dtype="bmp" type="button" class="btn btn-block btn-default"> Baixar em BMP
                        </button>
                        <button dtype="xls" id="downloadTable_{{cellList[0].codi_rbd}}"
                          name="downloadTable_{{cellList[0].codi_rbd}}" type="button" class="btn btn-block btn-default img_xls">
                          Baixar em XLS
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Botões de Download de tabela como CSV, XLSX -->
                  <button *ngIf=" hasTitleControls(cell, 'sheet') "
                    id="downloadTable_{{cell.codi_rbd}}" name="downloadTable_{{cell.codi_rbd}}" type="button"
                    class="btn btn-box-tool d-inline float-right csv_xlsx">
                    <i class="fa fa-download"></i>
                  </button>

                  <!-- Botões para alteração do tipo de gráfico -->
                  <div *ngIf=" hasTitleControls(cell, 'graph') "
                    id="graphpanel_{{cell.codi_rbd}}" name="graphpanel_{{cell.codi_rbd}}" class="box-tools float-right"
                    style="display: inline;">
                    <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                      class="btn btn-box-tool dropdown-toggle">
                      <i class="fas fa-cogs" title="Opções do Gráfico"></i>
                    </button>
                    <div class="dropdown-menu not_report" onclick="event.stopPropagation();" aria-labelledby="fieldset_{{cell.codi_rbd}}">
                      <div id="regression_div_{{cell.codi_rbd}}" class="regression_div">
                      </div>

                      <h5>Visualizações</h5>
                      <button gtype="origin" type="button" class="btn btn-box-tool">
                        <i class="fa fa-registered" title="Original"></i>
                      </button>
                      <button gtype="pie" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-pie" title="Pizza"></i>
                      </button>
                      <button gtype="line" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-line" title="Linha"></i>
                      </button>
                      <button gtype="scatter" type="button" class="btn btn-box-tool">
                        <i class="mdi mdi-chart-scatterplot-hexbin" title="Dispersão"></i>
                      </button>
                      <button gtype="bubble" type="button" class="btn btn-box-tool">
                        <i class="mdi mdi-chart-bubble" title="Bolhas"></i>
                      </button>
                      <button gtype="histogram" type="button" class="btn btn-box-tool">
                        <i class="mdi mdi-chart-histogram" title="Histograma"></i>
                      </button>
                      <button gtype="boxplot" type="button" class="btn btn-box-tool">
                        <i class="mdi mdi-textbox fa fa-rotate-90" title="Boxplot"></i>
                      </button>
                      <button gtype="area" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-area" title="Área"></i>
                      </button>
                      <button gtype="column" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-bar" title="Colunas"></i>
                      </button>
                      <button gtype="stackedcolumn" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-bar" title="Colunas Empilhadas"></i>
                        <sup>E</sup>
                      </button>
                      <button gtype="bar" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-bar fa-rotate-90" title="Barras"></i>
                      </button>
                      <button gtype="stackedbar" type="button" class="btn btn-box-tool">
                        <i class="fas fa-chart-bar fa-rotate-90" title="Barras Empilhadas"></i>
                        <sup>E</sup>
                      </button>
                      <button gtype="radar" type="button" class="btn btn-box-tool">
                        <i class="fas fa-circle-notch" title="Radar"></i>
                      </button>
                      <button gtype="doughnut" type="button" class="btn btn-box-tool">
                        <i class="fas fa-dot-circle" title="Donut"></i>
                      </button>

                      <button id="show_comboForm_{{cell.codi_rbd}}" name="show_comboForm_{{cell.codi_rbd}}"
                        class="btn btn-box-tool">
                        <i class="far fa-edit" title="Combo"></i>
                      </button>
                      <div id="div_comboForm_{{cell.codi_rbd}}" name="div_comboForm_{{cell.codi_rbd}}"
                        style="display: none;">
                        <div id="comboForm_{{cell.codi_rbd}}" name="comboForm_{{cell.codi_rbd}}" style="margin: 15px;">
                          <div class="comboFormFields">

                          </div>
                          <button class="btn btn-primary ComboApply">
                            Aplicar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Conteúdo acima do asset -->
            <div id="customReportUpperAuxCell_{{cell.codi_rbd}}" class="auxDivClass customReportUpperAuxCell_{{cell.codi_rbd}}"></div>

            <!-- Escolha do asset -->
            <div *ngSwitchCase=" cell.type_rbd == 'text' && cell.addContent != null "
              id="customReportCell_{{cell.codi_rbd}}"
              class="customReportCell_{{cell.codi_rbd}} notClear textComp customReportCell overflow-auto"
              [innerHTML]="cell.addContentSanitized"></div>
            <div *ngSwitchCase=" cell.type_rbd == 'text' && cell.addContent == null "
              id="customReportCell_{{cell.codi_rbd}}"
              class="customReportCell_{{cell.codi_rbd}} notClear textComp customReportCell overflow-auto"></div>
            <div *ngSwitchCase=" cell.type_rbd == 'tree' " id="customReportCell_{{cell.codi_rbd}}"
              class="customReportCell_{{cell.codi_rbd}} treeComp customReportCell overflow-auto"></div>
            <div *ngSwitchCase=" cell.type_rbd == 'table' || cell.type_rbd == 'correlationmatrix' " id="customReportCell_{{cell.codi_rbd}}"
              class="customReportCell_{{cell.codi_rbd}} tableComp customReportCell overflow-auto fixed-header-table-custom">
            </div>
            <div *ngSwitchCase=" cell.type_rbd == 'tb_pagination' " id="customReportCell_{{cell.codi_rbd}}"
              class="customReportCell_{{cell.codi_rbd}} tableComp customReportCell overflow-auto fixed-header-table-custom">

              <table class='table table-bordered table-hover dataTable ' role='grid' width='100%'>
                <thead>
                  <tr>
                    <th class=""
                      *ngFor="let head of ( (tables[cell.codi_rbd.toString()] && tables[cell.codi_rbd.toString()].header) || defaultTableConfig.header)">
                      {{head}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let row of ( (tables[cell.codi_rbd.toString()] && tables[cell.codi_rbd.toString()].rows) || defaultTableConfig.rows)">
                    <td role="row" *ngFor="let r of row">
                      {{r}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li *ngIf="prevPage" class="page-item disabled=false">
                    <a class="page-link" (click)="fetchPrevious()">Anterior</a>
                  </li>
                  <li *ngIf="nextPage" class="page-item">
                    <a class="page-link" (click)="fetchNext()">Próximo</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div *ngSwitchCase=" cell.type_rbd == 'map' " id="customReportCell_{{cell.codi_rbd}}" class="customReportCell_{{cell.codi_rbd}} map-height" >
            </div>

            <div *ngSwitchDefault style="height: 75%">
              <canvas id="customReportCell_{{cell.codi_rbd}}" class="customReportCell_{{cell.codi_rbd}} graphComp customReportCell {{cell.bottomclass}}"></canvas>
            </div>

            <!-- Conteúdo abaixo do asset -->
            <div id="customReportAuxCell_{{cell.codi_rbd}}" class="auxDivClass customReportAuxCell_{{cell.codi_rbd}}"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
<!--
  <ng-container *ngIf="information.length > 0">
    <div class="row">
      <div class="col-12" *ngFor="let item of information">
        <app-three-render [data]="item" [images]="fileAssets"></app-three-render>
      </div>
    </div>
  </ng-container> -->


  <div id="report_wall" name="report_wall" *ngIf="config.freq == undefined">
    <app-filter-wall class="favoriteReportList" key='{{reportId}}' event='#applyFilters'>
    </app-filter-wall>
  </div>

  <app-button-how-to [sequence]="tourSequence"></app-button-how-to>
</div>


<!-- General Modal -->
<div id="form-container" class="modal fade over-all-assets" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-xl" role="document" id="popoverform_{id}" name="popoverform_{id}" style="max-width: 95%">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
          (click)='closeGeneralModal()'>×</button>
      </div>
      <div id="custom-modal-body" class="modal-body" style="overflow-y: auto; max-height: 500px;">

      </div>
    </div>
  </div>
</div>


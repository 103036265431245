import { Component, OnInit } from '@angular/core';
import { StatusService } from './status.service';
import { take } from 'rxjs/operators';
import { $ } from 'protractor';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  showMailService: boolean = false;
  showMainService: boolean = false;
  showRepoService: boolean = false;
  showFormService: boolean = false;
  showUserAuth: boolean = false;
  showSimulate: boolean = false;
  showBotService: boolean = false;

  constructor(
    private statusActive:StatusService
  ) { }

  ngOnInit(): void {
    var seconds = 10;
    var self = this;
    var process_number:any = {};

    var updateFunc = function(){
      if (Array.from(document.querySelectorAll('#tabela')).length == 0)
        clearInterval(process_number);

      self.statusActive.getStatusMail().pipe(take(1)).subscribe(
        data => {
          self.showMailService = true;

        },
        error => {
          self.showMailService = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusRepo().pipe(take(1)).subscribe(
        data => {
          self.showRepoService = true;

        },
        error => {
          self.showRepoService = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusForm().pipe(take(1)).subscribe(
        data => {
          self.showFormService = true;

        },
        error => {
          self.showFormService = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusMain().pipe(take(1)).subscribe(
        data => {
          self.showMainService = true;

        },
        error => {
          self.showMainService = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusUserAuth().pipe(take(1)).subscribe(
        data => {
          self.showUserAuth = true;

        },
        error => {
          self.showUserAuth = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusSimulate().pipe(take(1)).subscribe(
        data => {
          self.showSimulate = true;

        },
        error => {
          self.showSimulate = false;
        },
        () => {

        }
      );
      self.statusActive.getStatusBot().pipe(take(1)).subscribe(
        data => {
          self.showBotService = true;

        },
        error => {
          self.showBotService = false;
        },
        () => {

        }
      );
    };
    process_number = setInterval(updateFunc, seconds * 1000);
    updateFunc();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CubeComponent } from './cube/cube.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { SceneryBuilderComponent } from './scenery-builder/scenery-builder.component';
import { SceneryComparisonComponent } from './scenery-comparison/scenery-comparison.component';

@NgModule({
  declarations: [
    ReportBuilderComponent,
    CustomReportsComponent,
    CubeComponent,
    SceneryBuilderComponent,
    SceneryComparisonComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    ReportBuilderComponent,
    CustomReportsComponent,
  ]
})
export class ReportsModule { }

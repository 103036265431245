import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { Global } from 'src/app/components/shared/base-func/global';
import { FieldEngineComponent } from 'src/app/components/shared/field-engine/field-engine.component';
import { BaseFunc } from 'src/app/components/shared/base-func/base-func';

/**
 * Componente Angular responsável pela criação de pontos (Local de medição).
 */
@Component({
  selector: 'app-point-create',
  templateUrl: './point-create.component.html',
  styleUrls: ['./point-create.component.css']
})
export class PointCreateComponent extends BaseFunc implements OnInit  {
  @ViewChildren('fields') fields: QueryList<FieldEngineComponent>;

  /**
   * Formulário para criação de pontos.
   */
  form: FormGroup;

  /**
   * Lista de setores disponíveis.
   */
  sectors = "";

  /**
   * Indica se o formulário foi submetido.
   */
  submitted = false;

  isLoading: boolean = false;
  cusstomFields: any[] = []


  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService,
  ) {  super() }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    this.onFindCustomField();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('add_point')) {
      this.router.navigate(['/403']);
    };

    let company = this.authService.getPerson();
    this.plantService.getSectorAllList(this.authService.endpoint_main + 'plant/sector/list/nopage', company).pipe(take(1)).subscribe(
      data => {
        this.sectors = data;
      }, error => {
        console.error(error);
      }
    );

    this.form = this.formBuilder.group({
      company: [''],
      sector: ['', Validators.required],
      name: ['', Validators.required],
      latitud: ['', [Validators.maxLength(12), Validators.pattern(/^[-]?\d+(\.\d+)?$/)]],
      longitud: ['', [Validators.maxLength(12), Validators.pattern(/^[-]?\d+(\.\d+)?$/)]],
    });
  }

  convertField(fd): any {
    return {
      'label': fd.name,
      'type': fd.type != 'AUTOCOMPLETE' ? fd.type : fd.autocomplete_type,
      'is_autocomplete': fd.type == 'AUTOCOMPLETE',
      'default_value': fd.default_value != null ? fd.default_value.text : null,
      'obg': fd.obg,
      'unique': fd.unique,
      'limit': fd.limit != 'ilimitado' ? parseInt(fd.limit) : null,
      'items': fd.items != null ? fd.items.map(x=>[x.text]) : [],
      'count': fd.count,
      'dom_id': fd.dom_id,
      'icon': ""
    };
  }

  onFindCustomField() {
    this.plantService.onFindCustomFields('PON').subscribe((data) => {
      // this.onPrepareData(data.fields);
      this.cusstomFields = data;
    })
  }

  onPrepareData(fields: any) {
    const customFields = fields.map((el, index) => {
      return {
        ...el,
        obg: this.isRequired(el.type),
        items: this.onPrepareItems(el.type),
        type: this.onPrepareType(el.type),
        positionField: 'col-12 col-md-6 col-lg-6',
        dom_id: `${el.name.toLowerCase()}${index}`,
        count: index,
      }
    })
    this.cusstomFields = customFields
  }

  
  onPrepareType(type: string) {
    if(type.includes('SELECT')) { return 'SELECT' }

    const [value, _] = type.split(':')
    return value
  }

  onPrepareItems(type: string) {
    if(!type.includes('SELECT')) { return null }

    // "minute<^^>Minuto|hour<^^>Hora|day<^^>Dia|month<^^>Mês"
    const match = type.match(/\{([^}]+)\}/)

    if(match.length > 0) { 
      const pars = match[1].split('|')

      const result = pars.map(el => {
        const [value, text] = el.split('<^^>')
        
        return {
          value, text
        }
      })
      return result
    }
  }

  isRequired(type: string) {
    const splited = type.split(':')
    if(splited.length == 0) { return false }

    if(splited[1].includes('OBG')) { return true }
  }


  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns - Retorna `true` se o campo não for válido e o formulário tiver sido submetido; caso contrário, retorna `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna uma classe CSS condicional para destacar campos inválidos no formulário.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns - Objeto CSS com a classe 'is-invalid' se o campo for inválido e o formulário tiver sido submetido.
   */
  displayFieldCss(field: string): { 'is-invalid': boolean } {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Getter conveniente para fácil acesso aos campos do formulário.
   */
  get f() { return this.form.controls; }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup - Grupo de formulários a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Salva as informações do formulário.
   */
  save = (): void => {
    this.submitted = true;

    const global = new Global()
    const result = global.saveData()


    if (this.form.valid) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {

        this.form.get('company').setValue(this.authService.getPerson());

        const payload = {
          ...this.form.value,
          ...result
        }

        console.log(payload);

        
        this.isLoading = true;
        this.plantService.createPoint(payload).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            console.error(error);
          }
        ).add(() => {
          scrollTo(0,0)
          this.isLoading = false;
        });

      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o formulário.
   */
  reset = (): void => {
    this.submitted = false;
    this.form.reset();
  }

  /**
   * Formata o valor da coordenada no campo de formulário especificado.
   *
   * @param field - O nome do campo de formulário que contém o valor da coordenada.
   */
  formatCoordinate(field: string) {
    // Obtém o valor atual do campo de formulário especificado
    let currentValue = this.form.get(field).value;

    // Remove caracteres não numéricos do valor
    currentValue = currentValue.replace(/[^0-9.-]/g, '');

    // Atualiza o valor no formulário sem acionar um evento
    this.form.get(field).setValue(currentValue, { emitEvent: false });
  }
}

import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.css'],
})
export class NotificationPopupComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() notifications: any[] = [];

  constructor(public headerService: HeaderService) {}

  ngOnDestroy(): void {
    this.onMarkAllAsRead();
  }

  ngOnInit(): void {}

  onMarkAllAsRead() {
    this.headerService.postNofificationList(this.notifications).subscribe(console.log);
  }

  onClose() {
    this.close.emit(true);
    this.onMarkAllAsRead();
  }
}

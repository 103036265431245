import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './../../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersProfilesService {

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json; charset=utf-8', 
      'Server-Context': this.authService.server_context, 
      'Authorization': `JWT ${this.authService.getToken()}`,
      'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] )
    }),
      }
  params = new HttpParams();

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  getUsersProfiles(url: string, params): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Server-Context': this.authService.server_context, 'Authorization': `JWT ${this.authService.getToken()}`, 'uw': JSON.stringify( [ this.authService.getReportDb(), this.authService.getUserId(), this.authService.getPerson(), 10000* Math.random() ] )
    }),
      params: this.params.set('person', params)
    })
  }

  createUsersProfiles(profile): Observable<any> {
    const body = { name: profile.name, person: profile.person, app_id: profile.app_id };
    return this.http.post(this.authService.endpoint + '/profiles/create', body, this.httpOptions );
  }

  deleteUsersProfiles(id): Observable<any> {
    return this.http.delete(this.authService.endpoint + '/profiles/delete/' + id, this.httpOptions);
  }

  updateUsersProfiles(profile): Observable<any> {
    const body = { name: profile.name, person: profile.person, app_id: profile.app_id };
    return this.http.put(this.authService.endpoint + '/profiles/update/' + profile.id, body, this.httpOptions);
  }

  detailUserProfile(id): Observable<any> {
    return this.http.get(this.authService.endpoint + '/profiles/' + id, this.httpOptions);
  }

}

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { DealershipService } from './../dealership.service';
import { Company } from '../../company/shared/model/company-division.model';
import { Person } from './../../person/person.model';
import { PersonService } from './../../person/person.service';
import { take } from 'rxjs/operators';
import { FieldEngineComponent } from '../../shared/field-engine/field-engine.component';
import { BaseFunc, IntermediateFormat } from '../../shared/base-func/base-func';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-dealership-create',
  templateUrl: './dealership-create.component.html',
  styleUrls: ['./dealership-create.component.css'],
})
export class DealershipCreateComponent extends BaseFunc implements OnInit {
  @ViewChildren('fields') fields: QueryList<FieldEngineComponent>;
  @ViewChildren('wrapper') wrapper: QueryList<FieldEngineComponent>;

  form: FormGroup;
  persons: Person[];
  companies: Company[];

  hour_capacitive_start = '';
  hour_capacitive_end = '';
  hour_point_start = '';
  hour_point_end = '';

  submitted = false;

  isLoading: boolean = false;

  data: any;

  selectedConcessionary: any;

  onSelectConcessionary() {
    const index = this.form.get('concessionary').value;
    this.selectedConcessionary = this.data[index];
  }

  onSubmit() {
    const payload = {
      person: this.form.get('person').value,
      company: this.authService.getPerson(),
      concessionary: this.selectedConcessionary.type,
      fields: this.selectedConcessionary.items.map((el: any) => {
        return {
          field_id: el.field.id,
          field_name: el.field.name,
          value: this.$j(`#hourField${el.field.count}`).val(),
        };
      }),
    };

    const hasEmpty = payload.fields.some((el) => !el.value)
    if(!hasEmpty) {
      this.dealershipService.onCreateDealer(payload).subscribe((data) => {
        this.modalAlertService.showAlertSuccess('Concessionária criada com sucesso!')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private dealershipService: DealershipService,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    // check if user is enable
    this.authService.isEnable();

    // check permission
    if (this.authService.checkPermission('add_dealership') == false) {
      this.router.navigate(['/403']);
    }

    // get all person
    this.personService.getPersons(this.authService.endpoint_main + 'person/list/nopage').subscribe(
      (data) => {
        this.persons = data;
      },
      (error) => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
      },
    );

    this.form = this.formBuilder.group({
      company: [''],
      person: ['', Validators.required],
      concessionary: ['', Validators.required],
      hour_point_start: [this.hour_point_start, Validators.required],
      hour_point_end: [this.hour_point_end, Validators.required],
      hour_capacitive_start: [this.hour_capacitive_start, Validators.required],
      hour_capacitive_end: [this.hour_capacitive_end, Validators.required],
    });

    this.onListDealerFields();
  }

  onListDealerFields() {

    this.dealershipService.onListFieldsToCreateDealer().subscribe((data) => {
      this.data = Object.entries(data).map(([key, value]) => {
        return { type: key, items: value };
      });
    })
 
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field),
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

  save = () => {
    this.submitted = true;

    if (this.form.valid) {
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        this.form.get('company').setValue(this.authService.getPerson());

        this.isLoading = true;
        this.dealershipService
          .createDealership(this.form.value)
          .pipe(take(1))
          .subscribe(
            (data) => {
              this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
              this.location.back();
            },
            (error) => {
              this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            },
          )
          .add(() => {
            scrollTo(0, 0);
            this.isLoading = false;
          });
      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      this.validateAllFormFields(this.form);
    }
  };

  reset = () => {
    this.submitted = false;
    this.form.reset();
  };
}

<aside  [ngClass]="isHorizontal ? 'horizontal-sidebar' : 'left-sidebar'" *ngIf="layout.loadedTheme">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar" *ngIf="!isHorizontal">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">
      <ul id="logo-container" *ngIf="!layout.showLogoMini">
        <li class="nav-item Fd-none d-md-block ">
          <a class="nav-link sidebartoggler waves-effect waves-light" id="logo-link">
            <img [src]="navlogo_wl" class="light-logo" alt="homepage" />
          </a>
        </li>
      </ul>
      <ul id="logo-container-mini" *ngIf="layout.showLogoMini">
        <li class="nav-item Fd-none d-md-block">
          <a class="nav-link sidebartoggler waves-effect waves-light" data-sidebartype="mini-sidebar">
            <img [src]="navlogomini_wl" class="light-logo" alt="homepage" />
          </a>
        </li>
      </ul>

      <!-- Nav referente ao layout Analytics -->
      <ul id="sidebarnav ps-container"  [ngClass]="isHorizontal ? 'horizontal-ecos' : 'vertical-analytics'">
        <!-- End Registers-->
        <li class="sidebar-item tour-home" *ngIf="!isHorizontal">
          <a routerLink="/dashboard"  class="sidebar-link waves-effect waves-dark">
            <i class="mdi mdi-home-outline"></i>
            <span class="hide-menu mx-2"> Início </span>
          </a>
        </li>

    

        <!-- Dropdown do layout horizontal -->
        <li class="sidebar-item dropdown tour-home" *ngIf="isHorizontal">
          <a routerLink="/dashboard"  class="sidebar-link waves-effect waves-dark">
            <i class="mdi mdi-home-outline mt-0"></i>
            <span class="hide-menu mx-2"> Início </span>
          </a>
          <div class="dropdown-menu"></div>
        </li>


        <li class="sidebar-item dropdown d-flex align-items-start" *ngIf="isHorizontal">
            <a class="sidebar-link waves-effect waves-dark tour-cadastro-ecos"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="icon-Add-User"></i>
              <span class="hide-menu mx-2"> Cadastros </span>
              <i class="fas fa-angle-down"></i>
            </a>
              <div class="dropdown-menu">
                <div *ngIf="showUserAuth" >
                  <a *ngIf="authService.checkPermission('view_user')" class="dropdown-item dropdown-link waves-effect waves-dark"  [routerLink]="['/users']" aria-expanded="false">
                    <i class="icon-Add-User"></i>
                    <span class="hide-menu mx-2"> {{ "Usuário" | translate }} </span>
                  </a>
                </div>
                <div *ngIf="showMainService">
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_person')">
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/persons" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "Pessoa Física/Jurídica" | translate }} </span>
                    </a>
                  </li>
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_company')" >
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/company" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "Empresa" | translate }} </span>
                    </a>
                  </li>
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_dealership')" >
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/dealerships" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "Concessionária" | translate }} </span>
                    </a>
                  </li>
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_sector')">
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/plant" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "Planta" | translate }} </span>
                    </a>
                  </li>
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_equipment')" >
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/plant/equipment" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "IoT" | translate }} </span>
                    </a>
                  </li>
                  <li class="sidebar-item" *ngIf="authService.checkPermission('view_equipment')" >
                    <a class="dropdown-item dropdown-link waves-effect waves-dark" routerLink="/plant/alarm" aria-expanded="false">
                      <i class="icon-Add-User"></i>
                      <span class="hide-menu mx-2"> {{ "Alarmes" | translate }} </span>
                    </a>
                  </li>
                </div>
              </div>
        </li>
        <li class="sidebar-item dropdown tour-analytics-ecos" *ngIf="isHorizontal && showMenuElement('Analytics', false)">
            <a class="sidebar-link waves-effect waves-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="mdi mdi-view-dashboard"></i>
              <span class="hide-menu mx-2"> Analytics </span>
              <i class="fas fa-angle-down fa-xs"></i>
            </a>
              <div class="dropdown-menu">
                <li *ngFor="let item of folders | keyvalue" class="sidebar-item">
                  <a class="dropdown-item dropdown-link waves-effect waves-dark" *ngIf="!isInitialPage(item.value[0].codi_rep)" routerLink="/custom-report/{{item.value[0].codi_rep}}"
                    aria-expanded="false">
                    <i class="mdi mdi-chart-areaspline"></i>
                    <span class="hide-menu mx-2">{{item.key}}</span>
                  </a>
                </li>

                <li *ngFor="let r of items" class="sidebar-item">
                  <a routerLink="/custom-report/{{r.codi_rep}}" class="dropdown-item dropdown-link waves-effect waves-dark">
                    <i class="icon-Record"></i>
                    <span class="hide-menu mx-2"> {{r.nome_rep}}</span>
                  </a>
                </li>

              </div>
        </li>
        <!-- Fim Dropdown do layout horizontal -->

        <ng-container *ngIf="!isHorizontal">
          <!-- Reports-->
          <ng-template [ngIf]=" showMenuElement('Analytics', false) ">
            <li id="reports-tour" class="nav-small-cap tour-analytics" >
              <span class="hide-menu"> {{ "Analytics" }} </span>
            </li>
          </ng-template>
          <span class="helpTag_1" position="L_MARGIN" help_type="Component" ref_to="#reports-tour" style="display: none;">
            <h2 class="help_title">Analytics</h2>
            <p class="help_body">Este campo é responsável por apresentar informações sobre parâmetros analíticos e de
              monitoramento por meio de tabelas, gráficos e dashboards.</p>
          </span>
          <!-- End Reports-->

          <!-- Custom Reports-->

          <li *ngFor="let item of dataService.folders | keyvalue" class="sidebar-item">
            <!-- passar como parametros também o nome da folder (item) clicada -->
            <a class="sidebar-link waves-effect waves-dark" routerLink="/custom-report/{{item.value[0].codi_rep}}"
              aria-expanded="false">

              <i class="mdi mdi-chart-areaspline"></i>
              <span class="hide-menu mx-2">{{item.key}}</span>
            </a>
          </li>

          <li *ngFor="let r of dataService.items" class="sidebar-item">
            <a routerLink="/custom-report/{{r.codi_rep}}" class="sidebar-link">
              <i class="icon-Record"></i>
              <span class="hide-menu mx-2"> {{r.nome_rep}}</span>
            </a>
          </li>
        </ng-container>

        <!-- <ng-template
          [ngIf]="this.authService && ( ( this.authService.getPersonName() && this.authService.getPersonName().toLowerCase().indexOf('anglo') != -1 ) || (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('anglo') != -1 ) )">
          <li class="sidebar-item">
            <a routerLink="/panel-list/scenary" class="sidebar-link waves-effect waves-dark">
              <i class="icon-Bar-Chart5"></i>
              <span class="hide-menu"> Simulação de cenários </span>
            </a>
          </li>
        </ng-template>-->

        <!-- End Custom Reports-->

        <ng-template [ngIf]=" showMenuElement('Construtor', false) ">
          <li id="custom-reports-tour" class="nav-small-cap tour-constructor">
            <span class="hide-menu"> {{ "Construtor" }} </span>
          </li>
        </ng-template>
        <span class="helpTag_1" position="L_MARGIN" help_type="Component" ref_to="#custom-reports-tour" style="display: none;">
          <h2 class="help_title">Construtor</h2>
          <p class="help_body">Este campo é responsável por acessar ferramentas responsáveis pela construção de seus próprios dashboards, analytics, regras de disparos automáticos de e-mail e cubos de dados.</p>
        </span>

        <ng-template [ngIf]=" showMenuElement('access_upload', true)">
          <li class="sidebar-item">
            <a routerLink="/upload-panel" class="sidebar-link waves-effect waves-dark">
              <i class="mdi mdi-monitor"></i>
              <span class="hide-menu mx-2"> Upload </span>
            </a>
          </li>
        </ng-template>

        <div class="tour-constructor-ecos" [ngClass]="{ 'd-flex': isHorizontal }">
          <ng-template [ngIf]=" showMenuElement('access_analytics', true) ">
            <li class="sidebar-item">
              <a routerLink="/panel-list/analytics" class="sidebar-link">
                <i class="icon-Bar-Chart5"></i>
                <span class="hide-menu mx-2"> Analytics </span>
              </a>
            </li>
          </ng-template>
  
          <ng-template [ngIf]=" showMenuElement('access_dashboards', true)">
            <li class="sidebar-item">
              <a routerLink="/panel-list/dashboard" class="sidebar-link waves-effect waves-dark">
                <i class="mdi mdi-monitor"></i>
                <span class="hide-menu mx-2"> Dashboards </span>
              </a>
            </li>
          </ng-template>
  
          <ng-template [ngIf]=" showMenuElement('access_forms', true) ">
            <li class="sidebar-item">
              <a routerLink="/panel-list/crud" class="sidebar-link waves-effect waves-dark">
                <i class="icon-Receipt-2"></i>
                <span class="hide-menu mx-2"> Formulários </span>
              </a>
            </li>
          </ng-template>
  
          <ng-template [ngIf]=" showMenuElement('access_emails', true) ">
            <li class="sidebar-item">
              <a routerLink="/panel-list/newsletter" class="sidebar-link">
                <i class="icon-Mail-Read"></i>
                <span class="hide-menu mx-2"> Emails </span>
              </a>
            </li>
          </ng-template>
          
          <!-- Cubo -->
          <ng-template [ngIf]=" showMenuElement('access_cube', true) ">
            <li class="sidebar-item">
  
              <a class="sidebar-link waves-effect waves-dark" routerLink="/cube" aria-expanded="false">
                <i class="mdi mdi-cube-outline"></i>
                <span class="hide-menu mx-2"> {{ "Cubo" | translate }} </span>
              </a>
  
            </li>
          </ng-template>
        </div>

        <ng-template [ngIf]="showMenuElement('Cadastros', false)">
          <li class="sidebar-item register-menu nav-small-cap tour-cadastros">
            <span class="hide-menu mx-2">CADASTROS</span>
          </li>
        </ng-template>

        <!-- Cadastros-->
        <ng-container id="register-menu-tour" [ngSwitch]="true" *ngIf="!isHorizontal">
          <span class="helpTag_1" position="L_MARGIN" help_type="Component" ref_to="#register-menu-tour"
            style="display: none;">
            <h2 class="help_title">Cadastros</h2>
            <p class="help_body">Este campo é responsável por gerenciar os cadastros de informações no sistema, como
              dados de usuários e empresas, assim como, o gerenciamento de seus respectivos níveis de permissões.</p>
          </span>
          <!-- <ng-template *ngSwitchCase="authService.isSuperUser()" [ngIf]=" showMenuElement('Super', true) "> -->
            <div *ngIf="showUserAuth">
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_user')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/users" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ "Usuário" | translate }} </span>
                </a>
              </li>
            </div>
            <div *ngIf="showMainService">
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_company')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/company" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ layout.aliasTitle('Empresa') }} </span>
                </a>
              </li>
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_dealership')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/dealerships" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ layout.aliasTitle('Concessionárias') }} </span>
                </a>
              </li>
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_sector')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/plant" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ layout.aliasTitle('Planta') }} </span>
                </a>
              </li>
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_equipment')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/plant/equipment" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ layout.aliasTitle('IoT')  }} </span>
                </a>
              </li>
              <li class="sidebar-item" *ngIf="authService.checkPermission('view_equipment')">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/plant/alarm" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ layout.aliasTitle('Alarmes')  }} </span>
                </a>
              </li>
              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" routerLink="/supervisory/list" aria-expanded="false">
                  <i class="icon-Add-User"></i>
                  <span class="hide-menu mx-2"> {{ "Supervisórios" }} </span>
                </a>
              </li>
            </div>
          <!-- </ng-template> -->
        </ng-container>


        <ng-container *ngIf="!isHorizontal">
          <li *ngFor="let item of dataService.folders_crud | keyvalue; index as i" class="sidebar-item">
            <a class="sidebar-link has-arrow waves-effect waves-dark" (click)="onOpenCrudFolders(i)">
              <i class="icon-Receipt-3"></i>
              <span class="hide-menu mx-2">{{item.key}} </span>
            </a>

            <ul class="first-level" *ngIf="isCrudOpened[i]">
              <li *ngFor="let r of item.value" class="sidebar-item">
                <a class="sidebar-link" routerLink="/get_crud/{{r.codi_crd}}">
                  <i class="icon-Record"></i>
                  <span class="hide-menu mx-2">{{r.nome_crd}}</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngFor="let r of dataService.items_crud" class="sidebar-item">
            <a routerLink="/get_crud/{{r.codi_crd}}" class="sidebar-link">
              <i class="icon-Record"></i>
              <span class="hide-menu mx-2"> {{r.nome_crd}}</span>
            </a>
          </li>
        </ng-container>


      </ul>
    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <app-myecos-navbar></app-myecos-navbar>

  <!-- End Sidebar scroll-->
</aside>

<div class="page-wrapper" style="display: block;" [ngStyle]="{ 'padding-top': layout.isFullScreen ? '0px' : '64px'}">
  <router-outlet></router-outlet>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { StoreInstanceConfService } from 'src/app/components/instance-configuration/stores/store-instance-conf.service';
import { BootstrapModalAlertService } from 'src/app/components/shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-mainlayout',
  templateUrl: './mainlayout.component.html',
  styleUrls: ['./mainlayout.component.css'],
})
export class MainlayoutComponent implements OnInit {
  hasLoadedTheme: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    public layout: LayoutService,
    private authService: AuthService,
    private router: Router,
    private modalAlertService: BootstrapModalAlertService,
    public storeInstance: StoreInstanceConfService,
  ) {}

  ngOnInit(): void {
    this.onWaitForRender();
    this.onVerifyIfHasAccess();

    this.onFillThemeColors();
  }

  isHorizontal = window['whiteLabelConf']('horizontal');

  onWaitForRender() {
    if (!this.layout.isLoadedStyles) {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.layout.isLoadedStyles = true;
      }, 2000);
    }
  }

  onVerifyIfHasAccess() {
    if (!this.authService.hasToken()) {
      this.modalAlertService.showAlertDanger('Sessão expirada, por favor realize um novo login');
      this.router.navigate(['/signin']);
    }
  }

  onMountSectorTabs() {
    let defaultItems: any[] = [
      {
        id: 0,
        label: 'Local de medição',
        alias: 'Local de medição',
        permission: 'view_point',
        key: 'PON',
      },
    ];

    this.storeInstance.items.map((item) => {
      if (item.label == 'Setor') {
        item = {
          ...item,
          key: 'SET',
          supkey: 'SET_1',
          formName: 'plant_sector',
          table: 'plant_sector',
        };
      }

      if (item.label == 'Empresa') {
        item = {
          ...item,
          table: 'company',
          formName: 'company',
        };
      }

      if (item.label == 'Grupo') {
        item = {
          ...item,
          table: 'company_group',
          formName: 'company_group',
        };
      }

      if (item.label == 'Unidade') {
        item = {
          ...item,
          table: 'company_division',
          formName: 'company_division',
        };
      }

      if (item.label == 'Concessionárias') {
        item = {
          ...item,
          table: 'dealership',
          formName: 'dealership',
        };
      }
      if (item.label == 'Local de medição') {
        item = {
          ...item,
          table: 'plant_point',
          formName: 'plant_point',
        };
      }

      if (item.label == 'Natureza') {
        item = {
          ...item,
          table: 'plant_equipment_nature',
          formName: 'plant_equipment_nature',
        };
      }
      if (item.label == 'IoT') {
        item = {
          ...item,
          table: 'plant_equipment',
          formName: 'plant_equipment',
        };
      }

      if (item.label == 'Imagem') {
        item = {
          ...item,
          table: 'plant_equipment_image',
          formName: 'plant_equipment_image',
        };
      }
      if (item.label == 'Agrupador') {
        item = {
          ...item,
          table: 'plant_equipment_buddle',
          formName: 'plant_equipment_buddle',
        };
      }
      if (item.label == 'Tipo') {
        item = {
          ...item,
          table: 'plant_equipment_type',
          formName: 'plant_equipment_type',
        };
      }
      if (item.label == 'Alarmes') {
        item = {
          ...item,
          table: 'alert_config',
          formName: 'alert_config',
        };
      }
      if (item.label == 'Usuário') {
        item = {
          ...item,
          table: 'users',
          formName: 'users',
        };
      }
      if (item.label == 'Supervisórios') {
        item = {
          ...item,
          table: 'supervisory',
          formName: 'supervisory',
        };
      }
    });

    const items = this.storeInstance.items.filter((item) => item?.key?.startsWith('SET'));
    const newItems = [];

    const sectorExists = this.storeInstance.items.some((el) => el.key == 'SET');
    if (!sectorExists) {
      defaultItems.push({
        id: 1,
        label: 'Setor',
        alias: 'Setor',
        permission: 'view_sector',
        key: 'SET',
        supkey: 'SET_1',
      });
    }

    items.map((el, index) => {
      const { key, supkey } = el;

      const supKeyExists = items.some(other => other.key == supkey)
      newItems.push({
        ...el,
        last: !supKeyExists ? true : false,
        permission: 'view_sector',
      });
    });

    defaultItems = [...defaultItems, ...newItems];

    this.storeInstance.sectorTabs = defaultItems
  }

  onFillThemeColors() {
    if (!this.authService.userDetails) {
      return;
    }

    this.authService
      .onFindThemeColors()
      .subscribe(({ theme, graficColors, logoFull, logoMini, mainTitles }) => {
        document.documentElement.style.setProperty(`--primary-color`, theme?.color1);
        document.documentElement.style.setProperty(`--secondary-color`, theme?.color2);
        document.documentElement.style.setProperty(`--third-color`, theme?.color3);

        document.documentElement.style.setProperty(`--error-color`, theme?.color4);
        document.documentElement.style.setProperty(`--success-color`, theme?.color5);

        if (mainTitles) {
          this.storeInstance.items = mainTitles;
        }
        
        this.onMountSectorTabs();

        if (graficColors) {
          this.storeInstance.graficColors = graficColors;
        }

        this.layout.logoFull = logoFull ?? window['whiteLabelConf']('navlogo');
        this.layout.logoMini = logoMini ?? window['whiteLabelConf']('navlogo');
      })
      .add(() => {
        this.layout.loadedTheme = true
      });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CompanyGroup } from '../../../shared/model/company-group.model';
import { CompanyModule } from '../../../company.module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para leitura de grupos empresariais.
 */
@Component({
  selector: 'app-company-group-read',
  templateUrl: './company-group-read.component.html',
  styleUrls: ['./company-group-read.component.css']
})
export class CompanyGroupReadComponent implements OnInit {

  /** Lista de grupos empresariais. */
  groups: CompanyGroup[];
  groupsFiltered: CompanyGroup[] = [];

  /** Próxima página de resultados paginados. */
  next: string;

  /** Página anterior de resultados paginados. */
  previous: string;

  /** Parâmetros HTTP para a solicitação. */
  params = new HttpParams();

  /** Referência para o modal de exclusão. */
  deleteModalRef: BsModalRef;

  /** Referência para o elemento do modal de exclusão. */
  @ViewChild('deleteModal') deleteModal;

  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
    { label: 'Ativo', field: 'active', type: 'boolean', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private companyService: CompanyService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso e a habilitação do usuário.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_companygroup') == false) {
      this.router.navigate(['/403']);
    };

    this.readAll(this.authService.endpoint_main + 'companygroup/')
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.groupsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.groupsFiltered = this.groups;
      return;
    }

    this.groupsFiltered = this.groupsFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onFilterBoolean(value: boolean) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0]
    filter.value = value;

    this.groupsFiltered = this.groups.filter((el) => el[filter.field] == value);
  }

  onClearFilters() {
    this.groupsFiltered = this.groups;
  }

  /**
 * Lê todos os grupos empresariais.
 *
 * @param url URL para recuperar os grupos empresariais.
 */
  readAll(url: string) {
    // Verifica se existe token.
    if (this.authService.hasToken()) {

      // Chama o serviço para recuperar todos os grupos empresariais.
      this.companyService.getCompanyGroupAll(url).pipe(take(1)).subscribe(
        data => {
          // Atribui os resultados obtidos à variável 'groups'.
          this.groups = data.results;
          this.groupsFiltered = data.results;
          
          // Atualiza as propriedades 'next' e 'previous' com base nos dados obtidos.
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Não foi possível listar grupos empresariais');
        }
      );
    } else {
      // Se não houver um ID, exibe um alerta de perigo e realiza o logout.
      this.modalAlertService.showAlertDanger('Erro ao recuperar grupos');
      this.authService.doLogout();
    }
  }


  /**
   * Função que busca os próximos itens paginados usando a URL na propriedade 'next'.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Função que busca os itens paginados anteriores usando a URL na propriedade 'previous'.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização com base no ID do grupo empresarial.
   *
   * @param id ID do grupo empresarial.
   */
  readId = (id) => {
    this.router.navigate(['/companygroups/update', id]);
  }

  /**
   * Navega para a página de criação de grupos empresariais.
   */
  create = () => {
    this.router.navigate(['/companygroups/create']);
  }

  /**
 * Manipula a exclusão de um grupo empresarial.
 *
 * @param item Grupo empresarial a ser excluído.
 */
  onDelete = (item) => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('delete_companygroup') == false) {
      // Redireciona para a página de erro 403 se o usuário não tiver permissão.
      this.router.navigate(['/403']);
    };

    this.selectedItem = item;

    // Exibe um diálogo de confirmação para o usuário.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este grupo?'
    );

    // Aguarda a resposta do diálogo de confirmação.
    result$.asObservable()
      .pipe(
        take(1),
        // Executa a exclusão se o usuário confirmar.
        switchMap(result => result ? this.companyService.deleteCompanyGroup(item.company_group_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o grupo excluído da lista local.
          let index;
          this.groups.forEach((e, i) => {
            if (e['company_group_id'] === item.company_group_id) {
              index = i;
            }
          });
          this.groups.splice(index, 1);

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Exibe um alerta de perigo em caso de erro.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

import { BaseFunc } from '../../shared/base-func/base-func';
import { CubeMachine } from './cube-machine';

export class CubeInstance extends BaseFunc {
    GdivContent: any;
    GdivChart: any;
    GdivPage: any;
    Gmetrics: any;
    Gheader: any;
    Gdata: any;
    GheaderRow: any;
    GheaderCol: any;
    Gfilter: any;
    Goptions: any;
    callbackFunction: any;
    formatterCells: any;
    GPivot: any;

    constructor(divContent, divChart, divPage, metrics, header, data, headerRow, headerCol, filter, options, formatterCells, callbackFunction) {
        super();
        var self = this;
        self.GdivContent = divContent;
        self.GdivChart = divChart,
        self.GdivPage = divPage;
        self.Gmetrics = metrics;
        self.Gheader = header;
        self.Gdata = data;
        self.GheaderRow = headerRow;
        self.GheaderCol = headerCol;
        self.Gfilter = filter;
        self.Goptions = options;
        self.callbackFunction = callbackFunction;
        self.formatterCells = formatterCells;
        self.GPivot;
    }

    

    initPivotTable = () => {
        var self = this;
        var pivot = new CubeMachine(self.GdivContent, self.GdivChart, self.GdivPage, self.Gmetrics, self.Gheader, self.Gdata,
            self.GheaderRow, self.GheaderCol, self.Gfilter, self.Goptions, self.formatterCells, self.callbackFunction);
        self.GPivot = pivot;
        self.GPivot.startPivot();
    };

    initPivotTableCallBack = (call) => {
        var self = this;
        var pivot = new CubeMachine(self.GdivContent, self.GdivChart, self.GdivPage, self.Gmetrics, self.Gheader, self.Gdata,
            self.GheaderRow, self.GheaderCol, self.Gfilter, self.Goptions, self.formatterCells, self.callbackFunction);
        if (call)
            pivot = call(pivot);

        self.GPivot = pivot;
        self.GPivot.startPivot();
    };

    initPivotTableWithConfig = (conf) => {
        var self = this;
        if (conf &&
        (self.$j(self.Gheader).not(conf.headers).length == 0 && self.$j(conf.headers).not(self.Gheader).length == 0) &&
        (self.$j(self.GheaderCol).not(conf.cols).length == 0 && self.$j(conf.cols).not(self.GheaderCol).length == 0) &&
        (self.$j(self.GheaderRow).not(conf.rows).length == 0 && self.$j(conf.rows).not(self.GheaderRow).length == 0)) {
            self.GheaderCol = conf.cols;
            self.GheaderRow = conf.rows;
            self.Gfilter = conf.filters;
        }

        self.GPivot = new CubeMachine(self.GdivContent, self.GdivChart, self.GdivPage, self.Gmetrics, self.Gheader, self.Gdata,
            self.GheaderRow, self.GheaderCol, self.Gfilter, self.Goptions, self.formatterCells, self.callbackFunction);

        if (conf && conf.graphConfig !== undefined) { self.GPivot.graphConfig = conf.graphConfig; }
        //if (conf && conf.metricCollection !== undefined) { self.GPivot.metricCollection = conf.metricCollection; }
        if (conf && conf.metricDinamycMap !== undefined) { self.GPivot.metricDinamycMap = conf.metricDinamycMap; }
        if (conf && conf.metricShowedLabel !== undefined) { self.GPivot.metricShowedLabel = conf.metricShowedLabel; }
        if (conf && conf.mapAssociationIndex !== undefined) { self.GPivot.mapAssociationIndex = conf.mapAssociationIndex; }

        var metricNames = [];
        for (var j = 0; j < self.GPivot.metricCollection.length; ++j)
            metricNames.push(self.GPivot.metricCollection[j].name);

        if (conf &&
        (self.$j(self.Gheader).not(conf.headers).length == 0 && self.$j(conf.headers).not(self.Gheader).length == 0) &&
        (self.$j(self.GheaderCol).not(conf.cols).length == 0 && self.$j(conf.cols).not(self.GheaderCol).length == 0) &&
        (self.$j(self.GheaderRow).not(conf.rows).length == 0 && self.$j(conf.rows).not(self.GheaderRow).length == 0) &&
        (self.$j(metricNames).not(conf.metrics).length == 0 && self.$j(conf.metrics).not(metricNames).length == 0)) {
            self.GPivot.rankedIndex = conf.rank;
            self.GPivot.clearRank = false;
        }

        if (conf && conf.metricDinamycMap)
            self.GPivot.configDynamicMetrics( conf.metricDinamycMap );
        self.GPivot.startPivot();
    };

}

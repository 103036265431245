<ng-container *ngIf="divType === 'default'; then alertPopup; else alertDiv">
</ng-container>

<ng-template #alertPopup>
  <div class="alert alert-{{ type }} no-margin" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div [innerHTML]="message"></div>
  </div>
</ng-template>

<ng-template #alertDiv>
  <div class="alert alert-{{ type }} alert-dismissible fade show" role="alert">
    <div [innerHTML]="message"></div>
  </div>  
</ng-template>

<section class="section mt-3">
  <div class="section-header">
      <h1>Editar {{ layout.aliasTitle('Agrupador') }}</h1>
      <!-- <div class="section-header-breadcrumb">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/plant/equipmentnatures">Natureza da Medição</a></div>
          <div class="breadcrumb-item active">Adicionar</div>
      </div> -->
  </div>

  <div class="row">
      <div class="col-12 ">
          <div class="card">
              <div class="card-header">
                 <!--  <h4>Adicionar Alarme</h4> -->
              </div>
              <div class="card-body">
                  <form method="POST" [formGroup]="form">
                      <div class="form-row">
                          <div class="form-group col-md-4">
                              <label for="logo">Nome</label>  
                              <input type="text" class="form-control mb-2 mr-sm-2" formControlName="bundle_name"
                                  [ngClass]="displayFieldCss('bundle_name')">
                              <div class="invalid-feedback">
                                  Por favor informe o nome do alarme
                              </div>
                          </div>

                          <div class="form-group col-md-4">
                            <label for="equipment_type">{{ layout.aliasTitle('Tipo') }}</label>
                            <select class="form-control" formControlName="equipment_type"
                              [ngClass]="displayFieldCss('equipment_type')">
                              <option *ngFor="let item of types" value="{{item.plant_equipment_type_id}}">
                                {{item.name}}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              Por favor selecione um(a) {{ layout.aliasTitle('Tipo') }} para o {{ layout.aliasTitle('Agrupador') }}
                            </div>
                          </div>


                          <div class="form-group col-md-4">
                              <label for="IoT">{{ layout.aliasTitle('IoT') }}</label>
                              <div>
                                  <app-ic-select #selectref  key='EQP' [fullwidth]="true">
                                  </app-ic-select>
                              </div>

                          </div>
                      </div>
                      
                      
                  </form>
              </div>
              <div class="card-footer text-right">
                <app-default-button [isLoading]="isLoading" (pressed)="update()"></app-default-button>
                <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
                <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="section">
  <div class="section-header">
    <h1>{{ layout.aliasTitle('Natureza') }}</h1>
    <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipmentnatures">Natureza da Medição</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
  </div>

  <div class="row">
    <div class="col-12 ">
      <div class="card">
        <div class="card-header">
          <h4>Adicionar {{ layout.aliasTitle('Natureza') }}</h4>
        </div>
        <div class="card-body">
          <form method="POST" [formGroup]="form">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="logo">Nome</label>
                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="name"
                  [ngClass]="displayFieldCss('name')" maxlength="100">
                <div class="invalid-feedback">
                  Por favor informe o nome
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="aggregation">Função de agregração</label>
                <select class="form-control" formControlName="aggregation" [ngClass]="displayFieldCss('aggregation')">
                  <option *ngFor="let item of aggrList" value="{{item.id}}">
                    {{item.value}}
                  </option>
                </select>
                <div class="invalid-feedback">
                  Por favor selecione uma função de agregação
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="un">{{ layout.aliasTitle('Unidade') }}</label>
                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="un"
                  [ngClass]="displayFieldCss('un')" maxlength="30">
                <div class="invalid-feedback">
                  Por favor informe o(a) {{ layout.aliasTitle('Unidade') }}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-right">
          <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
          <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
          <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
        </div>
      </div>
    </div>
  </div>
</section>

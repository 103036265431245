import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomCrudsService } from '../../cruds/custom-cruds/custom-cruds.service';
import { CustomReportsService } from '../../reports/custom-reports/custom-reports.service';

import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { StatusService } from '../../status/status.service';
import { DataService } from '../data.service';
import { AuthService } from './../../../auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  customreportslist = { cruds: [], folders: {}, folders_crud: {}, items: [], items_crud: [], reports: [] };
  folders;
  folders_crud;
  items;
  items_crud;
  personlist = { person_folders: {}, person_items: [] };
  person_folders;
  person_items;
  form: FormGroup;

  isHorizontal = window['whiteLabelConf']('horizontal');

  itemsByMenu = [
    { 'id': 'user', 'routes': ['/profiles', '/groups', '/users'] },
    { 'id': 'client', 'routes': ['/persons', '/personsperfil', '/personprofiles'] },
    { 'id': 'company', 'routes': ['/companygroups', '/companydivision', '/company'] },
    { 'id': 'dealership', 'routes': ['/dealerships',] },
    { 'id': 'plant', 'routes': ['/plant/sectors', '/plant/points'] },
    { 'id': 'equipment', 'routes': ['/plant/equipmenttypes', '/plant/equipmentnatures', '/plant/equipments', '/plant/equipmentimages'] },
  ]

  isCrudOpened: boolean[] = [];

  isInitialPage(id: string) {
    const path = `custom-report/${id}`;
    return path.includes(this.authService.getInitialConf());
  }

  constructor(
    public router: Router,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private customreport: CustomReportsService,
    private customcrud: CustomCrudsService,
    public dataService: DataService,
    private statusActive: StatusService,
    public layout: LayoutService,
    private sanitizer: DomSanitizer,
  ) { }


  showMainService: boolean = false;
  showUserAuth: boolean = false;
  showRepo: boolean = false;

  //Configurações White Label
  get navlogo_wl() {
    return this.layout.logoFull ?? window['whiteLabelConf']('navlogo')
  } 
  
  get navlogomini_wl() {
    return this.layout.logoMini ?? window['whiteLabelConf']('navlogomini')
  }

  ngOnInit(): void {
    const hasMainService = localStorage.getItem('main_service')
    this.showMainService = hasMainService ? true : false;

    const hasUsersService = localStorage.getItem('users_service')
    this.showUserAuth = hasUsersService ? true : false;

    const hasReposervice = localStorage.getItem('repo_service')
    this.showRepo =  hasReposervice ? true: false

    if(!hasUsersService) {
      this.onVerifyStatusUsersService()
    }

    if(!hasMainService) {
      this.onVerifyStatusMainService()
    }
    
    this.readCustomOptionList();
    this.onListTitles()
  }


  onListTitles() {
    this.authService.onFindThemeColors().subscribe(({ mainTitles }) => {
      if(mainTitles) {
        this.layout.mainTitles = mainTitles;
      }
    })
  }

  onOpenCrudFolders(index: number) {
    this.isCrudOpened[index] = !this.isCrudOpened[index];
  }

  onRemoveItemIfAlreadyHome() {
    const initialPage = this.authService.getInitialConf();

    if(!initialPage || !this.folders.filter)
      return;

    this.folders = this.folders.filter((el) => !el.url.includes(initialPage));
  }

  onVerifyStatusUsersService() {
    this.statusActive.getStatusUserAuth().pipe(take(1)).subscribe(
      (data: { message: string }) => {
        this.showUserAuth = true;
        localStorage.setItem('users_service', data.message)
      },
      error => {
        this.showUserAuth = false;
        localStorage.removeItem('users_service')
      },
      () => {}
    );
  }

  onVerifyStatusMainService() {
    this.statusActive.getStatusMain().pipe(take(1)).subscribe(
      (data: { message: string }) => {
        this.showMainService = true;
        localStorage.setItem('main_service', data.message)
      },
      (error) => {
        this.showMainService = false;
        localStorage.removeItem('main_service')
      },
      () => {
    }
    );
  }

  onNavigate(to: string) {
    this.router.navigate([to])
  }

  showMenuElement = (source, isOption) => {
    var self = this;
    if ( self.authService.isSuperUser() )
      return true;

    if ( !isOption ) {
      if (source == 'Cadastros')
        return self.folders_crud?.length > 0 || self.items_crud?.length > 0 ||
          self.authService.checkPermission('view_user') ||
          self.authService.checkPermission('view_person') ||
          self.authService.checkPermission('view_company') ||
          self.authService.checkPermission('view_dealership') ||
          self.authService.checkPermission('view_plant') ||
          self.authService.checkPermission('view_equipment') ||
          self.authService.checkPermission('view_alarm');
      else if (source == 'Analytics')
        return (self.folders != null && Object.keys(self.folders).length > 0) || (self.items != null && Object.keys(self.items).length > 0);
      else if ( source == 'Construtor' )
        return self.authService.checkPermission('access_cube') ||
          self.authService.checkPermission('access_upload') ||
          self.authService.checkPermission('access_analytics') ||
          self.authService.checkPermission('access_dashboards') ||
          self.authService.checkPermission('access_forms') ||
          self.authService.checkPermission('access_emails');
      else
        return false;
    }
    else
      return self.authService.checkPermission(source);
  }

  setActiveItemByRouteUrl = () =>{
    for (let i=0; i<this.itemsByMenu.length;i++){
      for(let j=0; j<this.itemsByMenu[i].routes.length;j++){
        if(this.itemsByMenu[i].routes[j] == this.router.url ){

          let ul_parent = document.getElementById(this.itemsByMenu[i].id).getElementsByTagName('ul')[0];
          let li_dropdown = document.getElementById(this.itemsByMenu[i].id);

          if (li_dropdown.classList.length > 0 ){
            li_dropdown.className = '';
            ul_parent.style.display = 'none';
          }else{
            li_dropdown.className = '';
            ul_parent.style.display = 'block';
          }
        }
      }
    }

  }

  toogleCollapse = (id) => {
    let liClasses = document.getElementById(id).getAttribute('class');
    if (liClasses != null) {
      document.getElementById(id).removeAttribute('class');
      document.getElementById(id).getElementsByTagName('ul')[0].removeAttribute('style');
      document.getElementById(id).getElementsByTagName('ul')[0].setAttribute('style', 'display:none;');
    } else {
      let liElement = document.getElementById(id);
      document.getElementById(id).setAttribute('class', 'active');
      document.getElementById(id).getElementsByTagName('ul')[0].removeAttribute('style');
      document.getElementById(id).getElementsByTagName('ul')[0].setAttribute('style', 'display:block;');
    }
  }

  readCustomOptionList = () => {
    var script = document.createElement('script');
    script.src = '/assets/alfa_adminbite/dist/js/sidebarmenu.js';
    document.body.appendChild(script);

    // <script src="/assets/alfa_adminbite/assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js"></script>
    // var scriptScrollBar = document.createElement('script');
    // scriptScrollBar.src = '/assets/alfa_adminbite/assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js';
    // document.body.appendChild(scriptScrollBar);

    this.customreport.getCustomReportsList().pipe(take(1)).subscribe(
      data => {
        this
        this.customreportslist = data;
        this.folders = data.folders;
        this.items = data.items;

        this.dataService.folders = data.folders;
        this.dataService.items = data.items
        
        this.dataService.changeParam([
          { 'Folders': data.folders, 'Items': data.items }
        ]); // Salvando no "repositório compartilhado" entre telas

        this.form = this.formBuilder.group({});
        this.onRemoveItemIfAlreadyHome();

      },
      error => {
        console.log('Erro ao obter a lista de relatórios customizados.');
      }
    );

    this.customcrud.getCustomCrudsList().pipe(take(1)).subscribe(
      data => {
        this.dataService.folders_crud = data.folders_crud;
        this.dataService.items_crud = data.items_crud;

        this.folders_crud = data.folders_crud;
        this.items_crud = data.items_crud;
      },
      error => {
        console.log('Erro ao obter a lista de cadastros customizados.');
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { BootstrapModalAlertService } from '../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { UsersService } from '../users/users.service';
import { PersonService } from '../person/person.service';
import { ReportBuilderService } from '../reports/report-builder/report-builder.service';
import { CrudBuilderService } from '../cruds/crud-builder/crud-builder.service';

import { PubsubService } from './pubsub.service';
import { SceneryService } from '../reports/scenery-builder/scenery.service';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-pubsub',
  templateUrl: './pubsub.component.html',
  styleUrls: ['./pubsub.component.css']
})
export class PubsubComponent implements OnInit {

  constructor(
    private modalAlertService: BootstrapModalAlertService,
    private usersService: UsersService,
    private personService: PersonService,
    private reportBuilderService: ReportBuilderService,
    private crudBuilderService: CrudBuilderService,
    private pubsubService: PubsubService,
    private simulationService: SceneryService,
  ) { }

  message: string;
  repo_status: any = {};
  form_status: any = {};
  user_status: any = {};
  main_status: any = {};
  mail_status: any = {};
  bot_status: any = {};

  ngOnInit(): void {
    var seconds = 10;
    var self = this;
    var process_number:any = {};

    var updateFunc = function(){
      if (Array.from(document.querySelectorAll('#tabela_publicacoes')).length == 0)
        clearInterval(process_number);

      self.reportBuilderService.repoPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.repo_status = data;
        },
        error => {
          self.repo_status = {}
        },
        () => {
        }
      );

      self.crudBuilderService.formPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.form_status = data;
        },
        error => {
          self.form_status = {}
        },
        () => {
        }
      );

      self.personService.mainPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.main_status = data;
        },
        error => {
          self.main_status = {}
        },
        () => {
        }
      );

      self.usersService.userPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.user_status = data;
        },
        error => {
          self.user_status = {}
        },
        () => {
        }
      );

      self.reportBuilderService.mailPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.mail_status = data;
        },
        error => {
          self.mail_status = {}
        },
        () => {
        }
      );

      self.pubsubService.botPublisherStatus().pipe(take(1)).subscribe(
        data => {
          self.bot_status = data;
        },
        error => {
          self.bot_status = {}
        },
        () => {
        }
      );
    };
    process_number = setInterval(updateFunc, seconds * 1000);
    updateFunc();
  }

  userPub = (forceSync=false) => {
    this.usersService.userPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  mainPub = (forceSync=false) => {
    this.personService.mainPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  repoPub = (forceSync=false) => {
    this.reportBuilderService.repoPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  formPub = (forceSync=false) => {
    this.crudBuilderService.formPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  mailPub = (forceSync=false) => {
    this.reportBuilderService.mailPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  botPub = (forceSync=false) => {
    this.pubsubService.botPublisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

  simulationPub = (forceSync=false) => {
    this.simulationService.simulationPubisher({forceSync: forceSync}).pipe(take(1)).subscribe(
      data => {
        this.message = data.message;
        this.modalAlertService.showAlertSuccess(this.message);
      },
      error => {
        var resultError = error.message ? error.message : error().message
        this.modalAlertService.showAlertDanger(resultError);
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { Global } from 'src/app/components/shared/base-func/global';
import { StoreInstanceConfService } from 'src/app/components/instance-configuration/stores/store-instance-conf.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular responsável pela atualização de setores.
 */
@Component({
  selector: 'app-sector-update',
  templateUrl: './sector-update.component.html',
  styleUrls: ['./sector-update.component.css']
})
export class SectorUpdateComponent implements OnInit {

  /**
   * Formulário para a atualização do setor.
   */
  form: FormGroup;

  /**
   * Lista de setores pais.
   */
  parents = '';

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Setor pai selecionado.
   */
  selectedParent = "";

  isLoading: boolean = false;
  customFields: any[] = []

  defaultKey: string = 'SET'
  defaultSupKey: string = 'SET'
  hiddenFather: boolean = false;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public storeInstance: StoreInstanceConfService,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {
      if(data.key) {
        this.defaultKey = data.key
        this.defaultSupKey = data.supkey


        const lastItem = this.storeInstance.sectorTabs.filter(el => el.last)
        
        if(lastItem[0]?.key == this.defaultKey) {
          this.hiddenFather = true;
          return 
        }

        this.authService.onFindSectors(this.defaultSupKey).subscribe((data) => {
          this.parents = data.results
        })
      }
    })
    
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('change_sector')) {
      this.router.navigate(['/403']);
    };

    // Obtém o ID do setor da rota e executa a leitura correspondente.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Inicializa o formulário com os campos e validadores necessários.
    this.form = this.formBuilder.group({
      plant_sector_id: [''],
      company: [''],
      parent: null,
      name: ['', Validators.required],
      latitud: ['', Validators.maxLength(12)],
      longitud: ['', Validators.maxLength(12)],
    });
  }

  /**
   * Realiza a leitura dos dados do setor com o ID fornecido.
   *
   * @param id - ID do setor a ser lido.
   */
  read = (id: number) => {

    // Obtém informações sobre a empresa e o aplicativo.
    let company = this.authService.getPerson();
    let sector = id;
    const key = this.defaultKey

    // Obtém a lista de setores pais.
    this.plantService.getSectorAll(this.authService.endpoint_main + 'plant/sector/list/nopage', company, sector).pipe(take(1)).subscribe(
      data => {
        this.parents = data;

        // Obtém os dados do setor.
        this.plantService.getSector(id, key).pipe(take(1)).subscribe(
          data => {
            this.customFields = data.fields ?? []

            this.form.get('plant_sector_id').setValue(data.plant_sector_id);
            let companyValue = data.company && data.company.company_id ? data.company.company_id : null;
            this.form.get('company').setValue(companyValue);
            if(data.parent != null){
              this.selectedParent = data.parent.plant_sector_id;
              this.form.get('parent').setValue(data.parent.plant_sector_id);
            }

            this.form.get('name').setValue(data.name);
            this.form.get('latitud').setValue(data.latitud);
            this.form.get('longitud').setValue(data.longitud);
          }, error => {
            // Tratar erros de leitura.
          }
        )
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
      }
    )
  }

  /**
   * Obtém os controles do formulário para facilitar o acesso.
   */
  get f() { return this.form.controls; }

  /**
   * Verifica se um campo específico do formulário é válido.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns true se o campo for inválido e o formulário tiver sido submetido, false caso contrário.
   */
  isFieldValid(field: string) {
    let result = !this.form.get(field).valid && this.submitted == true;
    return result
  }

  /**
   * Retorna as classes CSS necessárias para a exibição de erros no campo.
   *
   * @param field - Nome do campo a ser verificado.
   * @returns Objeto contendo a classe 'is-invalid' se o campo for inválido, vazio caso contrário.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup - Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Atualiza o setor com os dados fornecidos no formulário.
   */
  update = () => {
    this.submitted = true;

    const global = new Global()
    const result = global.saveData()

    if (this.form.valid) {

      const payload = {
        ...this.form.value,
        ...result,
        key: this.defaultKey
      }

      if (this.authService.hasToken() == true && this.authService.hasPerson() == true) {
        // Se o campo 'parent' estiver vazio, define-o como null antes da atualização.
        if(this.form.get('parent').value == ""){
          this.form.get('parent').setValue(null);
        };

        // Realiza a atualização do setor.
        this.isLoading = true;
        this.plantService.updateSector(payload).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            console.error(error);
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        })
      } else {
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reinicia o formulário e o estado submetido.
   */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }
}

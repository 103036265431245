import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente Angular responsável pela leitura e gerenciamento de setores.
 */
@Component({
  selector: 'app-sector-read',
  templateUrl: './sector-read.component.html',
  styleUrls: ['./sector-read.component.css']
})
export class SectorReadComponent implements OnInit {

  @Input() info: any;

  /**
   * Lista de setores.
   */
  sectors = [];
  sectorsFiltered: any[] = [];

  /**
   * URL para a próxima página de setores.
   */
  next: string;

  /**
   * URL para a página anterior de setores.
   */
  previous: string;

  /**
   * Parâmetros HTTP.
   */
  params = new HttpParams();

  /**
   * Referência ao modal de exclusão.
   */
  deleteModalRef: BsModalRef;

  /**
   * Referência ao elemento de exclusão no template.
   */
  @ViewChild('deleteModal') deleteModal;

  /**
   * Item selecionado para exclusão.
   */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método executado durante a inicialização do componente.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('view_sector')) {
      this.router.navigate(['/403']);
    };

    // Inicia a leitura de setores.
    this.readAll(this.authService.endpoint_main + 'plant/sector/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.sectorsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.sectorsFiltered = this.sectors;
      return;
    }

    this.sectorsFiltered = this.sectorsFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onClearFilters() {
    this.sectorsFiltered = this.sectors;
  }


  /**
   * Lê todos os setores da URL fornecida.
   *
   * @param url - URL para obter a lista de setores.
   */
  readAll(url: string): void {
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      let company = this.authService.getPerson();
      let page = url.split('&').find(x => x.startsWith('page='));

      const { key } = this.info
      

      this.plantService.getSectorAllList(this.authService.endpoint_main + 'plant/sector/list?' + page, company, key).pipe(take(1)).subscribe(
        data => {
          this.sectors = data.results;
          this.sectorsFiltered = data.results;
          
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          this.modalAlertService.showAlertDanger('Não foi possível listar Concessionárias de Energia');
          console.error(error);
        }
      );

    } else {
      this.modalAlertService.showAlertDanger('Erro ao recuperar Concessionárias de Energia');
      this.authService.doLogout();
    }
  }

  /**
   * Obtém a próxima página de setores.
   */
  fetchNext(): void {
    this.readAll(this.next);
  }

  /**
   * Obtém a página anterior de setores.
   */
  fetchPrevious(): void {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de um setor específico.
   *
   * @param id - Identificador do setor.
   */
  readId = (id): void => {
    this.router.navigate([`/plant/sector/update/${id}`],  { queryParams: { supkey: this.info.supkey, key: this.info.key } });
  }

  /**
   * Navega para a página de criação de setores.
   */
  create = (): void => {
    this.router.navigate([`/plant/sector/create`], { queryParams: { supkey: this.info.supkey, key: this.info.key }});
  }

  /**
   * Executa a exclusão de um setor.
   *
   * @param item - Setor a ser excluído.
   */
  onDelete = (item): void => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();
    const { key } = this.info

    // Verifica permissão. Redireciona para a página de permissão negada se a permissão não for concedida.
    if (!this.authService.checkPermission('delete_sector')) {
      this.router.navigate(['/403']);
    };

    this.selectedItem = item;
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este setor'
    );
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.plantService.deleteSector(item.plant_sector_id, key) : EMPTY)
      )
      .subscribe(
        success => {
          let index;
          this.sectors.forEach((e, i) => {
            if (e.plant_sector_id === item.plant_sector_id) {
              index = i;
            }
          });
          this.sectors.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
          console.error(error);
        }
      );
  }
}

<section class="section">
    <div class="section-header">
        <h1>Perfis de usuário</h1>
        <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/profiles">Perfils de usuário</a></div>
            <div class="breadcrumb-item active">Editar</div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h4>Editar perfil de usuário </h4>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-group">
                            <label for="name">Nome</label>
                            <input type="text" class="form-control mb-2 mr-sm-2" id="name"
                                placeholder="Digite nome do novo perfil" [(ngModel)]="selectedItem.name" name="name">
                        </div>
                    </form>
                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-primary mr-1" type="submit" (click)="update()">Salvar</button>
                    <a class="btn btn-secondary cancel-button" style="color:white" routerLink="/profiles">Cancelar</a>
                </div>
            </div>
        </div>
    </div>
</section>

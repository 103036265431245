<ul class="nav nav-pills m-t-30 m-b-30">
  <li class=" nav-item" (click)="setTabIndex(0)">
    <a href="#navpills-0" class="nav-link active" data-toggle="tab" aria-expanded="false">Concessionária de Energia</a>
  </li>
</ul>
<div class="tab-content br-n pn">
  <div id="navpills-0" class="tab-pane active" *ngIf="selectedIndex == 0">
    <app-dealership-read>
    </app-dealership-read>
  </div>
</div>

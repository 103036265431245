import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cruds',
  templateUrl: './cruds.component.html',
  styleUrls: ['./cruds.component.css']
})
export class CrudsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

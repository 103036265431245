<section class="section">
  <div class="section-header user-group-header_read mt-3">
    <h1>Gestão de Perfis</h1>
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-12 ">
        <div class="card">
          <div class="card-header">
            <h4><a class="btn btn-primary" routerLink='/groups/create'>Novo Perfil</a></h4>
            <div class="card-header-action">
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="fixed-header-table">
                <table class="table table-bordered table-md">
                  <thead>
                    <tr>
                      <th class="w-50">
                        <div class="d-flex justify-content-between">
                          <span>Nome</span>
                          <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onShowFilters('name')"></i>
                        </div>
                        <div [class.d-none]="selectedFilter !== 'name'">
                          <ng-container *ngTemplateOutlet="options"></ng-container>
                        </div>
                      </th>
                      <th class="text-center">
                        <div class="d-flex justify-content-between">
                          <span>Quantidade de Usuários</span>
                          <i class="fa-solid fa-sort pointer px-1 py-1" (click)="onReverse()"></i>
                        </div>
                        <div [class.d-none]="selectedFilter !== 'qtd'">
                          <ng-container *ngTemplateOutlet="options"></ng-container>
                        </div>
                      </th>
                      <th class="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of groupsFiltered | slice:offset:limit">
                      <td>
                        {{ item.name }}
                      </td>
                      <td class="text-center">{{ countGroups[item.name] || 0 }}</td>
                      <td class="text-center">
                        <app-default-icon-button [tooltiptext]="'Editar'" [type]="'warning'" (pressed)="readId(item.id)">
                           <i class="fa fa-edit fa-lg mx-0"></i>
                        </app-default-icon-button>

                        <app-default-icon-button [tooltiptext]="'Excluir'" [type]="'danger'" (pressed)="onDelete(item)">
                          <i class="fa fa-trash fa-lg mx-0"></i>
                       </app-default-icon-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" [ngClass]="{ 'bg-white': isHorizontal }">
            <div class="col-12">
              <app-paginator-control [content]="groupsFiltered" (change)="onPaginate($event)"></app-paginator-control>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<ng-template #options>
  <app-table-filters-popup 
  [visible]="isOptionsOpened" 
  [field]="'Nome'" 
  [hasStatus]="false" 
  (orderBy)="onOrderBy($event)"
  (close)="onHideFilters()"
  (clear)="onClearFilters()"
  (search)="onSearch($event)">
</app-table-filters-popup>
</ng-template>


import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-crud-scope-card',
  templateUrl: './crud-scope-card.component.html',
  styleUrls: ['./crud-scope-card.component.css'],
})
export class CrudScopeCardComponent implements OnInit, OnChanges {
  @Output() checked: EventEmitter<any> = new EventEmitter();
  @Output() fullChecked: EventEmitter<any> = new EventEmitter();
  @Input() scope: any;
  @Input() isOpened: boolean = false;
  checkeds: any[] = [];

  get countCheckeds() {
    return this.scope.permissions.filter((per) => per.checked == true).length;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onEmitWhenHasAllChecked();
  }

  onEmitWhenHasAllChecked() {
    const scopesCheckeds = this.scope.permissions.filter((per) => per.checked == true);

    if (scopesCheckeds.length == 4) {
      this.fullChecked.emit({ id: this.scope.id, name: this.scope.name });
    }
  }

  onHandleFolder() {
    this.isOpened = !this.isOpened;
  }

  onCheck(permission: any) {
    permission.checked = !permission.checked;
    this.checkeds = this.scope.permissions.filter((per) => per.checked == true);
    this.checked.emit({ id: this.scope.id, name: this.scope.name, values: this.checkeds });
  }

  onSelectAllPermissions(value: boolean) {
    this.scope.permissions.map((per) => {
      per.checked = value;
    });
    this.checkeds = this.scope.permissions.filter((per) => per.checked == true);
    this.checked.emit({ id: this.scope.id, name: this.scope.name, values: this.checkeds });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ReportBuilderService } from '../../reports/report-builder/report-builder.service';
import { CrudBuilderService } from '../../cruds/crud-builder/crud-builder.service';

import { take } from 'rxjs/operators';
import { BaseFunc } from '../base-func/base-func';
import { DataEngineManager } from '../base-func/data-engine-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { Scenery } from '../../reports/scenery-builder/entities/scenery';
import { SceneryService } from '../../reports/scenery-builder/scenery.service';
import * as moment from 'moment';

@Component({
  selector: 'app-panel-list',
  templateUrl: './panel-list.component.html',
  styleUrls: ['./panel-list.component.css']
})
export class PanelListComponent extends BaseFunc implements OnInit {
  list_type: string;

  name: string;
  router_create: string;
  actions: string[][];
  entity_list: any[];
  entity_show: any[];
  sceneries: Scenery[];

  // Elementos de visualização
  loading_data = false;
  isGrid: boolean = true;
  showText: boolean = false;
  filterText: string = "";
  order: number = 1;
  colVisible: any = {};

  reportCodeToDelete: number;
  graphManager = new DataEngineManager(this);

  constructor(
    private router: Router,
    private active_route: ActivatedRoute,
    private sceneryService: SceneryService,
    private reportBuilderService: ReportBuilderService,
    private crudBuilderService: CrudBuilderService,
    private dataService: DataService) {
      super();
  }

  onVerifyUrl() {
    this.active_route.params.subscribe((params: any) => { 
      this.list_type = params['type'];
      this.name = {'analytics': 'Analytics', 'dashboard': 'Dashboard', 'scenary': 'Cenário', 'crud': 'Formulários', 'newsletter': 'Email' }[this.list_type];
      this.router_create = {'analytics': '/report-builder/analytics', 'dashboard': '/report-builder/dashboard', 'scenary': '/scenery-builder', 'crud': '/crud-builder', 'newsletter': '/report-builder/newsletter' }[this.list_type];
      this.actions = {'analytics': [], 'dashboard': [], 'scenary': [['compare-scenery', '/scenery-comparison', 'Comparar Cenários']], 'crud': [], 'newsletter': [] }[this.list_type];
      this.read_config_data();
    });
  }

  ngOnInit(): void {
    this.showSnackAfterReload();
    this.onVerifyUrl();
  }

  searchReport(code) {
    this.router.navigate({
      'scenary': ['/scenery-builder/' + code],
      'analytics': ['/report-builder/analytics/' + code ],
      'dashboard': ['/report-builder/dashboard/' + code ],
      'crud': ['/crud-builder/' + code],
      'newsletter': ['/report-builder/newsletter/' + code]
    }[this.list_type]);
  }

  changeView(value) {
    this.isGrid = (value == 'grid');
  }

  calculate_list() {
    var self = this;

    //Calcula as linhas visiveis
    self.colVisible['group'] = self.entity_list.filter(x => x['group'] != null && x['group'].trim() != '' ).length > 0;
    self.colVisible['graph'] = self.entity_list.filter(x => x['graph'] ).length > 0;
    self.colVisible['create_date'] = self.entity_list.filter(x => x['create_date'] != null && x['create_date'].trim() != '' ).length > 0;
    self.colVisible['update_date'] = self.entity_list.filter(x => x['update_date'] != null && x['update_date'].trim() != '' ).length > 0;
    self.colVisible['user'] = self.entity_list.filter(x => x['user'] != null && x['user'].trim() != '' ).length > 0;
    self.colVisible['complement'] = self.entity_list.filter(x => x['complement'] != null && x['complement'].length > 0 ).length > 0;
    self.colVisible['btn_complement'] = self.entity_list.filter(x => x['btn_complement'] != null && x['btn_complement'].length > 0 ).length > 0;

    self.entity_show = self.entity_list.filter(x => {
      return Object.keys( x ).filter( (a, ax) => { return x[a] != "" && x[a].toString().toLowerCase().indexOf(self.filterText.toLowerCase()) != -1; }).length > 0;
    });
    self.entity_show.sort( ( a, b ) => {
      const first = a['name'];
      const second = b['name'];
      const sortOrder = self.order;
      if (typeof first === "number")
        return (first-second) * sortOrder;
      else
        return (first.localeCompare(second)) * sortOrder;
    });
  }

  cloneReport(codeReport) {
    var newScenery;
    this.sceneryService.getScenary(codeReport).subscribe(
      backData => {
        backData.data.creationDate = moment(backData.data.creationDate);
        backData.data.projects.forEach(x => {
          x.startPeriod = moment(x.startPeriod);
          x.endPeriod = moment(x.endPeriod);
          x.valueImpact = parseFloat(x.valueImpact);
        });
        newScenery = backData.data;
        this.saveScenery(newScenery);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => { }
    );
  }

  saveScenery(newScenery: Scenery) {
    newScenery.id = null;
    newScenery.name = newScenery.name + " (Cópia)";
    this.sceneryService.saveScenery(newScenery).subscribe(
      backData => {
        newScenery.id = backData.data;
        this.showMessage(backData.status, 'Cenário duplicado com sucesso!', 'Erro ao duplicar cenário!');
        this.getSceneries();
      },
      error => {
        this.showSnack('Erro ao duplicar cenário. ' + error.message);
      },
      () => { }
    );
  }

  read_config_data = () => {
    var self = this;
    var func = {
      'analytics': ['reportBuilderService', 'getReportBuilderConf'],
      'dashboard': ['reportBuilderService', 'getReportBuilderConf'],
      'crud': ['crudBuilderService', 'getCRUDBuilderConf'],
      'newsletter': ['reportBuilderService', 'getNewsletterBuilderConf']
    }[self.list_type];

    self.loading_data = true;
    self[func[0]][func[1]](self.list_type == 'dashboard').pipe(take(1)).subscribe(
      data => {
        self.entity_list = data.entity_list;
        this.calculate_list();
        self.loading_data = false;
      },
      error => {
        console.log('Aconteceu um erro ao buscar o ' + self.list_type, error.message);
      },
      () => {
      }
    );
  }

  deleteReport(code) {
    var self = this;
    self.reportCodeToDelete = code;
    self.$j('#snack-modal-delete').modal('show');
  }

  confirmDeleteReport() {
    var self = this;
    var func = {
      'analytics': ['reportBuilderService', 'getReportBuilderDelete'],
      'dashboard': ['reportBuilderService', 'getReportBuilderDelete'],
      'scenary': ['sceneryService', 'deleteScenery'],
      'crud': ['crudBuilderService', 'getCrudBuilderDelete'],
      'newsletter': ['reportBuilderService', 'getNewsletterBuilderDelete']
    }[self.list_type];

    self.loading_data = true;
    self[func[0]][func[1]](this.reportCodeToDelete).pipe(take(1)).subscribe(
      data => {
        self.loading_data = false;
        this.showSnackBeforeReload(this.name + " excluído com sucesso!");
        location.reload();
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }

  hideSnackbarModal() {
    var self = this;
    self.loading_data = false;
    self.$j('#snack-modal-delete').modal('hide');
  }

  getSceneries(): void {
    this.sceneryService.getScenaries().subscribe(
      backData => {
        this.sceneries = <Scenery[]> backData.data;
        this.entity_list = this.sceneries.map(x => {
          return {
            'code': x.id,
            'name': x.name,
            'group': x.industrialUnity,
            'create_date': x.creationDate,
            'graph': true
          }
        });
        this.calculate_list();
        this.createGraphs();
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => { }
    );
  }

  createGraphs() {
    if (this.sceneries) {
      for (var i=0; i<this.sceneries.length; i++ ) {
        this.getSceneryAndCreateGraph(this.sceneries[i].id);
      }
    }
  }

  showMessage(status, successMessage: string, errorMessage: string) {
    if (status == 'success') {
      this.showSnack(successMessage);
    } else if(status == 'error') {
      this.showSnack(errorMessage);
    }
  }

  formatDate(value) {
    if (value == '')
      return '-';

    return moment(value).format('DD/MM/YYYY HH:mm');
  }

  getSceneryAndCreateGraph(idScenery: number) {
    //console.log('idScenery: '+idScenery);
    var scenery = this.sceneries.find(x => x.id == idScenery);
    //console.log('name: '+scenery.name);
    this.sceneryService.simulate(idScenery).subscribe(
      backData => {
        if (backData.status != 'error') {
          scenery.projections = backData.data.projections;
          //console.log('projections: '+scenery.projections.length);
        } else {
          console.log("error: "+backData.messageError)
        }
        setTimeout(function() {
          var colNames = ['Ano', 'BAU', scenery.name ];
          this.graphManager.initializeGraph(colNames, scenery.projections[1][0]['data'], {}, 'line', 'projection_graph_'+scenery.id, {"formatter": "default2,default2", "hideLegend": true, "hideGridLinesFunc": true}, 'months', true, false);
        }.bind(this), 100);
      },
      error => {
        console.log('Aconteceu um erro.', error.message);
      },
      () => {
      }
    );
  }
}

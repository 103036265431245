<ul class="nav nav-pills m-t-30 m-b-30">
  <li class="nav-item" (click)="setTabIndex(i)" *ngFor="let item of storeInstance.sectorTabs; index as i">
    <a href="#navpills-{{ i }}" class="nav-link" [class.active]="selectedIndex == i" data-toggle="tab" aria-expanded="false">{{ item.alias }}</a>
  </li>
</ul>

<div class="tab-content br-n pn">
    <div id="navpills-0" *ngIf="selectedIndex == 0">
      <app-point-read>
      </app-point-read>
    </div>
  
    <ng-container *ngFor="let item of storeInstance.sectorTabs; index as i">
      <div id="navpills-1" *ngIf="selectedIndex == i && selectedIndex != 0">
        <app-sector-read [info]="storeInstance.sectorTabs[selectedIndex]">
        </app-sector-read>
      </div>
    </ng-container>
</div>



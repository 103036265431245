
declare global {
    interface Date {
        format(pattern: string): string;
    }
 
    interface String {
        format(base:string, val1?, val2?, val3?, val4?, val5?, val6?, val7?, val8?, val9?, val10?): String;
        endsWith(suffix): boolean;
    }

    interface Array<T> {
        pivotCustomFind(str: string): number;
        copy(): Array<T>;
        append(arr: Array<T>): void;
    }
 }

String.prototype.format = function(base:string, val1?, val2?, val3?, val4?, val5?, val6?, val7?, val8?, val9?, val10?) {
    var s = arguments[0];
    var refArray = arguments.length == 2 && arguments[1].length != undefined ? [''].concat(arguments[1]) : arguments;
    for (var i = 0; i < refArray.length - 1; i++) {       
        var reg = new RegExp("\\{" + i + "\\}", "gm");             
        s = s.replace(reg, refArray[i + 1]);
    }

    return s;
};

String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

Array.prototype.pivotCustomFind = function(str) {
	for (var i=0;i<this.length;i++) if (this[i] == str) { return i; }
	return -1;
};

Array.prototype.copy = function() {
	var a = [];
	for (var i=0;i<this.length;i++) { a.push(this[i]); }
	return a;
};

Array.prototype.append = function(arr) {
	var a = arr;
	if (!(arr instanceof Array)) { a = [arr]; }
	for (var i=0;i<a.length;i++) { this.push(a[i]); }
};

Date.prototype.format = function(formatStr) {
    var result = formatStr;
	result = result.replace(/d/, this.getDate().toString().leadingZero(2));
	result = result.replace(/g/, ( parseInt(this.getHours()) % 12 ).toString() );
	result = result.replace(/G/, this.getHours() );
	//result = result.replace(/h/, ( parseInt(this.getHours()) % 12 ).toString().leadingZero(2));
	result = result.replace(/H/, this.getHours().toString().leadingZero(2));
	result = result.replace(/i/, this.getMinutes().toString().leadingZero(2));
	result = result.replace(/j/, this.getDate());
	result = result.replace(/m/, (this.getMonth()+1).toString().leadingZero(2));
	result = result.replace(/n/, this.getMonth()+1);
	result = result.replace(/s/, this.getSeconds().toString().leadingZero(2));
	result = result.replace(/U/, this.getTime());
	result = result.replace(/w/, this.getDay());
	result = result.replace(/Y/, this.getFullYear());
	result = result.replace(/x/, this.getMilliseconds().toString().leadingZero(3));
	return result;
};

export class CubePreConfig {

    static cubeDataFormat = {
        TYPE_BASIC: [0, "Básico - 1234.56"],
        TYPE_PERCENT: [1, "Percentual - 1234.56%"],
        TYPE_SCI: [2, "Científico - 1234E+02"],
        TYPE_SPACE: [3, "Com espaço - 1 234.56"],
        TYPE_CUSTOM: [4, "Customizado"],
        /* function in options.customType */
        TYPE_COMMA: [5, "Com vírgula - 1,234.56"],
        TYPE_CURRENCY: [6, "Moeda - $ 1,234.56"],
        /* currency symbol in options.currencySymbol. $ is default */
        TYPE_BROWSER: [7, "Representação Local"]
    };

    static localizate_event = (event) => {
        var isWebKit = navigator.userAgent.match(/AppleWebKit/);
        var isIE = document['attachEvent'] && !document.addEventListener;
        var scroll = [];
        if (isWebKit || (isIE && document.compatMode == "BackCompat")) 
            scroll = [document.body.scrollLeft, document.body.scrollTop];
            else 
            scroll = [Math.max(document.documentElement.scrollLeft, document.body.scrollLeft), 
                Math.max(document.documentElement.scrollTop, document.body.scrollTop)];
        
        
        return [event.clientX+scroll[0], event.clientY+scroll[1], event.clientX, event.clientY];
    }
}

<button *ngIf="hasID == null"  type="submit" id="saveView" name="saveView" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Salvar Visualização
</button>
<div class="dropdown-menu" (click)="dropdownMenu($event)" aria-labelledby="dropdownMenuButton">
    
    <div id="ok_message" name="ok_message" style="display: none">
        <h1 style="color: green;font-size: 60px;text-align: center;" ><i class="fa fa-check"></i> </h1>
    </div>
    <div id="saveFilterForm" name="saveFilterForm" style="margin: 15px;">
        <label for='viewName'>Nome</label>
        <input type='text' id='viewName' name='viewName' class="form-control" placeholder="Digite um nome para a visualização" maxlength="50" size='50'/>
        <button class='btn btn-secondary' id="SaveFilter" name="SaveFilter" (click)="save_filter($event)">Salvar</button>
    </div>
</div>
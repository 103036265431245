import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../../auth/auth.service';
import { UsersGroupsService } from '../users-groups.service';
import { BootstrapModalAlertService } from '../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Router } from '@angular/router';
import { Group } from '../group.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';

/**
 * O componente 'UsersGroupsCreateComponent' é responsável pela criação de novos grupos de usuários.
 *
 * Este componente gerencia a criação de novos grupos, interações com permissões
 * e fornece funcionalidade para salvar os dados do grupo.
 */
@Component({
  selector: 'app-users-groups-create',
  templateUrl: './users-groups-create.component.html',
  styleUrls: ['./users-groups-create.component.css'],
})
export class UsersGroupsCreateComponent implements OnInit, AfterViewInit {
  /**
   * Formulário para a criação de perfil (Grupo de permissões).
   */
  formGroup: FormGroup;

  /**
   * Lista para armazenar os IDs das permissões selecionados.
   */
  permissionsIds: number[] = [];

  /**
   * Indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Recebe as informações relativas as empresas (mapping).
   */
  mapping: any = {};

  /**
   * Recebe a empresa com suas respectivas informações .
   */
  companies: any[] = [];

  /**
   * Recebe a lista das empresas cadastradas no banco de dados (Keys).
   */
  source: any[] = [];

  /**
   * Variavel de controle do Toggle Collapse das hierarquias.
   */
  isCollapsedHierarchy = false;

  /**
   * Variavel de controle do Toggle Collapse das permissões.
   */
  isCollapsedPermissions = false;

  /**
   * Itens da varialve do ttree-view-checkbox.
   */
  items: TreeviewItem[];

  /**
   * Configuração do tree-view-checkbox.
   */
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });

  selectedCompanies: any[] = [];
  isOpened(data: any) {
    if (!this.formGroup) {
      return;
    }

    const company = this.formGroup.get('companies').value;
    return company !== data.company;
  }

  itemsByDb: any[] = [];
  selectedDb: number = null;
  treeViewByDb: { db: string; tree: TreeviewItem[]; selectedIds: number[] }[] = [];
  permissions: any[] = []

  hasCompanyToHisUser(db: string) {
    return this.selectedCompanies.filter((el) => el.company === db).length > 0 || db === 'permissões gerais';
  }

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private authService: AuthService,
    private usersGroupsService: UsersGroupsService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {}

  // Verificar a permissão de adicionar Nível organizacional
  hasHierarchyAddPermission(): boolean {
    return this.authService.checkPermission('add_authhierarchy');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadData();
    this.setupFormChanges();
  }

  /**
   * Inicializa o formulário reativo.
   */
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      person: [''],
      permissions: [[]],
      description: [''],
      companies: [[]],
      plants: [[]],
      auth_hierarchies: [[]],
    });
  }

  /**
   * Método chamado após a inicialização do Angular e das propriedades vinculadas a dados.
   * Inicializa as permissões disponíveis para o grupo.
   */
  ngAfterViewInit(): void {
    // Verifica se o usuário está habilitado
    this.authService.isEnable();

    // Verifica permissões para acessar a página
    var is_mdb =
      this.authService &&
      this.authService.getProfile().indexOf('2') != -1 &&
      this.authService &&
      ((this.authService.getPersonName() &&
        this.authService.getPersonName().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('mdiasbranco') != -1) ||
        (this.authService.getEmail() && this.authService.getEmail().toLowerCase().indexOf('terceirizadomdb') != -1));

    if (is_mdb == false && this.authService.checkPermission('add_group') == false) {
      this.router.navigate(['/403']);
    }

    // Obtém todas as permissões disponíveis
    this.usersGroupsService.getUsersPermissions().subscribe(
      (data) => {
        this.permissions = data;
        
        this.onTransformPermissionsByDb(data);
        this.items = this.transformPermissionsToTreeViewItems({ permissions: data });
      },
      (error) => {
        console.error(error);
        this.modalAlertService.showAlertDanger('Não foi possível listar as permissões');
      },
    );
  }

  onGetDbName(index: number) {
    let name = Object.keys(this.itemsByDb[index])[0];
    return name;
  }

  onSelectDb(index: number) {
    this.selectedDb = index;
  }

  onTransformPermissionsByDb(data: any[]) {
    const accumulator = data.reduce((acc: any, el: any) => {
      const { report_db } = el;

      const key = report_db === 'main' || report_db === 'None' || report_db === 'form' || report_db === null ? 'permissões gerais' : report_db;

      acc[key] = acc[key] || [];
      acc[key].push(el);

      return acc;
    }, {});

    this.itemsByDb = Object.entries(accumulator).map(([key, value]) => ({
      [key]: value,
    }));

    this.itemsByDb.map((el: any) => {
      const db = Object.keys(el)[0];
      this.treeViewByDb.push({
        db,
        tree: this.transformPermissionsToTreeViewItems({ permissions: el[db] }),
        selectedIds: [],
      });
    });
  }

  /**
   * Método para salvar o novo grupo.
   * Verifica as permissões e a identificação da aplicação antes de criar o grupo.
   */
  save() {
    // Marca o formulário como submetido.
    this.submitted = true;

    if (this.formGroup.valid) {
      if (this.authService.hasPerson() && this.authService.hasToken()) {
        this.formGroup.get('person').setValue(this.authService.getPerson());
        this.permissionsIds = [];

        this.treeViewByDb.map((tree) => {
          if (tree.selectedIds.length > 0) {
            this.permissionsIds = [...this.permissionsIds, ...tree.selectedIds];
          }
        });
        this.formGroup.get('permissions').setValue(this.permissionsIds);

        if (this.selectedCompanies.length == 0) {
          return;
        }

        let hierarchies = [];
        this.selectedCompanies.map((co) => {
          co.plants.map((plant) => {
            const payload = {
              hierarchy_id: plant.id,
              db_name: plant.report_db,
              hierarchy_type: 'Company',
              label: plant.person.trade_name,
            };
            hierarchies.push(payload);
          });
        });

        this.formGroup.get('auth_hierarchies').setValue(hierarchies);

        this.isLoading = true;

        // Envia a requisição para criar o grupo
        this.usersGroupsService.createUsersGroups(this.formGroup.value).subscribe(
          (data) => {
            // Retorna para a lista de grupos após a criação bem-sucedida
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
          (error) => {
            // Exibe alerta em caso de erro durante a criação do grupo
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          },
        ).add(() => {
          this.isLoading = false;
        });
      } else {
        // Trata o caso em que não há identificação de pessoa ou ID de aplicação
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de inválido.
      this.validateAllFormFields(this.formGroup);
    }
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo não for válido e o formulário foi submetido, caso contrário `false`.
   */
  isFieldValid(field: string): boolean {
    return !this.formGroup.get(field).valid && this.submitted;
  }

  /**
   * Obtém as classes CSS a serem aplicadas a um campo do formulário com base na validação.
   * @param field Nome do campo a ser verificado.
   * @returns Objeto com a classe 'is-invalid' se o campo for inválido, caso contrário `null`.
   */
  displayFieldCss(field: string): any {
    return {
      'is-invalid': this.isFieldValid(field),
    };
  }

  /**
   * Valida todos os campos de um formulário reativo.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
   * Cancela a operação e retorna para a tela anterior.
   */
  cancel() {
    this.location.back();
  }

  onSelectedChange(ids: number[], target: any): void {
    target.selectedIds = ids;
  }

  loadData() {
    this.authService
      .getCompanyList()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.source = data.keys;
          this.companies = [];
        },
        (error) => {
          console.error('Falha ao carregar empresas.', error.msg);
        },
      );
  }

  setupFormChanges() {
    this.formGroup.get('companies').valueChanges.subscribe((selectedCompany) => {
      this.updatePlantsOptions(selectedCompany);

      const indexOfDb = this.itemsByDb.findIndex((el) => Object.keys(el)[0] === selectedCompany);
      if (indexOfDb !== -1) {
        this.isCollapsedPermissions = false;
        this.onSelectDb(indexOfDb);
      }
    });
  }

  updatePlantsOptions(selectedCompany) {
    this.authService
      .getCompanyList()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.companies = data.mapping[selectedCompany] || [];

          this.formGroup.get('plants').reset();
          this.selectedCompanies.map((co) => {
            if (co.company == selectedCompany) {
              this.formGroup.get('plants').setValue(co.plants);
            }
          });
        },
        (error) => {
          console.error('Falha ao carregar empresas.', error.msg);
        },
      );
  }

  onSelectPlant(plants: { id: number; report_db: string; person: any }[]) {
    const company = this.formGroup.get('companies').value;
    const companyIndex = this.selectedCompanies.findIndex((co) => co.company == company);

    if (companyIndex !== -1 && plants.length == 0) {
      this.selectedCompanies.splice(companyIndex, 1);
      this.onClearTreeByCompanyIfIsEmpty(company);
      return;
    }

    if (companyIndex !== -1 && plants.length > 0) {
      this.selectedCompanies[companyIndex].plants = plants;
      return;
    }

    this.selectedCompanies.push({
      company,
      plants,
    });
  }

  onClearTreeByCompanyIfIsEmpty(company: string) {
    const treeExistsIndex = this.treeViewByDb.findIndex((tree) => tree.db == company);
    if(treeExistsIndex !== -1) {
      this.treeViewByDb[treeExistsIndex].selectedIds = [];
      const permissions = this.itemsByDb.filter((el) => Object.keys(el)[0] == company)[0][company];
      this.treeViewByDb[treeExistsIndex].tree = this.transformPermissionsToTreeViewItems({ permissions });
      this.onSelectDb(0);
    }
  }

  onRemoveSingle(data: { company: string; plantIndex: number }) {
    const companyIndex = this.selectedCompanies.findIndex((co) => co.company == data.company);
    if (companyIndex !== -1) {
      this.selectedCompanies[companyIndex].plants.splice(data.plantIndex, 1);
      this.formGroup.get('companies').setValue(data.company);
      this.formGroup.get('plants').setValue(this.selectedCompanies[companyIndex].plants);

      if (this.selectedCompanies[companyIndex].plants.length == 0) {
        this.selectedCompanies.splice(companyIndex, 1);
        this.onClearTreeByCompanyIfIsEmpty(data.company);
      }
    }
  }

  onRemoveAll(data: { company: string; plants: { id: number; report_db: string; person: any }[] }) {
    const company = this.formGroup.get('companies').value;
    if (company == data.company) {
      this.formGroup.get('plants').setValue([]);
    }
    this.formGroup.get('companies').setValue(data.company);
    this.selectedCompanies = this.selectedCompanies.filter((co) => co.company !== data.company);

    this.onClearTreeByCompanyIfIsEmpty(data.company);

  }

  transformPermissionsToTreeViewItems(permissionsJson: any): TreeviewItem[] {
    const groupedPermissions = this.groupPermissions(permissionsJson.permissions);

    const children = [].concat(
      ...Object.keys(groupedPermissions).map((actionType) => {
        return Object.keys(groupedPermissions[actionType]).map((screenOrReport) => {
          return {
            text: `${actionType} ${screenOrReport}`, // Texto do nível superior
            value: screenOrReport,
            collapsed: true,
            children: groupedPermissions[actionType][screenOrReport].map((action) => ({
              text: this.getActionName(action.codename), // Texto do nível de ação
              value: action.id, // O ID da ação
              checked: false,
            })),
          };
        });
      }),
    );

    return [
      new TreeviewItem({
        text: 'Selecionar todas as permissões',
        value: null,
        children: children,
      }),
    ];
  }

  groupPermissions(permissions: any[]): any {
    const grouped = {};

    permissions.forEach((permission) => {
      let typeKey = 'Gerenciar';
      let itemKey = permission.name;

      if (permission.codename.startsWith('report')) {
        typeKey = 'Relatorio';
        const reportNameMatch = permission.name.match(/Visualizar (.+)/i);
        itemKey = reportNameMatch && reportNameMatch[1] ? reportNameMatch[1].trim() : 'Relatório Sem Nome';
      } else {
        // Extrai o nome relevante da propriedade 'name' para ações não-relatório
        const actionRemoved = permission.name.split(' ').slice(2).join(' ');
        itemKey = actionRemoved || 'Ação Desconhecida';
      }

      
      if (!grouped[typeKey]) {
        grouped[typeKey] = {};
      }

      if (!grouped[typeKey][itemKey]) {
        grouped[typeKey][itemKey] = [];
      }

      // Evita adicionar a mesma ação de visualização mais de uma vez para o mesmo item.
      const existingAction = grouped[typeKey][itemKey].find((action) => action.codename === permission.codename);
      if (!existingAction) {
        grouped[typeKey][itemKey].push(permission);
      }
    });

    return grouped;
  }

  getScreenOrReportFromCodename(codename: string, name: string): string {
    if (codename.startsWith('report')) {
      const reportName = name.match(/Visualizar (.+)/i);
      return reportName && reportName[1] ? reportName[1] : 'Relatório Sem Nome';
    } else {
      // Remove a ação (primeira palavra) e retorna o restante como nome do objeto
      const actionRemoved = name.split(' ').slice(2).join(' ');
      return actionRemoved || 'Ação Desconhecida';
    }
  }

  getActionName(codename: string): string {
    if (codename.startsWith('view')) {
      return 'Visualizar';
    } else if (codename.startsWith('change')) {
      return 'Atualizar';
    } else if (codename.startsWith('add')) {
      return 'Adicionar';
    } else if (codename.startsWith('delete')) {
      return 'Excluir';
    } else if (codename.startsWith('report')) {
      return 'Visualizar';
    } else {
      return 'Ação Desconhecida';
    }
  }
}

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente responsável pela atualização de informações de tipos de IOT.
 */
@Component({
  selector: 'app-type-update',
  templateUrl: './type-update.component.html',
  styleUrls: ['./type-update.component.css']
})
export class TypeUpdateComponent implements OnInit {

  /**
   * Formulário reativo para a atualização de informações do tipo de IOT.
   */
  form: FormGroup;

  /**
   * Flag que indica se o formulário foi enviado/submetido.
   */
  submitted = false;

  /**
   * Variável para armazenar o setor selecionado.
   */
  selectedSector = "";

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutService
  ) { }

  /**
   * Inicializa o componente. Verifica a habilitação do usuário e as permissões.
   * Inicializa o formulário e lê os dados do tipo de equipamento com base no ID fornecido.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica as permissões de acesso.
    if (!this.authService.checkPermission('change_equipmenttype')) {
      this.router.navigate(['/403']);
    };

    // Obtém o ID do tipo de equipamento da rota e lê os dados correspondentes.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Inicializa o formulário com as validações necessárias.
    this.form = this.formBuilder.group({
      plant_equipment_type_id: '',
      company: '',
      has_ip: true,
      name: ['', Validators.required],
      is_measurement: true,
    });
  }

  /**
   * Realiza a leitura dos dados do tipo de IOT com base no ID fornecido.
   * @param id Identificador do tipo de IOT.
   */
  read = (id) => {
    // Chama o serviço para obter os dados do tipo de IOT.
    this.plantService.getEquipmentType(id).pipe(take(1)).subscribe(
      data => {
        // Preenche os campos do formulário com os dados obtidos.
        this.form.get('plant_equipment_type_id').setValue(data.plant_equipment_type_id);
        let companyValue = data.company && data.company.company_id ? data.company.company_id : null;
        this.form.get('company').setValue(companyValue);
        this.form.get('name').setValue(data.name);
        this.form.get('has_ip').setValue(data.has_ip);
        this.form.get('is_measurement').setValue(data.is_measurement);
      }, error => {
        // Trate o erro conforme necessário.
      }
    )
  }

  /**
   * Obtém os controles do formulário para facilitar o acesso na template.
   */
  get f() { return this.form.controls; }

  /**
   * Verifica se um campo específico do formulário é válido.
   * @param field Nome do campo.
   * @returns True se o campo for inválido e tiver sido tocado, false caso contrário.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes CSS a serem aplicadas a um campo com base em sua validade.
   * @param field Nome do campo.
   * @returns Objeto com as classes CSS.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   * @param formGroup Grupo do formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Atualiza as informações do tipo de IOT.
   */
  update = () => {
    this.submitted = true;

    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Chama o serviço para atualizar as informações do tipo de IOT.
        this.isLoading = true;
        this.plantService.updateEquipmentType(this.form.value).pipe(take(1)).subscribe(
          data => {
            // Exibe um alerta de sucesso em caso de sucesso.
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
          }, error => {
            // Exibe um alerta de perigo em caso de erro.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = true;
        })
      } else {
        // Exibe um alerta de perigo e realiza o logout em caso de falha na autenticação.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Valida todos os campos do formulário em caso de dados inválidos.
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Reseta o estado do formulário e lê novamente os dados do tipo de IOT com base no ID fornecido.
   */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

}

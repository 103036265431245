import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { Location } from '@angular/common';
import { PlantService } from './../../plant.service';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para a criação de Natureza de Medição.
 */
@Component({
  selector: 'app-nature-create',
  templateUrl: './nature-create.component.html',
  styleUrls: ['./nature-create.component.css']
})
export class NatureCreateComponent implements OnInit {

  /** Lista de opções para o campo de agregação. */
  aggrList = [
    { 'id': 'avg', 'value': 'Média' },
    { 'id': 'max', 'value': 'Máximo' },
    { 'id': 'min', 'value': 'Mínimo' },
    { 'id': 'sum', 'value': 'Soma' },
    { 'id': 'count', 'value': 'Contador' },
  ];

  /** Formulário para a criação da Natureza de Medição. */
  form: FormGroup;

  /** Indica se o formulário foi enviado. */
  submitted = false;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /** Inicialização do componente. */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('add_equipmentnature') == false) {
      this.router.navigate(['/403']);
    }

    // Inicializa o formulário.
    this.form = this.formBuilder.group({
      company: '',
      name: ['', [Validators.required, Validators.maxLength(100)]],
      aggregation: ['', Validators.required],
      un: ['', [Validators.required, Validators.maxLength(30)]]
    });
  }

  /**
   * Verifica se um campo do formulário é válido.
   *
   * @param field Nome do campo.
   * @returns `true` se o campo for inválido, `false` caso contrário.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes de estilo para exibição do campo no formulário.
   *
   * @param field Nome do campo.
   * @returns Objeto com as classes de estilo.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup Grupo de formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /** Salva as informações do formulário. */
  save = () => {
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Define os valores de company no formulário.
        this.form.get('company').setValue(this.authService.getPerson());

        // Chama o serviço para criar a Natureza de Medição.
        this.isLoading = true;
        this.plantService.createEquipmentNature(this.form.value).pipe(take(1)).subscribe(
          data => {
            this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
            this.location.back();
          }, error => {
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = false;
        });
      } else {
        // Exibe um alerta de perigo caso não haja um ID de aplicativo ou pessoa disponíveis.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }

    } else {
      // Caso o formulário não seja válido, valida todos os campos.
      this.validateAllFormFields(this.form);
    }
  }

  /** Reinicia o formulário. */
  reset = () => {
    this.submitted = false;
    this.form.reset();
  }

}

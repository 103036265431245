import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para a listagem de Naturezas da Medição.
 */
@Component({
  selector: 'app-nature-read',
  templateUrl: './nature-read.component.html',
  styleUrls: ['./nature-read.component.css']
})
export class NatureReadComponent implements OnInit {

  /** Lista de Naturezas. */
  equipmentNatures = [];
  equipmentNaturesFiltered: any[] = [];

  /** URL para a próxima página. */
  next: string;

  /** URL para a página anterior. */
  previous: string;

  /** Parâmetros HTTP. */
  params = new HttpParams();

  /** Referência ao modal de exclusão. */
  deleteModalRef: BsModalRef;

  /** Referência ao modal de exclusão. */
  @ViewChild('deleteModal') deleteModal;

  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /** Inicialização do componente. */
  ngOnInit(): void {

    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_equipmentnature') == false) {
      this.router.navigate(['/403']);
    };

    // Carrega todas as Naturezas na inicialização.
    this.readAll(this.authService.endpoint_main + 'plant/equipmentnature/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }
  
  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.equipmentNaturesFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.equipmentNaturesFiltered = this.equipmentNatures;
      return;
    }

    this.equipmentNaturesFiltered = this.equipmentNaturesFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onClearFilters() {
    this.equipmentNaturesFiltered = this.equipmentNatures;
  }

  /**
   * Lê todas as Naturezas.
   *
   * @param url URL para a leitura da lista.
   */
  readAll(url: string): void {
    // Verifica se existe token e pessoa disponíveis.
    if (this.authService.hasToken() && this.authService.hasPerson()) {

      const company = this.authService.getPerson();

      // Chama o serviço para obter a lista de Naturezas.
      this.plantService.getEquipmentNatureAllList(url, company).pipe(take(1)).subscribe(
        data => {
          this.equipmentNatures = data.results;
          this.equipmentNaturesFiltered = data.results;

          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          this.modalAlertService.showAlertDanger('Não foi possível listar naturezas');
        }
      );
    } else {
      // Exibe um alerta de perigo caso não haja um ID de aplicativo ou pessoa disponíveis.
      this.modalAlertService.showAlertDanger('Erro ao recuperar naturezas');
      this.authService.doLogout();
    }
  }

  /** Carrega a próxima página de Naturezas. */
  fetchNext() {
    this.readAll(this.next);
  }

  /** Carrega a página anterior de Naturezas. */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de uma Natureza específica.
   *
   * @param id Identificador da Natureza.
   */
  readId = (id) => {
    this.router.navigate(['/plant/equipmentnature/update', id]);
  }

  /** Navega para a página de criação de uma Natureza. */
  create = () => {
    this.router.navigate(['/plant/equipmentnature/create']);
  }

  /**
   * Executa a exclusão de uma Natureza.
   *
   * @param item Natureza a ser excluída.
   */
  onDelete = (item) => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('delete_equipmentnature') == false) {
      this.router.navigate(['/403']);
    };

    // Define o item a ser excluído.
    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir esta natureza?'
    );

    // Aguarda a resposta do modal.
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.plantService.deleteEquipmentNature(item.plant_equipment_nature_id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o item excluído da lista.
          let index;
          this.equipmentNatures.forEach((e, i) => {
            if (e.plant_equipment_nature_id === item.plant_equipment_nature_id) {
              index = i;
            }
          });
          this.equipmentNatures.splice(index, 1);
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

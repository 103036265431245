export class CubeMathLib {

    static list = [
		{ longDesc: "Contar", shortDesc: "COUNT", func: "count" },
        { longDesc: "Soma", shortDesc: "SUM", func: "sum" },
        { longDesc: "Soma", shortDesc: "SUM", func: "sumAgg" },
		{ longDesc: "Produto", shortDesc: "PRODUCT", func: "product" },
		{ longDesc: "Média aritmética", shortDesc: "MEAN", func: "amean" },
        { longDesc: "Média aritmética", shortDesc: "MEAN", func: "ameanAgg" },
        { longDesc: "Proporção", shortDesc: "PROP", func: "proportionvar" },
        { longDesc: "Proporção", shortDesc: "PROP", func: "proportionAgg" },
        { longDesc: "Média geométrica", shortDesc: "GEOMEAN", func: "geomeanAgg" },
		{ longDesc: "Máximo", shortDesc: "MAX", func: "max" },
		{ longDesc: "Mínimo", shortDesc: "MIN", func: "min" },
		{ longDesc: "Valores distintos", shortDesc: "DISTINCT", func: "distinct" },
        { longDesc: "Contagem de valores distintos", shortDesc: "DISTINCTCOUNT", func: "distinctcount" },
        { longDesc: "Variação Absoluta", shortDesc: "VARABS", func: "varabs" },
		{ longDesc: "Variação", shortDesc: "VAR", func: "variance" },
		{ longDesc: "Variação padrão", shortDesc: "STDDEV", func: "deviation" },
		{ longDesc: "Mediana", shortDesc: "MEDIAN", func: "median" },
		{ longDesc: "Moda", shortDesc: "MODE", func: "mode" },
        { longDesc: "Variação Percentual", shortDesc: "PERCENTVAR", func: "percentvar" },
        { longDesc: "Variação Percentual", shortDesc: "PERCENTVAR", func: "percentvarAgg" },
        { longDesc: "Primeiro", shortDesc: "FIRST", func: "first" },
        { longDesc: "Último", shortDesc: "LAST", func: "last" },
        { longDesc: "Potência", shortDesc: "fp_Agg", func: "fp_Agg" }
	]

    static count = (arr) => {
        return arr.length;
    };

    static sum = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 0;
        for (var i = 0; i < arr.length; i++) {
            value += (arr[i] != null && !isNaN(arr[i]) ? arr[i] : 0);
        }
        return value;
    };

    static sumAgg = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 0;
        for (var i = 0; i < arr.length; i++) {
            value += (arr[i] != null && !isNaN(arr[i]) ? arr[i] : 0);
        }
        return value;
    };

    static product = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 1;
        for (var i = 0; i < arr.length; i++) {
            value *= arr[i];
        }
        return value;
    };

    static avg = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 0;
        for (var i = 0; i < arr.length; i++) {
            value += arr[i];
        }
        value = (arr.length ? value / arr.length : 0);
        return value;
    };

    static amean = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 0;
        for (var i = 0; i < arr.length; i++) {
            value += arr[i];
        }
        value = (arr.length ? value / arr.length : 0);
        return value;
    };

    static ameanAgg = (arr, aux) => {
        var value = 0;
        var absoleSumArr = arr.map(x => Array.isArray(x) ? CubeMathLib.sum(x) : x );
        var sumValues = CubeMathLib.sum(absoleSumArr);

        var absoleCountArr = aux.map(x => Array.isArray(x) ? CubeMathLib.sum(x) : x );
        var sumCount = CubeMathLib.sum(absoleCountArr);

        if (sumCount > 0) {
            value = sumValues / sumCount;
        }

        return value;
    };

    static fp_Agg = (arr, aux) => {
        var value = 0;
        var sumActive = CubeMathLib.sum(aux[0]);
        var sumReactive = CubeMathLib.sum(aux.length > 1 ? aux[1] : []);

        value = sumActive / Math.sqrt( Math.pow(sumActive,2) + Math.pow(sumReactive,2) );

        return value;
    };

    static percentvar = (arr, aux) => {
        var value = 0;
        var sumValues, sumBase;
        if (aux && aux.length > 1) {
            sumValues = aux[1].length ? CubeMathLib.sum(aux[1]) : aux[1];
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        } else {
            sumValues = CubeMathLib.sum(arr);
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        }

        if (!sumValues.length && !sumBase.length && !isNaN(sumValues) && !isNaN(sumBase)) {
            if (sumBase == 0 && sumValues > 0)
                value = 100;
            else if (sumBase == 0 && sumValues < 0)
                value = -100;
            else if (sumBase == 0 && sumValues == 0)
                value = 0;
            else
                value = ((sumValues / sumBase) - 1) * 100;
        }

        return value;
    };

    static percentvarAgg = (arr, aux) => {
        var value = 0;
        var sumValues, sumBase;
        if (aux && aux.length > 1) {
            sumValues = aux[1].length ? CubeMathLib.sum(aux[1]) : aux[1];
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        } else {
            sumValues = CubeMathLib.sum(arr);
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        }

        if (!sumValues.length && !sumBase.length && !isNaN(sumValues) && !isNaN(sumBase)) {
            if (sumBase == 0 && sumValues > 0)
                value = 100;
            else if (sumBase == 0 && sumValues < 0)
                value = -100;
            else if (sumBase == 0 && sumValues == 0)
                value = 0;
            else
                value = ((sumValues / sumBase) - 1) * 100;
        }

        return value;
    };

    static proportionvar = (arr, aux) => {
        var value = 0;
        var sumValues = aux[1].length ? CubeMathLib.sum(aux[1]) : aux[1];
        var sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];

        if (!sumValues.length && !sumBase.length && !isNaN(sumValues) && !isNaN(sumBase)) {
            if (sumBase == 0 && sumValues > 0)
                value = 100;
            else if (sumBase == 0 && sumValues < 0)
                value = -100;
            else if (sumBase == 0 && sumValues == 0)
                value = 0;
            else
                value = ((sumValues / sumBase)) * 100;
        }

        return value;
    };

    static proportionAgg = (arr, aux) => {
        var value = 0;
        var sumValues = aux[1].length ? CubeMathLib.sum(aux[1]) : aux[1];
        var sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];

        if (!sumValues.length && !sumBase.length && !isNaN(sumValues) && !isNaN(sumBase)) {
            if (sumBase == 0 && sumValues > 0)
                value = 100;
            else if (sumBase == 0 && sumValues < 0)
                value = -100;
            else if (sumBase == 0 && sumValues == 0)
                value = 0;
            else
                value = ((sumValues / sumBase)) * 100;
        }

        return value;
    };

    static geomean = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = 1;
        for (var i = 0; i < arr.length; i++) {
            value *= Math.pow(arr[i], 1 / arr.length);
        }
        return value;
    };

    static geomeanAgg = (arr, aux) => {
        var sumCount = CubeMathLib.sum(aux.length > 0 ? aux[0] : []);
        var value = 0;
        if (sumCount > 0) {
            var sumArr = CubeMathLib.sum(arr);
            value = Math.pow(Math.E, sumArr / sumCount);
        }

        return value;
    };

    static max = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        var value = Number.MIN_VALUE;
        for (var i = 0; i < arr.length; i++)
            if (arr[i] > value) {
                value = arr[i];
            }
        return value == Number.MIN_VALUE ? null : value;
    };

    static min = (arr) => {
        var value = Number.MAX_VALUE;
        for (var i = 0; i < arr.length; i++)
            if (arr[i] < value) {
                value = arr[i];
            }
        return value == Number.MAX_VALUE ? null : value;;
    };

    static distinct = (arr) => {
        var value = 0;
        var values = {};
        for (var i = 0; i < arr.length; i++) {
            values[arr[i]] = 1;
        }
        for (var p in values) {
            value++;
        }
        return value;
    };

    static distinctcount = (arr) => {
        var value = 0;
        var values = {};
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] instanceof Array == true) {
                for (var j = 0; j < arr[i].length; j++) {
                    if (arr[i][j] instanceof Array == true) {
                        for (var k = 0; k < arr[i][j].length; k++)
                            values[arr[i][j][k]] = 1;
                    } else
                        values[arr[i][j]] = 1;
                }
            } else
                values[arr[i]] = 1;
        }
        for (var p in values) {
            value++;
        }
        return value;
    };

    static varabs = (arr, aux) => {
        var value = 0;
        var sumValues, sumBase;
        if (aux && aux.length > 1) {
            sumValues = aux[1].length ? CubeMathLib.sum(aux[1]) : aux[1];
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        } else {
            sumValues = CubeMathLib.sum(arr);
            sumBase = aux[0].length ? CubeMathLib.sum(aux[0]) : aux[0];
        }



        return sumValues - sumBase;
    };

    static deviation = (arr) => {
        var v = CubeMathLib.variance(arr);
        return Math.sqrt(v);
    };

    static variance = (arr) => {
        if (arr.length < 2) {
            return 0;
        }
        var value = 0;
        var avg = CubeMathLib.amean(arr);
        for (var i = 0; i < arr.length; i++) {
            value += (arr[i] - avg) * (arr[i] - avg);
        }
        return (value / (arr.length - 1));
    };

    static median = (arr) => {
        var sorted = arr.sort(function (a, b) {
            return a - b;
        });
        var i = Math.floor(arr.length / 2);
        return sorted[i];
    };

    static mode = (arr) => {
        var conversion = {};
        for (var i = 0; i < arr.length; i++) {
            var val = arr[i];
            var index = val + "";
            if (!(index in conversion)) {
                conversion[index] = 1;
            } else {
                conversion[index]++;
            }
        }
        var max = 0;
        var prop = "";
        for (var p in conversion) {
            var cnt = conversion[p];
            if (cnt > max) {
                max = cnt;
                prop = p;
            }
        }
        return parseFloat(prop);
    };

    static first = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        return arr[0];
    };

    static last = (arr) => {
        if(arr.length == 0 || arr.filter(x=> !isNaN(x) ).length == 0 )
            return null;

        return arr[arr.length - 1];
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformDbName'
})
export class TransformDbNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value == 'None') {
      return 'permissões gerais'
    }
    if(value == 'main') {
      return 'Qualquercoisa'
    }

    return value;
  }

}

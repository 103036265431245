import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.css'],
})
export class NotificationBellComponent implements OnInit, OnDestroy {
  @Input() notifications: any[] = [];

  interval: any = null;
  isHorizontal = window['whiteLabelConf']('horizontal');

  hasPopUpOpened: boolean = false;

  constructor(public headerService: HeaderService) {}

  ngOnInit(): void {
    this.onFindNotifications();
    this.interval = setInterval(() => {
      this.headerService.getNofificationList().subscribe((data) => {
        this.notifications = data;
      });
    }, 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  onFindNotifications() {
    this.headerService.getNofificationList().subscribe((data) => {
      this.notifications = data;
    });
  }

  onShowNotifications() {
    this.hasPopUpOpened = !this.hasPopUpOpened;
  }

  onClosePopUp() {
    this.hasPopUpOpened = false;
    this.notifications = [];
  }
}

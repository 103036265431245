import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { AuthService } from '../../../../../auth/auth.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para criação de um grupo empresarial.
 */
@Component({
  selector: 'app-company-group-create',
  templateUrl: './company-group-create.component.html',
  styleUrls: ['./company-group-create.component.css']
})
export class CompanyGroupCreateComponent implements OnInit {

  /** Objeto representando os detalhes do grupo empresarial. */
  group = {
    name: '',
    active: true
  };

  /**
   * Construtor para injetar dependências necessárias
   */
  constructor(
    private companyService: CompanyService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso e a habilitação do usuário.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('add_companygroup') == false) {
      this.router.navigate(['/403']);
    };
  }

  /**
 * Salva os detalhes do grupo empresarial.
 */
  save = () => {
    // Verifica se existe token.
    if (this.authService.hasToken()) {
      // Chama o serviço para criar o novo grupo empresarial.
      this.companyService.createCompanyGroup(this.group).pipe(take(1)).subscribe(
        data => {
          // Atribui os dados retornados ao objeto 'group'.
          this.group = data;

          // Navega de volta à página anterior.
          this.location.back();

          // Exibe um alerta de sucesso.
          this.modalAlertService.showAlertSuccess('Registro incluído com sucesso.');
        },
        error => {
          // Em caso de erro, exibe um alerta de perigo.
          this.modalAlertService.showAlertDanger('Erro ao incluir o registro.');
        }
      )
    } else {
      // Se não houver um ID, exibe um alerta de perigo e realiza o logout.
      this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
      this.authService.doLogout();
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PlantService } from './../../plant.service';
import { HttpParams } from '@angular/common/http';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

/**
 * Componente para exibir e gerenciar a listagem.
 */
@Component({
  selector: 'app-bundler-read',
  templateUrl: './bundler-read.component.html',
  styleUrls: ['./bundler-read.component.css']
})
export class BundlerReadComponent implements OnInit {

  /** Lista de agrupador de IOT. */
  bundleList = [];
  bundleListFiltered: any[] = [];
  next: string;
  previous: string;
  selectedBundle = {};
  form: FormGroup;

  /** Parâmetros para a requisição HTTP. */
  params = new HttpParams();

  /** Referência para o modal de exclusão. */
  deleteModalRef: BsModalRef;
  @ViewChild('deleteModal') deleteModal;
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
    { label: 'Agrupação', field: 'group', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  offset: number = 0;
  limit: number = 5;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private plantService: PlantService,
    private fb: FormBuilder,
    public layout: LayoutService
  ) { }

  /**
   * Método chamado ao inicializar o componente.
   * Verifica se o usuário está habilitado e se tem permissão para visualizar equipamentos.
   * Inicializa a listagem de agrupadores.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('view_equipment')) {
      this.router.navigate(['/403']);
    };

    // Inicializa a leitura de bundles.
    this.readAll(this.authService.endpoint_main + 'plant/equipmentbundle/list');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }
  
  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.bundleListFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.bundleListFiltered = this.bundleList;
      return;
    }
    
    this.bundleListFiltered = this.bundleListFiltered.filter((el: any) => el[filter.field].toLowerCase().includes(value))
  }

  onClearFilters() {
    this.bundleListFiltered = this.bundleList;
  }

  /**
  * Realiza a leitura de todos os agrupadores.
  * @param url URL para a leitura do agrupador.
  */
  readAll(url: string): void {
    // Verifica se existe token e se há uma pessoa autenticada.
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      const company = this.authService.getPerson();

      // Chama o serviço para obter a lista de agrupadores de equipamentos.
      this.plantService.getEquipmentBundleAllList(url, company).pipe(take(1)).subscribe(
        data => {
          // Atualiza a lista de agrupadores com os dados recebidos.
          this.bundleList = data;
          this.bundleListFiltered = data;

          // Atualiza os links para páginas seguintes e anteriores.
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Exibe um alerta de erro em caso de falha ao obter a lista de agrupadores.
          this.modalAlertService.showAlertDanger('Não foi possível listar os bundles');
        }
      );
    } else {
      // Exibe um alerta de erro em caso de falha na autenticação.
      this.modalAlertService.showAlertDanger('Erro ao recuperar naturezas');
      this.authService.doLogout();
    }
  }

  /**
   * Obtém a próxima página.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Obtém a página anterior.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de um bundle específico.
   * @param id Identificador do agrupador a ser atualizado.
   */
  readId(id) {
    this.router.navigate(['/plant/equipmentbundle/update', id]);
  }

  /**
   * Navega para a página de criação de um novo agrupador.
   */
  create() {
    this.router.navigate(['/plant/alarm/create']);
  }

  /**
  * Executa a exclusão de um item específico.
  * @param item Item a ser excluído.
  */
  onDelete(item: any): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (!this.authService.checkPermission('delete_equipment')) {
      this.router.navigate(['/403']);
      return; // Encerra a execução do método se não houver permissão.
    }

    // Armazena o item selecionado.
    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este bundle?'
    );

    // Aguarda a resposta do modal.
    result$.asObservable()
      .pipe(
        take(1),
        // Executa a exclusão se a resposta for verdadeira, caso contrário, emite um Observable vazio.
        switchMap(result => result ? this.plantService.deleteEquipmentBundle(item.id) : EMPTY)
      )
      .subscribe(
        // Em caso de sucesso, remove o item da lista e exibe um alerta de sucesso.
        success => {
          let index = this.bundleList.findIndex(e => e.id === item.id);
          if (index !== -1) {
            this.bundleList.splice(index, 1);
          }
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        // Em caso de erro, exibe um alerta de erro.
        error => {
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }

}

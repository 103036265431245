import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../../../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BootstrapModalAlertService } from './../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PlantService } from './../../plant.service';
import { EquipmentType } from './../../plant.model';
import { take } from 'rxjs/operators';

/**
 * Componente Angular para a atualização de equipamentos.
 */
@Component({
  selector: 'app-equipment-update',
  templateUrl: './equipment-update.component.html',
  styleUrls: ['./equipment-update.component.css']
})
export class EquipmentUpdateComponent implements OnInit {

  /** FormGroup para gerenciar o estado do formulário reativo. */
  form: FormGroup;

  /** Indica se o formulário foi submetido. */
  submitted = false;

  /** String para armazenar pontos. */
  points = '';

  /** Lista de tipos de IOT disponíveis. */
  types: EquipmentType[];

  /** String para armazenar naturezas. */
  natures = '';

  /** Variáveis para armazenar valores selecionados. */
  selectedType = '';
  selectedPoint = '';
  selectedNature = '';

  /** Arrays para armazenar informações de equipamentos e pontos virtuais. */
  plant_equipment = [];
  virtual = [];

  /** Link para a página anterior. */
  previous: string;

  /** Link para a próxima página. */
  next: string;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private modalAlertService: BootstrapModalAlertService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  /**
   * Método executado na inicialização do componente.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissão de mudança de IOT.
    if (this.authService.checkPermission('change_equipment') == false) {
      this.router.navigate(['/403']);
    };

    // Inicializa o formulário reativo.
    this.form = this.formBuilder.group({
      plant_equipment_id: '',
      company: '',
      name: ['', Validators.required],
      equipment_nature: ['', Validators.required],
      equipment_type: ['', Validators.required],
      point: ['', Validators.required],
      id_capture: null,
      is_on: false,
      is_real: true,
      is_dealership: true,
      theorical_consumption: null,
      ip_address: ['', Validators.maxLength(12)],
      mid_value: null,
      period: null,
      plant_equipment: [null],
      virtual: [],
    });

    // Obtém o ID da rota e carrega os dados do IOT.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });

    // Obtém a lista de equipamentos da planta.
    let url_plant_equipment_list = this.authService.endpoint_main + 'plant/equipment/list/nopage';
    if (this.authService.hasToken() && this.authService.hasPerson()) {
      let company = this.authService.getPerson();
      this.plantService.getEquipmentAllList(url_plant_equipment_list, company).pipe(take(1)).subscribe(
        data => {
          this.plant_equipment = data;
          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => { }
      );
    }

    // Verifica pontos virtuais.
    this.pointVirtual();

    // Obtém a lista de equipamentos virtuais.
    let url_plant_equipment = this.authService.endpoint_main + 'plant/equipment-virtual/list/';

    if (this.authService.hasToken() && this.authService.hasPerson()) {
      let company = this.authService.getPerson();
      let id: number;
      this.route.paramMap.subscribe((param: ParamMap) => {
        id = +param.get('id');
      });
      this.plantService.getEquipmentVirtual(id, url_plant_equipment, company).pipe(take(1)).subscribe(
        data => {
          this.virtual = data;
          this.setValuesVirtualPoint();
        },
        error => { }
      );
    }
  }

  /**
   * Obtém os dados do IOT com o ID fornecido.
   * @param id ID do IOT.
   */
  read = (id) => {
    this.plantService.getEquipment(id).pipe(take(1)).subscribe(
      data => {
        this.form.get('plant_equipment_id').setValue(data.plant_equipment_id);
        let companyValue = data.company && data.company.company_id ? data.company.company_id : null;
        this.form.get('company').setValue(companyValue);
        this.form.get('name').setValue(data.name);
        this.form.get('id_capture').setValue(data.id_capture);
        this.form.get('point').setValue(data.plant_point_id);
        this.form.get('equipment_type').setValue(data.plant_equipment_type_id);
        this.form.get('equipment_nature').setValue(data.plant_equipment_nature_id);
        this.form.get('is_on').setValue(data.is_on);
        this.form.get('is_real').setValue(data.is_real);
        this.form.get('is_dealership').setValue(data.is_dealership);
        this.form.get('ip_address').setValue(data.ip_address);
        this.form.get('theorical_consumption').setValue(data.theorical_consumption);
      }, error => {
      }
    )

    // Obtém a lista de pontos.
    let url_points = this.authService.endpoint_main + 'plant/point/list/nopage';
    let company = this.authService.getPerson();
    this.plantService.getPointAllList(url_points, company).pipe(take(1)).subscribe(
      data => {
        this.points = data;
        this.selectedPoint = this.form.get('point').value;
      }, error => { }
    )

    // Obtém a lista de naturezas.
    let url_natures = this.authService.endpoint_main + 'plant/equipmentnature/list/nopage';
    this.plantService.getEquipmentNatureAllList(url_natures, company).pipe(take(1)).subscribe(
      data => {
        this.natures = data;
        this.selectedNature = this.form.get('equipment_nature').value;
      }, error => { }
    )

    // Obtém a lista de tipos de IOT.
    let url_types = this.authService.endpoint_main + 'plant/equipmenttype/list/nopage';
    this.plantService.getEquipmentTypeAllList(url_types, company).pipe(take(1)).subscribe(
      data => {
        this.types = data;
        this.selectedType = this.form.get('equipment_type').value;;
      }, error => { }
    )
  }

  /**
   * Verifica se um campo do formulário é válido.
   * @param field Nome do campo do formulário.
   * @returns `true` se o campo é inválido, `false` caso contrário.
   */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes CSS a serem aplicadas a um campo do formulário com base em sua validade.
   * @param field Nome do campo do formulário.
   * @returns Objeto contendo as classes CSS.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos de um formulário.
   * @param formGroup Formulário a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Verifica o tipo de IOT e se o endereço IP é necessário.
   * @returns `true` se o tipo e o IP são válidos, `false` caso contrário.
   */
  verifyType = () => {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == true && this.form.get('ip_address').value == '') {
        return false;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == true && this.form.get('ip_address').value != '') {
        return true;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == false && this.form.get('ip_address').value == '') {
        return true;
      } else if (this.types[i].plant_equipment_type_id == this.form.get('equipment_type').value && this.types[i].has_ip == false && this.form.get('ip_address').value != '') {
        return true;
      }
    }
  }

  /**
   * Método chamado para atualizar as informações do IOT.
   * Realiza a validação do formulário, verifica se o tipo de IOT requer um endereço IP
   * e se o formulário é válido, realiza a chamada para o serviço de atualização.
   */
  update = () => {
    // Marca o formulário como submetido
    this.submitted = true;

    // Obtém os pontos virtuais do formulário
    this.getVirtualPoint();

    // Verifica se a propriedade 'plant_equipment' é nula ou indefinida e a remove
    this.form.value['plant_equipment'] == null || this.form.value['plant_equipment'] == undefined ? 0 : delete this.form.value['plant_equipment'];

    // Define a propriedade 'virtual' do formulário com os pontos virtuais
    this.form.value.virtual = this.virtual;

    // Verifica se o formulário é válido
    if (this.form.valid) {
      // Verifica se existe autenticacao
      if (this.authService.hasToken() && this.authService.hasPerson()) {
        // Define a propriedade 'virtual' do formulário com os pontos virtuais
        this.form.value.virtual = this.virtual;

        // Verifica se o tipo de IOT requer um endereço IP
        if (this.verifyType()) {
          // Chama o serviço para atualizar as informações do IOT
          this.isLoading = true;
          this.plantService.updateEquipment(this.form.value).pipe(take(1)).subscribe(
            data => {
              // Adiciona os pontos virtuais ao objeto de resposta
              data.virtual = this.virtual;

              // Verifica duplicatas nos pontos virtuais
              let verifyEqual = [];
              let lenghtEqual = [];
              if (data['virtual'] == undefined) {
                data.virtual = this.virtual;
              }
              if (data['virtual'].length >= 1) {
                for (let i = 0; data['virtual'].length > i; i++) {
                  verifyEqual.push(data['virtual'][i]['plant_equipment_id_real']);
                }

                for (let i = 0; verifyEqual.length - 1 > i; i++) {
                  for (let j = 0; verifyEqual.length - 1 > j; j++) {
                    if (verifyEqual[i] == verifyEqual[j]) {
                      lenghtEqual.push(verifyEqual[j]);
                    }
                  }
                }
              }

              // Exibe alerta em caso de pontos virtuais duplicados
              if (verifyEqual.length - 1 > lenghtEqual.length && lenghtEqual.length > 0) {
                this.modalAlertService.showAlertDanger(`Existem pontos virtuais duplicados`);
              } else {
                // Exibe alerta de sucesso após a atualização
                this.modalAlertService.showAlertSuccess('Registro incluído com sucesso');
              }
            },
            error => {
              // Exibe alerta de erro em caso de falha na atualização
              this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
            }
          ).add(() => {
            scrollTo(0,0);
            this.isLoading = false;
          });
        } else {
          // Exibe alerta em caso de falta de endereço IP para tipos específicos de IoT
          this.modalAlertService.showAlertDanger('Endereço de IP é necessário para esse tipo de IoT');
        }
      } else {
        // Exibe alerta em caso de falha na autenticação
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Realiza a validação de todos os campos do formulário
      this.validateAllFormFields(this.form);
    }
  }

  /**
   * Método chamado para redefinir o estado do formulário e carregar as informações do IOT com base no ID fornecido na rota.
   * Reseta a flag 'submitted' para falso e chama o método 'read' para carregar as informações do IOT.
   */
  reset = () => {
    // Reseta a flag 'submitted' para falso
    this.submitted = false;

    // Obtém o ID do IOT a partir dos parâmetros da rota
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');

      // Chama o método 'read' para carregar as informações do IOT com base no ID
      this.read(id);
    });
  }

  /**
 * Método utilizado para gerenciar a lógica e a interação do usuário com os pontos virtuais.
 * Adiciona e remove dinamicamente pontos virtuais no formulário com base nas ações do usuário.
 * Controla a visibilidade da seção de pontos virtuais dependendo da opção 'is_real' selecionada.
 */
  pointVirtual = () => {
    // Referências aos elementos DOM relevantes
    let selectIsReal = document.querySelector('[formcontrolname="is_real"]');
    let selectDivVirtualPoint = document.querySelector('.contain-virtual-point');
    let selectADDCircle = document.querySelector('.add-circle');
    let selectRemoveCircle = document.querySelector('.remove-circle');

    /**
     * Função interna que duplica dinamicamente um ponto virtual.
     * Adiciona o ponto virtual clonado à seção de pontos virtuais e configura os ouvintes de eventos necessários.
     */
    function duplicateVirtualPoint() {
      let selectDivVirtualPointTOClone = document.querySelector('.virtual-point').cloneNode(true);
      document.querySelector('.contain-virtual-point').appendChild(selectDivVirtualPointTOClone);
      let selectADDCircleArray = document.querySelectorAll('.add-circle');
      let selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      selectRemoveCircleArray[selectRemoveCircleArray.length - 1].addEventListener('click', removeVirtualPoint);

      selectADDCircleArray.forEach(function (el, index, array) {
        el.removeEventListener('click', duplicateVirtualPoint);
        el.addEventListener('click', duplicateVirtualPoint);
      });
    }

    /**
     * Função interna que remove dinamicamente o último ponto virtual adicionado.
     * Remove o ponto virtual correspondente na seção de pontos virtuais e reconfigura os ouvintes de eventos.
     */
    function removeVirtualPoint() {
      let selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      if (selectRemoveCircleArray.length >= 2) {
        selectRemoveCircleArray[selectRemoveCircleArray.length - 1].parentElement.parentElement.remove();
      }
      selectRemoveCircleArray = document.querySelectorAll('.remove-circle');
      selectRemoveCircleArray.forEach(function (el, index, array) {
        el.removeEventListener('click', removeVirtualPoint);
        el.addEventListener('click', removeVirtualPoint);
      });
    }

    // Verifica o estado inicial da opção 'is_real' para controlar a visibilidade da seção de pontos virtuais
    if (!selectIsReal['checked']) selectDivVirtualPoint.classList.remove('is-hidden');

    // Configura os ouvintes de eventos para a opção 'is_real' e os botões de adição/remoção de pontos virtuais
    selectIsReal.removeEventListener('change', () => { });
    selectIsReal.addEventListener('change', () => {
      if (!selectIsReal['checked']) {
        selectDivVirtualPoint.classList.remove('is-hidden');
      } else if (selectIsReal['checked']) {
        selectDivVirtualPoint.classList.add('is-hidden');
      }
    });

    selectADDCircle.addEventListener('click', duplicateVirtualPoint);
    selectRemoveCircle.addEventListener('click', removeVirtualPoint);
  }

  /**
   * Método utilizado para extrair dados dos pontos virtuais do formulário e atualizar a propriedade 'virtual'.
   * Obtém os valores dos campos relacionados aos pontos virtuais e os armazena em um array de objetos.
   * Atualiza a propriedade 'virtual' com os dados coletados ou define como um array vazio se não houver pontos virtuais.
   */
  getVirtualPoint = () => {
    // Referências aos elementos DOM relevantes
    let plant_equipment_id = document.querySelectorAll('[ng-reflect-name="plant_equipment"]');
    let factor = document.querySelectorAll('.factor');
    let operation = document.querySelectorAll('.operation');
    let virtualPointArrayData = [];

    // Verifica se há pelo menos um ponto virtual no formulário
    if (plant_equipment_id.length >= 1) {
      // Itera sobre os elementos e cria objetos com os valores dos campos relacionados aos pontos virtuais
      for (let i = 0; plant_equipment_id.length > i; i++) {
        let x = {
          factor: factor[i]['value'],
          operation: operation[i]['value'],
          plant_equipment_id_real: plant_equipment_id[i]['value']
        };
        virtualPointArrayData.push(x);
      }
      // Atualiza a propriedade 'virtual' com os dados coletados
      this.virtual = virtualPointArrayData;
    } else {
      // Define a propriedade 'virtual' como um array vazio se não houver pontos virtuais
      this.virtual = [];
    }
  };

  /**
   * Método utilizado para popular os campos do formulário com os valores dos pontos virtuais armazenados na propriedade 'virtual'.
   * Verifica se há pontos virtuais na propriedade 'virtual' e, se houver, duplica dinamicamente os campos no formulário
   * para acomodar todos os pontos virtuais. Em seguida, preenche os campos duplicados com os valores correspondentes.
   */
  setValuesVirtualPoint = () => {
    // Verifica se há pelo menos um ponto virtual na propriedade 'virtual'
    if (this.virtual.length >= 1) {
      // Referências aos elementos DOM relevantes
      let plant_equipment = document.querySelectorAll('[ng-reflect-name="plant_equipment"]');
      let factor = document.querySelectorAll('.factor');
      let operation = document.querySelectorAll('.operation');

      // Itera sobre os pontos virtuais e duplica dinamicamente os campos no formulário
      for (let i = 0; this.virtual.length > plant_equipment.length; i++) {
        let duplicateVirtualPoint = new this.pointVirtual();
        duplicateVirtualPoint.duplicateVirtualPoint();
      }

      // Preenche os campos duplicados com os valores correspondentes dos pontos virtuais
      for (let i = 0; plant_equipment.length > i; i++) {
        plant_equipment[i]['value'] = this.virtual[i]['plant_equipment_id_real'];
        factor[i]['value'] = this.virtual[i]['factor'];
        operation[i]['value'] = this.virtual[i]['operation'];
      }
    }
  };

}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainlayoutComponent } from './../../views/mainlayout/mainlayout.component';

import { SectorReadComponent } from './../plant/sector/sector-read/sector-read.component';
import { SectorUpdateComponent } from './../plant/sector/sector-update/sector-update.component';
import { SectorCreateComponent } from './../plant/sector/sector-create/sector-create.component';

import { PointReadComponent } from './../plant/point/point-read/point-read.component';
import { PointCreateComponent } from './../plant/point/point-create/point-create.component';
import { PointUpdateComponent } from './../plant/point/point-update/point-update.component';

import { TypeReadComponent } from './../plant/type/type-read/type-read.component';
import { TypeCreateComponent } from './../plant/type/type-create/type-create.component';
import { TypeUpdateComponent } from './../plant/type/type-update/type-update.component';

import { NatureReadComponent } from './../plant/nature/nature-read/nature-read.component';
import { NatureCreateComponent } from './../plant/nature/nature-create/nature-create.component';
import { NatureUpdateComponent } from './../plant/nature/nature-update/nature-update.component';

import { EquipmentReadComponent } from './../plant/equipment/equipment-read/equipment-read.component';
import { EquipmentCreateComponent } from './../plant/equipment/equipment-create/equipment-create.component';
import { EquipmentUpdateComponent } from './../plant/equipment/equipment-update/equipment-update.component';

import { EquipmentImageReadComponent } from './../plant/equipment-image/equipment-image-read/equipment-image-read.component';
import { EquipmentImageCreateComponent } from './../plant/equipment-image/equipment-image-create/equipment-image-create.component';
import { EquipmentImageUpdateComponent } from './../plant/equipment-image/equipment-image-update/equipment-image-update.component';

import { AlarmCreateComponent } from './../plant/alarm/alarm-create/alarm-create.component';
import { AlarmReadComponent } from './../plant/alarm/alarm-read/alarm-read.component';
import { AlarmUpdateComponent } from './../plant/alarm/alarm-update/alarm-update.component';


import { BundlerCreateComponent } from './../plant/bundler/bundler-create/bundler-create.component';
import { BundlerReadComponent } from './../plant/bundler/bundler-read/bundler-read.component';
import { BundlerUpdateComponent } from './../plant/bundler/bundler-update/bundler-update.component';

const routes: Routes = [
  {
    path: 'plant/sectors',
    component: MainlayoutComponent,
    children: [
      { path: '', component: SectorReadComponent }
    ]
  },
  {
    path: 'plant/sector/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: SectorCreateComponent }
    ]
  },
  {
    path: 'plant/sector/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: SectorUpdateComponent }
    ]
  },
  {
    path: 'plant/points',
    component: MainlayoutComponent,
    children: [
      { path: '', component: PointReadComponent }
    ]
  },
  {
    path: 'plant/point/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: PointCreateComponent }
    ]
  },
  {
    path: 'plant/point/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: PointUpdateComponent }
    ]
  },
  {
    path: 'plant/equipmenttypes',
    component: MainlayoutComponent,
    children: [
      { path: '', component: TypeReadComponent }
    ]
  },
  {
    path: 'plant/equipmenttype/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: TypeCreateComponent }
    ]
  },
  {
    path: 'plant/equipmenttype/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: TypeUpdateComponent }
    ]
  },
  {
    path: 'plant/equipmentnatures',
    component: MainlayoutComponent,
    children: [
      { path: '', component: NatureReadComponent }
    ]
  },
  {
    path: 'plant/equipmentnature/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: NatureCreateComponent }
    ]
  },
  {
    path: 'plant/equipmentnature/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: NatureUpdateComponent }
    ]
  },
  {
    path: 'plant/equipments',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentReadComponent }
    ]
  },
  {
    path: 'plant/equipment/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentCreateComponent }
    ]
  },
  {
    path: 'plant/equipment/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentUpdateComponent }
    ]
  },
  {
    path: 'plant/equipmentimages',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentImageReadComponent }
    ]
  },
  {
    path: 'plant/equipmentimage/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentImageCreateComponent }
    ]
  },
  {
    path: 'plant/equipmentimage/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: EquipmentImageUpdateComponent }
    ]
  },
  {
    path: 'plant/equipmentbundle/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: BundlerCreateComponent }
    ]
  },
  {
    path: 'plant/equipmentbundle',
    component: MainlayoutComponent,
    children: [
      { path: '', component: BundlerReadComponent }
    ]
  },
  {
    path: 'plant/equipmentbundle/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: BundlerUpdateComponent }
    ]
  },
  {
    path: 'plant/alarm/create',
    component: MainlayoutComponent,
    children: [
      { path: '', component: AlarmCreateComponent }
    ]
  },
  {
    path: 'plant/alarm',
    component: MainlayoutComponent,
    children: [
      { path: '', component: AlarmReadComponent }
    ]
  },
  {
    path: 'plant/alarm/update/:id',
    component: MainlayoutComponent,
    children: [
      { path: '', component: AlarmUpdateComponent }
    ]
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlantRoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapModalAlertService } from './../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { PersonsPerfilService } from './../persons-perfil.service';
import { HttpParams } from '@angular/common/http';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { FormBuilder, FormGroup } from '@angular/forms';

/**
 * Componente para visualizar perfis de pessoas.
 */
@Component({
  selector: 'app-persons-perfil-read',
  templateUrl: './persons-perfil-read.component.html',
  styleUrls: ['./persons-perfil-read.component.css']
})
export class PersonsPerfilReadComponent implements OnInit {

  /** Lista de perfis de pessoas. */
  perfils: { id: number, name: string, active: boolean }[] = [];
  perfilsFiltered: { id: number, name: string, active: boolean }[] = [];
  /** URL para a próxima página de resultados paginados. */
  next: string;
  /** URL para a página anterior de resultados paginados. */
  previous: string;

  /** Parâmetros HTTP para a solicitação. */
  params = new HttpParams();

  /** Referência ao modal de exclusão. */
  deleteModalRef: BsModalRef;
  /** Referência ao elemento de exclusão no template. */
  @ViewChild('deleteModal') deleteModal;
  /** Item selecionado para exclusão. */
  selectedItem;

  isOptionsOpened: boolean = false;
  selectedFilter: string;
  search: FormGroup;

  offset: number = 0;
  limit: number = 5;

  filters: { label: string, field: any, type: any, value: any }[] = [
    { label: 'Nome', field: 'name', type: 'text', value: null },
    { label: 'Ativo', field: 'active', type: 'text', value: null },
  ]

  get orderBy() {
    const filter = this.filters.filter((el) => el.label === this.selectedFilter)[0];
    return filter.value;
  }

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private authService: AuthService,
    private modalAlertService: BootstrapModalAlertService,
    private router: Router,
    private personPerfilService: PersonsPerfilService,
    public layout: LayoutService,
    private fb: FormBuilder
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso e a habilitação do usuário, em seguida, realiza a leitura inicial dos perfis.
   */
  ngOnInit(): void {
    this.initForm();
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('view_personperfil') == false) {
      this.router.navigate(['/403']);
    };

    // Inicia a leitura dos perfis.
    this.readAll(this.authService.endpoint_main + 'personsperfil/');
  }

  onPaginate({ offset, limit }: any) {
    this.offset = offset;
    this.limit = limit;
  }

  initForm() {
    this.search = this.fb.group({
      value: ['']
    })
  }

  onShowFilters(field: any) {
    this.selectedFilter = field;
    this.isOptionsOpened = true
  }

  onHideFilters() {
    this.isOptionsOpened = false;
    this.selectedFilter = null;
  }

  onOrderBy(value: 'asc' | 'desc') {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];
    filter.value = value;

    this.perfilsFiltered.sort((a, b) => {
      if (value === 'asc') {
        return a[filter.field].localeCompare(b[filter.field]);
      } else {
        return b[filter.field].localeCompare(a[filter.field]);
      }
    })
  }

  onFilterBoolean(value: boolean) {
    const filter = this.filters.filter((el) => el.label == 'Ativo')[0]
    filter.value = value;

    this.perfilsFiltered = this.perfils.filter((el) => el[filter.field] == value);
  }

  onClearFilters() {
    this.perfilsFiltered = this.perfils;
  }

  onSearch(value: string) {
    const filter = this.filters.filter((el) => el.label == this.selectedFilter)[0];

    if(value == '') {
      this.perfilsFiltered = this.perfils;
      return;
    }
    this.perfilsFiltered = this.perfilsFiltered.filter((el)=> el[filter.field].includes(value));
  }

  /**
   * Realiza a leitura de todos os perfis, utilizando a URL fornecida.
   *
   * @param url URL para buscar os perfis.
   */
  readAll(url: string) {
    // Verifica se existe token.
    if (this.authService.hasToken()) {

      // Busca os perfis usando o serviço PersonsPerfilService.
      this.personPerfilService.getPersonsPerfils(url).pipe(take(1)).subscribe(
        data => {
          // Atualiza a lista de perfis com os dados retornados.
          this.perfils = data.results;
          this.perfilsFiltered = data.results;

          if (data.next) {
            this.next = data.next;
          }
          if (data.previous) {
            this.previous = data.previous;
          }
        },
        error => {
          // Exibe uma mensagem de alerta em caso de erro.
          this.modalAlertService.showAlertDanger('Não foi possível listar perfis de pessoa');
        }
      );

    } else {
      // Caso não haja ID de aplicativo, exibe uma mensagem de erro e realiza logout.
      this.modalAlertService.showAlertDanger('Erro ao recuperar perfis');
      this.authService.doLogout();
    }
  }

  /**
   * Busca os itens da próxima página de resultados paginados.
   */
  fetchNext() {
    this.readAll(this.next);
  }

  /**
   * Busca os itens da página anterior de resultados paginados.
   */
  fetchPrevious() {
    this.readAll(this.previous);
  }

  /**
   * Navega para a página de atualização de um perfil específico.
   *
   * @param id ID do perfil a ser atualizado.
   */
  readId = (id) => {
    this.router.navigate(['/personsperfil/update', id]);
  }

  /**
   * Navega para a página de criação de um novo perfil.
   */
  create = () => {
    this.router.navigate(['/personsperfil/create']);
  }

  /**
   * Inicia o processo de exclusão de um perfil.
   *
   * @param item Perfil a ser excluído.
   */
  onDelete = (item) => {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de exclusão de perfil.
    if (this.authService.checkPermission('delete_personperfil') == false) {
      this.router.navigate(['/403']);
    };

    // Define o item a ser excluído.
    this.selectedItem = item;

    // Exibe um modal de confirmação.
    const result$ = this.modalAlertService.showConfirm(
      'Confirmação',
      'Confirma a intenção de excluir este perfil?'
    );

    // Assina o resultado do modal e, se confirmado, realiza a exclusão usando o PersonsPerfilService.
    result$.asObservable()
      .pipe(
        take(1),
        switchMap(result => result ? this.personPerfilService.deletePersonsPerfil(item.id) : EMPTY)
      )
      .subscribe(
        success => {
          // Remove o perfil da lista após a exclusão bem-sucedida.
          let index;
          this.perfils.forEach((e, i) => {
            if (e.id === item.id) {
              index = i;
            }
          });
          this.perfils.splice(index, 1);

          // Exibe uma mensagem de sucesso.
          this.modalAlertService.showAlertSuccess('Registro removido com sucesso.');
        },
        error => {
          // Lida com erros durante a exclusão do perfil.
          this.modalAlertService.showAlertDanger('Erro ao tentar excluir.');
        }
      );
  }
}

import { Injectable } from '@angular/core';
import { IlayoutInformation } from '../../interfaces/layout-information.interface';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  width: number = 0;
  height: number = 0;

  layoutType: 'DEFAULT' | 'MYECOS' = 'DEFAULT';
  isLoadedStyles: boolean = false;

  isFullScreen: boolean = false;

  logoFull: string;
  logoMini: string;
  showLogoMini: boolean = false;

  mainTitles: any[] = [];

  loadedTheme: boolean = false;

  constructor() {}

  aliasTitle(label: string) {
    const item = this.mainTitles?.filter((el) => el.label == label);
    return (item.length > 0 && item[0].alias) ? item[0].alias : label;
  }

  aliasTitleByKey(key: string) {
    const item = this.mainTitles?.filter((el) => el.key == key);
    return item[0].alias
  }

  onSetScreenDetails({ height, width, layout }: IlayoutInformation) {
    this.width = width;
    this.height = height;
    this.layoutType = layout;
  }

  onHandleLogo(show: boolean) {
    this.showLogoMini = show;
  }

  onHandleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }
}

<div  class="modal-options mt-2 rounded shadow-sm absolute bg-white border tour-table-filter-popup" *ngIf="visible">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center mx-4">
        <i class="fa-solid fa-filter"></i>
        <h4 class="mx-2 mb-0">Filtros</h4>
      </div>
      <button class="btn tour-table-filter-close" (click)="onClose()">
        <i class="fa-solid fa-xmark fa-lg"></i>
      </button>
    </div>

    <hr class="w-100 my-2">
   
    <div class="d-flex flex-column mx-4 my-3">
      <h5 class="mt-2">Campo selecionado</h5>
      <span class="mb-2" style="font-weight: 400;">{{ field }}</span>

      <ng-container *ngIf="hasOrdernator">
        <h5 class="my-2">Ordenação</h5>
        <div class="d-flex mb-3 tour-table-filter-order">
            <button class="d-flex align-items-center border rounded py-2 w-100 mr-2" (click)="onOrderBy('asc')" [ngClass]="{ 'bg-primary text-white': config.order == 'asc' }">
            <i class="fa-solid fa-arrow-up-z-a"></i>
            <span class="mx-2">Ascendente</span>
            </button>

            <button class="d-flex align-items-center border rounded py-2 w-100" (click)="onOrderBy('desc')" [ngClass]="{ 'bg-primary text-white': config.order == 'desc' }">
            <i class="fa-solid fa-arrow-down-z-a"></i>
            <span class="mx-2">Decrescente</span>
            </button>
        </div>
      </ng-container>

      <ng-container *ngIf="hasSearch">
        <h5 class="my-2">Buscar informações</h5>
        <form [formGroup]="form">
            <div class="d-flex align-items-center justify-content-between border rounded mb-2 bg-form tour-table-filter-search">
                <input type="text" class="h-100 w-100 py-2" placeholder="Digite aqui" formControlName="value" (keyup)="onSearch()">
            </div>
        </form>
      </ng-container>

      <ng-container *ngIf="hasStatus">
        <h5 class="">Status</h5>
        <div class="d-flex mb-2">
            <button class="d-flex align-items-center border rounded py-2 w-100 mr-2" [ngClass]="{ 'bg-primary text-white': config.status == true }" (click)="onChangeStatus(true)">
                <i class="fa-solid fa-toggle-on"></i>
                <span class="mx-2">Ativo</span>
            </button>

            <button class="d-flex align-items-center border rounded py-2 w-100" [ngClass]="{ 'bg-primary text-white': config.status == false }" (click)="onChangeStatus(false)">
                <i class="fa-solid fa-toggle-off"></i>
                <span class="mx-2">Desativado</span>
            </button>
        </div>
      </ng-container>

      <button class="d-flex align-items-center justify-content-center border rounded py-2 w-100 my-2 tour-table-filter-clear"(click)="onClear()">
        <i class="fa-solid fa-filter-circle-xmark"></i>
        <span class="mx-2">Limpar</span>
      </button>

    </div>
  </div>
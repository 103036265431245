import { BaseFunc } from '../../shared/base-func/base-func';
import { CubeMetric, NumberConfig } from './cube-metric';
import { CubeThread } from './cube-thread';
import { CubeCluster } from './cube-cluster';
import { CubeDrag, CubeDragEvent } from './cube-drag';
import { CubeMathLib } from './cube-math-lib';
import { CubePreConfig } from './cube-pre-config';

export class CubeMachine extends BaseFunc {

    options;
    drag;
    div;
    config_div;
    loading_div;
    filterDiv;
    chartDiv;
    chartbtn;
    graphConfig;
    headerRow;
    allData;
    filteredData;
    tabularData;
    rawTabularData;
    rawColTotals;
    rawRowTotals;
    rawGTotal;

    rowConditions;
    colConditions;
    filterIndexes;
    metricCollection;
    metricShowedLabel;
    metricTempFormatters
    metricFormatters
    mapAssociationIndex;

    metricDinamycMap;

    progressCount;
    progressMessage;

    threadCount;
    threadTableCount;

    conditions;
    rowStructure;
    colStructure;
    colPointers;
    rowPointers;
    rowTotals;
    colTotals;
    gTotal;
    subTotals;

    dimensionDepth;

    rankedOrder;
    rankedIndex;
    clearRank;
    clusterManager;

    propPage;
    modalPage;

    w;
    h;

    constructor(div, chartDiv, filterDiv, metrics, headerRow, dataRows, headerRowIndexes, headerColIndexes, filterIndexes, optObj, formatterCells, callbackFunction) {
        super();
        var self = this;
        this.options = {
            defaultgraphapi: true,
            staticpivottable: false,
            headingBefore: 1,
            headingAfter: 1,
            agg: 1,
            aggTotals: 1,
            type: CubePreConfig.cubeDataFormat.TYPE_BROWSER[0],
            currencySymbol: "R$",
            showEmpty: 0,
            subtotals: 0,
            totals: 1,
            aggMetric: 0,
            formatterCells: formatterCells,
            callbackFunction: callbackFunction,
            emptyCell: "",
            hasColSpan: true,
            hasRowSpan: true
        };

        if (optObj)
            for (var p in optObj)
                this.options[p] = optObj[p];


        this.drag = new CubeDrag();
        this.drag.onFail = self.lightOff;
        this.div = self.$j('#' + div)[0];
        this.div.className = "pivotTableArea dc-scroll";
        this.config_div = self.$j(this.div).parent().find('.p_config_div')[0];
        this.loading_div = self.$j("#pivot_div_bar")[0];
        this.filterDiv = self.$j('#' + filterDiv)[0];
        this.chartDiv = self.$j('#' + chartDiv)[0];
        this.chartDiv.className = "pivotGraphArea";
        this.chartbtn = self.$j('.graphChartsBtn')[0];
        this.graphConfig = [];

        this.headerRow = headerRow;
        this.allData = dataRows;
        this.filteredData = [];
        this.tabularData = [];
        this.rawTabularData = [];
        this.rawColTotals = [];
        this.rawRowTotals = [];
        this.rawGTotal = [];

        this.rowConditions = headerRowIndexes;
        this.colConditions = headerColIndexes;
        this.filterIndexes = filterIndexes;
        this.metricCollection = metrics;
        this.metricShowedLabel = [];
        this.metricTempFormatters = []
        this.metricFormatters = []
        this.mapAssociationIndex = [];

        this.metricDinamycMap = [];

        this.progressCount = 0;
        this.progressMessage = "";

        this.threadCount = 0;
        this.threadTableCount = 0;

        this.conditions = [];
        this.filterDiv.selects = [];
        this.rowStructure = {};
        this.colStructure = {};
        this.colPointers = [];
        this.rowPointers = [];
        this.rowTotals = [];
        this.colTotals = [];
        this.gTotal = [];
        this.subTotals = [];

        this.dimensionDepth = {};

        this.rankedOrder = -1;
        this.rankedIndex = -1;
        this.clearRank = true;
        this.clusterManager = null;
    }

    lightOn = () => {
        var self = this;
        for (var i = 0; i < self.drag.targets.length; i++) {
            var elm = self.drag.targets[i][0];
            elm.style.color = "#f00";
            if(self.$j(elm).hasClass('dispose_area')) {
                self.$j(elm).css('border', '1px #f00 solid');
                self.$j(elm).css({'border-style': 'dashed'});
                if (!self.filterIndexes.length)
                    self.$j(elm).html("Área de dimensões a disposição");
            }
        }
    };

    lightOff = () => {
        var self = this;
        for (var i = 0; i < self.drag.targets.length; i++) {
            var elm = self.drag.targets[i][0];
            elm.style.color = "";
            if(self.$j(elm).hasClass('dispose_area')) {
                self.$j(elm).css('border', '');
                self.$j(elm).css({'border-style': ''});
                if (!self.filterIndexes.length)
                    self.$j(elm).html("");
            }
        }
    };
    //self.drag.onFail = self.lightOff;

    process = (elm) => {
        var self = this;
        self.lightOn();
        elm.style.backgroundColor = "#888";
        elm.style.padding = "2px";
        elm.style.cursor = "pointer";
        self.$j(elm).mouseup(function (e) {
            self.lightOff();
        });
    };

    filterOK = (row) => {
        var self = this;
        /* does row pass filters? */
        for (var i = 0; i < self.rowConditions.length; i++) {
            /* row blacklist */
            var value = row[self.rowConditions[i]];
            var cond = self.conditions[self.rowConditions[i]];
            if (cond.blackList.pivotCustomFind(value) != -1) {
                return false;
            }
        }
        for (var i = 0; i < self.colConditions.length; i++) {
            /* column blacklist */
            var value = row[self.colConditions[i]];
            var cond = self.conditions[self.colConditions[i]];
            if (cond.blackList.pivotCustomFind(value) != -1) {
                return false;
            }
        }
        return true;
    };

    sort = (cond) => {
        var self = this;
        /* sort distinct values of a condition */
        var months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        var sortFunc;
        var coef = cond.sort;
        var numSort = function (a, b) {
            if (a == b) {
                return 0;
            }
            return coef * (parseInt(a) > parseInt(b) ? 1 : -1);
        }
        var dictSort = function (a, b) {
            if (a == b) {
                return 0;
            }
            return coef * (a > b ? 1 : -1);
        }
        var dateSort = function (a, b) {
            var ia = months.pivotCustomFind(a.toLowerCase());
            var ib = months.pivotCustomFind(b.toLowerCase());
            return numSort(ia, ib);
        }
        /* get data type, trial & error... */
        var testValue = cond.distinctValues[0];
        if (testValue == parseInt(testValue)) {
            sortFunc = numSort;
        } else {
            sortFunc = dictSort;
        }
        if (months.pivotCustomFind(testValue.toString().toLowerCase()) != -1) {
            sortFunc = dateSort;
        }

        cond.distinctValues.sort(sortFunc);
    };

    quickSort = (items, left, right, comparator) => {
        var self = this;
        var swap = function (items, firstIndex, secondIndex) {
            var temp = items[firstIndex];
            items[firstIndex] = items[secondIndex];
            items[secondIndex] = temp;
        };
        var partition = function (items, left, right, comparator) {
            var pivot = items[Math.floor((right + left) / 2)],
                i = left,
                j = right;
            while (i <= j) {
                while (comparator(items[i], pivot) < 0) {
                    i++;
                }
                while (comparator(items[j], pivot) > 0) {
                    j--;
                }
                if (i <= j) {
                    swap(items, i, j);
                    i++;
                    j--;
                }
            }
            return i;
        };

        var index;
        if (items.length > 1) {
            left = typeof left != "number" ? 0 : left;
            right = typeof right != "number" ? items.length - 1 : right;
            index = partition(items, left, right, comparator);

            if (left < index - 1) {
                self.quickSort(items, left, index - 1, comparator);
            }
            if (index < right) {
                self.quickSort(items, index, right, comparator);
            }
        }
        return items;
    };

    initCondition = (position) => {
        var self = this;
        for (var p = 0; p < self.metricCollection.length; ++p)
            if (position == self.metricCollection[p].metricIndex) { //Verifica se o indice do vetor de cabeçalhos é igual ao do elemento indicado como métrica
                self.conditions.push(false); //Seta o elemento métricas, ou seja aquele que não deve ser
                return;
            }

        var cond = {
            distinctValues: [],
            decodedValues: [],
            blackList: [],
            sort: 1,
            subtotals: self.options.subtotals,
            dimension: self.headerRow[position]
        }; //Constroi um objeto chamado cond
        self.conditions.push(cond); //Adiciona objeto cond ao vetor conditions do RDCJS

        var array = self.allData;
        var work = new CubeThread(array);
        var dimension = new Object();

        work.action = function (item, index, total) {
            self.progressLoader("Resolvendo condição " + index + " de " + total);
            var value = array[index][position];

            /*if (cond.distinctValues.pivotCustomFind(value) == -1) { //Verifica se o elemento faz parte do vetor distinctValues daquele objeto cond
            cond.distinctValues.push(value);         //Adiciona elemento a coleção distinct values
            } // if new value */

            dimension[value] = 1;
        };

        work.finish = function (thread) {
            --self.threadCount;
            for (var i in dimension)
                if (dimension.hasOwnProperty(i))
                    cond.distinctValues.push(i);

            self.sort(cond); //Ordena o vetor de cond
        };

        ++self.threadCount;
        work.start();
    };

    getAggregationFunc = (isTotal, position) => {
        var self = this;
        var aggType = isTotal ? "aggTotals" : "agg";
        var indexAgg = self.metricCollection[position][aggType][self.metricCollection[position][aggType + "Index"]];

        return CubeMathLib[indexAgg];
    };

    findDinamycMetricByName = (name) => {
        var self = this;
        var position = -1;
        for (var i = 0; i < self.metricDinamycMap.length; ++i) {
            if (self.metricDinamycMap[i].name == name) {
                position = i;
                break;
            }
        }
        return position;
    };

    initModalPage = () => {
        var self = this;
        self.$j('#pivotModal').remove();
        return self.$j(`<div id="pivotModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title"></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="backModalBtn btn btn-default" style="display: none">Voltar</button>
                        <button type="button" class="saveConfigBtn btn btn-primary" style="display: none" >Salvar Configurações</button>
                        <button type="button" class="cancelBtn btn btn-default" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="actionModal btn btn-primary" >Aplicar</button>
                    </div>
                </div>
            </div>
        </div>`)[0];
    };

    initPropPage = () => {
        var self = this;
        self.$j("#float_pivot_box").remove();
        return self.$j(`<div id="float_pivot_box" name="float_pivot_box"
            style="position: absolute;border: 1px solid #000;border-radius: 25px;padding: 20px;background-color: #fff;z-index: 100">
            </div>`)[0];
    };

    init = () => {
        var self = this;
        self.propPage = self.initPropPage();
        self.modalPage = self.initModalPage();
        document.body.appendChild(self.propPage);
        document.body.appendChild(self.modalPage);
        self.$j(document).mouseup(function(e)
        {
            var container = self.$j(self.propPage);
            // if the target of the click isn't the container nor a descendant of the container
            if (container != e.target && container.find(e.target).length === 0)
                container.hide();
        });

        if (!self.options.staticpivottable) {
            self.$j('.metricDP').unbind('click');
            self.$j('.metricDP').click(self.callMetricPanel());
        }

        self.$j(self.div).empty();
        self.$j(self.chartDiv).empty();
        self.$j(self.propPage).empty();
        self.$j(self.filterDiv).empty();
        self.$j(self.chartDiv).hide();
        self.$j(self.config_div).hide();
        self.$j(self.loading_div).show();

        var bound = self.headerRow.length + self.metricCollection.length - 1;
        for (var i = 0; i < bound; i++) { //Itera sobre o vetor de cabeçalhos
            self.initCondition(i); //Método utilizado para construir o vetor de conditions(Vetor que irá guardar os elementos de colunas
            //bem como os valores distintos para cada um
        }
    };

    getOrderReference = (conditionIndex) => {
        var self = this;
        return function (target, x, y) {
            /* somehow reorder conditions */
            self.lightOff();

            /* filters? */
            if (target == self.filterDiv) {
                self.filterIndexes.push(conditionIndex);
                self.conditions[conditionIndex].blackList = [];
                for (var i = 0; i < self.rowConditions.length; i++) {
                    if (self.rowConditions[i] == conditionIndex) {
                        self.rowConditions.splice(i, 1);
                    }
                }
                for (var i = 0; i < self.colConditions.length; i++) {
                    if (self.colConditions[i] == conditionIndex) {
                        self.colConditions.splice(i, 1);
                    }
                }
                self.buildDataSource();
                return;
            }

            var sourceCI = conditionIndex; /* global index */
            var targetCI = target.conditionIndex; /* global index */
            if (sourceCI == targetCI) {
                return;
            } /* dragged onto the same */
            var sourceType:any = false;
            var sourceI = -1; /* local */
            var targetType:any = false;
            var targetI = -1; /* local */
            for (var i = 0; i < self.rowConditions.length; i++) {
                if (self.rowConditions[i] == sourceCI) {
                    sourceType = self.rowConditions;
                    sourceI = i;
                }
                if (self.rowConditions[i] == targetCI) {
                    targetType = self.rowConditions;
                    targetI = i;
                }
            }
            for (var i = 0; i < self.colConditions.length; i++) {
                if (self.colConditions[i] == sourceCI) {
                    sourceType = self.colConditions;
                    sourceI = i;
                }
                if (self.colConditions[i] == targetCI) {
                    targetType = self.colConditions;
                    targetI = i;
                }
            }
            if (targetCI == -1) {
                /* no cols - lets create one */
                self.colConditions.push(sourceCI);
                self.rowConditions.splice(sourceI, 1);
                self.buildDataSource();
                return;
            }
            if (targetCI == -2) {
                /* no rows - lets create one */
                self.rowConditions.push(sourceCI);
                self.colConditions.splice(sourceI, 1);
                self.buildDataSource();
                return;
            }
            if (sourceType == targetType) {
                /* same condition type */
                if (sourceI + 1 == targetI) {
                    /* dragged on condition immediately after */
                    targetType.splice(targetI + 1, 0, sourceCI);
                    targetType.splice(sourceI, 1);
                } else {
                    targetType.splice(sourceI, 1);
                    targetType.splice(targetI, 0, sourceCI);
                }
            } else {
                /* different condition type */
                sourceType.splice(sourceI, 1);
                targetType.splice(targetI, 0, sourceCI);
            }
            self.buildDataSource();
        };
    };

    getClickReference = (cond, index, type) => {
        var self = this;
        var tempCond = cond;

        var runRef = function () {
            var subtotalItemSelected = <HTMLInputElement>document.getElementById('pivot_checkbox_subtotals');
            tempCond.subtotals = (subtotalItemSelected.checked ? true : false);

            tempCond.blackList = [];
            for (var i = 0; i < tempCond.distinctValues.length; ++i) {
                var blackListedItemSelected = <HTMLInputElement>document.getElementById('pivot_distinct_' + i);
                if (!blackListedItemSelected.checked) {
                    tempCond.blackList.push(tempCond.distinctValues[i]);
                }
            }

            var orderItemSelected = <HTMLInputElement>document.getElementById('pivot_order_asc');
            tempCond.sort = (orderItemSelected.checked ? 1 : -1);
            self.sort(tempCond);

            cond = tempCond;
            self.$j(self.propPage).hide();
            self.buildDataSource();
        };

        var windowSizeFunc = function (elem) {
            // Get document width or height
            if (elem.nodeType === 9) {
                var doc = elem.documentElement;

                return [Math.max(elem.body["scrollWidth"], doc["scrollWidth"], elem.body["offsetWidth"], doc["offsetWidth"], doc["clientWidth"]),
                Math.max(elem.body["scrollHeight"], doc["scrollHeight"], elem.body["offsetHeight"], doc["offsetHeight"], doc["clientHeight"])];
            }
            return [0, 0];
        };

        return function (event) {
            self.$j(self.propPage).empty();
            /* contents */
            var close = self.$j('<div style="position: absolute;top: 3px;right: 10px;cursor: pointer"><span>X</span></div>')[0];
            self.$j(close).click(function () {
                self.$j(self.propPage).hide();
            });

            var sendLeft = self.$j('<button class="btn btn-circle"><i class="fas fa-arrow-left"></i></button>');
            var refLeft = self.actionLeft(index, type);
            self.$j(sendLeft).click(refLeft);

            var sendUp = self.$j('<button class="btn btn-circle"><i class="fas fa-arrow-up"></i></button>');
            var refUp = self.actionUp(index, type);
            self.$j(sendUp).click(refUp);

            var actLabel = self.$j('<label>Ações:</label>')[0];

            var divNavigate = self.$j('<div style="display: flex;"></div>');
            self.$j(divNavigate).append([actLabel, sendLeft, sendUp,  ]);

            var asc = self.$j('<input type="radio" id="pivot_order_asc" name="order" />')[0];
            var alabel = self.$j('<label for="pivot_order_asc">Ascendente</label>')[0];
            var desc = self.$j('<input type="radio" id="pivot_order_desc" name="order" />')[0];
            var dlabel = self.$j('<label for="pivot_order_desc">Descendente</label>')[0];
            var divOrder = self.$j('<div style="display: flex;"></div>');
            self.$j(divOrder).append([asc, alabel, self.$j('<br/>')[0], desc, dlabel])


            var hr1 = self.$j('<hr style="width: 95%; margin: 5px"/>')[0];
            var hr2 = self.$j('<hr style="width: 95%; margin: 5px"/>')[0];

            var subtotals = self.$j('<div></div>')[0];
            var sch = self.$j( '<input type="checkbox" id="pivot_checkbox_subtotals" class="checkbox-style" />' )[0];
            sch.checked = (tempCond.subtotals ? true : false); /*(cond.subtotals ? true : false);*/
            sch.__checked = (sch.checked ? "1" : "0");

            var sl = self.$j('<label for="pivot_checkbox_subtotals">Adicionar Subtotais</label>')[0];
            self.$j(subtotals).append([sch, sl]);

            var distinct = self.$j('<div></div>')[0];

            var runFilter = self.$j('<input class="btn btn-primary" type="button" value="Aplicar" />')[0];
            self.$j(runFilter).click(runRef);

            self.$j(self.propPage).append([close, divNavigate, self.$j('<hr style="width: 95%; margin: 5px"/>')[0], divOrder, hr1, subtotals, hr2, distinct, runFilter]);

            self.distinctDivs( /*cond*/ tempCond, distinct);
            var coords = CubePreConfig.localizate_event(event);
            var windowSize = windowSizeFunc(document);
            var diff = 0;
            if (windowSize[0] < coords[0] + 100) {
                self.propPage.style.visibility = "hidden";
                self.propPage.style["display"] = "block";
                diff = -1 * self.propPage.offsetWidth;
                self.propPage.style["display"] = "none";
                self.propPage.style.visibility = "";
            }
            self.propPage.style.left = (coords[0] + diff) + "px";
            self.propPage.style.top = coords[1] + "px";

            self.$j(self.propPage).show();

            /* this needs to be here because of IE :/ */
            asc.checked = tempCond.sort == 1; /*cond.sort == 1;*/
            asc.__checked = asc.checked;
            desc.checked = tempCond.sort == -1; /*cond.sort == -1;*/
            desc.__checked = desc.checked;
        };
    };

    getDelFilterReference = (index, type) => {
        var self = this;
        return function () {
            var idx = self.filterIndexes.pivotCustomFind(index);
            self.filterIndexes.splice(idx, 1);
            if(type == 'col')
                self.colConditions.push(index);
            else
                self.rowConditions.push(index);
            self.buildDataSource();
        };
    };

    distinctDivs = (cond, div) => {
        var self = this;
        /* valores distintos por checkbox*/
        var tempCond = cond;
        var getPair = function (text, id) {
            var div = self.$j('<div></div>')[0];
            var ch = self.$j( String.prototype.format('<input type="checkbox" id="{0}" class="checkbox-style" />', id) )[0];
            var t = self.$j( String.prototype.format('<label for="{0}">{1}</label>', id, tempCond.decodedValues[text]) )[0];
            div.appendChild(ch);
            div.appendChild(t);
            return [div, ch];
        };

        var allRef = function () {
            for (var i = 0; i < tempCond.distinctValues.length; ++i) {
                var blackListedItemSelected = <HTMLInputElement>document.getElementById('pivot_distinct_' + i);
                blackListedItemSelected.checked = true;
            }
        };

        var noneRef = function () {
            for (var i = 0; i < tempCond.distinctValues.length; ++i) {
                var blackListedItemSelected = <HTMLInputElement>document.getElementById('pivot_distinct_' + i);
                blackListedItemSelected.checked = false;
            }
        };

        var reverseRef = function () {
            for (var i = 0; i < tempCond.distinctValues.length; ++i) {
                var blackListedItemSelected = <HTMLInputElement>document.getElementById('pivot_distinct_' + i);
                blackListedItemSelected.checked = !blackListedItemSelected.checked;
            }
        };

        self.$j(div).empty();
        var d = self.$j('<div class="buttonBoxDiv"></div>')[0];
        var checkBoxArea = self.$j('<div class="mt-3 mb-3 border" style="overflow: auto;max-height: 130px;"></div>')[0];
        var height = 0;

        var all = self.$j('<input class="btn btn-secondary" type="button" value="Todos" />')[0];
        self.$j(all).click(allRef);

        var none = self.$j('<input class="btn btn-secondary" type="button" value="Nenhum" />')[0];
        self.$j(none).click(noneRef);

        var reverse = self.$j('<input class="btn btn-secondary" type="button" value="Inverter" />')[0];
        self.$j(reverse).click(reverseRef);

        self.$j(d).append([all, none, reverse]);
        self.$j(div).append(d);
        for (var i = 0; i < tempCond.distinctValues.length /*cond.distinctValues.length*/ ; i++) {
            var value = tempCond.distinctValues[i]; /*cond.distinctValues[i];*/
            var pair = getPair(value, "pivot_distinct_" + i);
            checkBoxArea.appendChild(pair[0]);
            pair[1].checked = (tempCond.blackList.pivotCustomFind(value) == -1); /*(cond.blackList.pivotCustomFind(value) == -1);*/
            pair[1].__checked = (pair[1].checked ? "1" : "0");
        }
        div.appendChild(checkBoxArea);
        if (tempCond.distinctValues.length > 20) {
            checkBoxArea.style["height"] = "400px";
        }
    };

    drawFilters = () => {
        var self = this;
        if (!self.options.staticpivottable) {
            var savedValues = [];

            var div = self.filterDiv;
            self.$j(div).empty();
            self.drag.addTarget(div);
            div.selects = [];

            for (var i = 0; i < self.filterIndexes.length; i++) {
                var index = self.filterIndexes[i];
                var filterBtn = self.$j(`<div class="input-group col-sm-4">
                        <div class="input-group-prepend">
                            <button class="btn btn-info rowFilter" type="button">L</button>
                        </div>
                        <span type="text" placeholder="" aria-label="" aria-describedby="basic-addon1" class="input-group-text">{0}</span>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-success btn-secondary colFilter">C</button>
                        </div>
                    </div>`.replace('{0}', self.headerRow[index]));
                var refRow = self.getDelFilterReference(index, 'row');
                var refCol = self.getDelFilterReference(index, 'col');
                self.$j(filterBtn).find('.rowFilter').click(refRow);
                self.$j(filterBtn).find('.colFilter').click(refCol);

                self.$j(self.filterDiv).append(filterBtn);
            }
        }
    };

    setMetricConf = () => {
        var self = this;
        var showed = [], formats = [];
        for (var i = 0; i < self.metricCollection.length; ++i) {
            if( self.metricFormatters.length > i && self.metricFormatters[i] ) {
                formats.push( self.metricFormatters[i] );
                self.metricCollection[i].numberConfigInstance = self.metricFormatters[i];
            }
            else
                formats.push( self.metricCollection[i].numberConfigInstance )
            if (self.metricCollection[i].show)
                showed.push(i);
        }
        self.metricShowedLabel = showed;
        self.metricFormatters = formats;

        var association = [];
        for (var n = 0; n < self.metricCollection.length; ++n) {
            for (var j = 0; j < self.metricCollection[n].association.length; ++j) {
                var name = self.metricCollection[n].association[j];
                if (!association[name]) {
                    for (var k = self.metricCollection.length - 1; k >= 0; --k)
                        if (name == self.metricCollection[k].key)
                            association[name] = k;
                }
            }
            self.progressLoader("Associando métrica " + n + " de " + self.metricCollection.length);
        }

        var aIndex = [];
        for (var j = 0; j < self.metricCollection.length; ++j) {
            aIndex[self.metricCollection[j].key] = [];
            for (var k = 0; k < self.metricCollection[j].association.length; ++k)
                aIndex[self.metricCollection[j].key].push(association[self.metricCollection[j].association[k]]);
        }
        self.mapAssociationIndex = aIndex;
    };

    countTotals = () => {
        var self = this;
        /* totals */
        self.rowTotals = [];
        self.colTotals = [];
        self.gTotal = [];

        for (var n = 0; n < self.filteredData.length; n++) {
            self.colTotals[n] = [];
            for (var i = 0; i < self.w; i++) {
                self.colTotals[n].push([]);
            }
        }

        for (var n = 0; n < self.filteredData.length; n++) {
            self.rowTotals[n] = [];
            for (var i = 0; i < self.h; i++) {
                self.rowTotals[n].push([]);
            }
        }

        for (var n = 0; n < self.filteredData.length; n++) {
            self.gTotal[n] = [];
        }

        for (var n = 0; n < self.filteredData.length; n++) {
            for (var i = 0; i < self.w; i++) {
                for (var j = 0; j < self.h; j++) {
                    var val = self.tabularData[n][i][j];
                    if (self.metricCollection[n].totalWithRawData == true)
                        val = self.rawTabularData[n][i][j];
                    else if (self.metricCollection[n].totalWithRawData == "string")
                        val = self.rawTabularData[n][i][j].toString();
                    else if (self.metricCollection[n].totalWithRawData == "integer")
                        val = parseInt(self.rawTabularData[n][i][j].toString());
                    else if (self.metricCollection[n].totalWithRawData == "float")
                        val = parseFloat(self.rawTabularData[n][i][j].toString().replace(/,/g, '.').replace(/%/g, '').replace(/ /g, ''));
                    self.colTotals[n][i].push(val);
                    self.rowTotals[n][j].push(val);
                    self.gTotal[n].push(val);
                }
            }
        }

        self.rawColTotals = self.extend(true, [], self.colTotals);
        self.rawRowTotals = self.extend(true, [], self.rowTotals);
        self.rawGTotal = self.extend(true, [], self.gTotal);

        var func; /* statistics */
        for (var n = 0; n < self.filteredData.length; n++) {
            self.progressLoader("Construíndo estruturas para agregação dos totais: " + (n + 1) + " de " + self.filteredData.length, null, 70);

            var indexM = self.findDinamycMetricByName(self.metricCollection[n].name);
            var dependencies = self.mapAssociationIndex[self.metricCollection[n].key];
            func = (indexM == -1 ? self.getAggregationFunc(true, n) : self.metricCollection[n].formula);

            for (var i = 0; i < self.rowTotals[n].length; i++)
                self.rowTotals[n][i] = func(self.rowTotals[n][i], (function (array, index) {
                    var values = [];
                    for (var k = 0; k < array.length; ++k)
                        values.push(self.rowTotals[array[k]][index]);
                    return values;
                })(dependencies, i));


            for (var i = 0; i < self.colTotals[n].length; i++)
                self.colTotals[n][i] = func(self.colTotals[n][i], (function (array, index) {
                    var values = [];
                    for (var k = 0; k < array.length; ++k)
                        values.push(self.colTotals[array[k]][index]);
                    return values;
                })(dependencies, i));

            self.gTotal[n] = func(self.gTotal[n], (function (array) {
                var values = [];
                for (var k = 0; k < array.length; ++k)
                    values.push(self.gTotal[array[k]]);
                return values;
            })(dependencies));
        }
    };

    countSubTotals = () => {
        var self = this;
        /* sub-totals */
        function clean(ptrArray, count) { //Método uilizado para constuir um vetor totals dentro do objeto, inicialmente vázio
            for (var i = 0; i < ptrArray.length - 1; i++) { //Deve ter pelo menos
                var stack = ptrArray[i];
                for (var j = 0; j < stack.length; j++) {
                    stack[j].totals = [];
                    for (var k = 0; k < count; k++) {
                        stack[j].totals.push([]);
                    }
                }
            }
        }

        function addTotal(arr, arrIndex, totalIndex, value) { //Adiciona um valor ao  totals do respectivo elemento indicado
            if (!arr.length) {
                return;
            }
            var item = arr[arr.length - 1][arrIndex].parent;
            while (item.parent) {
                item.totals[totalIndex].push(value);
                item = item.parent;
            }
        }

        function apply(ptrArray, func) { //Aplica se existir uma função do aggTotals ao respectivo vetor
            for (var i = 0; i < ptrArray.length - 1; i++) {
                var stack = ptrArray[i];
                for (var j = 0; j < stack.length; j++) {
                    var totals = stack[j].totals;
                    for (var k = 0; k < totals.length; k++) {
                        totals[k] = {
                            array: totals[k] /*, value: func(totals[k]) */
                        };
                    }
                }
            }
        }

        clean(self.colPointers, self.h);
        clean(self.rowPointers, self.w);

        for (var n = 0; n < self.filteredData.length; n++) { //Percorre o vetor tridimensional tabularData adicionando os valores ao subtotals de cada linha
            for (var i = 0; i < self.w; i++) {
                for (var j = 0; j < self.h; j++) {
                    var val = self.tabularData[n][i][j];
                    if (self.metricCollection[n].totalWithRawData == true)
                        val = self.rawTabularData[n][i][j];
                    else if (self.metricCollection[n].totalWithRawData == "string")
                        val = self.rawTabularData[n][i][j].toString();
                    else if (self.metricCollection[n].totalWithRawData == "integer")
                        val = parseInt(self.rawTabularData[n][i][j].toString());
                    else if (self.metricCollection[n].totalWithRawData == "float")
                        val = parseFloat(self.rawTabularData[n][i][j].toString().replace(/,/g, '.').replace(/%/g, '').replace(/ /g, ''));
                    addTotal(self.colPointers, i, j, val);
                    addTotal(self.rowPointers, j, i, val);
                }
            }
        }

        var func = CubeMathLib[CubeMathLib.list[self.options.aggTotals].func]; /* statistics */
        apply(self.colPointers, func);
        apply(self.rowPointers, func);
    };

    countPointers = () => {
        var self = this;
        /* create arrays of pointers to levels of agg structures */
        function count(struct, arr, propName) {
            self[propName] = [];
            var stack = [struct];
            for (var i = 0; i < arr.length; i++) {
                var newstack = [];
                for (var j = 0; j < stack.length; j++) {
                    var item = stack[j];
                    for (var k = 0; k < item.items.length; k++) {
                        newstack.push(item.items[k]);
                    }
                }
                stack = newstack;
                self[propName].push(stack.copy());
            }
        }

        count(self.rowStructure, self.rowConditions, "rowPointers");
        count(self.colStructure, self.colConditions, "colPointers");
    };

    countOffsets = () => {
        var self = this;
        /* starting offsets for aggregate structures */
        function count(ptrArray) {
            for (var i = 0; i < ptrArray.length; i++) {
                var stack = ptrArray[i];
                var counter = 0;
                for (var j = 0; j < stack.length; j++) {
                    var item = stack[j];
                    item.offset = counter;
                    counter += item.spanData;
                }
            }
        }

        count(self.rowPointers);
        count(self.colPointers);
    };

    hasSpan = () => {
        var self = this;
        //Verifica se a tabela conterá rowspan
        var maxRowSpam = 0;
        for(var r=0; r < self.rowStructure.items.length; ++r)
            maxRowSpam = Math.max(maxRowSpam, self.rowStructure.items[r].span);

        self.options.hasRowSpan = (maxRowSpam < 1000); // Tomando o max span do google chrome de referência
        //Verifica se a tabela conterá colspan
        var maxColSpam = 0;
        for(var c=0; c < self.colStructure.items.length; ++c)
            maxColSpam = Math.max(maxColSpam, self.colStructure.items[c].span);

        self.options.hasColSpan = (maxColSpam  * self.metricShowedLabel.length < 1000); // Tomando o max span do google chrome de referência
        //self.colStructure.items
    };

    count = () => {
        var self = this;
        /* create tabularData from filteredData */
        /* compute spans = table dimensions */
        function spans(ptr, arr) {
            /* return span for a given aggregate pointer */
            var s = 0;
            var sD = 0;
            if (!ptr.items) {
                ptr.span = 1;
                ptr.spanData = 1;
                return [ptr.span, ptr.spanData];
            }
            for (var i = 0; i < ptr.items.length; i++) {
                var tmp = spans(ptr.items[i], arr);
                s += tmp[0];
                sD += tmp[1];
            }
            ptr.span = s;
            ptr.spanData = sD;
            if (ptr.items.length && ptr.items[0].items) {
                //var cond = self.conditions[arr[ptr.items[0].depth]];
                var cond = self.conditions[arr[self.dimensionDepth[ptr.items[0].dimension]]];
                if (cond.subtotals) {
                    ptr.span += ptr.items.length;
                }
            }
            return [ptr.span, ptr.spanData];
        }

        self.progressLoader("Mesclando linhas", null, 33);
        spans(self.rowStructure, self.rowConditions);
        self.progressLoader("Mesclando colunas", null, 35);
        spans(self.colStructure, self.colConditions);

        self.countPointers();
        self.countOffsets();
        self.hasSpan();

        /* create blank table */
        self.progressLoader("Definindo dimensões da tabela", null, 37);

        self.tabularData = [];
        self.w = 1;
        self.h = 1;
        if (self.colConditions.length) {
            self.w = self.colPointers[self.colPointers.length - 1].length;
        } //Seta a largura da tabela
        if (self.rowConditions.length) {
            self.h = self.rowPointers[self.rowPointers.length - 1].length;
        } //Seta a altura da tabela

        self.progressLoader("Criando matriz tridimensional de dados", null, 39);

        for (var n = 0; n < self.filteredData.length; n++) { //Bloco de código  utilizado para construir um vetor tridimensional vazio tabularData
            self.tabularData[n] = [];
            for (var i = 0; i < self.w; i++) {
                var col = new Array(self.h);
                for (var j = 0; j < self.h; j++) {
                    col[j] = [];
                }
                self.tabularData[n].push(col);
            }
        }

        //TODO Transformar esse for em multithread e refatorar métodos subsequentes
        for (var b = 0; b < self.filteredData.length; b++) { //Bloco que seta os valores  do tabular data
            if (self.filteredData[b].length > 0)
            	self.builTabularDataLine(b);
        }

    };

    builTabularDataLine = (position) => {
        var self = this;
        function coords(struct, arr, row) {
            var pos = 0;
            var ptr = struct;
            for (var i = 0; i < arr.length; i++) {
                var rindex = arr[i];
                var cond = self.conditions[arr[i]];
                var value = row[rindex];
                var o = false;
                /*for (var j = 0; j < ptr.items.length; j++) {
                if (ptr.items[j].value != value) {
                pos += ptr.items[j].spanData;
                } else {
                o = ptr.items[j];
                break;
                }
                }*/
                var index = self.findConditionItem(ptr.items, value, cond.sort, cond.dimensionNumberOrder);
                o = ptr.items[index];
                pos = ptr.items[index].offset;
                if (!o) {
                    alert("Value not found in distinct?!?!? PANIC!!!");
                }
                ptr = o;
            } /* for all conditions */
            return pos;
        }


        var array = self.filteredData[position];
        var work = new CubeThread(array);

        work.action = function (item, index, total) {
            self.progressLoader("Tridimensionalizando dados: " + index + " de " + total);

            var row = array[index];
            var x = coords(self.colStructure, self.colConditions, row); //Seta a coordenada X do elemento
            var y = coords(self.rowStructure, self.rowConditions, row); //Seta a coordenada Y do elemento

            var val = row[self.headerRow.length - 1];
            val = ( val != null ? val.toString() : "" );
            if (val.indexOf('[') == -1) {
                val = val.replace(/,/g, '.');
                val = val.replace(/%/g, '');
                val = val.replace(/ /g, '');
                val = parseFloat(val);
            }
            else
                val = eval(val)

            //if (isNaN(val)) { val = 0; }
            self.tabularData[position][x][y].push(val);
        };

        work.finish = function (thread) {
            --self.threadCount;
        };

        work.start();
        ++self.threadCount;
    };

    convertToFloat = () => {
        var self = this;
        var func; /* statistics */
        for (var n = 0; n < self.filteredData.length; n++) { //Transforma todos os elementos do tabularData em Floats
            var dependencies = self.mapAssociationIndex[self.metricCollection[n].key];
            self.progressLoader("Agregando valores para métrica: " + (n + 1) + " de " + self.filteredData.length);

            var indexM = self.findDinamycMetricByName(self.metricCollection[n].name);
            func = (indexM == -1 ? self.getAggregationFunc(false, n) : self.metricCollection[n].formula);
            for (var i = 0; i < self.w; i++) {
                for (var j = 0; j < self.h; j++) {
                    var result = parseFloat(func(self.tabularData[n][i][j], (function (array, line, column) {
                        var values = [];
                        for (var k = 0; k < array.length; ++k)
                            values.push(self.tabularData[array[k]][line][column]);
                        return values;
                    })(dependencies, i, j)));
                    self.tabularData[n][i][j] = result;
                }
            }
        }
    };

    isSubtotals = () => {
        var self = this;
        self.options.subtotals = 0; //Valor do subtotals por defaulf será  (false)
        for (var i = 0; i < self.conditions.length; i++) { //Percorre o vetor de conditions do self, basta que um possua um subtotals  para que o  options.subtotals seja true
            var cond = self.conditions[i];
            if (cond.subtotals) {
                self.options.subtotals = true;
            }
        }
        if (self.options.subtotals) {
            self.countSubTotals();
        } //Se o options.subtotals for true chama o método countSubtotals
        if (self.options.totals) {
            self.countTotals();
        }
    };

    actionLeft = (index, type) => {
        var self = this;
        return function (event) {
            if (type == 'row' && self.rowConditions.length > 0 && index != 0) {
                var temp = self.rowConditions[index];
                self.rowConditions[index] = self.rowConditions[index - 1];
                self.rowConditions[index - 1] = temp;
                self.buildDataSource();
            } else if (type == 'col') {
                self.rowConditions[self.rowConditions.length] = self.colConditions[index];
                self.colConditions.splice(index, 1);
                self.buildDataSource();
            }
            self.$j(self.propPage).hide();
        };
    };

    actionUp = (index, type) => {
        var self = this;
        return function (event) {
            if (type == 'col' && self.colConditions.length > 0 && index != 0) {
                var temp = self.colConditions[index];
                self.colConditions[index] = self.colConditions[index - 1];
                self.colConditions[index - 1] = temp;
                self.buildDataSource();
            } else if (type == 'row') {
                self.colConditions[self.colConditions.length] = self.rowConditions[index];
                self.rowConditions.splice(index, 1);
                self.buildDataSource();
            }
            self.$j(self.propPage).hide();
        };
    };

    //#region Dynamic Metrics

    //Cria objetos de controle das métricas dinâmicas
    configDynamicMetrics = (dynListParam?) => {
        var self = this;
        function makeDinamycFunc(str) {
            var logBase = function (val) {
                return Math.log(val) / Math.LN10;
            };
            return function (foo, variables) {
                var result:any = 0;
                try {
                    result = eval(str);
                } catch (e) {
                    result = "";
                }
                return result;
            };
        };

        var deriveFunc = function (dinList) {
            var derivedFormula = [];
            for (var p = 0; p < dinList.length; ++p) {
                var str = dinList[p].formula;
                var inst;

                do {
                    inst = false;
                    for (var d = 0; d < dinList.length; ++d) {
                        if (d != p && str.indexOf("{" + dinList[d].name + "}") != -1) {
                            str = str.replace(new RegExp("\{" + dinList[d].name + "\}", 'g'), "\( " + dinList[d].formula + " \)");
                            inst = true;
                        }
                    }
                } while (inst);
                derivedFormula.push(str);
            }
            return derivedFormula;
        };

        var dinList;
        self.clearDinamycMetricList();
        dinList = dynListParam || self.getDinamycMetricList();

        var derivedFormulaList = deriveFunc(dinList);
        for (var p = 0; p < dinList.length; ++p) {
            var dFormula = derivedFormulaList[p];
            var str = self.caretReplace(dFormula.replace(/×/g, '*')).replace(/yroot\(/g, '^(');
            str = self.caretReplace(str, true);
            str = str.replace(/sin/g, 'Math.sin').replace(/cos/g, 'Math.cos').replace(/tan/g, 'Math.tan').replace(/mod/g, '%')
                .replace(/π/g, 'Math.PI').replace(/℮/g, 'Math.E').replace(/log/g, 'logBase').replace(/ln/g, 'Math.log');

            var dependencies = [];
            for (var i = 0; i < self.metricCollection.length; ++i) {
                var mName = self.metricCollection[i].name.replace(/\(/g, "\\(").replace(/\)/g, "\\)").replace(/\//g, "\\\/");
                if (self.metricCollection[i].show && str.indexOf("{" + self.metricCollection[i].name + "}") != -1) {
                    str = str.replace(new RegExp("{" + mName + "}", 'g'), "variables[" + dependencies.length + "]");
                    dependencies.push(self.metricCollection[i].key);
                }
            }

            var m = new CubeMetric(dinList[p].name, dinList[p].name, dependencies, makeDinamycFunc(str), dinList[p].show, false, 0, [], 0, []);
            m.metricIndex = self.headerRow.length + self.metricCollection.length - 1;
            m.numberConfigInstance = dinList[p].numberConfigInstance;

            self.metricDinamycMap.push({
                index: m.metricIndex,
                name: dinList[p].name,
                show: dinList[p].show,
                formula: dinList[p].formula,
                numberConfigInstance: dinList[p].numberConfigInstance
            });
            self.metricCollection.push(m);
        }
    };

    //Click do botão de configurações
    callMetricPanel = () => {
        var self = this;
        return function (event) {

            var type = self.$j('<label for="numberFormatSelect">Formatação numérica: </label>')[0];
            //self.propPage.appendChild(type);
            var select = self.$j("<select id='numberFormatSelect' ></select>")[0];
            for (var p in CubePreConfig.cubeDataFormat) {
                var t = CubePreConfig.cubeDataFormat[p];
                var selected = (self.options.type == t[0] ? 'selected' : '');
                var opt = self.$j(String.prototype.format('<option value="{1}" {2}>{0}</option>', t[1], t[0], selected));
                self.$j(select).append(opt);
            }
            self.$j(select).change(function () {
                self.options.type = parseInt(self.$j(select).val());
                //refresh();
            });

            var mCol = [];
            for (var i = 0; i < self.metricCollection.length; ++i)
                if (self.metricCollection[i].show && !self.metricCollection[i].formula)
                    mCol.push(self.metricCollection[i])


            self.metricTempFormatters = self.metricFormatters.slice();
            self.$j(self.modalPage).find('.modal-title').html('Configurações');
            self.$j(self.modalPage).find('.modal-body').html('');
            self.$j(self.modalPage).find('.modal-body').append(
                self.dinamycMetricBuilder(mCol, self.metricDinamycMap.slice(0))
            );

            self.$j(self.modalPage).find('.modal-footer .backModalBtn').unbind('click');
            self.$j(self.modalPage).find('.modal-footer .backModalBtn').click(function() {
                self.$j('#changeMetricDiv').hide();
                self.$j('#managerDinamycMetric').show();
                self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').hide();
                self.$j(self.modalPage).find('.actionModal, .cancelBtn').show();
            });

            self.$j(self.modalPage).find('.modal-footer .saveConfigBtn').unbind('click');
            self.$j(self.modalPage).find('.modal-footer .saveConfigBtn').click(function() {
                self.saveFormatForm()
                self.$j('#changeMetricDiv').hide();
                self.$j('#managerDinamycMetric').show();
                self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').hide();
                self.$j(self.modalPage).find('.actionModal, .cancelBtn').show();
            });

            self.$j(self.modalPage).find('.modal-footer .actionModal').unbind('click');
            self.$j(self.modalPage).find('.modal-footer .actionModal').click(function() {
                self.metricFormatters = self.metricTempFormatters.slice();
                self.configDynamicMetrics()
                self.startPivot();
                self.$j('#pivotModal').modal('hide');
                self.$j('body').removeClass('modal-open');
                self.$j('.modal-backdrop').remove();
            });
            self.$j(self.modalPage).modal('show');

            self.$j(self.modalPage).find('[data-dismiss="modal"]').forEach(function(e) {
                self.$j(e).unbind('click');
                self.$j(e).click(function() {
                    self.$j(self.modalPage).modal('hide');
                });
            });

        };
    };

    //Constrói a visualização das configurações
    dinamycMetricBuilder = (collection, dinMetric) => {
        var self = this;
        var tableDiv = self.buildMetricCRUD(dinMetric);
        var createDiv = self.buildMetricCreateDiv(collection);
        var changeDiv = self.buildMetricChangeDiv()

        self.$j("#dinamycMetricContentDiv").remove();
        var resultDiv = self.$j('<div id="dinamycMetricContentDiv" name="dinamycMetricContentDiv" class="dinamycMetricContentDiv"></div>')[0];
        self.$j(resultDiv).append([tableDiv, createDiv, changeDiv]);
        return resultDiv;
    };

    buildMetricCRUD = (dinMetric) => {
        var self = this;
        var managerPanelDiv = self.$j('<div id="managerDinamycMetric" style="width:100%;vertical-align:top;margin-right:5px;display:inline-block" ></div>')[0];
        var wrapperHeader = self.$j('<div id="managerDinamycMetricHeader"></div>')[0];

        var sendBulletin = self.$j(`<div class="float-left">
            <input type="checkbox" val="isBulletin" id="isBulletin" name="isBulletin" class="checkbox-style" />
            <label for="isBulletin" >Worflow</label>
        </div>`)[0];

        var addButton = self.$j('<button class="btn btn-primary float-right"><i class="fas fa-plus"></i>Adicionar</button>')[0];
        var addButtonFunc = function () {
            self.fillDinamycMetricDiv();
            self.$j('#managerDinamycMetric').hide();
            self.$j('#createMetricDiv').show();
            (<HTMLInputElement>document.getElementById("keyPad_UsernameText")).value = '';
            (<HTMLInputElement>document.getElementById("keyPad_UserInput")).value = '';
            document.getElementById("pivotErrorDiv").innerHTML = '';

            self.$j(self.modalPage).find('.actionModal, .cancelBtn, .backModalBtn, .saveConfigBtn').hide();

            /*var divs = document.getElementById('userMetricDiv').childNodes;
            for (var i = 0; i < divs.length; ++i)
                divs[i].className = '';*/

            self.$j('#userMetricDiv tbody tr').forEach(function(e){
                self.$j(e).removeClass('changeableMetric');
            });

        };
        self.$j(addButton).click(addButtonFunc);
        wrapperHeader.appendChild(sendBulletin);
        wrapperHeader.appendChild(addButton);

        var messagesManagerDiv = self.$j('<div></div>')[0];
        messagesManagerDiv.id = 'messagesManagerDiv';
        wrapperHeader.appendChild(messagesManagerDiv);

        managerPanelDiv.appendChild(wrapperHeader);

        var tableMetric = self.$j('<table id="userMetricDiv" class="table table-bordered table-striped" ></table>')[0];
        var structure = self.$j(String.prototype.format(`
            <thead><tr>{0}</tr></thead>
            <tbody></tbody>`, ['Ativo', 'Nome', 'Ações'].map(function(e){ return "<td>" + e + "</td>"}).join('') ) );
        self.$j(tableMetric).html(structure);
        self.buildMetricTable(dinMetric, tableMetric);
        managerPanelDiv.appendChild(tableMetric);

        return managerPanelDiv;
    };

    buildMetricCreateDiv = (collection) => {
        var self = this;
        var createMetricDiv = self.$j('<div id="createMetricDiv" style="width:100%;display:none"></div>')[0];
        var keyPadDiv = self.buildMetricCalculator(collection);

        self.$j(createMetricDiv).append(keyPadDiv);

        return createMetricDiv;
    };

    buildMetricChangeDiv = () => {
        var self = this;
        function addColorLine(isInit) {
            var row = self.$j(`<div class="row lineInterval">
                <div class="col-sm-4 d-flex ineqSel">
                    <label class="input-group-text">Valor</label>
                    <select>
                        <option value="lessthan" >Menor que</option>
                        <option value="greatherthanorequal" >Maior ou igual</option>
                        <option value="between" >Entre</option>
                    </select>
                </div>
                <div class="col-sm-2 startInterval">
                    <input class="form-control" type="number" value="0" />
                </div>
                <div class="col-sm-2 endInterval">
                    <input class="form-control" type="number" value="0" />
                </div>
                <div class="col-sm-2 colorpicker">
                    <input class="form-control" type="text" value="red" />
                </div>
                <div class="col-sm-2 actIntervals">
                    <button class="addRow btn btn-circle btn-success">+</button>
                    <button class="removeRow btn btn-circle btn-danger">-</button>
                </div>
            </div>`);
            self.$j(row).find('.addRow').click(function() {
                var i = addColorLine(false);
                self.$j('#generalConfPivot').append(i);
            });
            self.$j(row).find('.removeRow').click(function() {
                self.$j(this).parent().parent().remove();
            });
            self.$j(row).find('.ineqSel select').change(function() {
                if(self.$j(this).val() == 'between')
                    self.$j(row).find('.endInterval, .actIntervals').show();
                else {
                    self.$j('.removeRow').forEach(function(e){ self.$j(e).trigger('click'); });
                    self.$j(row).find('.endInterval').val('');
                    self.$j(row).find('.endInterval, .actIntervals').hide();
                }
            })

            if(isInit) {
                self.$j(row).find('.removeRow').remove();
            }
            else {
                self.$j(row).find('option[value="lessthan"]').remove();
                self.$j(row).find('option[value="greatherthanorequal"]').remove();
                self.$j(row).find('.ineqSel select').trigger('change');
            }

            return row;
        };

        var changeMetricDiv = self.$j('<div id="changeMetricDiv" style="width:100%;display:none"></div>')[0];
        var title = self.$j('<h4 id="titleChangeDiv" name="titleChangeDiv" class="text-center"></h4>');

        var formConf = self.$j(`
            <div id="generalConfPivot">
            <br/>
            <h4>Configurações da Métrica</h4>
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-2">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="prefix">Prefixo</label>
                        <input type="text" id="prefix" name="prefix" class="form-control" value="" maxlength="5" />
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-2">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="sufix">Sufixo</label>
                        <input type="text" id="sufix" name="sufix" class="form-control" value="" maxlength="5" />
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-2">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3 mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="numDecimal">Casas Decimais</label>
                        <select id="numDecimal" name="numDecimal">
                            <option value="0" >0</option>
                            <option value="1" >1</option>
                            <option value="2" selected>2</option>
                            <option value="3" >3</option>
                            <option value="4" >4</option>
                            <option value="5" >5</option>
                            <option value="6" >6</option>
                            <option value="7" >7</option>
                            <option value="8" >8</option>
                            <option value="9" >9</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3 mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="sepDecimal">Separador Decimal</label>
                        <select id="sepDecimal" name="sepDecimal">
                            <option value="." >Ponto</option>
                            <option value="," >Virgula</option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3 mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="sepThousand">Separador de Milhar</label>
                        <select id="sepThousand" name="sepThousand">
                            <option value=" " >    </option>
                            <option value="." >Ponto</option>
                            <option value="," >Virgula</option>
                        </select>
                    </div>
                </div>
            </div>
            </div>`);
        var firstLine = addColorLine(true);
        self.$j(formConf).append(firstLine);

        var totalFilterRow = self.$j(`<div id="totalFilterRowDiv">
            <br/>
            <h4>Filtro de Totais</h4>
            <div class="row filterRow">
                <div class="col-sm-4 d-flex ineqSel">
                    <label class="input-group-text">Valor</label>
                    <select>
                        <option value="lessthan" >Menor que</option>
                        <option value="greatherthanorequal" >Maior ou igual</option>
                    </select>
                </div>
                <div class="col-sm-4 filterval">
                    <input class="form-control" type="number" value="" />
                </div>
            </div>
            </div>`);

        self.$j(changeMetricDiv).append([title, formConf, totalFilterRow]);

        return changeMetricDiv;
    };

    buildMetricTable = (dinMetric, tableMetric) => {
        var self = this;
        var totalShowed = 0;
        var tbody = self.$j(tableMetric).find('tbody');

        //Estáticas
        for (var i = 0; i < self.metricCollection.length; ++i) {
            if (self.metricCollection[i].show && !self.metricCollection[i].formula) {
                var confButtonND = self.$j('<button formatterIndex="' + i + '" class="btn btn-info" ><i class="fas fa-cogs"></i>Configurações</button>')[0];
                self.$j(confButtonND).click(function () {
                    self.$j('#titleChangeDiv').html(self.metricCollection[i].name);
                    self.$j('#managerDinamycMetric').hide();
                    self.$j('#changeMetricDiv').show();
                    self.fillFormatForm(parseInt( self.$j(this).attr('formatterIndex') ));

                    self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').show();
                    self.$j(self.modalPage).find('.actionModal, .cancelBtn').hide();
                });

                var tr = self.$j('<tr></tr>').append([
                    self.$j('<td></td>'),
                    self.$j('<td></td>').append( document.createTextNode( self.metricCollection[i].name ) ),
                    self.$j('<td></td>').append( confButtonND )
                ]);
                self.$j(tbody).append(tr);
                totalShowed++;
            }
        }

        //Dinâmicas
        for (var i = 0; i < dinMetric.length; ++i) {
            var currTR = self.$j('<tr class="pivot_dmetric"></tr>')[0];

            var ch = self.$j(String.prototype.format('<input type="checkbox" class="pivot_dmetric checkbox-style" id="pivot_dmetric_{0}" mname="{1}" formula="{2}" formatterIndex="{3}" />',
                i, dinMetric[i].name, dinMetric[i].formula,  (i + totalShowed) ))[0];
            ch.checked = dinMetric[i].show;
            ch.__checked = (ch.checked ? "1" : "0");

            var t = self.$j(String.prototype.format('<label for="{0}" >{1}</label>', "pivot_dmetric_" + i, dinMetric[i].name) )[0];

            var confButtonD = self.$j('<button formatterIndex="' + (i + totalShowed) + '" class="btn btn-info" ><i class="fas fa-cogs"></i>Configurações</button>')[0];
            self.$j(confButtonD).click(function () {
                self.$j('#titleChangeDiv').html('dinMetric[i].name');
                self.$j('#managerDinamycMetric').hide();
                self.$j('#changeMetricDiv').show();
                self.fillFormatForm(parseInt( self.$j(this).attr('formatterIndex') ));

                self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').show();
                self.$j(self.modalPage).find('.actionModal, .cancelBtn').hide();
            });

            var changeButton = self.$j('<button id="pivot_updatebutton_' + i + '" class="btn btn-info" ><i class="fas fa-edit"></i>Alterar</button>')[0];
            self.$j(changeButton).click(function () {
                var dinList = self.getDinamycMetricList();
                var pos = this.id.substring("pivot_updatebutton_".length, this.id.length);
                (<HTMLInputElement>document.getElementById("keyPad_UsernameText")).value = dinList[pos].name;
                (<HTMLInputElement>document.getElementById("keyPad_UserInput")).value = dinList[pos].formula;
                document.getElementById("pivotErrorDiv").innerHTML = '';
                self.$j('#managerDinamycMetric').hide();
                self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn, .actionModal, .cancelBtn').hide();

                self.fillDinamycMetricDiv(dinList[pos]);

                self.$j('#createMetricDiv').show();

                // var divs = this.parentNode.parentNode.childNodes;
                // for (var i = 0; i < divs.length; ++i)
                //     divs[i].className = '';
                // self.$j(this.parentNode).className = 'changeableMetric';
                var divs = self.$j('#userMetricDiv tbody tr.pivot_dmetric');
                for (var i = 0; i < divs.length; ++i)
                    self.$j(divs[i]).removeClass('changeableMetric');
                self.$j(this).parent().parent().addClass('changeableMetric');
            });

            var delButton = self.$j('<button id="pivot_delbutton_' + i + '" formatterIndex="' + (i + totalShowed) + '" class="btn btn-danger" ><i class="fas fa-trash-alt"></i>Excluir</button>')[0];
            var delButtonFunc = function () {
                var dinList = self.getDinamycMetricList();
                var id = this.id.substring("pivot_delbutton_".length, this.id.length);
                var forbidden = false;
                for (var i = 0; i < dinList.length; ++i)
                    if (dinList[i].formula.indexOf("{" + dinList[id].name + "}") != -1)
                        forbidden = true;

                if (forbidden) {
                    document.getElementById('messagesManagerDiv').innerHTML = 'A métrica não pode ser removida, pois outras métricas possuem dependência desta.';
                    setTimeout(function () {
                        document.getElementById('messagesManagerDiv').innerHTML = '';
                    }, 5000);
                }
                else {
                    dinList.splice(id, 1);
                    self.metricTempFormatters.splice( parseInt( self.$j(this).attr('formatterIndex') ), 1 )

                    //var newPanel = self.$j('<div id="userMetricDiv" style="overflow: auto; border: 1px solid;height:162px;max-height: 270px"></div>')[0];
                    var newPanel = self.$j('#userMetricDiv');
                    self.$j(newPanel).find('tbody').empty();
                    self.buildMetricTable(dinList, newPanel);
                    //tableMetric.parentNode.replaceChild(newPanel, tableMetric);

                    document.getElementById('changeMetricDiv').style.display = 'none';
                    self.$j('#managerDinamycMetric').show();
                }
            }
            self.$j(delButton).click(delButtonFunc);

            self.$j(currTR).append([
                self.$j('<td></td>').append(ch) ,
                self.$j('<td></td>').append(t),
                self.$j('<td></td>').append([confButtonD, changeButton, delButton])
            ]);
            self.$j(tbody).append(currTR);
        }
    };

    buildMetricCalculator = (collection) => {
        var self = this;
        var buttons = ['7', '8', '9', '+', '-', '×', '/', //Primeira Linha
            '4', '5', '6', '(', ')', 'log', 'ln', //Segunda Linhas
            '1', '2', '3', 'x<sup>y</sup>', '<sup>y</sup>&#8730', '&#8494', '&#8494<sup>x</sup>', //Terceira Linha
            '0', '.', 'mod', 'sin', 'cos', 'tan', 'π'
        ]; //Quarta Linha

        var keyPadDiv = self.$j('<div id="keyPad" style="width:100%"></div>')[0];
        var lnome = self.$j('<label for="keyPad_UsernameText" style="text-align:right">Nome </label>')[0];
        keyPadDiv.appendChild(lnome);

        var nameText = self.$j('<input type="text" id="keyPad_UsernameText" onpaste="return false" style="width:77.5%" />')[0];
        var vAlphaNumber = function (event) {
            var key = ('charCode' in event) ? event.charCode : event.keyCode;
            var result = !(/[^A-Za-z0-9 ]/.test(String.fromCharCode(key)));
            if (!result) {
                event.preventDefault();
                event.stopPropagation();
            }
            return result;
        };
        if (nameText.addEventListener)
            nameText.addEventListener("keypress", vAlphaNumber, false);
        else if (nameText.attachEvent)
            nameText.attachEvent("onkeypress", vAlphaNumber);
        keyPadDiv.appendChild(nameText);

        var uInputDiv = self.$j('<div style="width:92%;display:block"></div>')[0];
        var lfx = self.$j('<label for="keyPad_UserInput" style="text-align:right">Fun(x)</label>')[0];
        uInputDiv.appendChild(lfx);

        var inputText = self.$j('<input type="text" id="keyPad_UserInput" readonly="readonly" style="width:84%" />')[0];
        uInputDiv.appendChild(inputText);

        var errorBox = self.$j('<div id="pivotErrorDiv"></div>')[0];
        uInputDiv.appendChild(errorBox);

        keyPadDiv.appendChild(uInputDiv);

        for (var i = 0; i < buttons.length; ++i) {
            var currButton = self.$j('<button class="keyPad_btnNormal"  >' + buttons[i] + '</buttons>')[0];
            self.$j(currButton).click(function () {
                if (this.innerHTML == 'log' || this.innerHTML == 'ln' || this.innerHTML == 'sin' || this.innerHTML == 'cos' || this.innerHTML == 'tan' || this.innerHTML == 'mod')
                    inputText.value += this.innerHTML + "(";
                else if (this.innerHTML == 'x<sup>y</sup>') {
                    inputText.value += "^(";
                } else if (this.innerHTML == '℮<sup>x</sup>') {
                    inputText.value += "℮^(";
                } else if (this.innerHTML == '<sup>y</sup>√') {
                    inputText.value += "yroot(";
                } else {
                    inputText.value += this.innerHTML;
                }
                inputText.focus();
            });
            keyPadDiv.appendChild(currButton);
        }

        var metricDiv = self.$j('<div></div>')[0];
        metricDiv.id = 'metricCalculatorDiv';
        for (var i = 0; i < collection.length; ++i) {
            var currButton = self.$j('<button class="keyPad_btnMetric" >' + collection[i].name + '</button>')[0];
            self.$j(currButton).click(function () {
                inputText.value += "{" + this.innerHTML + "}";
            });
            metricDiv.appendChild(currButton);
        }
        var mDinCalculatorDiv = self.$j('<div id="metricDinamycCalculatorDiv"></div>')[0];
        metricDiv.appendChild(mDinCalculatorDiv);
        keyPadDiv.appendChild(metricDiv);

        var backButton = self.$j('<button class="keyPad_btnBottom" >&#8678</button>')[0];
        self.$j(backButton).click(function () {
            var l = 1;
            var str = inputText.value;
            if (str.endsWith('yroot(')) {
                l = 6;
            } else if (str.endsWith('log(') || str.endsWith('sin(') || str.endsWith('cos(') || str.endsWith('tan(') || str.endsWith('mod(')) {
                l = 4;
            } else if (str.endsWith('ln(')) {
                l = 3;
            } else if (str.endsWith('^(')) {
                l = 2;
            } else if (/^.*\{.*\}$/g.test(str)) {
                l = str.substring(str.lastIndexOf('{'), str.length).length;
            }
            inputText.value = inputText.value.substring(0, inputText.value.length - l);
            inputText.focus();
        });
        keyPadDiv.appendChild(backButton);

        var clearButton = self.$j('<button class="keyPad_btnBottom" >C</button>')[0];
        self.$j(clearButton).click(function () {
            inputText.value = "";
            inputText.focus();
        });
        keyPadDiv.appendChild(clearButton);

        var cancelButton = self.$j('<button class="keyPad_btnBottom" >Cancelar</button>')[0];
        self.$j(cancelButton).click(function () {
            self.$j('#createMetricDiv').hide();
            self.$j('#managerDinamycMetric').show();
            self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').hide();
            self.$j(self.modalPage).find('.actionModal, .cancelBtn').show();
            var divs = this.parentNode.parentNode.childNodes;
            for (var i = 0; i < divs.length; ++i)
                divs[i].className = '';
        });
        keyPadDiv.appendChild(cancelButton);

        var enterButton = self.$j('<button class="keyPad_btnEnter" >OK</button>')[0];
        self.$j(enterButton).click(function () {
            var oldElement;
            var dinCollection = self.getDinamycMetricList();
            var isUpdate = false;
            var divs = self.$j('#userMetricDiv tbody tr.pivot_dmetric');
            var pos = -1;
            for (var i = 0; i < divs.length; ++i) {
                if (self.$j(divs[i]).hasClass('changeableMetric')) {
                    isUpdate = true;
                    pos = i;
                    oldElement = dinCollection.splice(i, 1);
                    for (var j = 0; j < dinCollection.length; ++j)
                        if (dinCollection[j].formula.indexOf("{" + oldElement[0].name + "}") != -1) {
                            var rExp = new RegExp("\{" + oldElement[0].name + "\}", 'g');
                            dinCollection[j].formula = dinCollection[j].formula.replace(rExp, "{" + nameText.value + "}");
                        }
                    break;
                }
            }

            var dependencies = [];
            var mCompleteList = dinCollection.concat(collection);
            for (var i = 0; i < mCompleteList.length; ++i) {
                var mName = mCompleteList[i].name.replace(/\(/g, "\\(").replace(/\)/g, "\\)").replace(/\//g, "\\\/");
                if (inputText.value.indexOf("{" + mCompleteList[i].name + "}") != -1) {
                    dependencies.push(mCompleteList[i].name);
                }
            }

            if (!self.validateDinamycMetric(nameText.value, dependencies, inputText.value, dinCollection, errorBox))
                return;


            if (isUpdate)
                dinCollection.splice(pos, 0, {
                    name: nameText.value,
                    show: true,
                    formula: inputText.value,
                    numberConfigInstance: oldElement[0].numberConfigInstance
                });
            else {
                var defaultFormatter = new NumberConfig({});
                dinCollection.push({
                    name: nameText.value,
                    show: true,
                    formula: inputText.value,
                    numberConfigInstance: defaultFormatter
                });
                self.metricTempFormatters.push(defaultFormatter)
            }

            var panel = document.getElementById("userMetricDiv");
            self.$j(panel).find('tbody').empty();
            self.buildMetricTable(dinCollection, panel);

            self.$j('#createMetricDiv').hide();
            self.$j('#managerDinamycMetric').show();
            self.$j(self.modalPage).find('.backModalBtn, .saveConfigBtn').hide();
            self.$j(self.modalPage).find('.actionModal, .cancelBtn').show();
        });
        keyPadDiv.appendChild(enterButton);

        return keyPadDiv;
    };

    fillFormatForm = (index) => {
        var self = this;
        var conf = self.metricTempFormatters[index].getConf();
        self.$j('#prefix').val(conf.prefix);
        self.$j('#sufix').val(conf.sufix);
        self.$j('#sepDecimal').val(conf.decimalSymbol);
        self.$j('#sepThousand').val(conf.groupingSymbol);
        self.$j('#numDecimal').val(conf.decimalPlaces);

        //Intervalos
        self.$j('.removeRow').forEach(function(e){ self.$j(e).trigger('click'); });
        self.$j('.startInterval input, .endInterval input').val('');
        self.$j('.lineInterval div.ineqSel select').val('lessthan');
        self.$j('.lineInterval div.ineqSel select').trigger('change');
        for(var i = 0; i < conf.intervals.length; ++i) {
            var lineRef = self.$j('.lineInterval')[i];
            var confRef = conf.intervals[i];
            self.$j(lineRef).find('div.ineqSel select').val(confRef[0]);
            self.$j(lineRef).find('div.ineqSel select').trigger('change');
            self.$j(lineRef).find('div.startInterval input').val(confRef[1]);
            if(confRef[0] == 'between') {
                self.$j(lineRef).find('div.endInterval input').val(confRef[2]);
                if(conf.intervals.length > i + 1)
                    self.$j(lineRef).find('.addRow').trigger('click');
            }
            self.$j(lineRef).find('div.colorpicker input').val(confRef[confRef.length - 1]);
        }

        if(conf.limitTotal) {
            self.$j('.filterRow .ineqSel select').val(conf.limitTotal[0])
            self.$j('.filterRow .filterval input').val(conf.limitTotal[1]);
        }
        self.$j('#generalConfPivot').attr('formatterIndex', index);
    };

    saveFormatForm = () => {
        var self = this;
        var index = parseInt( self.$j('#generalConfPivot').attr('formatterIndex') );

        var intervals = self.$j('.lineInterval').map(function(e){
            try {
                var selVal = self.$j(e).find('div.ineqSel select').val();
                var startInterval = self.$j(e).find('div.startInterval input').val();
                var endInterval = self.$j(e).find('div.endInterval input').val();
                var colorpicker = self.$j(e).find('div.colorpicker input').val();

                var returnVal;
                if( selVal == 'between' )
                    if( parseFloat(startInterval) < parseFloat(endInterval) )
                        returnVal = [ selVal, parseFloat(startInterval), parseFloat(endInterval), colorpicker ];
                    else
                        throw "invalid interval";
                else
                    returnVal = [selVal, parseFloat(startInterval), colorpicker ];

                return returnVal;
            }
            catch (e) {
                return null;
            }
        }).filter(x => x != null);

        var limitTotal = null;
        if(!isNaN(parseFloat( self.$j('.filterRow .filterval input').val() )))
            limitTotal = [ self.$j('.filterRow .ineqSel select').val(), parseFloat( self.$j('.filterRow .filterval input').val() )];

        self.metricTempFormatters[index] = new NumberConfig({
            prefix: self.$j('#prefix').val(),
            sufix: self.$j('#sufix').val(),
            decimalSymbol: self.$j('#sepDecimal').val(),
            groupingSymbol: self.$j('#sepThousand').val(),
            decimalPlaces: parseInt(self.$j('#numDecimal').val()),
            intervals: intervals,
            limitTotal: limitTotal
        });

    };

    fillDinamycMetricDiv = (exclude?) => {
        var self = this;
        var div = document.getElementById('metricDinamycCalculatorDiv');
        div.innerHTML = '';
        var collection = self.getDinamycMetricList();

        for (var i = 0; i < collection.length; ++i) {
            if (exclude && exclude.name == collection[i].name) {
                continue;
            }
            var currButton = self.$j('<button class="keyPad_btnMetric" >' + collection[i].name + '</button>')[0];
            self.$j(currButton).click(function () {
                (<HTMLInputElement>document.getElementById("keyPad_UserInput")).value += "{" + this.innerHTML + "}";
            });
            div.appendChild(currButton);
        }
    };

    getDinamycMetricList = () => {
        var self = this;
        var dinList = [];
        self.$j("#userMetricDiv tbody tr.pivot_dmetric").forEach(function(e, i){
            var ch = <HTMLInputElement>document.getElementById("pivot_dmetric_" + i);
            dinList.push({
                formula: ch.getAttribute('formula'),
                name: ch.getAttribute('mname'),
                numberConfigInstance: self.metricTempFormatters[parseInt(ch.getAttribute('formatterIndex'))],
                checked: ch.checked,
                show: ch.checked
            });
        });

        return dinList;
    };

    clearDinamycMetricList = () => {
        var self = this;
        //Limpa metricas dinamicas
        var temp = [];
        for (var i = 0; i < self.metricCollection.length; ++i)
            if (!self.metricCollection[i].formula)
                temp.push(self.metricCollection[i]);
        self.metricCollection = temp;
        self.metricDinamycMap = [];
    };

    validateDinamycMetric = (name, dependencies, exp, dinMetric, errorBox) => {
        var self = this;
        var messages = [];
        if (name == "" || name.replace(/ /g, "") == "")
            messages.push("A métrica deve possuir um nome.");
        else {
            for (var i = 0; i < self.metricCollection.length; ++i) {
                if (!self.metricCollection[i].formula && self.metricCollection[i].name == name) {
                    messages.push("Já existe uma métrica com esse nome.");
                    break;
                }
            }
            for (var i = 0; i < dinMetric.length; ++i) {
                if (dinMetric[i].name == name) {
                    messages.push("Já existe uma métrica com esse nome.");
                    break;
                }
            }
        }

        if (exp == "" || exp.replace(/ /g, "") == "") {
            messages.push("A métrica deve possuir um valor para Func(x).");
        }
        else {
            if (dependencies.length == 0)
                messages.push("A métrica deve possuir pelo menos uma variável.");
            else {
                if (!self.checkParentheses(exp))
                    messages.push("A expressão matemática é inválida.");
                else {
                    if (!self.isValidMathEXP(exp))
                        messages.push("A expressão matemática é inválida.");
                }

                if (dinMetric.length > 0) { //Protecao contra referencias circulares
                    var assoc = new Object;
                    for (var i = 0; i < dinMetric.length; ++i)
                        assoc[dinMetric[i].name] = {
                            position: i,
                            fx: dinMetric[i].formula.match(/{[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ.çÇŒœßØøÅåÆæÞþÐð\w\d\s\(\)\-\/]*}/g)
                        };

                    var dinDependeciesMap = new Object;
                    for (var i = 0; i < dependencies.length; ++i) {
                        if (assoc.hasOwnProperty(dependencies[i])) {
                            if (!dinDependeciesMap.hasOwnProperty(dependencies[i]))
                                dinDependeciesMap[dependencies[i]] = i;

                            for (var j = 0; j < assoc[dependencies[i]].fx.length; ++j) {
                                if (!dinDependeciesMap.hasOwnProperty(assoc[dependencies[i]].fx[j].replace('{', '').replace('}', '')))
                                    dependencies.push(assoc[dependencies[i]].fx[j].replace('{', '').replace('}', ''));
                            }
                        } else if (dependencies[i] == name) {
                            messages.push('Existe uma referência circular nessa expressão');
                            break;
                        }
                    }

                }

            }
        }

        if (messages.length > 0) {
            errorBox.innerHTML = "Erro: " + messages.join('<br/>');
            return false;
        }

        errorBox.innerHTML = "";
        return true;
    };

    checkParentheses = (s) => {
        var self = this;
        var nesting = 0;
        for (var i = 0; i < s.length; ++i) {
            var c = s.charAt(i);
            switch (c) {
                case '(':
                    nesting++;
                    break;
                case ')':
                    nesting--;
                    if (nesting < 0) {
                        return false;
                    }
                    break;
            }
        }
        return nesting == 0;
    };

    isValidMathEXP = (exp) => {
        var self = this;
        var evalTestFunc = function (str, array) {
            var logBase = function (val) {
                return Math.log(val) / Math.LN10;
            };
            var values = [-1000000, -100000, -10000, -1000, -100, -10, -1, 0, 1, 10, 100, 1000, 10000, 100000, 1000000];
            var valid = false;
            for (var i = 0; i < values.length; ++i) {
                try {
                    valid = !isNaN(eval(str.replace(/{variableX}/g, '(' + values[i] + ")" )));
                } catch (err) {
                    return false;
                }

                if (valid)
                    break;
            }
            return valid;
        };

        var _s = exp;
        _s = _s.replace(/{[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ.çÇŒœßØøÅåÆæÞþÐð\w\d\s\(\)\-\/]*}/g, '{variableX}');
        _s = self.caretReplace(_s.replace(/×/g, '*')).replace(/yroot\(/g, '^(');
        _s = self.caretReplace(_s, true)
        _s = _s.replace(/sin/g, 'Math.sin').replace(/cos/g, 'Math.cos').replace(/tan/g, 'Math.tan').replace(/mod/g, '%')
            .replace(/π/g, 'Math.PI').replace(/℮/g, 'Math.E').replace(/log/g, 'logBase').replace(/ln/g, 'Math.log');

        var tab = [];
        var joker = "___joker___";
        while (_s.indexOf("(") > -1) {
            _s = _s.replace(/(\([^\(\)]*\))/g, function (m, t) {
                tab.push(t);
                return (joker + "[" + (tab.length - 1) + "]_closure_");
            });
        }

        tab.push(_s);
        _s = joker + (tab.length - 1);
        for (var i = 0; i < tab.length; ++i) {
            var f = tab[i].indexOf(',');
            if (f != -1) {
                if (evalTestFunc(tab[i].substring(0, f).replace('(', '').replace(new RegExp(']_closure_', 'g'), '])').replace(new RegExp(joker, 'g'), '(array'), tab) &&
                    evalTestFunc(tab[i].substring(f + 1, tab[i].length).replace(')', '').replace(new RegExp(']_closure_', 'g'), '])').replace(new RegExp(joker, 'g'), '(array'), tab)) {
                    tab[i] = "10";
                } else
                    return false;
            } else {
                var valid = evalTestFunc(tab[i].replace(new RegExp(']_closure_', 'g'), '])').replace(new RegExp(joker, 'g'), '(array').replace(/Math.pow/g, 'Math.log'), tab);
                if (valid) {
                    tab[i] = "10";
                } else {
                    return false;
                }
            }
        }
        return true;
    };

    caretReplace = (_s, invert?) => {
        var self = this;
    	while(_s.indexOf("^") != - 1 ) {
    		var refPos = _s.indexOf("^(");
	    	var curPos = refPos + 1
	    	var opendBracket = 1
	        while (opendBracket != 0 || curPos == _s.length - 1) {
				if( _s[++curPos] == "(" )
					opendBracket++
				else if(_s[curPos] == ")")
					opendBracket--;
			}
	        curPos++;

	        var beforePos = refPos - 1;
	        if (beforePos > 0)
	            if ( _s[beforePos] == "}" )
					beforePos = _s.substring(0, beforePos).lastIndexOf("{");
	            else if ( ["π, ℮"].indexOf(_s[beforePos]) != -1 )
					beforePos = beforePos;
	        else if ( _s[beforePos] == ")" ) {
			    opendBracket = 1;
				while ( opendBracket != 0 || beforePos < 0 ) {
					 if( _s[--beforePos] == "(" )
						opendBracket--;
					 else if( _s[beforePos] == ")" )
						opendBracket++;
				}

			    var listFuncs = ["cos", "sin", "tan", "ln", "log"];
	            for (var i = 0; i < listFuncs.length; ++i) {
	                var r = beforePos - listFuncs[i].length;
	                if (_s.substring(r, beforePos) == listFuncs[i]) {
	                    beforePos = r;
	                    break
	                }
	            }
			}

            //TODO Revisar a lógica de construção do operador de radiciação
	        _s = String.prototype.format(false ? "{0}Math.pow({1},1/({2})){3}" : "{0}Math.pow({1},{2}){3}",
	        	_s.substring(0, beforePos),
	        	_s.substring(beforePos, refPos),
	        	_s.substring(refPos + 1, curPos),
	        	_s.substring(curPos))
	    }

	    return _s;
    };
    //#endregion Dynamic Metrics

    //#region Draw components

    drawTable = () => {
        var self = this;
        self.subTotals = (self.rankedOrder == -1 ? new Array() : self.subTotals);
        self.$j(self.div).empty();
        var table_id = "pivotIDGenerated" + (Math.floor(1e20 * Math.random()) + 1);
        var table = self.$j('<table class="pivot_table" ></table>')[0];
        var thead = self.$j('<thead></thead>')[0];
        var tbody = self.$j('<tbody id="' + table_id + '" class="clusterize-content"></tbody>')[0];
        var tfoot = self.$j('<tfoot></tfoot>')[0];
        var lines = []

        //upper part
        var upperArray = self.colConditions;
        var upperWork = new CubeThread(upperArray);

        // main part
        var array = new Array(self.h);
        var work = new CubeThread(array);

        upperWork.action = function (uperItem, index, total) {
            //upper part
            self.progressLoader("Criando colunas do cabeçalho: " + index + " de " + total);

            var tr = self.$j('<tr></tr>')[0];
            if (index == 0 && self.rowConditions.length) { //Verifica  se estamos tratando da primeira coluna da primeira linha (Titulo do cubo)
                var th = self.$j('<td></td>')[0];
                self._drawCorner(th);
                th.colSpan = self.rowConditions.length;
                th.rowSpan = self.colConditions.length;
                tr.appendChild(th);
            }
            if (self.options.headingBefore)  //column headings before
                self._drawColConditionsHeadings(tr, index);

            var stack = self.colPointers[index];
            for (var j = 0; j < stack.length; j++) { //column values
                self.progressLoader("Criando colunas do cabeçalho: " + index + " de " + total + "... Carregando");

                var item = stack[j];
                var th = self.$j('<td class="h2"></td>')[0];
                if (self.options.formatterCells)
                    th.innerHTML = self.options.formatterCells(item);
                else
                    th.innerHTML = item.value;

                if (!self.conditions[self.colConditions[index]].decodedValues[item.value])
                    self.conditions[self.colConditions[index]].decodedValues[item.value] = th.textContent || th.innerText;

                if(self.options.hasColSpan) {
                    th.colSpan = item.span * self.metricShowedLabel.length;
                    tr.appendChild(th);
                }
                else
                    for(var cs = 0; cs < item.span * self.metricShowedLabel.length; ++cs)
                        tr.appendChild(self.$j(th).clone()[0]);


                //var cond = self.conditions[self.colConditions[item.depth]];
                var cond = self.conditions[self.colConditions[self.dimensionDepth[item.dimension]]];
                if (cond.subtotals && index + 1 < self.colConditions.length) { //subtotal columns
                    self.progressLoader("Criando colunas do cabeçalho: " + index + " de " + total + "... Criando coluna de subtotais");

                    var th = self.$j('<td class="h2" ></td>')[0];
                    if (self.options.formatterCells)
                        th.innerHTML = "Total para " + self.options.formatterCells(item);
                    else
                        th.innerHTML = "Total para " + item.value;

                    th.rowSpan = self.colConditions.length - index;
                    th.colSpan = self.metricShowedLabel.length;
                    tr.appendChild(th);
                }
            }

            if (self.options.totals && index == 0) {
                var th = self.$j('<td class="h2" ></td>')[0];
                th.innerHTML = "TOTAL";
                th.colSpan = self.metricShowedLabel.length;
                th.rowSpan = self.colConditions.length;
                tr.appendChild(th);
            }
            if (self.options.headingAfter) //column headings after
                self._drawColConditionsHeadings(tr, index);

            self.$j(tr).addClass("upperpart");
            thead.appendChild(tr);
        };

        upperWork.finish = function (thread) {
            if (self.rowConditions.length && self.options.headingBefore)
                self._drawRowConditionsHeadings(thead, true);


            //Inicializa a construçao da parte central  do grafico
            if (self.h > 0)
                work.start();
            else
                self.drawTotalsRow(thead, tbody, tfoot, table);
        };

        work.action = function (item, index, total) {
            self.progressLoader("Criando linhas da tabela: " + (index + 1) + " de " + total, null, 95);

            var tr = self.$j('<tr></tr>')[0];
            var rankedIndex = ( self.rankedOrder != -1 ? self.rankedOrder[index].position : index );

            //Utilizado para construir um array ptrArray contendo objetos o, desde o no folha ate o inicio da arvore de condiçoes para a linha
            if (self.rowConditions.length) {
                var item = self.rowPointers[self.rowConditions.length - 1][rankedIndex]; /* stack has number of values equal to height of table */
                var ptrArray = [];
                var ptr = item;
                while (ptr.parent) {
                    ptrArray.unshift(ptr); //Adiciona elementos no inicio do array
                    ptr = ptr.parent;
                }
            }

            //Laço utilizado para construir a parte esquerda da linha, correspondente as dimensoes daquela linha. O vetor ptrArray sera acessado da esquerda para direita
            for (var j = 0; j < self.rowConditions.length; j++) { //row header values   Inicio For 56FHYHGVHJ
                var item = ptrArray[j];
                if ( item.offset == rankedIndex || !self.options.hasRowSpan ) {
                    var th = self.$j('<td class="h2" ></td>')[0];
                    if (self.options.formatterCells)
                        th.innerHTML = self.options.formatterCells(item);
                    else
                        th.innerHTML = item.value;

                    if (!self.conditions[self.rowConditions[j]].decodedValues[item.value])
                        self.conditions[self.rowConditions[j]].decodedValues[item.value] = th.textContent || th.innerText;

                    if(self.options.hasRowSpan)
                        th.rowSpan = ptrArray[j].span;
                    tr.appendChild(th);
                }
            }

            //Usado para desenhar um  espaço em branco entre as celulas das dimensoes e dos valores
            if (self.colConditions.length /*&& index == 0*/ && self.options.headingBefore) {
                var th = self.$j('<td></td>')[0];
                if (!self.rowConditions.length) {
                    self._drawCorner(th, true);
                    th.conditionIndex = -2;
                }
                else
                    th.style.border = "none";

                //th.rowSpan = self.rowStructure.span;
                tr.appendChild(th);
            }

            var subTotalsCount = -1;
            //Laço utilizado para construir a parte central, onde se encontrarao os dados
            for (var j = 0; j < self.w; j++) { //Inicio ForLinhasCentrais
                //Laço interno, para cada valor da dimensao uma coluna com o valor para metrica sera desenhada
                for (var n = 0; n < self.metricShowedLabel.length; n++) { // Inicio ForMetricas
                    var result = self.tabularData[self.metricShowedLabel[n]][j][rankedIndex];
                    var contentFormatted = (!isNaN(result) ? self.formatValue(result, self.metricShowedLabel[n]) : self.options.emptyCell);
                    var td = self.$j('<td class="' + self.getClassName(rankedIndex, j) + '" >' + contentFormatted + '</td>')[0];
                    tr.appendChild(td);

                    // column subtotals
                    if (self.options.subtotals && self.colPointers.length) {
                        //Obtem o objeto "o", referente a coluna(pega a ultima dimensao na arvore de colunas ) para aquela linha
                        var item = self.colPointers[self.colPointers.length - 1][j].parent;

                        while (item.parent) { //Inicio while268189
                            var cond = self.conditions[self.colConditions[self.dimensionDepth[item.dimension]]];
                            if (item.spanData - 1 == j - item.offset && (n == self.metricShowedLabel.length - 1) && cond.subtotals) {
                                self.progressLoader("Criando linhas da tabela: " + index + " de " + total + " Calculando subtotal");

                                var func;
                                var aggSubTotal = [];
                                for (var r = 0; r < self.metricShowedLabel.length; r++) {
                                    var td = self.$j('<td class="subtotal"></td>')[0];
                                    var tmp = [];
                                    var dependArr = [];

                                    var resultSubTotal;
                                    var indexM = self.findDinamycMetricByName(self.metricCollection[self.metricShowedLabel[r]].name);
                                    var dependencies = self.mapAssociationIndex[self.metricCollection[self.metricShowedLabel[r]].key];
                                    if (indexM != -1) {
                                        func = self.metricCollection[self.metricShowedLabel[r]].formula;

                                        var variables = []
                                        for (var p = 0; p < dependencies.length; ++p)
                                            variables.push(aggSubTotal[dependencies[p]]);
                                        resultSubTotal = func(0, variables);
                                    }
                                    else {
                                        func = self.getAggregationFunc(true, r);
                                        var stepIndex = item.totals[rankedIndex].array.length / self.filteredData.length;
                                        var carrossel = 0;
                                        for (var y = r * stepIndex; y < (r + 1) * stepIndex; ++y) {
                                            tmp.append(item.totals[rankedIndex].array[y]);
                                            dependArr.append( dependencies.map(p => item.totals[rankedIndex].array[p * stepIndex + carrossel++] ) );
                                        }

                                        resultSubTotal = func(tmp, dependArr);
                                    }

                                    aggSubTotal.push(resultSubTotal);
                                    td.innerHTML = !isNaN(resultSubTotal) ? self.formatValue(resultSubTotal, self.metricShowedLabel[n]) : self.options.emptyCell;
                                    tr.appendChild(td);
                                    if (self.rankedOrder == -1) {
                                        if (!self.subTotals[++subTotalsCount])
                                            self.subTotals[subTotalsCount] = [];
                                        self.subTotals[subTotalsCount].push(resultSubTotal);
                                    }
                                    //Controle de memória
                                    tmp = null; //Para IE
                                    //delete tmp;
                                }
                            }
                            item = item.parent;
                        }
                    }
                }
            }

            //Cria as celulas de totais referentes a aquela linha
            if (self.options.totals && self.colConditions.length) { //totals
                if (self.rowConditions.length)
                    for (var n = 0; n < self.metricShowedLabel.length; n++) {
                        var resultTotal = self.rowTotals[self.metricShowedLabel[n]][rankedIndex];
                        var contentFormatted = (!isNaN(resultTotal) ? self.formatValue(resultTotal, self.metricShowedLabel[n]) : self.options.emptyCell);
                        var td = self.$j('<td class="total">' + contentFormatted + '</td>')[0];
                        tr.appendChild(td);
                    }
                else
                    self._drawGTotal(tr);
            }

            //Cria espaço em branco logo apos as colunas de total para aquela linha
            if (self.colConditions.length /*&& index == 0*/ && self.options.headingAfter) {
                /* blank space after */
                var th = self.$j('<td></td>')[0]
                if (!self.rowConditions.length) {
                    self._drawCorner(th, true);
                    th.conditionIndex = -2;
                }
                else
                    th.style.border = "none";

                //th.rowSpan = self.rowStructure.span + (self.options.totals && self.rowConditions.length ? 1 : 0);
                tr.appendChild(th);
            }
            lines.push(tr.outerHTML);

            //Cria uma linha inteira para computar subtotais de uma dimensao da linha
            for (var j = self.rowConditions.length - 2; j >= 0; j--) {
                var item = ptrArray[j];

                var cond = self.conditions[self.rowConditions[self.dimensionDepth[item.dimension]]]; //Obtem a condiçao referente aquela dimensao da linha
                if (cond.subtotals && item.offset + item.spanData - 1 == index) {
                    var tr = self.$j('<tr></tr>')[0];
                    if(!self.options.hasRowSpan && j != 0) {
                        var thSpan = self.$j('<td class="h2" ></td>')[0];
                        thSpan.colSpan = j;
                        tr.appendChild(thSpan);
                    }
                    var th = self.$j('<td class="h2" ></td>')[0];
                    th.colSpan = self.rowConditions.length - j;
                    if (self.options.formatterCells)
                        th.innerHTML = "Total para " + self.options.formatterCells(item);
                    else
                        th.innerHTML = "Total para " + item.value;

                    tr.appendChild(th);
                    self._drawRowSubtotals(tr, rankedIndex, item);
                    lines.push(tr.outerHTML);
                }
            }
        };

        work.finish = function (thread) {
            self.progressLoader("Criando linha para os totais");

            self.drawTotalsRow(thead, tbody, tfoot, table);
            if(self.options.hasRowSpan)
            	self.$j(tbody).html(lines.join("\n"));
            else
            	self.clusterManager = CubeCluster.createCluster({
                rows: lines,
                scrollId: self.$j(self.div).attr("id"),
                contentId: table_id
              });

            self.rankedOrder = -1;
        };

        ++self.threadTableCount;
        upperWork.start();

        //return 1;
    };

    asyncDrawTable = () => {
        var self = this;
        self.drawTable();

        var drawTableThread = setInterval(function () {
            self.progressLoader(null, null, 95);

            if (self.threadTableCount == 0) {
                clearInterval(drawTableThread);
                self.progressLoader(null, 97);
                self.chartPanel();
                self.drawGraphConfig();
                self.progressLoader("Concluído", 100);

                self.$j(self.loading_div).hide();
                self.$j(self.config_div).show();
                self.progressLoader("", 0);

                if (self.options.callbackFunction)
                    self.options.callbackFunction();
            }
        }, 100);
    };

    drawTotalsRow = (thead, tbody, tfoot, table) => {
        var self = this;
        //totals row
        if (self.options.totals && self.rowConditions.length) {
            var tr = self.$j('<tr></tr>')[0];
            var th = self.$j('<td class="h2" >TOTAL</td>')[0];
            th.colSpan = self.rowConditions.length;
            tr.appendChild(th);
            self._drawRowTotals(tr);
            tfoot.appendChild(tr);
        }

        /* second connector */
        if (self.rowConditions.length && self.options.headingAfter)
            self._drawRowConditionsHeadings(tfoot, false);

        self.drawFilters();
        self.$j(table).append(thead);
        self.$j(table).append(tbody);
        self.$j(table).append(tfoot);
        self.$j(self.div).append(table);

        --self.threadTableCount;
    };

    _drawRowConditionsHeadings = (tbody, type) => {
        var self = this;
        /* rowConditions headings */
        var tr = self.$j('<tr></tr>')[0];
        for (var j = 0; j < self.rowConditions.length; j++) {
            var cond = self.conditions[self.rowConditions[j]];
            var th = self.$j('<td class="h1"></td>')[0];
            var div = self.$j('<div></div>')[0];

            var innerText = self.$j('<div style="display: inline; padding: 0 0 0 5px">' + self.headerRow[self.rowConditions[j]] + '</div>')[0];
            var ref = self.getClickReference(cond, j, 'row');
            self.$j(innerText).click(ref);

            if (self.options.staticpivottable) {
                div.appendChild(innerText);
            } else {
                div.appendChild(innerText);
                var callback = self.getOrderReference(self.rowConditions[j]);
                self.drag.addSource(div, self.process, callback);
                self.drag.addTarget(th);
            }

            th.conditionIndex = self.rowConditions[j];
            self.$j(th).append([div]);
            self.$j(tr).append([th]);
        }

        if (type) {
            var metricCellConfig = [];
            var defaultCount = -1,
                subtotalCount = -1,
                totalsCount = -1;
            for (var i = 0; i < self.w; ++i) {
                for (var n = 0; n < self.metricShowedLabel.length; ++n) {
                    metricCellConfig.push({
                        position: metricCellConfig.length,
                        type: "default",
                        typeCount: ++defaultCount
                    });

                    // column subtotals
                    if (self.options.subtotals && self.colPointers.length) {
                        var item = self.colPointers[self.colPointers.length - 1][i].parent;

                        while (item.parent) {
                            //var cond = self.conditions[self.colConditions[item.depth]];
                            var cond = self.conditions[self.colConditions[self.dimensionDepth[item.dimension]]];
                            if (item.spanData - 1 == i - item.offset && (n == self.metricShowedLabel.length - 1) && cond.subtotals) {
                                for (var p = 0; p < self.metricShowedLabel.length; ++p)
                                    metricCellConfig.push({
                                        position: metricCellConfig.length,
                                        type: "subtotals",
                                        typeCount: ++subtotalCount
                                    });
                            }
                            item = item.parent;
                        }
                    } // if subtotals
                }
            }

            if (self.options.totals && self.colConditions.length) { //totals
                if (self.rowConditions.length) {
                    for (var p = 0; p < self.metricShowedLabel.length; ++p)
                        metricCellConfig.push({
                            position: metricCellConfig.length,
                            type: "totals",
                            typeCount: ++totalsCount
                        });
                }
            }

            if (metricCellConfig.length > self.metricShowedLabel.length || self.w == 0) {
                var thFiller = self.$j('<td style="border: none"></td>')[0];
                tr.appendChild(thFiller);
            }

            for (var r = 0; r < metricCellConfig.length; r++) {
                var th = self.$j('<td></td>')[0];
                var refRank = self.rankCol(metricCellConfig[r]);

                if (self.rowConditions.length == 1) {
                    th.style.cursor = "pointer";
                    self.$j(th).click(refRank);
                    var stateAsc = "pivot_rank_disable",
                        stateDesc = "pivot_rank_disable";
                    if (self.rankedIndex != -1 && metricCellConfig[r].typeCount == self.rankedIndex.index && self.rankedIndex.order == "desc") {
                        stateDesc = "";
                    }
                    if (self.rankedIndex != -1 && metricCellConfig[r].typeCount == self.rankedIndex.index && self.rankedIndex.order == "asc") {
                        stateAsc = "";
                    }

                    var div = self.$j('<div style="display: inline; padding: 0 6px 0 6px"></div>')[0];
                    var innerText = self.$j('<div style="float: left;width: 100%;padding: 0 4.5px 0 0px">' + self.metricCollection[self.metricShowedLabel[r % self.metricShowedLabel.length]].name + '</div>')[0];
                    div.appendChild(innerText);

                    if (self.rankedIndex != -1 &&
                        self.rankedIndex.type == metricCellConfig[r].type && self.rankedIndex.index == metricCellConfig[r].typeCount) {
                        innerText.style.width = "70%";
                        var span = self.$j(String.prototype.format(`
                            <span style="width: 15%;float: left">
                                <span class="pivot_asc_arrow {0}"></span>
                                <span class="pivot_desc_arrow {1}"></span>
                            </span>`, stateAsc, stateDesc))[0];
                        div.appendChild(span);
                    }
                    th.appendChild(div);

                } else
                    th.innerHTML = self.metricCollection[self.metricShowedLabel[r % self.metricShowedLabel.length]].name;
                tr.appendChild(th);
            }

        }
        else {
            var th = self.$j('<td></td>')[0];
            if (!self.colConditions.length) {
                self._drawCorner(th, true);
                th.conditionIndex = -1;
            } else {
                th.style.border = "none";
            }
            th.colSpan = self.metricShowedLabel.length * (self.colStructure.span + (self.options.headingBefore ? 1 : 0)) + (self.options.totals ? 1 : 0);
            tr.appendChild(th);
        }

        if (self.colConditions.length) {
            /* blank space after */
            var th = self.$j('<td class="" style="border: none"></td>')[0];
            tr.appendChild(th);
        }
        self.$j(tr).addClass(type ? "upperpart" : "bottompart")
        tbody.appendChild(tr);
    };

    _drawColConditionsHeadings = (tr, i) => {
        var self = this;
        var cond = self.conditions[self.colConditions[i]];
        var th = self.$j('<td class="h1"></td>')[0];
        var div = self.$j('<div></div>')[0];

        var innerText = self.$j('<div style="display: inline;padding: 0 0 0 5px">' + self.headerRow[self.colConditions[i]] + '</div>')[0];
        var ref = self.getClickReference(cond, i, 'col');
        self.$j(innerText).click(ref);

        if (self.options.staticpivottable) {
            div.appendChild(innerText);
        } else {
            div.appendChild(innerText);
            var callback = self.getOrderReference(self.colConditions[i]);
            self.drag.addSource(div, self.process, callback);
            self.drag.addTarget(th);
        }

        th.conditionIndex = self.colConditions[i];
        th.appendChild(div);
        tr.appendChild(th);
    };

    _drawGTotal = (tr) => {
        var self = this;
        for (var n = 0; n < self.metricShowedLabel.length; n++) {
            var contentFormatted = (!isNaN(self.gTotal[self.metricShowedLabel[n]]) ? self.formatValue(self.gTotal[self.metricShowedLabel[n]], self.metricShowedLabel[n]) : self.options.emptyCell);
            var td = self.$j('<td class="gtotal" >' + contentFormatted + '</td>')[0];
            tr.appendChild(td);
        }
    };

    _drawRowTotals = (tr) => {
        var self = this;
        if (self.options.headingBefore && self.colConditions.length) {
            var th = self.$j('<td style="border: none"></td>')[0];
            tr.appendChild(th);
        }
        var func; /* statistics */
        if (self.colConditions.length) {
            for (var i = 0; i < self.w; i++)
                for (var n = 0; n < self.metricShowedLabel.length; n++) { // Inicio do For IHGHJKJH
                    var contentCell = !isNaN(self.colTotals[self.metricShowedLabel[n]][i]) ? self.formatValue(self.colTotals[self.metricShowedLabel[n]][i], self.metricShowedLabel[n]) : self.options.emptyCell;
                    var td = self.$j('<td class="total" >' + contentCell + '</td>')[0];
                    tr.appendChild(td);
                    if (!self.colPointers.length) {
                        continue;
                    }
                    var item = self.colPointers[self.colPointers.length - 1][i].parent;

                    while (item.parent) { //Inicio While   GJVBNHGYGF
                        //var cond = self.conditions[self.colConditions[item.depth]];
                        var cond = self.conditions[self.colConditions[self.dimensionDepth[item.dimension]]];
                        if (item.spanData - 1 == i - item.offset && (n == self.metricShowedLabel.length - 1) && cond.subtotals) {

                            var aggSubTotal = [];
                            for (var r = 0; r < self.metricShowedLabel.length; r++) {
                                var td = self.$j('<td class="total" ></td>')[0];
                                var resultRowTotals;
                                var indexM = self.findDinamycMetricByName(self.metricCollection[self.metricShowedLabel[r]].name);
                                var dependencies = self.mapAssociationIndex[self.metricCollection[self.metricShowedLabel[r]].key];
                                if (indexM != -1) {
                                    func = self.metricCollection[self.metricShowedLabel[r]].formula;

                                    var variables = []
                                    for (var p = 0; p < dependencies.length; ++p)
                                        variables.push(aggSubTotal[dependencies[p]]);
                                    resultRowTotals = func(0, variables);
                                } else {
                                    var tmp = [];
                                    var dependArr = [];
                                    for (var l = 0; l < item.totals.length; l++) {
                                        //tmp.append(item.totals[l].array);
                                        var carrossel = 0;
                                        var stepIndex = item.totals[l].array.length / self.filteredData.length;
                                        for (var y = r * stepIndex; y < (r + 1) * stepIndex; ++y) {
                                            tmp.append(item.totals[l].array[y]);
                                            dependArr.append( dependencies.map(p => item.totals[l].array[p * stepIndex + carrossel++] ) );
                                        }
                                    }
                                    func = self.getAggregationFunc(true, r);

                                    resultRowTotals = func(tmp, dependArr);
                                }

                                aggSubTotal.push(resultRowTotals);
                                td.innerHTML = !isNaN(resultRowTotals) ? self.formatValue(resultRowTotals, self.metricShowedLabel[n]) : self.options.emptyCell;
                                tr.appendChild(td);

                                //Controle de memória
                                tmp = null; //Para IE
                                //delete tmp;
                            }
                        }
                        item = item.parent;
                    }
                }
        }
        self._drawGTotal(tr);
        if (self.options.headingAfter && self.colConditions.length) {
            var th = self.$j('<td class="" style="border: none"></td>')[0];
            tr.appendChild(th);
        }
    };

    _drawRowSubtotals = (tr, i, ptr) => {
        var self = this;
        /* subtotals for i-th row */
        tr.setAttribute("lineType", "subtotalsLine")
        //Usado para desenhar um  espaço em branco entre as celulas das dimensoes e dos valores
        if (self.colConditions.length /*&& index == 0*/ && self.options.headingBefore) {
            var th = self.$j('<td ></td>')[0];
            if (!self.rowConditions.length) {
                self._drawCorner(th, true);
                th.conditionIndex = -2;
            } else {
                th.style.border = "none";
            }
            //th.rowSpan = self.rowStructure.span;
            tr.appendChild(th);
        }

        var func; // statistics
        var calculatedSubTotals = [];
        for (var k = 0; k < self.w; k++) {
            calculatedSubTotals.push([]);
            for (var n = 0; n < self.metricShowedLabel.length; ++n) {
                var td = self.$j('<td class="subtotal" ></td>')[0];
                var sum = [];
                var dependArr = [];
                var index = ptr.totals[k].array.length / self.filteredData.length;

                var resultRowSubtotals;
                var indexM = self.findDinamycMetricByName(self.metricCollection[self.metricShowedLabel[n]].name);
                var dependencies = self.mapAssociationIndex[self.metricCollection[self.metricShowedLabel[n]].key];
                if (indexM != -1) {
                    func = self.metricCollection[self.metricShowedLabel[n]].formula;

                    var variables = []
                    for (var p = 0; p < dependencies.length; ++p)
                        variables.push(calculatedSubTotals[k][dependencies[p]]);
                    resultRowSubtotals = func(0, variables);
                } else {
                    func = self.getAggregationFunc(true, n);
                    var carrossel = 0;
                    for (var x = index * n; x < index * (n + 1); ++x) {
                      sum.append(ptr.totals[k].array[x]);
                      dependArr.append( dependencies.map(p => ptr.totals[k].array[p * index + carrossel++] ) );
                    }

                    resultRowSubtotals = func(sum, dependArr);
                }

                calculatedSubTotals[k].push(resultRowSubtotals);

                td.innerHTML = !isNaN(resultRowSubtotals) ? self.formatValue(resultRowSubtotals, self.metricShowedLabel[n]) : self.options.emptyCell;
                tr.appendChild(td);

                //Controle de memória
                sum = null; //Para IE
                //delete sum;

                if (!self.colPointers.length) {
                    continue;
                }
                var item = self.colPointers[self.colPointers.length - 1][k].parent;
                while (item.parent) {
                    //var cond = self.conditions[self.colConditions[item.depth]];
                    var cond = self.conditions[self.colConditions[self.dimensionDepth[item.dimension]]];

                    if (cond.subtotals && item.spanData - 1 == k - item.offset && (n == self.metricShowedLabel.length - 1)) {
                        var aggSubTotal = [];
                        for (var r = 0; r < self.metricShowedLabel.length; r++) {
                            var td = self.$j('<td class="subtotal"></td>')[0];
                            tr.appendChild(td);

                            var resultRowSubtotalsThree;
                            var indexM = self.findDinamycMetricByName(self.metricCollection[self.metricShowedLabel[r]].name);
                            var dependencies = self.mapAssociationIndex[self.metricCollection[self.metricShowedLabel[r]].key];
                            if (indexM != -1) {
                                func = self.metricCollection[self.metricShowedLabel[r]].formula;

                                var variables = []
                                for (var p = 0; p < dependencies.length; ++p)
                                    variables.push(aggSubTotal[dependencies[p]]);
                                resultRowSubtotalsThree = func(0, variables);
                            } else {
                                var tmp = [];
                                var dependArr = [];
                                for (var l = 0; l < ptr.totals.length; l++) {
                                    if (l >= item.offset && l < item.spanData + item.offset) {
                                        //tmp.append(ptr.totals[l].array);
                                        var stepIndex = ptr.totals[l].array.length / self.filteredData.length;
                                        var carrossel = 0;
                                        for (var y = r * stepIndex; y < (r + 1) * stepIndex; ++y) {
                                            tmp.append(ptr.totals[l].array[y]);
                                            dependArr.append( dependencies.map(p => ptr.totals[l].array[p * stepIndex + carrossel++] ) );
                                        }
                                    }
                                } // for all possible totals of this row
                                func = self.getAggregationFunc(true, r);

                                resultRowSubtotalsThree = func(tmp, dependArr);
                            }
                            aggSubTotal.push(resultRowSubtotalsThree);
                            td.innerHTML = !isNaN(resultRowSubtotalsThree) ? self.formatValue(resultRowSubtotalsThree, self.metricShowedLabel[n]) : self.options.emptyCell;

                            //Controle de memória
                            tmp = null; //Para IE
                            //delete tmp;
                        }
                    } // irregular subtotal
                    item = item.parent;
                }
            }
        } // for all regular subtotals

        //Somatorio dos subtotais
        if (self.options.totals && self.colConditions.length) {
            var sumAgg;
            var aggSubTotal = [];
            for (var n = 0; n < self.metricShowedLabel.length; ++n) {
                var resultSumTotals;
                var dependArr = [];
                var indexM = self.findDinamycMetricByName(self.metricCollection[self.metricShowedLabel[n]].name);
                var dependencies = self.mapAssociationIndex[self.metricCollection[self.metricShowedLabel[n]].key];
                if (indexM != -1) {
                    func = self.metricCollection[self.metricShowedLabel[n]].formula;

                    var variables = []
                    for (var p = 0; p < dependencies.length; ++p)
                        variables.push(aggSubTotal[dependencies[p]]);
                    resultSumTotals = func(0, variables);
                } else {
                    sumAgg = [];
                    func = self.getAggregationFunc(true, n);
                    for (var l = 0; l < ptr.totals.length; l++) {
                        var index = ptr.totals[l].array.length / self.filteredData.length;
                        var carrossel = 0;
                        for (var x = index * n; x < index * (n + 1); ++x) {
                            sumAgg.append(ptr.totals[l].array[x]);
                            dependArr.append( dependencies.map(p => ptr.totals[l].array[p * index + carrossel++] ) );
                        }
                    }

                    resultSumTotals = func(sumAgg, dependArr);
                }
                aggSubTotal.push(resultSumTotals);
                var totalCell = ( !isNaN(resultSumTotals) ? self.formatValue(resultSumTotals, self.metricShowedLabel[n]) : self.options.emptyCell );
                var td = self.$j('<td class="total">' + totalCell + '</td>')[0];
                tr.appendChild(td);

                //Controle de memória
                sumAgg = null; //Para IE
            }
            //Controle de memória
            sumAgg = null; //Para IE
            //delete sumAgg;
        }

        //Cria espaço em branco logo apos as colunas de subtotal para aquela linha
        if (self.colConditions.length /*&& index == 0*/ && self.options.headingAfter) {
            /* blank space after */
            var th = self.$j('<td ></td>')[0];
            if (!self.rowConditions.length) {
                self._drawCorner(th, true);
                th.conditionIndex = -2;
            } else {
                th.style.border = "none";
            }
            //th.rowSpan = self.rowStructure.span + (self.options.totals && self.rowConditions.length ? 1 : 0);
            tr.appendChild(th);
        }

    };

    _drawCorner = (th, target?) => {
        var self = this;
        //th.innerHTML = self.headerRow[self.headerRow.length - 1];
        //th.style.cursor = "pointer";
        th.className = "h1";
        if (target) {
            self.drag.addTarget(th);
        }

        var innerText = self.$j('<div style="display: inline; padding: 0 0 0 5px">' + self.headerRow[self.headerRow.length - 1] + '</div>')[0];
        th.appendChild(innerText);
    };

    //#endregion Draw components

    //#region Graph Section
    getLabels = (arr, direction, glue) => {
        var self = this;
        if (!arr) {
            return [];
        }
        var result = [];
        var conditionMap = new Object();
        for (var i = 0; i < arr.length; i++) {
            var item = arr[i];
            var ptr = item;
            var value = [];
            while (ptr && ptr.parent) {

                if (!conditionMap[ptr.dimension + "_" + ptr.value]) {
                    for (var b = 0; b < self.conditions.length - self.metricCollection.length; ++b) {
                        if (ptr.dimension == self.conditions[b].dimension) {
                            value.unshift(self.conditions[b].decodedValues[ptr.value]);
                            conditionMap[ptr.dimension + "_" + ptr.value] = b;
                        }
                    }
                } else {
                    value.unshift(self.conditions[conditionMap[ptr.dimension + "_" + ptr.value]].decodedValues[ptr.value]);
                }
                //value.unshift(ptr.value);
                ptr = ptr.parent;
            }
            result.push(value.join(glue));
        }
        return result;
    };

    //Desenha o painel de criação dos gráficos
    chartPanel = () => {
        var self = this;
        var tableButton = self.$j(`
        <button type="button" class="btn  btn-circle btn-lg">
            <h2><i class="fas fa-arrow-alt-circle-left"></i></h2>
        </button>`);
        var addGraphButton = self.$j(`
        <button type="button" class="btn  btn-circle btn-lg">
            <h2><i class=" fas fa-user-plus"></i></h2>
        </button>`);

        self.$j(self.chartbtn).unbind('click');
        self.$j(self.chartbtn).click(function () {
            self.$j(self.div).hide();
            self.$j(self.config_div).hide();
            self.$j(self.filterDiv).hide();
            self.$j(self.chartDiv).show();
        });

        self.$j(tableButton).unbind('click')
        self.$j(tableButton).click(function () {
            self.$j(self.div).show();
            self.$j(self.config_div).show();
            self.$j(self.filterDiv).show();
            self.$j(self.chartDiv).hide();
        }).trigger('click');

        self.$j(addGraphButton).click(function (event) {
            var coords = CubePreConfig.localizate_event(event);
            self.propPage.style.left = coords[0] + "px";
            self.propPage.style.top = coords[1] + "px";
            self.$j(self.propPage).empty();

            /* contents */

            var graphName = self.$j(`<label for="graphName" style="width: 110px;text-align: right">Título</label>
            <input id="graphName" type="text" ><br/>`);

            var xAxis = self.$j(`<label for="xAxisName" style="width: 110px;text-align: right">Eixo X</label>
            <input id="xAxisName" type="text" ><br/>`);

            var yAxis = self.$j(`<label for="yAxisName" style="width: 110px;text-align: right">Eixo Y</label>
            <input id="yAxisName" type="text" ><br/>`);

            var label = self.$j('<label for="metricGraph" style="width: 110px;text-align: right">Métricas</label>');
            var selectM = self.$j('<select id="metricGraph" name="metricGraph" multiple></select>');
            self.$j(self.metricShowedLabel).forEach(function (e, i) {
                self.$j(selectM).append( self.$j('<option value="' + i + '">' + self.metricCollection[e].name +'</option>') );
            });

            var dataSource = self.$j(`<label for="dataSource" style="width: 110px;text-align: right">Fonte de Dados</label>
            <select id="dataSource" name="dataSource">
                <option value="row">Total das linhas da tabela</option>
                <option value="col">Total das colunas da tabela</option>
                <option value="pivot">Cruzamento das dimensões</option>
            </select>`);
            self.$j(dataSource[2]).change(function(){
                var current = self.$j(this).val();
                if(current == 'pivot')
                    self.$j('#metricGraph').removeAttr('multiple');
                else
                    self.$j('#metricGraph').attr('multiple','multiple');
            });

            var graphFormat = self.$j(`<br/>
            <label for="graphFormat" style="width: 110px;text-align: right">Tipo de Gráfico</label>
            <select id="graphFormat" name="graphFormat">
                <option value="line">Linhas</option>
                <option value="column">Colunas</option>
                <option value="bar">Barras</option>
                <option value="pie">Pizza</option>
                <option value="doughnut">Donut</option>
            </select><br/><span id="errorGraph" name="errorGraph" style="display:none;color:red"></span>`);

            var runButton = function() {
                var message = [];
                if (self.$j('#graphName').val().trim() == '')
                    message.push('Defina um título para o gráfico');
                if (self.$j('#metricGraph').val().length == 0)
                    message.push('Selecione pelo menos uma métrica');

                if (message.length == 0) {
                    self.graphConfig.push({
                        name: self.$j('#graphName').val(),
                        metrics: self.$j('#metricGraph').val(),
                        dataSource: self.$j('#dataSource').val(),
                        format: self.$j('#graphFormat').val(),
                        xAxis: self.$j('#xAxisName').val(),
                        yAxis: self.$j('#yAxisName').val(),
                        instance: self.options.defaultgraphapi.graph_factory()
                    });
                    self.$j(self.propPage).hide();
                    self.drawGraphConfig();
                    return true;
                }
                else {
                    self.$j('#errorGraph').html(message.join('<br/>')).show();
                    return false;
                }
            };

            // self.$j(self.propPage).append([close, self.$j("<br/>"), graphName, label, selectM, self.$j("<br/>"), dataSource, graphFormat, self.$j("<br/>"), runButton]);
            // self.$j(self.propPage).show();

            self.$j(self.modalPage).find('.modal-title').html('Adicionar Gráfico');
            self.$j(self.modalPage).find('.modal-body').html('');
            self.$j(self.modalPage).find('.modal-body').append(
                [graphName, xAxis, yAxis, label, selectM, self.$j("<br/>"), dataSource, graphFormat, self.$j("<br/>")]
            );

            self.$j(self.modalPage).find('.modal-footer .actionModal').unbind('click');
            self.$j(self.modalPage).find('.modal-footer .actionModal').click(function() {
                if(runButton()) {
                    self.$j('#pivotModal').modal('hide');
                    self.$j('body').removeClass('modal-open');
                    self.$j('.modal-backdrop').remove();
                }
            });
            self.$j(self.modalPage).modal('show');

            self.$j(self.modalPage).find('[data-dismiss="modal"]').forEach(function(e) {
                self.$j(e).unbind('click');
                self.$j(e).click(function() {
                    self.$j(self.modalPage).modal('hide');
                });
            });

        });

        self.$j(self.chartDiv).empty();
        self.$j(self.chartDiv).append(tableButton);
        self.$j(self.chartDiv).append(addGraphButton);
        self.$j("#new_pivot_chart").show();
        self.$j('#new_pivot_chart').empty();

    };

    //Prepara o dado para ser enviado para a engine de geraçãp de gráficos
    getChartData = (metric, type) => {
        var self = this;
        var cols = [];
        var data = [];

        if (type == 'pivot') {
            var index = metric[0];
            var aux:any = ['row'];

            cols.push(aux.concat(self.getLabels(self.rowPointers[self.rowConditions.length - 1], -1, ":").reverse()));
            var textY = self.getLabels(self.colPointers[self.colConditions.length - 1], 1, ":");
            aux = self.tabularData[index][0].length - 1;
            for (var i = 0; i < self.tabularData[index].length; i++) {
                var col = [textY[i]];
                var array = self.tabularData[index][i];
                for (var j = 0; j < array.length; ++j) {
                    if (j <= aux)
                        col.push(Math.round(100 * Math.abs(self.tabularData[index][i][aux - j])) / 100);
                }
                data.push(col);
            }
        }
        else if (type == 'row' || type == 'col') {
            var pointers = (type == 'row' ? self.rowPointers[self.rowConditions.length - 1] : self.colPointers[self.colConditions.length - 1]);
            var textX = self.getLabels(pointers, 1, ":");

            var seriesName = [type], totals = []
            for(var i = 0; i < metric.length; ++i) {
                var index = metric[i];
                seriesName.push(self.metricCollection[self.metricShowedLabel[index]].name);
                totals.push(type == 'row' ? self.rowTotals[index] : self.colTotals[index]);
            }
            cols.push(seriesName);

            if (textX.length > 0) {
                for (var i = 0; i < textX.length; ++i) {
                    var line = [ textX[i] ];
                    for(var j = 0; j < totals.length; ++j)
                        line.push( !isNaN(totals[j][i]) ? Math.round(100 * Math.abs(totals[j][i]) ) / 100 : 0);
                    data.push(line);
                }
            }
        }

        return { cols: cols, data: data };
    };

    //Invoca a engine de geraçã de gráficos
    drawGraphConfig = () => {
        var self = this;
        self.$j('.dynGraph').remove();

        for (var i = 0; i < self.graphConfig.length; ++i) {
            //Prepara div para o gráfico
            var manager = self.graphConfig[i].instance;
            var div_id = 'graph_' + i;
            var title = self.graphConfig[i].name;
            var newDiv = self.$j( manager.dynamicReport(div_id, title) );
            self.$j(newDiv).find('.btn-dyncustom').click( (function(index) {
                return function() {
                    self.graphConfig.splice(index);
                    self.drawGraphConfig();
                };
            })(i) );
            self.$j(self.chartDiv).append( newDiv );

            var conf: { [key:string]:any } = {};
            if( self.graphConfig[i].xAxis && self.graphConfig[i].xAxis.trim() != '' )
                conf.titleX = self.graphConfig[i].xAxis;
            else if( self.graphConfig[i].yAxis && self.graphConfig[i].yAxis.trim() != '' )
                conf.titleY = self.graphConfig[i].yAxis;

            //Renderiza o gráfico
            var result = self.getChartData( self.graphConfig[i].metrics, self.graphConfig[i].dataSource );
            manager.initializeGraph(result.cols[0], result.data, {}, self.graphConfig[i].format, div_id, conf, null, true);
        }
    };
    //#endregion Graph Section

    rankCol = (conf) => {
        var self = this;
        return function (event) {
            var metricIndex = conf.typeCount % self.metricShowedLabel.length;
            var yIndex = Math.trunc(conf.typeCount / self.metricShowedLabel.length);
            var list;
            if (conf.type == "default")
                list = self.tabularData[self.metricShowedLabel[metricIndex]][yIndex].slice();
            else if (conf.type == "totals")
                list = self.rowTotals[self.metricShowedLabel[metricIndex]].slice();
            else if (conf.type == "subtotals")
                list = self.subTotals[conf.typeCount].slice();

            for (var i = 0; i < list.length; ++i) {
                list[i] = {
                    position: i,
                    element: list[i]
                };
            }
            self.rankedIndex = {
                index: conf.typeCount,
                type: conf.type,
                order: (self.rankedIndex != -1 && self.rankedIndex.index == conf.typeCount && self.rankedIndex.order == "desc" ? "asc" : "desc")
            };
            self.rankedOrder = self.quickSort(list, null, null, function (a, b) {
                if (a.element < b.element) return 1 * (self.rankedIndex.order == "desc" ? 1 : -1);
                else if (a.element > b.element) return -1 * (self.rankedIndex.order == "desc" ? 1 : -1);
                else return 0;
            });

            self.clearRank = false;
            self.buildDataSource();
        };
    };

    rankColPre = () => {
        var self = this;
        var metricIndex = self.rankedIndex.index % self.metricShowedLabel.length;
        var yIndex = Math.trunc(self.rankedIndex.index / self.metricShowedLabel.length);
        var list;
        if (self.rankedIndex.type == "default")
            list = self.tabularData[self.metricShowedLabel[metricIndex]][yIndex].slice();
        else if (self.rankedIndex.type == "totals")
            list = self.rowTotals[self.metricShowedLabel[metricIndex]].slice();
        /*else if (conf.type == "subtotals")
        list = self.subTotals[conf.typeCount].slice();*/

        for (var i = 0; i < list.length; ++i) {
            list[i] = {
                position: i,
                element: list[i]
            };
        }
        self.rankedOrder = self.quickSort(list, null, null, function (a, b) {
            if (a.element < b.element) return 1 * (self.rankedIndex.order == "desc" ? 1 : -1);
            else if (a.element > b.element) return -1 * (self.rankedIndex.order == "desc" ? 1 : -1);
            else return 0;
        });
    };

    getClassName = (i, j) => {
        var self = this;
        /* decide odd/even class */
        var xCounter = 1;
        var yCounter = 1;
        if (self.colConditions.length > 1) {
            var colItem = self.colPointers[self.colConditions.length - 1][j].parent;
            var index = colItem.parent.items.pivotCustomFind(colItem);
            xCounter = (index % 2 ? 1 : -1);
        }
        if (self.rowConditions.length > 1) {
            var rowItem = self.rowPointers[self.rowConditions.length - 1][i].parent;
            var index = rowItem.parent.items.pivotCustomFind(rowItem);
            yCounter = (index % 2 ? 1 : -1);
        }
        if (xCounter * yCounter == 1) {
            return "odd";
        } else {
            return "even";
        }
    };

    formatValue = (value, indexMetric) => {
        var self = this;
        var formatter = self.metricCollection[indexMetric].numberConfigInstance;
        var cellFormatValue  = formatter ? formatter.formatValue(value) : value;

        return cellFormatValue;
    };

    progressLoader = (msg, value?, bound?) => {
        var self = this;
        if (msg)
            self.progressMessage = msg;

        if (value || bound) {
            setTimeout(function () {
                if (self.progressCount <= 100 && value != null)
                    self.progressCount = value;
                else if (self.progressCount <= 100 && self.progressCount <= bound)
                    self.progressCount += 0.01;

                var message = self.progressMessage != "" ? "(" + self.progressMessage + ")" : "";
                if (document.getElementById("pivot_loading_count") != null && document.getElementById("pivot_loading_count") != null) {
                    document.getElementById("pivot_loading_count").innerHTML = (self.progressCount.toFixed(2)) + "% ";
                    document.getElementById("pivot_loading_text").innerHTML = message;
                }
            }, 1);

        }
    };

    applyFilters = () => {
        var self = this; // create filteredData from allData
        self.filteredData = [];
        for (var n = 0; n < self.metricCollection.length; n++) {
            self.filteredData[n] = [];
            var isDinamyc = (self.findDinamycMetricByName(self.metricCollection[n].name) != -1);
            self.progressLoader("Criando filtros : " + n * self.allData.length + " de " + self.metricCollection.length * self.allData.length, null, 20);

            for (var i = 0; i < self.allData.length; i++) {
                var subIndex = self.allData[i].length - (self.metricCollection.length - self.metricDinamycMap.length);
                var subArray = self.allData[i].slice(0, subIndex);
                subArray.push(isDinamyc ? 0 : self.allData[i][n + subIndex]);
                if (self.filterOK(subArray)) {
                    self.filteredData[n].push(subArray);
                }
            }
        }
    };

    getDimensionItemCount = (stack, cond) => {
        var self = this; //Devolve o numero de celulas na linha ou na coluna resultante da configuraçao escolhida pelo usuario
        var count = 0;
        for (var j = 0; j < stack.length; j++) {
            count += (cond.distinctValues.length - cond.blackList.length);
        }

        return count;
    };

    findConditionItem = (sortedArr, val, coef, numberOrder) => {
        var self = this;
        function greaterValue(a, b, coef, numberOrder) {
            if (a == b)
                return 0;

            if (numberOrder) {
                return coef * (parseInt(a) > parseInt(b) ? 1 : -1);
            } else {
                return coef * (a > b ? 1 : -1);
            }
        }
        var low = 0,
            high = sortedArr.length;
        var mid = -1,
            c = 0;
        while (low < high) {
            mid = Math.trunc((low + high) / 2);
            c = greaterValue(sortedArr[mid].value, val, coef, numberOrder);
            if (c < 0) {
                low = mid + 1;
            } else if (c > 0) {
                high = mid;
            } else {
                return mid;
            }
        }
        return low;
    };

    createAggStructure = () => {
        var self = this;
        /* create a multidimensional aggregation structure */
        function createStructureForDimension(struct, arr) {
            struct.items = false;
            //struct.depth = -1;

            var array = self.allData;
            var work = new CubeThread(array);

            work.action = function (item, d, total) {
                self.progressLoader("Criando estruturas agregadas : " + (d + 1) + " de " + total);

                var previous = null;
                for (var i = 0; i < arr.length; i++) {
                    var cond = self.conditions[arr[i]];

                    var dimensionForCell = cond.dimension;
                    var valueForCell = array[d][arr[i]];
                    var parentForCell = (previous ? previous : struct);

                    if (!cond.dimensionNumberOrder)
                        cond.dimensionNumberOrder = array.filter(function(e) {
					        return e[arr[i]] != parseInt(e[arr[i]])
					    }).length == 0;


                    if (cond.blackList.pivotCustomFind(valueForCell) == -1) {
                        if (!self.dimensionDepth[dimensionForCell])
                            self.dimensionDepth[dimensionForCell] = i;

                        var node = null;
                        var index = 0;
                        if (parentForCell.items) {

                            var index = self.findConditionItem(parentForCell.items, valueForCell, cond.sort, cond.dimensionNumberOrder);
                            if (parentForCell.items[index] && parentForCell.items[index].value == valueForCell) {
                                node = parentForCell.items[index];
                            }
                        }

                        if (!parentForCell.items)
                            parentForCell.items = [];

                        if (!node) {
                            node = {
                                value: valueForCell,
                                parent: parentForCell,
                                items: false,
                                dimension: dimensionForCell
                            };
                            //parentForCell.items.push(node);
                            parentForCell.items.splice(index, 0, node);
                        }
                        previous = node;
                    }
                    else { //O item foi encontrado na blacklist
                        i = arr.length; // Aborta o aprofundamento na arvore
                        var child:any = new Object;
                        var father = parentForCell;
                        while (father.parent &&
                            (father.items == false || father.items.length == 0 || (father.items.length == 1 && father.items[0].value == child.value))) {
                            child = father;
                            father = father.parent;
                            father.items.splice(father.items.indexOf(child), 1);
                        }
                    }
                }
            };

            work.finish = function (thread) {
                --self.threadCount;
            };
            ++self.threadCount;
            work.start();
        }
        self.dimensionDepth = new Object();

        createStructureForDimension(self.rowStructure, self.rowConditions);
        createStructureForDimension(self.colStructure, self.colConditions);
    };

    fillAggStructure = () => {
        var self = this;
        /* mark used branches of aggregation structure */


        /*function fillAllPart(struct) {
        var ptr = struct;
        if (!ptr.items) {
        ptr.used = true;
        return;
        }
        for (var i = 0; i < ptr.items.length; i++) { fillAllPart(ptr.items[i]); }
        }

        if (self.options.showEmpty) {
        fillAllPart(self.rowStructure);
        fillAllPart(self.colStructure);
        } else {*/


        // for (var n = 0; n < self.filteredData.length; n++) {
        //     if (self.filteredData[n].length > 0) self.fillAggStructurePartial(n);
        // }


        //}
    };

    asyncCount = () => {
        var self = this;

        self.count(); // fill tabularData with values

        var drawPointer = setInterval(function () {
            self.progressLoader(null, null, 45);
            if (self.threadCount == 0) {
                clearInterval(drawPointer);
                self.progressLoader(null, 48);

                self.rawTabularData = JSON.parse(JSON.stringify(self.tabularData));//self.extend(false, [], self.tabularData);
                self.convertToFloat();
                self.isSubtotals();

                if (self.rankedIndex != -1 && self.rankedOrder == -1) { //Pedido de preordenaçao externa
                    self.rankColPre();
                }
                self.asyncDrawTable();
            }
        }, 100);

    };

    attachEventToAggFunc = () => {
        var self = this;
        var aggFunc, aggTotal;
        var aggRef = function () {
            self.options.agg = parseInt(self.$j(aggFunc).val());
            self.buildDataSource();
        }
        var aggRefTotals = function () {
            self.options.aggTotals = parseInt(self.$j(aggTotal).val());
            self.buildDataSource();
        }
    };

    isOverloadingConfiguration = () => {
        var self = this;
        var result = true;

        var numColHead = 1;
        for (var i = 0; i < self.colConditions.length; ++i) {
            var cond = self.conditions[self.colConditions[i]];
            numColHead *= (cond.distinctValues.length - cond.blackList.length);
        }

        var numRowHead = 1;
        for (var j = 0; j < self.rowConditions.length; ++j) {
            var cond = self.conditions[self.rowConditions[j]];
            numRowHead *= (cond.distinctValues.length - cond.blackList.length);
        }

        return result;
    };

    clearPivotConf = () => {
        var self = this;
        self.conditions = [];
        self.filteredData = [];
        self.metricShowedLabel = [];
        self.mapAssociationIndex = [];
    };

    buildDataSource = (isAlreadyInitializedProgressLoader?) => {
        var self = this;
        if (self.clearRank)
            self.rankedIndex = -1;
        else
            self.clearRank = true;

        self.$j(self.div).empty();
        self.$j(self.chartDiv).empty();
        self.$j(self.propPage).empty();
        self.$j(self.filterDiv).empty();
        self.$j(self.config_div).hide();
        self.$j(self.loading_div).show();
        self.drag.clearSources();
        self.drag.clearTargets();

        if(!isAlreadyInitializedProgressLoader)
            self.progressLoader(null, 1, 30);

        if (self.isOverloadingConfiguration()) {
            self.applyFilters();
            self.createAggStructure();

            var pointerAgg = setInterval(function () {
                self.progressLoader(null, null, 30);
                if (self.threadCount == 0) {
                    self.progressLoader("Estruturas agregadas concluídas", 30);
                    clearInterval(pointerAgg);
                    self.asyncCount();
                }
            }, 100);
        } else {
            self.$j(self.config_div).show();
            self.$j(self.loading_div).hide();
            if (self.options.callbackFunction)
                self.options.callbackFunction();
        }
    };

    eventSubscription = () => {
        var self = this;
        self.$j(document).unbind('mousemove', CubeDragEvent.move);
        self.$j(document).unbind('mouseup', CubeDragEvent.up);
        self.$j(document).mousemove(CubeDragEvent.move);
        self.$j(document).mouseup(CubeDragEvent.up);
    }

    startPivot = () => {
        var self = this;
        self.clearPivotConf();
        self.progressLoader(null, null, 1);
        self.$j(self.loading_div).show();
        self.progressLoader(null, 0, 5);
        self.setMetricConf();
        self.init();
        self.eventSubscription();

        var pointer = setInterval(function () {
            self.progressLoader(null, null, 10);
            if (self.threadCount == 0) {
                self.progressLoader("Estruturas de configurações concluídas", 10);

                clearInterval(pointer);
                self.buildDataSource(true);
                //self.attachEventToAggFunc();
            }
        }, 100);
    };

}



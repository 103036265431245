import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AuthRoutingModule } from './auth-routing.module';
import { SigninComponent } from './signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Error403Component } from './pages/error403/error403.component';
import { HubComponent } from './hub/hub.component';
import { SharedModule } from '../components/shared/shared.module';


@NgModule({
  declarations: [SigninComponent, ResetPasswordComponent, Error403Component, HubComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    AuthRoutingModule,
    ToastrModule.forRoot(), // ToastrModule added
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [SigninComponent, ResetPasswordComponent, Error403Component]
})
export class AuthModule { }

<section class="section mt-3">
  <div class="section-header">
    <h1>{{ layout.aliasTitle('Imagem') }}</h1>
    <!-- <div class="section-header-breadcrumb">
            <div class="breadcrumb-item "><a href="">Dashboard</a></div>
            <div class="breadcrumb-item"><a href="/plant/equipmentimages">Imagem do IoT</a></div>
            <div class="breadcrumb-item active">Adicionar</div>
        </div> -->
  </div>

  <div class="row">
    <div class="col-12 ">
      <div class="card">
        <div class="card-header">
          <h4>Upload de {{ layout.aliasTitle('Imagem') }}</h4>
        </div>
        <div class="card-body">
          <form method="POST" [formGroup]="form">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="Sector">Agrupador</label>
                <select class="form-control" formControlName="bundle_equipment_id"
                  [ngClass]="displayFieldCss('bundle_equipment_id')">
                  <option value="" selected disabled>Selecione</option>
                  <option *ngFor="let item of equipments" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  Por favor selecione um {{ layout.aliasTitle('Agrupador') }}
                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="qr_code">QR code</label>
                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="qr_code">
              </div>
              <div class="form-group col-md-4">
                <label for="description">Descrição</label>
                <input type="text" class="form-control mb-2 mr-sm-2" formControlName="description">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="image">Imagem</label>
                <input type="file" class="form-control mb-2 mr-sm-2" formControlName="image"
                  [ngClass]="{'is-invalid': invalidFileTypeError }" [accept]="allowedExtensions"
                  (change)="onImageChange($event)">
                <div class="invalid-feedback">
                  {{ invalidFileTypeError }}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-right">
          <app-default-button [isLoading]="isLoading" (pressed)="save()"></app-default-button>
          <app-default-button [type]="'secondary'" [label]="'Limpar'" (pressed)="reset()"></app-default-button>
          <app-default-button [type]="'secondary'" [label]="'Cancelar'"  [navigateTo]="'/plant/equipment'"></app-default-button>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../../../auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BootstrapModalAlertService } from '../../../../shared/bootstrap-modal-alert/bootstrap-modal-alert.service';
import { PersonService } from '../../../../person/person.service';
import { Location } from '@angular/common';
import { Company, CompanyDivision } from '../../../shared/model/company-division.model';
import { Person } from '../../../../person/person.model';
import { take } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CompanyService } from '../../../shared/services/company.service';

/**
 * Componente para atualizar informações de uma empresa.
 */
@Component({
  selector: 'app-company-update',
  templateUrl: './company-update.component.html',
  styleUrls: ['./company-update.component.css']
})
export class CompanyUpdateComponent implements OnInit {

  /** Formulário para informações da empresa. */
  companyForm: FormGroup;

  /** Lista de pessoas associadas à empresa. */
  persons: Person[];

  /** Lista de divisões de empresa. */
  companyDivisions: CompanyDivision[];

  /** Lista de fusos horários. */
  timezones = [];

  /** ID da pessoa selecionada. */
  person_selected = "";

  /** ID da divisão de empresa selecionada. */
  company_division_selected = "";

  /** Fusão horária selecionada. */
  timezone_selected = "";

  /** URL da imagem do logo da empresa. */
  logo_src = "";

  /** Flag para verificar se o formulário foi enviado/submetido. */
  submitted = false;

  personId: number = null;

  isLoading: boolean = false;

  /**
   * Construtor para injetar dependências necessárias.
   */
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private personService: PersonService,
    private modalAlertService: BootstrapModalAlertService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    public layout: LayoutService
  ) { }

  /**
   * Método do ciclo de vida do Angular executado ao inicializar o componente.
   * Verifica permissões de acesso, a habilitação do usuário e recupera as informações da empresa.
   */
  ngOnInit(): void {
    // Verifica se o usuário está habilitado.
    this.authService.isEnable();

    // Verifica permissões de acesso.
    if (this.authService.checkPermission('change_company') == false) {
      this.router.navigate(['/403']);
    };

    // Observa as mudanças nos parâmetros da rota e executa o método read quando houver alterações.
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }

  /**
   * Lê as informações da empresa a partir do ID fornecido.
   *
   * @param id ID da empresa a ser lida.
   */
  read = (id) => {

    // Obtém todas as pessoas associadas à empresa.
    this.personService.getPersons(this.authService.endpoint_main + 'person/list/nopage').pipe(take(1)).subscribe(
      data => {
        this.persons = data;
        
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de perfis de clientes');
      }
    );

    // Obtém todas as divisões de empresas.
    this.companyService.getCompanyDivisionAll(this.authService.endpoint_main + 'companydivisions/nopage').pipe(take(1)).subscribe(
      data => {
        this.companyDivisions = data;
      }, error => {
        this.modalAlertService.showAlertDanger('Não foi possível recuperar lista de unidades de grupos empresariais ');
      }
    );

    // Fusos horários disponíveis.
    this.timezones = [
      { "id": "America/Noronha", "value": "America/Noronha" },
      { "id": "America/Belem", "value": "America/Belem" },
      { "id": "America/Fortaleza", "value": "America/Fortaleza" },
      { "id": "America/Recife", "value": "America/Recife" },
      { "id": "America/Araguaina", "value": "America/Araguaina" },
      { "id": "America/Maceio", "value": "America/Maceio" },
      { "id": "America/Bahia", "value": "America/Bahia" },
      { "id": "America/Sao_Paulo", "value": "America/Sao_Paulo" },
      { "id": "America/Campo_Grande", "value": "America/Campo_Grande" },
      { "id": "America/Cuiaba", "value": "America/Cuiaba" },
      { "id": "America/Santarem", "value": "America/Santarem" },
      { "id": "America/Porto_Velho", "value": "America/Porto_Velho" },
      { "id": "America/Boa_Vista", "value": "America/Boa_Vista" },
      { "id": "America/Manaus", "value": "America/Manaus" },
      { "id": "America/Eirunepe", "value": "America/Eirunepe" },
      { "id": "America/Rio_Branco", "value": "America/Rio_Branco" },
    ];

    // Inicializa o formulário reativo.
    this.companyForm = this.formBuilder.group({
      id: [''],
      company_division: null,
      timezone: ['', Validators.required],
      logo: ['']
    });

    // Obtém informações da empresa.
    this.companyService.getCompany(id).pipe(take(1)).subscribe(
      data => {
        // Preenche o formulário com os dados da empresa.
        this.companyForm.get('id').setValue(data.company_id);
        this.companyForm.get('timezone').setValue(data.timezone);
        if (data.company_division_id != null) {
          this.companyForm.get('company_division').setValue(data.company_division_id.company_division_id);
          this.company_division_selected = data.company_division_id.company_division_id;
        }
        this.person_selected = data.person_id.id;
        this.personId = data.person_id.id

        console.log(this.personId);
        
        
        this.timezone_selected = data.timezone;
        this.logo_src = data.logo;

      }, error => { }
    );
  }

  /**
   * Verifica se um campo específico do formulário é válido.
   *
   * @param field Nome do campo a ser verificado.
   * @returns `true` se o campo for inválido, `false` caso contrário.
   */
  isFieldValid(field: string) {
    return !this.companyForm.get(field).valid && this.submitted == true;
  }

  /**
   * Retorna as classes CSS a serem aplicadas a um campo específico do formulário.
   *
   * @param field Nome do campo.
   * @returns Objeto com a classe CSS a ser aplicada.
   */
  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  /**
   * Valida todos os campos do formulário.
   *
   * @param formGroup Grupo de formulários a ser validado.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Atualiza os dados de uma empresa com base nos valores do formulário.
   */
  update = () => {
    // Define que o formulário foi submetido.
    this.submitted = true;

    // Verifica se o formulário é válido.
    if (this.companyForm.valid) {
      // Verifica se existe token.
      if (this.authService.hasToken()) {

        // Chama o serviço para atualizar os dados da empresa.
        this.isLoading = true;

        const payload = {
          ...this.companyForm.value,
          person: this.personId,
          company_division: String(this.companyForm.value['company_division']),
        }

        this.companyService.updateCompany(payload).pipe(take(1)).subscribe(
          data => {
            // Exibe um alerta de sucesso.
            this.modalAlertService.showAlertSuccess('Registro alterado com sucesso');
            this.router.navigate(['/company'])

          }, error => {
            // Em caso de erro na atualização, exibe um alerta de perigo.
            this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
          }
        ).add(() => {
          scrollTo(0,0);
          this.isLoading = true;
        });
      } else {
        // Se não houver ID, exibe um alerta de perigo e faz logout.
        this.modalAlertService.showAlertDanger('Erro ao incluir o registro');
        this.authService.doLogout();
      }
    } else {
      // Se o formulário não for válido, valida todos os campos do formulário.
      this.validateAllFormFields(this.companyForm);
    }
  }

  /**
   * Reseta o formulário e obtém novamente as informações da empresa.
   */
  reset = () => {
    this.submitted = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const id = +param.get('id');
      this.read(id);
    });
  }
}

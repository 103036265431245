import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainlayoutComponent } from 'src/app/views/mainlayout/mainlayout.component';
import { PersonContainerComponent } from '../customize-container/person-container/person-container.component';
import { PersonCreateComponent } from './person-create/person-create.component';
import { PersonUpdateComponent } from './person-update/person-update.component';

const routes: Routes = [
  { path: '', component: MainlayoutComponent, children: [{ path: '', component: PersonContainerComponent }] },
  { path: 'create', component: MainlayoutComponent, children: [{ path: '', component: PersonCreateComponent }] },
  {
    path: 'update/:id',
    component: MainlayoutComponent,
    children: [{ path: '', component: PersonUpdateComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PersonRoutingModule {}

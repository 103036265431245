<section class="section">
  <div class="section-header">
      <h1>{{ layout.aliasTitle('Grupo') }}</h1>
      <!-- <div class="section-header-breadcrumb">
          <div class="breadcrumb-item "><a href="">Dashboard</a></div>
          <div class="breadcrumb-item"><a href="/companygroups">Grupos empresariais</a></div>
          <div class="breadcrumb-item active">Adicionar</div>
      </div> -->
  </div>

  <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
          <div class="card">
              <div class="card-header">
                  <h4>Adicionar {{ layout.aliasTitle('Grupo') }} </h4>
              </div>
              <div class="card-body">
                  <form method="POST">
                      <div class="form-group">
                          <label for="name">Nome</label>
                          <input type="text" class="form-control mb-2 mr-sm-2" id="name"
                              placeholder="Digite nome do novo grupo" [(ngModel)]="group.name" name="name">
                      </div>
                      <div class="form-group">
                          <label class="d-block">Status</label>
                          <div class="form-check">
                              <input class="form-check-input" [(ngModel)]="group.active"
                                  [ngModelOptions]="{standalone: true}" type="checkbox" id="active">
                              <label class="form-check-label" for="active">
                                  Ativo
                              </label>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="card-footer text-right">
                  <button class="btn btn-primary mr-1" type="submit" (click)="save()">Salvar</button>
                  <a class="btn btn-secondary cancel-button" style="color:white" routerLink="/company">Cancelar</a>
              </div>
          </div>
      </div>
  </div>
</section>

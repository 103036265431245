import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { IcSelectService } from './ic-select.service';
import { take } from 'rxjs/operators';
import { BaseFunc } from '../../shared/base-func/base-func';
import { Global } from '../../shared/base-func/global';


@Component({
  selector: 'app-ic-select',
  templateUrl: './ic-select.component.html',
  styleUrls: ['./ic-select.component.css']
})
export class IcSelectComponent extends BaseFunc implements OnInit {
  @Input() key: string = null;
  @Input() prefix_id? : string = null;
  @Input() sufix_id? : string = null;
  @Input() hasLabel: boolean = true;
  @Input() icon?: string = null;
  @Input() label?: string = null;
  @Input() fullwidth: boolean = false;
  // @Input() select? : string = 'true';
  @Input() showOnly? : number = 2;
  @Input() style? : string = 'width: 100%';
  @Input() style_label? : string = '';
  @Input() class_label? : string = '';
  @Input() limit? : any = 999999999999;
  @Input() validateOption : boolean = false;
  @Input() obg : string = 'false';
  // @Input() depends = str.join(",", ["select%s" % (k,) for k in mapping.depends.keys()] if mapping.depends else [])
  @ViewChild('selectref', { static: true }) selectref;

  itemlist = [];
  selectedItemsCollection = [];
  mapping = {}
  global: Global = new Global()

  constructor(private itemListService: IcSelectService) { super() }

  ngOnInit(): void {
    var self = this;
    if(self['limit'] == '')
      self.limit = 999999999999;

    this.itemListService.getItemComponent(this.key).pipe(take(1)).subscribe(
      data => {
        this.mapping = data;
        this.onOpen();
      },
      error => {
        console.log('Ocorreu um erro ao buscar uma definição do campo autocompletável ' + this.getId())
      }
    );
  }

  onSelectItem = (newValue) => {
    //console.log(newValue);
    if (newValue.length > 0) {
      const id = [];
      for (const i of newValue) {
        id.push(i.id);
      }
    }
  }

  onKeyPress = (event) => {
    event.stopImmediatePropagation();
    var text = event.target.value;
    this.searchFunction(text);
    return true;

    // return this.itemlist;
  }

  onOpen = () => {
    this.searchFunction(null);
  }

  searchFunction(inputValue) {
    var map = {};
    var depends = this.mapping['depends'].trim() != '' ? this.mapping['depends'].split(',') : [];
    for(var i=0; i < depends.length; ++ i ) {
      var els = document.querySelectorAll('#'+depends[i]);
      if( els.length > 0 ) {
        var selElements = this.getComponent(document.querySelector('#' + depends[i])).itemsList.selectedItems;
        if( selElements.length > 0)
          map[depends[i].replace("select", "")] = selElements.map(function(e) { return e.value.id; }).join('<spt_s>');
      }
    }
    this.itemListService.getItemList(this.key, inputValue, map).pipe(take(1)).subscribe(
      data => {
        this.itemlist = [];
        for (var r in data.results) {
          if (data.results.hasOwnProperty( r ))
            this.itemlist.push({
              id: data.results[r].id,
              name: data.results[r].text,
              grouping: data.results[r].grouping
            });
        }

        //Essa parte do código foi comentada para evitar que as opções "SELECIONAR TODOS" e "LIMPAR TODOS" sumam
        //As funcionalidades comentadas abaixo fazem um calculo do tamanho do bloco de conteudo que lista os parametros e a partir de determinadas condições oculta ou mostra as opções descritas abaixo
        if(this.limit < this.itemlist.length) {

          //this.$j('.ng-dropdown-header').hide();
          this.$j('.ng-dropdown-header').show();
        }
        else {

          //this.$j('.ng-dropdown-header').show();
        }

      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectAll() {
    const selected = this.itemlist.map(item => this.global.addToSelect2( this.selectref, item.id, item.name) );
  }

  onClearAll() {
    this.global.clearSelect( this.selectref );
  }

  getId() {
    return (this.prefix_id ? this.prefix_id : 'select') + (this.key ? this.key : '' ) + ( this.sufix_id ? this.sufix_id : '' );
  }

  getLabel() {
    if ( this.label != null && this.label != '' && this.label.startsWith('override') )
      return this.label.replace('override', '');

    if (this.mapping['label'] != '')
      return this.mapping['label'];

    return this.label == '' || this.label ? this.label : this.mapping['label'];
  }

  getIcon() {
    if ( this.icon != null && this.icon != '' && this.icon.startsWith('override') )
      return this.label.replace('override', '');

    if (this.mapping['icon'] != '')
      return this.mapping['icon'];

    return this.icon == '' || this.icon ? this.icon : this.mapping['icon'];
  }

  getSelectType() {
    return this.limit != 1 ? 'true' : 'false';
  }

}

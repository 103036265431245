<ng-template #childComponentTemplate>
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="(reportFilters.indexOf(field.type) > -1)">
      <div id="div_select{{field.type}}"
        class="{{positionField}} custom_autocomplete" [attr.ac_tools]="field.type" >
        <app-ic-select key="{{field.type}}" limit="{{field.limit}}" label="{{field.label}}" obg="{{field.obg}}" icon="{{field.icon}}" [hasLabel]="hasLabel">
        </app-ic-select>
      </div>
    </ng-container>

    <!-- Date range-->
    <ng-container *ngSwitchCase="field.type === 'DATEBTW'">
      <div id="div_date-interval" class="datefilter tour-field-datebtw {{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">

          <ng-container *ngIf="hasLabel">
            <ng-container *ngIf="(field.icon != ''); then withIcon; else withoutIcon">
            </ng-container>

            <ng-template #withIcon>
              <div>
                <span class="input-group-text">
                  <i class='{{field.icon}}' title='Data'></i>
                </span>
              </div>
            </ng-template>

            <ng-template #withoutIcon>
              <div class="d-flex mb-1">
                <span class="input-group-text mx-0">
                {{field.label}}
                </span>
                <i class="fa-solid fa-circle-info mx-1" [tooltip]="field.description" *ngIf="field.description"></i>
              </div>
            </ng-template>

          </ng-container>

          <input type="text" id="date-interval{{field.count}}" name="date-interval"
            class="form-control field-engine date-interval datebtw"
            ngxDaterangepickerMd
            alwaysShowCalendars="true"
            autocomplete="off"
            [closeOnAutoApply]="true"
            [linkedCalendars]="true"
            [locale]="{
              applyLabel: 'Aplicar',
              customRangeLabel: 'Personalizar',
              clearLabel: '',
              cancelLabel: 'Cancelar',
              format: 'DD/MM/YYYY HH:mm',
              firstDay: 1,
              monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
              daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
            }"
            [(ngModel)]="selected"
            [ngModelOptions]="{standalone: true}"
            [opens]="'right'"
            [ranges]="ranges"
            [showCancel]="true"
            [showClearButton]="true"
            [showCustomRangeLabel]="true"
            [showDropdowns]="true"
            [timePicker]="true"
            [timePickerIncrement]="15"
            [timePickerSeconds]="false"
            [timePicker24Hour]="true"
          />

        </div>
      </div>
      <span class="container" id="navigateButtons" name="navigateButtons" *ngIf=" formType == 'REPORT' ">
        <button id="rewind" name="rewind" class="btn btn-default">
          <i class="fa fa-angle-left fa-1x"></i>
        </button>
        <button id="forward" name="forward" class="btn btn-default">
          <i class="fa fa-angle-right fa-1x"></i>
        </button>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'DATA'">
      <div id="div_date-interval" class="datefilter tour-field-date {{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">

          <ng-container *ngIf="hasLabel">
            <ng-container *ngIf="(field.icon != ''); then withIcon; else withoutIcon">
            </ng-container>

            <ng-template #withIcon>
              <div>
              <span class="input-group-text">
                <i class='{{field.icon}}' title='Data'></i>
              </span>
              </div>
            </ng-template>

            <ng-template #withoutIcon>
              <div class="d-flex mb-1">
                <span class="input-group-text mx-0">
                  {{field.label}}
                </span>
                <i class="fa-solid fa-circle-info mx-1" [tooltip]="field.description" *ngIf="field.description"></i>
              </div>
            </ng-template>
          </ng-container>

          <input type="text" id="date-interval" name="date-interval"
            class="form-control field-engine date-interval single-date"
            ngxDaterangepickerMd
            alwaysShowCalendars="true"
            autocomplete="off"
            [closeOnAutoApply]="true"
            [linkedCalendars]="true"
            [locale]="{
                  applyLabel: 'Aplicar',
                  customRangeLabel: 'Personalizar',
                  clearLabel: '',
                  cancelLabel: 'Cancelar',
                  format: 'DD/MM/YYYY HH:mm',
                  firstDay: 1,
                  monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                  daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                }"
            [(ngModel)]="selected"
            [ngModelOptions]="{standalone: true}"
            [opens]="'right'"
            [showCancel]="true"
            [showClearButton]="true"
            [showDropdowns]="true"
            [timePicker]="true"
            [timePickerIncrement]="15"
            [timePickerSeconds]="false"
            [timePicker24Hour]="true"
            [singleDatePicker]="true"
          />

        </div>
      </div>
      <span class="container" id="navigateButtons" name="navigateButtons" *ngIf=" formType == 'REPORT' ">
        <button id="rewind" name="rewind" class="btn btn-default">
          <i class="fa fa-angle-left fa-1x"></i>
        </button>
        <button id="forward" name="forward" class="btn btn-default">
          <i class="fa fa-angle-right fa-1x"></i>
        </button>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'DATA_WITHOUT_HOURS'">
      <div id="div_date_without_hours{{field.count}}" class="datefilter tour-field-date {{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">

          <ng-container *ngIf="hasLabel">
            <ng-container *ngIf="(field.icon != ''); then withIcon; else withoutIcon">
            </ng-container>

            <ng-template #withIcon>
              <div>
              <span class="input-group-text">
                <i class='{{field.icon}}' title='Data'></i>
              </span>
              </div>
            </ng-template>

            <ng-template #withoutIcon>
              <div class="d-flex mb-1">
                <span class="mx-0">
                  {{field.label}}
                </span>
                <i class="fa-solid fa-circle-info mx-1" [tooltip]="field.description" *ngIf="field.description"></i>
              </div>
            </ng-template>
          </ng-container>

          <input type="text" id="date_without_hours{{field.count}}" name="date_without_hours{{field.count}}"
            class="form-control field-engine single-date-without-hours"
            ngxDaterangepickerMd
            alwaysShowCalendars="true"
            autocomplete="off"
            [closeOnAutoApply]="true"
            [linkedCalendars]="true"
            [locale]="{
                  applyLabel: 'Aplicar',
                  customRangeLabel: 'Personalizar',
                  clearLabel: '',
                  cancelLabel: 'Cancelar',
                  format: 'DD/MM/YYYY',
                  firstDay: 1,
                  monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                  daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                }"
            [(ngModel)]="selected"
            [ngModelOptions]="{standalone: true}"
            [opens]="'right'"
            [showCancel]="true"
            [showClearButton]="true"
            [showDropdowns]="true"
            [timePicker]="false"
            [timePickerIncrement]="15"
            [timePickerSeconds]="false"
            [timePicker24Hour]="true"
            [singleDatePicker]="true"
          />

        </div>
      </div>
      <span class="container" id="navigateButtons" name="navigateButtons" *ngIf=" formType == 'REPORT' ">
        <button id="rewind" name="rewind" class="btn btn-default">
          <i class="fa fa-angle-left fa-1x"></i>
        </button>
        <button id="forward" name="forward" class="btn btn-default">
          <i class="fa fa-angle-right fa-1x"></i>
        </button>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'YEARBTW'">
      <div id="div_date-interval" class="datefilter tour-field-yearbtw {{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend">

          <ng-container *ngIf="hasLabel">
            <ng-container *ngIf="(field.icon != ''); then withIcon; else withoutIcon">
            </ng-container>

            <ng-template #withIcon>
              <div>
                <span class="input-group-text">
                  <i class='{{field.icon}}' title='Data'></i>
                </span>
              </div>
            </ng-template>

            <ng-template #withoutIcon>
              <div>
                <span class="input-group-text">
                {{field.label}}
                </span>
              </div>
            </ng-template>

          </ng-container>

          <ngx-slider class="date-interval-year" id="year-interval{{field.count}}" [(value)]="options.minValue" [(highValue)]="options.maxValue" [options]="options"></ngx-slider>

        </div>
      </div>

    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'TEXT'">
      <div id="div_textField{{field.count}}" class="{{positionField}}" [attr.ic_div]="true">
          <div class="d-flex ">
            <div class="d-flex align-items-center mb-1">
              <span class="mx-0" for="textField{{field.count}}" *ngIf="hasLabel">{{field.label}}</span>
              <i class="fa-solid fa-circle-info mx-1" [tooltip]="field.description" *ngIf="field.description"></i>
            </div>
            <input type="text" name="textField{{field.count}}" id="textField{{field.count}}" class="form-control field-engine betweenEdit textInput"  />
          </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'HOUR'">
      <div id="div_hourField{{field.count}}" class="tour-field-hour {{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">
          <span class="input-group-text" for="hourField{{field.count}}" *ngIf="hasLabel">{{field.label}}</span>
          <input type="time" name="hourField{{field.count}}" id="hourField{{field.count}}" class="form-control field-engine betweenEdit hourInput"  />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'IMAGE'">
      <div id="div_imageField{{field.count}}" class="{{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend">
          <span class="input-group-text" for="imageField{{field.count}}" *ngIf="hasLabel">{{field.label}}</span>
          <input capture accept="image/*" type="file" name="imageField{{field.count}}" id="imageField{{field.count}}"
            (change)="onFileSelected($event)"
            class="form-control field-engine betweenEdit imageInput"  />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'FILE'">
      <div id="div_fileField{{field.count}}" class="{{positionField}}" [attr.ic_div]="true">
        <div class="input-group-prepend">
          <span class="input-group-text" for="fileField{{field.count}}" *ngIf="hasLabel">{{field.label}}</span>
          <input type="file" accept="*/*" name="fileField{{field.count}}" id="fileField{{field.count}}"
            (change)="onFileSelected($event)"
            class="form-control field-engine betweenEdit fileInput"  />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'TEXT_AREA'">
      <div id="div_textAreaField{{field.count}}" class="{{positionField}}" style="min-height: 100px" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">
          <span class="input-group-text" for="textAreaField{{field.count}}" *ngIf="hasLabel">{{field.label}}</span>
          <textarea name="textAreaField{{field.count}}" id="textAreaField{{field.count}}" class="form-control field-engine betweenEdit textAreaInput" style="min-height: 75px"></textarea>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase=" field.type.startsWith('TEXT_ARRAY_') ">
      <div id="div_textArrayField{{field.count}}" class="{{positionField}}" [attr.ic_div]="true">

        <div class="input-group"
          *ngFor=" let h of text_array "
          id="array_div_{{field.count}}_{{h}}"
          name="array_div_{{field.count}}_{{h}}"
          [style.display]=" h == 0 ? '' : 'none' ">
          <div class="input-group-prepend">
            <span class="input-group-text" *ngIf="hasLabel"
              [style.visibility]="(h == 0 ? 'inherit' : 'hidden')" >
              {{field.label}}
            </span>
          </div>
          <input type="text"
            class="form-control field-engine ml-1 textArrayInput"
            *ngFor="let ti of field.limit "
            name="textArrayField{{field.count}}_{{h}}_{{ti}}"
            id="textArrayField{{field.count}}_{{h}}_{{ti}}">
          <div class="input-group-append1">
            <button class="btn waves-effect waves-light btn-rounded btn-danger"
              type="button"
              *ngIf="h!=0"
              (click)="removeLine(field.count, h)"
              name="removeButton{{field.count}}_{{h}}"
              id="removeButton{{field.count}}_{{h}}">
              -
            </button>
            <button class="btn waves-effect waves-light btn-rounded btn-success"
              type="button"
              *ngIf="h!=100"
              (click)="addLine(field.count, h)"
              name="addButton{{field.count}}_{{h}}"
              id="addButton{{field.count}}_{{h}}">
              +
            </button>
          </div>
        </div>

      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'NINT32' || field.type === 'NINT64' || field.type === 'IINT32' || field.type === 'IINT64' || field.type === 'FLOAT' ">
      <div id="div_plainField{{field.type}}{{field.count}}" class="{{positionField}} fieldLab" [attr.ic_div]="true">
        <div class="input-group-prepend d-flex ">
          <div class="d-flex align-items-center mb-1 mx-0">
            <span for="plainField{{field.type}}{{field.count}}" class="" *ngIf="hasLabel">
              {{field.label}}
            </span>
            <i class="fa-solid fa-circle-info mx-1" [tooltip]="field.description" *ngIf="field.description"></i>
          </div>
          <input type="number"
            name="plainField{{field.type}}{{field.count}}"
            id="plainField{{field.type}}{{field.count}}"
            class="betweenEdit plainInput form-control field-engine plainInput_{{field.type}}"
            maxval='@(typeConf[0].Contains("32") ? Int32.MaxValue : Int64.MaxValue)'
            maxlength='@(typeConf[0].Contains("32") ? "10" : "18")'
            onkeypress = '@(typeConf[0].StartsWith("N") ? "return naturalNumMask(event)" : "return integerMask(event)")' />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'SELECT'">
      <div id="div_selectTag{{field.count}}" dependson="" class="{{positionField}} fieldLab" [attr.ic_div]="true" >
        <div class="input-group-prepend">
          <span for="selectTag{{field.count}}" class="input-group-text" *ngIf="hasLabel">
            {{field.label}}
          </span>
          <select id="selectTag{{field.count}}" name="selectTag{{field.count}}" class="simpleSelect form-control field-engine">
            <option value="" *ngIf="field.default_value == null"></option>
            <option *ngFor="let s of field.items" [value]="s[0]">
              <ng-container *ngIf="s.length > 1; then case1; else case2">
              </ng-container>
              <ng-template #case1>{{s[1]}}</ng-template>
              <ng-template #case2>{{s[0]}}</ng-template>
            </option>

          </select>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'SELECTMULTIPLE'" >
      <div id="div_selectTag{{field.count}}" dependson="" class="{{positionField}} fieldLab" [attr.ic_div]="true" >
        <div class="input-group-prepend">
          <span for="selectTag{{field.count}}" class="input-group-text" *ngIf="hasLabel">
            {{field.label}}
          </span>
          <ng-select id="selectTag{{field.count}}" name="selectTag{{field.count}}" [multiple]="true" class="multipleSelect field-engine" style="width: 100%;">
            <ng-option *ngFor="let s of field.items" [value]="s[0]">
              <ng-container *ngIf="s.length > 1; then case1; else case2">
              </ng-container>
              <ng-template #case1>{{s[1]}}</ng-template>
              <ng-template #case2>{{s[0]}}</ng-template>
            </ng-option>

          </ng-select>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="field.type === 'CHECKBOX'  || field.type === 'RADIO' ">
      <div id="div_divselect{{field.type}}{{field.count}}" class="col-md-12 fieldLab" [attr.ic_div]="true">
        <div class="row">
          <label for="divselect{{field.type}}{{field.count}}" *ngIf="hasLabel">{{field.label}}</label>
          <div id="divselect{{field.type}}{{field.count}}" name="divselect{{field.type}}{{field.count}}" class="divGroup{{field.type}}">
            <ng-container *ngFor="let s of field.items; let i = index">
              <input type="{{field.type.toLowerCase()}}" class="{{ field.type === 'CHECKBOX' ? 'checkbox-style' : '' }}"
                name="select{{field.type}}{{field.count}}" id="{{s[0]}}_{{ i }}"
                value="{{s[0]}}"  />
              <ng-container *ngIf="s.length > 1; then case1; else case2">
              </ng-container>
              <ng-template #case1>
                <label for="{{s[0]}}_{{ i }}" class="inputright" >{{s[1]}}</label>
              </ng-template>
              <ng-template #case2>
                <label for="{{s[0]}}_{{ i }}" class="inputright" >{{s[0]}}</label>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-template>


import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-company-switcher',
  templateUrl: './company-switcher.component.html',
  styleUrls: ['./company-switcher.component.css'],
})
export class CompanySwitcherComponent implements OnInit {
  isSuperUser: boolean = false;
  isOpened: boolean = false;

  form: FormGroup;
  get formControls() {
    return this.form.controls;
  }

  data: { keys: any; mapping: {} } = null;
  databasesByCompany: any[] = [];
  actualDb: string = null;

  originDbByUser: string = null;

  selectedCompany: any;
  actualCompanyId: number;
  selectedDbIndex: number;

  get isHiden() {
    const url = window.location.href;
    return url.includes('signin');
  }

  isLoading: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    if (this.authService.hasToken() && this.hasPermissionCompanySwitcher()) {
      this.getCompanyList();
      this.actualCompanyId = this.authService.getPerson();
    }
  }

  hasPermissionCompanySwitcher(): boolean {
    return this.authService.checkPermission('access_company_switcher');
  }

  onHandle() {
    this.isOpened = !this.isOpened;
  }

  getCompanyList(): void {
    this.authService
      .getSwitchCompanyList()
      .subscribe((data: { keys: any; mapping: {} }) => {
        this.data = data;
        const name = this.data?.keys[0];
        // this.onSelectCompany(name);
      })
      .add(() => {
        this.actualDb = this.authService.getDbName();
      });
  }

  onSelectCompany(name: string) {
    this.databasesByCompany = [];
    this.selectedCompany = null;

    const databases = this.data.mapping[name];
    if (!databases) {
      return;
    }
    this.databasesByCompany = databases;
  }

  onSelectDatabase(index: any) {
    if (!index) {
      return;
    }

    this.selectedDbIndex = index;

    const db = this.databasesByCompany[index];
    this.selectedCompany = db;
    console.log(this.selectedCompany);
  }

  onSave(): void {
    if (!this.selectedCompany) {
      return;
    }

    this.isLoading = true;
    this.authService.setPerson(this.selectedCompany);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  onNavigateToOriginDb() {
    const originData = localStorage.getItem(this.authService.prefixKeyName + 'origin');
    const decryptedOrigin = JSON.parse(this.authService.decrypt(originData));

    let updatedData = {
      id: decryptedOrigin.person_data.id,
      person: decryptedOrigin.person_data,
      report_db: decryptedOrigin.report_db,
    };

    this.isLoading = true;
    this.authService.setPerson(updatedData);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}

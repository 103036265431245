<div class="notification-popup bg-white animated bounceInDown shadow-sm pb-3">
  <span class="with-arrow">
    <span class="bg-primary"></span>
  </span>
  <ul class="list-style-none w-100">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column px-3">
        <h4 class="">Notificações</h4>
        <small>{{ notifications.length }} notificações não lidas</small>
      </div>

      <button class="btn" (click)="onClose()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>

    <ng-container *ngIf="notifications.length > 0">
      <hr class="my-1"/>
      <li class="">
        <div class="notifications-scroll">
          <!-- Message -->
          <ng-container *ngFor="let n of notifications">
            <div class="d-flex px-3 py-2 notification mt-2">
              <span class="btn btn-danger btn-circle d-flex align-items-center">
                <i class="fa-regular fa-bell fa-lg"></i>
              </span>

              <div class="d-flex flex-column">
                <h4 class="message-title">{{ n.title }}</h4>
                <span class="mail-desc">{{ n.desc }}</span>
                <small class="time mx-2">{{ n.time }}</small>
              </div>
            </div>
          </ng-container>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
